import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {HexaDetailComponent} from "@hexalang/ui/core";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {DateFormControl, EPermission, PaymentHelper} from "@city-tax/shared";
import {BehaviorSubject, Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {IndividualService} from "../../../../../services/individual.service";
import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {NgxPermissionsService} from "ngx-permissions";
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {IndividualFilingOhW2FormComponent} from "../w2-form/individual-filing-oh-w2-form.component";
import {IndividualFilingOhScheduleFormComponent} from '../schedule-form/individual-filing-oh-schedule-form.component';
import {
  IndividualFilingOhAdjustmentIncomeFormComponent
} from '../adjustment-income-form/individual-filing-oh-adjustment-income-form.component';

import {WorksheetAHelper} from "libs/features/individual/src/lib/helpers/oh/worksheet-a.helper";
import {ScheduleHelper} from "libs/features/individual/src/lib/helpers/oh/schedule.helper";
import {WorksheetCHelper} from "libs/features/individual/src/lib/helpers/oh/worksheet-c.helper";
import {LossCarryForwardHelper} from "libs/features/individual/src/lib/helpers/oh/loss-carry-forward.helper";
import {OhIndividualReturnHelper} from "libs/features/individual/src/lib/helpers/oh/oh-individual-return.helper";
import {PartYearHelper} from "../../../../../helpers/oh/part-year.helper";
import {IWorksheetA} from "../../../../../interfaces/individual-return.interface";


@Component({
  selector: "city-tax-feature-individual-filing-oh-page1",
  templateUrl: "./individual-filing-oh-page1.component.html",
  styleUrls: ["./individual-filing-oh-page1.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IndividualFilingOhPage1Component
  extends HexaDetailComponent
  implements OnInit, AfterViewInit {
  public form: FormGroup;
  public taxYear = this.individualService?.taxYear;
  public currentYear = this.individualService.currentYear;
  public config$ = this.authService.config$;
  public ePermission = EPermission;
  public hasPermission$: Observable<boolean>;
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.individualService.isLoading$;
  public isUploading$ = this.individualService.isUploading$;
  public error = null;
  public taxDocument = this.authService.organization.taxDocument;
  public city = this.authService.config;
  public config = this.authService.config;
  public individualReturn$ = this.individualService.individualReturn$;
  public individualReturn = this.individualService.individualReturn;

  public adjustmentTypes = [
    "Military Pay",
    "Deferred Compensation",
    "Wages Earned Under 18",
    "Wages earned while non-resident",
    "Non-Resident Wages while Working outside City"
  ];

  changeDetectorRef: any;
  public w2ModelRef: BsModalRef;
  public scheduleModelRef: BsModalRef;
  public adjustmentIncomeModelRef: BsModalRef;

  constructor(
    injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private individualService: IndividualService,
    private authService: AuthService,
    public toastrService: ToastrService,
    public permissionService: NgxPermissionsService,
    private formBuilder: FormBuilder,
    private modal: BsModalService,
    private paymentHelper: PaymentHelper,
    private cdref: ChangeDetectorRef
  ) {
    super(injector);
  }

  get getOtherIncomeTypeScheduleCDetail(): FormArray {
    return this.form.get(
      "page2.worksheetB.otherIncomeTypeScheduleCDetail"
    ) as FormArray;
  }

  get getLossCarryForward(): FormArray {
    return this.form.get("page2.worksheetB.lossCarryForward") as FormArray;
  }

  get getOtherIncomeTypeScheduleK1Detail(): FormArray {
    return this.form.get(
      "page2.worksheetB.otherIncomeTypeScheduleK1Detail"
    ) as FormArray;
  }

  get getOtherIncomeTypeScheduleEDetail(): FormArray {
    return this.form.get(
      "page2.worksheetB.otherIncomeTypeScheduleEDetail"
    ) as FormArray;
  }

  get getOtherIncomeTypeScheduleMiscDetail(): FormArray {
    return this.form.get(
      "page2.worksheetB.otherIncomeTypeScheduleMiscDetail"
    ) as FormArray;
  }

  get getAdjustmentToIncomeLine(): FormArray {
    return this.form.get(
      "page2.worksheetC.adjustmentToIncomeLine"
    ) as FormArray;
  }

  get getSpouseScheduleOfWages(): FormArray {
    return this.form.get("page2.worksheetA.spouseScheduleOfWages") as FormArray;
  }

  get getTaxpayerScheduleOfWages(): FormArray {
    return this.form.get("page2.worksheetA.taxpayerScheduleOfWages") as FormArray;
  }

  get isResident() {
    if (this.individualReturn.residency === "R") return true;
  }

  get numberFormat(): string {
    return this.city.components?.individual?.fractionDigits === 2 ? '1.2-2' : '1.0-0';
  }


  async ngOnInit() {
    //this.checkPermission();
    this.form = this.formBuilder.group({
      id: null,
      wagesSalariesTips: null,
      otherIncome: null,
      totalIncome: null,
      adjustments: null,
      taxableIncome: null,
      multiplyTaxableIncome: null,
      fillingCityTaxRatePercent: null,
      totalCreditsAllowable: null,
      taxDue: null,
      totalEstimatedTaxPaidAndPriorYearOverpayment: null,
      totalDueAfterPayments: null,
      lateFillingFee: null,
      totalPenaltyAndInterest: null,
      totalAmountDue: null,
      overpayment: null,
      overpaymentToBeRefunded: null,
      penalty: null,
      interest: null,
      overpaymentLessThen: null,
      priorYearOverpayment: null,
      estimatedTaxPaid: null,
      totalEstimatedIncomeSubjectToTax: null,
      fillingCityCreditRatePercent: null,
      fillingCityCreditRateDecimal: null,
      estimatedIncomeTax: null,
      lessExpectedTaxCredits: null,
      netTaxDue: null,
      overpaymentToBeCredited: null,
      amountDueWithThisDeclaration: null,
      makeCheckPayableTo: null,
      totalOfThisPayment: null,
      ownProperty: null,
      landlordName: null,
      taxForms: this.formBuilder.group({
        w2: this.formBuilder.group({
          required: false,
          provided: null,
        }),
        otherCity: this.formBuilder.group({
          required: null,
          provided: null,
        }),
        federal: this.formBuilder.group({
          required: false,
          provided: null,
        }),
        city: this.formBuilder.group({
          type: null,
          attachment1: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_1: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_2: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_3: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_4: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment3: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment4: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment5: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment6: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment7: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment8: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment9: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment10: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment11: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment12: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment13: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment14: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment15: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment16: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment17: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment18: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment19: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment20: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment21: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment22: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          cfCov: this.formBuilder.group({
            required: false,
            provided: null,
          }),
        }),
      }),
      landlordAddress: this.formBuilder.group({
        addressLine1: null,
        addressLine2: null,
        aptNumber: null,
        city: null,
        state: null,
        postalCode: null,
      }),
      filingDate: null,
      page2: this.formBuilder.group({
        noTaxableIncome: false,
        noTaxableIncomeExplanation: null,
        worksheetA: this.formBuilder.group({
          taxpayerScheduleOfWages: this.formBuilder.array([]),
          spouseScheduleOfWages: this.formBuilder.array([]),
          grandTotalFillingCityTaxWithheld: null,
          grandTotalQualifyingWages: null,
          grandTotalOtherCityTaxWithheld: null,
          grandTotalTaxCreditAllowableOtherCities: null,
          grandTotalWages: null,
          grandTotalScheduleTaxableWages: null,
          grandTotalBox1Wages: null,
        }),
        worksheetB: this.formBuilder.group({
          totalIncomeOrLoss: null,
          totalMunicipalityTaxableIncome: null,
          totalTaxCreditAllowedForTaxPaidToOtherCities: null,

          otherIncomeTypeScheduleCDetail: this.formBuilder.array([]),
          otherIncomeTypeScheduleK1Detail: this.formBuilder.array([]),
          otherIncomeTypeScheduleEDetail: this.formBuilder.array([]),
          otherIncomeTypeScheduleMiscDetail: this.formBuilder.array([]),
          lossCarryForward: this.formBuilder.array([]),

          totalIncomeLossScheduleC: null,
          municipalityPercentageScheduleC: null,
          municipalityTaxableIncomeScheduleC: null,
          taxCreditAllowedForTaxPaidToOtherCitiesScheduleC: null,

          totalIncomeLossScheduleK1: null,
          municipalityPercentageScheduleK1: null,
          municipalityTaxableIncomeScheduleK1: null,
          taxCreditAllowedForTaxPaidToOtherCitiesScheduleK1: null,

          totalIncomeLossScheduleE: null,
          municipalityPercentageScheduleE: null,
          municipalityTaxableIncomeScheduleE: null,
          taxCreditAllowedForTaxPaidToOtherCitiesScheduleE: null,

          totalNetOperatingLossCarryForward: null,
          municipalityPercentageNetOperatingLossCarryForward: null,
          municipalityTaxableIncomeNetOperatingLossCarryForward: null,
          taxCreditAllowedForTaxPaidToOtherCitiesNetOperatingLossCarryForward:
            null,

          totalIncomeLossMisc: null,
          municipalityPercentageMisc: null,
          municipalityTaxableIncomeMisc: null,
          taxCreditAllowedForTaxPaidToOtherCitiesMisc: null,
        }),
        worksheetC: this.formBuilder.group({
          adjustmentToIncomeLine: this.formBuilder.array([]),
          totalColumn1Adjustment: null,
        }),
      }),
      authorization: null, //Validators.required
      residency: null,
      partYearFromDate: new DateFormControl(''),
      partYearToDate: new DateFormControl(''),
    });
    this.form.get('page2.noTaxableIncome')?.valueChanges.subscribe((value) => {

      if (value) {
        (this.form.get('page2.worksheetA.taxpayerScheduleOfWages') as FormArray).clear();
        (this.form.get('page2.worksheetA.spouseScheduleOfWages') as FormArray).clear();
        (this.form.get('page2.worksheetB.otherIncomeTypeScheduleCDetail') as FormArray).clear();
        (this.form.get('page2.worksheetB.otherIncomeTypeScheduleK1Detail') as FormArray).clear();
        (this.form.get('page2.worksheetB.otherIncomeTypeScheduleEDetail') as FormArray).clear();
        (this.form.get('page2.worksheetB.otherIncomeTypeScheduleMiscDetail') as FormArray).clear();
        (this.form.get('page2.worksheetB.lossCarryForward') as FormArray).clear();
        (this.form.get('page2.worksheetC.adjustmentToIncomeLine') as FormArray).clear();

        this.checkAdjustments();
        this.calculateWorksheetA();
        this.calculateWorksheetB();
        this.calculateWorksheetC();
        this.cdref.detectChanges();
      } else {
        this.form.get('page2.noTaxableIncomeExplanation').setValue(null);
      }
    });
    await this.getReturn();
  }

  async ngAfterViewInit() {
    await this.getReturn();
  }

  addWorksheetA() {

    this.getSpouseScheduleOfWages.clear();
    this.getTaxpayerScheduleOfWages.clear();

    this.individualReturn?.page2?.worksheetA?.spouseScheduleOfWages?.map(
      (u: any) => {
        u.w2LocalityInfo = Array.isArray(u.w2LocalityInfo) ? u.w2LocalityInfo : [u.w2LocalityInfo];
        u.type = "S";
        u.w2LocalityInfo = u.w2LocalityInfo
          ? this.formBuilder.array([...u.w2LocalityInfo])
          : this.formBuilder.array([]);

        this.addToSpouseScheduleOfWages(this.formBuilder.group(u));
      }
    );

    this.individualReturn?.page2?.worksheetA?.taxpayerScheduleOfWages?.map(
      (u: any) => {
        u.w2LocalityInfo = Array.isArray(u.w2LocalityInfo) ? u.w2LocalityInfo : [u.w2LocalityInfo];
        u.type = "T";
        u.w2LocalityInfo = u.w2LocalityInfo
          ? this.formBuilder.array([...u.w2LocalityInfo])
          : this.formBuilder.array([]);
        this.addToTaxpayerScheduleOfWages(this.formBuilder.group(u));
      }
    );
    this.calculateWorksheetA();
  }

  calculateWorksheetA() {
    this.calculateWages();
  }

  addWorksheetB() {

    this.getOtherIncomeTypeScheduleCDetail.clear();
    this.getOtherIncomeTypeScheduleK1Detail.clear();
    this.getOtherIncomeTypeScheduleEDetail.clear();
    this.getOtherIncomeTypeScheduleMiscDetail.clear();
    this.getLossCarryForward.clear();

    this.individualReturn?.page2?.worksheetB?.otherIncomeTypeScheduleCDetail?.map(
      (u: any) => {
        this.getOtherIncomeTypeScheduleCDetail.push(this.formBuilder.group(u));
      }
    );

    this.individualReturn?.page2?.worksheetB?.otherIncomeTypeScheduleK1Detail?.map(
      (u: any) => {
        this.getOtherIncomeTypeScheduleK1Detail.push(this.formBuilder.group(u));
      }
    );

    this.individualReturn?.page2?.worksheetB?.otherIncomeTypeScheduleEDetail?.map(
      (u: any) => {
        this.getOtherIncomeTypeScheduleEDetail.push(this.formBuilder.group(u));
      }
    );

    this.individualReturn?.page2?.worksheetB?.otherIncomeTypeScheduleMiscDetail?.map(
      (u: any) => {
        this.getOtherIncomeTypeScheduleMiscDetail.push(
          this.formBuilder.group(u)
        );
      }
    );

    this.individualReturn?.page2?.worksheetB?.lossCarryForward?.map(
      (u: any) => {
        this.getLossCarryForward.push(this.formBuilder.group(u));
      }
    );

    this.calculateWorksheetB();
  }

  calculateWorksheetB() {
    this.calculateScheduleC();
    this.calculateScheduleE();
    this.calculateScheduleK1();
    this.calculateScheduleMisc();
    let totalIncomeOrLoss = 0;
    let totalMunicipalityTaxableIncome = 0;
    let totalTaxCreditAllowedForTaxPaidToOtherCities = 0;

    totalIncomeOrLoss += +this.form.get(
      "page2.worksheetB.totalIncomeLossScheduleC"
    ).value;

    totalIncomeOrLoss += +this.form.get(
      "page2.worksheetB.totalIncomeLossScheduleE"
    ).value;

    totalIncomeOrLoss += +this.form.get(
      "page2.worksheetB.totalIncomeLossScheduleK1"
    ).value;

    totalMunicipalityTaxableIncome += +this.form.get(
      "page2.worksheetB.municipalityTaxableIncomeScheduleC"
    ).value;

    totalMunicipalityTaxableIncome += +this.form.get(
      "page2.worksheetB.municipalityTaxableIncomeScheduleE"
    ).value;

    totalMunicipalityTaxableIncome += +this.form.get(
      "page2.worksheetB.municipalityTaxableIncomeScheduleK1"
    ).value;

    this.calculateLossCarryForward(totalIncomeOrLoss);
  }

  addWorksheetC() {
    this.getAdjustmentToIncomeLine.clear();
    this.individualReturn?.page2?.worksheetC?.adjustmentToIncomeLine?.map(
      (u: any) => {
        this.getAdjustmentToIncomeLine.push(this.formBuilder.group(u));
      }
    );
    this.calculateWorksheetC();
  }

  calculateWorksheetC() {
    this.calculateAdjustments();
  }

  public trackByFn = (index, item) => {
    return index;
  };

  openScheduleWages(row = {}, isEdit = false, index) {
    this.w2ModelRef = this.modal.show(IndividualFilingOhW2FormComponent, {
      class: "modal-dialog modal-xlg",
      initialState: {
        isEdit,
        row,
        index,
      },
    });
    this.w2ModelRef.onHide.subscribe(() => {
      // console.log("************  destroy *********** ");
      // this.w2ModelRef.content.destroy();
      this.calculateWages();
    });
    this.w2ModelRef.content.addW2Form.subscribe((response: any) => {
      console.log("************  addW2Form *********** ", response);
      const resString = JSON.stringify(response);
      const res = JSON.parse(resString);
      res.w2LocalityInfo = res.w2LocalityInfo ? this.formBuilder.array([...[].concat(res.w2LocalityInfo)]) : this.formBuilder.array([]);
      if (res?.type == "T") {
        this.addToTaxpayerScheduleOfWages(this.formBuilder.group(res));
      } else if (res?.type == "S") {
        this.addToSpouseScheduleOfWages(this.formBuilder.group(res));
      }

      console.log(this.getTaxpayerScheduleOfWages);


      this.checkAdjustments();
      this.calculateWorksheetA();
      this.cdref.detectChanges();
      this.modal.hide();
    });
    this.w2ModelRef.content.updateW2Form.subscribe((response: any) => {
      // console.log("************  updateW2Form *********** ", res);
      const resString = JSON.stringify(response);
      const res = JSON.parse(resString);
      if (res?.type == "T" && row["type"] == "T") {
        this.updateTaxpayerScheduleOfWages(res, index);
      }
      if (res?.type == "T" && row["type"] == "S") {
        res.w2LocalityInfo = res.w2LocalityInfo
          ? this.formBuilder.array([...[].concat(res.w2LocalityInfo)])
          : this.formBuilder.array([]);
        this.removeSpouseScheduleOfWages(index);
        this.addToTaxpayerScheduleOfWages(this.formBuilder.group(res));
      } else if (res?.type == "S" && row["type"] == "S") {
        this.updateSpouseScheduleOfWages(res, index);
      } else if (res?.type == "S" && row["type"] == "T") {
        res.w2LocalityInfo = res.w2LocalityInfo
          ? this.formBuilder.array([...[].concat(res.w2LocalityInfo)])
          : this.formBuilder.array([]);
        this.removeTaxpayerScheduleOfWages(index);
        this.addToSpouseScheduleOfWages(this.formBuilder.group(res));
      }
      this.calculateWorksheetA();
      this.checkAdjustments();
      this.cdref.detectChanges();
      this.modal.hide();
    });
  }

  removeTaxpayerScheduleOfWages(index: number) {
    this.getTaxpayerScheduleOfWages.removeAt(index);
    this.calculateWages();
    this.checkAdjustments();
    this.calculateWorksheetA();
  }

  removeSpouseScheduleOfWages(index: number) {
    this.getSpouseScheduleOfWages.removeAt(index);
    this.calculateWages();
    this.checkAdjustments();
    this.calculateWorksheetA();
  }

  addToTaxpayerScheduleOfWages(item: any) {
    this.getTaxpayerScheduleOfWages.push(item);
  }

  updateTaxpayerScheduleOfWages(payload: any, index: number) {
    const formGroup = this.getTaxpayerScheduleOfWages.at(index) as FormGroup;
    Object.keys(payload).forEach(key => {
      if (key === 'w2LocalityInfo') {
        const control = formGroup.get(key) as FormArray;
        while (control.length !== 0) {
          control.removeAt(0);
        }
        payload[key].forEach((item: any) => {
          control.push(this.formBuilder.control(item));
        });
      } else {
        if (!formGroup.get(key)) {
          formGroup.addControl(key, this.formBuilder.control(payload[key]));
        } else {
          formGroup.get(key)?.patchValue(payload[key]);
        }
      }
    });
    this.form.updateValueAndValidity();
  }

  checkAdjustments() {
    const adjustments = this.getAdjustmentToIncomeLine.value;
    this.getAdjustmentToIncomeLine.clear();
    const taxpayerScheduleOfWages = this.getTaxpayerScheduleOfWages.value;
    const spouseScheduleOfWages = this.getSpouseScheduleOfWages.value;
    const wages = taxpayerScheduleOfWages.concat(spouseScheduleOfWages);
    const customAdjustments = adjustments.filter((a) => !this.adjustmentTypes.includes(a?.explanation));

    const refundRequestedWage = wages.find((w) => this.getBoolean(w?.refundRequestedFlag));
    if (refundRequestedWage) {
      this.form.get('taxForms.otherCity.required').setValue(true);
    }

    this.addExcludibleAdjustments(wages);
    this.addMilitaryPayAdjustments(wages);
    this.addUnder18Adjustments(wages);
    this.addDeferredCompensationAdjustments(wages);

    if (this.individualReturn.residency === "P") {
      this.addPartYearResidents(wages);
    }

    for (const adjustment of customAdjustments) {
      this.getAdjustmentToIncomeLine.push(this.formBuilder.group(adjustment));
    }
    this.calculateAdjustments();
  }

  addMilitaryPayAdjustments(wages: any[]) {
    //Military Pay
    const militaryPayWages = wages.filter((w) => this.getBoolean(w?.militaryWagesFlag));
    let totalAdjustment = 0;

    const pickValue = (box1: any, box5: any, localityTotal: any) => {
      if (localityTotal > 0) return localityTotal;
      if (box5 > 0) return box5;
      return box1;
    }

    for (const militaryWage of militaryPayWages) {
      const localityTotal = this.paymentHelper.sum(militaryWage?.w2LocalityInfo, 'wagesBox18');
      const box5 = this.paymentHelper.decimalValue(militaryWage.totalBox5Wages);
      const box1 = this.paymentHelper.decimalValue(militaryWage.box1Wages);
      totalAdjustment += pickValue(box1, box5, localityTotal);
    }

    if (totalAdjustment > 0) {
      const payload = {
        column1Adjustment: -this.paymentHelper.decimalValue(totalAdjustment),
        explanation: `Military Pay`,
      };
      this.getAdjustmentToIncomeLine.push(this.formBuilder.group(payload));
    }
  }

  addExcludibleAdjustments(wages: any[]) {
    //Excludible Wage
    const excludedWages = wages.filter((w) => this.getBoolean(w?.excludedWagesFlag));
    let totalAdjustment = 0;

    const pickValue = (box1: any, box5: any) => {
      if (box5 > 0) return box5;
      return box1;
    }

    for (const excludedWage of excludedWages) {
      const box5 = this.paymentHelper.decimalValue(excludedWage.totalBox5Wages);
      const box1 = this.paymentHelper.decimalValue(excludedWage.box1Wages);
      totalAdjustment += pickValue(box1, box5);
    }

    if (this.isPartYear()) {
      totalAdjustment = this.getWagesWorkedOutside(wages);
    }

    // if (totalAdjustment > 0) {
    //   const payload = {
    //     column1Adjustment: -this.paymentHelper.decimalValue(totalAdjustment, 0),
    //     explanation: `Non-Resident Wages while Working outside City`,
    //   };
    //   this.getAdjustmentToIncomeLine.push(this.formBuilder.group(payload));
    // }
  }

  addDeferredCompensationAdjustments(wages: any[]) {
    const deferredCompensationWages = wages.filter((w) => this.getBoolean(w?.deferredCompensationFlag));
    let totalAdjustment = 0;

    const pickValue = (box1: any, box5: any, localityTotal: any) => {
      if (localityTotal > 0) return localityTotal;
      if (box5 > 0) return box5;
      return box1;
    }

    for (const deferredCompensationWage of deferredCompensationWages) {
      const localityTotal = this.paymentHelper.sum(deferredCompensationWage?.w2LocalityInfo, 'wagesBox18');
      const box5 = this.paymentHelper.decimalValue(deferredCompensationWage.totalBox5Wages);
      const box1 = this.paymentHelper.decimalValue(deferredCompensationWage.box1Wages);
      totalAdjustment += pickValue(box1, box5, localityTotal);
    }

    if (totalAdjustment > 0) {
      const payload = {
        column1Adjustment: -this.paymentHelper.decimalValue(totalAdjustment),
        explanation: `Deferred Compensation`,
      };
      this.getAdjustmentToIncomeLine.push(this.formBuilder.group(payload));
    }
  }

  getWagesWorkedOutside(wages: any[]) {
    //this backs out wages worked outside (ie taxpayer should not enter but did)
    let totalExcludible = 0;
    const partYearHelper = new PartYearHelper(this.paymentHelper, this.individualReturn.taxYear);
    for (const wage of wages) {

      const partYearFromDate = wage.type === 'T' ? this.individualReturn.partYearFromDate : this.individualReturn.partYearFromDateSpouse;
      const partYearToDate = wage.type === 'T' ? this.individualReturn.partYearToDate : this.individualReturn.partYearToDateSpouse;

      const days = partYearHelper.daysWorkedAsNonResident(partYearFromDate, partYearToDate, wage.dateWorkedFrom, wage.dateWorkedTo);
      // console.log('>>>>>>>>>> days',days);
      if (days !== 0) {
        const pickValue = (box1: any, box5: any, localityTotal: any) => {
          if (localityTotal > 0) return localityTotal;
          if (box5 > 0) return box5;
          return box1;
        }
        const localityTotal = this.paymentHelper.sum(wage?.w2LocalityInfo, 'wagesBox18');
        const box5 = this.paymentHelper.decimalValue(wage.totalBox5Wages);
        const box1 = this.paymentHelper.decimalValue(wage.box1Wages);
        totalExcludible += pickValue(box1, box5, localityTotal);
      }
    }
    return totalExcludible;
  }

  addUnder18Adjustments(wages) {
    const under18PayWages = wages.filter((w) => this.getBoolean(w?.under18WagesFlag) && !this.getBoolean(w?.militaryWagesFlag));
    let totalAdjustment = 0;
    const partYearHelper = new PartYearHelper(this.paymentHelper, this.individualReturn.taxYear);
    for (const under18PayWage of under18PayWages) {

      // const dateWorkedFrom = under18PayWage?.dateWorkedFrom ? moment.utc(under18PayWage?.dateWorkedFrom, "YYYY-MM-DD").startOf("day") : moment.utc(this.individualService.taxYear, "YYYY").startOf("year");
      // const dateWorkedTo = under18PayWage?.dateWorkedTo ? moment.utc(under18PayWage?.dateWorkedTo, "YYYY-MM-DD").add(1).startOf("day") : moment.utc(+this.individualService.taxYear + 1, "YYYY").startOf("year");
      // const birthdate = under18PayWage?.birthdate ? moment.utc(under18PayWage?.birthdate, "YYYY-MM-DD").add(18, 'years').add(1).startOf("day") : moment.utc();
      // const daysInYear = moment.utc(this.individualService.taxYear, "YYYY").endOf("year").dayOfYear();
      //const daysUnder18 = birthdate.isAfter(dateWorkedTo) ? daysInYear : birthdate.diff(dateWorkedFrom, "days");
      const year = this.city?.name === 'Alliance' ? 16 : 18;
      const daysUnder18 = partYearHelper.getDaysUnder18(under18PayWage?.dateWorkedFrom, under18PayWage?.dateWorkedTo, under18PayWage?.birthdate, year);
      for (const locality of under18PayWage?.w2LocalityInfo) {
        if (
          this.isResident ||
          locality.taxWithheldLocalityName == this.city.name
        ) {
          totalAdjustment += (this.paymentHelper.decimalValue(locality.wagesBox18) * daysUnder18) / partYearHelper.getDaysInYear();
        }
      }
    }
    if (totalAdjustment > 0) {
      const payload = {
        column1Adjustment: -this.paymentHelper.decimalValue(totalAdjustment),
        explanation: this.city?.name === 'Alliance' ? `Wages Earned Under 16` : `Wages Earned Under 18`,
      };
      this.getAdjustmentToIncomeLine.push(this.formBuilder.group(payload));
    }
  }

  addPartYearResidents(wages) {
    const worksheetA = this.form.get("page2.worksheetA").value;
    const worksheetAHelper = new WorksheetAHelper(
      this.paymentHelper,
      this.authService.config,
      this.individualReturn,
      this.individualReturn.resident,
      worksheetA,
      this.isPartYear()
    );

    if (worksheetAHelper.grandTotalNonResidentAdjustment > 0) {
      const payload = {
        column1Adjustment: -this.paymentHelper.decimalValue(worksheetAHelper.grandTotalNonResidentAdjustment),
        explanation: "Wages earned while non-resident",
      };
      this.getAdjustmentToIncomeLine.push(this.formBuilder.group(payload));
    }
  }

  addToSpouseScheduleOfWages(item: any) {
    this.getSpouseScheduleOfWages.push(item);
  }

  updateSpouseScheduleOfWages(payload: any, index: number) {
    // console.log(
    //     "************  updateSpouseScheduleOfWages *********** ",
    //     payload,
    //     index
    // );
    const formGroup = this.getSpouseScheduleOfWages.at(index) as FormGroup;
    Object.keys(payload).forEach(key => {
      if (key === 'w2LocalityInfo') {
        const control = formGroup.get(key) as FormArray;
        while (control.length !== 0) {
          control.removeAt(0);
        }
        payload[key].forEach((item: any) => {
          control.push(this.formBuilder.control(item));
        });
      } else {
        if (!formGroup.get(key)) {
          formGroup.addControl(key, this.formBuilder.control(payload[key]));
        } else {
          formGroup.get(key)?.patchValue(payload[key]);
        }
      }
    });
    this.form.updateValueAndValidity();
  }

  updateOtherIncomeTypeScheduleCDetail(payload, index) {
    // console.log(
    //     "************  updateOtherIncomeTypeScheduleCDetail *********** ",
    //     payload
    // );
    this.getOtherIncomeTypeScheduleCDetail.at(index).patchValue(payload);
    this.form.updateValueAndValidity();
  }

  updateOtherIncomeTypeScheduleEDetail(payload, index) {
    // console.log(
    //   "************  updateOtherIncomeTypeScheduleEDetail *********** ",
    //   payload
    // );
    this.getOtherIncomeTypeScheduleEDetail.at(index).patchValue(payload);
    this.form.updateValueAndValidity();
  }

  updateOtherIncomeTypeScheduleK1Detail(payload, index) {
    console.log(
      "************  updateOtherIncomeTypeScheduleK1Detail *********** ",
      payload
    );
    this.getOtherIncomeTypeScheduleK1Detail.at(index).patchValue(payload);
    this.form.updateValueAndValidity();
  }

  updateOtherIncomeTypeScheduleMiscDetail(payload, index) {
    // console.log(
    //   "************  updateOtherIncomeTypeScheduleMiscDetail *********** ",
    //   payload
    // );
    this.getOtherIncomeTypeScheduleMiscDetail.at(index).patchValue(payload);
    this.form.updateValueAndValidity();
  }

  updateLossCarryForward(payload) {
    // console.log("************  updateLossCarryForward *********** ", payload);
    this.getLossCarryForward.patchValue(payload);
    this.form.updateValueAndValidity();
  }

  addOtherIncomeTypeScheduleCDetail(payload) {
    // console.log(
    //   "************  addOtherIncomeTypeScheduleCDetail *********** ",
    //   payload
    // );
    this.getOtherIncomeTypeScheduleCDetail.push(
      this.formBuilder.group(payload)
    );
    this.form.updateValueAndValidity();
  }

  addOtherIncomeTypeScheduleEDetail(payload) {
    // console.log("************  addOtherIncomeTypeScheduleEDetail *********** ", payload);
    this.getOtherIncomeTypeScheduleEDetail.push(
      this.formBuilder.group(payload)
    );
    this.form.updateValueAndValidity();
  }

  addOtherIncomeTypeScheduleK1Detail(payload) {
    // console.log("************  addOtherIncomeTypeScheduleK1Detail *********** ", payload);
    this.getOtherIncomeTypeScheduleK1Detail.push(
      this.formBuilder.group(payload)
    );
    this.form.updateValueAndValidity();
  }

  addOtherIncomeTypeScheduleMiscDetail(payload) {
    // console.log("************  addOtherIncomeTypeScheduleMiscDetail *********** ", payload);
    this.getOtherIncomeTypeScheduleMiscDetail.push(
      this.formBuilder.group(payload)
    );
    this.form.updateValueAndValidity();
  }

  addLossCarryForward(payload) {
    // console.log("************  Add LossCarryForward *********** ", payload);
    payload?.map((loss) => {
      this.getLossCarryForward.push(this.formBuilder.group(loss));
    });
    this.form.updateValueAndValidity();
  }

  openSchedule(row = {}, isEdit = false, index) {
    let lossCarryForward = this.getLossCarryForward.value;
    this.scheduleModelRef = this.modal.show(
      IndividualFilingOhScheduleFormComponent,
      {
        class: "modal-lg",
        initialState: {
          isEdit,
          row,
          ownProperty: this.individualReturn.ownProperty,
          residency: this.individualReturn.residency,
          index,
          hasLossCarryForward: lossCarryForward?.length ? true : false,
        },
      }
    );
    this.scheduleModelRef.onHide.subscribe(() => {
      this.calculateWorksheetB();
    });
    this.scheduleModelRef.content?.addScheduleForm?.subscribe(
      (payload: any) => {
        this.modal?.hide();
        // console.log("*** Add Schedule ***", payload);
        if (payload?.type == "scheduleC") {
          this.addOtherIncomeTypeScheduleCDetail(payload.scheduleC);
        } else if (payload?.type == "scheduleE") {
          this.addOtherIncomeTypeScheduleEDetail(payload.scheduleE);
        } else if (payload?.type == "scheduleK1") {
          this.addOtherIncomeTypeScheduleK1Detail(payload.scheduleK1);
        } else if (payload?.type == "scheduleMisc") {
          this.addOtherIncomeTypeScheduleMiscDetail(payload.scheduleMisc);
        } else if (payload?.type == "lossCarryForward") {
          this.addLossCarryForward(payload.lossCarryForward);
        }
        this.calculateWorksheetB();
        this.cdref.detectChanges();
      }
    );
    this.scheduleModelRef.content?.updateScheduleForm?.subscribe(
      (payload: any) => {
        this.modal?.hide();
        if (payload?.type == "scheduleC") {
          this.updateOtherIncomeTypeScheduleCDetail(payload.scheduleC, index);
        } else if (payload?.type == "scheduleE") {
          this.updateOtherIncomeTypeScheduleEDetail(payload.scheduleE, index);
        } else if (payload?.type == "scheduleK1") {
          this.updateOtherIncomeTypeScheduleK1Detail(payload.scheduleK1, index);
        } else if (payload?.type == "scheduleMisc") {
          this.updateOtherIncomeTypeScheduleMiscDetail(
            payload.scheduleMisc,
            index
          );
        } else if (payload?.type == "lossCarryForward") {
          this.updateLossCarryForward(payload.lossCarryForward);
        }
        this.calculateWorksheetB();
        this.cdref.detectChanges();
      }
    );
  }

  removeSchedule(index: number, type: string) {
    if (type == "scheduleC") {
      this.getOtherIncomeTypeScheduleCDetail.removeAt(index);
    } else if (type == "scheduleE") {
      this.getOtherIncomeTypeScheduleEDetail.removeAt(index);
    } else if (type == "scheduleK1") {
      this.getOtherIncomeTypeScheduleK1Detail.removeAt(index);
    } else if (type == "scheduleMisc") {
      this.getOtherIncomeTypeScheduleMiscDetail.removeAt(index);
    } else if (type == "lossCarryForward") {
      this.getLossCarryForward.clear();
    }
    this.calculateWorksheetB();
  }

  openAdjustmentToIncome(row = {}, isEdit = false) {
    this.adjustmentIncomeModelRef = this.modal.show(
      IndividualFilingOhAdjustmentIncomeFormComponent,
      {
        class: "",
        initialState: {
          isEdit,
          row,
        },
      }
    );
    this.adjustmentIncomeModelRef.onHide.subscribe(() => {
      this.calculateAdjustments();
    });
    this.adjustmentIncomeModelRef.content?.addAdjustmentIncomeForm?.subscribe(
      (payload: any) => {
        this.modal?.hide();
        // console.log("*** Add Adjustment Income ***", payload);
        this.getAdjustmentToIncomeLine.push(this.formBuilder.group(payload));
        this.cdref.detectChanges();
      }
    );
    this.adjustmentIncomeModelRef.content?.updateAdjustmentIncomeForm?.subscribe(
      (payload: any) => {
        this.modal?.hide();
        // console.log("*** Update Adjustment Income ***", payload);
        this.cdref.detectChanges();
      }
    );
  }

  removeAdjustmentToIncome(index: number) {
    this.getAdjustmentToIncomeLine.removeAt(index);
    this.calculateAdjustments();
  }

  calculateScheduleC() {
    const scheduleHelper = new ScheduleHelper(
      this.paymentHelper,
      this.form.get("page2.worksheetB").value,
      "otherIncomeTypeScheduleCDetail",
      this.individualReturn.resident,
      this.config
    );

    this.form.get("page2.worksheetB.totalIncomeLossScheduleC").setValue(scheduleHelper.totalIncomeLoss);
    this.individualReturn.page2.worksheetB.totalIncomeLossScheduleC =
      scheduleHelper.totalIncomeLoss;

    this.form
      .get("page2.worksheetB.municipalityPercentageScheduleC")
      .setValue(scheduleHelper.municipalityPercentage);

    this.form
      .get("page2.worksheetB.municipalityTaxableIncomeScheduleC")
      .setValue(scheduleHelper.municipalityTaxableIncome);

    this.form
      .get("page2.worksheetB.taxCreditAllowedForTaxPaidToOtherCitiesScheduleC")
      .setValue(scheduleHelper.taxCreditAllowed);
  }

  calculateScheduleE() {
    const scheduleHelper = new ScheduleHelper(
      this.paymentHelper,
      this.form.get("page2.worksheetB").value,
      "otherIncomeTypeScheduleEDetail",
      this.individualReturn.resident,
      this.config
    );

    this.form
      .get("page2.worksheetB.totalIncomeLossScheduleE")
      .setValue(scheduleHelper.totalIncomeLoss);
    this.individualReturn.page2.worksheetB.totalIncomeLossScheduleE =
      scheduleHelper.totalIncomeLoss;

    this.form
      .get("page2.worksheetB.municipalityPercentageScheduleE")
      .setValue(scheduleHelper.municipalityPercentage);
    this.form
      .get("page2.worksheetB.municipalityTaxableIncomeScheduleE")
      .setValue(scheduleHelper.municipalityTaxableIncome);
    this.form
      .get("page2.worksheetB.taxCreditAllowedForTaxPaidToOtherCitiesScheduleE")
      .setValue(scheduleHelper.taxCreditAllowed);
  }

  calculateScheduleK1() {
    const scheduleHelper = new ScheduleHelper(
      this.paymentHelper,
      this.form.get("page2.worksheetB").value,
      "otherIncomeTypeScheduleK1Detail",
      this.individualReturn.resident,
      this.config
    );
    this.form
      .get("page2.worksheetB.totalIncomeLossScheduleK1")
      .setValue(scheduleHelper.totalIncomeLoss);
    this.individualReturn.page2.worksheetB.totalIncomeLossScheduleK1 =
      scheduleHelper.totalIncomeLoss;

    this.form
      .get("page2.worksheetB.municipalityPercentageScheduleK1")
      .setValue(scheduleHelper.municipalityPercentage);
    this.form
      .get("page2.worksheetB.municipalityTaxableIncomeScheduleK1")
      .setValue(scheduleHelper.municipalityTaxableIncome);
    this.form
      .get("page2.worksheetB.taxCreditAllowedForTaxPaidToOtherCitiesScheduleK1")
      .setValue(scheduleHelper.taxCreditAllowed);
  }

  calculateScheduleMisc() {
    const scheduleHelper = new ScheduleHelper(
      this.paymentHelper,
      this.form.get("page2.worksheetB").value,
      "otherIncomeTypeScheduleMiscDetail",
      this.individualReturn.resident,
      this.config
    );
    this.form
      .get("page2.worksheetB.totalIncomeLossMisc")
      .setValue(scheduleHelper.totalIncomeLoss);
    this.individualReturn.page2.worksheetB.totalIncomeLossMisc =
      scheduleHelper.totalIncomeLoss;

    this.form
      .get("page2.worksheetB.municipalityPercentageMisc")
      .setValue(scheduleHelper.municipalityPercentage);
    this.form
      .get("page2.worksheetB.municipalityTaxableIncomeMisc")
      .setValue(scheduleHelper.municipalityTaxableIncome);
    this.form
      .get("page2.worksheetB.taxCreditAllowedForTaxPaidToOtherCitiesMisc")
      .setValue(scheduleHelper.taxCreditAllowed);
  }

  calculateLossCarryForward(totalIncomeOrLoss) {
    const lossCarryForwardHelper = new LossCarryForwardHelper(
      this.individualReturn.taxYear,
      this.form.get("page2.worksheetB").value,
      totalIncomeOrLoss
    );
    this.form
      .get("page2.worksheetB.totalNetOperatingLossCarryForward")
      .setValue(lossCarryForwardHelper.netOperatingLossCarryForward);
    this.form
      .get(
        "page2.worksheetB.municipalityPercentageNetOperatingLossCarryForward"
      )
      .setValue(lossCarryForwardHelper.municipalityPercentage);
    this.form
      .get(
        "page2.worksheetB.municipalityTaxableIncomeNetOperatingLossCarryForward"
      )
      .setValue(lossCarryForwardHelper.municipalityLossCarryForward);
  }

  calculateWages() {

    const worksheetA = this.form.get("page2.worksheetA").value || {}
    this.individualReturn.page2 = this.individualReturn.page2 || {};
    this.individualReturn.page2.worksheetA = this.individualReturn.page2.worksheetA || {} as IWorksheetA;

    const worksheetAHelper = new WorksheetAHelper(
      this.paymentHelper,
      this.authService.config,
      this.individualReturn,
      this.individualReturn.resident,
      worksheetA,
      this.isPartYear()
    );

    if (worksheetAHelper.grandTotalFillingCityTaxWithheld > 0) {
      this.form.get("taxForms.w2.required").setValue(true);
    } else {
      this.form.get("taxForms.w2.required").setValue(false);
    }

    this.form.get("page2.worksheetA.grandTotalFillingCityTaxWithheld").setValue(worksheetAHelper.grandTotalFillingCityTaxWithheld);
    this.individualReturn.page2.worksheetA.grandTotalFillingCityTaxWithheld = worksheetAHelper.grandTotalFillingCityTaxWithheld;
    this.form.get("page2.worksheetA.grandTotalOtherCityTaxWithheld").setValue(worksheetAHelper.grandTotalOtherCityTaxWithheld);

    this.individualReturn.page2.worksheetA.grandTotalOtherCityTaxWithheld = worksheetAHelper.grandTotalOtherCityTaxWithheld;

    this.form.get("page2.worksheetA.grandTotalTaxCreditAllowableOtherCities").setValue(worksheetAHelper.grandTotalTaxCreditAllowableOtherCities);

    this.individualReturn.page2.worksheetA.grandTotalTaxCreditAllowableOtherCities = worksheetAHelper.grandTotalTaxCreditAllowableOtherCities;

    this.form.get("page2.worksheetA.grandTotalWages").setValue(worksheetAHelper.grandTotalWages);

    this.individualReturn.page2.worksheetA.grandTotalWages = worksheetAHelper.grandTotalWages;

    this.form.get("page2.worksheetA.grandTotalScheduleTaxableWages").setValue(worksheetAHelper.grandTotalScheduleTaxableWages);
    this.individualReturn.page2.worksheetA.grandTotalScheduleTaxableWages =
      worksheetAHelper.grandTotalScheduleTaxableWages;

    this.form.get("page2.worksheetA.grandTotalBox1Wages").setValue(worksheetAHelper.grandTotalBox1Wages);
    this.individualReturn.page2.worksheetA.grandTotalBox1Wages =
      worksheetAHelper.grandTotalBox1Wages;
  }

  calculateAdjustments() {
    this.individualReturn.page2 = this.individualReturn.page2 || {};
    const worksheetCHelper = new WorksheetCHelper(this.paymentHelper, this.form.get("page2.worksheetC").value);
    this.form.get("page2.worksheetC.totalColumn1Adjustment").setValue(worksheetCHelper.totalAdjustmentToIncome);
    this.individualReturn.page2.worksheetC = this.individualReturn.page2.worksheetC || {};
    this.individualReturn.page2.worksheetC.totalColumn1Adjustment = worksheetCHelper.totalAdjustmentToIncome;
  }

  async next() {
    const path = `/individual/filings/oh/${this.individualService.taxYear}/page2`;

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      await this.router.navigate([path]);
    }

    if (this.form.valid) {
      const payload = this.resetAmounts(this.form.getRawValue());

      if (payload?.page2?.noTaxableIncome) {
        payload.taxForms.w2.required = false;
        payload.taxForms.federal.required = false;
      }

      const ir = new OhIndividualReturnHelper(
        this.paymentHelper,
        this.authService.config,
        payload
      );
      try {
        await this.individualService.updateReturn(this.city.id, ir.irForm);
        await this.router.navigate([path]);
      } catch (error) {
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  async back() {
    const path = `/individual/filings/oh/${this.individualService.taxYear}/start`;

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      await this.router.navigate([path]);
    }

    const payload = this.form.getRawValue();
    try {
      await this.individualService.updateReturn(this.city.id, payload);
      await this.router.navigate([path]);
    } catch (e) {
    }
  }

  resetAmounts(payload: any) {
    //line 1
    payload.wagesSalariesTips = null;

    //line 2
    payload.otherIncome = null;

    //line 3
    payload.taxableIncome = null;

    //line 4
    payload.adjustments = null;

    //line 5
    payload.totalIncome = null;

    //line 6
    payload.multiplyTaxableIncome = null;

    //line 7 a
    payload.grandTotalFillingCityTaxWithheld = null;

    //line 7 b
    payload.grandTotalTaxCreditAllowableOtherCities = null;

    payload.totalTaxCreditAllowedForTaxPaidToOtherCities = null;

    //line 7 d
    payload.totalCreditsAllowable = null;

    //line 8 taxDue
    payload.taxDue = null;

    //line 9 Estimated Tax Paid  Prior year overpayment
    payload.estimatedTaxPaidAndPriorYearOverpayment = null;

    //line 10 totalDueAfterPayments : 7,
    payload.totalDueAfterPayments = null;

    payload.lateFee = null;

    payload.penalty = null;

    payload.interest = null;

    payload.totalPenaltyAndInterest = null;

    //line 12
    payload.totalAmountDue = null;

    //Line 17
    payload.estimatedIncomeTax = null;

    //Line 18
    payload.lessExpectedTaxCredits = null;

    //Line 19 a
    payload.netTaxDue = null;

    //Line 20
    payload.amountDueWithThisDeclaration = null;
    payload.amountDueWithThisDeclarationMinimum = null;
    //Line 21 totalOfThisPayment
    payload.totalOfThisPayment = null;
    return payload;
  }

  isPartYear() {
    const residency = this.form.get("residency").value;
    const partYearToDate = this.form.get("partYearToDate").value;
    const partYearFromDate = this.form.get("partYearToDate").value;
    if (residency === "P") return true;
    return !!(partYearToDate || partYearFromDate);
  }

  getQualifyingWages(wage: any): number {
    //wage.multipleLocations
    const box1Wages = this.paymentHelper.decimalValue(wage.box1Wages);
    const totalBox5Wages = this.paymentHelper.decimalValue(wage.totalBox5Wages);
    let wageAmount = totalBox5Wages === 0 ? box1Wages : totalBox5Wages;
    const sumAmounts = this.paymentHelper.sum(wage?.w2LocalityInfo, "wagesBox18");
    return this.isPartYear() ? sumAmounts : wageAmount > sumAmounts ? wageAmount : sumAmounts;
  }

  getBoolean(any) {
    return (
      any === true ||
      any === "true" ||
      any === "TRUE" ||
      any === "X" ||
      any === "x" ||
      any === 1 ||
      any === "1" ||
      any === "Y"
    );
  }

  formatEmail(email: string) {
    if (!email) return null;
    return email.replace(/(?<=^.)[^@]*|(?<=@.).*(?=\.[^.]+$)/gm, (match) =>
      "*".repeat(match.length)
    );
  }

  private async getReturn() {
    try {
      await this.individualService.getReturn(this.authService.organization.id, "OH")
      this.patchForm();
    } catch (error) {

    }
  }

  private patchForm() {
    this.individualReturn = this.individualService.individualReturn;
    this.form.patchValue(this.individualReturn);
    this.addWorksheetA();
    this.addWorksheetB();
    this.addWorksheetC();
    this.checkAdjustments();
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      this.isDisabled$.next(true)
      this.form.disable();
    }
  }
}
