import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {FormatService} from '../../services/format.service';
import {AccountNumberValidator} from '../../validators/account-number.validator';
import {RoutingNumberValidator} from '../../validators/routing-number.validator';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'city-tax-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentMethodsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() paymentService: any;
  @Input() config: any;
  @Input() state$: any;
  public isBankTab = false;
  public isCreditCardTab = false;
  public type = "ach";
  public stripe: any;
  @Input() public organization: any;
  @Output() onSubmit = new EventEmitter<void>();
  @Output() onCancel = new EventEmitter<void>();
  @Output() onSelectPaymentMethod = new EventEmitter<string>();
  @Input() paymentMethods$: Observable<string[]>;
  paymentMethods: string[] = [];
  private paymentMethodsSubscription: Subscription;
  public returnCheckFee: string;
  public hadReturnCheckFee: boolean = false;
  public payment$: any;
  public payment: any;
  public data: any;
  public defaultMethod: any;
  public hasAch: boolean = false;
  public hasCC: boolean = false;
  public accountType = [
    {label: "Payment.checking", value: "C"},
    {label: "Payment.savings", value: "S"},
  ];
  private client_secret: string;

  constructor(
    private cd: ChangeDetectorRef,
    private formatService: FormatService,
  ) {

  }

  async ngOnInit() {
    this.state$.subscribe(async data => {
      if (data && data.paymentMethods) {
        this.defaultMethod = data.paymentMethods.find(method => method.isDefault);
      }
      await this.handleDefaultMethod();
    });

    this.hadReturnCheckFee = !!this.config?.returnCheckFee;
    this.payment$ = this.paymentService.payment$;

    this.payment$?.subscribe(async payment => {

      if (payment && payment.pm) {
        await this.setPaymentMethodType('pm');
      }
      await this.loadStripeElement();
      this.payment = payment;
    });

    if (this.hadReturnCheckFee) {
      const fee = this.formatService.currencyFormat(this.config.returnCheckFee);
      this.returnCheckFee = `{ returnCheckFee:'${fee}' }`
    }

    const paymentMethods = this.organization?.paymentMethods;
    if (paymentMethods) {
      this.setPaymentMethodFlags(paymentMethods);
    }
    this.paymentMethodsSubscription = this.paymentMethods$.subscribe(async methods => {
      if(methods){
        this.paymentMethods = methods;  
        this.setPaymentMethodFlags(methods);
        await this.handleDefaultMethod();
        this.cd.detectChanges()
      }
    });
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.paymentMethodsSubscription) {
      this.paymentMethodsSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {

  }

  private async handleDefaultMethod() {
    if (!this.defaultMethod?.id || (this.defaultMethod.type === 'card' && !this.hasCC)) {
      await this.showAddBankAccountMethod();
    } else {
      this.selectPaymentMethod(this.defaultMethod);
    }
  }

  private setPaymentMethodFlags(paymentMethods) {
    this.hasAch = paymentMethods.includes('ach');
    this.hasCC = paymentMethods.includes('card');
  }

  public async showAddCardMethod() {
    this.isBankTab = false;
    this.isCreditCardTab = true;
    this.cd.detectChanges();
    this.form.get('pm').setValue(null, {emitEvent: false});
    this.onSelectPaymentMethod.emit('card');
    await this.setPaymentMethodType('card');
  }

  public async showAddBankAccountMethod() {
    this.isBankTab = true;
    this.isCreditCardTab = false;
    this.form.get('pm').setValue(null, {emitEvent: false});
    this.onSelectPaymentMethod.emit('ach');
    this.removeBankDetails();
    const element = document.getElementById('payment-element');
    if (element) element.remove()
    await this.setPaymentMethodType('ach');
  }

  public removeBankDetails() {
    this.form.patchValue({
      addAsPaymentMethod: null,
      bank: {
        accountType: null,
        accountNumber: null,
        routingNumber: null
      },
      confirmAccountNumber: null,
      confirmRoutingNumber: null,
      pm: null,
    });
  }

  public patchFormValue(paymentMethod: any) {
    this.form.get('pm').setValue(paymentMethod, {emitEvent: false});
  }

  public async selectPaymentMethod(paymentMethod: any) {
    this.isCreditCardTab = false;
    this.isBankTab = false;
    this.patchFormValue(paymentMethod);
    this.onSelectPaymentMethod.emit(paymentMethod.type);
    const element = document.getElementById('payment-element');
    if (element) element.remove()
    await this.setPaymentMethodType('pm');
    this.cd.detectChanges();
  }

  public updateFormStatus() {
    this.form.markAllAsTouched();
    this.form.markAsDirty()
    this.form.updateValueAndValidity();
    this.cd.detectChanges();
  }

  async setPaymentMethodType(type: string) {
    // console.log('setPaymentMethodType', this.form.getRawValue().type, type);
    if (this.form.getRawValue().type !== type) {
      this.form.get('type').setValue(type);
      this.type = type;
      const bank = this.form.get("bank") as FormGroup;
      const accountNumber = bank.get("accountNumber");
      const routingNumber = bank.get("routingNumber");
      const accountType = bank.get("accountType");
      const confirmRoutingNumber = this.form.get("confirmRoutingNumber");
      const confirmAccountNumber = this.form.get("confirmAccountNumber");

      if (type === "ach") {
        accountNumber.setValidators([Validators.required, AccountNumberValidator()]);
        routingNumber.setValidators([Validators.required, RoutingNumberValidator()]);
        accountType.setValidators([Validators.required]);
        confirmRoutingNumber.setValidators([Validators.required]);
        confirmAccountNumber.setValidators([Validators.required]);

        accountNumber.updateValueAndValidity();
        routingNumber.updateValueAndValidity();
        accountType.updateValueAndValidity();
        confirmRoutingNumber.updateValueAndValidity();
        confirmAccountNumber.updateValueAndValidity();
      } else {
        this.form.get('bank').reset()
        this.form.get('confirmRoutingNumber').reset()
        this.form.get('confirmAccountNumber').reset()
        accountNumber.clearValidators();
        accountNumber.updateValueAndValidity();
        routingNumber.clearValidators();
        routingNumber.updateValueAndValidity();
        accountType.clearValidators();
        accountType.updateValueAndValidity();
        confirmRoutingNumber.clearValidators();
        confirmRoutingNumber.updateValueAndValidity();
        confirmAccountNumber.clearValidators();
        confirmAccountNumber.updateValueAndValidity();
      }
    }
    if (type === "card") {
      console.log('card - ************** Stripe Element loaded **************');
      return this.loadStripeElement();
    }
    return;
  }

  async loadStripeElement() {

    const element = document.getElementById('payment-element');

    if (!this.paymentService.payment || !this.isCreditCardTab) return;

    if (this.client_secret === this.paymentService.payment.intent.client_secret && element?.innerHTML?.length) return;

    this.client_secret = this.paymentService.client_secret;

    try {

      if (!this.stripe) {
        await this.paymentService.loadStripe();
        this.stripe = this.paymentService.stripe;
      }

      if (this.paymentService.elements) {
        console.log('************** Stripe Element unmounted **************');
        const paymentElement = this.paymentService.elements.getElement('payment');
        if (paymentElement) {
          paymentElement.unmount();
          paymentElement.destroy();
          this.paymentService.elements = null;
        }
      }

      this.paymentService.elements = this.stripe.elements({
        appearance: {
          theme: "stripe",
          variables: {
            borderRadius: "4px",
          },
        },
        locale: "en",
        clientSecret: this.paymentService.payment.intent.client_secret,
      });

      const paymentElement = this.paymentService.elements.create("payment");

      paymentElement.on('change', (event) => {
        this.cd.detectChanges();
      });
      paymentElement.mount("#payment-element");

      this.cd.detectChanges();

      console.log('************** Stripe Element loaded and remomunted **************');

    } catch (error) {
      console.error('Error loading Stripe:', error);
    }
  }

  formatInteger(any) {
    return !any ? null : any.toString().replace(/\D/g, '') === '' ? 0 : any.toString().replace(/\D/g, '');
  }

  stripControl(control) {
    control.updateValueAndValidity();
    const value = this.formatInteger(control.value);
    const accountNumberCtrl = this.form.get("bank").get("accountNumber");
    if (value === "272480678") {
      accountNumberCtrl.removeValidators([Validators.minLength(8)]);
      accountNumberCtrl.addValidators([Validators.minLength(12)]);
    } else {
      if (accountNumberCtrl.value !== "272480678") {
        if (accountNumberCtrl.hasValidator(Validators.min(12))) {
          accountNumberCtrl.removeValidators([Validators.minLength(12)]);
          accountNumberCtrl.addValidators([Validators.minLength(8)]);
        }
      }
    }
    accountNumberCtrl.updateValueAndValidity();
    control.setValue(value);
    control.markAsTouched();
    control.updateValueAndValidity();
    this.cd.detectChanges();
  }

  onlyNumberKey(event: any) {
    var keyCode = event.which ? event.which : event.keyCode;
    return keyCode > 31 && (keyCode < 48 || keyCode > 57) ? false : true;
  }

  displayCounter(value, name) {
    this.form.get(name).setValue(value);
  }

  cancel() {
    this.isBankTab = false;
    this.isCreditCardTab = false;
    this.onCancel.emit();
  }

  submit() {
    this.onSubmit.emit();
  }

  public getBrandLogo(brand: string): string {
    const logos: { [key: string]: string } = {
      visa: './global/visa.png',
      mastercard: './global/masterCard.png',
      amex: './global/americanExpress.png',
      ideal: './global/ideal.png',
      discovery: './global/discovery.png',
      dinersclub: './global/dinersClub.png',
      paypal: './global/paypal.png',
      stripe: './global/stripe.png',
      ach: './global/bank.png',
      bank: './global/bank.png',
      discover: './global/discover.png',
    };
    return logos[brand.toLowerCase()] || './global/default-logo.png';
  }
}
