import {ChangeDetectorRef, Component, Injector, OnInit} from "@angular/core";
import {HexaDetailComponent} from "@hexalang/ui/core";
import {DateFormControl, EPermission, PaymentHelper} from "@city-tax/shared";
import {BehaviorSubject, combineLatest, map, Observable, startWith} from "rxjs";

import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {NgxPermissionsService} from "ngx-permissions";
import {FormArray, FormBuilder, FormGroup, ValidationErrors} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {MiIndividualReturnHelper} from "../../../../../helpers/mi/mi-individual-return.helper";
import {
  IndividualFilingMIExcludedTaxWithheldFormComponent
} from "../excluded-tax-withheld-form/individual-filing-mi-excluded-tax-withheld-form.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {IndividualService} from "../../../../../services/individual.service";


@Component({
  selector: "city-tax-feature-individual-filing-mi-page2",
  templateUrl: "./individual-filing-mi-page2.component.html",
  styleUrls: ["./individual-filing-mi-page2.component.scss"],
})
export class IndividualFilingMiPage2Component
  extends HexaDetailComponent
  implements OnInit {
  public form: FormGroup;
  public title$;
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public whoResided = ["T", "S", "B"];
  public month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public residedFromDay$ = new BehaviorSubject<any>(null);
  public residedToDay$ = new BehaviorSubject<any>(null);
  public taxYear = this.individualService.taxYear;
  public ePermission = EPermission;
  public isLoading$ = this.individualService.isLoading$;
  public isUploading$ = this.individualService.isUploading$;
  public error = null;
  public taxDocument = this.authService.organization.taxDocument;
  public maxDDate = new Date();

  public city = this.authService.organization;
  public individualReturn$ = this.individualService.individualReturn$;
  public hasPermission$: Observable<boolean>;
  public individualReturn = this.individualService.individualReturn;
  public excludedModelRef: BsModalRef;

  constructor(
    injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private individualService: IndividualService,
    private authService: AuthService,
    public toastrService: ToastrService,
    public permissionService: NgxPermissionsService,
    private formBuilder: FormBuilder,
    private paymentHelper: PaymentHelper,
    private cdRef: ChangeDetectorRef,
    private modal: BsModalService
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.initFormUpdate();
    if (this.individualService.individualReturn) {
      this.patchFormUpdate();
    } else {
      await this.getReturn();
    }
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      this.isDisabled$.next(true)
      this.form.disable();
    }
  }

  get dependents(): FormArray {
    return this.form.get("dependents") as FormArray;
  }

  get getScheduleOfWages(): FormArray {
    return this.form.get("wagesSalariesTips.scheduleOfWages") as FormArray;
  }

  get addressWhereTaxpayerResided(): FormArray {
    return this.form.get("addressWhereTaxpayerResided") as FormArray;
  }

  get filing() {
    return this.individualService.individualReturn?.filing;
  }

  addDependents() {
    this.individualReturn?.irs1040CfPage2?.dependents?.map((u) => {
      this.dependents.push(this.formBuilder.group(u));
    });
  }

  removeDependent(index) {
    this.dependents.removeAt(index);
    this.form.updateValueAndValidity();
    this.cdRef.detectChanges();
  }

  public trackByFn = (index, item) => {
    return index;
  };

  removeWage(index) {
    this.getScheduleOfWages.removeAt(index);
    console.log("************  removeWage *********** ");

    if (this.getScheduleOfWages?.length === 0) {
      this.form.get('taxForms.w2.required').setValue(false);
    }

    this.calculateExcludibleWages()
    this.form.updateValueAndValidity();
    this.cdRef.detectChanges();
  }

  addScheduleOfWages(ir) {

    // Function to create a FormGroup for an address
    const createAddressGroup = (address) => {
      return this.formBuilder.group({
        addressLine1: [address?.addressLine1],
        addressLine2: [address?.addressLine2],
        aptNumber: [address?.aptNumber],
        city: [address?.city],
        state: [address?.state],
        postalCode: [address?.postalCode],
      });
    };

    // Function to handle the creation of FormGroups for wages
    const createWagesGroup = (u, type) => {
      u.w2LocalityInfo = u.w2LocalityInfo == null ? [] : [].concat(u.w2LocalityInfo);

      if (u.w2LocalityInfo.length === 0) {
        u.w2LocalityInfo.push({
          taxWithheldLocalityName: null,
          localityTaxRate: null,
          wagesBox18: null,
          taxWithheldBox19: null,
          creditForTaxesPaid: null,
        });
      }

      const w2LocalityInfoArray = this.formBuilder.array(u.w2LocalityInfo?.map(l => this.formBuilder.group(l)) || []);

      const wagesGroup = this.formBuilder.group({
        ...u,
        type: type,
        employersAddress: createAddressGroup(u.employersAddress),
        w2LocalityInfo: w2LocalityInfoArray
      });
      return wagesGroup;
    };

    // Function to process each set of wages
    const processWages = (wagesArray, type) => {
      wagesArray?.forEach(u => {
        const wageFormGroup = createWagesGroup(u, type);
        this.getScheduleOfWages.push(wageFormGroup);
      });
    };

    // Process spouse wages
    processWages(ir?.wagesSalariesTips?.spouseScheduleOfWages, 'S');

    // Process taxpayer wages
    processWages(ir?.wagesSalariesTips?.taxpayerScheduleOfWages, 'T');

    if (ir?.wagesSalariesTips?.taxpayerScheduleOfWages?.length > 0 || ir?.wagesSalariesTips?.spouseScheduleOfWages?.length > 0) {
      this.form.get('taxForms.w2.required').setValue(true);
    } else {
      this.form.get('taxForms.w2.required').setValue(true);
    }
  }

  addAddressFormGroup(a) {
    return this.formBuilder.group({
      addressLine1: a?.addressLine1,
      addressLine2: a?.addressLine2,
      aptNumber: a?.aptNumber,
      city: a?.city,
      state: a?.state,
      postalCode: a?.postalCode,
    });
  }

  addAddress() {
    const a: any = {
      residedIndicate: "",
      residedAddress: "",
      residedFromMonth: "",
      residedFromDay: "",
      residedToMonth: "",
      residedToDay: "",
    };
    this.addressWhereTaxpayerResided.push(this.formBuilder.group(a));
  }

  removeAddress(index) {
    this.addressWhereTaxpayerResided.removeAt(index);
  }

  addAddresses() {
    this.individualReturn?.irs1040CfPage2?.addressWhereTaxpayerResided?.map((u) => {
      this.addressWhereTaxpayerResided.push(this.formBuilder.group(u));
    });
  }

  getDays(month) {
    month -= 1;
    const date = new Date(+this.individualReturn.taxYear, month, 1);
    const days = [];
    while (date.getMonth() === month) {
      days.push(date.getDate());
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  getFromDays(index) {
    const days = this.getDays(
      this.form.get("addressWhereTaxpayerResided").value[index].residedFromMonth
    );
    this.residedFromDay$.next(days);
  }

  getToDays(index) {
    const days = this.getDays(
      this.form.get("addressWhereTaxpayerResided").value[index].residedToMonth
    );
    this.residedToDay$.next(days);
  }

  totalDeductions() {
    const ir = new MiIndividualReturnHelper(
      this.paymentHelper,
      this.authService.config,
      this.form.getRawValue()
    );
    this.form.get("totalDeductions").setValue(ir.totalDeductions);
    this.enableDisableCheckboxes();
  }

  calculateExcludibleWages() {
    const ir = new MiIndividualReturnHelper(this.paymentHelper, this.authService.config, this.form.getRawValue());
    const wagesSalariesTipsCtl = this.form.get("wagesSalariesTips");
    wagesSalariesTipsCtl.get("grandTotalScheduleExcludibleWages").setValue(ir.grandTotalScheduleExcludibleWages);
    wagesSalariesTipsCtl.get("grandTotalScheduleTaxWithheld").setValue(ir.grandTotalScheduleTaxWithheld);
    wagesSalariesTipsCtl.get("grandTotalScheduleWages").setValue(ir.grandTotalScheduleWages);
    this.calculate();
    wagesSalariesTipsCtl.updateValueAndValidity();
    this.form.updateValueAndValidity();
    this.cdRef.detectChanges();
  }

  getPayload() {
    const payload = this.form.getRawValue();
    payload.irs1040CfPage2.dependents = payload.dependents;
    delete payload.dependents;
    payload.irs1040CfPage2.addressWhereTaxpayerResided = payload.addressWhereTaxpayerResided;
    delete payload.addressWhereTaxpayerResided;

    const miHelper = new MiIndividualReturnHelper(
      this.paymentHelper,
      this.authService.config,
      payload
    );

    payload.wagesSalariesTips = miHelper.wagesSalariesTips;
    payload.taxWithheldByEmployer = payload.wagesSalariesTips.grandTotalScheduleTaxWithheld;
    //grandTotalCredit.creditForTaxPaidAnotherCity
    delete payload.wagesSalariesTips?.scheduleOfWages;
    return payload;
  }

  getFormValidationErrors(form) {
    if (form && form.controls) {
      Object.keys(form.controls).forEach((key) => {
        const control = form.get(key);
        if (control.controls) {
          this.getFormValidationErrors(this.form.get(key));
        }
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            console.log(
              "Key control: " +
              key +
              ", keyError: " +
              keyError +
              ", err value: ",
              controlErrors[keyError]
            );
          });
        }
      });
    }
  }

  async getReturn() {
    try {
      await this.individualService.getReturn(this.authService.organization.id, this.authService.config?.state);
      this.patchFormUpdate();
    } catch (error) {

    }
  }

  changeType(type: any, index: any) {
    if (type == "S") {
      this.getScheduleOfWages
        .at(index)
        .get("ssn")
        ?.setValue(this.individualService.individualReturn?.taxpayer?.ssn);
    } else {
      this.getScheduleOfWages
        .at(index)
        .get("ssn")
        ?.setValue(this.individualService.individualReturn?.spouse?.ssn);
    }
  }

  openExcludedTaxWithheld(row = {}, isEdit = false, index?: any) {
    this.excludedModelRef = this.modal.show(
      IndividualFilingMIExcludedTaxWithheldFormComponent,
      {
        class: "modal-dialog modal-lg",
        initialState: {
          isEdit,
          row,
          index,
        },
      }
    );
    this.excludedModelRef.onHide.subscribe(() => {

    });

    this.excludedModelRef.content.addW2Form.subscribe((wage: any) => {
      // console.log("************  addForm *********** ", wage);
      const w2LocalityInfo = this.formBuilder.array([]) as FormArray;
      wage.w2LocalityInfo.forEach((l) => {
        const locality = this.formBuilder.group(l);
        w2LocalityInfo.push(locality);
      });

      if (w2LocalityInfo.length === 0) {
        w2LocalityInfo.push({
          taxWithheldLocalityName: null,
          localityTaxRate: null,
          wagesBox18: null,
          taxWithheldBox19: null,
          creditForTaxesPaid: null,
        });
      }

      wage.w2LocalityInfo = w2LocalityInfo;
      this.getScheduleOfWages.push(this.formBuilder.group(wage));
      if (this.getScheduleOfWages?.length > 0) {
        this.form.get('taxForms.w2.required').setValue(true);
      }
      this.form.updateValueAndValidity();
      this.calculateExcludibleWages()
      this.cdRef.detectChanges();
      this.modal.hide();
    });

    this.excludedModelRef.content.updateW2Form.subscribe((wage: any) => {

      // Assuming `getScheduleOfWages` returns a FormArray
      let scheduleOfWagesArray = this.getScheduleOfWages as FormArray;
      let wageFormGroup = scheduleOfWagesArray.at(index) as FormGroup;

      // Patch the values for the form group excluding w2LocalityInfo
      let {w2LocalityInfo, ...wageInfo} = wage;

      wageFormGroup.patchValue(wageInfo);

      // Assuming w2LocalityInfo is the FormArray within the wage form group
      let w2LocalityInfoArray = wageFormGroup.get('w2LocalityInfo') as FormArray;

      if (!w2LocalityInfoArray) {
        w2LocalityInfoArray = this.formBuilder.array([]);
        wageFormGroup.setControl('w2LocalityInfo', w2LocalityInfoArray);
      } else {
        // If it exists, clear the existing content
        w2LocalityInfoArray.clear();
      }

      w2LocalityInfoArray?.clear();

      if (wage.w2LocalityInfo.length === 0) {
        wage.w2LocalityInfo.push({
          taxWithheldLocalityName: null,
          localityTaxRate: null,
          wagesBox18: null,
          taxWithheldBox19: null,
          creditForTaxesPaid: null,
        });
      }

      // Now set the value for each item in the w2LocalityInfo
      wage.w2LocalityInfo.forEach((localityInfo: any) => {
        // Create a new FormGroup for the localityInfo
        let localityFormGroup = this.formBuilder.group({
          taxWithheldLocalityName: [localityInfo.taxWithheldLocalityName],
          localityTaxRate: [localityInfo.localityTaxRate],
          wagesBox18: [localityInfo.wagesBox18],
          taxWithheldBox19: [localityInfo.taxWithheldBox19],
          creditForTaxesPaid: [localityInfo.creditForTaxesPaid]
        });
        // Add the new FormGroup to the FormArray
        w2LocalityInfoArray.push(localityFormGroup);
      });

      // Other operations
      this.form.updateValueAndValidity();
      this.calculateExcludibleWages();
      this.cdRef.detectChanges();
      this.modal.hide();
    });

  }

  calculateExemptionsSubscribe() {
    combineLatest([
      this.form.get("irs1040CfPage2.exemptionsYouRegular").valueChanges.pipe(startWith(this.form.get("irs1040CfPage2.exemptionsYouRegular").value)),
      this.form.get("irs1040CfPage2.exemptionsYouOver65").valueChanges.pipe(startWith(this.form.get("irs1040CfPage2.exemptionsYouOver65").value)),
      this.form.get("irs1040CfPage2.exemptionsYouBlind").valueChanges.pipe(startWith(this.form.get("irs1040CfPage2.exemptionsYouBlind").value)),
      this.form.get("irs1040CfPage2.exemptionsYouDeaf").valueChanges.pipe(startWith(this.form.get("irs1040CfPage2.exemptionsYouDeaf").value)),
      this.form.get("irs1040CfPage2.exemptionsYouDisabled").valueChanges.pipe(startWith(this.form.get("irs1040CfPage2.exemptionsYouDisabled").value)),
      this.form.get("irs1040CfPage2.exemptionsSpouseRegular").valueChanges.pipe(startWith(this.form.get("irs1040CfPage2.exemptionsSpouseRegular").value)),
      this.form.get("irs1040CfPage2.exemptionsSpouseOver65").valueChanges.pipe(startWith(this.form.get("irs1040CfPage2.exemptionsSpouseOver65").value)),
      this.form.get("irs1040CfPage2.exemptionsSpouseBlind").valueChanges.pipe(startWith(this.form.get("irs1040CfPage2.exemptionsSpouseBlind").value)),
      this.form.get("irs1040CfPage2.exemptionsSpouseDeaf").valueChanges.pipe(startWith(this.form.get("irs1040CfPage2.exemptionsSpouseDeaf").value)),
      this.form.get("irs1040CfPage2.claimedDependent").valueChanges.pipe(startWith(this.form.get("irs1040CfPage2.claimedDependent").value)),
      this.form.get("irs1040CfPage2.numDependentChild").valueChanges.pipe(startWith(this.form.get("irs1040CfPage2.numDependentChild").value)),
      this.form.get("dependents").valueChanges.pipe(startWith(this.form.get("dependents").value)),
    ]).pipe(
      map(() => {
        return this.form.get("irs1040CfPage2").value;
      })
    ).subscribe((irs1040CfPage2) => {
      this.calculateExemptions();
    });
  }

  onClaimedDependent() {
    if (!this.form.value.irs1040CfPage2.claimedDependent) {
      this.form.get("irs1040CfPage2.numDependentChild").setValue(0)
    }
  }

  calculateExemptions() {
    let irs1040CfPage2 = this.form.get("irs1040CfPage2").getRawValue();
    let total = 0;
    total += irs1040CfPage2?.numDependentChild || 0;
    total += (irs1040CfPage2?.exemptionsYouRegular ? 1 : 0) +
      (irs1040CfPage2?.exemptionsYouOver65 ? 1 : 0) +
      (irs1040CfPage2?.exemptionsYouBlind ? 1 : 0) +
      (irs1040CfPage2?.exemptionsYouDeaf ? 1 : 0) +
      (irs1040CfPage2?.exemptionsYouDisabled ? 1 : 0) +
      (irs1040CfPage2?.exemptionsSpouseRegular ? 1 : 0) +
      (irs1040CfPage2?.exemptionsSpouseOver65 ? 1 : 0) +
      (irs1040CfPage2?.exemptionsSpouseBlind ? 1 : 0) +
      (irs1040CfPage2?.exemptionsSpouseDeaf ? 1 : 0) +
      (irs1040CfPage2?.exemptionsSpouseDisabled ? 1 : 0);
    this.form.get("irs1040CfPage2.boxC").setValue(total);
  }

  checkExcludibleWages() {
    this.form.get("wagesSalariesTips.scheduleOfWages").valueChanges.subscribe((res: any) => {
      this.calculateExcludibleWages();
    });
  };

  private patchFormUpdate() {
    this.individualService.individualReturn.irs1040CfPage2 = this.individualService.individualReturn.irs1040CfPage2 || {};
    this.individualService.individualReturn.irs1040CfPage2.exemptionsYouDateOfBirth = this.individualService.individualReturn.taxpayer?.birthdate;
    this.individualService.individualReturn.irs1040CfPage2.exemptionsSpouseDateOfBirth = this.individualService.individualReturn.spouse?.birthdate;

    if (!this.individualService.individualReturn.irs1040CfPage2.exemptionsYouOver65 && !this.individualService.individualReturn.irs1040CfPage2.exemptionsYouBlind && !this.individualService.individualReturn.irs1040CfPage2.exemptionsYouDeaf && !this.individualService.individualReturn.irs1040CfPage2.exemptionsYouDisabled) {
      this.individualService.individualReturn.irs1040CfPage2.exemptionsYouRegular = true;
    }

    if (this.individualService.individualReturn.filing === 'S' || this.individualService.individualReturn.filing === 'MS') {
      this.individualService.individualReturn.irs1040CfPage2.exemptionsSpouseDateOfBirth = null;
      this.individualService.individualReturn.irs1040CfPage2.exemptionsSpouseRegular = null;
      this.individualService.individualReturn.irs1040CfPage2.exemptionsSpouseOver65 = null;
      this.individualService.individualReturn.irs1040CfPage2.exemptionsSpouseBlind = null;
      this.individualService.individualReturn.irs1040CfPage2.exemptionsSpouseDeaf = null;
      this.individualService.individualReturn.irs1040CfPage2.exemptionsSpouseDisabled = null;
    } else {
      if (!this.individualService.individualReturn.irs1040CfPage2.exemptionsSpouseOver65 && !this.individualService.individualReturn.irs1040CfPage2.exemptionsSpouseBlind && !this.individualService.individualReturn.irs1040CfPage2.exemptionsSpouseDeaf && !this.individualService.individualReturn.irs1040CfPage2.exemptionsSpouseDisabled) {
        this.individualService.individualReturn.irs1040CfPage2.exemptionsSpouseRegular = true;
      }
    }

    // console.log(this.individualService.individualReturn.residency, this.individualService.individualReturn.irs1040CfPage2);
    this.form.patchValue(this.individualService.individualReturn);

    this.addScheduleOfWages(this.individualService.individualReturn);
    this.addDependents();
    this.addAddresses();
    this.calculateExemptions();
    this.calculateExemptionsSubscribe();
    this.calculateExcludibleWages();
    // this.checkExcludibleWages();
    this.calculate();
    this.setCheckboxes()
    this.cdRef.detectChanges();
  }

  setCheckboxes() {
    const interest = this.checkInterest();
    const dividends = this.checkDividends();
    const businessIncome = this.checkBusinessIncome();
    const partnership = this.checkPartnership();
    const other = this.checkOther();
    const deductionsSchedule = this.checkDeductionsSchedule();
    this.setCheckbox('interestCb', interest);
    this.setCheckbox('dividendsCb', dividends);
    this.setCheckbox('businessIncomeCb', businessIncome);
    this.setCheckbox('partnershipCb', partnership);
    this.setCheckbox('otherCb', other);
    this.setCheckbox('deductionsScheduleCb', deductionsSchedule);
  }

  enableDisableCheckboxes() {
    const interest = this.checkInterest();
    const dividends = this.checkDividends();
    const businessIncome = this.checkBusinessIncome();
    const partnership = this.checkPartnership();
    const other = this.checkOther();
    const deductionsSchedule = this.checkDeductionsSchedule();
    this.enableDisableCheckbox('interestCb', interest);
    this.enableDisableCheckbox('dividendsCb', dividends);
    this.enableDisableCheckbox('businessIncomeCb', businessIncome);
    this.enableDisableCheckbox('partnershipCb', partnership);
    this.enableDisableCheckbox('otherCb', other);
    this.enableDisableCheckbox('deductionsScheduleCb', deductionsSchedule);
  }

  setCheckbox(controlName: string, value: boolean) {
    const control = this.form.get(controlName);
    control.setValue(value);
    this.enableDisableCheckbox(controlName, value);
  }

  enableDisableCheckbox(controlName: string, value: boolean) {
    const control = this.form.get(controlName);
    if (value) {
      control.disable();
    } else {
      control.enable();
    }
  }

  checkDeductionsSchedule() {
    const totalDeductions = this.paymentHelper.decimalValue(this.form.get("totalDeductions").value);
    return totalDeductions > 0;
  }

  checkInterest() {
    const interest = this.paymentHelper.decimalValue(this.form.get('taxableInterest.interestFromFederal').value);
    return interest > 0;
  }

  checkDividends() {
    const dividends = this.paymentHelper.decimalValue(this.form.get('ordinaryDividendsFederal.dividendIncomeFromFederalReturn').value);
    return dividends > 0;
  }

  checkBusinessIncome() {
    const businessIncome = this.paymentHelper.decimalValue(this.form.get('businessIncomeFederal').value);
    return businessIncome !== 0;
  }

  checkPartnership() {
    const partnership = this.paymentHelper.decimalValue(this.form.get('partnership.partnershipIncomeFederal').value);
    return partnership !== 0;
  }

  checkOther() {
    const taxableRefundsCreditsFederal = this.paymentHelper.decimalValue(this.form.get('taxableRefundsCreditsFederal').value);
    // console.log('taxableRefundsCreditsFederal', taxableRefundsCreditsFederal);
    if (taxableRefundsCreditsFederal > 0) return true;
    const alimonyReceivedFederal = this.paymentHelper.decimalValue(this.form.get('alimonyReceivedFederal').value);
    // console.log('alimonyReceivedFederal', alimonyReceivedFederal);
    if (alimonyReceivedFederal > 0) return true;
    const capitalGainsOrLosses = this.paymentHelper.decimalValue(this.form.get('capitalGainsOrLosses.totalCapitalGainsAndLossesFromFederalReturn').value);
    // console.log('capitalGainsOrLosses', capitalGainsOrLosses);
    if (capitalGainsOrLosses !== 0) return true;
    const otherGainsLoses = this.paymentHelper.decimalValue(this.form.get('otherGainsLoses.totalOtherGainsAndLosses').value);
    // console.log('otherGainsLoses', otherGainsLoses);
    if (otherGainsLoses !== 0) return true;
    const taxableIraDistributionsFederal = this.paymentHelper.decimalValue(this.form.get('taxableIraDistributions.taxableIraDistributionsFederal').value);
    // console.log('taxableIraDistributionsFederal', taxableIraDistributionsFederal);
    if (taxableIraDistributionsFederal > 0) return true;
    const taxablePensionDistributionsFederal = this.paymentHelper.decimalValue(this.form.get('taxablePensionDistributions.taxablePensionDistributionsFederal').value);
    // console.log('taxablePensionDistributionsFederal', taxablePensionDistributionsFederal);
    if (taxablePensionDistributionsFederal > 0) return true;
    const subchapterSCorporationDistributionsExclusions = this.paymentHelper.decimalValue(this.form.get('sCorporationDistributions.subchapterSCorporationDistributionsExclusions').value);
    // console.log('subchapterSCorporationDistributionsExclusions', subchapterSCorporationDistributionsExclusions);
    if (subchapterSCorporationDistributionsExclusions > 0) return true;
    const totalFarmIncomeNetProfit = this.paymentHelper.decimalValue(this.form.get('farmIncomeLoss.totalFarmIncomeNetProfit').value);
    // console.log('totalFarmIncomeNetProfit', totalFarmIncomeNetProfit);
    if (totalFarmIncomeNetProfit !== 0) return true;
    const unemploymentCompensationFederal = this.paymentHelper.decimalValue(this.form.get('unemploymentCompensationFederal').value);
    // console.log('unemploymentCompensationFederal', unemploymentCompensationFederal);
    if (unemploymentCompensationFederal > 0) return true;
    const socialSecurityFederal = this.paymentHelper.decimalValue(this.form.get('socialSecurityFederal').value);
    // console.log('socialSecurityFederal', socialSecurityFederal);
    if (socialSecurityFederal > 0) return true;

    const gamblingIncomeFederal = this.paymentHelper.decimalValue(this.form.get('gamblingIncome.gamblingIncomeFederal').value);
    if (gamblingIncomeFederal > 0) return true;

    const otherIncomeFederal = this.paymentHelper.decimalValue(this.form.get('otherIncome.otherIncomeFederal').value);
    // console.log('otherIncomeFederal', otherIncomeFederal);
    if (otherIncomeFederal > 0) return true;
    return false;
  }

  show(type: string) {
    if (type === 'wages') {
      return !!this.form.get("wagesSalariesTips.grandTotalScheduleWages").value;
    }
    return this.form.get(type).value;
  }

  showTotal() {
    return !!this.form.get("wagesSalariesTips.grandTotalScheduleWages").value || this.form.get('interestCb').value || this.form.get('dividendsCb').value || this.form.get('businessIncomeCb').value || this.form.get('partnershipCb').value || this.form.get('otherCb').value;
  }

  private initFormUpdate() {
    this.form = this.formBuilder.group({
      id: null,
      filing: null,
      eFileDeadline: null,
      taxpayer: this.formBuilder.group({
        address: this.formBuilder.group({
          addressLine1: null,
          addressLine2: null,
          aptNumber: null,
          city: null,
          state: null,
          postalCode: null,
        }),
        ssn: null,
        name: null,
        deceased: null,
        email: null,
        emailNotifications: null,
        birthdate: null,
        occupation: null,
        dateOfDeath: null,
        phone: null,
        individualId: null,
        older65: null,
        blind: null,
        regular: null,
        deaf: null,
        disabled: null,
      }),
      spouse: this.formBuilder.group({
        address: this.formBuilder.group({
          addressLine1: null,
          addressLine2: null,
          aptNumber: null,
          city: null,
          state: null,
          postalCode: null,
        }),
        ssn: null,
        name: null,
        deceased: null,
        birthdate: null,
        email: null,
        occupation: null,
        dateOfDeath: null,
        phone: null,
        individualId: null,
        older65: null,
        blind: null,
        regular: null,
        deaf: null,
        disabled: null,
      }),
      wagesSalariesTips: this.formBuilder.group({
        grandTotalScheduleWages: null,
        grandTotalScheduleExcludibleWages: null,
        grandTotalScheduleTaxableWages: null,
        grandTotalScheduleTaxWithheld: null,
        scheduleOfWages: this.formBuilder.array([]),
      }),
      //line 2
      taxableInterest: this.formBuilder.group({
        interestFromFederal: null,
        interestTotalExcludibleIncome: null,
        taxableInterestIncome: null,
      }),
      //line 3
      ordinaryDividendsFederal: this.formBuilder.group({
        dividendIncomeFromFederalReturn: null,
        dividendTotalExcludible: null,
        dividendTaxableIncome: null,
      }),
      //line 4
      taxableRefundsCreditsFederal: null,
      taxableRefundsCreditsAdjustment: null,

      //line5
      alimonyReceivedFederal: null,
      alimonyReceivedAdjustments: null,
      alimonyReceivedTax: null,
      alimonyDateOfDivorce: new DateFormControl(),

      //line 6
      businessIncomeFederal: null,
      businessIncomeAdjustments: null,
      businessIncomeTax: null,

      //line 7
      capitalGainsOrLosses: this.formBuilder.group({
        totalCapitalGainsAndLossesFromFederalReturn: null,
        totalExcludibleCapitalGains: null,
        taxableCapitalGainsAndLosses: null,
      }),

      //line 8
      otherGainsLoses: this.formBuilder.group({
        totalOtherGainsAndLosses: null,
        otherTotalExcludibleGainsAndLosses: null,
        otherTaxableGainsAndLosses: null,
      }),

      //line 9
      taxableIraDistributions: this.formBuilder.group({
        taxableIraDistributionsFederal: null,
        totalExcludibleIraDistributions: null,
        taxableIraDistributionsIncome: null,
      }),

      //line 10
      taxablePensionDistributions: this.formBuilder.group({
        taxablePensionDistributionsFederal: null,
        totalExcludiblePension: null,
        taxablePensionDistributionsIncome: null,
      }),

      //line 11
      rentalRealEstate: this.formBuilder.group({
        rentalRealEstateFederal: null,
        totalAdjustments: null,
        rentalRealEstateIncome: null,
      }),

      //line 12
      sCorporationDistributions: this.formBuilder.group({
        subchapterSCorporationDistributionsExclusions: null,
        subchapterSCorporationDistributionsIncome: null,
      }),

      //line 13
      farmIncomeLoss: this.formBuilder.group({
        totalFarmIncomeNetProfit: null,
        excludibleNetProfit: null,
        totalFarmIncomeLoss: null,
      }),

      // //line 14
      unemploymentCompensationFederal: null,
      unemploymentCompensationAdjustments: null,

      //line 15
      socialSecurityFederal: null,
      socialSecurityAdjustments: null,

      gamblingIncome: this.formBuilder.group({
        gamblingIncomeFederal: null,
        gamblingIncomeAdjustments: null,
        gamblingIncomeTax: null,
        gamblingIncomePartYearResident: null,
        gamblingIncomePartYearNonResident: null,
      }),

      partnership: this.formBuilder.group({
        partnershipIncomeFederal: null,
        partnershipIncomeAdjustments: null,
        partnershipIncomeTax: null,
        partnershipIncomePartYearResident: null,
        partnershipIncomePartYearNonResident: null,
      }),

      // //line 16
      otherIncome: this.formBuilder.group({
        otherIncomeFederal: null,
        otherIncomeExclusions: null,
        otherIncomeSubject: null,
      }),

      totalAdditionsFederal: null,
      totalIncomeFederal: null,

      irs1040CfPage2: this.formBuilder.group({
        boxA: null,
        dependents: null,
        claimedDependent: null,
        numDependentChild: null,
        boxB: null,
        boxC: null,

        addressWhereTaxpayerResided: null,
        exemptionsYouDateOfBirth: null,
        exemptionsYouRegular: null,
        exemptionsYouOver65: null,
        exemptionsYouBlind: null,
        exemptionsYouDeaf: null,
        exemptionsYouDisabled: null,

        exemptionsSpouseDateOfBirth: null,
        exemptionsSpouseRegular: null,
        exemptionsSpouseOver65: null,
        exemptionsSpouseBlind: null,
        exemptionsSpouseDeaf: null,
        exemptionsSpouseDisabled: null,

      }),
      totalDeductions: null,
      numberOfExemptions: null,
      totalIraDeduction: null,
      dependents: this.formBuilder.array([]),
      addressWhereTaxpayerResided: this.formBuilder.array([]),

      totalSelfEmployedSep: null,
      totalEmployeeBusinessExpense: null,
      totalMovingExpensesIntoCityAreaOnly: null,
      totalAlimonyPaidWithoutChildSupport: null,
      totalRenaissanceZoneDeduction: null,

      interestCb: null,
      dividendsCb: null,
      businessIncomeCb: null,
      partnershipCb: null,
      otherCb: null,
      deductionsScheduleCb: null,
      taxForms: this.formBuilder.group({
        w2: this.formBuilder.group({
          required: false,
          provided: null,
        }),
        otherCity: this.formBuilder.group({
          required: null,
          provided: null,
        }),
        federal: this.formBuilder.group({
          required: false,
          provided: null,
        }),
        city: this.formBuilder.group({
          type: null,
          attachment1: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_1: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_2: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_3: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_4: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment3: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment4: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment5: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment6: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment7: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment8: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment9: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment10: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment11: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment12: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment13: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment14: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment15: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment16: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment17: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment18: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment19: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment20: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment21: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment22: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          cfCov: this.formBuilder.group({
            required: false,
            provided: null,
          }),
        }),
      }),
    });
  }

  public calculate() {
    const ir = this.getPayload();
    const miHelper = new MiIndividualReturnHelper(
      this.paymentHelper,
      this.authService.config,
      ir
    );
    this.form.get("totalAdditionsFederal").setValue(miHelper.totalAdditionsFederal);
    this.form.get("totalIncomeFederal").setValue(miHelper.totalIncomeFederal);
    this.enableDisableCheckboxes();
  }

  public check(event: any, controlName: string) {
    // const isChecked = (event.target as HTMLInputElement).checked;
    // if (controlName.startsWith('exemptionsYou')) {
    //   if (controlName === 'exemptionsYouRegular') {
    //     if (isChecked) {
    //       this.form.get("irs1040CfPage2.exemptionsYouOver65").setValue(false);
    //       this.form.get("irs1040CfPage2.exemptionsYouBlind").setValue(false);
    //       this.form.get("irs1040CfPage2.exemptionsYouDeaf").setValue(false);
    //       this.form.get("irs1040CfPage2.exemptionsYouDisabled").setValue(false);
    //     }
    //   } else {
    //     if (isChecked) {
    //       this.form.get("irs1040CfPage2.exemptionsYouRegular").setValue(false);
    //     }
    //   }
    // }
    //
    // if (controlName.startsWith('exemptionsSpouse')) {
    //   if (controlName === 'exemptionsSpouseRegular') {
    //     if (isChecked) {
    //       this.form.get("irs1040CfPage2.exemptionsSpouseOver65").setValue(false);
    //       this.form.get("irs1040CfPage2.exemptionsSpouseBlind").setValue(false);
    //       this.form.get("irs1040CfPage2.exemptionsSpouseDeaf").setValue(false);
    //       this.form.get("irs1040CfPage2.exemptionsSpouseDisabled").setValue(false);
    //     }
    //   } else {
    //     if (isChecked) {
    //       this.form.get("irs1040CfPage2.exemptionsSpouseRegular").setValue(false);
    //     }
    //   }
    // }
  }

  async next() {
    let path = `/individual/filings/mi/${this.individualService.taxYear}/`;
    path += this.individualService.individualReturn.residency !== "P" ? "page1" : "tc";

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      await this.router.navigate([path]);
    } else {
      this.getFormValidationErrors(this.form);
      if (this.form.valid) {
        const ir = this.getPayload();
        const miHelper = new MiIndividualReturnHelper(
          this.paymentHelper,
          this.authService.config,
          ir
        );


        try {
          await this.individualService.updateReturn(this.authService.organization.id, miHelper.irForm);

          await this.router.navigate([path]);
        } catch (error) {
        }
      } else {
        this.form.markAllAsTouched();
      }
    }
  }

  async back() {
    let path = `/individual/filings/mi/${this.individualService.taxYear}/start`;

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      await this.router.navigate([path]);
    } else {
      const payload = this.getPayload();
      try {
        await this.individualService.updateReturn(this.city.id, payload);
        await this.router.navigate([path]);
      } catch (error) {
      }
    }
  }


}
