export class WorksheetCHelper {

  private adjustmentToIncome: any;

  constructor(private paymentHelper, worksheetC) {
    worksheetC = worksheetC ? worksheetC : {};
    this.adjustmentToIncome = this.adjustmentToIncomeLines(worksheetC);
  }

  get totalAdjustmentToIncome() {
    return this.paymentHelper.sum(this.adjustmentToIncome, 'column1Adjustment', 2);
  }

  adjustmentToIncomeLines(worksheetC) {
    return worksheetC.adjustmentToIncomeLine ? Array.isArray(worksheetC.adjustmentToIncomeLine) ? worksheetC?.adjustmentToIncomeLine : [worksheetC.adjustmentToIncomeLine] : [];
  }
}
