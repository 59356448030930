<form (submit)="updatePayment()"
    [formGroup]="form"
    class="d-flex flex-column flex-fill"
    novalidate>

  <div class="modal-header align-items-center">
    <h4 class="modal-title pull-left">{{ "Payment.update-payment-for" | translate }}  {{ payment.taxYear }} {{ payment.paymentType }}</h4>
    <a (click)="bsModalRef.hide()" class="text-muted" href="javascript:">
      <svg class="icon" viewBox="0 0 24 24">
        <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
            stroke="none"/>
      </svg>
    </a>
  </div>

  <div class="modal-body">

    <div class="row p-2">
      <div class="col-6">
        <label for="paymentDate" class="font-weight-bolder">
          {{ "CommonForm.payment-date-label" | translate }}
        </label>
      </div>
      <div class="col-6 text-right">
        <input
            [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
            [maxDate]="maxDate"
            [minDate]="minDate"
            bsDatepicker
            class="form-control text-right"
            formControlName="paymentDate"
            placeholder="mm/dd/yyyy"
            type="text"
            id="paymentDate"
            [attr.aria-label]="'CommonForm.payment-date-label' | translate"
        />
      </div>
    </div>

    <div class="row p-2">
      <div class="col-6">
        <label for="paymentReminderDate" class="font-weight-bolder">
          {{ "CommonForm.payment-reminder-date-label" | translate }}
        </label>
      </div>
      <div class="col-6 text-right">
        <input
            [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
            [maxDate]="maxDate"
            [minDate]="minDate"
            bsDatepicker
            class="form-control text-right"
            formControlName="paymentReminderDate"
            placeholder="mm/dd/yyyy"
            type="text"
            id="paymentReminderDate"
            [attr.aria-label]="'CommonForm.payment-reminder-date-label' | translate"
        />
      </div>
    </div>

    <div class="row p-2">
      <div class="col-6">
        <label for="amount" class="font-weight-bolder">
          {{ payment.taxYear }} {{ payment.paymentType }}
        </label> <span class="text-danger pl-1">*</span>
      </div>
      <div class="col-6">
        <input (input)="updateFees()"
            [allowNegativeNumbers]="false"
            [class.is-invalid]="form.get('amount').invalid && (form.get('amount').dirty || form.get('amount').touched)"
            class="form-control text-right"
            formControlName="amount"
            mask="separator.2"
            onfocus="this.select()"
            prefix="$"
            thousandSeparator=","
            type="text"
            id="amount"
            [attr.aria-invalid]="form.get('amount').invalid && (form.get('amount').dirty || form.get('amount').touched) ? 'true' : 'false'"
            [attr.aria-label]="'Payment.amount' | translate" 
            [attr.aria-required]="'true'"
        />
      </div>
    </div>
    <div class="row p-2">
      <div class="col-6">
        <label class="font-weight-bolder" for="feeAmount" id="feeLabel">
          {{ "CommonForm.fee-label" | translate }}
        </label>
      </div>
      <div class="col-6 px-3 text-right" id="feeAmount" [attr.aria-labelledby]="'feeLabel'">
        {{ payment?.feeAmount | currency }}
      </div>
    </div>
    <div class="row p-2">
      <div class="col-6">
        <label class="font-weight-bolder" for="totalAmount" id="totalLabel">
          {{ "CommonForm.total-label" | translate }}
        </label>
      </div>
      <div class="col-6 px-3 text-right" id="totalAmount" [attr.aria-labelledby]="'totalLabel'">
        <ui-spinner [class.active]="(isLoading$ | async)">
          {{ payment?.total | currency }}
        </ui-spinner>
      </div>
    </div>
  </div>

  <div class="modal-footer justify-content-end">
    <a (click)="bsModalRef.hide()" 
       class="btn btn-link" 
       href="javascript:"
       role="button"
       [attr.aria-label]="'Payment.close' | translate"
       tabindex="0">{{'Payment.close' | translate}}</a>
    <button [disabled]="(isLoading$ | async)" 
       class="btn btn-primary" 
       type="submit"
       role="button"
       [attr.aria-label]="'Payment.update-the-data' | translate"
       [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'"
      tabindex="0">
      <ui-spinner [class.active]="isLoading$ | async">
        {{'Payment.update' | translate}}
      </ui-spinner>
    </button>
  </div>

</form>
