import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from 'libs/features/auth/src/lib/services/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'city-tax-feature-individual-payment-method-delete',
  templateUrl: './individual-payment-method-delete.component.html',
  styleUrls: ['./individual-payment-method-delete.component.scss']
})
export class IndividualPaymentMethodDeleteComponent implements OnInit {
  public formDelete: FormGroup;
  public isLoading$ = this.authService.isLoading$;
  public paymentMethod;
  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private authService: AuthService,

  ) { }

  ngOnInit(): void {
    this._initFormDelete();
  }
  public async deletePayment() {
    if (!this.paymentMethod) {
      return;
    }  
      await this.authService.deletePaymentMethod(
        this.authService.organizationId,
        'individuals',
        this.paymentMethod.id
      );
  
      this.bsModalRef.hide();
      this.toastrService.success('individual payment method deleted successfully', 'Success');
  };
  private _initFormDelete = () => {
    this.formDelete = this.formBuilder.group({});
  }
}
