import {IIndividualPaymentsResponse} from "../interfaces/individual-payment.interface";
import {IndividualPaymentModel} from "./individual-payment.model";

export class IndividualPaymentsModel implements IIndividualPaymentsResponse {
  public data: Array<IndividualPaymentModel>;

  public meta: any;

  constructor(individualPaymentModels: IndividualPaymentsModel) {
    this.data =
      individualPaymentModels.data && individualPaymentModels.data.length > 0
        ? individualPaymentModels.data.map(
          (individualPayment) => new IndividualPaymentModel(individualPayment)
        )
        : [];
    this.meta = individualPaymentModels.meta;
  }
}
