import {AfterViewInit, Component, Injector, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HexaColumnService, HexaListComponent, HexaStorageService, HexaTitleService} from "@hexalang/ui/core";

import {AuthService} from '@city-tax/features/auth';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder} from "@angular/forms";
import {FormatService} from "@city-tax/shared";
import {ToastrService} from 'ngx-toastr';
import {CheckmarkTemplateComponent} from "@hexalang/ui/shared";
import {Observable, of} from "rxjs";
import {
  IndividualPaymentMethodCreateComponent
} from "../payment-method-create/individual-payment-method-create.component";
import {
  IndividualPaymentMethodUpdateComponent
} from "../payment-method-update/individual-payment-method-update.component";
import {
  IndividualPaymentMethodDeleteComponent
} from "../payment-method-delete/individual-payment-method-delete.component";
import { IndividualService } from '../../../services/individual.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'city-tax-feature-individual-payment-method-list',
  templateUrl: './individual-payment-method-list.component.html',
  styleUrls: ['./individual-payment-method-list.component.scss']
})
export class IndividualPaymentMethodListComponent
  extends HexaListComponent
  implements OnInit, AfterViewInit, OnDestroy {

  public isLoading$ = this.authService.isLoading$;
  @ViewChild(CheckmarkTemplateComponent, {static: true}) checkmarkTemplate: CheckmarkTemplateComponent;

  @ViewChild("detailTemplate") public detailTemplate: TemplateRef<any>;
  @ViewChild("actionTemplate") public actionTemplate: TemplateRef<any>;
  @ViewChild("statusTemplate") public statusTemplate: TemplateRef<any>;
  @ViewChild("typeTemplate") public typeTemplate: TemplateRef<any>;


  public individual$ = this.authService.individual$;

  public status$: Observable<Array<String>> = of(['Deleted', 'Active']);

  public bsModalRef: BsModalRef;
  public formFilterName = "pmFormFilter";

  constructor(
    injector: Injector,
    public titleService: HexaTitleService,
    public columnService: HexaColumnService,
    public storageService: HexaStorageService,
    public toastrService: ToastrService,
    public formatService: FormatService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private individualService: IndividualService,
    private authService: AuthService,
    private modalService: BsModalService,
  ) {
    super(injector, {dataGrid: 'Payment Methods'});
  }

  ngOnInit() {
    
    this.titleService.changeTitle(this.translate.instant("Payment.payment-methods"));
    this.translate.onLangChange.subscribe(val => {
      this.titleService.changeTitle(
        this.translate.instant("Payment.payment-methods")
      )
    })
    this.initializeForm();
    this.getIndividual();
  }

  ngAfterViewInit() {
    this.defineColumns();
    this.loadPersonalization();
  }


  public showModal(type: string, paymentMethod?) {
    let component: any;
    let modelClass: string;
    let ignoreBackdropClick = true;
    const initialState = paymentMethod ? {paymentMethod} : {};

    switch (type) {
      case 'create':
        component = IndividualPaymentMethodCreateComponent;
        modelClass = "fade-left modal-dialog-tall modal-dialog-right";
        break;
      case 'update':
        component = IndividualPaymentMethodUpdateComponent;
        modelClass = "fade-left modal-dialog-tall modal-dialog-right";
        break;
      case 'delete':
        component = IndividualPaymentMethodDeleteComponent;
        modelClass = "modal-sm";
        break;
    }

    this.bsModalRef = this.modalService.show(component, {
      class: modelClass,
      ignoreBackdropClick: ignoreBackdropClick,
      initialState
    });

    this.bsModalRef.onHide.subscribe(() => {
      this.refresh();
    });
  }

  private initializeForm() {
    this.formFilter = this.formBuilder.group({
      search: [null],
      status: 'Active',
    });
    this.loadFilterForm(this.formFilterName);
  }

  private async getIndividual() {
    await this.authService.getIndividual(this.authService.organizationId);
  }

  private defineColumns() {
    this.columns = [
      // {prop: 'fileNumber', name: 'File #', sortable: false,},
      {
        prop: "status",
        name: "Status",
        required: true,
        draggable: true,
        resizeable: true,
        maxWidth: 100,
        cellTemplate: this.statusTemplate,
        sortable: false,
      },
      {
        prop: "isDefault",
        name: "Default",
        sortable: false,
        width: 65,
        maxWidth: 100,
        draggable: true,
        resizeable: true,
        cellClass: "text-success-500",
        cellTemplate: this.checkmarkTemplate.template,
      },
      {
        prop: "type",
        name: "Type",
        maxWidth: 100,
        sortable: false,
        draggable: true,
        resizeable: true,
        cellTemplate: this.typeTemplate
      },
      {
        prop: "name",
        name: "Name",
        sortable: false,
        draggable: true,
        resizeable: true,
      },
      {
        prop: "",
        name: "Detail",
        sortable: false,
        draggable: true,
        resizeable: true,
        cellTemplate: this.detailTemplate,
      },
      {
        name: '',
        cellTemplate: this.actionTemplate,
        sortable: false,
        width: 60,
        maxWidth: 60,
        required: true,
        draggable: false,
        resizeable: false,
        canAutoResize: false,
        headerClass: "justify-content-end text-truncate-none position-unset",
        cellClass: "justify-content-end text-truncate-none",
      },
    ];
  }
}
