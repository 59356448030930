import {PaymentHelper} from "@city-tax/shared";


export class LossCarryForwardHelper {
  private taxYear: any;
  private totalIncomeOrLoss: any;
  private paymentHelper: PaymentHelper;

  constructor(taxYear, worksheetB, totalIncomeOrLoss) {
    this.taxYear = taxYear;
    this.loss = this.getLossCarryForwards(worksheetB);
    this.totalIncomeOrLoss = totalIncomeOrLoss;
    this.paymentHelper = new PaymentHelper();
  }

  get municipalityLossCarryForward() {
    if (this.totalIncomeOrLoss <= 0) return 0;
    const allocation = this.taxYear <= 2022 ? 0.5 : 1;
    const usableLoss = Math.ceil(this.netOperatingLossCarryForward * allocation);
    const usableIncome = Math.ceil(this.totalIncomeOrLoss * allocation);
    return Math.min(usableLoss, usableIncome) * -1;
  }

  get municipalityPercentage() {
    const totalIncomeLoss = this.netOperatingLossCarryForward;
    if (totalIncomeLoss === 0) return 0;
    return (this.municipalityLossCarryForward / totalIncomeLoss).toFixed(2);
  }

  get netOperatingLossCarryForward() {
    if (this.totalIncomeOrLoss <= 0) return 0;
    return this.paymentHelper.sum(this.loss, 'lossCarryForwardAmount');
  }

  get taxCreditAllowed() {
    return this.municipalityLossCarryForward;
  }

  loss(loss) {
    this.loss = loss;
  }

  getLossCarryForwards(worksheetB) {
    return worksheetB.lossCarryForward ? Array.isArray(worksheetB.lossCarryForward) ? worksheetB.lossCarryForward : [worksheetB.lossCarryForward] : [];
  }

}

