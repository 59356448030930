export enum EPeriod {
  JANUARY = 'January',
  FEBRUARY = 'February',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'August',
  SEPTEMBER = 'September',
  OCTOBER = 'October',
  NOVEMBER = 'November',
  DECEMBER = 'December',
  RECONCILIATION = 'Reconciliation',
  FIRST_QUARTER = 'First Quarter',
  SECOND_QUARTER = 'Second Quarter',
  THIRD_QUARTER = 'Third Quarter',
  FOURTH_QUARTER = 'Fourth Quarter',
}

export enum EPeriodQuarter {
  FIRST_QUARTER = 'First Quarter',
  SECOND_QUARTER = 'Second Quarter',
  THIRD_QUARTER = 'Third Quarter',
  FOURTH_QUARTER = 'Fourth Quarter',
}

export enum EPeriodMonth {
  JANUARY = 'January',
  FEBRUARY = 'February',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'August',
  SEPTEMBER = 'September',
  OCTOBER = 'October',
  NOVEMBER = 'November',
  DECEMBER = 'December',
}

export enum EPeriodSemiMonthly {
  JANUARY_1 = '1st January',
  JANUARY_2 = '2nd January',
  FEBRUARY_1 = '1st February',
  FEBRUARY_2 = '2nd February',
  MARCH_1 = '1st March',
  MARCH_2 = '2nd March',
  APRIL_1 = '1st April',
  APRIL_2 = '2nd April',
  MAY_1 = '1st May',
  MAY_2 = '2nd May',
  JUNE_1 = '1st June',
  JUNE_2 = '2nd June',
  JULY_1 = '1st July',
  JULY_2 = '2nd July',
  AUGUST_1 = '1st August',
  AUGUST_2 = '2nd August',
  SEPTEMBER_1 = '1st September',
  SEPTEMBER_2 = '2nd September',
  OCTOBER_1 = '1st October',
  OCTOBER_2 = '2nd October',
  NOVEMBER_1 = '1st November',
  NOVEMBER_2 = '2nd November',
  DECEMBER_1 = '1st December',
  DECEMBER_2 = '2nd December',
}
