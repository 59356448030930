import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {PaymentService} from "../../../../services/payment.service";
import {AuthService} from "@city-tax/features/auth";
import {DatatableComponent} from '@swimlane/ngx-datatable';
import {FormatService} from "@city-tax/shared";

@Component({
  selector: 'city-tax-feature-individual-payment-recurring-cancel',
  templateUrl: './individual-payment-recurring-cancel.component.html',
  styleUrls: ['./individual-payment-recurring-cancel.component.scss']
})
export class IndividualPaymentRecurringCancelComponent implements OnInit {

  @ViewChild("selectHeaderTemplate", {static: true}) public selectHeaderTemplate: TemplateRef<any>
  @ViewChild("selectTemplate", {static: true}) public selectTemplate: TemplateRef<any>

  @ViewChild(DatatableComponent) datatablePayments: DatatableComponent;

  public formDelete: FormGroup;
  public isLoading$ = this.paymentService.isLoading$;
  public payment: any;
  public payments = [];
  public selected = [];
  public columns = [];

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private paymentService: PaymentService,
    private authService: AuthService,
    public formatService: FormatService,
  ) {

  }

  ngOnInit(): void {
    this.defineColumns();
    this._initFormDelete();
    this.payments.push(this.payment);
    this.selected.push(this.payment);
    this.payment.recurring = this.payment.recurring || [];
    this.payments.push(...this.payment.scheduledPayments);
    this.selected.push(...this.payment.scheduledPayments);
  }

  onSelect({selected}) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  public async deletePayment() {
    for (const payment of this.selected) {
      const response = await this.paymentService.deletePayment(
        this.authService.organization.id,
        payment.id
      );
    }
    this.bsModalRef.hide();
    this.toastrService.success('Payments cancelled successfully', 'Cancel Payments');
  };

  private _initFormDelete = () => {
    this.formDelete = this.formBuilder.group({});
  }

  private defineColumns() {
    this.columns = [
      {
        prop: "",
        name: "",
        headerTemplate: this.selectHeaderTemplate,
        cellTemplate: this.selectTemplate,
        sortable: false,
        width: 60,
        cellClass: "text-truncate-none",
      },
      {
        prop: "taxYear",
        name: "Tax Year",
        sortable: false,
      },
      {
        prop: "paymentType",
        name: "Payment Type",
        sortable: true,
      },

      {
        prop: "pm.name",
        name: "Payment Method",
        sortable: false,
      },

      {
        prop: "paymentDate",
        name: "Payment Date",
        pipe: {transform: (value: any) => this.formatService.dateFormat(value)},
        sortable: true,
      },

      {
        prop: "amount",
        name: "Amount",
        pipe: {transform: (value: any) => this.formatService.currencyFormat(value)},
        sortable: true,
      },
      {
        prop: "feeAmount",
        name: "Fee",
        pipe: {transform: (value: any) => this.formatService.currencyFormat(value)},
        sortable: true,
      },
      {
        prop: "total",
        name: "Total",
        pipe: {transform: (value: any) => this.formatService.currencyFormat(value)},
        sortable: true,
      },

    ];
  }
}
