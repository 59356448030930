import {AbstractControl, ValidationErrors} from '@angular/forms';

export function SSNValidator(control: AbstractControl): ValidationErrors | null {
  const value = control.value;

  if (!value) {
    return null;
  }

  // Invalid if exactly '000000000' or starts with '00000' followed by any 4 digits
  const invalidPattern = /^000000000$|^00000\d{4}$/;

  if (invalidPattern.test(value)) {
    return { invalidSSN: true };
  }

  return null;
}
