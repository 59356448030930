import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, map, Observable } from 'rxjs';

export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    const globalTranslations = this.http.get(`/features/assets/i18n/${lang}.json`);
    const sharedTranslations = this.http.get(`/shared/assets/i18n/${lang}.json`);
    const assetTranslations = this.http.get(`/assets/i18n/${lang}.json`);

    return forkJoin([globalTranslations, sharedTranslations, assetTranslations]).pipe(
      map(([global, shared, assets]) => {
        return this.deepMerge(assets, shared, global);
      })
    );
  }

  deepMerge(...objects) {
    return objects.reduce((acc, obj) => {
      for (const key in obj) {
        if (obj[key] && typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          acc[key] = this.deepMerge(acc[key] || {}, obj[key]);
        } else {
          acc[key] = obj[key];
        }
      }
      return acc;
    }, {});
  }
}
