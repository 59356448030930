import {FormControl} from '@angular/forms';
import * as moment from 'moment';

export class DateFormControl extends FormControl {
  setValue(value: any, options?: any) {
    super.setValue(this.toDate(value), options);
  }

  patchValue(value: any, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
    emitModelToViewChange?: boolean;
    emitViewToModelChange?: boolean
  }) {
    super.patchValue(this.toDate(value), options);
  }

  getRawValue() {
    if (!this.value) return this.value;
    return moment(this.value).format('YYYY-MM-DDT00:00:00Z');
  }

  private toDate(value: any): any {
    if (!value) return value;
    if (typeof value === 'string') {
      const format = value.includes('/') ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
      return moment(value, format).toDate();
    }
    return moment(value).toDate();
  }
}
