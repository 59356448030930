<div class="container-fluid mt-3">
  <div class="row main-sec home-content">
    <div class="col-lg-12">
      <div class="complete-page">
        <div class="complete-page-inner">
          <div *ngIf="individualReturn$ | async as individualReturn" class="main">
            <div class="content">
              <div class="row mx-0">
                <div class="col-12">
                  <div class="card card-body">
                    <div *ngIf="individualReturn.status === 'Completed'" class="pt-1 font-18 w-100 font-weight-bold">                      
                      {{'Taxpayer.your-tax-return-filed-on' | translate:{taxYear: individualReturn.taxYear} }} {{ individualReturn?.filingDate | utcDate }}.
                    </div>
                    <div *ngIf="individualReturn.status === 'Mailed'" class="pt-1 font-18 w-100 font-weight-bold">
                      {{'Taxpayer.your-tax-return-pending' | translate:{taxYear: individualReturn.taxYear} }}
                    </div>
                    <div class="row">
                      <div class="col-6">
                        <div class="card d-flex flex-column p-3 mb-1 pb-1 h-100">
                          <div class="flex-grow-1">

                            <div class="row">
                              <div class="col-6">
                                <label id="filingDeadlineLabel" class="font-weight-bold">{{'Taxpayer.filing-deadline' | translate}} </label>
                              </div>
                              <div class="col-2" [attr.aria-labelledby]="'filingDeadlineLabel'">
                                {{ individualReturn?.eFileDeadline | utcDate:'MMMM dd, YYYY' }}
                              </div>
                              <div class="col-4 d-inline-flex  d-flex justify-content-end">
                                <ui-spinner [class.active]="(isDownloading$ | async) === 'downloadReturn'">
                                  <a (click)="downloadReturn(individualReturn)" class="text-primary pr-3"
                                     href="javascript:">
                                    {{'Taxpayer.download' | translate}}
                                    <svg class="icon icon-sm" viewBox="0 0 16 16">
                                      <path
                                          d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                                    </svg>
                                  </a>
                                </ui-spinner>
                                <ui-spinner [class.active]="(isDownloading$ | async) === 'printReturn'">
                                  <a (click)="printReturn(individualReturn)" class="text-primary pr-3"
                                     href="javascript:">
                                    {{'Taxpayer.print' | translate}}
                                    <svg class="icon icon-sm" viewBox="0 0 16 16">
                                      <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
                                      <path
                                          d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
                                    </svg>
                                  </a>
                                </ui-spinner>
                              </div>
                            </div>

                            <div *ngIf="individualReturn.status === 'Completed'" class="row py-2">
                              <div class="col-6">
                                <label id="filingDateLabel" class="font-weight-bold">{{'Taxpayer.filing-date' | translate}}</label>
                              </div>
                              <div class="col-6" [attr.aria-labelledby]="'filingDateLabel'">
                                {{ individualReturn?.filingDate | utcDate }}
                              </div>
                            </div>

                            <div *ngIf="individualReturn.status === 'Completed'" class="row py-2">
                              <div class="col-6">
                                <label id="eFileConfirmationLabel" class="font-weight-bold">{{'Taxpayer.e-file-confirmation' | translate}} #</label>
                              </div>
                              <div class="col-6" [attr.aria-labelledby]="'eFileConfirmationLabel'">
                                {{ individualReturn?.locator }}
                              </div>
                            </div>

                            <ng-container *ngIf="payments$ | async as payments">
                              <div class="row py-2">
                                <div class="col-6">
                                  <label class="font-weight-bold">{{'Taxpayer.balance-due' | translate}}</label>
                                </div>
                                <div class="col-2">
                                  {{ individualReturn?.totalTaxDue | currency:'USD':'symbol':numberFormat }}
                                </div>
                                <div *ngIf="city?.components?.individual?.payments" class="col-4">
                                   <span
                                       *ngIf="decimalValue(individualReturn?.totalTaxDue) > 0 && sumPayments(payments) < decimalValue(individualReturn?.totalTaxDue) && !individualReturn.payTaxDueDirectWithdrawal">
                                  <a [queryParams]="{
                                     individualReturnId: individualReturn?.id,
                                     amount: (decimalValue(individualReturn?.totalTaxDue) - sumPayments(payments)).toFixed(2),
                                     taxYear: individualReturn?.taxYear,
                                     paymentType: 'Balance Due',
                                     redirect: router.url.split('?')[0]
                                  }" [routerLink]="['/individual/payments/create']"
                                     class="flex-grow-1 btn btn-danger py-1"> {{'Taxpayer.pay' | translate}} </a>
                                 </span>
                                  <span *ngIf="individualReturn.payTaxDueDirectWithdrawal">
                                    {{'Taxpayer.payment-processing' | translate}}
                                  </span>
                                </div>
                              </div>

                              <div *ngIf="individualReturn?.amountDueWithThisDeclaration>0" class="row py-2">
                                <div class="col-6">
                                  <label class="font-weight-bold">{{'Taxpayer.1st-quarter-estimate-due' | translate}}</label>
                                </div>
                                <div class="col-2">
                                  {{ individualReturn?.amountDueWithThisDeclaration | currency:'USD':'symbol':numberFormat }}
                                </div>
                                <div *ngIf="city?.components?.individual?.payments" class="col-4">
                                   <span
                                       *ngIf="decimalValue(individualReturn?.amountDueWithThisDeclaration) > 0 && getFirstQuarterEstimate(payments) < decimalValue(individualReturn?.amountDueWithThisDeclaration)">
                                     <a [queryParams]="{
                                     individualReturnId: individualReturn?.id,
                                     amount: (decimalValue(individualReturn?.amountDueWithThisDeclaration) - getFirstQuarterEstimate(payments)).toFixed(2),
                                     taxYear: (+individualReturn?.taxYear+1),
                                     paymentType: '1st Quarter Estimate',
                                     redirect: router.url.split('?')[0]
                                  }" [routerLink]="['/individual/payments/create']"
                                        class="flex-grow-1 btn btn-danger py-1">
                                       {{'Taxpayer.pay' | translate}}
                                     </a>
                                 </span>
                                </div>
                              </div>

                              <div *ngIf="decimalValue(individualReturn?.overpaymentToBeRefunded) > 0" class="row py-2">
                                <div class="col-6">
                                  <label id="refundedLabel" class="font-weight-bold">{{'Taxpayer.refunded'| translate}}</label>
                                </div>
                                <div class="col-6" [attr.aria-labelledby]="'refundedLabel'">
                                  {{ individualReturn?.overpaymentToBeRefunded | currency:'USD':'symbol':numberFormat }}
                                </div>
                              </div>
                              <div *ngIf="decimalValue(individualReturn?.overpaymentToBeRefunded) > 0" class="row py-2">
                                <div class="col-12">
                                  <label id="refundedMessageLabel" class="font-weight-bold">{{'Taxpayer.refunded-message'| translate}}</label>
                                </div>
                              </div>

                              <div *ngIf="decimalValue(individualReturn?.amountOfOverpaymentCreditedForward) > 0 || decimalValue(individualReturn?.overpaymentToBeCredited) > 0 " class="row py-2">
                                <div class="col-6">
                                  <label class="font-weight-bold">{{'Taxpayer.amount-of-overpayment-credited' | translate}}
                                    {{ +(individualReturn.taxYear) + 1 }}</label>
                                </div>
                                <div *ngIf="decimalValue(individualReturn?.amountOfOverpaymentCreditedForward) > 0" class="col-6">
                                  {{ individualReturn?.amountOfOverpaymentCreditedForward | currency:'USD':'symbol':numberFormat }}
                                </div>
                                <div *ngIf="decimalValue(individualReturn?.overpaymentToBeCredited) > 0 " class="col-6">
                                  {{ individualReturn?.overpaymentToBeCredited | currency:'USD':'symbol':numberFormat }}
                                </div>
                              </div>

                              <div *ngIf="decimalValue(individualReturn?.totalTaxDue) > 0 && sumPayments(payments) >= decimalValue(individualReturn?.totalTaxDue)" class="row py-2">
                                <div class="col-12">
                                  <label class="font-16 font-weight-bold text-primary"> {{ 'Taxpayer.your-tax-return-paid' | translate:{taxYear: individualReturn.taxYear} }}</label>
                                </div>
                              </div>

                              <div *ngIf="(payments$|async)?.data?.length > 0" class="row py-2">
                                <div class="col-12">
                                  <label class="font-weight-bold text-uppercase">{{'Taxpayer.payments' | translate}}</label>
                                  <ngx-datatable
                                    #payments [columnMode]="'force'"
                                    [columns]="columns"
                                    [count]="(payments$ | async)?.meta?.inlineCount"
                                    [externalPaging]="false"
                                    [externalSorting]="false"
                                    [footerHeight]="0"
                                    [headerHeight]="30"
                                    [loadingIndicator]="isLoading$ | async"
                                    [rowHeight]="30"
                                    [rows]="(payments$ | async)?.data"
                                    [scrollbarH]="false"
                                    class="bootstrap datatable-flush"
                                  >
                                  </ngx-datatable>
                                </div>
                              </div>

                              <div *ngIf="decimalValue(individualReturn?.totalTaxDue) > 0 && sumPayments(payments) < decimalValue(individualReturn?.totalTaxDue) && !individualReturn.payTaxDueDirectWithdrawal"
                                   class="row py-2">
                                <div *ngIf="city?.components?.individual?.payments" class="col-12">
                                  <label class="font-weight-bold text-danger"> {{'Taxpayer.your-payment-has-not-been-made' | translate}}</label>
                                </div>
                              </div>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="card d-flex flex-column p-3 mb-1 pb-1 h-100">
                          <div class="flex-grow-1">
                            <h6 class="pt-1 w-100 font-weight-bold">
                              {{'Taxpayer.any-other-correspondence-or-requested-information' | translate}}
                            </h6>
                            <div class="upload-layout">
                              <div class="drop_box">
                                <header>
                                  <h3 class="mb-0">{{'Taxpayer.select-file-here' | translate}}</h3>
                                </header>
                                <div class="text-center button-upload mt-2">
                                  <button class="btn btn-primary font-weight-600 btn-upload"
                                      role="button"
                                      [attr.aria-label]="'Taxpayer.upload-file' | translate"
                                      tabindex="0">{{'Taxpayer.upload-file' | translate}}</button>
                                  <input (change)="uploadDocument(individualReturn.id,'Additional', $event)" accept=".pdf"
                                         class="py-2 py-2 file-upload w-100 border bg-white drop-file" name="filename" 
                                         type="file"
                                         aria-label="Upload a PDF file for the additional return"/>
                                </div>
                              </div>
                            </div>
                            <div *ngFor="let file of individualReturn?.files" class="row m-0">
                              <div *ngIf="file.type === 'Additional'" class="col-12 ">
                                <div class="row no-gutters align-items-center border-top bg-primary-100 py-1">
                                  <div class="col-8 align-items-center px-3 py-2 d-flex">
                                    <svg *ngIf="file?.mimeType === 'application/pdf'" class="icon" viewBox="0 0 24 24">
                                      <path
                                          d="M14,9H19.5L14,3.5V9M7,2H15L21,8V20A2,2 0 0,1 19,22H7C5.89,22 5,21.1 5,20V4A2,2 0 0,1 7,2M11.93,12.44C12.34,13.34 12.86,14.08 13.46,14.59L13.87,14.91C13,15.07 11.8,15.35 10.53,15.84V15.84L10.42,15.88L10.92,14.84C11.37,13.97 11.7,13.18 11.93,12.44M18.41,16.25C18.59,16.07 18.68,15.84 18.69,15.59C18.72,15.39 18.67,15.2 18.57,15.04C18.28,14.57 17.53,14.35 16.29,14.35L15,14.42L14.13,13.84C13.5,13.32 12.93,12.41 12.53,11.28L12.57,11.14C12.9,9.81 13.21,8.2 12.55,7.54C12.39,7.38 12.17,7.3 11.94,7.3H11.7C11.33,7.3 11,7.69 10.91,8.07C10.54,9.4 10.76,10.13 11.13,11.34V11.35C10.88,12.23 10.56,13.25 10.05,14.28L9.09,16.08L8.2,16.57C7,17.32 6.43,18.16 6.32,18.69C6.28,18.88 6.3,19.05 6.37,19.23L6.4,19.28L6.88,19.59L7.32,19.7C8.13,19.7 9.05,18.75 10.29,16.63L10.47,16.56C11.5,16.23 12.78,16 14.5,15.81C15.53,16.32 16.74,16.55 17.5,16.55C17.94,16.55 18.24,16.44 18.41,16.25M18,15.54L18.09,15.65C18.08,15.75 18.05,15.76 18,15.78H17.96L17.77,15.8C17.31,15.8 16.6,15.61 15.87,15.29C15.96,15.19 16,15.19 16.1,15.19C17.5,15.19 17.9,15.44 18,15.54M8.83,17C8.18,18.19 7.59,18.85 7.14,19C7.19,18.62 7.64,17.96 8.35,17.31L8.83,17M11.85,10.09C11.62,9.19 11.61,8.46 11.78,8.04L11.85,7.92L12,7.97C12.17,8.21 12.19,8.53 12.09,9.07L12.06,9.23L11.9,10.05L11.85,10.09Z"
                                          stroke="none"/>
                                    </svg>
                                    <svg *ngIf="
                                      file?.mimeType === 'image/png' ||
                                      file?.mimeType === 'image/jpg' ||
                                      file?.mimeType === 'image/jpeg'
                                      " class="icon" viewBox="0 0 24 24">
                                      <path
                                          d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M6,20H15L18,20V12L14,16L12,14L6,20M8,9A2,2 0 0,0 6,11A2,2 0 0,0 8,13A2,2 0 0,0 10,11A2,2 0 0,0 8,9Z"
                                          stroke="none"/>
                                    </svg>
                                    {{ file?.fileName }}
                                  </div>
                                  <div class="ml-auto d-flex align-items-center pr-3">
                                    <div class="text-nowrap mr-2">
                                      <strong class="font-weight-medium">{{ file?.size | fileSize }}</strong>
                                    </div>
                                    <div class="d-flex align-items-center justify-content-center">
                                      <button (click)="delete(file)" 
                                             class="btn btn-sm bg-danger border shadow p-1" 
                                             container="body"
                                             placement="left" 
                                             tooltip="Delete" 
                                             type="button"
                                             role="button" 
                                             aria-label="Delete file" 
                                             [attr.tabindex]="0" >
                                        <svg class="icon icon-sm fill-white d-flex" viewBox="0 0 24 24" aria-hidden="true">
                                          <path
                                              d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                              stroke="none"/>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                            <span class="text-sm">
                              {{ 'Taxpayer.contact-displayname' | translate: { displayName: (city?.displayName || city?.name), phone: (city?.components?.individual?.phone | phone), email: city?.components?.individual?.email } }}
                            </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template
  #paymentDateTemplate
  let-i="index"
  let-row="row"
  let-value="value"
  ngx-datatable-cell-template
>
  <ng-container *ngIf="row.type === 'card'">
    {{ row.updatedAt | utcDate:'MM/dd/yyyy' }}
  </ng-container>
  <ng-container *ngIf="row.type !== 'card'">
    {{ row.paymentDate | utcDate:'MM/dd/yyyy' }}
  </ng-container>
</ng-template>


<ng-template
  #paymentPrintTemplate
  let-i="index"
  let-row="row"
  let-value="value"
  ngx-datatable-cell-template
>
  <div class="row inline-flex">
    <div class="col-6 text-right">
      <ui-spinner [class.active]="(isDownloading$ | async) === 'downloadPayment-' + row.id ">
        <a (click)="downloadReceipt(row.id)" class="text-primary pr-3"
           href="javascript:">
          {{'Taxpayer.download' | translate}}
          <svg class="icon icon-sm" viewBox="0 0 16 16">
            <path
                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
          </svg>
        </a>
      </ui-spinner>
    </div>

    <div class="col-6 text-left">
      <ui-spinner [class.active]="(isDownloading$ | async) === 'printPayment-' + row.id ">
        <a (click)="printReceipt(row.id)" class="text-primary pr-3"
           href="javascript:">
          {{'Taxpayer.print' | translate}}
          <svg class="icon icon-sm" viewBox="0 0 16 16">
            <path d="M2.5 8a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"/>
            <path
                d="M5 1a2 2 0 0 0-2 2v2H2a2 2 0 0 0-2 2v3a2 2 0 0 0 2 2h1v1a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-1h1a2 2 0 0 0 2-2V7a2 2 0 0 0-2-2h-1V3a2 2 0 0 0-2-2H5zM4 3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v2H4V3zm1 5a2 2 0 0 0-2 2v1H2a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-1v-1a2 2 0 0 0-2-2H5zm7 2v3a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1z"/>
          </svg>
        </a>
      </ui-spinner>
    </div>
  </div>
</ng-template>
