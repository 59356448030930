<div *ngIf="payment$ | async as payment">

  <div class="row main-sec home-content col-md-6 mx-auto">
    <div class="col-12">
      <div class="row d-flex align-items-center p-3">
        <div class="col-8">
          <h2 class="bg-theme-color font-weight-bold mb-0">
            {{ "CompletePage.payment-heading" | translate }} for {{ organization.displayName || organization.name }}.
          </h2>
        </div>
        <div class="col-4" style="text-align: right">
          <a (click)="print(payment.id)" class="btn btn-primary bg-dark" href="javascript:;">
            <ui-spinner [class.active]="(isDownloading$ | async) === payment.id">
              <svg
                  class="bi bi-printer-fill"
                  fill="currentColor"
                  height="16"
                  viewBox="0 0 16 16"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"
                />
                <path
                    d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                />
              </svg>
              {{ "CompletePage.print-link" | translate }}
            </ui-spinner>
          </a>
        </div>
      </div>
    </div>
    <div>
      <div class="complete-page">
        <div class="complete-page-inner">
          <div class="col-12">
            <div class="main">
              <div class="content">
                <div class="bg-gray-100 p-2">
                  <div class="row">
                    <div class="col-12">
                      <div class="card card-body mb-12">
                        <div class="row border-bottom py-2">
                          <div class="col-6">
                            <label id="taxpayerLabel" class="p-Label Label Label--empty font-weight-bold mb-0">
                              {{ "CreateNewAccountComp.taxpayer-label" | translate }}
                            </label>
                          </div>
                          <div class="col-6" style="text-align: right" [attr.aria-labelledby]="'taxpayerLabel'">
                            {{ payment.taxpayer?.name }}
                          </div>
                        </div>
                        <div class="row border-bottom py-2">
                          <div class="col-6">
                            <label id="paymentDateLabel" class="p-Label Label Label--empty font-weight-bold mb-0">{{ "CompletePage.payment-date-label" | translate }}</label>
                          </div>
                          <div *ngIf="payment.type === 'card'" class="col-6" style="text-align: right" [attr.aria-labelledby]="'paymentDateLabel'">
                            {{ payment.originalPaymentDate || payment.paymentDate | utcDate: 'MM/dd/yyyy' }}
                          </div>
                          <div *ngIf="payment.type !== 'card'" class="col-6" style="text-align: right" [attr.aria-labelledby]="'paymentDateLabel'">
                            {{ payment.paymentDate |  utcDate: 'MM/dd/yyyy' }}
                          </div>
                        </div>
                        <div class="row border-bottom py-2">
                          <div class="col-6">
                            <label id="paymentMethodLabel" class="p-Label Label Label--empty font-weight-bold mb-0">
                              {{ "CompletePage.payment-method-label" | translate }}
                            </label>
                          </div>
                          <div *ngIf="payment.pm?.id" class="col-6" style="text-align: right" [attr.aria-labelledby]="payment.pm?.id ? 'paymentMethodLabel paymentMethodValue' : ''">
                            {{ payment.pm?.name }}
                          </div>
                          <div *ngIf="payment.type === 'card' && !payment.pm?.id" class="col-6" style="text-align: right" [attr.aria-labelledby]="'paymentMethodLabel paymentMethodCard'">
                            {{ "CompletePage.credit-card" | translate }}
                          </div>
                          <div *ngIf="payment.type === 'ach'  && !payment.pm?.id" class="col-6" style="text-align: right" [attr.aria-labelledby]="'paymentMethodLabel paymentMethodAch'">
                            {{ "CompletePage.ach" | translate }}
                          </div>

                        </div>

                        <div class="row border-bottom py-2">
                          <div class="col-6">
                            <label id="paymentAmount" class="p-Label Label Label--empty mb-0 font-weight-bold">
                              {{ payment.taxYear }} {{ payment.paymentType }}
                            </label>
                          </div>
                          <div class="col-6" style="text-align: right" [attr.aria-labelledby]="'paymentAmount'">
                            {{ payment.amount | currency }}
                          </div>
                        </div>

                        <div *ngIf="payment.type === 'card' || payment.pm?.type === 'card' " class="row border-bottom py-2">
                          <div class="col-6">
                            <label id="fee-label" class="p-Label Label Label--empty mb-0 font-weight-bold">
                              {{ "CommonForm.fee-label" | translate }}
                            </label>
                          </div>
                          <div class="col-6" style="text-align: right" [attr.aria-labelledby]="'fee-label'">
                            {{ payment.feeAmount | currency }}
                          </div>
                        </div>
                        <div class="row border-bottom py-2">
                          <div class="col-6">
                            <label id="total-label" class="p-Label Label Label--empty mb-0 font-weight-bold">
                              {{ "CommonForm.total-label" | translate }}
                            </label>
                          </div>
                          <div class="col-6" style="text-align: right" [attr.aria-labelledby]="'total-label'">
                            {{ payment.total | currency }}
                          </div>
                        </div>
                        <div class="row border-bottom py-2">
                          <div class="col-5">
                            <label id="locator-label" class="p-Label Label Label--empty mb-0 font-weight-bold">
                              {{ "CompletePage.confirm-label" | translate }}#
                            </label>
                          </div>
                          <div class="col-7" style="text-align: right" [attr.aria-labelledby]="'locator-label'">
                            {{ payment.locator }}
                          </div>
                        </div>
                        <div *ngIf="!payment.recurring" class="row pt-3">
                          <div class="col" id="additional">
                            <a (click)="additionalPayments()" class="btn btn-primary">
                              {{ "CompletePage.additional-payments-link"| translate }}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="payment?.type === 'card'" class="col-12 p-3">
                      <h6 class="mb-0 text-center">
                        {{ "CompletePage.description" | translate }}
                        '<strong>{{ "CompletePage.description-heading" | translate }}* {{ city }}</strong>'.
                      </h6>
                    </div>
                    <div *ngIf="payment?.type !== 'card'" class="col-12 p-3">
                      <h6 class="mb-0 text-center">
                        {{ "CompletePage.paymentTiming" | translate }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngFor="let recurring of payment?.scheduledPayments" class="row main-sec home-content col-md-6 mx-auto">
    <div class="col-12">
      <div class="row d-flex align-items-center p-3">
        <div class="col-8">
          <h2 class="bg-theme-color font-weight-bold mb-0">
            {{ "CompletePage.payment-heading" | translate }} for {{ organization.displayName }}.
          </h2>
        </div>
        <div class="col-4" style="text-align: right">
          <a (click)="print(recurring.id)" class="btn btn-primary bg-dark" href="javascript:;">
            <ui-spinner [class.active]="(isDownloading$ | async) === recurring.id">
              <svg
                  class="bi bi-printer-fill"
                  fill="currentColor"
                  height="16"
                  viewBox="0 0 16 16"
                  width="16"
                  xmlns="http://www.w3.org/2000/svg"
              >
                <path
                    d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z"
                />
                <path
                    d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                />
              </svg>
              {{ "CompletePage.print-link" | translate }}
            </ui-spinner>
          </a>
        </div>
      </div>
    </div>
    <div>
      <div class="complete-page">
        <div class="complete-page-inner">
          <div class="col-12">
            <div class="main">
              <div class="content">
                <div class="bg-gray-100 p-2">
                  <div class="row">
                    <div class="col-12">
                      <div class="card card-body mb-12">
                        <div class="row border-bottom py-2">
                          <div class="col-6">
                            <label id="taxpayer-label" class="p-Label Label Label--empty font-weight-bold mb-0">
                              {{ "CreateNewAccountComp.taxpayer-label" | translate }}
                            </label>
                          </div>
                          <div class="col-6" style="text-align: right" [attr.aria-labelledby]="'taxpayer-label'">
                            {{ recurring.taxpayer?.name }}
                          </div>
                        </div>
                        <div class="row border-bottom py-2">
                          <div class="col-6">
                            <label id="payment-date-label" class="p-Label Label Label--empty font-weight-bold mb-0">{{ "CompletePage.payment-date-label" | translate }}</label>
                          </div>
                          <div *ngIf="recurring.type === 'card'" class="col-6" style="text-align: right" [attr.aria-labelledby]="'payment-date-label'">
                            {{ recurring.originalPaymentDate || recurring.paymentDate | utcDate: 'MM/dd/yyyy' }}
                          </div>
                          <div *ngIf="recurring.type !== 'card'" class="col-6" style="text-align: right" [attr.aria-labelledby]="'payment-date-label'">
                            {{ recurring.paymentDate |  utcDate: 'MM/dd/yyyy' }}
                          </div>
                        </div>
                        <div class="row border-bottom py-2">
                          <div class="col-6">
                            <label id="payment-method-label" class="p-Label Label Label--empty font-weight-bold mb-0">
                              {{ "CompletePage.payment-method-label" | translate }}
                            </label>
                          </div>
                          <div *ngIf="recurring.pm?.id" class="col-6" style="text-align: right" [attr.aria-labelledby]="'payment-method-label'">
                            {{ recurring.pm?.name }}
                          </div>
                          <div *ngIf="recurring.type === 'card' && !recurring.pm?.id" class="col-6" style="text-align: right" 
                           [attr.aria-labelledby]="'payment-method-label'">
                            {{ "CompletePage.credit-card" | translate }}
                          </div>
                          <div *ngIf="recurring.type === 'ach'  && !recurring.pm?.id" class="col-6" style="text-align: right" 
                           [attr.aria-labelledby]="'payment-method-label'">
                            {{ "CompletePage.ach" | translate }}
                          </div>

                        </div>

                        <div class="row border-bottom py-2">
                          <div class="col-6">
                            <label id="payment-info-label" class="p-Label Label Label--empty mb-0 font-weight-bold">
                              {{ recurring.taxYear }} {{ recurring.paymentType }}
                            </label>
                          </div>
                          <div class="col-6" style="text-align: right" [attr.aria-labelledby]="'payment-info-label'">
                            {{ recurring.amount | currency }}
                          </div>
                        </div>

                        <div *ngIf="recurring.type === 'card' || recurring.pm?.type === 'card' " class="row border-bottom py-2">
                          <div class="col-6">
                            <label id="fee-label" class="p-Label Label Label--empty mb-0 font-weight-bold">
                              {{ "CommonForm.fee-label" | translate }}
                            </label>
                          </div>
                          <div class="col-6" style="text-align: right" [attr.aria-labelledby]="'fee-label'">
                            {{ recurring.feeAmount | currency }}
                          </div>
                        </div>
                        <div class="row border-bottom py-2">
                          <div class="col-6">
                            <label id="total-label" class="p-Label Label Label--empty mb-0 font-weight-bold">
                              {{ "CommonForm.total-label" | translate }}
                            </label>
                          </div>
                          <div class="col-6" style="text-align: right" [attr.aria-labelledby]="'total-label'">
                            {{ recurring.total | currency }}
                          </div>
                        </div>
                        <div class="row border-bottom py-2">
                          <div class="col-5">
                            <label id="confirm-label" class="p-Label Label Label--empty mb-0 font-weight-bold">
                              {{ "CompletePage.confirm-label" | translate }}#
                            </label>
                          </div>
                          <div class="col-7" style="text-align: right" [attr.aria-labelledby]="'confirm-label'">
                            {{ recurring.locator }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="payment?.type === 'card'" class="col-12 p-3">
                      <h6 class="mb-0 text-center">
                        {{ "CompletePage.description" | translate }}
                        '<strong>{{ "CompletePage.description-heading" | translate }}* {{ city }}</strong>'.
                      </h6>
                    </div>
                    <div *ngIf="payment?.type !== 'card'" class="col-12 p-3">
                      <h6 class="mb-0 text-center">
                        {{ "CompletePage.paymentTiming" | translate }}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
