import {NgModule} from "@angular/core";
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe} from "@angular/common";
import {
  FeinPipe,
  FileSizePipe,
  HexaSharedModule,
  MaskLastPipe,
  PhonePipe,
  SsnPipe,
  UtcDatePipe
} from "@hexalang/ui/shared";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {ModalModule as BsModalModule} from "ngx-bootstrap/modal";
import {TabsModule as BsTabModule} from "ngx-bootstrap/tabs";
import {PopoverModule as BsPopoverModule} from "ngx-bootstrap/popover";
import {TimepickerModule as BsTimepickerModule} from "ngx-bootstrap/timepicker";
import {ButtonsModule as BsButtonsModule} from "ngx-bootstrap/buttons";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {NgxMaskModule} from "ngx-mask";
import {NgxPermissionsModule} from "ngx-permissions";
import {FormatService} from "./services/format.service";
import {TranslateModule} from "@ngx-translate/core";
import {NgxStripeModule} from "ngx-stripe";
import {CardDetailsComponent} from './components/card-details/card-details.component';
import {PaymentMethodsComponent} from './components/payment-methods/payment-methods.component';
import {LanguageSelectorComponent} from './components/language-selector/language-selector.component';
import {LandingFooterComponent} from './components/landing-footer/landing-footer.component';
import {LandingIndividualComponent} from './components/landing-individual/landing-individual.component';
import {LandingBusinessComponent} from './components/landing-business/landing-business.component';
import {RouterLinkActive, RouterLinkWithHref} from "@angular/router";
import {AppHeaderComponent} from './components/app-header/app-header.component';
import {SidebarMenuComponent} from './components/sidebar-menu/sidebar-menu.component';
import {NgScrollbar} from "ngx-scrollbar";
import { EmailValidationPopupComponent } from './components/email-validation-popup/email-validation-popup.component';


@NgModule({
  imports: [
    CommonModule,
    HexaSharedModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    BsDropdownModule,
    BsModalModule,
    BsTabModule,
    BsPopoverModule,
    BsTimepickerModule,
    BsButtonsModule,
    TooltipModule,
    NgxMaskModule,
    NgxDatatableModule,
    NgSelectModule,
    NgxPermissionsModule,
    RouterLinkWithHref,
    NgScrollbar,
    RouterLinkActive,
  ],
  declarations: [
    CardDetailsComponent,
    PaymentMethodsComponent,
    LanguageSelectorComponent,
    LandingFooterComponent,
    LandingIndividualComponent,
    LandingBusinessComponent,
    AppHeaderComponent,
    SidebarMenuComponent,
    EmailValidationPopupComponent,

  ],
  exports: [
    HexaSharedModule,
    FormsModule,
    ReactiveFormsModule,
    BsDropdownModule,
    BsDatepickerModule,
    BsModalModule,
    BsTabModule,
    BsPopoverModule,
    BsTimepickerModule,
    BsButtonsModule,
    TooltipModule,
    NgxMaskModule,
    NgxDatatableModule,
    NgSelectModule,
    NgxPermissionsModule,
    TranslateModule,
    NgxStripeModule,
    CardDetailsComponent,
    PaymentMethodsComponent,
    LanguageSelectorComponent,
    LandingFooterComponent,
    LandingIndividualComponent,
    LandingBusinessComponent,
    SidebarMenuComponent,
    AppHeaderComponent,
    NgScrollbar
  ],
  providers: [FormatService, CurrencyPipe, DatePipe, DecimalPipe, FeinPipe, FileSizePipe, PhonePipe, SsnPipe, MaskLastPipe, UtcDatePipe],
})
export class SharedModule {
}
