import {PartYearHelper} from './part-year.helper';

export class WorksheetAHelper {
  protected readonly isPartYear: boolean;
  private filingCity: any;
  private readonly isResident: any;
  private worksheetA: any;
  private readonly ir: any;
  private readonly wages: any;
  private readonly localities: any;
  private readonly localitiesFilingCity: any;
  private readonly localitiesOther: any;
  private readonly partYearHelper;

  constructor(private paymentHelper, filingCity, ir, isResident, worksheetA, isPartYear) {
    worksheetA = worksheetA || {};
    this.filingCity = filingCity;
    this.ir = ir;
    this.isResident = isResident;
    this.isPartYear = isPartYear;
    this.worksheetA = worksheetA;
    this.wages = this.getWages(worksheetA);
    this.localities = this.getLocalities();
    this.localitiesFilingCity = this.localities.filter((l: any) => this.filingCity?.name === l?.taxWithheldLocalityName);
    this.localitiesOther = isResident ? this.localities.filter((l: any) => this.filingCity?.name !== l?.taxWithheldLocalityName) : [];
    this.partYearHelper = new PartYearHelper(paymentHelper, ir.taxYear);
  }

  get hasWages() {
    return this.wages.length > 0;
  }

  get hasOtherCityWages() {
    return this.localitiesOther.length > 0;
  }

  get grandTotalWages() {
    return this.isResident ? this.paymentHelper.sum(this.wages, 'totalBox5Wages', 2) : 0;
  }

  get grandTotalBox1Wages() {
    return this.isResident ? this.paymentHelper.sum(this.wages, 'box1Wages', 2) : 0;
  }

  get grandTotalFillingCityTaxWithheld() {
    return this.paymentHelper.sum(this.localitiesFilingCity, 'taxWithheldBox19', 2);
  }

  get grandTotalOtherCityTaxWithheld() {
    let total = 0;
    for (const wage of this.wages) {
      const loc = [].concat(wage?.w2LocalityInfo);
      const other = loc.filter((l: any) => this.filingCity?.name !== l?.taxWithheldLocalityName);
      if (other.length > 0) {
        const daysWorked = this.partYearHelper.daysWorked(wage.dateWorkedFrom, wage.dateWorkedTo);
        const daysWorkedAsNonResident = wage.type === 'T' ? this.getTaxpayerDaysNonResident(wage.dateWorkedFrom, wage.dateWorkedTo) : this.getSpouseDaysNonResident(wage.dateWorkedFrom, wage.dateWorkedTo);
        for (const locality of other) {
          const perDay = this.paymentHelper.decimalValue(locality.creditForTaxesPaid) / daysWorkedAsNonResident;
          // console.log('perDay', perDay);
          if (wage.under18WagesFlag) {
            const year = this.filingCity?.name === 'Alliance' ? 16 : 18;
            const daysUnder18 = this.partYearHelper.getDaysUnder18(wage?.dateWorkedFrom, wage?.dateWorkedTo, wage?.birthdate, year);
            const daysWorked = daysWorkedAsNonResident - daysUnder18;
            total += (perDay * daysWorked);
          }
            // else if (this.isPartYear) {
            //   total += (perDay * daysWorked);
            //   // console.log('partYear', total);
            //
          // }
          else {
            // console.log('Add', total, locality.creditForTaxesPaid);
            total += this.paymentHelper.decimalValue(locality.creditForTaxesPaid)
          }
        }
      }
    }

    //return this.paymentHelper.sum(this.localitiesOther, 'creditForTaxesPaid', 0);
    // console.log('-----:?>>>', total);
    return total;
  }

  get grandTotalQualifyingWages() {
    if (this.isPartYear) {
      return this.paymentHelper.sum(this.localities, 'wagesBox18')
    }
    const wageAmounts = [];
    for (const wage of this.wages) {
      let wageAmount = this.paymentHelper.decimalValue(wage.totalBox5Wages) === 0 ? this.paymentHelper.decimalValue(wage.box1Wages) : this.paymentHelper.decimalValue(wage.totalBox5Wages);
      wage.w2LocalityInfo = [].concat(wage?.w2LocalityInfo);
      const wagesBox18 = this.getWagesBox18Total(wage.w2LocalityInfo);
      wageAmount = wageAmount > wagesBox18 ? wageAmount : wagesBox18;
      wageAmounts.push(wageAmount);
    }
    return this.paymentHelper.sum(wageAmounts);
  }

  getWagesBox18Total(w2LocalityInfo) {
    return this.paymentHelper.sum(w2LocalityInfo, 'wagesBox18', 2);
  }

  // getLocalitiesWagesBox18(locObject: any) {
  //   if (!locObject) return 0;
  //   if (locObject && locObject.value && locObject.value.length > 0) {
  //     return this.paymentHelper.decimalValue(locObject.value[0].wagesBox18);
  //   }
  //   return this.paymentHelper.decimalValue(locObject.wagesBox18);
  // }

  get grandTotalNonResidentAdjustment() {
    let adjustment = 0;
    if (this.isResident && !this.isPartYear) return adjustment;

    let count = 0;
    // console.log('number of wages', this.wages.length)
    for (const wage of this.wages) {
      count++;
      if (this.getBoolean(wage?.militaryWagesFlag)) continue;
      const incomePerDay = this.incomePerDay(wage);
      // console.log(count, 'incomePerDay', incomePerDay);
      if (incomePerDay === 0) {
        continue;
      }
      const daysWorkedAsNonResident = wage.type === 'T' ? this.getTaxpayerDaysNonResident(wage.dateWorkedFrom, wage.dateWorkedTo) : this.getSpouseDaysNonResident(wage.dateWorkedFrom, wage.dateWorkedTo);
      // console.log('grandTotalNonResidentAdjustment - daysWorkedAsNonResident', daysWorkedAsNonResident);
      adjustment += (incomePerDay * daysWorkedAsNonResident);
      // console.log('grandTotalNonResidentAdjustment- incomePerDay', incomePerDay, 'daysWorkedAsNonResident', daysWorkedAsNonResident);
    }

    return this.paymentHelper.decimalValue(adjustment);
  }

  getBoolean(any) {
    return (
      any === true ||
      any === "true" ||
      any === "TRUE" ||
      any === "X" ||
      any === "x" ||
      any === 1 ||
      any === "1" ||
      any === "Y"
    );
  }

  get grandTotalScheduleExcludibleWages() {
    const excludible = this.wages.filter(w => w.excludedWagesFlag);
    return this.paymentHelper.sum(excludible, 'excludedWages', 2);
  }

  get grandTotalScheduleTaxableWages() {
    const box1Wages = this.grandTotalBox1Wages;
    const totalBox5Wages = this.grandTotalWages;
    let wageAmount = totalBox5Wages === 0 ? box1Wages : totalBox5Wages;
    const sumAmounts = this.paymentHelper.sum(this.localities, 'wagesBox18', 2);
    return this.isPartYear ? sumAmounts : wageAmount > sumAmounts ? wageAmount : sumAmounts; // passing 0
  }

  get grandTotalTaxCreditAllowableOtherCities() {
    const creditAllowableCalculated = this.localitiesOther.map((locality) =>
      this.paymentHelper.decimalValue(locality.wagesBox18 * locality.taxRate)
    );
    const entered = this.paymentHelper.sum(this.localities, 'creditForTaxesPaid'); //passing 0
    return entered > 0 ? entered : this.paymentHelper.sum(creditAllowableCalculated, 2);
  }

  getTaxpayerDaysNonResident(dateWorkedFrom, dateWorkedTo) {
    if (!this.isPartYear) return 365;
    const days = this.partYearHelper.daysWorkedAsNonResident(this.ir?.partYearFromDate, this.ir?.partYearToDate, dateWorkedFrom, dateWorkedTo);
    // console.log('getTaxpayerDaysNonResident', days)
    return days;
  }

  getSpouseDaysNonResident(dateWorkedFrom, dateWorkedTo) {
    if (!this.isPartYear) return 365;
    const days = this.partYearHelper.daysWorkedAsNonResident(this.ir?.partYearFromDateSpouse, this.ir?.partYearToDateSpouse, dateWorkedFrom, dateWorkedTo);
    // console.log('getSpouseDaysNonResident', days)
    return days;
  }

  getWages(worksheetA) {
    const taxpayer = worksheetA?.taxpayerScheduleOfWages ? Array.isArray(worksheetA?.taxpayerScheduleOfWages) ? worksheetA?.taxpayerScheduleOfWages : [worksheetA.taxpayerScheduleOfWages] : [];
    const spouse = worksheetA?.spouseScheduleOfWages ? Array.isArray(worksheetA?.spouseScheduleOfWages) ? worksheetA?.spouseScheduleOfWages : [worksheetA?.spouseScheduleOfWages] : [];

    const wages = taxpayer.concat(spouse);
    for (const wage of wages) {
      wage.locality = Array.isArray(wage?.w2LocalityInfo) ? wage?.w2LocalityInfo : [wage?.w2LocalityInfo];
    }
    return wages;
  }

  getLocalities() {
    const localities = this.wages.map((wage) => wage?.w2LocalityInfo).flat() || [];
    for (const loc of localities) {
      loc.taxWithheldBox19 = +loc?.taxWithheldBox19 === +loc?.wagesBox18 ? 0 : loc.taxWithheldBox19;
    }
    return localities;
  }

  incomePerDay(wage) {
    const wageAmount = this.getWageAmount(wage);
    if (wageAmount === 0) return 0;
    const daysWorked = this.partYearHelper.daysWorked(wage.dateWorkedFrom, wage.dateWorkedTo);
    return this.paymentHelper.decimalValue(wageAmount / daysWorked, 4);
  }

  getWageAmount(wage) {
    let wageAmount = this.paymentHelper.decimalValue(wage.totalBox5Wages) === 0 ? this.paymentHelper.decimalValue(wage.box1Wages) : this.paymentHelper.decimalValue(wage.totalBox5Wages);
    if (wage.multipleLocations) {
      wageAmount = 0;
    }
    const filingCityWages = wage?.w2LocalityInfo;
    if (filingCityWages.length === 0) return 0;
    if (!this.otherLocalityThenFillingCity(filingCityWages)) return 0;
    let totalLoc = 0;
    for (const loc of filingCityWages) {
      if (loc.taxWithheldLocalityName !== this.filingCity.name) {
        totalLoc += this.paymentHelper.decimalValue(loc.wagesBox18, 2);
      }
    }
    wageAmount = wageAmount > totalLoc ? wageAmount : totalLoc;
    return wageAmount;
  }

  otherLocalityThenFillingCity(filingCityWages) {
    return filingCityWages.find((loc) => loc.taxWithheldLocalityName !== this.filingCity.name);
  }
}

