import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {HexaDetailComponent} from "@hexalang/ui/core";
import {FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {DateFormControl, EPermission, FormatService} from "@city-tax/shared";
import {BehaviorSubject, forkJoin, Observable, Subscription, takeUntil, tap} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {IndividualService} from "../../../../../services/individual.service";
import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {NgxPermissionsService} from "ngx-permissions";
import * as moment from 'moment';

@Component({
  selector: "city-tax-feature-start",
  templateUrl: "./individual-filing-oh-start.component.html",
  styleUrls: ["./individual-filing-oh-start.component.scss"],
})
export class IndividualFilingOhStartComponent extends HexaDetailComponent implements OnInit, OnDestroy {

  public form: FormGroup;
  public title$;
  public taxYear = this.individualService.taxYear;
  public currentYear = this.individualService.currentYear;
  public individual$ = this.authService.individual$;
  public eFileDeadline = this.authService.eFileDeadline(this.taxYear);
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public fillingCityTaxRatePercent = 0.02;
  public fillingCityCreditRatePercent = 2.0;
  public minAmountOfTaxDue = 10;
  public penalty = 0;
  public interest = 0;
  public ePermission = EPermission;
  public config = this.authService.config;
  public hasPermission$: Observable<boolean>;
  public isLoading$ = this.individualService.isLoading$;
  public isUploading$ = this.individualService.isUploading$;
  public error = null;
  public taxDocument = this.authService.organization.taxDocument;
  public city = this.authService.organization;

  public individualReturn$ = this.individualService.individualReturn$;
  public minDate = moment(`${this.taxYear}-01-01`, 'YYYY-MM-DD').endOf('day').toDate();
  public maxDate = moment(`${this.taxYear}-12-31`, 'YYYY-MM-DD').endOf('day').toDate();
  changeDetectorRef: any;
  private routeSubscription: Subscription;

  constructor(
    injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private individualService: IndividualService,
    private authService: AuthService,
    public toastrService: ToastrService,
    public permissionService: NgxPermissionsService,
    public formatService: FormatService,
    private formBuilder: FormBuilder
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.individualReturn$.subscribe((ir) => {
      this.initForm();
      this.patchForm();
    });
  }

  ngOnDestroy() {
  }

  async next(needRedirection = true) {
    let path = `/individual/filings/oh/${this.individualService.taxYear}/page1`;
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      await this.router.navigate([path]);
    }
    this.getFormValidationErrors(this.form);
    if (this.form.valid) {
      const payload = this.form.getRawValue();
      payload.resident = payload.residency !== "N";
      payload.nonResident = payload.residency === "N";

      if (payload.filing === 'MS' && payload.spouse) {
        payload.spouse.individualId = null;
      }

      payload.source = 'Fill';
      if (payload.residency !== "P") {
        payload.partYearFromDate = null;
        payload.partYearToDate = null;
        payload.partYearFromDateSpouse = null;
        payload.partYearToDateSpouse = null;
      }

      try {
        // await this.authService.updateIndividual(this.city.id, payload.taxpayer);
        await this.individualService.updateReturn(this.city.id, payload);
        needRedirection && await this.router.navigate([path]);
      } catch (error) {
        console.log(error)
      }
    }
    this.form.markAllAsTouched();
  }

  async upload() {
    this.getFormValidationErrors(this.form);
    if (this.form.valid) {
      const payload = this.form.getRawValue();
      payload.source = 'Upload';
      payload.resident = payload.residency !== "N";
      return forkJoin([
        // this.authService.updateIndividual(this.authService.organization.id, payload.taxpayer),
        this.individualService.updateReturn(this.authService.organization.id, payload),
      ]).pipe(
        takeUntil(this.destroy$),
        tap(async () => {
          let path = `/individual/filings/${this.authService.config?.state.toLowerCase()}/${
            this.individualService.taxYear
          }`;
          path += "/upload";
          await this.router.navigate([path]);
        }),
      )
        .subscribe();
    } else {
      this.form.markAllAsTouched();
    }
  }

  getFormValidationErrors(form) {
    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      if (control.controls) {
        this.getFormValidationErrors(control);
      }
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }

  addFormValidators() {
    const filing = this.individualService.individualReturn?.filing;
    const residency = this.individualService.individualReturn?.residency;

    if (filing === "MJ") {
      this.form.get("spouse.name").addValidators([Validators.required]);
      this.form.get("spouse.name").updateValueAndValidity();
      this.form.get("spouse.ssn").addValidators([Validators.required]);
      this.form.get("spouse.ssn").updateValueAndValidity();
    } else if (filing === "MS") {
      this.form.get("spouse.name").addValidators([Validators.required]);
      this.form.get("spouse.name").updateValueAndValidity();
    } else if (filing === "S") {
      this.form.get("spouse.name").removeValidators([Validators.required]);
      this.form.get("spouse.name").updateValueAndValidity();
      this.form.get("spouse.ssn").removeValidators([Validators.required]);
      this.form.get("spouse.ssn").updateValueAndValidity();
      this.form.get("partYearFromDateSpouse").removeValidators(Validators.required);
      this.form.get("partYearToDateSpouse").removeValidators(Validators.required);
      this.form.get("partYearFromDateSpouse").updateValueAndValidity();
      this.form.get("partYearToDateSpouse").updateValueAndValidity();
    }

    if (residency === "P" && filing === "S") {
      this.form.get("partYearFromDate").addValidators(Validators.required);
      this.form.get("partYearToDate").addValidators(Validators.required);
      this.form.get("partYearFromDate").updateValueAndValidity();
      this.form.get("partYearToDate").updateValueAndValidity();
    } else if (residency === "P" && filing !== "S") {
      this.form.get("partYearFromDate").addValidators(Validators.required);
      this.form.get("partYearToDate").addValidators(Validators.required);
      this.form.get("partYearFromDateSpouse").addValidators(Validators.required);
      this.form.get("partYearToDateSpouse").addValidators(Validators.required);
      this.form.get("partYearFromDate").updateValueAndValidity();
      this.form.get("partYearToDate").updateValueAndValidity();
      this.form.get("partYearFromDateSpouse").updateValueAndValidity();
      this.form.get("partYearToDateSpouse").updateValueAndValidity();
    } else if (residency === "P") {
      this.form.get("partYearFromDateSpouse").removeValidators(Validators.required);
      this.form.get("partYearToDateSpouse").removeValidators(Validators.required);
      this.form.get("partYearFromDate").removeValidators(Validators.required);
      this.form.get("partYearToDate").removeValidators(Validators.required);

    }
  }

  formatEmail(email: string) {
    if (!email) return null;
    return email.replace(/(?<=^.)[^@]*|(?<=@.).*(?=\.[^.]+$)/gm, (match) =>
      "*".repeat(match.length)
    );
  }

  unlink() {
    this.individualService.individualReturn.spouse = null;
    this.form.get("spouse").reset({});
  }

  checkResidencyChange() {
    this.form.get("residency")?.valueChanges?.subscribe((residency) => {
      const filing = this.form.get("filing").value;

      if (residency === "P" && filing === "S") {
        this.form.get("partYearFromDate").addValidators(Validators.required);
        this.form.get("partYearToDate").addValidators(Validators.required);
        this.form.get("partYearFromDate").updateValueAndValidity();
        this.form.get("partYearToDate").updateValueAndValidity();
      } else if (residency === "P" && filing !== "S") {
        this.form.get("partYearFromDate").addValidators(Validators.required);
        this.form.get("partYearToDate").addValidators(Validators.required);
        this.form.get("partYearFromDateSpouse").addValidators(Validators.required);
        this.form.get("partYearToDateSpouse").addValidators(Validators.required);
        this.form.get("partYearFromDate").updateValueAndValidity();
        this.form.get("partYearToDate").updateValueAndValidity();
        this.form.get("partYearFromDateSpouse").updateValueAndValidity();
        this.form.get("partYearToDateSpouse").updateValueAndValidity();
      } else {
        this.form.get("partYearFromDate").removeValidators(Validators.required);
        this.form.get("partYearToDate").removeValidators(Validators.required);
        this.form.get("partYearFromDateSpouse").removeValidators(Validators.required);
        this.form.get("partYearToDateSpouse").removeValidators(Validators.required);
        this.form.get("partYearFromDate").updateValueAndValidity();
        this.form.get("partYearToDate").updateValueAndValidity();
        this.form.get("partYearFromDateSpouse").updateValueAndValidity();
        this.form.get("partYearToDateSpouse").updateValueAndValidity();
      }
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      id: null,
      taxYear: null,
      eFileDeadline: null,
      taxpayer: this.formBuilder.group({
        accountNumber: null,
        address: this.formBuilder.group({
          addressLine1: [null, Validators.required],
          addressLine2: null,
          aptNumber: null,
          city: [null, Validators.required],
          state: [null, Validators.required],
          postalCode: [null, Validators.required],
        }),
        ssn: [null, Validators.required],
        name: [null, Validators.required],
        deceased: null,
        email: [null, Validators.required],
        birthdate: null,
        emailNotifications: null,
        occupation: null,
        dateOfDeath: null,
        phone: null,
        individualId: null,
      }),
      spouse: this.formBuilder.group({
        accountNumber: null,
        ssn: null,
        name: null,
        deceased: null,
        birthdate: null,
        email: null,
        occupation: null,
        dateOfDeath: null,
        phone: null,
        address: this.formBuilder.group({
          addressLine1: null,
          addressLine2: null,
          aptNumber: null,
          city: null,
          state: null,
          postalCode: null,
        }),
        individualId: null,
      }),
      previousAddress: this.formBuilder.group({
        addressLine1: null,
        addressLine2: null,
        aptNumber: null,
        city: null,
        state: null,
        postalCode: null,
      }),
      residency: [null, Validators.required],
      partYearFromDate: new DateFormControl(''),
      partYearToDate: new DateFormControl(''),
      partYearFromDateSpouse: new DateFormControl(''),
      partYearToDateSpouse: new DateFormControl(''),
      resident: null,
      // priorYearOverpayment: null,
      // estimatedTaxPaid: null,
      ownProperty: null,
      filing: [null, Validators.required],
    });
    this.form.get("filing")?.valueChanges?.subscribe((filing) => {
      if (filing == "MJ") {
        this.form.get("spouse.name").addValidators([Validators.required]);
        this.form.get("spouse.name").updateValueAndValidity();
        this.form.get("spouse.ssn").addValidators([Validators.required]);
        this.form.get("spouse.ssn").updateValueAndValidity();
      } else if (filing == "MS") {
        this.form.get("spouse.name").addValidators([Validators.required]);
        this.form.get("spouse.name").updateValueAndValidity();
        this.form.get("spouse.ssn").removeValidators([Validators.required]);
        this.form.get("spouse.ssn").updateValueAndValidity();
      } else {
        this.form.get("spouse.name").removeValidators([Validators.required]);
        this.form.get("spouse.name").updateValueAndValidity();
        this.form.get("spouse.ssn").removeValidators([Validators.required]);
        this.form.get("spouse.ssn").updateValueAndValidity();
      }
    });
  }

  private patchForm() {
    this.form.patchValue(this.individualService.individualReturn);
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      this.isDisabled$.next(true)
      this.form.disable();
    } else {
      this.addFormValidators();
      this.checkResidencyChange();
    }
  }

  public switchTaxpayerSpouse(individualReturn) {
    // Get the form controls for taxpayer and spouse
    const taxpayerControl = this.form.get('taxpayer') as FormGroup;
    const spouseControl = this.form.get('spouse') as FormGroup;

    if (taxpayerControl && spouseControl) {
      // Temporarily store the taxpayer's value
      const taxpayerValue = taxpayerControl.value;
      const spouseValue = spouseControl.value;


      const taxpayer ={
        ssn: taxpayerValue.ssn,
        name: taxpayerValue.name,
        deceased: taxpayerValue.deceased,
        birthdate: taxpayerValue.birthdate,
        emailNotifications: taxpayerValue.emailNotifications,
        occupation: taxpayerValue.occupation,
        dateOfDeath: taxpayerValue.dateOfDeath,
        phone: taxpayerValue.phone,
        individualId: taxpayerValue.individualId,
      }

      const spouse ={
        ssn: spouseValue.ssn,
        name: spouseValue.name,
        deceased: spouseValue.deceased,
        birthdate: spouseValue.birthdate,
        emailNotifications: spouseValue.emailNotifications,
        occupation: spouseValue.occupation,
        dateOfDeath: spouseValue.dateOfDeath,
        phone: spouseValue.phone,
        individualId: spouseValue.individualId,
      }

      // Swap values between taxpayer and spouse
      taxpayerControl.patchValue(spouse);
      spouseControl.patchValue(taxpayer);

    }

    // Call the next function, passing the desired argument
    this.next(false);
  }

}
