import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {SharedModule} from "@city-tax/shared";
import {TranslateModule} from "@ngx-translate/core";
import {AuthIndSignInComponent} from "./components/auth-ind-sign-in/auth-ind-sign-in.component";
import {AuthIndSignUpComponent} from "./components/auth-ind-sign-up/auth-ind-sign-up.component";
import {AuthBusSignUpComponent} from "./components/auth-bus-sign-up/auth-bus-sign-up.component";
import {AuthBusSignInComponent} from "./components/auth-bus-sign-in/auth-bus-sign-in.component";
import {AuthBusOtpComponent} from "./components/auth-bus-otp/auth-bus-otp.component";
import {AuthBusPendingComponent} from "./components/auth-bus-pending/auth-bus-pending.component";
import {RequestFormComponent} from "./components/request-form/request-form.component";
import {AddMemberComponent} from "./components/add-member/add-member.component";
import {SS4Component} from "./components/ss4/ss4.component";
import {AccountConfirmationComponent} from "./components/account-confirmation/account-confirmation.component";

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    SharedModule,
  ],
  declarations: [
    AuthIndSignInComponent,
    AuthIndSignUpComponent,
    AuthBusSignUpComponent,
    AuthBusSignInComponent,
    AuthBusPendingComponent,
    AuthBusOtpComponent,
    RequestFormComponent,
    AddMemberComponent,
    SS4Component,
    AccountConfirmationComponent
  ],
  exports: [
    AuthIndSignInComponent,
    AuthIndSignUpComponent,
    AuthBusSignUpComponent,
    AuthBusSignInComponent,
    AuthBusPendingComponent,
    AuthBusOtpComponent,
  ],
  providers: [],
})
export class AuthModule {
}
