<form
    (ngSubmit)="submit()"
    [formGroup]="form"
    class="d-flex flex-column flex-fill"
    novalidate
>
  <div class="modal-header align-items-center">
    <h4 class="modal-title pull-left">{{ 'Taxpayer.withholding-type' | translate }}</h4>
    <a (click)="modalRef.hide()" class="text-muted" href="javascript:">
      <svg class="icon" viewBox="0 0 24 24">
        <path
            d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
            stroke="none"
        />
      </svg>
    </a>
  </div>
  <div class="modal-body">

    <div class="row pb-2 px-2 text-sm">
      {{ 'Taxpayer.courtesy-withholding-tax-withheld' | translate }}
    </div>

    <div class="px-5">
      <div class="row py-3">
        <input
            [class.is-invalid]="form.get('multipleLocations').invalid"
            class="custom-control-input"
            formControlName="multipleLocations"
            id="CourtesyWithholding"
            name="multipleLocations"
            type="radio"
            [attr.aria-invalid]="form.get('multipleLocations').invalid ? 'true' : 'false'"
            [attr.aria-label]="'Taxpayer.courtesy-withholding' | translate"
            [attr.aria-required]="'true'"
            value="false"
        />
        <label class="custom-control-label" for="CourtesyWithholding">{{ 'Taxpayer.courtesy-withholding' | translate }}</label>
      </div>


      <div class="row">
        <input
            [class.is-invalid]="form.get('multipleLocations').invalid"
            class="custom-control-input"
            formControlName="multipleLocations"
            id="multipleLocations"
            name="multipleLocations"
            type="radio"
            value="true"
            [attr.aria-invalid]="form.get('multipleLocations').invalid ? 'true' : 'false'"
            [attr.aria-label]="'Taxpayer.Worked-in-multiple-locations' | translate"
            [attr.aria-required]="'true'"
        />
        <label class="custom-control-label" for="multipleLocations">
          {{ 'Taxpayer.Worked-in-multiple-locations' | translate }}
        </label>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <a (click)="modalRef.hide()" 
       class="btn btn-link" 
       href="javascript:"
       role="button" 
       tabindex="0" 
       [attr.aria-label]="'Taxpayer.close' | translate">{{ 'Taxpayer.close' | translate }}
    </a>
    <button [disabled]="form.invalid" 
       class="btn btn-primary" 
       type="submit"
       [attr.aria-disabled]="form.invalid" 
       [attr.aria-label]="'Taxpayer.submit' | translate"
       tabindex="0">
       {{ 'Taxpayer.submit' | translate }}
    </button>
  </div>
</form>
