import {Component, Injector, OnDestroy, OnInit} from "@angular/core";
import {EPermission} from "@city-tax/shared";
import {Observable} from "rxjs";
import {NgxPermissionsService} from "ngx-permissions";
import {HexaDetailComponent, HexaWithPermissions} from "@hexalang/ui/core";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: "city-tax-feature-individual-filing-oh",
  templateUrl: "./individual-filing-oh.component.html",
  styleUrls: ["./individual-filing-oh.component.scss"],
})
export class IndividualFilingOhComponent
  extends HexaWithPermissions(HexaDetailComponent)
  implements OnInit, OnDestroy {
  public ePermission = EPermission;
  public hasPermission$: Observable<any>;


  constructor(
    injector: Injector,
    public toastrService: ToastrService,
    public permissionService: NgxPermissionsService,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.checkPermission(EPermission.INDIVIDUAL_FILING_OH);

  }


}
