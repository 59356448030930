export class WagesHelper {
  protected readonly isPartYear: boolean;
  private readonly wages: any;
  private readonly localitiesOther: any;
  private readonly localities: any;

  constructor(private paymentHelper, wagesSalariesTips) {
    this.wages = this.getWages(wagesSalariesTips);
    this.localities = this.getLocalities();
  }

  get grandTotalScheduleExcludibleWages() {
    return this.paymentHelper.sum(this.wages, 'excludibleWages', 0);
  }

  get grandTotalScheduleTaxWithheld() {
    return this.paymentHelper.sum(this.localities, 'taxWithheldBox19', 0);
  }

  get grandTotalScheduleTaxableWages() {
    return this.paymentHelper.sum(this.wages, 'taxableWages', 0);
  }

  get grandTotalScheduleWages() {
    const wages = this.paymentHelper.sum(this.wages, 'totalWages', 0);
    const wagesNotInBox = this.paymentHelper.sum(this.wages, 'wagesNotInBox', 0);
    return wages + wagesNotInBox;
  }

  get totalWagesA(){
    return this.grandTotalScheduleWages;
  }

  get grandTotalScheduleTaxableWagesNonResident() {
    return 0;
  }

  get taxpayerWagesSalariesTips() {
    return this.getWagesSalariesTipsByType("T");
  }

  get spouseWagesSalariesTips() {
    return this.getWagesSalariesTipsByType("S");
  }

  getWages(wagesSalariesTips: any) {
    const taxpayer = wagesSalariesTips?.taxpayerScheduleOfWages ? Array.isArray(wagesSalariesTips?.taxpayerScheduleOfWages) ? wagesSalariesTips?.taxpayerScheduleOfWages : [wagesSalariesTips.taxpayerScheduleOfWages] : [];
    const spouse = wagesSalariesTips?.spouseScheduleOfWages ? Array.isArray(wagesSalariesTips?.spouseScheduleOfWages) ? wagesSalariesTips?.spouseScheduleOfWages : [wagesSalariesTips?.spouseScheduleOfWages] : [];
    const scheduleOfWages = wagesSalariesTips?.scheduleOfWages ? Array.isArray(wagesSalariesTips?.scheduleOfWages) ? wagesSalariesTips?.scheduleOfWages : [wagesSalariesTips?.scheduleOfWages] : [];
    const wages = scheduleOfWages.length > 0 ? scheduleOfWages : taxpayer.concat(spouse);
    for (const wage of wages) {
      wage.w2LocalityInfo = Array.isArray(wage?.w2LocalityInfo) ? wage?.w2LocalityInfo : [wage?.w2LocalityInfo];
    }
    return wages;
  }

  getLocalities() {
    const localities = this.wages.map((wage: any) => wage?.w2LocalityInfo).flat() || [];
    for (const loc of localities) {
      loc.taxWithheldBox19 = +loc?.taxWithheldBox19 === +loc?.wagesBox18 ? 0 : loc.taxWithheldBox19;
    }
    return localities;
  }

  getWagesSalariesTipsByType(type) {
    return this.wages.filter((w) => w.type === type);
  }

  getWagesSalariesTips() {
    return {
      grandTotalScheduleTaxWithheld: this.grandTotalScheduleTaxWithheld,
      grandTotalScheduleWages: this.grandTotalScheduleWages,
      grandTotalScheduleTaxableWages: this.grandTotalScheduleTaxableWages,
      grandTotalScheduleExcludibleWages: this.grandTotalScheduleExcludibleWages,
      grandTotalScheduleTaxableWagesNonResident: this.grandTotalScheduleTaxableWagesNonResident,
      taxpayerScheduleOfWages: this.taxpayerWagesSalariesTips,
      spouseScheduleOfWages: this.spouseWagesSalariesTips

    }
  }

}

