import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'city-tax-feature-percent',
  templateUrl: './percent.component.html',
  styleUrls: ['./percent.component.scss']
})
export class PercentComponent implements OnInit {
  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public modalRef: BsModalRef
  ) {

  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      percent: null,
    });
  }

  submit() {
    this.modalRef.content = this.form.get('percent').value;
    this.modalRef.hide()
  }
}
