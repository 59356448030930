<div *ngIf="(individualReturn$ | async)?.status === 'Completed'"
    class="card p-3 position-absolute return-filed m-0 text-center">
  Filed
</div>
<div *ngIf="(individualReturn$ | async)?.status === 'Mailed'"
    class="card p-3 position-absolute return-filed m-0 text-center">
  Mailed
</div>
<div *ngIf="(individualReturn$ | async)?.status !=='Not found'" class="container py-3">
  <form *ngIf="individualReturn$ | async as individualReturn" [formGroup]="form" novalidate>
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="d-flex align-items-center justify-content-center">
          <h5 class="text-center mb-0 font-weight-bolder"> {{ individualReturn?.taxYear }} {{ "Taxpayer.individual-income-tax" | translate }}</h5>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="d-flex align-items-center justify-content-center">
          <div class="text-center font-weight-bolder">{{ "Taxpayer.individual-return-due" | translate }} {{ individualReturn?.eFileDeadline | date:'MMMM dd, YYYY' }}</div>
        </div>
      </div>
    </div>
    <div class="row py-3">
      <div class="col-xl-8 col-lg-7">
        <div class="row p-xl-3">
          <div class="col-12">
            <label for="filing-status" class="form-label">
              <span class="font-weight-bold text-uppercase">{{ "Taxpayer.filing-status" | translate }}</span></label>
          </div>
          <div class="col-12 pb-3">
            <div class="row pb-2 pl-4 border-bottom">
              <div class="col-sm-2">
                <input
                    [class.is-invalid]="form.get('filing').invalid"
                    class="custom-control-input"
                    formControlName="filing"
                    id="S"
                    name="filing"
                    type="radio"
                    value="S"
                    [attr.aria-label]="'Taxpayer.single' | translate"
                    [attr.aria-required]="'true'"
                    [attr.aria-invalid]="form.get('filing').invalid ? 'true' : 'false'"
                />
                <label class="custom-control-label" for="S">{{ "Taxpayer.single" | translate }}</label>
              </div>
              <div class="col-lg-4 col-md-5 col-sm-5 my-sm-0 my-3">
                <input
                    [class.is-invalid]="form.get('filing').invalid"
                    class="custom-control-input"
                    formControlName="filing"
                    id="MJ"
                    name="filing"
                    type="radio"
                    value="MJ"
                    [attr.aria-label]="'Taxpayer.married-filing-jointly' | translate"
                    [attr.aria-required]="'true'"
                    [attr.aria-invalid]="form.get('filing').invalid ? 'true' : 'false'"
                />
                <label class="custom-control-label" for="MJ">
                  {{ "Taxpayer.married-filing-jointly" | translate }}
                </label>
              </div>
              <div class="col-lg-4 col-md-5 col-sm-5">
                <input
                    [class.is-invalid]="form.get('filing').invalid"
                    class="custom-control-input"
                    formControlName="filing"
                    id="MS"
                    name="filing"
                    type="radio"
                    value="MS"
                    [attr.aria-label]="'Taxpayer.married-filing-separately' | translate"
                    [attr.aria-required]="'true'"
                    [attr.aria-invalid]="form.get('filing').invalid ? 'true' : 'false'"
                />
                <label class="custom-control-label" for="MS">
                  {{ "Taxpayer.married-filing-separately" | translate }}
                </label>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="small font-weight-medium text-muted text-uppercase">
                    {{ "Taxpayer.taxpayer-account" | translate }} #:
                  </label>
                  <ng-container *ngIf="individualReturn?.taxpayer?.accountNumber">
                    <div>{{ individualReturn?.taxpayer?.accountNumber }}</div>
                  </ng-container>
                  <!--                    <ng-container *ngIf="!individualReturn?.taxpayer?.accountNumber">-->
                  <!--                      <ui-input-selector-->
                  <!--                          [controlForm]="form"-->
                  <!--                          [data]="individualReturn?.review"-->
                  <!--                          class="w-100"-->
                  <!--                          controlClass="form-control bg-light"-->
                  <!--                          controlName="accountNumber"-->
                  <!--                          groupName="taxpayer"-->
                  <!--                      >-->
                  <!--                      </ui-input-selector>-->
                  <!--                    </ng-container>-->
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="small font-weight-medium text-muted text-uppercase">
                    {{ "Taxpayer.taxpayer-ssn" | translate }}:
                  </label>
                  <div class="row align-items-end">
                    <div class="col-12"> XXX-XX-{{ individualReturn?.taxpayer?.ssn.substring(5, 9) }}</div>
                  </div>
                  <!--                      <ui-input-selector-->
                  <!--                          groupName="taxpayer"-->
                  <!--                          controlName="ssn"-->
                  <!--                          placeholder="000-00-0000"-->
                  <!--                          controlClass="form-control bg-light"-->
                  <!--                          required="true"-->
                  <!--                          isSSN="true"-->
                  <!--                          [controlForm]="form"-->
                  <!--                          [data]="individualReturn?.review"-->
                  <!--                          class="w-100"-->
                  <!--                      >-->
                  <!--                      </ui-input-selector>-->
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label class="small font-weight-medium text-muted text-uppercase">
                    {{ "Taxpayer.taxpayer-name" | translate }}:
                  </label>
                  <div> {{ individualReturn?.taxpayer?.name }}</div>
                  <!--                    <ui-input-selector-->
                  <!--                        groupName="taxpayer"-->
                  <!--                        controlName="name"-->
                  <!--                        controlClass="form-control bg-light"-->
                  <!--                        class="w-100"-->
                  <!--                        [controlForm]="form"-->
                  <!--                        [data]="individualReturn?.review"-->
                  <!--                    >-->
                  <!--                    </ui-input-selector>-->
                </div>
              </div>
              <!--                <div class="col-sm-6">-->
              <!--                  <div class="form-group" *ngIf="!individualReturn?.taxpayer?.birthdate">-->
              <!--                    <label class="small font-weight-medium text-muted text-uppercase">-->
              <!--                      Taxpayer Birthdate:-->
              <!--                       <span class="text-danger pl-1">*</span>-->
              <!--                    </label>-->
              <!--                    <ui-input-selector-->
              <!--                        groupName="taxpayer"-->
              <!--                        controlName="birthdate"-->
              <!--                        controlClass="form-control bg-light"-->
              <!--                        isDate="true"-->
              <!--                        placeholder="mm/dd/yyyy"-->
              <!--                        class="w-100"-->
              <!--                        [controlForm]="form"-->
              <!--                        [data]="individualReturn?.review"-->
              <!--                    >-->
              <!--                    </ui-input-selector>-->
              <!--                  </div>-->
              <!--                </div>-->
              <!--                <div class="col-12 col-xl-6">-->
              <!--                  <div class="form-group" *ngIf="!individualReturn?.taxpayer?.phone">-->
              <!--                    <label class="small font-weight-medium text-muted text-uppercase">-->
              <!--                      Phone:-->
              <!--                       <span class="text-danger pl-1">*</span>-->
              <!--                    </label>-->
              <!--                    <ui-input-selector-->
              <!--                        groupName="taxpayer"-->
              <!--                        controlName="phone"-->
              <!--                        controlClass="form-control bg-light"-->
              <!--                        class="w-100"-->
              <!--                        [controlForm]="form"-->
              <!--                        [data]="individualReturn?.review"-->
              <!--                        mask="(000) 000-0000"-->
              <!--                        placeholder="(000) 000-0000"-->
              <!--                    >-->
              <!--                    </ui-input-selector>-->
              <!--                  </div>-->
              <!--                </div>-->
              <div class="col-12">
                <div class="form-group">
                  <label for="email" class="small font-weight-medium text-muted text-uppercase">
                    {{ "Taxpayer.email" | translate }}:
                  </label>
                  <ng-container *ngIf="individualReturn?.taxpayer?.email">
                    <div [attr.aria-live]="'polite'"> {{ formatEmail(individualReturn?.taxpayer?.email) }}</div>
                  </ng-container>
                  <ng-container *ngIf="!individualReturn?.taxpayer?.email">
                    <ui-input-selector
                        [controlForm]="form"
                        [data]="individualReturn?.review"
                        class="w-100"
                        controlClass="form-control bg-light"
                        controlName="email"
                        groupName="taxpayer"
                        [attr.aria-label]="'Taxpayer.email' | translate"
                    >
                    </ui-input-selector>
                  </ng-container>
                  <div class="pt-1 pl-4" formGroupName="taxpayer">
                    <input
                        class="custom-control-input"
                        formControlName="emailNotifications"
                        id="emailNotifications"
                        type="checkbox"
                        [value]="true"
                        [attr.aria-label]="'Taxpayer.email-notifications' | translate"
                    />
                    <label class="custom-control-label" for="emailNotifications">{{ "Taxpayer.email-notifications" | translate }}</label>
                  </div>
                </div>
              </div>
              <div *ngIf="form.get('filing').value !=='S'" class="col-sm-6">
                <div class="form-group">
                  <label [for]="individualReturn?.spouse?.ssn ? 'spouseSSNValue' : 'spouseSSNInput'"  class="small font-weight-medium text-muted text-uppercase">
                    {{ "Taxpayer.spouse-ssn" | translate }}:
                    <span class="text-danger pl-1">*</span>
                  </label>
                  <ng-container *ngIf="individualReturn?.spouse?.ssn">
                    <div 
                      id="spouseSSNValue"  
                      [attr.aria-labelledby]="'Taxpayer.spouse-ssn' | translate"
                      [attr.aria-label]="'Taxpayer.spouse-ssn-masked' | translate"> XXX-XX-{{ individualReturn?.spouse?.ssn.substring(5, 9) }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!individualReturn?.spouse?.ssn">
                    <ui-input-selector
                        id="spouseSSNInput"
                        [controlForm]="form"
                        [data]="individualReturn?.review"
                        class="w-100"
                        controlClass="form-control bg-light"
                        controlName="ssn"
                        groupName="spouse"
                        isSSN="true"
                        mask="000-00-0000"
                        placeholder="000-00-0000"
                        required="true"
                        [attr.aria-required]="'true'"
                        [attr.aria-label]="'Taxpayer.spouse-ssn' | translate"
                    >
                    </ui-input-selector>
                  </ng-container>
                </div>
              </div>
              <div *ngIf="form.get('filing').value!=='S'" class="col-xl-6 col-12">
                <div class="form-group">
                  <label 
                      class="small font-weight-medium text-muted text-uppercase" 
                      [for]="individualReturn?.spouse?.name ? 'spouseNameValue' : 'spouseNameInput'" >
                      {{ "Taxpayer.spouse-name" | translate }}:
                    <span class="text-danger pl-1">*</span>
                  </label>
                  <ng-container *ngIf="individualReturn?.spouse?.name">
                    <div  
                      id="spouseNameValue" 
                      [attr.aria-label]="'Taxpayer.spouse-name' | translate"> {{ individualReturn?.spouse?.name }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!individualReturn?.spouse?.name">
                    <ui-input-selector
                        [controlForm]="form"
                        [data]="individualReturn?.review"
                        class="w-100"
                        controlClass="form-control bg-light"
                        controlName="name"
                        groupName="spouse"
                        required="true"
                        id="spouseNameInput"
                        [attr.aria-label]="'Taxpayer.spouse-name' | translate"
                        [attr.aria-required]="'true'"
                    >
                    </ui-input-selector>
                  </ng-container>
                </div>
              </div>
              <div *ngIf="form.get('filing').value!=='S' && individualReturn?.spouse?.ssn" class="col-xl-6 col-12">
                <div class="form-group">
                  <a (click)="unlink()" href="javascript:">
                    <svg class="bi bi-link" fill="currentColor" height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                      <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>
                      <path d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z" fill="red" fill-rule="evenodd"/>
                    </svg>
                    {{ "Taxpayer.unlink-spouse" | translate }}
                  </a>
                </div>
              </div>
              <div *ngIf="form.get('filing').value === 'MJ' && form.get('spouse.individualId').value" class="col-xl-6 col-12">
                <a (click)="switchTaxpayerSpouse(individualReturn)" href="javascript:">{{ "Taxpayer.switch-to-primary-taxpayer" | translate }}</a>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="row">
              <div class="col-sm-8">
                <div class="form-group">
                  <label for="addressLine1" class="small font-weight-medium text-muted text-uppercase"> {{ "Taxpayer.current-address" | translate }}</label>
                  <ui-input-selector
                      id="addressLine1"
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="addressLine1"
                      groupName="taxpayer.address"
                      [attr.aria-label]="'Taxpayer.current-address' | translate"
                  >
                  </ui-input-selector>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label for="aptNumber" class="small font-weight-medium text-muted text-uppercase">
                    {{ "Taxpayer.apt-no" | translate }}</label>
                  <ui-input-selector
                      id="aptNumber"
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="aptNumber"
                      groupName="taxpayer.address"
                      [attr.aria-label]="'Taxpayer.apt-no' | translate"
                  >
                  </ui-input-selector>
                </div>
              </div>
              <div class="col-12">
                <div class="form-group">
                  <label for="addressLine2" class="small font-weight-medium text-muted text-uppercase">
                    {{ "Taxpayer.address-line2" | translate }}
                  </label>
                  <ui-input-selector
                      id="addressLine2"
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="addressLine2"
                      groupName="taxpayer.address"
                      [attr.aria-label]="'Taxpayer.address-line2' | translate"
                  >
                  </ui-input-selector>
                </div>
              </div>
              <div class="col-12 col-xl-6">
                <label for="city" class="small font-weight-medium text-muted text-uppercase">{{ "Taxpayer.city-town-post-office" | translate }}</label>
                <ui-input-selector
                    id="city"
                    [controlForm]="form"
                    [data]="individualReturn?.review"
                    class="w-100"
                    controlClass="form-control bg-light"
                    controlName="city"
                    groupName="taxpayer.address"
                    [attr.aria-label]="'Taxpayer.city-town-post-office' | translate"
                >
                </ui-input-selector>
              </div>
              <div class="col-sm-6 col-xl-3 mt-3 mt-xl-0">
                <label for="state" class="small font-weight-medium text-muted text-uppercase">{{ "Taxpayer.state" | translate }}</label>
                <ui-input-selector
                    id="state"
                    [controlForm]="form"
                    [data]="individualReturn?.review"
                    class="w-100"
                    controlClass="form-control bg-light"
                    controlName="state"
                    groupName="taxpayer.address"
                    [attr.aria-label]="'Taxpayer.state' | translate">
                </ui-input-selector>
              </div>
              <div class="col-sm-6 col-xl-3 mt-3 mt-xl-0">
                <label for="postalCode" class="small font-weight-medium text-muted text-uppercase">{{ "Taxpayer.zip-code" | translate }}</label>
                <ui-input-selector
                    id="postalCode"
                    [controlForm]="form"
                    [data]="individualReturn?.review"
                    class="w-100"
                    controlClass="form-control bg-light"
                    controlName="postalCode"
                    groupName="taxpayer.address"
                    [attr.aria-label]="'Taxpayer.zip-code' | translate"
                >
                </ui-input-selector>
              </div>
              <div *ngIf="city.name==='Mason'" class="col-lg-12">
                <div class="row pt-1">
                  <div class="col-lg-6 col-md-3 text-nowrap pr-2" for="ownProperty">{{ "Taxpayer.own-property" | translate }}</div>
                  <div class="col-lg-3">
                    <div class="form-check font-weight-bold  d-flex align-items-center  filling-form">
                      <input id="propertyYes"
                           class="form-check-input" 
                           formControlName="ownProperty" 
                           name="ownProperty" 
                           type="radio" 
                           [value]="true"
                           [attr.aria-label]="'Taxpayer.own-property-yes' | translate">
                      <label class="form-check-label" for="propertyYes">{{ "Taxpayer.yes" | translate }}</label>
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-check font-weight-bold  d-flex align-items-center  filling-form">
                      <input id="propertyNo"
                          class="form-check-input" 
                          formControlName="ownProperty" 
                          name="ownProperty" 
                          type="radio" 
                          [value]="false"
                          [attr.aria-label]="'Taxpayer.own-property-no' | translate">
                      <label class="form-check-label" for="propertyNo">{{ "Taxpayer.no" | translate }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="form.get('residency').value==='P'" class="col-12 pt-3">
                <label class="small font-weight-medium text-uppercase">{{ "Taxpayer.previous-address" | translate }}</label>
              </div>
              <div *ngIf="form.get('residency').value==='P'" class="col-sm-8">
                <div class="form-group">
                  <label for="addressLine1" class="small font-weight-medium text-muted text-uppercase"> {{ "Taxpayer.address" | translate }}</label>
                  <ui-input-selector
                      id="addressLine1"
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="addressLine1"
                      groupName="previousAddress"
                      [attr.aria-label]="'Taxpayer.address' | translate"
                  >
                  </ui-input-selector>
                </div>
              </div>
              <div *ngIf="form.get('residency').value==='P'" class="col-sm-4">
                <div class="small font-weight-medium text-muted text-uppercase">
                  <label for="aptNumber" class="form-label">
                    {{ "Taxpayer.apt-no" | translate }}</label>
                  <ui-input-selector
                      id="aptNumber"
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="aptNumber"
                      groupName="previousAddress"
                      [attr.aria-label]="'Taxpayer.apt-no' | translate"
                  >
                  </ui-input-selector>
                </div>
              </div>
              <div *ngIf="form.get('residency').value==='P'" class="col-12">
                <div class="form-group">
                  <label for="addressLine2" class="small font-weight-medium text-muted text-uppercase">
                    {{ "Taxpayer.address-line2" | translate }}
                  </label>
                  <ui-input-selector
                      id="addressLine2" 
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="addressLine2"
                      groupName="previousAddress"
                      [attr.aria-label]="'Taxpayer.address-line2' | translate"
                  >
                  </ui-input-selector>
                </div>
              </div>
              <div *ngIf="form.get('residency').value==='P'" class="col-sm-6">
                <label for="city" class="small font-weight-medium text-muted text-uppercase">{{ "Taxpayer.city-town-post-office" | translate }}</label>
                <ui-input-selector
                    id="city"
                    [controlForm]="form"
                    [data]="individualReturn?.review"
                    class="w-100"
                    controlClass="form-control bg-light"
                    controlName="city"
                    groupName="previousAddress"
                    [attr.aria-label]="'Taxpayer.city-town-post-office' | translate"
                >
                </ui-input-selector>
              </div>
              <div *ngIf="form.get('residency').value==='P'" class="col-sm-3">
                <label for="state" class="small font-weight-medium text-muted text-uppercase">{{ "Taxpayer.state" | translate }}</label>
                <ui-input-selector
                    id="state"
                    [controlForm]="form"
                    [data]="individualReturn?.review"
                    class="w-100"
                    controlClass="form-control bg-light"
                    controlName="state"
                    groupName="previousAddress"
                    [attr.aria-label]="'Taxpayer.state' | translate"
                >
                </ui-input-selector>
              </div>
              <div *ngIf="form.get('residency').value==='P'" class="col-sm-3">
                <label for="postalCode" class="small font-weight-medium text-muted text-uppercase">{{ "Taxpayer.zip-code" | translate }}</label>
                <ui-input-selector
                    id="postalCode"
                    [controlForm]="form"
                    [data]="individualReturn?.review"
                    class="w-100"
                    controlClass="form-control bg-light"
                    controlName="postalCode"
                    groupName="previousAddress"
                    [attr.aria-label]="'Taxpayer.zip-code' | translate"
                >
                </ui-input-selector>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-5 mt-lg-0 mt-5">
        <div class="row p-xl-3">
          <div class="col-12">
            <label for="residency" class="form-label">
              <span class="font-weight-bold text-uppercase">{{ "Taxpayer.residence-status" | translate }}</span></label>
          </div>
          <div class="col-12 pb-3">
            <div class="row pb-2 pl-4 border-bottom">
              <div class="col-sm-4">
                <input
                    [class.is-invalid]="form.get('residency').invalid"
                    [attr.aria-invalid]="form.get('residency').invalid ? 'true' : 'false'"
                    class="custom-control-input"
                    [attr.aria-required]="'true'"
                    formControlName="residency"
                    id="resident"
                    name="residency"
                    type="radio"
                    value="R"
                />
                <label class="custom-control-label" for="resident">{{ "Taxpayer.resident" | translate }}
                </label>
              </div>
              <div class="col-sm-4 my-sm-0 my-3">
                <input
                    [class.is-invalid]="form.get('residency').invalid"
                    [attr.aria-invalid]="form.get('residency').invalid ? 'true' : 'false'"
                    [attr.aria-label]="'Taxpayer.non-resident' | translate"
                    [attr.aria-required]="'true'"
                    class="custom-control-input"
                    formControlName="residency"
                    id="nonresident"
                    name="residency"
                    type="radio"
                    value="N"
                />
                <label class="custom-control-label" for="nonresident">
                  {{ "Taxpayer.non-resident" | translate }}
                </label>
              </div>
              <div class="col-sm-4">
                <input
                    [class.is-invalid]="form.get('residency').invalid"
                    [attr.aria-invalid]="form.get('residency').invalid ? 'true' : 'false'"
                    [attr.aria-label]="'Taxpayer.part-year-resident' | translate"
                    [attr.aria-required]="'true'"
                    class="custom-control-input"
                    formControlName="residency"
                    id="part-year"
                    name="residency"
                    type="radio"
                    value="P"
                />
                <label
                  class="custom-control-label text-nowrap"
                  for="part-year">
                  {{ "Taxpayer.part-year-resident" | translate }}
                </label>
              </div>
            </div>
          </div>
          <!--          <div class="col-12">-->
          <!--            <div class="form-group">-->
          <!--              <label class="small font-weight-medium text-muted text-uppercase">-->
          <!--                Estimates Paid in current tax year:-->
          <!--              </label>-->
          <!--              <input [allowNegativeNumbers]="false"-->
          <!--                     class="w-100 form-control bg-light text-right custom-height"-->
          <!--                     formControlName="estimatedTaxPaid"-->
          <!--                     type="text"-->
          <!--                     ui-number-->
          <!--              >-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <div class="col-12">-->
          <!--            <div class="form-group">-->
          <!--              <label class="small font-weight-medium text-muted text-uppercase">-->
          <!--                Credit Carry Forward from previous tax year:-->
          <!--              </label>-->
          <!--              <input [allowNegativeNumbers]="false"-->
          <!--                     class="w-100 form-control bg-light text-right custom-height"-->
          <!--                     formControlName="priorYearOverpayment"-->
          <!--                     type="text"-->
          <!--                     ui-number-->
          <!--              >-->
          <!--            </div>-->
          <!--          </div>-->
          <div *ngIf="form.get('residency').value==='P'" class="col-12">
            <label class="small font-weight-medium text-uppercase">{{ "Taxpayer.part-year-resident-dates" | translate }}</label>
          </div>
          <div *ngIf="form.get('residency').value==='P'" class="col-6">
            <div class="form-group">
              <label for="partYearFromDate" class="small font-weight-medium text-muted text-uppercase">
                {{ "Taxpayer.from" | translate }}:
              </label>
              <input
                  id="partYearFromDate"
                  [class.is-invalid]="form.get('partYearFromDate').invalid && (form.get('partYearFromDate')?.dirty || form.get('partYearFromDate')?.touched)"
                  class="form-control bg-light text-left custom-height"
                  formControlName="partYearFromDate"
                  [maxDate]="maxDate"
                  [minDate]="minDate"
                  [bsConfig]="{containerClass: 'theme-dark-blue',  showWeekNumbers: false}"
                  [attr.aria-label]="'Taxpayer.from' | translate"
                  [attr.aria-invalid]="form.get('partYearFromDate').invalid && (form.get('partYearFromDate')?.dirty || form.get('partYearFromDate')?.touched) ? 'true' : 'false'"
                  type="text"
                  placeholder="mm/dd/yyyy"
                  bsDatepicker
                />             
            </div>
          </div>
          <div *ngIf="form.get('residency').value==='P'" class="col-6">
            <div class="form-group">
              <label for="partYearToDate" class="small font-weight-medium text-muted text-uppercase">
                {{ "Taxpayer.to" | translate }}:
              </label>
              <input
                  id="partYearToDate"
                  [class.is-invalid]="form?.get('partYearToDate')?.invalid && (form?.get('partYearToDate')?.dirty || form?.get('partYearToDate')?.touched)"
                  class="form-control bg-light text-left custom-height"
                  formControlName="partYearToDate"
                  type="text"
                  [maxDate]="maxDate"
                  [minDate]="minDate"
                  [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                  [attr.aria-label]="'Taxpayer.to' | translate"
                  [attr.aria-invalid]="form?.get('partYearToDate')?.invalid && (form?.get('partYearToDate')?.dirty || form?.get('partYearToDate')?.touched) ? 'true' : 'false'"
                  placeholder="mm/dd/yyyy"
                  bsDatepicker
               />
            </div>
          </div>

          <div *ngIf="form.get('residency').value==='P' && form.get('filing').value !=='S'" class="col-12">
            <label class="small font-weight-medium text-uppercase">
              {{ "Taxpayer.spouse-part-year-resident-dates" | translate }}</label>
          </div>
          <div *ngIf="form.get('residency').value==='P'  && form.get('filing').value !=='S'" class="col-6">
            <div class="form-group">
              <label for="partYearFromDateSpouse" class="small font-weight-medium text-muted text-uppercase">
                {{ "Taxpayer.from" | translate }}:
              </label>

              <input
                  id="partYearFromDateSpouse"
                  [class.is-invalid]="form.get('partYearFromDateSpouse')?.invalid && (form.get('partYearFromDateSpouse')?.dirty || form.get('partYearFromDateSpouse')?.touched)"
                  [maxDate]="maxDate"
                  [minDate]="minDate"
                  class="form-control bg-light text-left custom-height"
                  formControlName="partYearFromDateSpouse"
                  type="text"
                  [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                  [attr.aria-label]="'Taxpayer.from' | translate"
                  [attr.aria-invalid]="form.get('partYearFromDateSpouse')?.invalid && (form.get('partYearFromDateSpouse')?.dirty || form.get('partYearFromDateSpouse')?.touched) ? 'true' : 'false'"
                  placeholder="mm/dd/yyyy"
                  bsDatepicker
              />
            </div>
          </div>
          <div *ngIf="form.get('residency').value==='P' && form.get('filing').value !=='S'" class="col-6">
            <div class="form-group">
              <label for="partYearToDateSpouse" class="small font-weight-medium text-muted text-uppercase">
                {{ "Taxpayer.to" | translate }}:
              </label>
              <input
                  id="partYearToDateSpouse"
                  [class.is-invalid]="form.get('partYearToDateSpouse')?.invalid && (form.get('partYearToDateSpouse')?.dirty || form.get('partYearToDateSpouse')?.touched)"
                  [maxDate]="maxDate"
                  [minDate]="minDate"
                  class="form-control bg-light text-left custom-height"
                  formControlName="partYearToDateSpouse"
                  type="text"
                  [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                  [attr.aria-label]="'Taxpayer.to' | translate"
                  [attr.aria-invalid]="form.get('partYearToDateSpouse')?.invalid && (form.get('partYearToDateSpouse')?.dirty || form.get('partYearToDateSpouse')?.touched) ? 'true' : 'false'"
                  placeholder="mm/dd/yyyy"
                  bsDatepicker
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!(isDisabled$|async) && city.components.individual.uploadToFile" class="row py-3">
      <div class="col-lg-6 d-flex">
        <div class="card d-flex flex-column p-3 mb-1 pb-1 h-100">
          <div class="flex-grow-1">
            <h3 class="mb-0 text-uppercase font-weight-bold">{{ "Taxpayer.upload-to-file" | translate }}</h3>
            <div class="form-content pt-3 font-weight-bold">
              <p class="text-danger font-weight-bold">
                {{ "Taxpayer.do-not-upload-handwritten-returns" | translate }}
              </p>
              <p>
                {{ "Taxpayer.upload-file-info" | translate }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-end">
              <button (click)="upload()"
                      [disabled]="isLoading$ | async"
                      class="btn btn-primary font-weight-600"
                      id="efile"
                      [attr.aria-label]="'Taxpayer.upload' | translate"
                      [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
                      [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'"
                      role="button"
                      tabindex="0">
                <ui-spinner [class.active]="isLoading$ | async">
                 <span>
                  {{ "Taxpayer.upload" | translate }}
                 </span>
                </ui-spinner>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(isDisabled$|async) || city.components.individual.fillToFile" class="col-lg-6 d-flex">
        <div class="card d-flex flex-column p-3 mb-1 pb-1 h-100">
          <div class="flex-grow-1">
            <h3 class="mb-0 text-uppercase font-weight-bold">
              {{ "Taxpayer.fill-in-to-file" | translate }}
            </h3>
            <div class="form-content pt-3 font-weight-bold">
              <p>
                {{ "Taxpayer.fill-in-to-file-info" | translate: {cityName: city.name} }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-end">
              <button (click)="next()"
                      [disabled]="isLoading$ | async"
                      class="btn btn-primary font-weight-600"
                      id="next"
                      [attr.aria-label]="'Taxpayer.fill-in' | translate"
                      [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
                      [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'"
                      tabindex="0"
                      role="button">
                <ui-spinner [class.active]="isLoading$ | async">
                  {{ "Taxpayer.fill-in" | translate }}
                </ui-spinner>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(isDisabled$|async) || !city.components.individual.uploadToFile" class="row border-top py-3">
      <div class="col-12 d-flex justify-content-end">
        <button (click)="next()" 
          [disabled]="isLoading$ | async" 
          class="btn btn-primary font-weight-600"
          id="submit"
          tabindex="0" 
          role="button" 
          [attr.aria-label]="'Buttons.next-btn' | translate" 
          [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'" 
          [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'">
            <span>{{
                "Buttons.next-btn" | translate
              }}</span>
        </button>
      </div>
    </div>
  </form>
</div>
<div *ngIf="(individualReturn$ | async)?.status ==='Not found'" class="container py-3">
  <div class="row align-items-center">
    <div class="col-md-12">
      <div class="d-flex align-items-center justify-content-center">
        <h5 class="text-center mb-0 font-weight-bolder"> {{ individualReturn?.taxYear }} {{ "Taxpayer.individual-income-tax" | translate }} </h5>
      </div>
    </div>
  </div>
  <div class="row align-items-center">
  </div>
  <div class="row py-3 text-center d-flex align-items-center justify-content-center">
    <h5> {{ "Taxpayer.no-tax-return-found" | translate }}</h5>
  </div>
</div>


<ng-template #noPermission>
  <ui-forbidden></ui-forbidden>
</ng-template>

