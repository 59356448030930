<div class="px-3 pt-3 flex-grow-1">
  <div class="row">
    <div class="col-12">
      <div class="d-flex align-items-center">
        <ng-container *ngIf="history.length > 1">
          <a class="text-primary d-flex align-items-center" href="javascript: history.go(-1);">
            <svg class="icon btn-primary mr-3" viewBox="0 0 24 24">
              <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" stroke="none"/>
            </svg>
          </a>
        </ng-container>
        <span class="flex-grow-1 d-flex align-items-center">
          <h5 class="text-primary text-uppercase font-weight-bolder m-0">{{ title$ | async }}</h5>
        </span>
      </div>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
