export enum EComponent {
  INDIVIDUAL = "individual",
  BUSINESS = "business",
  PAYMENTS = "payments",
  FILING = "uploadToFile",
  WITHHOLDING = "withholding",
  W3 = "w3",
}

export enum ERole {
  BUSINESS = "Business",
  INDIVIDUAL = "Individual",
}

export enum EState {
  MI = "MI",
  OH = "OH",
}

export enum ECity {
  DUBLIN = "Dublin",
  VANDALIA = "Vandalia",
  OAKWOOD = "Oakwood",
}
