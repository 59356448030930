import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {ABARoutingNumberIsValid} from 'bank-routing-number-validator';

export function RoutingNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    if (value.length < 9 || value.length > 12) return {lengthError: true};

    const routingNumberMinusLast4 = value.substring(0, value.length - 4);
    if (+routingNumberMinusLast4 === 0) {
      return null
    }

    const isValid = ABARoutingNumberIsValid(value);
    return isValid ? null : {invalidRoutingNumber: true}; // Return null if valid, else return an error object
  };
}

