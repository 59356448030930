import {Component, Injector, OnInit} from "@angular/core";
import {HexaDetailComponent, HexaWithPermissions} from "@hexalang/ui/core";
import {DateFormControl, EPermission, FormatService} from "@city-tax/shared";
import {BehaviorSubject, Observable,} from "rxjs";
import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {NgxPermissionsService} from "ngx-permissions";
import {FormBuilder, FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {MiIndividualReturnHelper} from "libs/features/individual/src/lib/helpers/mi/mi-individual-return.helper";
import {IndividualService} from "../../../../../services/individual.service";
import {PaymentHelper} from "@city-tax/shared";
import * as moment from "moment";

@Component({
  selector: "city-tax-feature-individual-filing-mi-tc",
  templateUrl: "./individual-filing-mi-tc.component.html",
  styleUrls: ["./individual-filing-mi-tc.component.scss"],
})
export class IndividualFilingMiTCComponent
  extends HexaWithPermissions(HexaDetailComponent)
  implements OnInit {
  public form: FormGroup;
  public title$;
  public individualType = ["T", "S"];
  public whoResided = ["T", "S", "B"];
  public month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public residedFromDay$ = new BehaviorSubject<any>(null);
  public residedToDay$ = new BehaviorSubject<any>(null);
  public taxYear = this.individualService.taxYear;
  public ePermission = EPermission;
  public isLoading$ = this.individualService.isLoading$;
  public isUploading$ = this.individualService.isUploading$;
  public error = null;
  public taxDocument = this.authService.organization.taxDocument;
  public city = this.authService.organization.name;
  public individualReturn$ = this.individualService.individualReturn$;
  public hasPermission$: Observable<boolean>;
  public individualReturn = this.individualService.individualReturn;
  public minDate = moment(`${this.taxYear}-01-01`, 'YYYY-MM-DD').endOf('day').toDate();
  public maxDate = moment(`${this.taxYear}-12-31`, 'YYYY-MM-DD').endOf('day').toDate();
  public ir;
  public taxRate = this.authService.config?.components?.individual?.taxRate;
  public creditRate = this.authService.config?.components?.individual?.creditRate || 0;

  constructor(
    injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private individualService: IndividualService,
    private authService: AuthService,
    public toastrService: ToastrService,
    public formatService: FormatService,
    public permissionService: NgxPermissionsService,
    private formBuilder: FormBuilder,
    private paymentHelper: PaymentHelper
  ) {
    super(injector);
    this._initFormUpdate();
  }

  async ngOnInit() {
    //this.checkPermission();
    if (this.individualService.individualReturn) {
      this._patchFormUpdate();
    } else {
      await this._getReturn();
    }
  }

  async next() {
    let path = `/individual/filings/mi/${this.individualService.taxYear}/acknowledgement`;

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      await this.router.navigate([path]);
    } else {
      this.getFormValidationErrors(this.form);
      if (this.form.valid) {
        const payload = this.getPayload();
        try {
          await this.individualService.nextPageAttachment1(this.authService.organization.id, payload);
          await this.router.navigate([path]);
        } catch (error) {

        }
      } else {
        this.form.markAllAsTouched();
      }
    }
  }


  async back() {
    const path = `/individual/filings/mi/${this.individualService.taxYear}/page2`;

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      await this.router.navigate([path]);
    } else {
      try {
        const payload = this.getPayload();
        await this.individualService.updateReturn(this.city.id, payload);
        await this.router.navigate([path]);
      } catch (error) {

      }
    }
  }

  getFormValidationErrors(form) {
    if (form && form.controls) {
      Object.keys(form.controls).forEach((key) => {
        const control = form.get(key);
        if (control.controls) {
          this.getFormValidationErrors(this.form.get(key));
        }
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            console.log(
              "Key control: " +
              key +
              ", keyError: " +
              keyError +
              ", err value: ",
              controlErrors[keyError]
            );
          });
        }
      });
    }
  }

  getPayload() {
    const payload = this.form.getRawValue();

    if (payload.irs1040ScheduleTC.taxpayerFrom) {
      payload.partYearFromDate = payload.irs1040ScheduleTC.taxpayerFrom;
    }

    if (payload.irs1040ScheduleTC.taxpayerTo) {
      payload.partYearToDate = payload.irs1040ScheduleTC.taxpayerTo;
    }

    if (payload.irs1040ScheduleTC.spouseFrom) {
      payload.partYearFromDateSpouse = payload.irs1040ScheduleTC.spouseFrom;
    }

    if (payload.irs1040ScheduleTC.spouseTo) {

      payload.partYearToDateSpouse = payload.irs1040ScheduleTC.spouseTo;
    }
    return payload;
  }

  public calculate() {
    const miHelper = new MiIndividualReturnHelper(
      this.paymentHelper,
      this.authService.config,
      this.form.getRawValue()
    );

    this.form.get("irs1040ScheduleTC.totalAdditionsA").setValue(miHelper.totalAdditionsA);
    this.form.get("irs1040ScheduleTC.totalAdditionsB").setValue(miHelper.totalAdditionsB);
    this.form.get("irs1040ScheduleTC.totalAdditionsC").setValue(miHelper.totalAdditionsC);
    this.form.get("irs1040ScheduleTC.totalAdditionsD").setValue(miHelper.totalAdditionsD);

    this.form.get("irs1040ScheduleTC.totalIncomeA").setValue(miHelper.totalIncomeA);
    this.form.get("irs1040ScheduleTC.totalIncomeB").setValue(miHelper.totalIncomeB);
    this.form.get("irs1040ScheduleTC.totalIncomeC").setValue(miHelper.totalIncomeC);
    this.form.get("irs1040ScheduleTC.totalIncomeD").setValue(miHelper.totalIncomeD);

    this.form.get("irs1040ScheduleTC.totalIncomeAfterDeductionC").setValue(miHelper.totalIncomeAfterDeductionC);
    this.form.get("irs1040ScheduleTC.totalIncomeAfterDeductionD").setValue(miHelper.totalIncomeAfterDeductionD);
    this.form.get("irs1040ScheduleTC.totalLossesTransferredC").setValue(miHelper.totalLossesTransferredC);
    this.form.get("irs1040ScheduleTC.totalLossesTransferredD").setValue(miHelper.totalLossesTransferredD);
    this.form.get("irs1040ScheduleTC.totalIncomeAfterC").setValue(miHelper.totalIncomeAfterC);

    this.form.get("irs1040ScheduleTC.totalIncomeAfterD").setValue(miHelper.totalIncomeAfterD);
    this.form.get("irs1040ScheduleTC.amountExemptionsD").setValue(miHelper.amountExemptionsD);
    this.form.get("irs1040ScheduleTC.resTotalIncomeSubTaxBC").setValue(miHelper.resTotalIncomeSubTaxBC);
    this.form.get("irs1040ScheduleTC.totalDeductC").setValue(miHelper.totalDeductC);
    this.form.get("irs1040ScheduleTC.totalDeductD").setValue(miHelper.totalDeductD);
    this.form.get("irs1040ScheduleTC.nonTotalIncomeSubTaxAD").setValue(miHelper.nonTotalIncomeSubTaxAD);

    this.form.get("irs1040ScheduleTC.taxResRateAC").setValue(miHelper.taxResRateAC);
    this.form.get("irs1040ScheduleTC.taxNonResRateAD").setValue(miHelper.taxNonResRateAD);

    this.form.get("totalTax").setValue(miHelper.totalTax);
    this.form.get("totalPaymentsCredits").setValue(miHelper.totalPaymentsCredits);
    this.form.get("penaltyOnUnderpaidEstimatesOrLateFiledReturns").setValue(miHelper.penaltyOnUnderpaidEstimatesOrLateFiledReturns);
    this.form.get("totalDonations").setValue(miHelper.totalDonations);
    this.form.get('totalOverPayment').setValue(miHelper.totalOverPayment);
    this.form.get("totalTaxDue").setValue(miHelper.totalTaxDue);
    const group = this.form.get('irs1040ScheduleTC') as FormGroup;
    group.updateValueAndValidity();
  }

  private _initFormUpdate() {
    this.form = this.formBuilder.group({
      id: null,
      //line 21
      numberOfExemptions: null,
      amountForExemptions: null,
      fillingCityTaxRateDecimal: null,
      residency: null,
      totalTax: null,
      irs1040ScheduleTC: this.formBuilder.group({
        taxpayerAddress: null,
        spouseAddress: null,

        taxpayerFrom: new DateFormControl(''),
        taxpayerTo: new DateFormControl(''),

        spouseFrom: new DateFormControl(''),
        spouseTo: new DateFormControl(''),

        totalWagesA: null,
        totalWagesB: null,
        totalWagesC: null,
        totalWagesD: null,

        taxableInterestA: null,
        taxableInterestB: null,
        taxableInterestC: null,

        ordinaryDividendsA: null,
        ordinaryDividendsB: null,
        ordinaryDividendsC: null,

        //line 4

        taxableRefundsA: null,
        taxableRefundsB: null,

        //line5

        alimonyReceivedA: null,
        alimonyReceivedB: null,
        alimonyReceivedC: null,
        alimonyReceivedD: null,

        //line 6
        businessIncomeA: null,
        businessIncomeB: null,
        businessIncomeC: null,
        businessIncomeD: null,
        //line 7

        capitalGainA: null,
        capitalGainB: null,
        capitalGainC: null,
        capitalGainD: null,

        //line 8

        otherGainLossA: null,
        otherGainLossB: null,
        otherGainLossC: null,
        otherGainLossD: null,

        //line 9

        taxableIraDistributionA: null,
        taxableIraDistributionB: null,
        taxableIraDistributionC: null,
        taxableIraDistributionD: null,

        //line 10

        taxablePensionA: null,
        taxablePensionB: null,
        taxablePensionC: null,
        taxablePensionD: null,

        //line 11

        rentalRealA: null,
        rentalRealB: null,
        rentalRealC: null,
        rentalRealD: null,

        //line 12

        subchapterSA: null,
        subchapterSB: null,
        subchapterSC: null,
        subchapterSD: null,

        //line 13

        farmIncomeA: null,
        farmIncomeB: null,
        farmIncomeC: null,
        farmIncomeD: null,

        // //line 14

        unemploymentA: null,
        unemploymentB: null,

        //line 15

        ssBenefitsA: null,
        ssBenefitsB: null,

        // //line 16

        otherIncomeA: null,
        otherIncomeB: null,
        otherIncomeC: null,
        otherIncomeD: null,

        // //line 17
        totalAdditionsA: null,
        totalAdditionsB: null,
        totalAdditionsC: null,
        totalAdditionsD: null,

        // //line 18
        totalIncomeA: null,
        totalIncomeB: null,
        totalIncomeC: null,
        totalIncomeD: null,

        iraDeductionA: null,
        iraDeductionB: null,
        iraDeductionC: null,
        iraDeductionD: null,

        selfEmpA: null,
        selfEmpB: null,
        selfEmpC: null,
        selfEmpD: null,

        empBizA: null,
        empBizB: null,
        empBizC: null,
        empBizD: null,

        movingExpA: null,
        movingExpB: null,
        movingExpC: null,
        movingExpD: null,

        alimonyPaidA: null,
        alimonyPaidB: null,
        alimonyPaidC: null,
        alimonyPaidD: null,

        renaissanceZoneDeductA: null,
        renaissanceZoneDeductB: null,
        renaissanceZoneDeductC: null,
        renaissanceZoneDeductD: null,

        //line 19
        totalDeductC: null,
        totalDeductD: null,

        //line 20a
        totalIncomeAfterDeductionC: null,
        totalIncomeAfterDeductionD: null,
        //line 20b
        totalLossesTransferredC: null,
        totalLossesTransferredD: null,
        //line 20c
        totalIncomeAfterC: null,
        totalIncomeAfterD: null,

        // line 21
        //amountExemptionsD : null,

        amountExemptionsD: null,
        //22a
        resTotalIncomeSubTaxBC: null,
        //22b
        nonTotalIncomeSubTaxAD: null,
        //23a
        taxResRateAC: null,
        //23b
        taxNonResRateAD: null,
      }),
      //line 24
      totalPaymentsCredits: null,
      taxWithheldByEmployer: null,
      paymentsOnPriorYearDeclarationTax: null,

      creditForTaxPaidAnotherCity: this.formBuilder.group({
        grandTotalCredit: null,
      }),

      //line 25
      totalInterestAndPenaltyOnUnderpaidEstimatesOrLateFilesReturns: null,
      interestOnUnderpaidEstimatesOrLateFiledReturns: null,
      penaltyOnUnderpaidEstimatesOrLateFiledReturns: null,

      //line 26
      totalTaxDue: null,

      //line 27
      totalOverPayment: null,

      //line 28
      donation35A: null,
      donation35B: null,
      donation35C: null,
      totalDonations: null,

      //line 29
      amountOfOverpaymentCreditedForward: null,

      //line 30
      overpaymentToBeRefunded: null,
      routingNumber: null,
      accountNumber: null,
      payTaxDueDirectWithdrawal: null,
      overpaymentDirectWithdrawal: null,
      payTaxDetail: null,
      typeOfAccount: null,
      refundDirectDeposit: null,
    });
    this.applyValidators();
  }

  private _patchFormUpdate() {
    const helper = new MiIndividualReturnHelper(
      this.paymentHelper,
      this.authService.config,
      this.individualService.individualReturn
    );
    this.form.patchValue(helper.irForm);
    this.calculate();
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      this.isDisabled$.next(true)
      this.form.disable();
    }
  }

  private async _getReturn() {
    try {
      await this.individualService.getReturn(this.authService.organization.id, this.authService.config?.state);
      this._patchFormUpdate();
    } catch (error) {
    }
  }

  private applyValidators() {
    const group = this.form.get('irs1040ScheduleTC') as FormGroup;
    const validators = this.createValidators();
    group.setValidators(validators);
    group.updateValueAndValidity(); // Update the validity of the form group
  }

  private createValidators(): ValidatorFn[] {
    //'totalAdditions', 'totalIncome',
    const controlBase = ['totalWages', 'alimonyReceived', 'businessIncome', 'capitalGain', 'otherGainLoss', 'taxableIraDistribution', 'taxablePension', 'rentalReal', 'subchapterS', 'farmIncome', 'otherIncome', 'iraDeduction', 'selfEmp', 'empBiz', 'movingExp', 'alimonyPaid', 'renaissanceZoneDeduct'];
    return controlBase.map(base => this.createRowValidator(base));
  }

  private createRowValidator(base: string): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const valueA = group.get(`${base}A`)?.value ? +group.get(`${base}A`)?.value : 0;
      const valueB = group.get(`${base}B`)?.value ? +group.get(`${base}B`)?.value : 0;
      const valueC = group.get(`${base}C`)?.value ? +group.get(`${base}C`)?.value : 0;
      const valueD = group.get(`${base}D`)?.value ? +group.get(`${base}D`)?.value : 0;
      const isValid = (valueA - valueB) === (valueC + valueD);
      return isValid ? null : {[`${base}Error`]: true};
    };
  }
}
