<form (submit)="submit()" [formGroup]="w2form" novalidate>
  <div class="main">
    <div class="modal-header">
      <h3>{{ isEdit ? ('Taxpayer.update' | translate) : ('Taxpayer.add' | translate) }} {{ 'Taxpayer.w2-description' | translate }}</h3>
      <div class="">
        <button (click)="hide()"
            [attr.aria-label]="'Taxpayer.close' | translate"
            class="close pull-right"
            role="button"
            tabindex="0"
            type="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-content py-3">
      <table>
        <tr class="pb-2">
          <td class="border-right col-3 py-0 border-bottom">
            <h5><b>{{ 'Taxpayer.taxpayer-wages' | translate }}</b></h5>
          </td>
          <td class="text-center border-right col-2 py-0 border-bottom font-weight-bold">
            {{ 'Taxpayer.employer-source' | translate }}
          </td>
        </tr>
        <tr>
          <td class="border-right col-3 pt-2">
            <div class="d-flex">
              <span>1.</span>
              <span class="mx-1">{{ 'Taxpayer.taxpayer-spouse' | translate }}</span>
            </div>
          </td>
          <td class="text-center border-right col-2 pt-2">
            <div class="row">
              <div class="col-12 d-flex justify-content-start pl-5">
                <input (change)="setSSN(individualReturn?.taxpayer?.ssn)"
                    [class.is-invalid]="w2form.get('type').invalid"
                    class="custom-control-input"
                    formControlName="type"
                    id="taxpayer"
                    name="type"
                    type="radio"
                    value="T"
                >
                <label class="custom-control-label" for="taxpayer">
                  {{ 'Taxpayer.taxpayer' | translate }}
                </label>
              </div>
            </div>
            <div *ngIf="individualReturn.filing !=='S'" class="row">
              <div class="col-12 d-flex justify-content-start pl-5">
                <input
                    (change)="setSSN(individualReturn?.spouse?.ssn)"
                    [required]="true"
                    class="custom-control-input"
                    formControlName="type"
                    id="spouse"
                    name="type"
                    type="radio"
                    value="S"
                >
                <label class="custom-control-label" for="spouse">
                  {{ 'Taxpayer.spouse' | translate }}
                </label>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span>2.</span>
              <span class="mx-1">{{ 'Taxpayer.ssn' | translate }}</span>
            </div>
          </td>
          <td *ngIf="w2form.get('type').value==='T'" class="text-center border-right col-2">
            XXX-XX-{{ individualReturn?.taxpayer?.ssn?.substring(5, 9) }}
          </td>
          <td *ngIf="w2form.get('type').value==='S'" class="text-center border-right col-2">
            XXX-XX-{{ individualReturn?.spouse?.ssn?.substring(5, 9) }}
          </td>
        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span> 3. </span>
              <span class="mx-1">
                {{ 'Taxpayer.employer-id-number' | translate }}</span>
              <span class="ml-auto"></span>
            </div>
          </td>
          <td class="text-center border-right col-2">
            <ui-input-selector
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                class="w-100"
                controlClass="form-control bg-light text-center"
                controlName="employersFederalId"
                id="employersFederalId"
                mask="00-0000000"
            >
            </ui-input-selector>
          </td>
        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span> 4. </span>
              <span class="mx-1">{{ 'Taxpayer.employer-name-w2' | translate }}</span>
            </div>
          </td>
          <td class="text-center border-right col-2">
            <ui-input-selector
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                class="w-100"
                controlClass="form-control bg-light text-center"
                controlName="employersName"
                id="employersName"
            >
            </ui-input-selector>
          </td>
        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span> 5. </span>
              <span class="mx-1">{{ 'Taxpayer.dates-employment-during-tax-year' | translate }}</span>
            </div>
          </td>
          <td class="text-center border-right col-2">
            <table class="w-100 border-top-0">
              <tr>
                <td class="border-top-0">{{ 'Taxpayer.from' | translate }}</td>
                <td class="border-top-0">

                  <input
                      [attr.aria-invalid]="w2form.get('dateWorkedFrom')?.invalid && (w2form.get('dateWorkedFrom')?.dirty || w2form.get('dateWorkedFrom')?.touched) ? 'true' : 'false'"
                      [attr.aria-label]="'Taxpayer.date-worked-from' | translate"
                      [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                      [maxDate]="maxDate"
                      [minDate]="minDate"
                      bsDatepicker
                      class="form-control"
                      formControlName="dateWorkedFrom"
                      id="dateWorkedFrom"
                      type="text"
                  />

                </td>
              </tr>
              <tr>
                <td class="border-top-0">To</td>
                <td class="border-top-0">

                  <input
                      [attr.aria-invalid]="w2form.get('dateWorkedTo')?.invalid && (w2form.get('dateWorkedTo')?.dirty || w2form.get('dateWorkedTo')?.touched) ? 'true' : 'false'"
                      [attr.aria-label]="'Taxpayer.date-worked-to' | translate"
                      [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                      [maxDate]="maxDate"
                      [minDate]="minDate"
                      bsDatepicker
                      class="form-control"
                      formControlName="dateWorkedTo"
                      id="dateWorkedTo"
                      type="text"
                  />

                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span> 6. </span>
              <span class="mx-1">
                {{ 'Taxpayer.check-box-work-at-multiple-location' | translate }} {{ city?.name?.toUpperCase() }}
              </span>
            </div>
          </td>
          <td class="text-center border-right col-2 p-3">
            <input
                class="custom-control-input"
                formControlName="multipleLocations"
                id="multipleLocations"
                type="checkbox"
            />
            <label class="custom-control-label" for="multipleLocations"></label>
          </td>

        </tr>
        <tr>
          <td class="border-right col-3 py-0" style="vertical-align: top;">
            <div class="d-flex py-2">
              <span> 7. </span>
              <span class="mx-1 text-danger">
                {{ 'Taxpayer.address-of-work-station' | translate }}
              </span>
            </div>
          </td>
          <td class="text-center border-right col-2" formGroupName="employersAddress">
            <div class="row">
              <div class="form-group col-12">
                <input
                    [class.is-invalid]="w2form.get('employersAddress.addressLine1')?.invalid"
                    class="form-control"
                    formControlName="addressLine1"
                    [placeholder]="'Taxpayer.address-line1' | translate"
                    type="text"/>
              </div>
              <div class="form-group col-8">
                <input class="form-control" formControlName="addressLine2" [placeholder]="'Taxpayer.address-line2' | translate" type="text"/>
              </div>
              <div class="form-group col-4">
                <input
                    [class.is-invalid]="w2form.get('employersAddress.aptNumber')?.invalid && (w2form.get('employersAddress.aptNumber')?.dirty || w2form.get('employersAddress.aptNumber')?.touched)"
                    class="form-control" formControlName="aptNumber" [placeholder]="'Taxpayer.apt-no' | translate"
                    type="text"/>
              </div>
              <div class="form-group col-12">
                <input
                    [class.is-invalid]="w2form.get('employersAddress.city')?.invalid && (w2form.get('employersAddress.city')?.dirty || w2form.get('employersAddress.city')?.touched)"
                    class="form-control" formControlName="city" [placeholder]="'Taxpayer.city' | translate"
                    type="text"/>
              </div>
              <div class="form-group col-6">
                <input
                    [class.is-invalid]="w2form.get('employersAddress.state')?.invalid && (w2form.get('employersAddress.state')?.dirty || w2form.get('employersAddress.state')?.touched)"
                    class="form-control" formControlName="state" [placeholder]="'Taxpayer.state' | translate"
                    type="text"/>
              </div>
              <div class="form-group col-6">
                <input
                    [class.is-invalid]="w2form.get('employersAddress.postalCode')?.invalid && (w2form.get('employersAddress.postalCode')?.dirty || w2form.get('employersAddress.postalCode')?.touched)"
                    class="form-control" formControlName="postalCode" [placeholder]="'Taxpayer.zip-code' | translate"
                    type="text"/>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span>8.</span>
              <span class="mx-1">
                {{ 'Taxpayer.wages-tips-compensation-zero' | translate }}
              </span>
            </div>
          </td>
          <td class="text-center border-right col-2">
            <ui-input-selector
                (change)="calculate()"
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                controlClass="form-control bg-light text-right"
                controlName="totalWages" decimals="0"
                isNumeric="true"
            >
            </ui-input-selector>
          </td>

        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span> 9. </span>
              <span class="mx-1">{{ 'Taxpayer.wages-not-included-from-w2' | translate }}
                <!--                            (See instructions)-->
              </span>

            </div>
          </td>
          <td class="text-center border-right col-2 pb-2">
            <ui-input-selector
                (change)="calculate()"
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                controlClass="form-control bg-light text-right"
                controlName="wagesNotInBox" decimals="0"
                isNumeric="true"
            >
            </ui-input-selector>
          </td>
        </tr>

        <tr [class.is-invalid]="w2form.get('w2LocalityInfo')?.invalid && (w2form.get('w2LocalityInfo')?.dirty || w2form.get('w2LocalityInfo')?.touched)"
            class="bg-light border">
          <td class="border-top" colspan="2">
            <div class="mb-0 font-weight-bold p-2 row">
              <div class="col-8 d-flex align-items-center">
                <span class="font-weight-bold">10. {{ 'Taxpayer.enter-w2-locality-info' | translate }}
                  <span *ngIf="!w2form.get('excludeLocality').value" class="text-danger">*</span></span>
              </div>
              <div class="col-2 d-flex align-items-center">
                <button
                    (click)="addW2LocalityInfo()"
                    [attr.aria-busy]="w2form.get('excludeLocality').value ? 'true' : 'false'"
                    [attr.aria-disabled]="w2form.get('excludeLocality').value"
                    [attr.aria-label]="'Taxpayer.add-locality-info' | translate"
                    [attr.role]="'button'"
                    [attr.tabindex]="w2form.get('excludeLocality').value ? -1 : 0"
                    [disabled]="w2form.get('excludeLocality').value"
                    class="btn btn-primary font-weight-600 ml-2 py-1"
                    type="button">
                    {{ 'Taxpayer.add' | translate }}
                </button>

              </div>
              <div class="col-2 d-flex align-items-center">
                <input
                    (change)="onExcludeLocalityChange($event)"
                    class="custom-control-input"
                    formControlName="excludeLocality"
                    id="excludeLocality"
                    type="checkbox"
                />
                <label class="custom-control-label" for="excludeLocality">{{ 'Taxpayer.no-locality' | translate }}</label>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="!w2form.get('excludeLocality').value">
          <td class="col-3 pb-1" colspan="2">
            <ng-container *ngIf="localityInfo?.controls?.length" formGroupName="w2LocalityInfo">
              <div class="row pt-2">
                <div class="col-11">
                  <div class="row">
                    <label class="col-4 font-weight-medium">{{ 'Taxpayer.box18-local-wages' | translate }}</label>
                    <label class="col-4 font-weight-medium">{{ 'Taxpayer.box19-local-tax' | translate }}</label>
                    <label class="col-4 font-weight-medium">{{ 'Taxpayer.box20-locality' | translate }} <span class="text-danger pl-1">*</span></label>
                    <!--                    <label class="col-3 font-weight-medium">Credit for Tax Withheld</label>-->
                  </div>
                </div>
                <div class="col-1">
                </div>
              </div>
              <ng-container *ngFor="let locality of localityInfo?.controls; index as i">
                <div [formGroupName]="i" class="row pb-1">
                  <div class="col-11">
                    <city-tax-feature-mi-locality
                        [count]="localityInfo?.controls.length"
                        [localityFormGroup]="locality"
                        [locality]="locality.value"
                        [multipleLocations]="w2form.get('multipleLocations').value">
                    </city-tax-feature-mi-locality>
                  </div>
                  <div class="col-1 d-flex align-items-center">
                    <div (click)="removeW2LocalityInfo(i)" class="form-group cursor-pointer">
                      <svg class="icon icon-sm fill-danger-500" viewBox="0 0 24 24">
                        <path
                            d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                            stroke="none"/>
                      </svg>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </td>
        </tr>
        <tr *ngIf="individualReturn.residency !== 'R'">
          <td class="border-right col-3" colspan="2">
            <div class="d-flex py-2 font-weight-bold">
              {{ 'Taxpayer.residency-info' | translate }}
            </div>
          </td>
        </tr>
        <tr>
          <td class="border-top border-right col-3 pt-1">
            <div class="d-flex">
              <span class="mx-1 font-weight-bold">
                {{ 'Taxpayer.non-resident-wage-allocation' | translate }}
              </span>
            </div>
          </td>
          <td class="text-center border-right border-top col-2 pt-1">
          </td>
        </tr>
        <tr>
          <td class="border-right col-3  pt-1">
            <div class="d-flex">
              <span> 11. </span>
              <span class="mx-1">
                {{ 'Taxpayer.actual-number-of-days-hours-job-period' | translate }}
              </span>
            </div>
          </td>
          <td class="text-center border-right col-2 pt-1">
            <ui-input-selector (change)="calculate()"
                *ngIf="individualReturn.residency !== 'R'"
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                controlClass="form-control bg-light text-right"
                controlName="partYearHoursOnJob" decimals="0"
                isNumeric="true"
            >
            </ui-input-selector>
          </td>
        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span> 12. </span>
              <span class="mx-1">
                {{ 'Taxpayer.vacation-holiday-sick-days-hours' | translate }}
              </span>

            </div>
          </td>
          <td class="text-center border-right col-2">
            <ui-input-selector (change)="calculate()"
                *ngIf="individualReturn.residency !== 'R'"
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                controlClass="form-control bg-light text-right"
                controlName="partYearVacation" decimals="0"
                isNumeric="true"
            >
            </ui-input-selector>
          </td>

        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span> 13. </span>
              <span class="mx-1">{{ 'Taxpayer.actual-number-of-days-hours-job' | translate }}
                <!--                            (Line 11 less line 12)-->
              </span>

            </div>
          </td>
          <td class="text-center border-right col-2">
            <ui-input-selector *ngIf="individualReturn.residency !== 'R'"
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                [readonly]="true"
                controlClass="form-control bg-light text-right"
                controlName="partYearDaysHoursWorked" decimals="0"
                isNumeric="true"
            >
            </ui-input-selector>
          </td>

        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span> 14. </span>
              <span class="mx-1">
                {{ 'Taxpayer.actual-number-of-days-hours-worked-city' | translate }}
              </span>
            </div>
          </td>
          <td class="text-center border-right col-2">
            <ui-input-selector (change)="calculate()"
                *ngIf="individualReturn.residency !== 'R'"
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                controlClass="form-control bg-light text-right"
                controlName="partYearDaysHoursWorkedCity" decimals="0"
                isNumeric="true"
            >
            </ui-input-selector>
          </td>

        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span> 15. </span>
              <span class="mx-1">{{ 'Taxpayer.percentage-days-hours-worked-city' | translate }}
                <!--                (Line 14 divided by line 13; default is 100%)-->
              </span>

            </div>
          </td>
          <td class="text-center border-right col-2">
            <ui-input-selector *ngIf="individualReturn.residency !== 'R'"
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                [readonly]="true"
                controlClass="form-control bg-light text-right"
                controlName="partYearPercentDaysHours"
                decimals="0"
                isNumeric="true"
            >
            </ui-input-selector>
          </td>
        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span> 16. </span>
              <span class="mx-1">
                {{ 'Taxpayer.wages-earned-city' | translate }}
                <!--                (Total of lines 8 and 9 multiplied by line 15; part-year residents use only the portion of wages earned while a nonresident)-->
              </span>
            </div>
          </td>
          <td class="text-center border-right col-2">
            <ui-input-selector *ngIf="individualReturn.residency !== 'R'"
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                [readonly]="true"
                controlClass="form-control bg-light text-right"
                controlName="partYearWagesEarnedCity" decimals="0"
                isNumeric="true"
            >
            </ui-input-selector>
          </td>
        </tr>

        <tr *ngIf="individualReturn.residency !== 'R'">
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              {{ 'Taxpayer.employer-wage-allocation-documentation' | translate }}
            </div>
          </td>
          <td class="text-center border-right col-2">
            <div *ngIf="!(isDisabled$|async)" class="upload-layout">
              <div class="drop_box">
                <header>
                  <h3 class="mb-0">{{ 'Taxpayer.select-file-here' | translate }}</h3>
                </header>
                <div class="text-center button-upload mt-2">
                  <button [attr.aria-busy]="(isUploading$ | async) === 'Wage-Other' ? true : false"
                      [attr.aria-disabled]="(isUploading$ | async) ? true : false"
                      [attr.aria-label]="'Taxpayer.upload-file' | translate"
                      [disabled]="(isUploading$ | async)"
                      class="btn btn-primary font-weight-600 btn-upload"
                      role="button"
                      tabindex="0">
                    <ui-spinner [class.active]="(isUploading$ | async) === 'Wage-Other'">{{ 'Taxpayer.upload-file' | translate }}</ui-spinner>
                  </button>

                  <input (change)="uploadDocument('Wage-Other', $event)"
                      [disabled]="(isDisabled$|async)"
                      accept=".pdf"
                      class="py-2 px-2 file-upload w-100 border bg-white drop-file"
                      name="Employer Wage Allocation Documentation"
                      type="file"
                  />
                </div>
              </div>
            </div>
            <div *ngFor="let file of individualReturn?.files" class="row">
              <div *ngIf="file.type === 'Wage-Other'" class="col-12">
                <div class="row no-gutters align-items-center border-top bg-primary-100 py-1">
                  <div class="col-8 align-items-center px-3 py-2 d-flex">
                    <svg *ngIf="file?.mimeType === 'application/pdf'" class="icon" viewBox="0 0 24 24">
                      <path d="M14,9H19.5L14,3.5V9M7,2H15L21,8V20A2,2 0 0,1 19,22H7C5.89,22 5,21.1 5,20V4A2,2 0 0,1 7,2M11.93,12.44C12.34,13.34 12.86,14.08 13.46,14.59L13.87,14.91C13,15.07 11.8,15.35 10.53,15.84V15.84L10.42,15.88L10.92,14.84C11.37,13.97 11.7,13.18 11.93,12.44M18.41,16.25C18.59,16.07 18.68,15.84 18.69,15.59C18.72,15.39 18.67,15.2 18.57,15.04C18.28,14.57 17.53,14.35 16.29,14.35L15,14.42L14.13,13.84C13.5,13.32 12.93,12.41 12.53,11.28L12.57,11.14C12.9,9.81 13.21,8.2 12.55,7.54C12.39,7.38 12.17,7.3 11.94,7.3H11.7C11.33,7.3 11,7.69 10.91,8.07C10.54,9.4 10.76,10.13 11.13,11.34V11.35C10.88,12.23 10.56,13.25 10.05,14.28L9.09,16.08L8.2,16.57C7,17.32 6.43,18.16 6.32,18.69C6.28,18.88 6.3,19.05 6.37,19.23L6.4,19.28L6.88,19.59L7.32,19.7C8.13,19.7 9.05,18.75 10.29,16.63L10.47,16.56C11.5,16.23 12.78,16 14.5,15.81C15.53,16.32 16.74,16.55 17.5,16.55C17.94,16.55 18.24,16.44 18.41,16.25M18,15.54L18.09,15.65C18.08,15.75 18.05,15.76 18,15.78H17.96L17.77,15.8C17.31,15.8 16.6,15.61 15.87,15.29C15.96,15.19 16,15.19 16.1,15.19C17.5,15.19 17.9,15.44 18,15.54M8.83,17C8.18,18.19 7.59,18.85 7.14,19C7.19,18.62 7.64,17.96 8.35,17.31L8.83,17M11.85,10.09C11.62,9.19 11.61,8.46 11.78,8.04L11.85,7.92L12,7.97C12.17,8.21 12.19,8.53 12.09,9.07L12.06,9.23L11.9,10.05L11.85,10.09Z" stroke="none"/>
                    </svg>
                    <svg *ngIf="file?.mimeType === 'image/png' || file?.mimeType === 'image/jpg' || file?.mimeType === 'image/jpeg'" class="icon" viewBox="0 0 24 24">
                      <path d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M6,20H15L18,20V12L14,16L12,14L6,20M8,9A2,2 0 0,0 6,11A2,2 0 0,0 8,13A2,2 0 0,0 10,11A2,2 0 0,0 8,9Z" stroke="none"/>
                    </svg>
                    {{ file?.fileName }}
                  </div>
                  <div class="ml-auto d-flex align-items-center pr-3">
                    <div class="text-nowrap mr-2">
                      <strong class="font-weight-medium">{{ file?.size * 1e-6 | number }}MB</strong>
                    </div>
                    <div class="d-flex align-items-center justify-content-center">
                      <button
                          (click)="delete(file)"
                          *ngIf="!(isDisabled$|async)"
                          [attr.aria-label]="'Taxpayer.delete-file' | translate"
                          class="btn btn-sm bg-danger border shadow p-1"
                          container="body"
                          placement="left"
                          role="button"
                          tabindex="0"
                          tooltip="Delete"
                          type="button"
                      >
                        <svg class="icon icon-sm fill-white d-flex" viewBox="0 0 24 24">
                          <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" stroke="none"/>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>

        <tr>
          <td class="border-right col-3  pt-1">
            <div class="d-flex">
              <span class="mx-1 font-weight-bold">
                {{ 'Taxpayer.excludible-wages' | translate }}
              </span>
            </div>
          </td>
          <td class="text-center border-right col-2 pt-1">
          </td>
        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span> 17. </span>
              <span class="mx-1">
                {{ 'Taxpayer.total-nonresident-excludible-wages' | translate }}
                <!--                (Total of lines 8 & 9 less line 16)-->
              </span>
            </div>
          </td>
          <td class="text-center border-right col-2">
            <ui-input-selector
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                [readonly]="true"
                controlClass="form-control bg-light text-right"
                controlName="taxableWagesNonResident"
                decimals="0"
                isNumeric="true"
            >
            </ui-input-selector>
          </td>

        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span> 18. </span>
              <span class="mx-1">{{ 'Taxpayer.enter-resident-excludible-wages' | translate }}</span>
            </div>
          </td>
          <td class="text-center border-right col-2">
            <ui-input-selector (change)="calculate()"
                *ngIf="individualReturn.residency === 'R'"
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                controlClass="form-control bg-light text-right"
                controlName="excludedWages" decimals="0"
                isNumeric="true"
            >
            </ui-input-selector>
          </td>

        </tr>
        <tr>
          <td class="border-right col-3 py-0">
            <div class="d-flex">
              <span> 19. </span>
              <span class="mx-1">{{ 'Taxpayer.enter-reason-excludible-wages' | translate }} {{ city?.name }}</span>

            </div>
          </td>
          <td class="text-center border-right col-2">
            <ui-input-selector
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                controlClass="form-control bg-light"
                controlName="reasonWagesNonTaxable"
            >
            </ui-input-selector>
          </td>

        </tr>
        <tr>
          <td class="border-right col-3 py-2">
            <div class="d-flex">
              <span> 20. </span>
              <span class="mx-1">{{ 'Taxpayer.total-excludible-wages' | translate }}
                <!--                (Line 17 plus line 18; Enter here and on CF-1040, page 2, Excluded Wages schedule)-->
              </span>

            </div>
          </td>
          <td class="text-center border-right col-2">
            <ui-input-selector
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                [readonly]="true"
                controlClass="form-control bg-light text-right"
                controlName="excludibleWages"
                decimals="0"
                isNumeric="true"
            >
            </ui-input-selector>
          </td>

        </tr>

        <tr>
          <td class="border-right col-3 py-2">
            <div class="d-flex justify-content-end">
              <span class="mx-1 font-weight-bold">
                {{ 'Taxpayer.total-taxable-wages' | translate }}
                <!--                (Line 8 plus line 9  less line 20)-->
              </span>
            </div>
          </td>
          <td class="text-center border-right col-2">
            <ui-input-selector
                [controlForm]="w2form"
                [data]="individualReturn?.review"
                [readonly]="true"
                controlClass="form-control bg-light text-right"
                controlName="taxableWages" decimals="0"
                isNumeric="true"
            >
            </ui-input-selector>
          </td>
        </tr>

      </table>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <a (click)="hide()"
        [attr.aria-label]="'Buttons.cancel-btn' | translate"
        [attr.role]="'button'"
        [attr.tabindex]="0"
        class="btn btn-link"
        href="javascript:">
      {{ "Buttons.cancel-btn" | translate }}
    </a>
    <button
        [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'"
        [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
        [attr.aria-label]="isEdit ? 'Update' : 'Buttons.submit-btn' | translate"
        [attr.role]="'button'"
        [attr.tabindex]="0"
        [disabled]="(isLoading$ | async)"
        class="btn btn-primary f-bold"
        type="submit">
      <ui-spinner [class.active]="isLoading$ | async">
        {{ isEdit ? "Update" : "Buttons.submit-btn" | translate }}
      </ui-spinner>
    </button>
  </div>
</form>
