import {AfterViewInit, ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild} from "@angular/core";
import {HexaDetailComponent, HexaTitleService, HexaWithPermissions} from "@hexalang/ui/core";

import {
  AccountNumberValidator,
  DateFormControl,
  EBusinessPaymentType,
  EIndividualPaymentType,
  EPermission,
  FormatService,
  RoutingNumberValidator,
} from "@city-tax/shared";
import {FormBuilder, FormGroup, ValidationErrors, Validators,} from "@angular/forms";
import {BehaviorSubject, Observable, of, takeUntil,} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {PaymentService} from "../../../../services/payment.service";
import {NgxPermissionsService} from "ngx-permissions";
import * as moment from "moment/moment";
import {HttpErrorResponse} from "@angular/common/http";
import {PaymentMethodsComponent} from "libs/shared/src/lib/components/payment-methods/payment-methods.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {
  EmailValidationPopupComponent
} from "libs/shared/src/lib/components/email-validation-popup/email-validation-popup.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "city-tax-feature-individual-payment-create-intent",
  templateUrl: "./individual-payment-create-intent.component.html",
  styleUrls: ["./individual-payment-create-intent.component.scss"],
})
export class IndividualPaymentCreateIntentComponent
  extends HexaWithPermissions(HexaDetailComponent)
  implements OnInit, AfterViewInit {
  public title$;
  public config = this.authService.config;
  public bsModalRef: BsModalRef;
  public ePermission = EPermission;
  public hasPermission$: Observable<boolean>;
  public taxYear = this.paymentService.taxYear;
  public selectedYear = this.paymentService.taxYear;
  public currentYear = this.paymentService.currentYear;
  public nextYear = this.paymentService.nextYear;
  public years$ = this.paymentService.years$;
  public paymentTypes$ = this.paymentService.paymentTypes$;
  public payment$ = this.paymentService.payment$;
  public warningFlag$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public form: FormGroup;
  public type = "ach";
  public isLoading$ = this.paymentService.isLoading$;
  public id;
  public organization = this.authService.organization;
  public individual$ = this.authService.individual$;
  public paymentMethods = this.authService.config?.paymentMethods;
  public paymentMethods$: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>(null);
  public stripe: any;
  public elements: any;
  public endString = `${this.nextYear}-03-31`;
  public maxDate = moment(this.endString, 'YYYY-MM-DD').endOf('day').toDate();
  public minDate = moment().add(1, "day").startOf("date").toDate();
  public maxDateReminder;
  public paymentInterval$ = of(['Monthly'])
  public readonly = false;

  @Input() public redirectPath;
  @ViewChild(PaymentMethodsComponent) cityTaxPaymentMethods: PaymentMethodsComponent;

  constructor(
    injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public paymentService: PaymentService,
    public toastrService: ToastrService,
    public permissionService: NgxPermissionsService,
    private titleService: HexaTitleService,
    private translate: TranslateService,
    public formatService: FormatService,
    private modalService: BsModalService,
    private cd: ChangeDetectorRef
  ) {
    super(injector);
    this.form = this.formBuilder.group({
        addAsPaymentMethod: null,
        paymentMethodName: null,
        pm: null,
        bank: this.formBuilder.group({
          accountType: [null, Validators.required],
          accountNumber: [null,
            [
              Validators.required,
              AccountNumberValidator(),
              Validators.minLength(8),
              Validators.maxLength(12),
            ],
          ],
          routingNumber: [
            null,
            [Validators.required,
              RoutingNumberValidator()
            ],
          ],
        }),
        taxYear: [null, Validators.required],
        paymentType: [null, Validators.required],
        confirmRoutingNumber: [null, Validators.required],
        confirmAccountNumber: [null, Validators.required],
        amount: [null, Validators.required],
        type: [null, Validators.required],
        paymentDate: new DateFormControl(''),
        individualReturnId: null,
        paymentReminderDate: new DateFormControl(''),
        recurring: false,
        additionalPayments: null,
        interval: null,
      },
    );
  }

  async ngOnInit() {
    this.titleService.changeTitle(this.translate.instant("Payment.payment"));
    this.translate.onLangChange.subscribe(val => {
      this.titleService.changeTitle(
        this.translate.instant("Payment.payment")
      )
    })
    this.paymentService.getPaymentTypes();
    await this.getIndividual();
    this.redirectPath = this.route.snapshot.queryParams["redirect"];
    if (this.route.snapshot.queryParams["redirect"] && this.redirectPath.toString().endsWith("payments")) {
      this.redirectPath += "/history";
    }
    this.id = this.route.snapshot.queryParams.id;
    const individualReturnId = this.route.snapshot.queryParams.individualReturnId;
    const amount = this.route.snapshot.queryParams.amount;
    const paymentType = this.route.snapshot.queryParams.paymentType;
    const taxYear = this.route.snapshot.queryParams.taxYear;
    if (amount > 0 && amount < 1) {
      this.paymentMethods$.next(['ach']);
    } else {
      this.paymentMethods$.next(this.paymentMethods);
    }

    if (individualReturnId) {
      this.readonly = true;
    }

    this.form.patchValue({taxYear, paymentType, amount, individualReturnId});
    if (!amount) {
      this.form.get('amount').addValidators(Validators.min(1));
    }
    if (!this.route.snapshot.queryParams.id) {
      await this.createPayment();
    } else {
      await this.getPayment();
    }

    this.authService.individual$.pipe(takeUntil(this.destroy$)).subscribe((individual) => this.checkEmailValidation(individual))
  }

  async ngAfterViewInit() {
  }

  public onSelectPaymentMethod(type: string) {
    this.type = type;
    this.updateFees();
  }
  
  public formatTranslationKey(value: string): string {
  return value.toLowerCase().replace(/\s+/g, '-');
  } 

  public async createPayment() {
    try {
      const payload = this.form.getRawValue()
      payload.pm = null;
      const payment = await this.paymentService.createPayment(
        this.authService.organization.id,
        payload
      );
      this.id = payment.id;
      await this._patchFormUpdate();
    } catch (error) {
    }
  }

  public async getPayment() {
    try {
      const payment = await this.paymentService.loadPayment(
        this.authService.organization.id,
        this.id
      );
      if (payment) {
        await this._patchFormUpdate();
      }
    } catch (error) {
      console.error(error);
    }
  }

  public async submit() {
    this.getFormValidationErrors(this.form);
    if (this.form.valid) {
      return this._submit();
    } else {
      this.cityTaxPaymentMethods.updateFormStatus();
    }
  }

  async _submit() {
    const pm = this.form.get("pm").value;
    const recurring = this.form.get("recurring").value;

    if (this.type === "card" && (!pm || !pm.id)) {
      const payment = await this.updateIntent();
      if (payment.intent.object === "payment_intent") {
        return this.processStripePayment(recurring);
      } else {
        return this.processStripeSetup(recurring);
      }
    } else {
      return this.processPayment();
    }
  }

  async processStripePayment(recurring: boolean) {

    this.paymentService.stripe.confirmPayment({
      elements: this.paymentService.elements,
      confirmParams: {
        return_url: this.getReturnUrl(recurring),
      },
    }).then((ret: any) => {
      if (ret.error.type === "payment_intent_unexpected_state") {
        this.toastrService.error(
          "Payment already submitted.",
          "Payment Error"
        );
      } else {
        console.error('ret error++++', ret.error);
        this.toastrService.error(ret.error.message, ret.error.name);
      }
    }).catch((error: any) => {
      if (error instanceof HttpErrorResponse) {
        this.toastrService.error(error.error.message, error.error.name);
      } else {
        this.toastrService.error(error.message, error.name);
      }
      return of(error);
    });
  }

  async processStripeSetup(recurring: boolean) {
    this.paymentService.stripe.confirmSetup({
      elements: this.paymentService.elements,
      confirmParams: {
        return_url: this.getReturnUrl(recurring),
      },
    }).then((ret: any) => {
      if (ret.error.type === "payment_intent_unexpected_state") {
        this.toastrService.error(
          "Payment already submitted.",
          "Payment Error"
        );
      } else {
        this.toastrService.error(ret.error.message, ret.error.name);
      }
    }).catch((error: any) => {
      if (error instanceof HttpErrorResponse) {
        this.toastrService.error(error.error.message, error.error.name);
      } else {
        this.toastrService.error(error.message, error.name);
      }
      return of(error);
    });
  }

  getReturnUrl(recurring: boolean) {
    const location = `${window.location.protocol}//${window.location.host}`;
    if (recurring) {
      return `${location}/#/individual/payments/create/intent/${this.paymentService.id}/recurring`;
    }
    return `${location}/#/individual/payments/create/confirmation?id=${this.paymentService.id}`;
  }

  async processPayment() {
    try {
      const payload = this.form.getRawValue();
      payload.pm = payload.pm || {};

      if (payload.pm.id) {
        payload.type = 'pm';
      }

      this.paymentService.stripe = null;
      this.paymentService.elements = null;

      await this.paymentService.submitPayment(this.authService.organization.id, payload);

      if (!payload.recurring) {
        await this.router.navigate(
          [`/individual/payments/create/confirmation`],
          {
            queryParams: {id: this.paymentService.id},
          }
        );
      } else {
        await this.router.navigate(
          [`/individual/payments/create/intent`, this.paymentService.id, 'recurring'],
        );
      }
    } catch (error) {
      if (error?.name == "InvalidAccountNumberError") {
        this.form.get("bank.accountNumber").setErrors({invalidAccountNumber: true});
      } else if (error?.name == "InvalidRoutingNumberError") {
        this.form.get("bank.routingNumber").setErrors({invalidRoutingNumber: true})
      }
    }
  }

  async onDateChange(event: any) {
    if (!event) return;

    const paymentDateString = this.form.get("paymentDate").value ? this.formatService.dateFormat(this.form.get("paymentDate").value) : null;
    const storedPaymentDate = this.paymentService.payment.paymentDate ? this.formatService.dateFormat(this.paymentService.payment.paymentDate) : null;
    if (paymentDateString !== storedPaymentDate) {
      await this.updateIntent();
    }
  }

  async updateIntent() {
    try {
      const payment = await this.paymentService.updatePayment(
        this.authService.organization.id,
        this.paymentService.payment.id,
        this.form.getRawValue()
      );
      await this._patchFormUpdate();
      return payment;
    } catch (error) {
      console.log('updateIntent error-----------------------------------------', error);
    }
  }

  async cancelUpdate() {
    try {
      await this.paymentService.deletePayment(
        this.authService.organization.id,
        this.paymentService.payment.id
      );
      await this.router.navigate([`/individual/payments`]);
    } catch (error) {
    }
  }

  async updatePaymentType() {
    this.warningFlag$.next(false);
    this.form.get("taxYear").setValue(null);
    switch (this.form.getRawValue().paymentType) {
      case EIndividualPaymentType.QUARTER_1_ESTIMATE:
      case EIndividualPaymentType.QUARTER_2_ESTIMATE:
      case EIndividualPaymentType.QUARTER_3_ESTIMATE: {
        this.years$ = of([`${this.currentYear}`]);
        this.form.get("taxYear").setValue(this.currentYear);
        break;
      }
      case EIndividualPaymentType.QUARTER_4_ESTIMATE: {
        this.years$ = of([`${this.currentYear}`]);
        this.form.get("taxYear").setValue(this.currentYear);
        this.years$ = of(
          Array.from([this.currentYear, this.taxYear]).map((y) => y.toString())
        );
        break;
      }
      case EIndividualPaymentType.BALANCE_DUE:
      case EIndividualPaymentType.EXTENSION: {
        this.years$ = of([`${this.taxYear}`]);
        this.form.get("taxYear").setValue(this.taxYear);
        break;
      }

      case EIndividualPaymentType.BALANCE_DUE_NOTICE: {
        const year = +this.taxYear - 1;
        this.form.get("taxYear").setValue(year);
        this.years$ = of(
          Array.from(
            {length: (year - 11 - year) / -1 + 1},
            (_, i) => year + i * -1
          ).map((y) => y.toString())
        );
        break;
      }

      case EIndividualPaymentType.PAYMENT_PLAN: {
        const year = +this.taxYear;
        this.form.get("taxYear").setValue(this.taxYear);
        this.years$ = of(
          Array.from(
            {length: (year - 11 - year) / -1 + 1},
            (_, i) => year + i * -1
          ).map((y) => y.toString())
        );
        break;
      }
    }
  }

  getFormValidationErrors(form) {
    this.form.markAllAsTouched();
    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      if (control.controls) {
        this.getFormValidationErrors(this.form.get(key));
      }
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }

  public async cancel() {
    console.log("cancel", this.redirectPath);
    await this.router.navigate([this.redirectPath]);
  }

  initPaymentTypeYears() {
    switch (this.form.getRawValue().paymentType) {
      case EIndividualPaymentType.QUARTER_1_ESTIMATE:
      case EIndividualPaymentType.QUARTER_2_ESTIMATE:
      case EIndividualPaymentType.QUARTER_3_ESTIMATE: {
        this.years$ = of([`${this.currentYear}`]);
        break;
      }
      case EIndividualPaymentType.QUARTER_4_ESTIMATE: {
        this.years$ = of([`${this.currentYear}`]);
        this.years$ = of(
          Array.from([this.currentYear, this.taxYear]).map((y) => y.toString())
        );
        break;
      }
      case EIndividualPaymentType.BALANCE_DUE_NOTICE: {
        const year = +this.taxYear;
        const years = Array.from({length: 11}, (_, i) => year + i * -1).map((y) => `${y}`);
        this.years$ = of(years);
        break;
      }
      case EIndividualPaymentType.EXTENSION: {
        this.years$ = of([`${this.taxYear}`]);
        break;
      }
      case EBusinessPaymentType.BALANCE_DUE: {
        this.years$ = of([`${this.taxYear}`]);
        break;
      }
      case EIndividualPaymentType.PAYMENT_PLAN: {
        const year = +this.taxYear;
        this.years$ = of(
          Array.from(
            {length: (year - 11 - year) / -1 + 1},
            (_, i) => year + i * -1
          ).map((y) => y.toString())
        );
        break;
      }
    }
  }

  updateFees() {
    const payment = this.paymentService.payment;
    if (payment) {
      payment.amount = +(this.form?.value?.amount || 0);
      payment.feeAmount = this.calculateFee(this.type, payment.amount);
      payment.total = payment.amount + payment.feeAmount;
      this.paymentService.payment = payment;
    }
  }

  calculateFee(type, amount) {
    if (type !== 'card' || +amount === 0) return 0;
    const fee = 0.03;
    const feeMinimum = 1.95;
    const feeAmount = amount * fee;
    return feeAmount > feeMinimum ? feeAmount : feeMinimum;
  }

  private async _patchFormUpdate() {

    this.warningFlag$.next(false);

    const paymentValue = this.paymentService.payment;

    this.form.patchValue({
      amount: paymentValue?.amount,
      paymentDate: paymentValue?.paymentDate,
      paymentType: paymentValue?.paymentType,
      taxYear: paymentValue?.taxYear,
      paymentReminderDate: paymentValue?.paymentReminderDate,
      recurring: paymentValue?.recurring,
      additionalPayments: paymentValue?.additionalPayments,
      interval: paymentValue?.interval,
    })
    if (paymentValue?.pm) {
      this.form.get('pm').setValue(paymentValue?.pm);
    }
    this.selectedYear = this.form.getRawValue().year;
    this.initPaymentTypeYears();
    this.maxDateReminder = paymentValue.isFuturePayment ? moment(paymentValue.paymentDate).subtract(1, "day").toDate() : null;


    if (this.paymentService.payment.warningFlag) {
      this.warningFlag$.next(true);
    }

    this.cd.detectChanges();
  }

  private async getIndividual() {
    await this.authService.getIndividual(this.authService.organizationId);
  }

  private checkEmailValidation(individual) {
    if (individual && individual.email) {
      const emailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(individual.email);
      if (!emailValid) {
        this.bsModalRef = this.modalService.show(EmailValidationPopupComponent, {
          initialState: {individual},
          ignoreBackdropClick: true,
        });
      }
    }
  }
}
