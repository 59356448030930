import {ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HexaDetailComponent} from "@hexalang/ui/core";
import {FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {StripeCardComponent, StripeService} from "ngx-stripe";
import {BsModalRef} from "ngx-bootstrap/modal";
import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {AccountNumberValidator, FormatService, MatchValidator, RoutingNumberValidator} from "@city-tax/shared";

@Component({
  selector: 'city-tax-feature-individual-payment-method-update',
  templateUrl: './individual-payment-method-update.component.html',
  styleUrls: ['./individual-payment-method-update.component.scss']
})
export class IndividualPaymentMethodUpdateComponent extends HexaDetailComponent implements OnInit, OnDestroy {
  public form: FormGroup;

  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  public organization = this.authService.organization;
  public isLoading$ = this.authService.isLoading$;
  public paymentMethod;

  public accountType = [
    {label: "Payment.checking", value: "C"},
    {label: "Payment.savings", value: "S"},
  ];
  defaultPaymentMethods: any;

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public toastrService: ToastrService,
    private stripeService: StripeService,
    private cd: ChangeDetectorRef,
    public formatService: FormatService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.initform();

    this.form.patchValue(this.paymentMethod);
    this.form.get("confirmRoutingNumber").setValue(this.form.getRawValue().bank.routingNumber);
    this.form.get("confirmAccountNumber").setValue(this.form.getRawValue().bank.accountNumber);
    this.setPaymentMethodType(this.paymentMethod.type);

  }

  setPaymentMethodType(type: string) {

    const bank = this.form.get("bank") as FormGroup;
    const accountNumber = bank.get("accountNumber");
    const routingNumber = bank.get("routingNumber");
    const accountType = bank.get("accountType");
    const confirmRoutingNumber = this.form.get("confirmRoutingNumber");
    const confirmAccountNumber = this.form.get("confirmAccountNumber");

    if (type === "ach") {
      accountNumber.setValidators([Validators.required, AccountNumberValidator()]);
      routingNumber.setValidators([Validators.required, RoutingNumberValidator()]);
      accountType.setValidators([Validators.required]);
      confirmRoutingNumber.setValidators([Validators.required]);
      confirmAccountNumber.setValidators([Validators.required]);

      accountNumber.updateValueAndValidity();
      routingNumber.updateValueAndValidity();
      accountType.updateValueAndValidity();
      confirmRoutingNumber.updateValueAndValidity();
      confirmAccountNumber.updateValueAndValidity();
    } else {
      accountNumber.clearValidators();
      accountNumber.updateValueAndValidity();
      routingNumber.clearValidators();
      routingNumber.updateValueAndValidity();
      accountType.clearValidators();
      accountType.updateValueAndValidity();
      confirmRoutingNumber.clearValidators();
      confirmRoutingNumber.updateValueAndValidity();
      confirmAccountNumber.clearValidators();
      confirmAccountNumber.updateValueAndValidity();
    }

  }

  public async update() {
    this.getFormValidationErrors(this.form);
    if (this.form.valid) {
      const payload = this.form.getRawValue();
      if(payload.isDefault){
        var data = await this.authService.getIndividual(this.authService.organization.id);
        this.defaultPaymentMethods = data.paymentMethods.find(method => method.isDefault === true);
      if (this.defaultPaymentMethods) {
        this.defaultPaymentMethods.isDefault = false;
        await this.authService.updatePaymentMethod(this.authService.organizationId, 'individuals',  this.defaultPaymentMethods.id, this.defaultPaymentMethods);
       } 
      }
      try {
        await this.authService.updatePaymentMethod(this.authService.organizationId, 'individuals', this.paymentMethod.id, payload);
        this.bsModalRef.hide();
        setTimeout(() => {
          this.toastrService.success(
            "Payment Method was successfully saved!",
            "Payment Method Create"
          );
        }, 1000);
      } catch (error) {

      }
    } else {
      console.log('form', this.form.errors, this.form.valid)
      this.form.markAllAsTouched();
    }
  }

  private initform() {
    this.form = this.formBuilder.group({
        isDefault: [false],
        name: [null, Validators.required],
        type: ["ach", Validators.required],
        bank: this.formBuilder.group({
          accountType: [null, Validators.required],
          accountNumber: [
            null,
            [
              Validators.required,
              AccountNumberValidator(),
              Validators.minLength(8),
              Validators.maxLength(12),
            ],
          ],
          routingNumber: [
            null,
            [Validators.required,
              RoutingNumberValidator()
            ],
          ],
        }),
        confirmRoutingNumber: [null, Validators.required],
        confirmAccountNumber: [null, Validators.required],
      },
      {
        validators: [
          MatchValidator("routingNumber", "confirmRoutingNumber", "bank"),
          MatchValidator("accountNumber", "confirmAccountNumber", "bank"),
        ],
      }
    );
  };

  onlyNumberKey(event: any) {
    var keyCode = event.which ? event.which : event.keyCode;
    return keyCode > 31 && (keyCode < 48 || keyCode > 57) ? false : true;
  }

  formatInteger(any) {
    return !any ? null : any.toString().replace(/\D/g, '') === '' ? 0 : any.toString().replace(/\D/g, '');
  }

  strip(control) {
    control.updateValueAndValidity();
    const value = this.formatInteger(control.value);
    const accountNumberCtrl = this.form.get("bank").get("accountNumber");
    if (value === "272480678") {
      accountNumberCtrl.removeValidators([Validators.minLength(8)]);
      accountNumberCtrl.addValidators([Validators.minLength(12)]);
    } else {
      if (accountNumberCtrl.value !== "272480678") {
        if (accountNumberCtrl.hasValidator(Validators.min(12))) {
          accountNumberCtrl.removeValidators([Validators.minLength(12)]);
          accountNumberCtrl.addValidators([Validators.minLength(8)]);
        }
      }
    }
    accountNumberCtrl.updateValueAndValidity();
    control.setValue(value);
    control.markAsTouched();
    control.updateValueAndValidity();
    this.cd.detectChanges()
  }

  displayCounter(value, name) {
    this.form.get(name).setValue(value);
  }

  getFormValidationErrors(form) {
    if (form && form.controls) {
      Object.keys(form.controls).forEach((key) => {
        const control = form.get(key);
        if (control.controls) {
          this.getFormValidationErrors(form.get(key));
        }
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            console.log(
              "Key control: " +
              key +
              ", keyError: " +
              keyError +
              ", err value: ",
              controlErrors[keyError]
            );
          });
        }
      });
    }
  }

}
