export enum EBusinessPaymentType {
  //Current year
  WITHHOLDING = 'Withholding',
  RECONCILIATION = "Reconciliation",
  QUARTER_1_ESTIMATE = "1st Quarter Estimate",
  QUARTER_2_ESTIMATE = "2nd Quarter Estimate",
  QUARTER_3_ESTIMATE = "3rd Quarter Estimate",
  QUARTER_4_ESTIMATE = "4th Quarter Estimate",
  //Tax year
  BALANCE_DUE = "Balance Due",
  EXTENSION = "Extension",
  //Previous years
  BALANCE_DUE_NOTICE = 'Balance Due with Notice',
  PAYMENT_PLAN = 'Payment Plan',
}

export enum EBusinessPaymentNoWithholdingsType {
  //Current year
  ESTIMATE = "Estimate",
  //Tax year
  BALANCE_DUE = "Balance Due",
  EXTENSION = "Extension",
  //Previous years
  BALANCE_DUE_NOTICE = 'Balance Due with Notice',
  PAYMENT_PLAN = 'Payment Plan',
}


export enum EBusinessPaymentTypeTaxYear {
  BALANCE_DUE = "Balance Due",
  EXTENSION = "Extension",
}

export enum EBusinessPaymentTypePreviousTaxYear {
  BALANCE_DUE_NOTICE = 'Balance Due with Notice',
  PAYMENT_PLAN = 'Payment Plan',
}

export enum EBusinessPaymentTypeCurrentYear {
  WITHHOLDING = "Withholding",
  RECONCILIATION = "Reconciliation",
  ESTIMATE = "Estimate",
}

export enum EIndividualPaymentType {
  //Tax year
  BALANCE_DUE = 'Balance Due',
  EXTENSION = "Extension",
  //Current year
  QUARTER_1_ESTIMATE = "1st Quarter Estimate",
  QUARTER_2_ESTIMATE = "2nd Quarter Estimate",
  QUARTER_3_ESTIMATE = "3rd Quarter Estimate",
  QUARTER_4_ESTIMATE = "4th Quarter Estimate",
  //Previous years
  BALANCE_DUE_NOTICE = 'Balance Due with Notice',
  PAYMENT_PLAN = 'Payment Plan',
}


export enum EIndividualPaymentTypeTaxYear {
  BALANCE_DUE = 'Balance Due',
}

export enum EIndividualPaymentTypePreviousTaxYear {
  BALANCE_DUE_NOTICE = 'Balance Due with Notice',
  PAYMENT_PLAN = 'Payment Plan',
}

export enum EIndividualPaymentTypeCurrentYear {
  QUARTER_1_ESTIMATE = "1st Quarter Estimate",
  QUARTER_2_ESTIMATE = "2nd Quarter Estimate",
  QUARTER_3_ESTIMATE = "3rd Quarter Estimate",
  QUARTER_4_ESTIMATE = "4th Quarter Estimate",
}

export enum EIndividualPeriod {
  QUARTER_1_ESTIMATE = "1st Quarter Estimate",
  QUARTER_2_ESTIMATE = "2nd Quarter Estimate",
  QUARTER_3_ESTIMATE = "3rd Quarter Estimate",
  QUARTER_4_ESTIMATE = "4th Quarter Estimate",
}
