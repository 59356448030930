import {ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {HexaDetailComponent} from "@hexalang/ui/core";
import {FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors} from "@angular/forms";
import {DateFormControl, EPermission, PaymentHelper} from "@city-tax/shared";
import {BehaviorSubject, Observable, takeUntil, tap} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {IndividualService} from "../../../../../services/individual.service";
import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {NgxPermissionsService} from "ngx-permissions";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {OhIndividualReturnHelper} from "libs/features/individual/src/lib/helpers/oh/oh-individual-return.helper";


@Component({
  selector: "city-tax-feature-individual-filing-oh-page2",
  templateUrl: "./individual-filing-oh-page2.component.html",
  styleUrls: ["./individual-filing-oh-page2.component.scss"],
})
export class IndividualFilingOhPage2Component
  extends HexaDetailComponent
  implements OnInit {
  public form: FormGroup;
  public title$;
  public taxYear = this.individualService?.taxYear;
  public currentYear = this.individualService.currentYear;
  public config$ = this.authService.config$;
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public ePermission = EPermission;
  public hasPermission$: Observable<boolean>;
  public isLoading$ = this.individualService.isLoading$;
  public isDownloading$ = this.individualService.isDownloading$;
  public isUploading$ = this.individualService.isUploading$;
  public error = null;
  public taxDocument = this.authService.organization.taxDocument;
  public city = this.authService.organization;
  public config = this.authService.config;
  public individualReturn$ = this.individualService.individualReturn$;
  public ir;

  changeDetectorRef: any;
  public bsModalRef: BsModalRef;
  scheduleYFields: any;

  constructor(
    injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private individualService: IndividualService,
    private authService: AuthService,
    public toastrService: ToastrService,
    public permissionService: NgxPermissionsService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private paymentHelper: PaymentHelper,
    private cd: ChangeDetectorRef
  ) {
    super(injector);
  }

  get getOtherIncomeTypeScheduleCDetail(): FormArray {
    return this.form.get(
      "page2.worksheetB.otherIncomeTypeScheduleCDetail"
    ) as FormArray;
  }

  get getLossCarryForward(): FormArray {
    return this.form.get("page2.worksheetB.lossCarryForward") as FormArray;
  }

  get getOtherIncomeTypeScheduleK1Detail(): FormArray {
    return this.form.get(
      "page2.worksheetB.otherIncomeTypeScheduleK1Detail"
    ) as FormArray;
  }

  get getOtherIncomeTypeScheduleEDetail(): FormArray {
    return this.form.get(
      "page2.worksheetB.otherIncomeTypeScheduleEDetail"
    ) as FormArray;
  }

  get getOtherIncomeTypeScheduleMiscDetail(): FormArray {
    return this.form.get(
      "page2.worksheetB.otherIncomeTypeScheduleMiscDetail"
    ) as FormArray;
  }

  get getScheduleOfWages(): FormArray {
    return this.form.get("scheduleOfWages") as FormArray;
  }

  get getAdjustmentToIncomeLine(): FormArray {
    return this.form.get(
      "page2.worksheetC.adjustmentToIncomeLine"
    ) as FormArray;
  }

  get getTaxpayerScheduleOfWages(): FormArray {
    return this.form.get(
      "page2.worksheetA.taxpayerScheduleOfWages"
    ) as FormArray;
  }

  get getSpouseScheduleOfWages(): FormArray {
    return this.form.get("page2.worksheetA.spouseScheduleOfWages") as FormArray;
  }

  get numberFormat(): string {
    return this.city.components?.individual?.fractionDigits === 2 ? '1.2-2' : '1.0-0';
  }


  async ngOnInit() {
    //this.checkPermission();

    this.form = this.formBuilder.group(
      {
        id: null,
        wagesSalariesTips: null,
        otherIncome: null,
        totalIncome: null,
        adjustments: null,
        taxableIncome: null,
        multiplyTaxableIncome: null,
        fillingCityTaxRatePercent: null,
        totalCreditsAllowable: null,
        taxDue: null,
        totalEstimatedTaxPaidAndPriorYearOverpayment: null,
        totalDueAfterPayments: null,
        lateFillingFee: null,
        totalPenaltyAndInterest: null,
        totalAmountDue: null,
        totalTaxDue: null,
        overpayment: null,

        penalty: null,
        interest: null,
        overpaymentLessThen: null,
        priorYearOverpayment: null,
        estimatedTaxPaid: null,
        totalEstimatedIncomeSubjectToTax: null,
        fillingCityCreditRatePercent: null,
        fillingCityCreditRateDecimal: null,
        estimatedIncomeTax: null,
        lessExpectedTaxCredits: null,
        netTaxDue: null,
        netOverpaymentToBeCredited: null,
        overpaymentToBeRefunded: null,
        overpaymentToBeCredited: null,
        amountDueWithThisDeclaration: null,
        makeCheckPayableTo: null,
        totalOfThisPayment: null,
        ownProperty: null,
        landlordName: null,
        taxForms: this.formBuilder.group({
          w2: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          otherCity: this.formBuilder.group({
            required: null,
            provided: null,
          }),
          federal: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          city: this.formBuilder.group({
            type: null,
            attachment1: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment2_1: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment2_2: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment2_3: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment2_4: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment3: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment4: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment5: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment6: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment7: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment8: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment9: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment10: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment11: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment12: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment13: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment14: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment15: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment16: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment17: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment18: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment19: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment20: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment21: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            attachment22: this.formBuilder.group({
              required: false,
              provided: null,
            }),
            cfCov: this.formBuilder.group({
              required: false,
              provided: null,
            }),
          }),
        }),
        landlordAddress: this.formBuilder.group({
          addressLine1: null,
          addressLine2: null,
          aptNumber: null,
          city: null,
          state: null,
          postalCode: null,
        }),
        filingDate: new DateFormControl(''),
        residentHomeownerCreditAmount: null,
        page2: this.formBuilder.group({
          worksheetA: this.formBuilder.group({
            taxpayerScheduleOfWages: this.formBuilder.array([]),
            spouseScheduleOfWages: this.formBuilder.array([]),
            grandTotalFillingCityTaxWithheld: null,
            grandTotalQualifyingWages: null,
            grandTotalOtherCityTaxWithheld: null,
            grandTotalTaxCreditAllowableOtherCities: null,
            grandTotalWages: null,
            grandTotalScheduleTaxableWages: null,
            grandTotalBox1Wages: null,
          }),
          worksheetB: this.formBuilder.group({
            totalIncomeOrLoss: null,
            totalMunicipalityTaxableIncome: null,
            totalTaxCreditAllowedForTaxPaidToOtherCities: null,
            otherIncomeTypeScheduleCDetail: this.formBuilder.array([]),
            otherIncomeTypeScheduleK1Detail: this.formBuilder.array([]),
            otherIncomeTypeScheduleEDetail: this.formBuilder.array([]),
            otherIncomeTypeScheduleMiscDetail: this.formBuilder.array([]),
            lossCarryForward: this.formBuilder.array([]),
            totalIncomeLossScheduleC: null,
            municipalityPercentageScheduleC: null,
            municipalityTaxableIncomeScheduleC: null,
            taxCreditAllowedForTaxPaidToOtherCitiesScheduleC: null,

            totalIncomeLossScheduleK1: null,
            municipalityPercentageScheduleK1: null,
            municipalityTaxableIncomeScheduleK1: null,
            taxCreditAllowedForTaxPaidToOtherCitiesScheduleK1: null,

            totalIncomeLossScheduleE: null,
            municipalityPercentageScheduleE: null,
            municipalityTaxableIncomeScheduleE: null,
            taxCreditAllowedForTaxPaidToOtherCitiesScheduleE: null,

            totalNetOperatingLossCarryForward: null,
            municipalityPercentageNetOperatingLossCarryForward: null,
            municipalityTaxableIncomeNetOperatingLossCarryForward: null,
            taxCreditAllowedForTaxPaidToOtherCitiesNetOperatingLossCarryForward:
              null,

            totalIncomeLossMisc: null,
            municipalityPercentageMisc: null,
            municipalityTaxableIncomeMisc: null,
            taxCreditAllowedForTaxPaidToOtherCitiesMisc: null,
          }),
          worksheetC: this.formBuilder.group({
            adjustmentToIncomeLine: this.formBuilder.array([]),
            totalColumn1Adjustment: null,
          }),
        }),
        authorization: [false, [this.trueValueValidator]],
        scheduleOfWages: this.formBuilder.array([]),
      },
      {
        validators: this._totalValidator,
      }
    );

    await this.getReturn();

    this.form.valueChanges.pipe(
      takeUntil(this.destroy$),
      tap(() => this.cd.detectChanges())
    ).subscribe();
  }

  trueValueValidator(control: FormControl): { [key: string]: any } | null {
    return control.value === true ? null : {'notTrue': true};
  }

  addToTaxpayerScheduleOfWages(item: any) {
    this.getTaxpayerScheduleOfWages.push(item);
  }

  addToSpouseScheduleOfWages(item: any) {
    this.getSpouseScheduleOfWages.push(item);
  }

  addWorksheetA() {
    this.individualService.individualReturn?.page2?.worksheetA?.spouseScheduleOfWages?.map(
      (u: any) => {
        u.type = "S";
        u.w2LocalityInfo = u.w2LocalityInfo
          ? this.formBuilder.array([...u.w2LocalityInfo])
          : this.formBuilder.array([]);

        this.addToSpouseScheduleOfWages(this.formBuilder.group(u));
      }
    );

    this.individualService.individualReturn?.page2?.worksheetA?.taxpayerScheduleOfWages?.map(
      (u: any) => {
        u.type = "T";
        u.w2LocalityInfo = u.w2LocalityInfo
          ? this.formBuilder.array([...u.w2LocalityInfo])
          : this.formBuilder.array([]);
        this.addToTaxpayerScheduleOfWages(this.formBuilder.group(u));
      }
    );
  }

  addWorksheetB() {
    this.individualService.individualReturn?.page2?.worksheetB?.otherIncomeTypeScheduleCDetail?.map(
      (u: any) => {
        this.getOtherIncomeTypeScheduleCDetail.push(this.formBuilder.group(u));
      }
    );

    this.individualService.individualReturn?.page2?.worksheetB?.otherIncomeTypeScheduleK1Detail?.map(
      (u: any) => {
        this.getOtherIncomeTypeScheduleK1Detail.push(this.formBuilder.group(u));
      }
    );

    this.individualService.individualReturn?.page2?.worksheetB?.otherIncomeTypeScheduleEDetail?.map(
      (u: any) => {
        this.getOtherIncomeTypeScheduleEDetail.push(this.formBuilder.group(u));
      }
    );

    this.individualService.individualReturn?.page2?.worksheetB?.otherIncomeTypeScheduleMiscDetail?.map(
      (u: any) => {
        this.getOtherIncomeTypeScheduleMiscDetail.push(
          this.formBuilder.group(u)
        );
      }
    );

    this.individualService.individualReturn?.page2?.worksheetB?.lossCarryForward?.map(
      (u: any) => {
        this.getLossCarryForward.push(this.formBuilder.group(u));
      }
    );
  }

  addWorksheetC() {
    this.individualService.individualReturn?.page2?.worksheetC?.adjustmentToIncomeLine?.map(
      (u: any) => {
        this.getAdjustmentToIncomeLine.push(this.formBuilder.group(u));
      }
    );
  }

  public trackByFn = (index, item) => {
    return index;
  };

  async next() {
    const path = `/individual/filings/oh/${this.individualService.taxYear}/acknowledgement`;
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      await this.router.navigate([path]);
    } else if (this.form.valid) {

      const overpaymentToBeCredited = this.paymentHelper.decimalValue(this.form.get("overpaymentToBeCredited").value);
      const overpaymentToBeRefunded = this.paymentHelper.decimalValue(this.form.get("overpaymentToBeRefunded").value);

      const authorization = this.form.get("authorization").value;
      const totalTaxDue = this.paymentHelper.decimalValue(this.form.get('totalAmountDue').value);

      const amountDueWithThisDeclaration = this.form.get('amountDueWithThisDeclaration').value;
      const totalOfThisPayment = this.form.get('totalOfThisPayment').value;
      const netTaxDue = this.form.get('netTaxDue').value;
      const estimatedIncomeTax = this.form.get("estimatedIncomeTax").value;
      const totalEstimatedIncomeSubjectToTax = this.form.get("totalEstimatedIncomeSubjectToTax").value;
      const netOverpaymentToBeCredited = this.form.get('netOverpaymentToBeCredited').value;

      const payload = {
        netTaxDue,
        estimatedIncomeTax,
        totalOfThisPayment,
        totalEstimatedIncomeSubjectToTax,
        amountDueWithThisDeclaration,
        totalDueAfterPayments: this.form.get('totalDueAfterPayments').value,
        totalAmountDue: totalTaxDue,
        netOverpaymentToBeCredited,
        lateFillingFee: this.form.get('lateFillingFee').value,
        totalPenaltyAndInterest: this.form.get('totalPenaltyAndInterest').value,
        overpaymentToBeCredited: overpaymentToBeCredited === 0 ? null : overpaymentToBeCredited,
        overpaymentToBeRefunded: overpaymentToBeRefunded === 0 ? null : overpaymentToBeRefunded,
        authorization,
        totalTaxDue,
        taxForms: this.form.get("taxForms").value,
      };
      try {
        //console.log('............................................', payload);
        await this.individualService.updateReturn(this.city.id, payload)
        await this.router.navigate([path]);
      } catch (error) {

      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  async back() {
    const path = `/individual/filings/oh/${this.individualService.taxYear}/page1`;

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      await this.router.navigate([path]);
    }

    const overpaymentToBeCredited = this.paymentHelper.decimalValue(this.form.get("overpaymentToBeCredited").value);
    const overpaymentToBeRefunded = this.paymentHelper.decimalValue(this.form.get("overpaymentToBeRefunded").value);

    const authorization = this.form.get("authorization").value;

    const payload = {
      overpaymentToBeCredited: overpaymentToBeCredited === 0 ? null : overpaymentToBeCredited,
      overpaymentToBeRefunded: overpaymentToBeRefunded === 0 ? null : overpaymentToBeRefunded,
      authorization,
    };

    try {
      await this.individualService.updateReturn(this.city.id, payload);
      await this.router.navigate([path]);
    } catch (error) {

    }
  }

  async print() {
    try {
      await this.individualService.printWorksheets(this.authService.organization.id, this.individualService.individualReturn?.id, 'printWorksheet')
    } catch (error) {
    }
  }

  public hasSchedules = () => {
    // console.log('hasSchedules',this.ir);
    return (
      this.ir?.page2?.worksheetB?.scheduleC?.schedule?.length > 0 ||
      this.ir?.page2?.worksheetB?.scheduleK1?.schedule?.length > 0 ||
      this.ir?.page2?.worksheetB?.scheduleE?.schedule?.length > 0 ||
      this.ir?.page2?.worksheetB?.scheduleMisc?.schedule?.length > 0 ||
      this.ir?.page2?.worksheetB?.lossCarryForward?.schedule?.length > 0
    );
  };

  public hasAdjustments = () => {
    return this.ir?.worksheetC?.adjustmentToIncome?.length > 0;
  };

  public hasScheduleY = () => {
    return (
      this.ir?.worksheetB?.scheduleC?.schedule?.length > 0 &&
      this.ir?.worksheetB?.scheduleC?.schedule?.map((c) => c.scheduleY) !== null
    );
  };

  isPartYear() {
    const residency = this.ir?.residency;
    const partYearToDate = this.ir?.partYearToDate;
    const partYearFromDate = this.ir?.partYearToDate;
    if (residency === "P") return true;
    return !!(partYearToDate || partYearFromDate);
  }

  getQualifyingWages(wage: any): number {
    const box1Wages = this.paymentHelper.decimalValue(wage.box1Wages);
    const totalBox5Wages = this.paymentHelper.decimalValue(wage.totalBox5Wages);
    let wageAmount = totalBox5Wages === 0 ? box1Wages : totalBox5Wages;
    const sumAmounts = this.paymentHelper.sum(wage?.w2LocalityInfo, "wagesBox18");
    return this.isPartYear() ? sumAmounts : wageAmount > sumAmounts ? wageAmount : sumAmounts;
  }

  formatEmail(email: string) {
    if (!email) return null;
    return email.replace(/(?<=^.)[^@]*|(?<=@.).*(?=\.[^.]+$)/gm, (match) =>
      "*".repeat(match.length)
    );
  }

  getFormValidationErrors(form) {
    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      if (control.controls) {
        this.getFormValidationErrors(control);
      }
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }

  interestPerMonth(interest: number) {
    return this.paymentHelper.decimalValue(+interest / 12, 6);
  }

  setNetOverpaymentToBeCredited(event) {
    const value = this.paymentHelper.decimalValue(this.form.get('overpaymentToBeCredited').value);
    this.form.get('netOverpaymentToBeCredited').setValue(value);
    this.calculate();
  }

  private async getReturn() {
    try {
      await this.individualService.getReturn(this.authService.organization.id, "OH");
      this.patchForm();
      this.calculate();
    } catch (error) {

    }
  }

  private patchForm() {
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      this.form.patchValue(this.individualService.individualReturn);
      this.isDisabled$.next(true)
      this.form.disable();
    } else {

      this.ir = new OhIndividualReturnHelper(this.paymentHelper, this.authService.config, this.individualService.individualReturn);
      this.form.patchValue(this.ir?.irForm);
      this.scheduleYFields = this.ir.schedulesY;
      this.addWorksheetA();
      this.addWorksheetB();
      this.addWorksheetC();
    }
  }

  calculate() {
    //line 16
    let totalEstimatedIncomeSubjectToTax = this.paymentHelper.decimalValue(this.form.get('totalEstimatedIncomeSubjectToTax').value, 2);

    //RM 02202024 REMOVED For Vandalia issue 65975fd5a1f3d38ce71bb2d6
    // if (!totalEstimatedIncomeSubjectToTax || totalEstimatedIncomeSubjectToTax === 0) {
    //   const taxableIncome = this.paymentHelper.decimalValue(this.form.get('taxableIncome').value, 2);
    //   this.form.get('totalEstimatedIncomeSubjectToTax').setValue(taxableIncome);
    //   totalEstimatedIncomeSubjectToTax = taxableIncome;
    // }

    //17
    const estimatedIncomeTax = this.paymentHelper.decimalValue(totalEstimatedIncomeSubjectToTax * this.config?.components?.individual?.taxRate, 2);
    this.form.get('estimatedIncomeTax').setValue(estimatedIncomeTax);
    //line 18
    const lessExpectedTaxCredits = this.paymentHelper.decimalValue(this.form.get('lessExpectedTaxCredits').value, 2);
    const netTaxDue = this.paymentHelper.decimalValue(estimatedIncomeTax - lessExpectedTaxCredits);
    this.form.get('netTaxDue').setValue(netTaxDue > 0 ? netTaxDue : 0);

    const overpaymentToBeCredited = this.paymentHelper.decimalValue(this.form.get('overpaymentToBeCredited').value);
    this.form.get('netOverpaymentToBeCredited').setValue(overpaymentToBeCredited);

    const amountDueWithThisDeclaration = this.calculateAmountDueWithThisDeclaration(netTaxDue, overpaymentToBeCredited);
    this.form.get('amountDueWithThisDeclaration').setValue(amountDueWithThisDeclaration);
    const totalAmountDue = this.form.get('totalAmountDue').value
    const totalOfThisPayment = this.paymentHelper.decimalValue((this.paymentHelper.decimalValue(amountDueWithThisDeclaration) + this.paymentHelper.decimalValue(totalAmountDue)));
    this.form.get('totalOfThisPayment').setValue(totalOfThisPayment);
  }

  calculateAmountDueWithThisDeclaration(netTaxDue, overpaymentToBeCredited) {
    const value = this.paymentHelper.decimalValue((this.paymentHelper.decimalValue(netTaxDue) - this.paymentHelper.decimalValue(overpaymentToBeCredited)) / 4);
    return value > 0 ? value : 0
  }

  private _totalValidator = (group: FormGroup) => {
    const fractionDigits = this.city.components?.individual?.fractionDigits || 0;

    const overpaymentToBeCredited = group.get("overpaymentToBeCredited");
    const overpaymentToBeRefunded = group.get("overpaymentToBeRefunded");
    const overpayment = group.get("overpayment");
    if (!overpayment.value || this.paymentHelper.decimalValue(overpayment.value) === 0) return null;

    const valid = this.paymentHelper.decimalValue(overpaymentToBeRefunded.value, fractionDigits) + this.paymentHelper.decimalValue(overpaymentToBeCredited.value, fractionDigits) === this.paymentHelper.decimalValue(overpayment.value, fractionDigits);

    if (!valid) {
      return {totalMismatch: true,};
    }
    return null;
  };
}
