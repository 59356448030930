<div [formGroup]="form" class="row p-3 m-0">
  <div class="d-flex flex-column flex-grow-1">
    <h4 class="text-primary py-3 flex-grow-1 m-0">{{'Payment.choose-payment-method' | translate}}</h4>
    <div class="card-container">
      <div *ngFor="let paymentMethod of (state$ | async)?.paymentMethods; let index = index;">
        <div (click)="selectPaymentMethod(paymentMethod)"
            [ngClass]="{'border-active': paymentMethod.id === form.getRawValue()?.pm?.id }"
            class="card card-border">
          <div>
            <div class="d-flex justify-content-between align-items-center">
              <div *ngIf="paymentMethod.type === 'card' && hasCC; else bankTemplate" class="custom-cards">
                <span *ngIf="paymentMethod.isDefault" class="default-checkmark">✔</span>
                <img [src]="getBrandLogo(paymentMethod.card.brand)" alt="{{ paymentMethod.card.brand | titlecase }}" class="custom-icon">
                <div class="card-text">{{ paymentMethod.card.cardNumber }}</div>
              </div>
              <ng-template #bankTemplate>
                <div *ngIf="(paymentMethod.type === 'ach' || paymentMethod.type === 'bank') && hasAch" class="custom-cards">
                  <span *ngIf="paymentMethod.isDefault" class="default-checkmark">✔</span>
                  <img [src]="getBrandLogo(paymentMethod.type)" alt="{{ paymentMethod.type }}" class="custom-icon">
                  <div class="card-text">{{ paymentMethod.name }}</div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
      <button (click)="showAddCardMethod()" 
          *ngIf="this.hasCC" 
          class="btn btn-primary font-weight-600 shadow-none"
          [attr.aria-label]="'Payment.add-credit-debit-card' | translate"
          role="button" 
          tabindex="0">{{'Payment.add-credit-debit-card' | translate}}</button>
      <button (click)="showAddBankAccountMethod()" 
          *ngIf="this.hasAch" 
          class="btn btn-primary btn-custom shadow-none"
          [attr.aria-label]="'Payment.add-bank-account' | translate" 
          role="button" 
          tabindex="0">{{'Payment.add-bank-account' | translate}}</button>
    </div>
    <div>
      <div *ngIf="isBankTab">
        <div class="card mt-3">
          <div class="p-3">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group pl-4">
                  <input
                      class="custom-control-input"
                      formControlName="addAsPaymentMethod"
                      id="addAsPaymentMethod"
                      type="checkbox"
                      [attr.aria-label]="'Payment.save-account-for-future-payments' | translate"
                  />
                  <label class="custom-control-label" for="addAsPaymentMethod">
                    {{'Payment.save-account-for-future-payments' | translate}}
                  </label>
                </div>
              </div>
              <div class="col-12 col-md-6">
              </div>
            </div>
            <div id="bank">
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="font-weight-600" for="routingNumber">
                      {{ "CommonForm.routing-number-label" | translate }}
                      <span class="text-danger pl-1">*</span>
                    </label>
                    <div class="input-group" formGroupName="bank">
                      <input (change)="stripControl(form.get('bank.routingNumber'))"
                           id="routingNumber"
                          (displayCounter)="displayCounter($event, 'bank.routingNumber')"
                          (keypress)="onlyNumberKey($event)"
                          [class.is-invalid]="form.get('bank.routingNumber').invalid && (form.get('bank.routingNumber').dirty || form.get('bank.routingNumber').touched)"
                          class="form-control"
                          formControlName="routingNumber"
                          maskChar="*"
                          type="text"
                          [attr.aria-invalid]="form.get('bank.routingNumber').invalid && (form.get('bank.routingNumber').dirty || form.get('bank.routingNumber').touched) ? 'true' : 'false'"
                          [attr.aria-label]="'Routing Number'"
                          [attr.aria-required]="'true'"
                          ui-mask-show/>
                    </div>
                    <div *ngIf="form.get('bank.routingNumber').touched && form.get('bank.routingNumber').errors" class="invalid-feedback" style="display: block">
                      <div *ngIf="form.get('bank.routingNumber').errors['required'] ||form.get('bank.routingNumber').errors['pattern']">
                        {{ "CommonForm.routing-number-required"| translate }}
                      </div>
                      <div *ngIf="form.get('bank.routingNumber').errors['mustMatch']">
                        {{ "CommonForm.routing-number-not-valid"| translate }}
                      </div>
                      <div *ngIf="form.get('bank.routingNumber').errors['invalidRoutingNumber'] || form.get('bank.routingNumber').errors['lengthError'] ">
                        {{ "CommonForm.routing-number-not-valid"| translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label class="font-weight-600" for="confirmRoutingNumber">{{ "CommonForm.confirm-routing-number" | translate }} <span class="text-danger pl-1">*</span></label>
                    <div class="input-group">
                      <input (change)="stripControl(form.get('confirmRoutingNumber'))"
                          (displayCounter)="displayCounter($event, 'confirmRoutingNumber')"
                          (keypress)="onlyNumberKey($event)"
                          [class.is-invalid]="form.get('confirmRoutingNumber').invalid && (form.get('confirmRoutingNumber').dirty || form.get('confirmRoutingNumber').touched)"
                          class="form-control"
                          formControlName="confirmRoutingNumber"
                          maskChar="*"
                          type="text"
                          id="confirmRoutingNumber"
                          [attr.aria-invalid]="form.get('confirmRoutingNumber').invalid && (form.get('confirmRoutingNumber').dirty || form.get('confirmRoutingNumber').touched) ? 'true' : 'false'"
                          [attr.aria-label]="'Confirm Routing Number'"
                          [attr.aria-required]="'true'"
                          ui-mask-show
                      />
                    </div>
                    <div *ngIf="form.get('confirmRoutingNumber').touched && form.get('confirmRoutingNumber').errors" class="invalid-feedback" style="display: block">
                      <div *ngIf="form.get('confirmRoutingNumber').errors['required']">
                        {{ "CommonForm.routing-number-required"| translate }}
                      </div>
                      <div *ngIf="form.get('confirmRoutingNumber').errors['mustMatch']">
                        {{ "CommonForm.routing-number-not-valid"| translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="accountNumber" class="font-weight-600">
                      {{ "CommonForm.account-number-label" | translate }}
                    </label><span class="text-danger pl-1">*</span>
                    <div class="input-group" formGroupName="bank">
                      <input (change)="stripControl(form.get('bank.accountNumber'))"
                          (displayCounter)="displayCounter($event, 'bank.accountNumber')"
                          (keypress)="onlyNumberKey($event)"
                          [class.is-invalid]=" form.get('bank.accountNumber').invalid && (form.get('bank.accountNumber').dirty || form.get('bank.accountNumber').touched)"
                          class="form-control"
                          formControlName="accountNumber"
                          maskChar="*"
                          type="text"
                          id="accountNumber"
                          [attr.aria-label]="'CommonForm.account-number-label' | translate"
                          [attr.aria-required]="'true'"
                          [attr.aria-invalid]="form.get('bank.accountNumber').invalid && (form.get('bank.accountNumber').dirty || form.get('bank.accountNumber').touched) ? 'true' : 'false'"
                          ui-mask-show
                      />
                    </div>
                    <div *ngIf="form.get('bank.accountNumber').touched && form.get('bank.accountNumber').errors " class="invalid-feedback" style="display: block">
                      <div *ngIf="form.get('bank.accountNumber').errors['required']">
                        {{ "CommonForm.account-number-required"| translate }}
                      </div>
                      <div *ngIf="form.get('bank.accountNumber').errors[ 'mustMatch'  ] || form.get('bank.accountNumber').errors['pattern']">
                        {{ "CommonForm.account-number-not-valid"| translate }}
                      </div>
                      <div *ngIf="form.get('bank.accountNumber').errors['invalidAccountNumber'] || form.get('bank.accountNumber').errors['lengthError']">
                        {{ "CommonForm.account-number-not-valid"| translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label for="confirmAccountNumber" class="font-weight-600">
                      {{ "CommonForm.confirm-account-number-label"| translate }}
                    </label> <span class="text-danger pl-1">*</span>
                    <div class="input-group">
                      <input
                          (change)="stripControl(form.get('confirmAccountNumber'))"
                          (displayCounter)="displayCounter($event, 'confirmAccountNumber')"
                          (keypress)="onlyNumberKey($event)"
                          [class.is-invalid]="form.get('confirmAccountNumber').invalid && (form.get('confirmAccountNumber').dirty || form.get('confirmAccountNumber').touched)"
                          class="form-control"
                          formControlName="confirmAccountNumber"
                          maskChar="*"
                          type="text"
                          id="confirmAccountNumber"
                          [attr.aria-label]="'CommonForm.confirm-account-number-label' | translate"
                          [attr.aria-required]="'true'"
                          [attr.aria-invalid]="form.get('confirmAccountNumber').invalid && (form.get('confirmAccountNumber').dirty || form.get('confirmAccountNumber').touched) ? 'true' : 'false'"
                          ui-mask-show
                      />
                    </div>
                    <div *ngIf="form.get('confirmAccountNumber').touched && form.get('confirmAccountNumber').errors"
                        class="invalid-feedback"
                        style="display: block">
                      <div *ngIf="form.get('confirmAccountNumber').errors['required']">
                        {{ "CommonForm.account-number-required"| translate }}
                      </div>
                      <div *ngIf="form.get('confirmAccountNumber').errors['mustMatch']">
                        {{ "CommonForm.account-number-not-valid"| translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group" formGroupName="bank" >
              <div class="d-flex align-items-center mb-1">
                <label for="accountType" class="mb-0">{{ "CommonForm.account-type-label" | translate }}<span class="text-danger pl-1">*</span></label>
                <div *ngFor="let rad of accountType" class="radio-inline d-flex align-items-center" >
                  <input 
                      [class.is-invalid]=" form.get('bank.accountType')?. invalid &&(form.get('bank.accountType')?.dirty ||form.get('bank.accountType')?.touched)"
                      id="accountType-{{rad.value}}"
                      class="radio m-1 text-sm-start"
                      formControlName="accountType"
                      name="accountType"
                      type="radio"
                      value="{{ rad.value }}"
                      [attr.aria-invalid]="form.get('bank.accountType')?.invalid && (form.get('bank.accountType')?.dirty || form.get('bank.accountType')?.touched) ? 'true' : 'false'"
                      [attr.aria-required]="'true'"
                      [attr.aria-label]="rad.label | translate"
                  />
                  <label for="accountType-{{rad.value}}" class="mb-0">{{ rad.label | translate }}</label>
                  </div>
                </div>
                <div *ngIf="form.get('bank.accountType').touched &&form.get('bank.accountType').errors"
                    class="invalid-feedback"
                    style="display: block">
                  <div *ngIf="form.get('bank.accountType').errors['required']">
                    {{'Payment.account-type-required' | translate}}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <span class="small font-weight-medium text-muted text-uppercase">
                    {{ "PaymentPage.payment-note" | translate }}
                  </span>
                </div>
              </div>
              <div *ngIf="hadReturnCheckFee" class="row">
                <div class="col-12">
                  <span class="small font-weight-medium text-muted text-uppercase">
                    {{ "PaymentPage.payment-return" | translate :returnCheckFee }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="isCreditCardTab">
        <div class="card mt-3">
          <div class="p-3">
            <div class="row">
              <div class="col-12 col-md-6 ">
                <div class="form-group pl-4">
                  <input
                      class="custom-control-input"
                      formControlName="addAsPaymentMethod"
                      id="addAsPaymentMethodCard"
                      type="checkbox"
                      [attr.aria-label]="'Payment.save-card-future-payments' | translate"
                  />
                  <label class="custom-control-label" for="addAsPaymentMethodCard">
                    {{'Payment.save-card-future-payments' | translate}}
                  </label>
                </div>
              </div>
            </div>
            <div *ngIf="isCreditCardTab" id="card">
              <div *ngIf="isCreditCardTab" id="payment-element"></div>
              <div class="row py-2">
                <div class="col-12">
              <span class="small font-weight-medium text-muted text-uppercase">
                {{'Payment.convenience-fee-collected' | translate}}
              </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!isBankTab && !isCreditCardTab">
        <city-tax-card-details *ngIf="form.value?.pm" [selectedPaymentMethod]="form.value?.pm">
        </city-tax-card-details>
      </div>
    </div>
  </div>
</div>
