import {NgModule} from "@angular/core";
import {CommonModule, CurrencyPipe, DatePipe, DecimalPipe, NgOptimizedImage} from "@angular/common";
import {RouterModule} from "@angular/router";

import {IndividualRouting} from "./individual.routing";
import {IndividualComponent} from "./individual.component";
import {
  IndividualFilingMiPage1Component
} from "./components/filing/tax-year/mi/page1/individual-filing-mi-page1.component";
import {
  IndividualFilingMiPage2Component
} from "./components/filing/tax-year/mi/page2/individual-filing-mi-page2.component";

import {IndividualFilingMiComponent} from "./components/filing/tax-year/mi/individual-filing-mi.component";
import {IndividualFilingOhComponent} from "./components/filing/tax-year/oh/individual-filing-oh.component";

import {IndividualPaymentComponent} from "./components/payment/individual-payment.component";

import {IndividualFilingTaxYearComponent} from "./components/filing/tax-year/individual-filing-tax-year.component";
import {IndividualFilingUploadComponent} from "./components/filing/tax-year/upload/individual-filing-upload.component";
import {
  IndividualFilingConfirmationComponent
} from "./components/filing/tax-year/confirmation/individual-filing-confirmation.component";
import {IndividualUpdateComponent} from './components/individual-update/individual-update.component';

import {IndividualFilingTypeComponent} from './components/filing/tax-year/type/individual-filing-type.component';
import {
  IndividualFilingAcknowledgementComponent
} from './components/filing/tax-year/acknowledgement/individual-filing-acknowledgement.component';
import {IndividualFilingMiTCComponent} from "./components/filing/tax-year/mi/tc/individual-filing-mi-tc.component";
import {
  IndividualFilingOhPage1Component
} from "./components/filing/tax-year/oh/page1/individual-filing-oh-page1.component";
import {
  IndividualFilingOhPage2Component
} from "./components/filing/tax-year/oh/page2/individual-filing-oh-page2.component";
import {
  IndividualFilingOhStartComponent
} from './components/filing/tax-year/oh/start/individual-filing-oh-start.component';
import {
  IndividualFilingMiStartComponent
} from "./components/filing/tax-year/mi/start/individual-filing-mi-start.component";
import {
  IndividualFilingOhW2FormComponent
} from "./components/filing/tax-year/oh/w2-form/individual-filing-oh-w2-form.component";
import {
  IndividualFilingOhScheduleFormComponent
} from "./components/filing/tax-year/oh/schedule-form/individual-filing-oh-schedule-form.component";
import {
  IndividualFilingOhAdjustmentIncomeFormComponent
} from "./components/filing/tax-year/oh/adjustment-income-form/individual-filing-oh-adjustment-income-form.component";
import {LocalityComponent} from "./components/filing/tax-year/oh/controls/locality/locality.component";
import {EstimateComponent} from './components/estimate/estimate.component';
import {PercentComponent} from './components/filing/tax-year/oh/controls/percent/percent.component';
import {SharedModule} from "@city-tax/shared";
import {LoadingBarModule} from "@ngx-loading-bar/core";

import {FileSizePipe, PhonePipe, SsnPipe} from "@hexalang/ui/shared";
import {
  CourtesyWithholdingComponent
} from './components/filing/tax-year/oh/controls/courtesy-withholding/courtesy-withholding.component';
import {
  IndividualFilingMIExcludedTaxWithheldFormComponent
} from "./components/filing/tax-year/mi/excluded-tax-withheld-form/individual-filing-mi-excluded-tax-withheld-form.component";
import {MiLocalityComponent} from "./components/filing/tax-year/mi/controls/mi-locality/mi-locality.component";
import {NotificationComponent} from './components/notification/notification.component';

import {
  IndividualPaymentHistoryComponent
} from "./components/payment/payment-history/individual-payment-history.component";
import {
  IndividualPaymentCreateIntentComponent
} from "./components/payment/payment-create/intent/individual-payment-create-intent.component";
import {
  IndividualPaymentCreateComponent
} from "./components/payment/payment-create/individual-payment-create.component";
import {
  IndividualPaymentCreateConfirmationComponent
} from "./components/payment/payment-create/confirmation/individual-payment-create-confirmation.component";
import {
  IndividualPaymentMethodListComponent
} from "./components/payment/payment-method-list/individual-payment-method-list.component";
import {
  IndividualPaymentMethodCreateComponent
} from "./components/payment/payment-method-create/individual-payment-method-create.component";
import {
  IndividualPaymentMethodUpdateComponent
} from "./components/payment/payment-method-update/individual-payment-method-update.component";
import {
  IndividualPaymentMethodDeleteComponent
} from "./components/payment/payment-method-delete/individual-payment-method-delete.component";
import {IndividualPaymentDeleteComponent} from './components/payment/payment-delete/payment-delete.component';
import {
  IndividualPaymentRecurringComponent
} from './components/payment/payment-create/recurring/individual-payment-recurring.component';
import {
  IndividualPaymentRecurringEditComponent
} from './components/payment/payment-create/recurring-edit/individual-payment-recurring-edit.component';
import { IndividualPaymentRecurringCancelComponent } from './components/payment/payment-create/recurring-cancel/individual-payment-recurring-cancel.component';
import { LocalityRefundComponent } from './components/filing/tax-year/oh/controls/locality-refund/locality-refund.component';


@NgModule({
  imports: [
    IndividualRouting,
    CommonModule,
    RouterModule,
    SharedModule,
    LoadingBarModule,
    NgOptimizedImage,
  ],
  declarations: [
    IndividualComponent,
    IndividualFilingMiPage1Component,
    IndividualFilingMiPage2Component,
    IndividualFilingMiTCComponent,
    IndividualFilingConfirmationComponent,
    IndividualFilingMiComponent,
    IndividualFilingOhComponent,
    IndividualPaymentComponent,
    IndividualPaymentHistoryComponent,
    IndividualPaymentCreateIntentComponent,
    IndividualPaymentCreateComponent,
    IndividualPaymentCreateConfirmationComponent,
    IndividualPaymentMethodListComponent,
    IndividualPaymentMethodCreateComponent,
    IndividualPaymentMethodUpdateComponent,
    IndividualPaymentMethodDeleteComponent,
    IndividualFilingTaxYearComponent,
    IndividualFilingUploadComponent,
    IndividualUpdateComponent,
    IndividualFilingTypeComponent,
    IndividualFilingAcknowledgementComponent,
    IndividualFilingOhPage1Component,
    IndividualFilingOhPage2Component,
    IndividualFilingOhStartComponent,
    IndividualFilingMiStartComponent,
    IndividualFilingOhW2FormComponent,
    IndividualFilingOhScheduleFormComponent,
    IndividualFilingOhAdjustmentIncomeFormComponent,
    LocalityComponent,
    EstimateComponent,
    PercentComponent,
    CourtesyWithholdingComponent,
    IndividualFilingMIExcludedTaxWithheldFormComponent,
    MiLocalityComponent,
    NotificationComponent,
    IndividualPaymentDeleteComponent,
    IndividualPaymentRecurringComponent,
    IndividualPaymentRecurringEditComponent,
    IndividualPaymentRecurringCancelComponent,
    LocalityRefundComponent,
  ],
  exports: [IndividualUpdateComponent, EstimateComponent],
  providers: [CurrencyPipe, DatePipe, DecimalPipe, FileSizePipe, PhonePipe, SsnPipe],
})
export class IndividualModule {
}
