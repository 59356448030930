import {AfterViewInit, Component, Injector, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {FormatService, PaymentHelper} from "@city-tax/shared";
import {Router} from "@angular/router";
import {IndividualService} from "../../../../services/individual.service";
import {AuthService} from "@city-tax/features/auth";
import {PaymentService} from "../../../../services/payment.service";
import {HexaDetailComponent, HexaTitleService} from "@hexalang/ui/core";
import {ToastrService} from "ngx-toastr";
import {BehaviorSubject} from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "city-tax-feature-individual-filing-confirmation",
  templateUrl: "./individual-filing-confirmation.component.html",
  styleUrls: ["./individual-filing-confirmation.component.scss"],
})
export class IndividualFilingConfirmationComponent
  extends HexaDetailComponent
  implements OnInit, AfterViewInit {
  @ViewChild("paymentDateTemplate") public paymentDateTemplate: TemplateRef<any>;
  @ViewChild("paymentPrintTemplate") public paymentPrintTemplate: TemplateRef<any>;

  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.individualService.isLoading$;
  public isDownloading$ = this.individualService.isDownloading$;

  public organization = this.authService.org;
  public city = this.authService.config;

  public paymentHelper = new PaymentHelper();

  public individualReturn$ = this.individualService.individualReturn$;
  public payments$ = this.paymentService.payments$;
  public columns = []

  constructor(
    injector: Injector,
    public toastrService: ToastrService,
    private titleService: HexaTitleService,
    public router: Router,
    private individualService: IndividualService,
    public paymentService: PaymentService,
    private translate: TranslateService,
    private authService: AuthService,
    private formatService: FormatService,
  ) {
    super(injector);
    this.titleService.changeTitle(
      this.translate.instant("Taxpayer.taxpayer-filing", { taxYear: this.individualService.taxYear })
    );
    this.translate.onLangChange.subscribe(() => {
      this.titleService.changeTitle(
        this.translate.instant("Taxpayer.taxpayer-filing", { taxYear: this.individualService.taxYear })
      );
    });
  }

  async ngOnInit() {
    await this.getReturn();
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      this.isDisabled$.next(true)
    }
  }

  ngAfterViewInit() {
    this.defineColumns();
  }

  public async downloadReturn(ir: any) {
    try {
      await this.individualService.downloadReturn(this.authService.organization.id, ir.id, 'downloadReturn')
    } catch (error) {
    }
  }

  public async printReturn(ir: any) {
    try {
      await this.individualService.openReturn(
        this.authService.organization.id,
        ir.id,
        "printReturn"
      );
    } catch (error) {
    }
  }

  async uploadDocument(id: string, type: string, event: any) {
    const media = {
      file: event.target.files[0],
      mimeType: event.target.files[0].type,
      type: type
    };
    try {
      await this.individualService.uploadFile(this.authService.organization.id, id, type, media)
    } catch (error) {

    }
  }

  public async delete(file: any) {
    try {
      await this.individualService
        .deleteFile(
          this.authService.organization.id,
          this.individualService.individualReturn.id,
          file.id
        );
    } catch (error) {
      console.log(error)
    }
  }

  sumPayments(payments: any) {
    if (!payments || !payments.data) return;
    return this.paymentService.sum(payments.data, 'amount')
  }

  getFirstQuarterEstimate(payments: any) {
    if (!payments || !payments.data) return;
    const estimates = payments.data.filter(p => p.paymentType === '1st Quarter Estimate');
    return this.paymentService.sum(estimates, 'amount')
  }

  async printReceipt(id: string) {
    try {
      await this.paymentService.printReceipt(this.authService.organization.id, id, `printReceipt-${id}`);
    } catch (error) {
    }
  }

  async downloadReceipt(id: string) {
    try {
      await this.paymentService.downloadReceipt(this.authService.organization.id, id, `downloadReceipt-${id}`);
    } catch (error) {
    }
  }

  private async getReturn() {
    try {
      const ir = await this.individualService.getReturn(this.authService.organization.id, this.authService.config?.state);
      const taxYear = ir.taxYear;
      await this.getPayments(taxYear);
    } catch (error) {

    }
  }

  private async getPayments(taxYear?: string) {
    const pagingParams = {
      $filter: `((taxYear eq ${taxYear} and paymentType eq 'Balance Due') or (taxYear eq ${+taxYear + 1} and paymentType eq '1st Quarter Estimate')) and (status eq 'Succeeded' or status eq 'Payout')`
    };
    try {
      await this.paymentService.getPayments(this.authService.organization?.id, 'me', pagingParams);
    } catch (error) {
    }
  }

  private defineColumns() {
    this.columns = [
      {
        prop: "paymentDate",
        name: "Payment Date",
        cellTemplate: this.paymentDateTemplate,
        sortable: false,
      },
      {
        prop: "amount",
        name: "Amount",
        pipe: {transform: (value: any) => this.formatService.currencyFormat(value)},
        sortable: false,
      },
      {
        prop: 'locator',
        name: 'Confirmation #',
        sortable: false,
      },

      {
        prop: '',
        cellTemplate: this.paymentPrintTemplate,
        sortable: false,
      },
    ];
  }

  get numberFormat(): string {
    return this.city.components?.individual?.fractionDigits === 2 ? '1.2-2' : '1.0-0';
  }

  decimalValue(value:any) {
    return this.paymentHelper.decimalValue(value,this.city.components?.individual?.fractionDigits);
  }
}
