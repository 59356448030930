<div class="px-4 pt-0 mt-3">
  <div class="card bg-transparent border-0 shadow-none">
    <div class="row">
      <div class="col-12">
        <form [formGroup]="form" class="mb-5" novalidate>
          <div class="main">
            <div class="content">
              <div class="mb-12">
                <h2 class="text-theme-color mt-0 mb-2">
                  {{'Taxpayer.upload-tax-docs' | translate}}
                </h2>

                <div class="form-content py-3">
                  <h5 class="mb-2">
                    {{'Taxpayer.please-upload-all-requested-pdf' | translate}}
                  </h5>
                  <h5 class="mb-2 text-danger">{{'Taxpayer.please-do-not-upload-all-forms-together' | translate}}</h5>
                </div>


                <div class="row">
                  <div class="col-lg-6">
                    <!-- 1st column -->
                    <div class="row">
                      <div class="col-lg-12 mb-3">
                        <div [ngClass]="{'red-box': !(validCityForm$ | async)}"
                            class="card d-flex flex-column p-3 mb-1 pb-1 h-100">

                          <div class="flex-grow-1">
                            <h6 class="pt-1 w-100 font-weight-bold">
                              {{'Taxpayer.your-city-tax-from' | translate:{city: city.name} }}
                               <span class="text-danger pl-1">*</span>
                            </h6>

                            <div class="upload-layout">
                              <div class="drop_box">

                                <header>
                                  <h3 class="mb-0">{{'Taxpayer.select-file-here' | translate}}</h3>
                                </header>

                                <div class="text-center button-upload mt-2">
                                  <button [disabled]="(isUploading$ | async)"
                                          class="btn btn-primary font-weight-600 btn-upload"
                                          [attr.aria-label]="'Taxpayer.upload-file' | translate"
                                          [attr.aria-busy]="(isUploading$ | async) ? 'true' : 'false'" 
                                          tabindex="0" 
                                          role="button">
                                    <ui-spinner [class.active]="(isUploading$ | async) === authService.organization.taxDocument">{{'Taxpayer.upload-file' | translate}}</ui-spinner>
                                  </button>
                                  <input
                                      (change)="uploadCityForm($event)"
                                      accept=".pdf"
                                      class="py-2 px-2 file-upload w-100 border bg-white drop-file"
                                      formControlName="cityFormFile"
                                      name="City Form"
                                      type="file"
                                      [attr.aria-label]="'Select a file for City Form'"
                                  />
                                </div>
                              </div>
                            </div>

                            <div *ngFor="let file of (individualReturn$ | async)?.files"
                                class="row">
                              <div *ngIf="file.type === taxDocument"
                                  class="col-lg-12 text-nowrap">
                                <div
                                    class="row no-gutters align-items-center border-top bg-primary-100 py-1"
                                >
                                  <div class="col-8 align-items-center px-3 py-2">
                                    <svg
                                        *ngIf="file?.mimeType === 'application/pdf'"
                                        class="icon"
                                        viewBox="0 0 24 24"
                                    >
                                      <path
                                          d="M14,9H19.5L14,3.5V9M7,2H15L21,8V20A2,2 0 0,1 19,22H7C5.89,22 5,21.1 5,20V4A2,2 0 0,1 7,2M11.93,12.44C12.34,13.34 12.86,14.08 13.46,14.59L13.87,14.91C13,15.07 11.8,15.35 10.53,15.84V15.84L10.42,15.88L10.92,14.84C11.37,13.97 11.7,13.18 11.93,12.44M18.41,16.25C18.59,16.07 18.68,15.84 18.69,15.59C18.72,15.39 18.67,15.2 18.57,15.04C18.28,14.57 17.53,14.35 16.29,14.35L15,14.42L14.13,13.84C13.5,13.32 12.93,12.41 12.53,11.28L12.57,11.14C12.9,9.81 13.21,8.2 12.55,7.54C12.39,7.38 12.17,7.3 11.94,7.3H11.7C11.33,7.3 11,7.69 10.91,8.07C10.54,9.4 10.76,10.13 11.13,11.34V11.35C10.88,12.23 10.56,13.25 10.05,14.28L9.09,16.08L8.2,16.57C7,17.32 6.43,18.16 6.32,18.69C6.28,18.88 6.3,19.05 6.37,19.23L6.4,19.28L6.88,19.59L7.32,19.7C8.13,19.7 9.05,18.75 10.29,16.63L10.47,16.56C11.5,16.23 12.78,16 14.5,15.81C15.53,16.32 16.74,16.55 17.5,16.55C17.94,16.55 18.24,16.44 18.41,16.25M18,15.54L18.09,15.65C18.08,15.75 18.05,15.76 18,15.78H17.96L17.77,15.8C17.31,15.8 16.6,15.61 15.87,15.29C15.96,15.19 16,15.19 16.1,15.19C17.5,15.19 17.9,15.44 18,15.54M8.83,17C8.18,18.19 7.59,18.85 7.14,19C7.19,18.62 7.64,17.96 8.35,17.31L8.83,17M11.85,10.09C11.62,9.19 11.61,8.46 11.78,8.04L11.85,7.92L12,7.97C12.17,8.21 12.19,8.53 12.09,9.07L12.06,9.23L11.9,10.05L11.85,10.09Z"
                                          stroke="none"
                                      />
                                    </svg>
                                    <svg
                                        *ngIf="
                                        file?.mimeType === 'image/png' ||
                                        file?.mimeType === 'image/jpg' ||
                                        file?.mimeType === 'image/jpeg'
                                      "
                                        class="icon"
                                        viewBox="0 0 24 24"
                                    >
                                      <path
                                          d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M6,20H15L18,20V12L14,16L12,14L6,20M8,9A2,2 0 0,0 6,11A2,2 0 0,0 8,13A2,2 0 0,0 10,11A2,2 0 0,0 8,9Z"
                                          stroke="none"
                                      />
                                    </svg>
                                    {{ file?.fileName }}
                                  </div>
                                  <div class="ml-auto d-flex align-items-center pr-3">
                                    <div class="text-nowrap mr-2">
                                      <strong class="font-weight-medium"
                                      >{{ file?.size * 1e-6 | number }}MB</strong
                                      >
                                    </div>
                                    <div
                                        *ngIf="
                                      (individualReturn$ | async)?.source ===
                                      'Upload'
                                    "
                                        class=""
                                    >
                                      <button
                                          (click)="delete(file)"
                                          class="btn btn-sm bg-danger border shadow p-1"
                                          container="body"
                                          placement="left"
                                          tooltip="{{'Taxpayer.delete' | translate}}"
                                          type="button"
                                          tabindex="0"
                                          role="button"
                                          [attr.aria-label]="'Taxpayer.delete' | translate"
                                      >
                                        <svg class="icon icon-sm fill-white d-flex" viewBox="0 0 24 24">
                                          <path
                                              d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                              stroke="none"></path>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>


                          <!--                          <div-->
                          <!--                              class="row m-0"-->
                          <!--                              *ngIf="(individualReturn$ | async)?.filing === 'MJ'"-->
                          <!--                          >-->
                          <!--                            <div class="col-lg-12 mb-1 pb-1">-->
                          <!--                              <div formGroupName="spouse">-->
                          <!--                                <label class="form-label pt-1 w-100"-->
                          <!--                                >Please Verify Spouse SSN:-->
                          <!--                                   <span class="text-danger pl-1">*</span></label-->
                          <!--                                >-->
                          <!--                                <input-->
                          <!--                                    type="text"-->
                          <!--                                    class="form-control"-->
                          <!--                                    (blur)="maskSsn()"-->
                          <!--                                    (focus)="unmaskSsn()"-->
                          <!--                                    value="{{ SsnDisplay }}"-->
                          <!--                                    [validation]="true"-->
                          <!--                                    placeholder="XXX-XX-0000"-->
                          <!--                                    formControlName="ssn"-->
                          <!--                                    mask="000-00-0000"-->
                          <!--                                />-->
                          <!--                              </div>-->
                          <!--                            </div>-->
                          <!--                          </div>-->

                        </div>
                      </div>

                      <div class="col-lg-12 mb-3">
                        <div class="card d-flex flex-column p-3 mb-1 pb-1 h-100">
                          <div class="flex-grow-1">
                            <h6 class="pt-1 w-100 font-weight-bold"
                            >{{'Taxpayer.upload-any-w2' | translate}}<span class="text-danger pl-1">*</span></h6>
                            <div class="upload-layout">
                              <div class="drop_box">

                                <header>
                                  <h3 class="mb-0">{{'Taxpayer.select-file-here' | translate}}</h3>
                                </header>

                                <div class="text-center button-upload mt-2">
                                  <button [disabled]="(isUploading$ | async)"
                                          class="btn btn-primary font-weight-600 btn-upload"
                                          [attr.aria-label]="'Taxpayer.upload-file' | translate"
                                          [attr.aria-disabled]="(isUploading$ | async) ? 'true' : 'false'" 
                                          [attr.aria-busy]="(isUploading$ | async) === 'W2' ? 'true' : 'false'"
                                          tabindex="0">
                                    <ui-spinner [class.active]="(isUploading$ | async)==='W2'">
                                      {{'Taxpayer.upload-file' | translate}}
                                    </ui-spinner>
                                  </button>
                                  <input
                                      (change)="uploadDocument('W2', $event)"
                                      accept=".pdf"
                                      class="py-2 px-2 file-upload w-100 border bg-white drop-file"
                                      name="W2"
                                      type="file"
                                      [attr.aria-label]="'Taxpayer.choose-file-to-upload' | translate"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                                *ngFor="let file of (individualReturn$ | async)?.files"
                                class="row m-0"
                            >
                              <div *ngIf="file.type === 'W2'" class="col-12 px-0">
                                <div
                                    class="row no-gutters align-items-center border-top bg-primary-100 py-1"
                                >
                                  <div class="col-8 align-items-center px-3 py-2 d-flex">
                                    <svg
                                        *ngIf="file?.mimeType === 'application/pdf'"
                                        class="icon"
                                        viewBox="0 0 24 24"
                                    >
                                      <path
                                          d="M14,9H19.5L14,3.5V9M7,2H15L21,8V20A2,2 0 0,1 19,22H7C5.89,22 5,21.1 5,20V4A2,2 0 0,1 7,2M11.93,12.44C12.34,13.34 12.86,14.08 13.46,14.59L13.87,14.91C13,15.07 11.8,15.35 10.53,15.84V15.84L10.42,15.88L10.92,14.84C11.37,13.97 11.7,13.18 11.93,12.44M18.41,16.25C18.59,16.07 18.68,15.84 18.69,15.59C18.72,15.39 18.67,15.2 18.57,15.04C18.28,14.57 17.53,14.35 16.29,14.35L15,14.42L14.13,13.84C13.5,13.32 12.93,12.41 12.53,11.28L12.57,11.14C12.9,9.81 13.21,8.2 12.55,7.54C12.39,7.38 12.17,7.3 11.94,7.3H11.7C11.33,7.3 11,7.69 10.91,8.07C10.54,9.4 10.76,10.13 11.13,11.34V11.35C10.88,12.23 10.56,13.25 10.05,14.28L9.09,16.08L8.2,16.57C7,17.32 6.43,18.16 6.32,18.69C6.28,18.88 6.3,19.05 6.37,19.23L6.4,19.28L6.88,19.59L7.32,19.7C8.13,19.7 9.05,18.75 10.29,16.63L10.47,16.56C11.5,16.23 12.78,16 14.5,15.81C15.53,16.32 16.74,16.55 17.5,16.55C17.94,16.55 18.24,16.44 18.41,16.25M18,15.54L18.09,15.65C18.08,15.75 18.05,15.76 18,15.78H17.96L17.77,15.8C17.31,15.8 16.6,15.61 15.87,15.29C15.96,15.19 16,15.19 16.1,15.19C17.5,15.19 17.9,15.44 18,15.54M8.83,17C8.18,18.19 7.59,18.85 7.14,19C7.19,18.62 7.64,17.96 8.35,17.31L8.83,17M11.85,10.09C11.62,9.19 11.61,8.46 11.78,8.04L11.85,7.92L12,7.97C12.17,8.21 12.19,8.53 12.09,9.07L12.06,9.23L11.9,10.05L11.85,10.09Z"
                                          stroke="none"
                                      />
                                    </svg>
                                    <svg
                                        *ngIf="
                              file?.mimeType === 'image/png' ||
                              file?.mimeType === 'image/jpg' ||
                              file?.mimeType === 'image/jpeg'
                            "
                                        class="icon"
                                        viewBox="0 0 24 24"
                                    >
                                      <path
                                          d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M6,20H15L18,20V12L14,16L12,14L6,20M8,9A2,2 0 0,0 6,11A2,2 0 0,0 8,13A2,2 0 0,0 10,11A2,2 0 0,0 8,9Z"
                                          stroke="none"
                                      />
                                    </svg>
                                    {{ file?.fileName }}
                                  </div>
                                  <div class="ml-auto d-flex align-items-center pr-3">
                                    <div class="text-nowrap mr-2">
                                      <strong class="font-weight-medium"
                                      >{{ file?.size * 1e-6 | number }}MB</strong
                                      >
                                    </div>
                                    <div
                                        class="d-flex align-items-center justify-content-center"
                                    >
                                      <button
                                          (click)="delete(file)"
                                          class="btn btn-sm bg-danger border shadow p-1"
                                          container="body"
                                          placement="left"
                                          tooltip="Delete"
                                          type="button"
                                          tabindex="0"
                                          role="button"
                                          [attr.aria-label]="'Taxpayer.delete-file' | translate"
                                      >
                                        <svg class="icon icon-sm fill-white d-flex" viewBox="0 0 24 24">
                                          <path
                                              d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                              stroke="none"></path>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- 1st -->

                          <!-- 1st end -->
                        </div>
                      </div>
                    </div>
                    <!-- 1st column end -->
                  </div>
                  <div class="col-lg-6">
                    <!-- 2nd column -->
                    <div class="row">
                      <div
                          *ngIf="(individualReturn$ | async)?.taxForms?.federal?.required"
                          class="col-lg-12 mb-3"
                      >
                        <div class="card d-flex flex-column p-3 mb-1 pb-1 h-100">
                          <div class="flex-grow-1">
                            <h6 class="pt-1 w-100 font-weight-bold"
                            >{{'Taxpayer.first-and-second-page-of-your-federal-return' | translate}}
                               <span class="text-danger pl-1">*</span>
                            </h6>
                            <div class="upload-layout">
                              <div class="drop_box">

                                <header>
                                  <h3 class="mb-0">{{'Taxpayer.select-file-here' | translate}}</h3>
                                </header>

                                <div class="text-center button-upload mt-2">
                                  <button [disabled]="(isUploading$ | async)" 
                                       class="btn btn-primary font-weight-600 btn-upload"
                                       [attr.aria-label]="'Taxpayer.upload-file' | translate"
                                       [attr.aria-disabled]="(isUploading$ | async) ? 'true' : 'false'" 
                                       [attr.aria-busy]="(isUploading$ | async) === 'Federal' ? 'true' : 'false'"
                                       tabindex="0">
                                    <ui-spinner [class.active]="(isUploading$ | async)==='Federal'">
                                      {{'Taxpayer.upload-file' | translate  }}                                  </ui-spinner>
                                  </button>

                                  <input
                                      (change)="uploadDocument('Federal', $event)"
                                      accept=".pdf"
                                      class="py-2 px-2 file-upload w-100 border bg-white drop-file"
                                      name="Federal Return"
                                      type="file"
                                      [attr.aria-label]="'Taxpayer.choose-file-to-upload' | translate"
                                  />
                                </div>
                                <div>
                                 {{'Taxpayer.federal-return-match' | translate}}
                                </div>
                              </div>
                            </div>
                            <div
                                *ngFor="let file of (individualReturn$ | async)?.files"
                                class="row"
                            >
                              <div *ngIf="file.type === 'Federal'" class="col-lg-12 text-nowrap">
                                <div
                                    class="row no-gutters align-items-center border-top bg-primary-100 py-1"
                                >
                                  <div
                                      class="col-8 align-items-center px-3 py-2 d-flex"
                                  >
                                    <svg
                                        *ngIf="file?.mimeType === 'application/pdf'"
                                        class="icon"
                                        viewBox="0 0 24 24"
                                    >
                                      <path
                                          d="M14,9H19.5L14,3.5V9M7,2H15L21,8V20A2,2 0 0,1 19,22H7C5.89,22 5,21.1 5,20V4A2,2 0 0,1 7,2M11.93,12.44C12.34,13.34 12.86,14.08 13.46,14.59L13.87,14.91C13,15.07 11.8,15.35 10.53,15.84V15.84L10.42,15.88L10.92,14.84C11.37,13.97 11.7,13.18 11.93,12.44M18.41,16.25C18.59,16.07 18.68,15.84 18.69,15.59C18.72,15.39 18.67,15.2 18.57,15.04C18.28,14.57 17.53,14.35 16.29,14.35L15,14.42L14.13,13.84C13.5,13.32 12.93,12.41 12.53,11.28L12.57,11.14C12.9,9.81 13.21,8.2 12.55,7.54C12.39,7.38 12.17,7.3 11.94,7.3H11.7C11.33,7.3 11,7.69 10.91,8.07C10.54,9.4 10.76,10.13 11.13,11.34V11.35C10.88,12.23 10.56,13.25 10.05,14.28L9.09,16.08L8.2,16.57C7,17.32 6.43,18.16 6.32,18.69C6.28,18.88 6.3,19.05 6.37,19.23L6.4,19.28L6.88,19.59L7.32,19.7C8.13,19.7 9.05,18.75 10.29,16.63L10.47,16.56C11.5,16.23 12.78,16 14.5,15.81C15.53,16.32 16.74,16.55 17.5,16.55C17.94,16.55 18.24,16.44 18.41,16.25M18,15.54L18.09,15.65C18.08,15.75 18.05,15.76 18,15.78H17.96L17.77,15.8C17.31,15.8 16.6,15.61 15.87,15.29C15.96,15.19 16,15.19 16.1,15.19C17.5,15.19 17.9,15.44 18,15.54M8.83,17C8.18,18.19 7.59,18.85 7.14,19C7.19,18.62 7.64,17.96 8.35,17.31L8.83,17M11.85,10.09C11.62,9.19 11.61,8.46 11.78,8.04L11.85,7.92L12,7.97C12.17,8.21 12.19,8.53 12.09,9.07L12.06,9.23L11.9,10.05L11.85,10.09Z"
                                          stroke="none"
                                      />
                                    </svg>
                                    <svg
                                        *ngIf="
                                    file?.mimeType === 'image/png' ||
                                    file?.mimeType === 'image/jpg' ||
                                    file?.mimeType === 'image/jpeg'
                                  "
                                        class="icon"
                                        viewBox="0 0 24 24"
                                    >
                                      <path
                                          d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M6,20H15L18,20V12L14,16L12,14L6,20M8,9A2,2 0 0,0 6,11A2,2 0 0,0 8,13A2,2 0 0,0 10,11A2,2 0 0,0 8,9Z"
                                          stroke="none"
                                      />
                                    </svg>
                                    {{ file?.fileName }}
                                  </div>
                                  <div class="ml-auto d-flex align-items-center pr-3">
                                    <div class="text-nowrap mr-2">
                                      <strong class="font-weight-medium"
                                      >{{ file?.size * 1e-6 | number }}MB</strong
                                      >
                                    </div>
                                    <div
                                        class="d-flex align-items-center justify-content-center"
                                    >
                                      <button
                                          (click)="delete(file)"
                                          class="btn btn-sm bg-danger border shadow p-1"
                                          container="body"
                                          placement="left"
                                          tooltip="Delete"
                                          type="button"
                                          tabindex="0"
                                          role="button"
                                          [attr.aria-label]="'Taxpayer.delete-file' | translate"
                                      >
                                        <svg class="icon icon-sm fill-white d-flex" viewBox="0 0 24 24">
                                          <path
                                              d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                              stroke="none"></path>
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      <div class="col-lg-12 mb-3">
                        <div class="card d-flex flex-column p-3 mb-1 pb-1 h-100">
                          <div class="flex-grow-1">
                            <h6 class="pt-1 w-100 font-weight-bold">
                              {{'Taxpayer.copy-of-1st-page-of-other-city' | translate}}</h6
                            >
                            <div class="upload-layout">
                              <div class="drop_box">

                                <header>
                                  <h3 class="mb-0">{{'Taxpayer.select-file-here' | translate}}</h3>
                                </header>

                                <div class="text-center button-upload mt-2">
                                  <button [disabled]="(isUploading$ | async)" 
                                      class="btn btn-primary font-weight-600 btn-upload"
                                      [attr.aria-label]="'Taxpayer.upload-file' | translate"
                                      [attr.aria-disabled]="(isUploading$ | async) ? 'true' : 'false'" 
                                      [attr.aria-busy]="(isUploading$ | async) === 'City-Other' ? 'true' : 'false'"
                                       tabindex="0">
                                    <ui-spinner [class.active]="(isUploading$ | async)==='City-Other'">
                                      {{'Taxpayer.upload-file' | translate}}</ui-spinner>
                                  </button>

                                  <input
                                      (change)="uploadDocument('City-Other', $event)"
                                      accept=".pdf"
                                      class="py-2 px-2 file-upload w-100 border bg-white drop-file"
                                      name="filename"
                                      type="file"
                                      [attr.aria-label]="'Taxpayer.choose-file-to-upload' | translate"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                                *ngFor="let file of (individualReturn$ | async)?.files"
                                class="row m-0"
                            >
                              <div *ngIf="file.type === 'City-Other'" class="col-12 px-0">
                                <div
                                    class="row no-gutters align-items-center border-top bg-primary-100 py-1"
                                >
                                  <div class="col-8 align-items-center px-3 py-2 d-flex">
                                    <svg
                                        *ngIf="file?.mimeType === 'application/pdf'"
                                        class="icon"
                                        viewBox="0 0 24 24"
                                    >
                                      <path
                                          d="M14,9H19.5L14,3.5V9M7,2H15L21,8V20A2,2 0 0,1 19,22H7C5.89,22 5,21.1 5,20V4A2,2 0 0,1 7,2M11.93,12.44C12.34,13.34 12.86,14.08 13.46,14.59L13.87,14.91C13,15.07 11.8,15.35 10.53,15.84V15.84L10.42,15.88L10.92,14.84C11.37,13.97 11.7,13.18 11.93,12.44M18.41,16.25C18.59,16.07 18.68,15.84 18.69,15.59C18.72,15.39 18.67,15.2 18.57,15.04C18.28,14.57 17.53,14.35 16.29,14.35L15,14.42L14.13,13.84C13.5,13.32 12.93,12.41 12.53,11.28L12.57,11.14C12.9,9.81 13.21,8.2 12.55,7.54C12.39,7.38 12.17,7.3 11.94,7.3H11.7C11.33,7.3 11,7.69 10.91,8.07C10.54,9.4 10.76,10.13 11.13,11.34V11.35C10.88,12.23 10.56,13.25 10.05,14.28L9.09,16.08L8.2,16.57C7,17.32 6.43,18.16 6.32,18.69C6.28,18.88 6.3,19.05 6.37,19.23L6.4,19.28L6.88,19.59L7.32,19.7C8.13,19.7 9.05,18.75 10.29,16.63L10.47,16.56C11.5,16.23 12.78,16 14.5,15.81C15.53,16.32 16.74,16.55 17.5,16.55C17.94,16.55 18.24,16.44 18.41,16.25M18,15.54L18.09,15.65C18.08,15.75 18.05,15.76 18,15.78H17.96L17.77,15.8C17.31,15.8 16.6,15.61 15.87,15.29C15.96,15.19 16,15.19 16.1,15.19C17.5,15.19 17.9,15.44 18,15.54M8.83,17C8.18,18.19 7.59,18.85 7.14,19C7.19,18.62 7.64,17.96 8.35,17.31L8.83,17M11.85,10.09C11.62,9.19 11.61,8.46 11.78,8.04L11.85,7.92L12,7.97C12.17,8.21 12.19,8.53 12.09,9.07L12.06,9.23L11.9,10.05L11.85,10.09Z"
                                          stroke="none"
                                      />
                                    </svg>
                                    <svg
                                        *ngIf="
                          file?.mimeType === 'image/png' ||
                          file?.mimeType === 'image/jpg' ||
                          file?.mimeType === 'image/jpeg'
                        "
                                        class="icon"
                                        viewBox="0 0 24 24"
                                    >
                                      <path
                                          d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M6,20H15L18,20V12L14,16L12,14L6,20M8,9A2,2 0 0,0 6,11A2,2 0 0,0 8,13A2,2 0 0,0 10,11A2,2 0 0,0 8,9Z"
                                          stroke="none"
                                      />
                                    </svg>
                                    {{ file?.fileName }}
                                  </div>
                                  <div class="ml-auto d-flex align-items-center pr-3">
                                    <div class="text-nowrap mr-2">
                                      <strong class="font-weight-medium"
                                      >{{ file?.size * 1e-6 | number }}MB</strong
                                      >
                                    </div>
                                    <div
                                        class="d-flex align-items-center justify-content-center"
                                    >
                                      <button
                                          (click)="delete(file)"
                                          class="btn btn-sm bg-danger border shadow p-1"
                                          container="body"
                                          placement="left"
                                          tooltip="Delete"
                                          type="button"
                                          tabindex="0"
                                          role="button"
                                          [attr.aria-label]="'Taxpayer.delete-file' | translate"
                                      >
                                        <svg
                                            class="icon icon-sm fill-white d-flex"
                                            viewBox="0 0 24 24"
                                        >
                                          <path
                                              d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                              stroke="none"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-lg-12 mb-3">
                        <div class="card d-flex flex-column p-3 mb-1 pb-1 h-100">
                          <div class="flex-grow-1">
                            <h6 class="pt-1 w-100 font-weight-bold"
                            >{{'Taxpayer.any-other-correspondence-or-requested-information' | translate}}</h6
                            >
                            <div class="upload-layout">
                              <div class="drop_box">

                                <header>
                                  <h3 class="mb-0">{{'Taxpayer.select-file-here' | translate}}</h3>
                                </header>

                                <div class="text-center button-upload mt-2">
                                  <button [disabled]="(isUploading$ | async)" 
                                      class="btn btn-primary font-weight-600 btn-upload"
                                      [attr.aria-label]="'Taxpayer.upload-file' | translate"
                                      [attr.aria-disabled]="(isUploading$ | async) ? 'true' : 'false'" 
                                      [attr.aria-busy]="(isUploading$ | async) === 'Additional' ? 'true' : 'false'"
                                      tabindex="0">
                                    <ui-spinner [class.active]="(isUploading$ | async)==='Additional'">
                                      {{'Taxpayer.upload-file' | translate}}
                                    </ui-spinner>
                                  </button>
                                  <input
                                      (change)="uploadDocument('Additional', $event)"
                                      accept=".pdf"
                                      class="py-2 px-2 file-upload w-100 border bg-white drop-file"
                                      name="filename"
                                      type="file"
                                      [attr.aria-label]="'Taxpayer.choose-file-to-upload' | translate"
                                  />
                                </div>
                              </div>
                            </div>
                            <div
                                *ngFor="let file of (individualReturn$ | async)?.files"
                                class="row m-0"
                            >
                              <div *ngIf="file.type === 'Additional'" class="col-12 px-0">
                                <div
                                    class="row no-gutters align-items-center border-top bg-primary-100 py-1"
                                >
                                  <div class="col-8 align-items-center px-3 py-2 d-flex">
                                    <svg
                                        *ngIf="file?.mimeType === 'application/pdf'"
                                        class="icon"
                                        viewBox="0 0 24 24"
                                    >
                                      <path
                                          d="M14,9H19.5L14,3.5V9M7,2H15L21,8V20A2,2 0 0,1 19,22H7C5.89,22 5,21.1 5,20V4A2,2 0 0,1 7,2M11.93,12.44C12.34,13.34 12.86,14.08 13.46,14.59L13.87,14.91C13,15.07 11.8,15.35 10.53,15.84V15.84L10.42,15.88L10.92,14.84C11.37,13.97 11.7,13.18 11.93,12.44M18.41,16.25C18.59,16.07 18.68,15.84 18.69,15.59C18.72,15.39 18.67,15.2 18.57,15.04C18.28,14.57 17.53,14.35 16.29,14.35L15,14.42L14.13,13.84C13.5,13.32 12.93,12.41 12.53,11.28L12.57,11.14C12.9,9.81 13.21,8.2 12.55,7.54C12.39,7.38 12.17,7.3 11.94,7.3H11.7C11.33,7.3 11,7.69 10.91,8.07C10.54,9.4 10.76,10.13 11.13,11.34V11.35C10.88,12.23 10.56,13.25 10.05,14.28L9.09,16.08L8.2,16.57C7,17.32 6.43,18.16 6.32,18.69C6.28,18.88 6.3,19.05 6.37,19.23L6.4,19.28L6.88,19.59L7.32,19.7C8.13,19.7 9.05,18.75 10.29,16.63L10.47,16.56C11.5,16.23 12.78,16 14.5,15.81C15.53,16.32 16.74,16.55 17.5,16.55C17.94,16.55 18.24,16.44 18.41,16.25M18,15.54L18.09,15.65C18.08,15.75 18.05,15.76 18,15.78H17.96L17.77,15.8C17.31,15.8 16.6,15.61 15.87,15.29C15.96,15.19 16,15.19 16.1,15.19C17.5,15.19 17.9,15.44 18,15.54M8.83,17C8.18,18.19 7.59,18.85 7.14,19C7.19,18.62 7.64,17.96 8.35,17.31L8.83,17M11.85,10.09C11.62,9.19 11.61,8.46 11.78,8.04L11.85,7.92L12,7.97C12.17,8.21 12.19,8.53 12.09,9.07L12.06,9.23L11.9,10.05L11.85,10.09Z"
                                          stroke="none"
                                      />
                                    </svg>
                                    <svg
                                        *ngIf="
                          file?.mimeType === 'image/png' ||
                          file?.mimeType === 'image/jpg' ||
                          file?.mimeType === 'image/jpeg'
                        "
                                        class="icon"
                                        viewBox="0 0 24 24"
                                    >
                                      <path
                                          d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M6,20H15L18,20V12L14,16L12,14L6,20M8,9A2,2 0 0,0 6,11A2,2 0 0,0 8,13A2,2 0 0,0 10,11A2,2 0 0,0 8,9Z"
                                          stroke="none"
                                      />
                                    </svg>
                                    {{ file?.fileName }}
                                  </div>
                                  <div class="ml-auto d-flex align-items-center pr-3">
                                    <div class="text-nowrap mr-2">
                                      <strong class="font-weight-medium"
                                      >{{ file?.size * 1e-6 | number }}MB</strong
                                      >
                                    </div>
                                    <div
                                        class="d-flex align-items-center justify-content-center"
                                    >
                                      <button
                                          (click)="delete(file)"
                                          class="btn btn-sm bg-danger border shadow p-1"
                                          container="body"
                                          placement="left"
                                          tooltip="Delete"
                                          type="button"
                                          tabindex="0"
                                          role="button"
                                          [attr.aria-label]="'Taxpayer.delete-file' | translate"
                                      >
                                        <svg
                                            class="icon icon-sm fill-white d-flex"
                                            viewBox="0 0 24 24"
                                        >
                                          <path
                                              d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                              stroke="none"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    <!-- 2nd column end -->
                  </div>
                </div>


                <div *ngIf="
                      (individualReturn$ | async)?.taxForms?.city
                        ?.attachmentRequired
                    "
                     class="row m-0"
                >
                  <div class="col-lg-6 mb-3 pb-3" formGroupName="taxForms">
                    <div
                        *ngIf="(individualReturn$ | async)?.taxForms"
                        formGroupName="city"
                    >
                      <label class="form-label pt-1 w-100"  for="required-attachments">
                        {{'Taxpayer.required-attachments' | translate}}
                      </label>
                      <div class="bg-white pt-2 pb-1" id="required-attachments">
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment1?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment1"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment1"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 1'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment1"
                            >{{'Taxpayer.attachment' | translate}} 1</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment2_1?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment2_1"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment2_1"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 2-1'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment2_1"
                            >{{'Taxpayer.attachment' | translate}} 2-1</label
                            >
                          </div>
                        </div>
                        <div *ngIf="(individualReturn$ | async)?.taxForms?.city?.attachment2_2?.required"
                             class="form-check pl-2 mb-2">
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment2_2"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment2_2"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 2-2'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment2_2"
                            >{{'Taxpayer.attachment' | translate}} 2-2</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment2_3?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment2_3"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment2_3"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 2-3'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment2_3"
                            >{{'Taxpayer.attachment' | translate}} 2-3</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment2_4?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment2_4"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment2_4"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 2-4'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment2_4"
                            >{{'Taxpayer.attachment' | translate}} 2-4</label
                            >
                          </div>
                        </div>

                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment3?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment3"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment3"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 3'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment3"
                            >{{'Taxpayer.attachment' | translate}} 3</label
                            >
                          </div>
                        </div>

                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment4?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment4"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment4"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 4'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment4"
                            >{{'Taxpayer.attachment' | translate}} 4</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment5?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment5"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment5"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 5'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment5"
                            >{{'Taxpayer.attachment' | translate}} 5</label
                            >
                          </div>
                        </div>

                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment6?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment6"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment6"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 6'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment6"
                            >{{'Taxpayer.attachment' | translate}} 6</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment7?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment7"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment7"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 7'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment7"
                            >{{'Taxpayer.attachment' | translate}} 7</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment8?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment8"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment8"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 8'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment8"
                            >{{'Taxpayer.attachment' | translate}} 8</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment9?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment9"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment9"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 9'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment9"
                            >{{'Taxpayer.attachment' | translate}} 9</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment10?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment10"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment10"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 10'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment10"
                            >{{'Taxpayer.attachment' | translate}} 10</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment11?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment11"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment11"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 11'"
                                type="checkbox"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment11"
                            >{{'Taxpayer.attachment' | translate}} 11</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment12?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment12"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment12"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 12'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment12"
                            >{{'Taxpayer.attachment' | translate}} 12</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment13?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment13"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment13"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 13'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment13"
                            >{{'Taxpayer.attachment' | translate}} 13</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment14?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment14"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment14"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 14'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment14"
                            >{{'Taxpayer.attachment' | translate}} 14</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment15?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment15"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment15"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 15'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment15"
                            >{{'Taxpayer.attachment' | translate}} 15</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment16?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment16"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment16"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 16'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment16"
                            >{{'Taxpayer.attachment' | translate}} 16</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment17?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment17"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment17"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 17'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment17"
                            >{{'Taxpayer.attachment' | translate}} 17</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment18?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment2_4"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment18"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 18'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment18"
                            >{{'Taxpayer.attachment' | translate}} 18</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment19?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment19"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment19"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 19'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment19"
                            >{{'Taxpayer.attachment' | translate}} 19</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment20?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment20"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment20"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 20'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment20"
                            >{{'Taxpayer.attachment' | translate}} 20</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment21?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment21"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment21"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 21'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment21"
                            >{{'Taxpayer.attachment' | translate}} 21</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city
                                ?.attachment22?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="attachment22"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="attachment22"
                                type="checkbox"
                                [attr.aria-label]="('Taxpayer.attachment' | translate) + ' 22'"
                            />
                            <label
                                class="custom-control-label"
                                for="attachment22"
                            >{{'Taxpayer.attachment' | translate}} 22</label
                            >
                          </div>
                        </div>
                        <div
                            *ngIf="
                              (individualReturn$ | async)?.taxForms?.city?.cfCov
                                ?.required
                            "
                            class="form-check pl-2 mb-2"
                        >
                          <div
                              class="custom-control custom-checkbox"
                              formGroupName="cfCov"
                          >
                            <input
                                class="custom-control-input"
                                formControlName="provided"
                                id="cfCov"
                                type="checkbox"
                                [attr.aria-label]="'CF-COV'"
                            />
                            <label class="custom-control-label" for="cfCov"
                            >CF-COV</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                      *ngIf="(individualReturn$ | async)?.taxForms?.city"
                      class="col-lg-6 mb-3 pb-3"
                  >
                    <div class="row m-0">
                      <div class="col-auto">
                        <label for="schedules" class="form-label pt-1 w-100">{{'Taxpayer.schedules' | translate}}</label>
                        <input
                            id="schedules"
                            (change)="uploadDocument('Schedule', $event)"
                            accept=".pdf"
                            class="py-2 px-2 file-upload w-100 border bg-white"
                            name="Schedules"
                            type="file"
                            [attr.aria-label]="'Taxpayer.schedules' | translate"
                        />
                      </div>
                    </div>
                    <div
                        *ngFor="let file of (individualReturn$ | async)?.files"
                        class="row m-0"
                    >
                      <div *ngIf="file.type === 'Schedule'" class="col-12">
                        <div
                            class="row no-gutters align-items-center border-top bg-primary-100 py-1"
                        >
                          <div
                              class="col-8 align-items-center px-3 py-2 d-flex"
                          >
                            <svg
                                *ngIf="file?.mimeType === 'application/pdf'"
                                class="icon"
                                viewBox="0 0 24 24"
                            >
                              <path
                                  d="M14,9H19.5L14,3.5V9M7,2H15L21,8V20A2,2 0 0,1 19,22H7C5.89,22 5,21.1 5,20V4A2,2 0 0,1 7,2M11.93,12.44C12.34,13.34 12.86,14.08 13.46,14.59L13.87,14.91C13,15.07 11.8,15.35 10.53,15.84V15.84L10.42,15.88L10.92,14.84C11.37,13.97 11.7,13.18 11.93,12.44M18.41,16.25C18.59,16.07 18.68,15.84 18.69,15.59C18.72,15.39 18.67,15.2 18.57,15.04C18.28,14.57 17.53,14.35 16.29,14.35L15,14.42L14.13,13.84C13.5,13.32 12.93,12.41 12.53,11.28L12.57,11.14C12.9,9.81 13.21,8.2 12.55,7.54C12.39,7.38 12.17,7.3 11.94,7.3H11.7C11.33,7.3 11,7.69 10.91,8.07C10.54,9.4 10.76,10.13 11.13,11.34V11.35C10.88,12.23 10.56,13.25 10.05,14.28L9.09,16.08L8.2,16.57C7,17.32 6.43,18.16 6.32,18.69C6.28,18.88 6.3,19.05 6.37,19.23L6.4,19.28L6.88,19.59L7.32,19.7C8.13,19.7 9.05,18.75 10.29,16.63L10.47,16.56C11.5,16.23 12.78,16 14.5,15.81C15.53,16.32 16.74,16.55 17.5,16.55C17.94,16.55 18.24,16.44 18.41,16.25M18,15.54L18.09,15.65C18.08,15.75 18.05,15.76 18,15.78H17.96L17.77,15.8C17.31,15.8 16.6,15.61 15.87,15.29C15.96,15.19 16,15.19 16.1,15.19C17.5,15.19 17.9,15.44 18,15.54M8.83,17C8.18,18.19 7.59,18.85 7.14,19C7.19,18.62 7.64,17.96 8.35,17.31L8.83,17M11.85,10.09C11.62,9.19 11.61,8.46 11.78,8.04L11.85,7.92L12,7.97C12.17,8.21 12.19,8.53 12.09,9.07L12.06,9.23L11.9,10.05L11.85,10.09Z"
                                  stroke="none"
                              />
                            </svg>
                            <svg
                                *ngIf="
                                  file?.mimeType === 'image/png' ||
                                  file?.mimeType === 'image/jpg' ||
                                  file?.mimeType === 'image/jpeg'
                                "
                                class="icon"
                                viewBox="0 0 24 24"
                            >
                              <path
                                  d="M13,9H18.5L13,3.5V9M6,2H14L20,8V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V4C4,2.89 4.89,2 6,2M6,20H15L18,20V12L14,16L12,14L6,20M8,9A2,2 0 0,0 6,11A2,2 0 0,0 8,13A2,2 0 0,0 10,11A2,2 0 0,0 8,9Z"
                                  stroke="none"
                              />
                            </svg>
                            {{ file?.fileName }}
                          </div>
                          <div class="ml-auto d-flex align-items-center pr-3">
                            <div class="text-nowrap mr-2">
                              <strong class="font-weight-medium"
                              >{{ file?.size * 1e-6 | number }}MB</strong
                              >
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-center"
                            >
                              <button
                                  (click)="delete(file)"
                                  class="btn btn-sm bg-danger border shadow p-1"
                                  container="body"
                                  placement="left"
                                  tooltip="Delete"
                                  type="button"
                                  tabindex="0"
                                  role="button"
                                  [attr.aria-label]="'Taxpayer.delete-file' | translate"
                              >
                                <svg
                                    class="icon icon-sm fill-white d-flex"
                                    viewBox="0 0 24 24"
                                >
                                  <path
                                      d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                      stroke="none"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row border-top py-3">
                  <div class="col-12 d-flex justify-content-end">
                    <button (click)="back()"
                            [disabled]="isUploading$ | async"
                            class="btn btn-secondary ml-3"
                            type="button"
                            tabindex="0"
                            role="button"
                            [attr.aria-label]="'Buttons.back-btn' | translate" 
                            [attr.aria-disabled]="(isUploading$ | async) ? 'true' : 'false'">
                    <span>{{
                        "Buttons.back-btn" | translate
                      }}</span>
                    </button>
                   
                    <button (click)="next()"
                            [disabled]="(isUploading$ | async) && !(validCityForm$ | async)"
                            class="btn btn-primary font-weight-600 ml-3"
                            id="submit"
                            tabindex="0"
                            role="button"
                            [attr.aria-label]="'Buttons.next-btn' | translate"
                            [attr.aria-disabled]="(isUploading$ | async) ? 'true' : 'false'"
                            [attr.aria-busy]="(isUploading$ | async) ? 'true' : 'false'">
                      <ui-spinner [class.active]="isUploading$ | async">
                        <span>{{ "Buttons.next-btn" | translate }}</span>
                      </ui-spinner>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<ng-template #noPermission>
  <ui-forbidden></ui-forbidden>
</ng-template>
