import {IAddressBase, ILastModifiedBy} from "@city-tax/shared";
import * as moment from "moment";
import {IIndividualBase, IIndividualResponse} from "../interfaces/individual.interface";


export class IndividualModel implements IIndividualResponse {
  public id: string;
  public status: string;
  public individualId: string;
  public accountNumber: string;
  public name: string;
  public ssn: string;
  public email: string;
  public birthdate: string;
  public occupation: string;
  public emailNotifications: boolean;
  public dateOfDeath: string;
  public deceased: boolean;
  public older65: boolean;
  public blind: boolean;
  public regular: boolean;
  public deaf: boolean;
  public disabled: boolean;
  public address: IAddressBase;
  public spouse: IIndividualBase;
  public lastModifiedBy: ILastModifiedBy;
  public phone: string;
  public paymentMethods: Array<any>;
  public createdAt: Date;
  public updatedAt: Date;

  constructor(individual: IndividualModel) {
    if (individual) {
      this.id = individual.id;
      this.status = individual.status;
      this.individualId = individual.individualId;
      this.accountNumber = individual.accountNumber;
      this.name = individual.name;
      this.ssn = individual.ssn;
      this.email = individual.email;
      this.phone = individual.phone;
      this.birthdate = individual.birthdate ;
      this.occupation = individual.occupation;
      this.dateOfDeath = individual.dateOfDeath;
      this.deceased = individual.deceased;
      this.emailNotifications = individual.emailNotifications;
      this.older65 = individual.older65;
      this.blind = individual.blind;
      this.regular = individual.regular;
      this.deaf = individual.deaf;
      this.disabled = individual.disabled;
      this.address = individual.address;
      this.spouse = individual.spouse;
      this.lastModifiedBy = individual.lastModifiedBy;
      this.paymentMethods = individual.paymentMethods;
      this.createdAt = individual.createdAt;
      this.updatedAt = individual.updatedAt;
    }
  }
}
