import {AfterViewInit, Component, Injector, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {HexaDetailComponent} from "@hexalang/ui/core";
import {IndividualService} from "../../../../../../services/individual.service";
import {PaymentHelper} from "@city-tax/shared";
import {BehaviorSubject, Subscription} from "rxjs";
import {AuthService} from "@city-tax/features/auth";
import {PartYearHelper} from "../../../../../../helpers/oh/part-year.helper";


@Component({
  selector: "city-tax-feature-locality",
  templateUrl: "./locality.component.html",
  styleUrls: ["./locality.component.scss"],
})
export class LocalityComponent extends HexaDetailComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input("locality") locality: any;
  @Input("residency") residency: any;
  @Input("localityFormGroup") localityFormGroup: FormGroup;
  @Input("count") count: number;
  @Input("multipleLocations") multipleLocations: boolean;
  @Input("type") type: string;
  @Input("dateWorkedFrom") dateWorkedFrom: Date;
  @Input("dateWorkedTo") dateWorkedTo: Date;
  @Input("ownProperty") ownProperty: boolean;
  private readonly partYearHelper;
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private selectedValue = {
    taxWithheldLocalityName: null,
    localityTaxRate: null,
    wagesBox18: null,
    taxWithheldBox19: null,
    creditForTaxesPaid: null,
  };

  public config = this.authService.config;
  localitiesSubs!: Subscription;
  public localities = [];
  public isLoading$ = this.individualService.isLoading$;
  public city = this.authService.config;
  public individualReturn = this.individualService.individualReturn;

  constructor(
    injector: Injector,
    private individualService: IndividualService,
    private authService: AuthService,
    private paymentHelper: PaymentHelper
  ) {
    super(injector);
    this.partYearHelper = new PartYearHelper(paymentHelper, this.individualReturn.taxYear);
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes.dateWorkedFrom || changes.dateWorkedTo) {
      this.calculateCredit();
    }
  }

  async ngOnInit() {

  }

  async ngAfterViewInit() {
    await this.getLocalities();
    if (this.individualReturn.resident == true && !this.multipleLocations && this.localityFormGroup.get("taxWithheldLocalityName")?.value === this.city.name && this.count > 1) {
      this.localityFormGroup.get("wagesBox18").disable();
    }
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      this.isDisabled$.next(true)
    }
  }

  ngOnDestroy(): void {
    this.localitiesSubs?.unsubscribe();
  }

  async onSearchDynamic(value: any) {
    try {
      const term =  value.term.replace(/[^A-Za-zÀ-ÿ\s'-]/g, '');
      if (term === "") {
        this.localities = [];
      } else {
        const res = await this.individualService.getLocalitySearch('OH',term)
        if (res) this.localities = res.data;
      }
    } catch (error) {

    }
  }

  onClearDynamic(): void {
    this.calculateCredit();
  }

  setValues(value: any): void {
    if (value) {
      const taxWithheldLocalityNameCtl = this.localityFormGroup.get("taxWithheldLocalityName");
      taxWithheldLocalityNameCtl.setValue(value.locality);

      const localityTaxRateCtl = this.localityFormGroup.get("localityTaxRate");
      localityTaxRateCtl.setValue(value.rate);

      this.selectedValue.taxWithheldLocalityName = value.locality;
      this.selectedValue.localityTaxRate = value.rate;

      if (this.individualReturn.resident === true && !this.multipleLocations && value.locality == this.city.name && this.count > 1) {
        this.localityFormGroup.get("wagesBox18").disable();
      } else {
        this.localityFormGroup.get("wagesBox18").enable();
      }
    } else {
      this.selectedValue.taxWithheldLocalityName = null;
      this.selectedValue.localityTaxRate = null;
    }
    this.calculateCredit();
  }

  async getLocalities() {
    try {
      if (!this.localities?.length) {
        const res = await this.individualService.getLocalities('OH')
        if (res)
          this.localities = res.data;
      }
    } catch (error) {
    }
  }

  calculateCredit() {
    let creditForTaxesPaid = 0.0;
    const creditForTaxesPaidCtl = this.localityFormGroup.get("creditForTaxesPaid");
    const taxWithheldLocalityNameCtl = this.localityFormGroup.get("taxWithheldLocalityName");

    if (taxWithheldLocalityNameCtl.value !== this.config?.name && taxWithheldLocalityNameCtl.value) {

      const filingCityTaxRate = this.config?.components?.individual?.taxRate;
      const filingCityCreditRate = this.config?.components?.individual?.creditRate;
      const localityTaxRateCtl = this.localityFormGroup.get("localityTaxRate");

      let creditRate = filingCityCreditRate === 0 ? 0 : filingCityCreditRate || filingCityTaxRate;
      const taxRate = this.selectedValue.localityTaxRate || +localityTaxRateCtl.value;

      console.log(this.ownProperty, {filingCityTaxRate, filingCityCreditRate, creditRate, taxRate});

      if (this.city.name === 'Mason' && !this.ownProperty) {
        creditRate = filingCityTaxRate;
      } else if (this.city.name === 'Mason' && this.ownProperty && this.residency !== 'N') {

      } else {
        creditRate = creditRate > taxRate ? taxRate : creditRate;
      }

      // console.log(this.city.name, 'creditRate', creditRate);
      if (creditRate) {
        const wagesBox18Ctl = this.localityFormGroup.get("wagesBox18");
        const taxWithheldBox19Ctl = this.localityFormGroup.get("taxWithheldBox19");
        const wagesBox18C = wagesBox18Ctl?.value;
        const taxWithheldBox19C = taxWithheldBox19Ctl?.value;

        if (wagesBox18C && taxWithheldBox19C) {

          const taxWithheldCalculated = this.paymentHelper.decimalValue(+wagesBox18C * taxRate);
          const totalCredit = this.paymentHelper.decimalValue(+wagesBox18C * creditRate);
          const creditForTaxes = this.paymentHelper.decimalValue(+taxWithheldBox19C - +totalCredit);

          if (+taxWithheldBox19C < +taxWithheldCalculated) {
            const creditForTaxesPaidCalculated = this.paymentHelper.decimalValue(+taxWithheldBox19C / taxRate);
            creditForTaxesPaid = this.paymentHelper.decimalValue(creditForTaxesPaidCalculated * creditRate);
          } else {
            creditForTaxesPaid = creditForTaxes < 0 ? +taxWithheldBox19C : totalCredit;
          }

          if (creditForTaxesPaid > +taxWithheldBox19C) {
            creditForTaxesPaid = +taxWithheldBox19C;
          }

          // console.log('calculate---', wagesBox18C, taxWithheldBox19C, taxWithheldCalculated, creditRate, totalCredit, creditForTaxes, creditForTaxesPaid);


          if (this.isPartYear()) {

            const daysWorkedAsNonResident = this.type === 'T' ? this.getTaxpayerDaysNonResident(this.dateWorkedFrom, this.dateWorkedTo) : this.getSpouseDaysNonResident(this.dateWorkedFrom, this.dateWorkedTo);
            const daysWorked = this.partYearHelper.daysWorked(this.dateWorkedFrom, this.dateWorkedTo);
            const daysWorkedAsResident = daysWorked - daysWorkedAsNonResident;
            // console.log('daysWorkedAsNonResident', daysWorkedAsNonResident, 'daysWorked', daysWorked);

            if (daysWorkedAsResident === 0) {

              creditForTaxesPaid = 0;

            } else {


              const taxWithheldCalculated = this.paymentHelper.decimalValue(+wagesBox18C * taxRate / (daysWorked - daysWorkedAsNonResident));
              const creditForTaxesPaidPerDay = this.paymentHelper.decimalValue(creditForTaxesPaid / daysWorked, 4);
              const creditForTaxes = this.paymentHelper.decimalValue(creditForTaxesPaidPerDay * (daysWorked - daysWorkedAsNonResident));
              const creditForTaxesPaidCalculated = this.paymentHelper.decimalValue(+taxWithheldBox19C / taxRate);
              const creditCalculated = this.paymentHelper.decimalValue(creditForTaxesPaidCalculated * creditRate);

              // console.log('daysWorkedAsNonResident', taxWithheldBox19C, taxWithheldCalculated, creditForTaxesPaidCalculated,creditCalculated,creditForTaxes  );

              if ((daysWorked - daysWorkedAsNonResident) === 0) {
                creditForTaxesPaid = 0;
              } else {
                // console.log('step1',+taxWithheldBox19C < +taxWithheldCalculated);

                if (+taxWithheldBox19C < +taxWithheldCalculated) {
                  const creditForTaxesPaidCalculated = this.paymentHelper.decimalValue(+taxWithheldBox19C / taxRate);
                  creditForTaxesPaid = this.paymentHelper.decimalValue(creditForTaxesPaidCalculated * creditRate);
                } else {
                  creditForTaxesPaid = creditForTaxes < creditCalculated ? +creditForTaxes : creditCalculated;
                }
                if (creditForTaxesPaid > +taxWithheldBox19C) {
                  creditForTaxesPaid = +taxWithheldBox19C;
                }
              }
            }
          }


        }
      }
    }
    creditForTaxesPaidCtl.setValue(`${creditForTaxesPaid}`);
  }

  isPartYear() {
    return this.individualReturn?.residency === 'P';
  }

  getTaxpayerDaysNonResident(dateWorkedFrom, dateWorkedTo) {
    if (!this.isPartYear()) return 365;
    return this.partYearHelper.daysWorkedAsNonResident(this.individualReturn?.partYearFromDate, this.individualReturn?.partYearToDate, dateWorkedFrom, dateWorkedTo);
  }

  getSpouseDaysNonResident(dateWorkedFrom, dateWorkedTo) {
    if (!this.isPartYear()) return 365;
    return this.partYearHelper.daysWorkedAsNonResident(this.individualReturn?.partYearFromDateSpouse, this.individualReturn?.partYearToDateSpouse, dateWorkedFrom, dateWorkedTo);
  }
}
