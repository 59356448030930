<div [formGroup]="localityFormGroup" class="row">

  <div class="col-4">
    <div class="form-group">
      <input class="w-100 form-control text-right"
             formControlName="wagesBox18"
             id="wagesBox18"
             name="wagesBox18"
             type="text"
             ui-number>
    </div>
  </div>
  <div class="col-4">
    <div class="form-group">
      <input class="w-100 form-control text-right"
             formControlName="taxWithheldBox19"
             id="taxWithheldBox19"
             name="taxWithheldBox19"
             type="text"
             ui-number
      >
    </div>
  </div>
  <div class="col-4">
    <input class="w-100 form-control"
           formControlName="taxWithheldLocalityName"
           id="taxWithheldLocalityName"
           name="taxWithheldLocalityName"
           type="text"
    >
  </div>
<!--  <div class="col-3">-->
<!--    <div class="form-group">-->
<!--      <input class="w-100 form-control text-right"-->
<!--             formControlName="creditForTaxesPaid"-->
<!--             id="creditForTaxesPaid"-->
<!--             name="creditForTaxesPaid"-->
<!--             type="text"-->
<!--             ui-number-->
<!--      >-->
<!--    </div>-->
<!--  </div>-->
</div>

