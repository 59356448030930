import {Injectable} from "@angular/core";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class PaymentHelper {

  constructor() {
  }

  sum(array: any, field: any = null, fractionDigits = 2) {
    if (!array || !array.length) return 0;
    let total = 0;
    const values = !field ? array.map(d => +d) : array.map(d => +(d[field] || 0));
    if (values.length === 0) return 0;
    for (const value of values) {
      total += +value;
    }
    return +total.toFixed(fractionDigits);
  }

  decimalValue(value: any, fractionDigits = 2) {
    if (!value) return 0;
    return +(+value).toFixed(fractionDigits);
  }

  getStartDate(date, includeDay, taxYear) {
    const include = includeDay ? 1 : 0;
    if (!date) return moment.utc(taxYear, 'YYYY').subtract(include, 'day').endOf('year');
    //console.log('getStartDate', date, includeDay, taxYear)

    return date ? this.getDate(date).subtract(include, 'day').endOf('day') : moment.utc(taxYear, 'YYYY').subtract(include, 'day').endOf('year');
  }

  getDate(date: any) {
    if (!date) return;
    const format = date.toString().includes('/') ? 'MM/DD/YYYYY' : 'YYYY-MM-DD';
    return moment.utc(date, format);
  }

  getEndDate(date, includeDay, taxYear) {
    const include = includeDay ? 1 : 0;
    if (!date) return moment.utc(taxYear + 1, 'YYYY').add(include, 'day').startOf('year');
    const format = date.toString().includes('/') ? 'MM/DD/YYYYY' : 'YYYY-MM-DD';
    return moment.utc(date, format).add(include, 'day').startOf('day');
  }
}
