<form (ngSubmit)="search()"
      [formGroup]="form"
      autocomplete="off"
      class="d-flex flex-column flex-fill"
      novalidate
>
  <div class="modal-header align-items-center">
    <h4 class="modal-title pull-left">{{'Taxpayer.estimates-tax-and-credit-lookup' | translate}}</h4>
    <a (click)="bsModalRef.hide()" class="text-muted" href="javascript:">
      <svg class="icon" viewBox="0 0 24 24">
        <path
            d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
            stroke="none"
        />
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="card card-body mb-12 p-4">
          <div class="pb-3">
            <div class="row" *ngIf="city || (organization$ | async)?.cityGroup">
              <div class="col-12 align-items-center">
                <div class="form-group w-100">
                  <label for="city-select" class="font-weight-medium text-muted text-uppercase">{{'Taxpayer.city' | translate}}</label>
                  <ng-select
                      id="city-select"
                      placeholder="City"
                      formControlName="organization"
                      (change)="selectCity()"
                      [items]="(organizations$ | async)?.data"
                      [groupBy]="'state'"
                      [bindLabel]="'name'"
                      [searchable]="true"
                      [multiple]="false"
                      [clearable]="true"
                      [loading]="isLoading$ | async"
                      [attr.aria-label]="'City'"
                  ></ng-select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 align-items-center">
                <div class="form-group w-100">
                  <label for="lastName" class="font-weight-medium text-muted text-uppercase">{{'Taxpayer.last-name' | translate}}</label>
                  <input autocomplete="off" id="lastName" class="form-control" formControlName="lastName" type="text"
                         name="noAutoLastName" [attr.aria-label]="'Taxpayer.last-name' | translate"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 align-items-center">
                <div class="form-group w-100">
                  <label for="accountNumber" class="font-weight-medium text-muted text-uppercase">{{'Taxpayer.account-number' | translate}}
                  </label>
                  <ng-template
                    #accountNumber
                    [ngTemplateOutletContext]="{ $implicit: false }"
                    [ngTemplateOutlet]="accountNumber"
                  >
                    <div class="form-control-wrapper form-control-wrapper-right">
                      <input
                          [type]="isAccountNumberVisible ? 'text' : 'password'"
                          autocomplete="off"
                          name="noAutoAccountNumber"
                          class="form-control"
                          id="accountNumber"
                          formControlName="accountNumber"
                          id="accountNumber"
                          [attr.aria-label]="'Taxpayer.account-number' | translate"
                      />
                      <a
                          (click)="isAccountNumberVisible = !isAccountNumberVisible"
                          class="form-control-action"
                          href="javascript:"
                      >
                        <svg class="icon fill-gray-400" viewBox="0 0 24 24">
                          <path
                              *ngIf="!isAccountNumberVisible"
                              d="M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9M12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17M12,4.5C7,4.5 2.73,7.61 1,12C2.73,16.39 7,19.5 12,19.5C17,19.5 21.27,16.39 23,12C21.27,7.61 17,4.5 12,4.5Z"
                              stroke="none"
                          />
                          <path
                              *ngIf="isAccountNumberVisible"
                              d="M11.83,9L15,12.16C15,12.11 15,12.05 15,12A3,3 0 0,0 12,9C11.94,9 11.89,9 11.83,9M7.53,9.8L9.08,11.35C9.03,11.56 9,11.77 9,12A3,3 0 0,0 12,15C12.22,15 12.44,14.97 12.65,14.92L14.2,16.47C13.53,16.8 12.79,17 12,17A5,5 0 0,1 7,12C7,11.21 7.2,10.47 7.53,9.8M2,4.27L4.28,6.55L4.73,7C3.08,8.3 1.78,10 1,12C2.73,16.39 7,19.5 12,19.5C13.55,19.5 15.03,19.2 16.38,18.66L16.81,19.08L19.73,22L21,20.73L3.27,3M12,7A5,5 0 0,1 17,12C17,12.64 16.87,13.26 16.64,13.82L19.57,16.75C21.07,15.5 22.27,13.86 23,12C21.27,7.61 17,4.5 12,4.5C10.6,4.5 9.26,4.75 8,5.2L10.17,7.35C10.74,7.13 11.35,7 12,7Z"
                              stroke="none"
                          />
                        </svg>
                      </a>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="(estimates$|async)" class="row d-flex inline-block">
            <div class="col-12 font-weight-medium">{{'Taxpayer.our-records-show-the-following' | translate}}:</div>
          </div>
          <div *ngIf="(estimates$|async) && state === 'OH'" class="border-top border-bottom py-3">
            <div class="row d-flex inline-block">
              <div class="col-6 font-weight-medium text-uppercase">{{taxYear}} {{'Taxpayer.estimates-paid' | translate}}:</div>
              <div class="col-6">{{(estimates$|async)?.estimatedTaxPaid | currency}}</div>
            </div>
            <div class="row pt-2">
              <div class="col-6 font-weight-medium text-uppercase">{{priorYear}} <span class="text-capitalize"></span>{{'Taxpayer.overpayment-title' | translate}}:</div>
              <div class="col-6">{{(estimates$|async)?.priorYearOverpayment | currency}}</div>
            </div>
          </div>
          <div *ngIf="(estimates$|async) && state === 'MI'" class="border-top border-bottom py-3">
            <div class="row d-flex inline-block">
              <div class="col-6 font-weight-medium text-uppercase">{{taxYear}} {{'Taxpayer.paid-on-prior-year' | translate}}:</div>
              <div class="col-6">{{(estimates$|async)?.paymentsOnPriorYearDeclarationTax | currency}}</div>
            </div>
          </div>
          <div class="row pt-2" *ngIf="(estimates$|async)?.updatedAt">
            <div class="col-6 font-weight-medium text-uppercase">{{'Taxpayer.asof' | translate}}:</div>
            <div class="col-6">{{(estimates$|async)?.updatedAt | utcDate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <a (click)="bsModalRef.hide()" 
     class="btn btn-link" 
     href="javascript:"
     role="button" 
     aria-label="Close the modal" 
     tabindex="0">{{'Taxpayer.close' | translate}}</a>
    <button [disabled]="(isLoading$ | async)" 
      class="btn btn-primary" 
      type="submit"
      [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
      aria-label="Search"
      tabindex="0"
      role="button">
      <ui-spinner [class.active]="(isLoading$ | async)">{{'Taxpayer.search' | translate}}</ui-spinner>
    </button>
  </div>
</form>
