<div *ngIf="(individualReturn$ | async)?.status === 'Completed'"
     class="card p-3 position-absolute return-filed m-0 text-center">
    {{'Taxpayer.filed' | translate}}
</div>
<div *ngIf="(individualReturn$ | async)?.status === 'Mailed'"
     class="card p-3 position-absolute return-filed m-0 text-center">
    {{'Taxpayer.mailed' | translate}}
</div>
<div class="p-4">
  <div class="card border-0 shadow-none taxpayer-form">
    <div class="row">
      <div class="col-md-12">
        <div class="taxpayer-form">
          <form *ngIf="individualReturn$ | async as individualReturn" [formGroup]="form" novalidate>
            <table class="border table w-100 bg-white">
              <tr>
                <td class="border-right border-dark col-5">
                  <div class="">
                    <p class="m-0">{{'Taxpayer.tax-payers-name' | translate}}</p>
                    <div class="font-weight-bold"> {{ individualReturn?.taxpayer?.name }}</div>
                  </div>
                </td>
                <td class="border-right border-dark col-2">
                  <div class="">
                    <p class="m-0">{{'Taxpayer.tax-payers-ssn' | translate}}</p>
                    <div class="font-weight-bold"> XXX-XX-{{ individualReturn?.taxpayer?.ssn.substring(5, 9) }}</div>

                  </div>
                </td>
                <td class="border-right border-dark col-5">
                  <div class="row">
                    <div class="col-10 d-flex align-items-center justify-content-center">
                      <h5 class="mb-0 mr-3">{{ taxYear }} {{ city }}</h5>
                    </div>
                    <div class="col-2 d-flex justify-content-end">
                      {{'Taxpayer.how-to-use-this-site' | translate}} <a class="pl-2" href="https://cdn.insourcetax.com/Michigan Fill In Instructions.pdf" tooltip="Help" target="_help">
                        <svg width="20" height="20" fill="currentColor" class="text-primary" viewBox="0 0 16 16">
                          <path
                              d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 0 1 0-2.098zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z"/>
                          <path
                              d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <b>{{'Taxpayer.schedule-c-part-year-resident' | translate}}</b>
                </td>
                <td class="nowrap d-flex justify-content-end">
                  <b>{{'Taxpayer.attachment' | translate}} 1</b>
                </td>
              </tr>
            </table>
            <div class="text-dark font-weight-600">
              {{'Taxpayer.part-year-resident-is-required' | translate}}:
              <ol class="mx-2 px-1">
                <li>
                  {{'Taxpayer.box-a-report-dates' | translate}}
                </li>

                <li>
                  {{'Taxpayer.box-b-to-report' | translate}}
                </li>
                <li>
                  {{'Taxpayer.column-a-to-repott-all-income' | translate}}
                </li>
                <li>
                  {{'Taxpayer.column-b-to-report-all-income' | translate}}
                </li>
                <li>
                  {{'Taxpayer.column-c-to-report-income' | translate}}
                </li>
                <li>
                  {{'Taxpayer.column-d-to-report-income' | translate}}
                </li>
              </ol>
            </div>
            <table class="border table w-100">
              <tr>
                <td>
                  <table class="w-100" formGroupName="irs1040ScheduleTC">
                    <tr>
                      <td class="border-right border-top-0 col-3">
                        A. {{'taxpayer.part-year-residency-period' | translate}}
                      </td>
                      <td class="border-right border-top-0 col-2">From</td>
                      <td class="border-right border-top-0 col-2">To</td>
                      <td class="col-5">
                        B. {{'Taxpayer.part-year-residents-former-address' | translate}}
                      </td>
                    </tr>
                    <tr>
                      <td class="border-right col-3">{{'Taxpayer.taxpayer' | translate}}</td>
                      <td class="border-right col-2">
                        <input
                          id="taxpayerFrom"
                          [class.is-invalid]="form.get('irs1040ScheduleTC.taxpayerFrom')?.invalid && (form.get('irs1040ScheduleTC.taxpayerFrom')?.dirty || form.get('irs1040ScheduleTC.taxpayerFrom')?.touched)"
                          [minDate]="minDate"
                          [maxDate]="maxDate"
                          class="form-control"
                          formControlName="taxpayerFrom"
                          type="text"
                          placeholder="mm/dd/yyyy"
                          [attr.aria-label]="'Taxpayer.taxpayer-from' | translate"
                          [attr.aria-invalid]="form.get('irs1040ScheduleTC.taxpayerFrom')?.invalid && (form.get('irs1040ScheduleTC.taxpayerFrom')?.dirty || form.get('irs1040ScheduleTC.taxpayerFrom')?.touched) ? 'true' : 'false'"
                          [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                          style="width: 152px"
                          bsDatepicker
                        />
                      </td>
                      <td class="border-right col-2">
                        <input
                            id="taxpayerTo"
                            [class.is-invalid]="form.get('irs1040ScheduleTC.taxpayerTo')?.invalid && (form.get('irs1040ScheduleTC.taxpayerTo')?.dirty || form.get('irs1040ScheduleTC.taxpayerTo')?.touched)"
                            [minDate]="minDate"
                            [maxDate]="maxDate"
                            class="form-control"
                            formControlName="taxpayerTo"
                            type="text"
                            [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                            [attr.aria-label]="'Taxpayer.taxpayer-to' | translate"
                            [attr.aria-invalid]="form.get('irs1040ScheduleTC.taxpayerTo')?.invalid && (form.get('irs1040ScheduleTC.taxpayerTo')?.dirty || form.get('irs1040ScheduleTC.taxpayerTo')?.touched) ? 'true' : 'false'"
                            placeholder="mm/dd/yyyy"
                            style="width: 152px"
                            bsDatepicker
                        />
                      </td>
                      <td class="col-5">
                        <div class="d-flex align-items-center">
                          {{'Taxpayer.taxpayer' | translate}}
                          <input
                              class="ml-3 bg-light form-control"
                              formControlName="taxpayerAddress"
                              type="text"
                          />
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="individualReturn?.filing !=='S'">
                      <td class="border-right col-3">{{'Taxpayer.spouse' | translate }}</td>
                      <td class="border-right col-2">
                        <input
                            id="spouseFrom"
                            [class.is-invalid]="form.get('irs1040ScheduleTC.spouseFrom')?.invalid && (form.get('irs1040ScheduleTC.spouseFrom')?.dirty || form.get('irs1040ScheduleTC.spouseFrom')?.touched)"
                            [minDate]="minDate"
                            [maxDate]="maxDate"
                            class="form-control"
                            formControlName="spouseFrom"
                            type="text"
                            [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                            [attr.aria-label]="'Taxpayer.spouse-from' | translate"
                            [attr.aria-invalid]="form.get('irs1040ScheduleTC.spouseFrom')?.invalid && (form.get('irs1040ScheduleTC.spouseFrom')?.dirty || form.get('irs1040ScheduleTC.spouseFrom')?.touched) ? 'true' : 'false'"
                            bsDatepicker
                            placeholder="mm/dd/yyyy"
                            style="width: 152px"
                        />
                      </td>
                      <td class="border-right col-2">
                        <input
                          id="spouseTo"
                          [class.is-invalid]="form.get('irs1040ScheduleTC.spouseTo')?.invalid && (form.get('irs1040ScheduleTC.spouseTo')?.dirty || form.get('irs1040ScheduleTC.spouseTo')?.touched)"
                          [minDate]="minDate"
                          [maxDate]="maxDate"
                          class="form-control"
                          formControlName="spouseTo"
                          type="text"
                          placeholder="mm/dd/yyyy"
                          [bsConfig]="{ containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                          [attr.aria-label]="'Taxpayer.spouse-to' | translate"
                          [attr.aria-invalid]="form.get('irs1040ScheduleTC.spouseTo')?.invalid && (form.get('irs1040ScheduleTC.spouseTo')?.dirty || form.get('irs1040ScheduleTC.spouseTo')?.touched) ? 'true' : 'false'"
                          bsDatepicker
                          style="width: 152px"
                        />
                      </td>
                      <td class="col-5">
                        <div class="d-flex align-items-center">
                          {{'Taxpayer.spouse' | translate}}
                          <input
                              class="ml-3 bg-light form-control"
                              formControlName="spouseAddress"
                              type="text"
                          />
                        </div>
                      </td>
                    </tr>
                  </table>
                  <table class="w-100" formGroupName="irs1040ScheduleTC">
                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex justify-content-between align-items-center">
                          <h5><b class="text-uppercase">{{'Taxpayer.income' | translate}}</b></h5>
                          <span class="text-danger font-weight-600" *ngIf="form.get('irs1040ScheduleTC').errors">
                              {{'Taxpayer.column-c-d-must-equal-the-sum-a-b' | translate}}
                            </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2 py-0">
                        <span [ngClass]="form.get('irs1040ScheduleTC').errors?'text-danger font-weight-600':''"> {{'Taxpayer.column' | translate}} A </span><br/>
                        {{'Taxpayer.federal-return-data' | translate }}
                      </td>
                      <td class="text-center border-right col-2 py-0">
                        <span [ngClass]="form.get('irs1040ScheduleTC').errors?'text-danger font-weight-600':''">  {{'Taxpayer.column' | translate}} B</span> <br/>
                        {{'Taxpayer.exclusions-adjustments' | translate}}

                      </td>
                      <td class="text-center border-right col-2 py-0">
                        <span [ngClass]="form.get('irs1040ScheduleTC').errors?'text-danger font-weight-600':''"> {{'Taxpayer.column' | translate}} C</span><br/>
                        {{'taxpayer.taxable-resident-income' | translate}}

                      </td>
                      <td class="text-center col-2 py-0">
                        <span [ngClass]="form.get('irs1040ScheduleTC').errors?'text-danger font-weight-600':''">  {{'Taxpayer.column' | translate}} D </span><br/>
                        {{'Taxpayer.taxable' | translate}}<br/>
                        {{'Taxpayer.non-resident-income' | translate}}
                      </td>
                    </tr>
                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 1. </span>
                          <span class="mx-1">
                            {{'Taxpayer.wages-salaries-tips-etc' | translate}}
                          </span>
                          <span class="ml-auto"> 1 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector
                            (change)="calculate()"
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            controlClass="form-control bg-light text-right"
                            controlName="totalWagesA"
                            [customClass]="form.get('irs1040ScheduleTC').errors?.totalWagesError ? 'is-invalid' : ''"
                            groupName="irs1040ScheduleTC"
                            isNumeric="true"
                        >
                        </ui-input-selector>


                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector
                            (change)="calculate()"
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            controlClass="form-control bg-light text-right"
                            controlName="totalWagesB"
                            [customClass]="form.get('irs1040ScheduleTC').errors?.totalWagesError ? 'is-invalid' : ''"
                            groupName="irs1040ScheduleTC"
                            isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           controlClass="form-control bg-light text-right"
                                           controlName="totalWagesC"
                                           groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.totalWagesError ? 'is-invalid' : ''"

                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           controlClass="form-control bg-light text-right"
                                           controlName="totalWagesD"
                                           groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.totalWagesError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 2. </span>
                          <span class="mx-1">{{'Taxpayer.taxable-interest' | translate}}</span>
                          <span class="ml-auto"> 2 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()"
                                           [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right"
                                           controlName="taxableInterestA"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="taxableInterestB" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="taxableInterestC" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">{{'Taxpayer.not-taxable' | translate}}</td>
                    </tr>
                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 3. </span>
                          <span class="mx-1">{{'Taxpayer.ordinary-dividends' | translate}}</span>
                          <span class="ml-auto"> 3 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="ordinaryDividendsA" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="ordinaryDividendsB" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector
                            (change)="calculate()"
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            allowNegativeNumbers="true"
                            controlClass="form-control bg-light text-right"
                            controlName="ordinaryDividendsC"

                            groupName="irs1040ScheduleTC"
                            isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">{{'Taxpayer.not-taxable' | translate}}</td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 4. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.taxable-refunds-crediteds-or-offsets' | translate}}</span
                          >
                          <span class="ml-auto"> 4 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="taxableRefundsA" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="taxableRefundsB" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        {{'Taxpayer.not-applicable' | translate}}
                      </td>
                      <td class="text-center col-2">{{'Taxpayer.not-taxable' | translate}}</td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 5. </span>
                          <span class="mx-1">{{'Taxpayer.alimony-received' | translate}}</span>
                          <span class="ml-auto"> 5 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()"
                                           [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.alimonyReceivedError ? 'is-invalid' : ''"

                                           controlClass="form-control bg-light text-right"
                                           controlName="alimonyReceivedA" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.alimonyReceivedError ? 'is-invalid' : ''"
                                           controlClass="form-control bg-light text-right" controlName="alimonyReceivedB" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.alimonyReceivedError ? 'is-invalid' : ''"
                                           controlClass="form-control bg-light text-right" controlName="alimonyReceivedC" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.alimonyReceivedError ? 'is-invalid' : ''"
                                           controlClass="form-control bg-light text-right" controlName="alimonyReceivedD" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 6. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.business-income-or-loss' | translate}}</span
                          >
                          <span class="ml-auto"> 6 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="businessIncomeA" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.businessIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="businessIncomeB" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.businessIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="businessIncomeC" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.businessIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="businessIncomeD" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.businessIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex align-items-center">
                          <span> 7. </span>
                          <div class="mx-1">
                            <div class="d-flex">
                              <div class="col-5 px-0">
                                {{'Taxpayer.capital-gain-or-loss' | translate}}
                              </div>

                              <div class="col-5 d-flex align-items-start">
                                {{'Taxpayer.mark-if-sch-d-not-required' | translate}}
                              </div>
                            </div>
                          </div>
                          <span class="ml-auto"> 7b </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="capitalGainA" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.capitalGainError ? 'is-invalid' : ''"

                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="capitalGainB" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.capitalGainError ? 'is-invalid' : ''"

                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="capitalGainC" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.capitalGainError ? 'is-invalid' : ''"

                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="capitalGainD" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.capitalGainError ? 'is-invalid' : ''"

                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 8. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.other-gains-or-losses' | translate}}</span>
                          <span class="ml-auto"> 8 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="otherGainLossA" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.otherGainLossError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="otherGainLossB" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.otherGainLossError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="otherGainLossC" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.otherGainLossError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="otherGainLossD" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.otherGainLossError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 9. </span>
                          <span class="mx-1">{{'Taxpayer.taxable-ira-distributions-title' | translate }}</span>
                          <span class="ml-auto"> 9 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="taxableIraDistributionA" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.taxableIraDistributionError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="taxableIraDistributionB" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.taxableIraDistributionError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="taxableIraDistributionC" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.taxableIraDistributionError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="taxableIraDistributionD" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.taxableIraDistributionError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 10. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.taxable-pensions-and-annuities' | translate}}</span
                          >
                          <span class="ml-auto"> 10 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="taxablePensionA" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.taxablePensionError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="taxablePensionB" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.taxablePensionError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="taxablePensionC" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.taxablePensionError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="taxablePensionD" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.taxablePensionError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 11. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.rental-real-estate-partnership' | translate}}</span
                          >
                          <span class="ml-auto"> 11 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="rentalRealA" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.rentalRealError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="rentalRealB" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.rentalRealError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="rentalRealC" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.rentalRealError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="rentalRealD" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.rentalRealError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 12. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.subchapter-s-corporation-distributions' | translate}}</span
                          >
                          <span class="ml-auto"> 12. </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        {{'Taxpayer.not-applicable' | translate}}
                      </td>
                      <td class="text-center border-right col-2"></td>
                      <td class="text-center border-right col-2"></td>
                      <td class="text-center col-2"></td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 13. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.farm-income-or-loss' | translate}}</span
                          >
                          <span class="ml-auto"> 13 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="farmIncomeA" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.farmIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="farmIncomeB" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.farmIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="farmIncomeC" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.farmIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="farmIncomeD" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.farmIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 14. </span>
                          <span class="mx-1">{{'Taxpayer.unemployment-compensation' | translate}}</span>
                          <span class="ml-auto"> 14. </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="unemploymentA" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="unemploymentB" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        {{'Taxpayer.not-applicable' | translate}}
                      </td>
                      <td class="text-center col-2">{{'Taxpayer.not-taxable' | translate}}</td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 15. </span>
                          <span class="mx-1">{{'Taxpayer.social-security-benefits' | translate }}</span>
                          <span class="ml-auto"> 15 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="ssBenefitsA" groupName="irs1040ScheduleTC"

                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="ssBenefitsB" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        {{'Taxpayer.not-applicable' | translate}}
                      </td>
                      <td class="text-center col-2">
                        {{'Taxpayer.not-applicable' | translate}}
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 16. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.other-income-att-statement' | translate}}</span
                          >
                          <span class="ml-auto"> 16 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector
                            (change)="calculate()"
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            allowNegativeNumbers="true"
                            controlClass="form-control bg-light text-right"
                            controlName="otherIncomeA"
                            groupName="irs1040ScheduleTC"
                            [customClass]="form.get('irs1040ScheduleTC').errors?.otherIncomeError ? 'is-invalid' : ''"
                            isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()"
                                           [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right"
                                           controlName="otherIncomeB"
                                           groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.otherIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()"
                                           [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right"
                                           controlName="otherIncomeC"
                                           groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.otherIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()"
                                           [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right"
                                           controlName="otherIncomeD"
                                           groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.otherIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 17. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.total-additions-add-lines-2-16' | translate}}</span
                          >
                          <span class="ml-auto"> 17 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           [readonly]="true"
                                           allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right"
                                           controlName="totalAdditionsA"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.totalAdditionsError ? 'is-invalid' : ''"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form"
                                           [data]="individualReturn?.review" [readonly]="true"
                                           allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right"
                                           controlName="totalAdditionsB"
                                           groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.totalAdditionsError ? 'is-invalid' : ''"
                                           isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           [readonly]="true"
                                           allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right"
                                           controlName="totalAdditionsC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.totalAdditionsError ? 'is-invalid' : ''"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           [readonly]="true"
                                           allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right"
                                           controlName="totalAdditionsD"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.totalAdditionsError ? 'is-invalid' : ''"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0">
                        <div class="d-flex">
                          <span> 18. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.total-income-add-lines-1-16' | translate}}</span
                          >
                          <span class="ml-auto"> 18 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" [readonly]="true"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right" controlName="totalIncomeA"
                                           groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.totalIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" [readonly]="true"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right" controlName="totalIncomeB"
                                           groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.totalIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" [readonly]="true"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right" controlName="totalIncomeC"
                                           groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.totalIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" [readonly]="true"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right" controlName="totalIncomeD"
                                           groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.totalIncomeError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="5">
                        <h5 class="m-0">
                          <b>{{'Taxpayer.deduction-schedule' | translate}}</b>
                          <small class="ml-2"
                          >{{'Taxpayer.see-instructions-text' | translate}}</small
                          >
                        </h5>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0 pl-4">
                        <div class="d-flex">
                          <span> 1. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.ira-deduction' | translate}}</span
                          >
                          <span class="ml-auto"> 1 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="iraDeductionA" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.iraDeductionError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="iraDeductionB" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.iraDeductionError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right" controlName="iraDeductionC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.iraDeductionError ? 'is-invalid' : ''"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right" controlName="iraDeductionD"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.iraDeductionError ? 'is-invalid' : ''"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0 pl-4">
                        <div class="d-flex">
                          <span> 2. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.self-employed-sep' | translate}}</span
                          >
                          <span class="ml-auto"> 2 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="selfEmpA" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.selfEmpError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="selfEmpB" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.selfEmpError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="selfEmpC" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.selfEmpError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="selfEmpD" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.selfEmpError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0 pl-4">
                        <div class="d-flex">
                          <span> 3. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.employee-business-expenses' | translate}}</span
                          >
                          <span class="ml-auto"> 3 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="empBizA" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.empBizError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="empBizB" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.empBizError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right" controlName="empBizC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.empBizError ? 'is-invalid' : ''"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right" controlName="empBizD"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.empBizError ? 'is-invalid' : ''"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0 pl-4">
                        <div class="d-flex">
                          <span> 4. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.moving-expenses-into-city-area-only' | translate}}</span
                          >
                          <span class="ml-auto"> 4 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">

                      </td>
                      <td class="text-center border-right col-2">

                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right" controlName="movingExpC"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right" controlName="movingExpD"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0 pl-4">
                        <div class="d-flex">
                          <span> 5. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.alimony-paid' | translate}}</span
                          >
                          <span class="ml-auto"> 5 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="alimonyPaidA" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.alimonyPaidError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="alimonyPaidB" groupName="irs1040ScheduleTC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.alimonyPaidError ? 'is-invalid' : ''"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right" controlName="alimonyPaidC"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.alimonyPaidError ? 'is-invalid' : ''"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right" controlName="alimonyPaidD"
                                           [customClass]="form.get('irs1040ScheduleTC').errors?.alimonyPaidError ? 'is-invalid' : ''"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-4 py-0 pl-4">
                        <div class="d-flex">
                          <span> 6. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.renaissance-zone-deduction-attach' | translate}} 6</span
                          >
                          <span class="ml-auto"> 6 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2"></td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review" allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right" controlName="renaissanceZoneDeductB" groupName="irs1040ScheduleTC"

                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form"
                                           [data]="individualReturn?.review" allowNegativeNumbers="true" controlClass="form-control bg-light text-right"
                                           controlName="renaissanceZoneDeductC" groupName="irs1040ScheduleTC"

                                           isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()" [controlForm]="form"
                                           [data]="individualReturn?.review" allowNegativeNumbers="true" controlClass="form-control bg-light text-right"
                                           controlName="renaissanceZoneDeductD" groupName="irs1040ScheduleTC"

                                           isNumeric="true"

                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                  </table>
                  <table class="w-100">
                    <tr formGroupName="irs1040ScheduleTC">
                      <td class="border-right col-8 py-0" colspan="3">
                        <div class="d-flex">
                          <span> 19. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.total-deductions-1-6' | translate}}</span
                          >
                          <span class="ml-auto"> 19 </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2 py-0">
                        <ui-input-selector [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           [readonly]="true"
                                           allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right"
                                           controlName="totalDeductC"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2 py-0">
                        <ui-input-selector [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           [readonly]="true"
                                           allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right"
                                           controlName="totalDeductD" groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr formGroupName="irs1040ScheduleTC">
                      <td class="border-right col-8 py-0" colspan="3">
                        <div class="d-flex">
                          <span> 20a. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.total-income-after-deductions' | translate}}</span
                          >
                          <span class="ml-auto"> 20a </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            [readonly]="true"
                            allowNegativeNumbers="true"
                            controlClass="form-control bg-light text-right"
                            controlName="totalIncomeAfterDeductionC"
                            groupName="irs1040ScheduleTC"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            [readonly]="true"
                            allowNegativeNumbers="true"
                            controlClass="form-control bg-light text-right"
                            controlName="totalIncomeAfterDeductionD"
                            groupName="irs1040ScheduleTC"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr formGroupName="irs1040ScheduleTC">
                      <td class="border-right col-8 py-0" colspan="3">
                        <div class="d-flex">
                          <span> 20b. </span>
                          <span class="mx-1"
                          >{{'Taxpayer.losses-transferred-between-column-c-d' | translate}}</span
                          >
                          <span class="ml-auto"> 20b </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right"
                                           controlName="totalLossesTransferredC"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right"
                                           controlName="totalLossesTransferredD"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr formGroupName="irs1040ScheduleTC">
                      <td class="border-right col-8 py-0" colspan="3">
                        <div class="d-flex">
                          <span> 20c. </span>
                          <span class="mx-1">
                            {{'Taxpayer.total-income-after-adjustment' | translate}}</span>
                          <span class="ml-auto"> 20c </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           allowNegativeNumbers="true" controlClass="form-control bg-light text-right"
                                           controlName="totalIncomeAfterC"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true"
                                           controlClass="form-control bg-light text-right"
                                           controlName="totalIncomeAfterD"

                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-8 py-0" colspan="3">
                        <div class="d-flex align-items-start">
                          <span> 21. </span>
                          <span class="mx-1"> {{'Taxpayer.exemptions' | translate}}</span>
                          <span class="mx-4">{{'Taxpayer.enter-the-number-of-exemptions' | translate}}</span>
                          <div class="d-flex align-items-center mr-3">
                            21a
                            <ui-input-selector [controlForm]="form"
                                               [data]="individualReturn?.review"
                                               [decimals]="0"
                                               [readonly]="true"
                                               allowNegativeNumbers="true"
                                               controlClass="form-control h-100 ml-2 rounded-0  text-right"
                                               controlName="numberOfExemptions"
                                               isNumeric="true"

                            >
                            </ui-input-selector>
                          </div>
                          <span class="ml-auto"> 21b </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            [readonly]="true"
                            allowNegativeNumbers="true"
                            controlClass="bg-light form-control text-right"
                            controlName="amountForExemptions"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">
                      </td>
                    </tr>

                    <tr formGroupName="irs1040ScheduleTC">
                      <td class="border-right border-top-0 col-8 py-0" colspan="3">
                        <div class="d-flex align-items-start">
                          <span class="w-115px"></span>
                          <span class="mx-4">
                            {{'Taxpayer.if-the-amount-on-line-21b-exceeds' | translate}}
                          </span>
                          <span class="ml-auto"> 21c </span>
                        </div>
                      </td>

                      <td class="text-center border-right border-top-0 col-2">

                      </td>
                      <td class="text-center col-2 border-top-0">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true"
                                           controlClass="bg-light form-control text-right"
                                           controlName="amountExemptionsD"

                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr formGroupName="irs1040ScheduleTC">
                      <td class="border-right col-8 py-0" colspan="3">
                        <div class="d-flex">
                          <span> 22a. </span>
                          <span class="mx-1">
                            {{'Taxpayer.total-income-subject-to-tax-as-a-resident' | translate}}</span>
                          <span class="ml-auto"> 22a </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review"
                                           allowNegativeNumbers="true"
                                           controlClass="bg-light form-control text-right"
                                           controlName="resTotalIncomeSubTaxBC"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">

                      </td>
                    </tr>

                    <tr formGroupName="irs1040ScheduleTC">
                      <td class="border-right col-8 py-0" colspan="3">
                        <div class="d-flex">
                          <span> 22b. </span>
                          <span class="mx-1">
                            {{'Taxpayer.total-income-subject-to-tax-as-a-nonresident' | translate}}</span>
                          <span class="ml-auto"> 22b </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">

                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           allowNegativeNumbers="true"
                                           controlClass="bg-light form-control text-right"
                                           controlName="nonTotalIncomeSubTaxAD"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr formGroupName="irs1040ScheduleTC">
                      <td class="border-right col-8 py-0" colspan="3">
                        <div class="d-flex">
                          <span> 23a. </span>
                          <span class="mx-1 w-175px"> {{'Taxpayer.tax-at-resident-rate' | translate}} {{ taxRate | percent:'0.2-2' }}</span>
                          <span class="mx-5">
                                            <b>({{'Taxpayer.multiply-line-22a-by-resident-taxRate' | translate}})</b>
                                          </span>
                          <span class="ml-auto"> 23a </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form"
                                           readonly="true"
                                           [data]="individualReturn?.review"
                                           allowNegativeNumbers="true"
                                           controlClass="bg-light form-control text-right"
                                           controlName="taxResRateAC"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2">

                      </td>
                    </tr>
                    <tr formGroupName="irs1040ScheduleTC">
                      <td class="border-right col-8 py-0" colspan="3">
                        <div class="d-flex">
                          <span> 23b. </span>
                          <span class="mx-1 w-175px">
                            {{'Taxpayer.taxt-at-nonresident-rate' | translate:{taxRate: (taxRate / 2) | percent:'0.2-2'} }}
                          </span>
                          <span class="mx-5">
                                            <b>({{'Taxpayer.multiply-line-22b-by-nonresident-taxRate' | translate}})</b>
                                          </span>
                          <span class="ml-auto"> 23b </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                      </td>
                      <td class="text-center col-2">
                        <ui-input-selector (change)="calculate()"
                                           [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           allowNegativeNumbers="true"
                                           controlClass="bg-light form-control text-right"
                                           controlName="taxNonResRateAD"
                                           groupName="irs1040ScheduleTC"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="border-right col-8 py-0" colspan="3">
                        <div class="d-flex">
                          <span> 23c. </span>
                          <span class="mx-1">{{'Taxpayer.total-tax-lines-23a-23b' | translate}}</span>
                          <span class="mx-5"></span>
                          <span class="ml-auto"> 23c </span>
                        </div>
                      </td>
                      <td class="text-center border-right col-2">
                        <ui-input-selector [controlForm]="form"
                                           [data]="individualReturn?.review"
                                           allowNegativeNumbers="true"
                                           controlClass="bg-light form-control text-right"
                                           controlName="totalTax"
                                           isNumeric="true">
                        </ui-input-selector>
                      </td>
                      <td class="text-center col-2"></td>
                    </tr>
                    <tr>
                      <td class="justify-items-center" colspan="3">
                        <div class="d-flex align-items-start">
                          <span> 24. </span>
                          <span class="mx-1"> {{'Taxpayer.payments-and-credits' | translate}}</span>
                          <table>
                            <tbody>
                            <tr>
                              <td class="border-top-0 pr-5" rowspan="2"></td>
                              <td class="border-top-0"></td>
                              <td class="border-top-0 text-center">
                                {{'Taxpayer.citytax-withheld' | translate:{city: city} }}
                              </td>
                              <td class="border-top-0"></td>
                              <td class="border-top-0 text-center">
                                {{'Taxpayer.other-tax-payments' | translate}}
                              </td>
                              <td class="border-top-0"></td>
                              <td class="border-top-0 text-center">
                                {{'Taxpayer.credit-for-tax-paid-to-another-city'}}
                              </td>
                            </tr>
                            <tr>
                              <!--                       rowspan -->
                              <td class="border-top-0">24a</td>
                              <td class="border-top-0">
                                <ui-input-selector
                                    (change)="calculate()"
                                    [controlForm]="form"
                                    [data]="(individualReturn$|async)?.review"
                                    allowNegativeNumbers="true"
                                    class="w-100"
                                    controlClass="form-control bg-light text-right"
                                    controlName="taxWithheldByEmployer"
                                    isNumeric="true"
                                >
                                </ui-input-selector>
                              </td>
                              <td class="border-top-0">24b</td>
                              <td class="border-top-0">
                                <ui-input-selector
                                    (change)="calculate()"
                                    [controlForm]="form"
                                    [data]="(individualReturn$|async)?.review"
                                    allowNegativeNumbers="true"
                                    controlClass="form-control bg-light fs-3 text-right d-inline h4"
                                    controlName="paymentsOnPriorYearDeclarationTax"
                                    isNumeric="true"
                                >
                                </ui-input-selector>
                              </td>
                              <td class="border-top-0">24c</td>
                              <td class="border-top-0">
                                <ui-input-selector
                                    (change)="calculate()"
                                    [controlForm]="form"
                                    [data]="(individualReturn$|async)?.review"
                                    allowNegativeNumbers="true"
                                    controlClass="form-control bg-light text-right"
                                    controlName="grandTotalCredit"
                                    groupName="creditForTaxPaidAnotherCity"
                                    isNumeric="true"
                                >
                                </ui-input-selector>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                      <td class="border-right text-right">
                        <span class="pr-2">{{'Taxpayer.total-payment-and-credit' | translate}}</span> 24
                      </td>
                      <td class="p-2">
                        <ui-input-selector
                            (change)="calculate()"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            allowNegativeNumbers="true"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="totalPaymentsCredits"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr>
                      <td class="justify-items-center" colspan="3">
                        <div class="d-flex align-items-start">
                          <span>25.</span>
                          <span class="mx-1">{{'Taxpayer.interest-and-penalty-for' | translate}}
                            </span>
                          <table>
                            <tbody>
                            <tr>
                              <td class="border-top-0"></td>
                              <td class="border-top-0 text-center">
                                {{'Taxpayer.interests' | translate}}
                              </td>
                              <td class="border-top-0"></td>
                              <td class="border-top-0 text-center">
                                {{'Taxpayer.penalty' | translate}}
                              </td>
                            </tr>
                            <tr>
                              <!--                       rowspan -->
                              <td class="border-top-0">25a</td>
                              <td class="border-top-0">
                                <ui-input-selector
                                    (change)="calculate()"
                                    [controlForm]="form"
                                    [data]="(individualReturn$|async)?.review"
                                    allowNegativeNumbers="true"
                                    controlClass="form-control bg-light text-right"
                                    controlName="totalInterestAndPenaltyOnUnderpaidEstimatesOrLateFilesReturns"
                                    isNumeric="true"
                                >
                                </ui-input-selector>
                              </td>
                              <td class="border-top-0">25b</td>
                              <td class="border-top-0">
                                <ui-input-selector
                                    (change)="calculate()"
                                    [controlForm]="form"
                                    [data]="(individualReturn$|async)?.review"
                                    allowNegativeNumbers="true"
                                    controlClass="form-control bg-light text-right"
                                    controlName="interestOnUnderpaidEstimatesOrLateFiledReturns"
                                    isNumeric="true"
                                >
                                </ui-input-selector>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </td>
                      <td class="text-right border-right">
                        <span class="pr-2">{{'Taxpayer.total-interest-and-penalty' | translate}} </span> 25c
                      </td>
                      <td class="p-2">
                        <ui-input-selector
                            (change)="calculate()"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            allowNegativeNumbers="true"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="penaltyOnUnderpaidEstimatesOrLateFiledReturns"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3">26.
                        <span class="font-weight-bold pr-2">{{'Taxpayer.tax-due' | translate}}</span>
                        {{'Taxpayer.amount-you-owe' | translate:{city: city} }}
                      </td>
                      <td class="text-right border-right">
                        <span class="font-weight-bold pr-2">{{'Taxpayer.pay-with-return' | translate}}</span> 26
                      </td>
                      <td class="p-2">
                        <ui-input-selector
                            readonly="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            allowNegativeNumbers="true"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="totalTaxDue"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <ng-container *ngIf="form.get('totalOverPayment').value > 0">
                      <tr>
                        <td colspan="3">27.<span class="font-weight-bold pr-2">{{'Taxpayer.overpayment-title' | translate}}</span> {{'Taxpayer.tax-overpayment-subtract-lines-23b-25c-24d' |translate}}
                        </td>
                        <td class="text-right border-right">
                          <span class="font-weight-bold pr-2">{{'Taxpayer.overpayment-title' | translate}}</span> 27
                        </td>
                        <td class="p-2">
                          <ui-input-selector
                              [controlForm]="form"
                              [data]="(individualReturn$|async)?.review"
                              allowNegativeNumbers="true"
                              class="w-100"
                              controlClass="form-control bg-light text-right"
                              controlName="totalOverPayment"
                              isNumeric="true"
                          >
                          </ui-input-selector>
                        </td>
                      </tr>
                      <tr>
                        <td class="justify-items-center" colspan="3">
                          28.
                          <table>
                            <tbody>
                            <tr>
                              <td class="border-top-0 pr-5" rowspan="2">
                                {{'Taxpayer.amount-of-overpayment-donated'}}
                              </td>
                              <td class="border-top-0"></td>
                              <td class="border-top-0 text-center">
                                {{'Taxpayer.donation' | translate}} 1
                              </td>
                              <td class="border-top-0"></td>
                              <td class="border-top-0 text-center">
                                {{'Taxpayer.donation' | translate}} 2
                              </td>
                              <td class="border-top-0"></td>
                              <td class="border-top-0 text-center">
                                {{'Taxpayer.donation' | translate}} 3
                              </td>
                            </tr>
                            <tr>
                              <!--                       rowspan -->
                              <td class="border-top-0">28a</td>
                              <td class="border-top-0">
                                <ui-input-selector
                                    (change)="calculate()"
                                    [controlForm]="form"
                                    [data]="(individualReturn$|async)?.review"
                                    allowNegativeNumbers="true"
                                    class="w-100"
                                    controlClass="form-control bg-light text-right"
                                    controlName="donation35A"
                                    isNumeric="true"
                                >
                                </ui-input-selector>
                              </td>
                              <td class="border-top-0">28b</td>
                              <td class="border-top-0">
                                <ui-input-selector
                                    (change)="calculate()"
                                    [controlForm]="form"
                                    [data]="(individualReturn$|async)?.review"
                                    allowNegativeNumbers="true"
                                    class="w-100"
                                    controlClass="form-control bg-light text-right"
                                    controlName="donation35B"
                                    isNumeric="true"
                                >
                                </ui-input-selector>
                              </td>
                              <td class="border-top-0">28c</td>
                              <td class="border-top-0">
                                <ui-input-selector
                                    (change)="calculate()"
                                    [controlForm]="form"
                                    [data]="(individualReturn$|async)?.review"
                                    allowNegativeNumbers="true"
                                    class="w-100"
                                    controlClass="form-control bg-light text-right"
                                    controlName="donation35C"
                                    isNumeric="true"
                                >
                                </ui-input-selector>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </td>
                        <td class="border-right text-right">
                          28d
                        </td>
                        <td class="p-2">
                          <ui-input-selector
                              [controlForm]="form"
                              [data]="(individualReturn$|async)?.review"
                              [readonly]="!form.get('totalOverPayment').value"
                              allowNegativeNumbers="true"
                              class="w-100"
                              controlClass="form-control bg-light text-right"
                              controlName="totalDonations"
                              isNumeric="true"
                          >
                          </ui-input-selector>
                        </td>
                      </tr>

                      <tr>
                        <td colspan="3">29.
                          {{'Taxpayer.amount-of-overpayment-credited' | translate}}
                          {{ +(individualReturn$|async)?.taxYear + 1 }}
                        </td>
                        <td class="text-right border-right">
                          <span class="font-weight-bold pr-2">{{'Taxpayer.amount-of-credit-to' | translate}} {{ +(individualReturn$|async)?.taxYear + 1 }} &gt;&gt;</span> 29
                        </td>
                        <td class="p-2">
                          <ui-input-selector
                              [controlForm]="form"
                              [data]="(individualReturn$|async)?.review"
                              [readonly]="!form.get('totalOverPayment').value"
                              allowNegativeNumbers="true"
                              class="w-100"
                              controlClass="form-control bg-light text-right"
                              controlName="amountOfOverpaymentCreditedForward"
                              isNumeric="true"
                          >
                          </ui-input-selector>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="3">30.
                          {{'Taxpayer.amount-of-overpayment-refunded' | translate}}
                        </td>
                        <td class="text-right border-right">
                          <span class="font-weight-bold pr-2">{{'Taxpayer.refund-amount' | translate}} &gt;&gt;</span> 30
                        </td>
                        <td class="p-2">
                          <ui-input-selector
                              [controlForm]="form"
                              [data]="(individualReturn$|async)?.review"
                              [readonly]="!form.get('totalOverPayment').value"
                              allowNegativeNumbers="true"
                              class="w-100"
                              controlClass="form-control bg-light text-right"
                              controlName="overpaymentToBeRefunded"
                              isNumeric="true"
                          >
                          </ui-input-selector>
                        </td>
                      </tr>
                      <ng-container *ngIf="form.get('overpaymentToBeRefunded').value>0">
                        <tr>
                          <td rowspan="3">31.</td>
                          <td colspan="2" rowspan="3">
                            {{'Taxpayer.direct-deposit-refund-v2' | translate}}
                          </td>
                          <td class="align-top text-nowrap inline">
                            <input
                                class="custom-control-input"
                                formControlName="refundDirectDeposit"
                                id="refund"
                                type="checkbox"
                            />
                            <label
                                class="custom-control-label"
                                for="refund"> 31.a {{'Taxpayer.refund-direct-deposit' | translate}}
                            </label>
                          </td>
                          <td class="text-right text-right">
                            31.c&nbsp;{{'Taxpayer.routing-number' | translate}}
                          </td>
                          <td class="align-top">
                            <ui-input-selector
                                [controlForm]="form"
                                [data]="(individualReturn$|async)?.review"
                                class="w-100"
                                controlClass="form-control bg-light text-right"
                                controlName="routingNumber"
                            >
                            </ui-input-selector>
                          </td>
                        </tr>
                        <tr class="border-white">
                          <!--              rowspan---    <td></td>-->
                          <td class="text-nowrap inline border-top-0">
                            <!--                    <input-->
                            <!--                        class="custom-control-input"-->
                            <!--                        formControlName="payTaxDueDirectWithdrawal"-->
                            <!--                        id="due"-->
                            <!--                        type="checkbox"-->
                            <!--                    />-->
                            <!--                    <label class="custom-control-label" for="due">-->
                            <!--                      31.b Pay tax due (direct deposit)-->
                            <!--                    </label>-->
                          </td>
                          <td class="text-right text-right border-top-0">
                            31.d {{'Taxpayer.account-number' | translate}}
                          </td>
                          <td class="border-top-0">
                            <ui-input-selector
                                [controlForm]="form"
                                [data]="(individualReturn$|async)?.review"
                                class="w-100"
                                controlClass="form-control bg-light text-right"
                                controlName="accountNumber"
                            >
                            </ui-input-selector>
                          </td>
                        </tr>
                        <tr>
                          <!--              rowspan---    <td></td>-->
                          <td class="text-right text-right border-top-0">31.d {{'Taxpayer.account-type' | translate}}</td>
                          <td class="align-top text-nowrap inline border-top-0" colspan="2">
                      <span class="px-5">
                      <input
                          [class.is-invalid]=" form.get('typeOfAccount').invalid"
                          class="custom-control-input"
                          formControlName="typeOfAccount"
                          id="actype"
                          name="typeOfAccount"
                          type="radio"
                          value="C"
                          [attr.aria-invalid]="form.get('typeOfAccount').invalid ? 'true' : 'false'"
                      />
                      <label class="custom-control-label" for="actype"
                      > 31.e1 {{'Taxpayer.checking' | translate}}</label>
                     </span>
                            <span class="px-5">
                      <input
                          [class.is-invalid]="form.get('typeOfAccount').invalid"
                          class="custom-control-input"
                          formControlName="typeOfAccount"
                          id="acsaving"
                          name="typeOfAccount"
                          type="radio"
                          value="S"
                          [attr.aria-invalid]="form.get('typeOfAccount').invalid ? 'true' : 'false'"
                      />
                      <label class="custom-control-label" for="acsaving">31.e2 {{'Taxpayer.savings' | translate}}</label>
                    </span>
                            <a (click)="form.get('typeOfAccount').setValue(null);"
                               href="javascript:;" tooltip="Clear Account Type">
                              <svg class="icon" viewBox="0 0 24 24">
                                <path
                                    d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
                                    stroke="none"
                                />
                              </svg>
                            </a>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>
                  </table>
                </td>
              </tr>
            </table>
            <div class="bg-white row">
              <div class="col-12 d-flex justify-content-end">
                <button (click)="back()"
                        [disabled]="isLoading$ | async"
                        class="btn btn-secondary ml-3"
                        type="button"
                        tabindex="0"
                        role="button"
                        [attr.aria-label]="'Buttons.back-btn' | translate"
                        [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
                        [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'">
                  <span>{{ "Buttons.back-btn" | translate }}</span>
                </button>
                <button (click)="next()"
                        [disabled]="isLoading$ | async"
                        class="btn btn-primary font-weight-600 ml-3"
                        id="submit"
                        [attr.aria-label]="'Buttons.next-btn' | translate"
                        [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
                        [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'">
                  <span>{{ "Buttons.next-btn" | translate }}</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

