export const environment = {
  production: true,
  state: "oh",
  city: "dayton",
  baseApiUrl: "https://api.insourcetax.com",
  baseSocketUrl: "https://api.insourcetax.com",
  whiteListDomains: ["api.insourcetax.com"],
  blackListRoutes: ["admin.insourcetax.com/users/login"],
  googleMapApiKey: "AIzaSyAdU5gmucsrIV8ji2XIO9AM8bguQNZDnGU",
  stripeKey:
    "pk_live_51JUXa0LZiHE2VTOk5aiI9SizWpau9svrDnIApKaAgSuxmJPgcd6fZAU73y4PtvyLafmvilujYBG7SsOIroeggQix00F4rmkUU4",
};
