import {BrowserModule} from "@angular/platform-browser";
import {NgModule} from "@angular/core";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {IndividualModule as IndividualFeatureModule} from "@city-tax/features/individual";
import {CoreModule} from "@city-tax/core";
import {IConfig, NgxMaskModule} from "ngx-mask";
import {SocketIoModule} from "ngx-socket-io";
import {NgxPermissionsModule} from "ngx-permissions";
import {JwtModule} from "@auth0/angular-jwt";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {AppComponent} from "./app.component";
import {AppRouting} from "./app.routing";

import {environment} from "../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ModalModule as BsModalModule} from "ngx-bootstrap/modal";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {TabsModule as BsTabModule} from "ngx-bootstrap/tabs";
import {PopoverModule as BsPopoverModule} from "ngx-bootstrap/popover";
import {TimepickerModule as BsTimepickerModule} from "ngx-bootstrap/timepicker";
import {ButtonsModule as BsButtonsModule} from "ngx-bootstrap/buttons";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {DatePipe} from "@angular/common";
import {NgxStripeModule} from 'ngx-stripe';
import {ApiServiceConfig} from "@hexalang/ui/core";
import {CustomTranslateLoader} from "@city-tax/shared";

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return sessionStorage.getItem("auth_token");
}

const config = new ApiServiceConfig(
  environment.baseApiUrl,
  environment.stripeKey,
  environment.production
);

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IndividualFeatureModule,
    CoreModule.forRoot(config),
    SocketIoModule.forRoot({
      url: environment.baseSocketUrl,
      options: {
        transports: ["websocket"],
        query: {token: tokenGetter()},
      },
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: environment.whiteListDomains,
        disallowedRoutes: environment.blackListRoutes,
        headerName: "x-auth",
        authScheme: "",
      },
    }),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsModalModule.forRoot(),
    BsTabModule.forRoot(),
    BsPopoverModule.forRoot(),
    BsTimepickerModule.forRoot(),
    BsButtonsModule.forRoot(),
    TooltipModule.forRoot(),
    NgxMaskModule.forRoot(maskConfigFunction),
    NgxPermissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRouting,
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    NgxStripeModule.forRoot(environment.stripeKey),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [DatePipe],
})
export class AppModule {
}
