<form [formGroup]="form" novalidate>
  <div class="main pt-2">
      <div class="d-flex justify-content-between p-3">
          <h3>Update Email</h3>
          <div class="">
              <button (click)="hide()" aria-label="Close" class="close pull-right" type="button" [attr.aria-label]="'Close'" 
              >
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
      </div>
      <div class="content">
          <div class="bg-gray-100">
              <div class="row">
                  <div class="col-12">
                      <div class="card card-body mb-12">
                          <div class="form-group">
                              <label for="email">Email <span class="text-danger pl-1">*</span></label>
                              <input id="email" 
                               class="form-control" 
                               formControlName="email" 
                               type="text" 
                               [class.is-invalid]="form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched)"
                               [attr.aria-label]="'Email'"
                               [attr.aria-required]="'true'"
                               [attr.aria-invalid]="form.get('email')?.invalid && (form.get('email')?.dirty || form.get('email')?.touched) ? 'true' : 'false'"/>
                          </div>
                          
                          <button (click)="submit()" 
                               [disabled]="form.invalid || (isLoading$ | async)" 
                               class="btn btn-primary f-bold" 
                               id="submit" 
                               [attr.aria-label]="'Buttons.submit-btn' | translate "
                               [attr.aria-disabled]="form.invalid || (isLoading$ | async) ? 'true' : 'false'"
                               [attr.aria-busy]="isLoading$ | async" 
                               role="button" 
                               tabindex="0">
                              <ui-spinner [class.active]="isLoading$ | async"
                                 [attr.aria-hidden]="!(isLoading$ | async)">
                                  {{ "Buttons.submit-btn" | translate }}
                              </ui-spinner>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</form>