import {Component, Injector, OnInit} from "@angular/core";
import {HexaDetailComponent, HexaTitleService} from "@hexalang/ui/core";

@Component({
  selector: "city-tax-feature-individual",
  templateUrl: "./individual.component.html",
  styleUrls: ["./individual.component.scss"],
})
export class IndividualComponent extends HexaDetailComponent implements OnInit {
  constructor(injector: Injector, private titleService: HexaTitleService) {
    super(injector);
    this.titleService.changeTitle("");
  }

  ngOnInit() {
  }
}
