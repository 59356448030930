import {Component, OnInit} from "@angular/core";

@Component({
  selector: "city-tax-feature-individual-payment-create",
  templateUrl: "./individual-payment-create.component.html",
  styleUrls: ["./individual-payment-create.component.scss"],
})
export class IndividualPaymentCreateComponent implements OnInit {
  ngOnInit(): void {
  }
}
