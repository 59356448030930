import {Component, Injector, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {IndividualService} from "../../../../services/individual.service";
import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {DateFormControl} from "@city-tax/shared";
import * as moment from "moment";

@Component({
  selector: 'city-tax-feature-individual-filing-acknowledgement',
  templateUrl: './individual-filing-acknowledgement.component.html',
  styleUrls: ['./individual-filing-acknowledgement.component.scss']
})
export class IndividualFilingAcknowledgementComponent

  implements OnInit {

  public form: FormGroup;
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public validDocuments$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public validW2$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public validFederal$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public validOtherCity$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public isLoading$ = this.individualService.isLoading$;
  public isUploading$ = this.individualService.isUploading$;
  public error = null;
  // public taxDocument = this.authService.organization.taxDocument;
  public city = this.authService.organization.name;
  public state = this.authService.config?.state;
  public individualReturn$ = this.individualService.individualReturn$;
  changeDetectorRef: any;
  public currentYear = new Date().getFullYear();
  public minDate = moment(`01/01/${this.currentYear}`, 'MM/DD/YYYY').endOf('day').toDate();
  public maxDate = moment().endOf('day').toDate();

  constructor(
    injector: Injector,
    public toastrService: ToastrService,
    private router: Router,
    private individualService: IndividualService,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) {

  }

  async ngOnInit() {
    this._initFormUpdate();
    await this._getReturn();
  }

  async uploadDocument(id: string, type: string, event: any) {
    const media = {
      file: event.target.files[0],
      mimeType: event.target.files[0].type,
      type: type,
    };
    try {
      const ir = await this.individualService.uploadFile(this.authService.organization.id, id, type, media);
      this.checkDocuments(ir);
      this.patchForm(ir);
    } catch (error) {
    }
  }

  checkDocuments(ir: any) {
    const w2Check = !ir.taxForms?.w2?.required || ir.files?.find(f => f.type === 'W2');
    this.validW2$.next(w2Check);
    const fedCheck = !ir.taxForms.federal.required || ir.files?.find(f => f.type === 'Federal');
    this.validFederal$.next(fedCheck);
    const otherCity = !ir.taxForms?.otherCity?.required || ir.files?.find(f => f.type === 'City-Other');
    this.validOtherCity$.next(otherCity);
    this.validDocuments$.next(w2Check && fedCheck);
  }

  async delete(file: any) {
    try {
      const ir = await this.individualService.deleteFile(
        this.authService.organization.id,
        this.individualService.individualReturn.id,
        file.id
      );
      this.checkDocuments(ir);
    } catch (error) {

    }
  }

  private _initFormUpdate() {
    this.form = this.formBuilder.group({
      acknowledgeName: [null, Validators.required],
      acknowledgeDate: new DateFormControl('', Validators.required),
      extension: null,
      source: null,
      taxForms: this.formBuilder.group({
        w2: this.formBuilder.group({
          required: true,
          provided: null,
        }),
        otherCity: this.formBuilder.group({
          required: null,
          provided: null,
        }),
        federal: this.formBuilder.group({
          required: false,
          provided: null,
        }),
        city: this.formBuilder.group({
          type: null,
          attachment1: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_1: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_2: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_3: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_4: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment3: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment4: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment5: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment6: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment7: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment8: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment9: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment10: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment11: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment12: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment13: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment14: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment15: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment16: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment17: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment18: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment19: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment20: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment21: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment22: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          cfCov: this.formBuilder.group({
            required: false,
            provided: null,
          }),
        }),
      }),
    });
  }

  private async _getReturn() {
    try {
      const ir = await this.individualService.getReturn(this.authService.organization.id, this.authService.config?.state)
      let path = `/individual/filings`;
      if (ir.status === "Completed") {
        path += `/${this.authService.config?.state.toLowerCase()}/${this.individualService.taxYear}/confirmation`;
        await this.router.navigate([path]);
      }
      this.patchForm(ir);
    } catch (error) {

    }
  }

  private patchForm(ir: any) {
    ir.extension = ir.extension ? 'true' : null;
    ir.acknowledgeDate = this.wholeDate(ir.acknowledgeDate)
    this.form.patchValue(ir);
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      this.isDisabled$.next(true);
      this.form.disable();
    } else {
      this.checkDocuments(ir);
    }
  }

  wholeDate(dateTime) {
    if (!dateTime) return null;
    const date = moment(dateTime);

    if (!date.isValid() || date.isAfter(this.maxDate)) {
      return moment(this.maxDate).format('YYYY-MM-DD');
    }
    return date.format('YYYY-MM-DD');
  }

  async next() {
    const path = `/individual/filings/${this.authService.config?.state.toLowerCase()}/${this.individualService.taxYear}/confirmation`;

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      await this.router.navigate([path]);
    } else {

      try {
        if (this.form.valid) {
          await this.individualService.submitReturn(this.authService.organization.id, this.form.getRawValue());
          await this.router.navigate([path]);
        } else {
          this.getFormValidationErrors(this.form);
          this.form.updateValueAndValidity();
        }
      } catch (error) {

      }
    }
  }

  async back() {
    let path = `/individual/filings/${this.authService.config?.state.toLowerCase()}/${this.individualService.taxYear}`;
    if (this.form.get('source').value === 'Upload') {
      path += '/upload';
    } else {
      path += this.individualService.individualReturn.residency !== "P" ? "/page1" : "/tc";
    }

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      await this.router.navigate([path]);
    } else {
      try {

        await this.individualService.updateReturn(this.city.id, this.form.getRawValue());
        await this.router.navigate([path]);

      } catch (e) {
      }
    }
  }

  async mailToFile() {
    const path = `/individual/filings/${this.authService.config?.state.toLowerCase()}/${this.individualService.taxYear}/confirmation`;

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      await this.router.navigate([path]);
    } else {

      try {

        if (this.form.valid) {
          await this.individualService.mailReturn(this.authService.organization.id, this.form.getRawValue());
          await this.router.navigate([path]);
        } else {
          this.form.updateValueAndValidity();
        }
      } catch (error) {

      }
    }
  }


  getFormValidationErrors(form) {
    if (form && form.controls) {
      Object.keys(form.controls).forEach((key) => {
        const control = form.get(key);
        if (control.controls) {
          this.getFormValidationErrors(this.form.get(key));
        }
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            console.log(
              "Key control: " +
              key +
              ", keyError: " +
              keyError +
              ", err value: ",
              controlErrors[keyError]
            );
          });
        }
      });
    }
  }

}
