import {IAddressBase, ILastModifiedBy} from "@city-tax/shared";
import {IIndividualBase, IIndividualResponse,} from "../interfaces/individual.interface";

export class IndividualModel implements IIndividualResponse {
  public id: string;
  public status: string;
  public individualId: string;
  public fileNumber: string;
  public name: string;
  public ssn: string;
  public email: string;
  public emailNotifications: boolean;
  public phone: string;
  public birthdate: string;
  public occupation: string;
  public dateOfDeath: string;
  public deceased: boolean;
  public address: IAddressBase;
  public spouse: IIndividualBase;
  public lastModifiedBy: ILastModifiedBy;
  public paymentMethods: Array<any>;
  public createdAt: Date;
  public updatedAt: Date;

  constructor(individual: IndividualModel) {
    this.id = individual.id;
    this.status = individual.status;
    this.individualId = individual.individualId;
    this.fileNumber = individual.fileNumber;
    this.name = individual.name;
    this.ssn = individual.ssn;
    this.email = individual.email;
    this.phone = individual.phone;
    this.birthdate = individual.birthdate;
    this.occupation = individual.occupation;
    this.dateOfDeath = individual.birthdate;
    this.deceased = individual.deceased;
    this.address = individual.address;
    this.spouse = individual.spouse;
    this.paymentMethods = individual.paymentMethods;
    this.lastModifiedBy = individual.lastModifiedBy;
    this.createdAt = individual.createdAt;
    this.updatedAt = individual.updatedAt;
  }
}
