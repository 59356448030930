<ng-container *ngIf="selectedPaymentMethod?.type === 'ach' || selectedPaymentMethod?.type === 'bank'">
  <div class="payment-card">
    <div class="cards-header">
      <div class="card-header-title d-flex align-items-center justify-content-between">
        <img [src]="getBrandLogo(selectedPaymentMethod.type)" alt="{{ selectedPaymentMethod.type }}" class="brand-logo">
      </div>
      <span *ngIf="selectedPaymentMethod.isDefault" class="default-checkmark">✔</span>
    </div>
    <div class="d-flex align-items-start justify-content-between">
      <div class="d-card-container flex-column mr-3">
        <div class="card-title">Routing Number</div>
        <div class="card-number">{{ selectedPaymentMethod?.bank?.routingNumber ? selectedPaymentMethod?.bank?.routingNumber : '-' }}</div>
      </div>
      <div class="d-flex flex-column">
        <div class="card-title">Account Type</div>
        <div class="card-number">{{ selectedPaymentMethod?.bank?.accountType === 'C' ? 'Checking' : 'Savings' }}</div>
      </div>
    </div>
    <div class="d-flex flex-column">
      <div class="card-title">Account Number</div>
      <div class="card-number">{{ selectedPaymentMethod?.bank?.accountNumber ? formatBankAccountNumber(selectedPaymentMethod.bank.accountNumber) : '-' }}</div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="selectedPaymentMethod?.type === 'card'">
  <div *ngIf="selectedPaymentMethod?.card" class="payment-card">
    <div class="cards-header">
      <div class="card-header-title d-flex align-items-center justify-content-between">
        <img [src]="getBrandLogo(selectedPaymentMethod.card.brand)" alt="{{ selectedPaymentMethod.card.brand }}" class="brand-logo">
        <div class="brand-name ">{{ selectedPaymentMethod.card.brand | titlecase }}</div>
      </div>
      <span *ngIf="selectedPaymentMethod.isDefault" class="default-checkmark">✔</span>
    </div>
    <div>
      <div class="d-flex flex-column">
        <div class="card-title">Card Number</div>
        <div class="card-number">XXXX XXXX XXXX {{ selectedPaymentMethod.card.cardNumber }}</div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <div class="d-flex flex-column">
        <div class="card-title">Valid Thru</div>
        <div class="card-expiry">{{ formatExpiryDate(selectedPaymentMethod.card.expMonth, selectedPaymentMethod.card.expYear) }}</div>
      </div>
    </div>
  </div>
</ng-container>
