<div class="pt-3 px-3">
  <form [formGroup]="formFilter" novalidate>
    <div class="row">
      <div class="col-2">
        <div class="form-control-wrapper form-control-wrapper-both">
          <input #input
              [form]="formFilter"
              class="form-control"
              formControlName="search"
              placeholder="{{'Taxpayer.search' | translate}}..."
              type="text"
              ui-search-clear
          />
        </div>
      </div>
    </div>
  </form>
</div>
<div class="p-3">
  <div class="card">
    <ngx-datatable
        #datatableNotifications
        (page)="onPage($event)"
        (sort)="onSort($event)"
        [columnMode]="'force'"
        [columns]="columns"
        [count]="(notifications$ | async)?.meta?.inlineCount"
        [externalPaging]="true"
        [externalSorting]="true"
        [footerHeight]="50"
        [headerHeight]="50"
        [limit]="limit"
        [loadingIndicator]="isLoading$ | async"
        [offset]="offset"
        [rowHeight]="50"
        [rows]="(notifications$ | async)?.data"
        class="bootstrap datatable-flush"
    >
    </ngx-datatable>
  </div>
</div>

<ng-template #message
    let-value="value"
    ngx-datatable-cell-template
>
  <div (click)="open(messagePopup,value)" class="text-truncate cursor-pointer">
    {{ value }}
  </div>
</ng-template>

<ng-template #messagePopup
    let-value="value"
>
  <div class="modal-header align-items-center">
    <h4 class="modal-title pull-left">{{'Taxpayer.message' | translate}}</h4>
    <a (click)="bsModalRef.hide()" class="text-muted" href="javascript:;">
      <svg class="icon" viewBox="0 0 24 24">
        <path
            d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
            stroke="none"/>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div class="alert alert-warning mb-0">
      <div class="d-flex align-items-center">
        <div class="active p-2"> {{ currentModalValue }}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <button (click)="bsModalRef.hide()" 
        class="btn btn-primary" 
        type="button"
        tabindex="0" 
        role="button" 
        [attr.aria-label]="'Taxpayer.close-modal' | translate">{{'Taxpayer.ok' | translate}}</button>
  </div>
</ng-template>

<ng-template
    #statusTemplate
    let-i="index"
    let-row="row"
    let-value="value"
    ngx-datatable-cell-template
>
  <a (click)="read(row.id)" class="btn btn-link">
    <ng-container *ngIf="value === 'Unread'">
      <svg class="icon fill-danger-500" fill="currentColor" height="16" tooltip="Click to mute" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"/>
      </svg>
    </ng-container>
    <ng-container *ngIf="value==='Read'">
      <svg class="icon fill-primary" fill="currentColor" height="16" tooltip="Click to unmute" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.164 14H15c-.299-.199-.557-.553-.78-1-.9-1.8-1.22-5.12-1.22-6 0-.264-.02-.523-.06-.776l-.938.938c.02.708.157 2.154.457 3.58.161.767.377 1.566.663 2.258H6.164l-1 1zm5.581-9.91a3.986 3.986 0 0 0-1.948-1.01L8 2.917l-.797.161A4.002 4.002 0 0 0 4 7c0 .628-.134 2.197-.459 3.742-.05.238-.105.479-.166.718l-1.653 1.653c.02-.037.04-.074.059-.113C2.679 11.2 3 7.88 3 7c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0c.942.19 1.788.645 2.457 1.284l-.707.707zM10 15a2 2 0 1 1-4 0h4zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75L.625 15.625z"/>
      </svg>
    </ng-container>
  </a>
</ng-template>
