export enum EPermission {
  INDIVIDUAL_FILING_MI = "IndividualFilingMi:all",
  INDIVIDUAL_FILING_OH = "IndividualFilingOh:all",
  INDIVIDUAL_PAYMENTS = "IndividualPayment:all",
  INDIVIDUAL_PAYMENT_METHODS = "IndividualPaymentMethods:all",
  INDIVIDUAL_ACCOUNT = "IndividualAccount:all",
  BUSINESS_PAYMENTS = "Business:Payment:all",
  BUSINESS_WITHHOLDING_OH = "BusinessOhWithholding:all",
  BUSINESS_W3 = "BusinessW3:all",
  BUSINESS_WITHHOLDING_MI = "BusinessMiWithholding:all",
  BUSINESS_PAYMENT_METHODS = "BusinessPaymentMethods:all",
  BUSINESS_ACCOUNT = "BusinessAccount:all",

  BUSINESS_FILING_MI = "BusinessFilingMi:all",
  BUSINESS_FILING_OH = "BusinessFilingOh:all",

  PENDING = "Pending:all",
}
