import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {JwtHelperService} from "@auth0/angular-jwt";
import {isFuture} from "date-fns";
import {Socket} from "ngx-socket-io";

import {IIndividualResponse} from "../interfaces/individual.interface";
import {IBusinessResponse} from "../interfaces/business.interface";
import {NgxPermissionsService, NgxRolesService} from "ngx-permissions";
import {EComponent, SessionService} from "@city-tax/shared";
import {BusinessModel} from "../models/business.model";
import {IndividualModel} from "../models/individual.model";
import * as CryptoJS from "crypto-js";
import * as moment from "moment";
import {HexaApiService, HexaStorageService} from "@hexalang/ui/core";
import {WithLoadingAndErrorHandler} from "@city-tax/core";
import {ToastrService} from "ngx-toastr";


@Injectable({
  providedIn: "root",
})
export class AuthService extends WithLoadingAndErrorHandler() {
  protected encryptSecretKey = "JIUzI1NiIsInR5cCI6IkpX";
  aesIv = CryptoJS.enc.Utf8.parse("0123456789abcdef");
  aesOptions = {
    iv: this.aesIv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  };

  public config$: Observable<any>;
  private authToken: BehaviorSubject<string>;
  public organizations$: Observable<any>;
  public organization$: Observable<any>;
  public individual$: Observable<IIndividualResponse>;
  public business$: Observable<IBusinessResponse>;
  public alerts$: Observable<number>;

  private _organizations: BehaviorSubject<any>;
  private _organization: BehaviorSubject<any>;
  private _config: BehaviorSubject<any>;
  private _individual: BehaviorSubject<IIndividualResponse>;
  private _business: BehaviorSubject<IBusinessResponse>;
  private _alerts: BehaviorSubject<number>;

  private dataStore: {
    authToken: string;
    _organizations: any;
    _organization: any;
    _individual: IIndividualResponse;
    _business: IBusinessResponse;
    _config: any;
    _alerts: 0;
  };

  constructor(
    private apiService: HexaApiService,
    private jwtHelperService: JwtHelperService,
    private socket: Socket,
    private roleService: NgxRolesService,
    private storageService: HexaStorageService,
    public permissionService: NgxPermissionsService,
    public toastrService: ToastrService,
    public sessionService: SessionService,
  ) {
    super(apiService, toastrService);

    this.storageService.sessionBased = true;

    this.dataStore = {
      authToken: null,
      _organizations: {},
      _organization: null,
      _individual: null,
      _business: null,
      _config: null,
      _alerts: 0,
    };

    this._organizations = new BehaviorSubject({}) as BehaviorSubject<any>;
    this._organization = new BehaviorSubject({}) as BehaviorSubject<any>;
    this._individual = new BehaviorSubject({}) as BehaviorSubject<IIndividualResponse>;
    this._business = new BehaviorSubject({}) as BehaviorSubject<IBusinessResponse>;
    this._config = new BehaviorSubject({}) as BehaviorSubject<any>;
    this._alerts = new BehaviorSubject({}) as BehaviorSubject<number>;

    this.organizations$ = this._organizations.asObservable();
    this.organization$ = this._organization.asObservable();
    this.individual$ = this._individual.asObservable();
    this.business$ = this._business.asObservable();
    this.config$ = this._config.asObservable();
    this.alerts$ = this._alerts.asObservable();
  }

  get path() {
    return this.dataStore._individual?.id ? 'individual' : 'business';
  }

  get token(): object | null {
    // console.log("auth_token", this.jwtHelperService.decodeToken(sessionStorage.getItem("auth_token")));
    if (!sessionStorage.getItem("auth_token")) return null;
    return sessionStorage.getItem("auth_token") ? this.jwtHelperService.decodeToken(sessionStorage.getItem("auth_token")) : null;
  }

  get config() {
    // console.log(this.storageService.get("config"), this.dataStore._config, this.storageService.get("state"), this.storageService.get("city"))
    if (this.dataStore._config !== null) {
      return this.dataStore._config;
    } else if (this.storageService.get("config") === null) {
      return null;
    } else {
      try {
        const config = this.storageService.get("config");
        this.dataStore._config = config;
        this._config.next(Object.assign({}, this.dataStore)._config);
        return config;
      } catch (e) {
        this.storageService.clear();
        return null;
      }
    }
  }

  eFileDeadline(taxYear) {
    const fileYear = taxYear + 1;
    const deadline = this.config?.components?.individual?.eFileDeadline;
    const deadlineDate = deadline ? moment.utc(deadline) : null;
    const date = moment.utc().set("month", 3).endOf("month");

    if (deadlineDate && deadlineDate.year() === fileYear) {
      return moment.utc(deadlineDate).toDate();
    }

    if (date.year() !== taxYear + 1) {
      date.set("year", taxYear + 1);
    }
    const dayOfWeek = date.day();
    const addDays = dayOfWeek === 6 ? 2 : dayOfWeek === 0 ? 1 : 0;
    const dueDate = date.add(addDays, "days");
    return dueDate.toDate();
  }

  get tokenExpiration() {
    return this.getTokenField("expiration");
  }

  get isLoggedIn() {
    if (!this.token) return false;
    return isFuture(this.tokenExpiration);
  }

  public storeToken(token: any) {
    if (!token) {
      sessionStorage.removeItem("auth_token");
    } else {
      sessionStorage.setItem("auth_token", token);
    }
  }

  public async signInIndividual(organization: any, payload: any): Promise<IIndividualResponse> {
    const response = await this.post(`organizations/${organization.id}/individuals/me/login`, payload);
    this.dataStore._organization = organization;
    await this._getConfig(organization.state, organization.name)
    return this.storeIndividual(response);
  }

  public async signInBusiness(organization: any, payload: any): Promise<any> {
    const response = await this.post(`organizations/${organization.id}/businesses/me/login`, payload);
    this.dataStore._organization = organization;
    await this._getConfig(organization.state, organization.name)
    return this.storeBusiness(response);
  }

  public async uploadSS4File(media): Promise<any> {
    const payload = new FormData();
    payload.append("file", media.file);
    payload.append("mimeType", media.mimeType);
    const response = await this
      .post(
        `organizations/${this.organization.id}/businesses/me/file/ss4`,
        payload
      );
    this.dataStore._business = response.body;
    this._business.next(Object.assign({}, this.dataStore)._business);
  }

  public async deleteSS4File(): Promise<any> {
    const response = await this.delete(
      `organizations/${this.organization.id}/businesses/me/file/ss4`
    );

    const business = this.dataStore._business;
    business.ss4.key = null;
    business.ss4.mimeType = null;
    business.ss4.fileName = null;
    business.ss4.size = null;
    this.dataStore._business = business;
    this._business.next(Object.assign({}, this.dataStore)._business);
  }

  public async requestOtpBusiness(organizationId, payload: any): Promise<any> {
    const response = await this.post(
      `organizations/${organizationId}/otp`,
      payload
    );

    if (response.headers.get("x-auth")) {
      this.storeToken(response.headers.get("x-auth"));
      this.dataStore._business = response.body;
      this._business.next(Object.assign({}, this.dataStore)._business);
    }
    return response?.body;
  }

  public async signUpIndividual(organizationId: string, payload: any): Promise<any> {
    const response = await this.post(`organizations/${organizationId}/individuals`, payload);

    this.storeToken(response.headers.get("x-auth"));
    this.dataStore._individual = response.body;
    this._individual.next(Object.assign({}, this.dataStore)._individual);
    return response.body;
  }

  public async signUpBusiness(organizationId, payload: any): Promise<any> {
    const response = await this.post(`organizations/${organizationId}/businesses`, payload);
    this.storeToken(response.headers.get("x-auth"));
    this.dataStore._business = response.body;
    this._business.next(Object.assign({}, this.dataStore)._business);
    return response.body;
  }

  public setOrganization(organization: any) {
    this.dataStore._organization = organization;
    this._organization.next(Object.assign({}, this.dataStore)._organization);
    this.socket.emit("subscribeToOrg", {
      organizationId: organization.organizationId || organization.id,
    });
    this.storageService.save("organization", organization);
    return organization;
  }


  public async getOrganization(id: string): Promise<any> {
    const response = await this.get(`cities/${id}`);
    const organization = response.body;
    await this._getConfig(organization.state, organization.name);
    this.dataStore._organization = organization;
    this._organization.next(Object.assign({}, this.dataStore)._organization);
    await this._getConfig(organization.state, organization.name);
    return organization;
  }

  public async getOrganizationsByGroup(externalKey: string = null): Promise<any> {
    const response = await this.get("cities");
    const organizations = response.body;
    if (externalKey) {
      this.setOrganization(organizations.data.find((org) => org.externalKey === externalKey));
      if (this.dataStore._organization.cityGroup) {
        organizations.data = organizations.data.filter(
          (org) => org.cityGroup === this.dataStore._organization.cityGroup
        );
      }
    }
    this.dataStore._organizations = organizations;
    this._organizations.next(Object.assign({}, this.dataStore)._organizations);
    return organizations;
  }

  public async getOrganizationsByState(state, type): Promise<any> {
    const response = await this.get("cities");
    const organizations = response.body;
    if (state) {
      organizations.data = organizations.data.filter(
        (org) => org.state.toLowerCase() === state.toLowerCase()
      );
      if (type === "individual") {
        organizations.data = organizations.data.filter(
          (org) => org.components?.individual?.payments
        );
      } else {
        organizations.data = organizations.data.filter(
          (org) => org.components?.business?.payments
        );
      }
    }
    this.dataStore._organizations = organizations;
    this._organizations.next(Object.assign({}, this.dataStore)._organizations);
    return organizations;
  }

  public async getOrganizationsByStateOrGroup(state, group, type): Promise<any> {
    if (group) {
      return await this.getOrganizationsByGroup(group);
    }
    return await this.getOrganizationsByState(state, type);
  }

  public maskSSN(mask, value, maskChar = "X") {
    if (!value) {
      return value;
    }
    const vis = value.slice(-4);
    let countNum = "";
    for (let i = 0; i < value.length - 4; i++) {
      if (i === 2 || i === 4) {
        countNum += maskChar + "-";
      } else {
        countNum += maskChar;
      }
    }
    if (mask === true) {
      return countNum + vis;
    } else {
      let countNum1 = "";
      for (let i = 0; i < value.length - 4; i++) {
        if (i === 2 || i === 4) {
          countNum1 += `${value[i]}-`;
        } else {
          countNum1 += `${value[i]}`;
        }
      }
      return countNum1 + vis;
    }
  }

  public mask(mask, value, visibleLength) {
    visibleLength = visibleLength || 4;
    if (!value) {
      return value;
    }
    const vis = value.slice(-visibleLength);
    let countNum = "";
    for (let i = value.length - visibleLength; i > 0; i--) {
      countNum += "X";
    }
    if (mask === true) {
      return countNum + vis;
    } else {
      return value;
    }
  }

  get role() {
    return this.getTokenField("role");
  }

  get accountNumber() {
    return this.getTokenField("accountNumber");
  }

  getTokenField(field: string) {
    return this.token ? this.token[field] : null;
  }

  get isPending() {
    if (!this.dataStore._business) return true;
    return (
      this.dataStore._business.pending ||
      this.dataStore._business.status !== "Active"
    );
  }

  get orgSS4() {
    return this.org ? this.org["ss4"] : null;
  }

  get org() {
    return this.storageService.get("organization");
  }

  get organization() {
    return this.dataStore._organization || this.getTokenField("organization");
  }

  get business(): any | null {
    return this.dataStore._business || this.token || null;
  }

  get individual() {
    return this.dataStore._individual || this.token || null;
  }

  get ss4() {
    return this.dataStore._business?.ss4 || null;
  }

  get name() {
    return this.getTokenField("name") || null;
  }

  get individualId() {
    return this.getTokenField("individualId");
  }

  get businessId() {
    return this.getTokenField("businessId");
  }

  get organizationId() {
    return this.organization?.organizationId || this.organization?.id;
  }

  public filingRedirectPath(path) {
    return `${path}/${this.organization.state.toLowerCase()}/${
      new Date().getFullYear() - 1
    }`;
  }

  public get withholdingRedirectPath() {
    return `/business/withholding/${this.config?.state?.toLowerCase()}/${new Date().getFullYear()}`;
  }

  public clearLocalStorage = () => {
    const config = this.storageService.get("config");
    this.storageService.clear();
    this.storageService.save("config", config);
  };

  public signOut() {
    this.roleService.flushRoles();
    this.permissionService.flushPermissions();
    this.dataStore = {
      authToken: null,
      _organizations: {},
      _organization: null,
      _individual: null,
      _business: null,
      _config: null,
      _alerts: 0,
    };
    this.clearLocalStorage();
  }

  public async updateBusiness(organizationId, payload: any): Promise<BusinessModel> {
    const response = await this.put(`organizations/${organizationId}/businesses/me`, payload);
    return this.storeBusiness(response);
  }

  public async updateBusinessSS4(organizationId: string, payload: any): Promise<BusinessModel> {
    delete payload.id;
    const response = await this.post(`organizations/${organizationId}/businesses/me/ss4`, payload);
    const business = response.body;
    this.dataStore._business = business;
    this._business.next(Object.assign({}, this.dataStore)._business);
    return business;
  }

  public async updateIndividual(organizationId: string, payload: any): Promise<IndividualModel> {
    const response = await this.put(`organizations/${organizationId}/individuals/me`, payload);
    return this.storeIndividual(response);
  }

  public async getIndividual(organizationId): Promise<IndividualModel> {
    const response = await this.get(`organizations/${organizationId}/individuals/me`);
    return this.storeIndividual(response);
  }

  storeIndividual(response: any) {
    if (response.headers.get("x-auth")) {
      this.storeToken(response.headers.get("x-auth"));
    }
    const individual = new IndividualModel(response.body);
    individual.paymentMethods = individual.paymentMethods || [];
    individual.paymentMethods = individual.paymentMethods.filter(p => p.status === "Active");
    this.dataStore._individual = individual;
    this._individual.next(Object.assign({}, this.dataStore)._individual);
    return individual;
  }

  storeBusiness(response: any) {
    if (response.headers.get("x-auth")) {
      this.storeToken(response.headers.get("x-auth"));
    }
    const business = new BusinessModel(response.body);
    business.paymentMethods = business.paymentMethods || [];
    business.paymentMethods = business.paymentMethods.filter(p => p.status === "Active");
    this.dataStore._business = business;
    this._business.next(Object.assign({}, this.dataStore)._business);
    return business;
  }

  public async getBusiness(organizationId): Promise<BusinessModel> {
    const response = await this.get(`organizations/${organizationId}/businesses/me`);
    return this.storeBusiness(response);
  }

  public async getBusinessBeforeSetPermission(acl: any, path: string) {
    try {
      if (this.role === "Business") {
        const business = await this.getBusiness(this.organization.id);
        this.setPermissions(acl, path);
      }
    } catch (error) {
      console.log(error);
    }
  }

  public async requestCommentForm(organizationId, payload): Promise<any> {
    const response = await this.post(
      `organizations/${organizationId}/requests`,
      payload
    );
    return response.body;
  }

  encryptDataSource(payload) {
    try {
      return CryptoJS.AES.encrypt(
        JSON.stringify(payload),
        this.encryptSecretKey,
        this.aesOptions
      ).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptDataSource(token) {
    try {
      const bytes = CryptoJS.AES.decrypt(
        token,
        this.encryptSecretKey,
        this.aesOptions
      );
      if (bytes.toString()) {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return token;
    } catch (e) {
      console.log(e);
    }
  }

  public async loadConfig(state: string, city: string) {
    // console.log("loadConfig", state, city);
    const config = this.config;
    this.storageService.save("city", city);
    this.storageService.save("state", state);
    if (config && config.state === state && config.name === city) {
      this._config.next(Object.assign({}, this.dataStore)._config);
    } else {
      await this._getConfig(state, city);
    }
  }

  private async _getConfig(state: string, city: string): Promise<any> {
    const name = city.replace(/\s+/g, "-").toLowerCase();
    const randomParam = Math.random();  // Generate a random number
    const url = `organizations/${state.toLowerCase()}/${name}?cache_buster=${randomParam}`;
    const response = await this.get(url);
    const config = response.body;
    // console.log("*** configurations ***", config);
    this.dataStore._config = config;
    this._config.next(Object.assign({}, this.dataStore)._config);
    this.storageService.save("config", config);
    return config;
  }

  public configUpdate(): Observable<any> {
    console.log(`[SOCKET]: Listen for config-update`);
    return this.socket.fromEvent("config-update").pipe(
      map((response: any) => {
        const config: any = response;
        console.log(`[SOCKET]: Receive from 'config-update'`, config);
        return config;
      })
    );
  }

  public async getEstimates(organizationId: string, payload: any): Promise<any> {
    const response = await this.post(
      `organizations/${organizationId}/estimates`,
      payload
    );
    return response.body;
  }

  public async getAlerts() {
    const businessId = this.dataStore._business?.id
    const individualId = this.dataStore._individual?.id
    if (businessId || individualId) {
      const path = individualId ? 'individuals' : 'businesses';
      const response = await this.get(`organizations/${this.organizationId}/${path}/me/notifications?$filter=status eq 'Unread'`)
      if (response) {
        const alerts = response.body.data.length;
        this.dataStore._alerts = alerts;
        this._alerts.next(Object.assign({}, this.dataStore)._alerts);
        return alerts;
      }
    }
  }

  public async addPaymentMethod(organizationId: string, type: string, payload: any): Promise<any> {
    const response = await this.post(`organizations/${organizationId}/${type}/me/payment-methods`, payload);
    return this.storeIndividual(response);
  }

  public async updatePaymentMethod(organizationId: string, type: string, paymentMethodId: string, payload: any): Promise<any> {
    const response = await this.put(`organizations/${organizationId}/${type}/me/payment-methods/${paymentMethodId}`, payload);
    if (type === 'individuals') {
      return this.storeIndividual(response);
    } else {
      return this.storeBusiness(response);
    }
  }

  public async deletePaymentMethod(organizationId: string, type: string, paymentMethodId: string): Promise<any> {
    const response = await this.delete(`organizations/${organizationId}/${type}/me/payment-methods/${paymentMethodId}`);
    if (type === 'individuals') {
      return this.storeIndividual(response);
    } else {
      return this.storeBusiness(response);
    }
  }

  public async startSession() {
    const response = await this.get("session");
    this.sessionService.setSessionId(response.body.sessionId)
  }

  public setPermissions(acl: any, path: string) {
    this.permissionService.flushPermissions();
    path = path || "";

    const config = this.config;

    const initialized = this.permissionService.getPermission("Initialized");

    if (this.isLoggedIn && !initialized) {
      const organization = this.organization
      const state = config.state?.toUpperCase();
      const city = organization.name;
      const role = this.role;
      const permissions = ["Initialized"];

      // console.log("role---->", role, config.state);

      if (role && config.components) {
        permissions.push(...[].concat(acl[`${role}`]));
        if (role === "Individual") {
          if (config.components?.individual?.payments) {
            permissions.push(...[].concat(acl[`${role}:${EComponent.PAYMENTS}`]));
          }
          if (!path.includes("payments")) {
            if (config.components?.individual?.uploadToFile || config.components?.individual?.fillToFile) {
              permissions.push(...[].concat(acl[`${role}:${EComponent.FILING}:${state}`]));
              permissions.push(...[].concat(acl[`${role}:${EComponent.FILING}:${state}:${city}`]));
            }
          }
        }

        if (role === "Business") {
          permissions.push(acl[`${role}`]);
          if (!this.isPending) {
            if (path.includes("payments")) {
              if (config.components?.business?.payments) {
                // console.log(
                //   `${role}:${EComponent.PAYMENTS}`,
                //   acl[`${role}:${EComponent.PAYMENTS}`]
                // );
                if (this.accountNumber) {
                  permissions.push(...[].concat(acl[`${role}:${EComponent.PAYMENTS}`]));
                }
              }
            } else if (path.includes("filings")) {
              // console.log("filings", config.components?.business?.uploadToFile || config.components?.business?.fillToFile);
              if (config.components?.business?.uploadToFile || config.components?.business?.fillToFile) {
                permissions.push(...[].concat(acl[`${role}:${EComponent.FILING}:${state}`]));
                permissions.push(...[].concat(acl[`${role}:${EComponent.FILING}:${state}:${city}`]));
              }

            } else {

              if (config.components?.business?.withholdings) {
                permissions.push(...[].concat(acl[`${role}:${EComponent.WITHHOLDING}:${state}`]));
              }
              if (config.components?.business?.w3) {
                permissions.push(...[].concat(acl[`${role}:${EComponent.W3}`]));
              }
            }
          } else {
            permissions.push(...[].concat(acl[`Pending`]));
          }
        }
        this.permissionService.loadPermissions(permissions);
      }
    }
  }

}
