import {FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';

export const DateRangeValidator = (fromDateField: string, toDateField: string): ValidatorFn => {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const fromDate = formGroup.get(fromDateField)?.value;
    const toDate = formGroup.get(toDateField)?.value;
    if (!fromDate && !toDate) {
      return null;
    }
    if (!fromDate || !toDate || fromDate > toDate) {
      return {dateRangeInvalid: true};
    }
    return null;
  };
};
