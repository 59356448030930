import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {HexaDetailComponent, HexaTitleService, HexaWithPermissions} from "@hexalang/ui/core";
import {EPermission, FormatService} from "@city-tax/shared";
import {Observable} from "rxjs";
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "@city-tax/features/auth";
import {PaymentService} from "../../../../services/payment.service";
import {ToastrService} from "ngx-toastr";
import {NgxPermissionsService} from "ngx-permissions";
import {IndividualPaymentDeleteComponent} from "../../payment-delete/payment-delete.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {IndividualPaymentRecurringEditComponent} from "../recurring-edit/individual-payment-recurring-edit.component";
import {
  IndividualPaymentRecurringCancelComponent
} from "../recurring-cancel/individual-payment-recurring-cancel.component";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'city-tax-feature-individual-payment-recurring',
  templateUrl: './individual-payment-recurring.component.html',
  styleUrls: ['./individual-payment-recurring.component.scss']
})
export class IndividualPaymentRecurringComponent
  extends HexaWithPermissions(HexaDetailComponent)
  implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("actionTemplate", {static: true}) public actionTemplate: TemplateRef<any>;


  public title$;
  public columns: any[] = [];
  public config = this.authService.config;
  public ePermission = EPermission;
  public hasPermission$: Observable<boolean>;
  public payments$: any;
  public payment$: any;

  public type = "ach";
  public isLoading$ = this.paymentService.isLoading$;
  public id;
  public organization = this.authService.organization;

  public bsModalRef: BsModalRef;
  @Input() public redirectPath;

  constructor(
    injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public paymentService: PaymentService,
    public toastrService: ToastrService,
    public permissionService: NgxPermissionsService,
    private titleService: HexaTitleService,
    private translate: TranslateService,
    public formatService: FormatService,
    private modalService: BsModalService,
    private cd: ChangeDetectorRef
  ) {
    super(injector);
    this.payments$ = this.paymentService.payments$;
    this.payment$ = this.paymentService.payment$;
  }

  async ngOnInit() {
    this.titleService.changeTitle(this.translate.instant("Payment.review-of-scheduled-payments"));
    this.translate.onLangChange.subscribe(val => {
      this.titleService.changeTitle(
        this.translate.instant("Payment.review-of-scheduled-payments")
      )
    })
    this.defineColumns();

    this.redirectPath = this.route.snapshot.queryParams["redirect"];
    if (this.route.snapshot.queryParams["redirectPath"]) {
      this.redirectPath = this.route.snapshot.queryParams["redirectPath"] + "/history";
    }
    this.id = this.route.snapshot.params.id;
    await this.getPayments();
  }

  async ngAfterViewInit() {
  }

  public async getPayments() {
    try {
      await this.paymentService.generateRecurring(this.authService.organization.id, this.id);
      await this.paymentService.getPayment(this.authService.organization.id, this.id);


    } catch (error) {
      console.error(error);
    }
  }

  public async submit() {
    await this.paymentService.submitRecurring(this.authService.organization.id, this.id);
    await this.router.navigate([`/individual/payments/create/confirmation`], {queryParams: {id: this.paymentService.id},});
  }

  public cancel() {

  }

  public showModal(type: string, payment: any) {
    let component: any;
    let modelClass: string;
    let ignoreBackdropClick = true;
    const initialState = payment ? {payment} : {};

    switch (type) {
      case 'delete':
        component = IndividualPaymentDeleteComponent;
        modelClass = "modal-sm";
        break;


      case 'edit':
        component = IndividualPaymentRecurringEditComponent;
        modelClass = "modal-md";
        break;


      case 'add':
        component = IndividualPaymentDeleteComponent;
        modelClass = "modal-sm";
        break;

      case 'cancel':
        component = IndividualPaymentRecurringCancelComponent;
        modelClass = "modal-lg";

        break;
    }


    this.bsModalRef = this.modalService.show(component, {
      class: modelClass,
      ignoreBackdropClick: ignoreBackdropClick,
      initialState
    });

    this.bsModalRef.onHide.subscribe(async () => {
      if (type === 'cancel') {
        this.redirectPath = this.redirectPath || '/individual/payments/history';
        this.router.navigate([this.redirectPath]);
      } else {
        await this.getPayments();
      }
    });

  }


  defineColumns() {
    this.columns = [
      {
        prop: "taxYear",
        name: "Tax Year",
        sortable: false,
      },
      {
        prop: "paymentType",
        name: "Payment Type",
        sortable: true,
      },

      {
        prop: "pm.name",
        name: "Payment Method",
        sortable: false,
      },

      {
        prop: "paymentDate",
        name: "Payment Date",
        pipe: {transform: (value: any) => this.formatService.dateFormat(value)},
        sortable: true,
      },
      {
        prop: "paymentReminderDate",
        name: "Payment Reminder Date",
        pipe: {transform: (value: any) => this.formatService.dateFormat(value)},
        sortable: true,
      },
      {
        prop: "amount",
        name: "Amount",
        pipe: {transform: (value: any) => this.formatService.currencyFormat(value)},
        sortable: true,
      },
      {
        prop: "feeAmount",
        name: "Fee",
        pipe: {transform: (value: any) => this.formatService.currencyFormat(value)},
        sortable: true,
      },
      {
        prop: "total",
        name: "Total",
        pipe: {transform: (value: any) => this.formatService.currencyFormat(value)},
        sortable: true,
      },
      {
        name: "",
        cellTemplate: this.actionTemplate,
        sortable: false,
        width: 100,
        required: true,
        draggable: false,
        resizeable: false,
        canAutoResize: false,
        headerClass: "justify-content-end text-truncate-none position-unset",
        cellClass: "justify-content-end text-truncate-none",
      },

    ];
  }

}
