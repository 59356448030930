<div *ngIf="(individualReturn$ | async)?.status === 'Completed'"
    class="card p-3 position-absolute return-filed m-0 text-center">
  {{'Taxpayer.filed' | translate}}
</div>
<div *ngIf="(individualReturn$ | async)?.status === 'Mailed'"
    class="card p-3 position-absolute return-filed m-0 text-center">
  {{'Taxpayer.mailed' | translate}}
</div>

<div class="p-4">
  <div class="card bg-transparent border-0 shadow-none taxpayer-form">
    <div class="row m-0">
      <div *ngIf="(individualReturn$ | async)?.status !=='Not found'" class="col-12">
        <form *ngIf="individualReturn$ | async as individualReturn" [formGroup]="form" novalidate>
          <div class="row align-items-center py-2 bg-white border border-bottom-0">
            <div class="col-3">
              <h2 class="mb-0">CF-1040</h2>
            </div>
            <div class="col-7">
              <div class="text-uppercase text-left py-1">
                <p class="mb-0">{{'Taxpayer.individual-return-due' | translate}} {{ form.get('eFileDeadline').value | utcDate:'MMMM dd, YYYY' }}</p>
              </div>
            </div>
            <div class="col-2 d-flex justify-content-end">
              {{'Taxpayer.how-to-use-this-site' | translate}} <a class="pl-2" href="https://cdn.insourcetax.com/Michigan Fill In Instructions.pdf" target="_help" tooltip="Help">
              <svg class="text-primary" fill="currentColor" height="20" viewBox="0 0 16 16" width="20">
                <path
                    d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 0 1 0-2.098zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z"/>
                <path
                    d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
              </svg>
            </a>
            </div>
          </div>
          <div class="row border bg-white">
            <div class="col-3 p-0 border-right">
              <div class="p-3 border-bottom">
                <div class="row p3">
                  <div class="col-6">
                    <label class="form-label pb-2">{{'Taxpayer.tax-payers-ssn' | translate}}</label>
                    <div class="font-weight-bold">
                      XXX-XX-{{ individualReturn?.taxpayer?.ssn.substring(5, 9) }}
                    </div>
                    <!--                    <ui-input-selector-->
                    <!--                        [controlForm]="form"-->
                    <!--                        [data]="individualReturn?.review"-->
                    <!--                        class="w-100"-->
                    <!--                        controlClass="form-control bg-light"-->
                    <!--                        controlName="ssn"-->
                    <!--                        groupName="taxpayer"-->
                    <!--                        isSSN="true"-->
                    <!--                        placeholder="000-00-0000"-->
                    <!--                        required="true"-->
                    <!--                    >-->
                    <!--                    </ui-input-selector>-->
                  </div>


                  <div class="col-6">
                    <label class="form-label pb-2">{{'Taxpayer.tax-payers-full-name' | translate}}</label>
                    <div class="font-weight-bold"> {{ individualReturn?.taxpayer?.name }}</div>
                    <!--                    <ui-input-selector-->
                    <!--                        [controlForm]="form"-->
                    <!--                        [data]="individualReturn?.review"-->
                    <!--                        class="w-100"-->
                    <!--                        controlClass="form-control bg-light"-->
                    <!--                        controlName="name"-->
                    <!--                        groupName="taxpayer"-->
                    <!--                    >-->
                    <!--                    </ui-input-selector>-->
                  </div>
                </div>
              </div>

              <div class="p-3 border-bottom">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0">{{'Taxpayer.taxpayers-phone-number' | translate}}</p>
                    <ui-input-selector [controlForm]="form" [data]="individualReturn?.review"
                        class="w-100"
                        controlClass="form-control bg-light text-center"
                        controlName="phone" groupName="taxpayer"
                        mask="(000) 000-0000"
                        placeholder="(000) 000-0000">
                    </ui-input-selector>
                  </div>
                  <div class="col-6">
                    <p class="mb-0">{{'Taxpayer.taxpayers-occupation' | translate}}</p>
                    <ui-input-selector [controlForm]="form" [data]="individualReturn?.review"
                        controlClass="form-control bg-light text-center"
                        controlName="occupation" groupName="taxpayer">
                    </ui-input-selector>
                  </div>
                </div>
              </div>

              <div *ngIf="form.get('filing').value!=='S'" class="p-3 border-bottom">
                <div class="row">
                  <div *ngIf="form.get('filing').value !=='S'" class="col-6">
                    <p class="mb-0"> {{'Taxpayer.spouse-ssn' | translate}}: <span class="text-danger pl-1">*</span></p>
                    <ng-container
                        *ngIf="individualReturn?.spouse?.ssn && individualReturn?.spouse?.individualId">
                      <div> XXX-XX-{{ individualReturn?.spouse?.ssn.substring(5, 9) }}</div>
                    </ng-container>
                    <ng-container
                        *ngIf="!individualReturn?.spouse?.ssn || !individualReturn?.spouse?.individualId">
                      <ui-input-selector
                          [controlForm]="form"
                          [data]="individualReturn?.review"
                          class="w-100"
                          controlClass="form-control bg-light text-center"
                          controlName="ssn"
                          groupName="spouse"
                          isSSN="true"
                          mask="000-00-0000"
                          placeholder="000-00-0000"
                          required="true"
                      >
                      </ui-input-selector>
                    </ng-container>
                  </div>
                  <div *ngIf="form.get('filing').value!=='S'" class="col-6">
                    <p class="mb-0"> {{'Taxpayer.spouse-name' | translate}}: <span class="text-danger pl-1">*</span></p>
                    <ng-container
                        *ngIf="individualReturn?.spouse?.name && individualReturn?.spouse?.individualId">
                      <div>{{ individualReturn?.spouse?.name }}</div>
                    </ng-container>
                    <ng-container
                        *ngIf="!individualReturn?.spouse?.name || !individualReturn?.spouse?.individualId">
                      <ui-input-selector
                          [controlForm]="form"
                          [data]="individualReturn?.review"
                          class="w-100"
                          controlClass="form-control bg-light text-center"
                          controlName="name"
                          groupName="spouse"
                          required="true"
                      >
                      </ui-input-selector>
                    </ng-container>
                  </div>
                  <div class="col-12 mt-2" *ngIf="form.get('filing').value === 'MJ' && form.get('spouse.individualId').value">
                    <a href="javascript:" (click)="switchTaxpayerSpouse(individualReturn)">{{'Taxpayer.switch-to-primary-taxpayer' | translate}}</a>
                  </div>
                </div>
                <div *ngIf="form.get('filing').value ! =='S' && individualReturn?.spouse?.individualId"
                    class="row">
                  <div class="col-12">
                    <a (click)="unlink()" href="javascript:;">
                      <svg class="bi bi-link" fill="currentColor" height="16" viewBox="0 0 16 16"
                          width="16" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
                        <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>
                        <path d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
                            fill="red" fill-rule="evenodd"/>
                      </svg>
                      {{'Taxpayer.unlink-spouse' | translate}}
                    </a>
                  </div>
                </div>
              </div>

              <div *ngIf="form.get('filing')?.value !== 'S'" class="p-3 border-bottom">
                <div class="row">
                  <div class="col-6">
                    <p class="mb-0">{{'Taxpayer.spouse-phone-number' | translate}}</p>
                    <ui-input-selector [controlForm]="form" [data]="individualReturn?.review"
                        controlClass="form-control bg-light text-center"
                        controlName="phone"
                        groupName="spouse"
                        mask="(000) 000-0000"
                        placeholder="(000) 000-0000">
                    </ui-input-selector>
                  </div>
                  <div class="col-6">
                    <p class="mb-0">{{'Taxpayer.spouse-occupation' | translate}}</p>
                    <ui-input-selector [controlForm]="form" [data]="individualReturn?.review"
                        controlClass="form-control bg-light text-center"
                        controlName="occupation" groupName="spouse">
                    </ui-input-selector>
                  </div>
                </div>
              </div>

              <div class="p-3 border-bottom">
                  <div class="row">
                    <div class="col-6" formGroupName="taxpayer">
                      <label class="form-label" for="taxpayer-birthdate">{{'Taxpayer.taxpayers-date-of-birth' | translate}}</label>
                      <input
                        id="taxpayer-birthdate"
                        class="form-control"
                        formControlName="birthdate"
                        [class.is-invalid]="form.get('taxpayer.birthdate')?.invalid && (form.get('taxpayer.birthdate')?.dirty || form.get('taxpayer.birthdate')?.touched)"
                        [maxDate]="maxDDate"
                        type="text"
                        [bsConfig]="{ containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                        placeholder="mm/dd/yyyy"
                        [attr.aria-label]="'Taxpayer.birthdate' | translate"
                        [attr.aria-invalid]="form.get('taxpayer.birthdate')?.invalid && (form.get('taxpayer.birthdate')?.dirty || form.get('taxpayer.birthdate')?.touched) ? 'true' : 'false'"
                        bsDatepicker
                    />
                    </div>
                  <div class="col-6">
                    <ng-container *ngIf="form.get('filing')?.value !== 'S'" formGroupName="spouse">
                      <label class="form-label" for="spouse-birthdate">{{'Taxpayer.spouse-date-of-birth' | translate}}</label>
                      <input
                        id="spouse-birthdate"
                        [class.is-invalid]="form.get('spouse.birthdate')?.invalid && (form.get('spouse.birthdate')?.dirty || form.get('spouse.birthdate')?.touched)"
                        [maxDate]="maxDDate"
                        class="form-control"
                        formControlName="birthdate"
                        [bsConfig]="{ containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                        [attr.aria-label]="'spouse-date-of-birth' | translate"
                        [attr.aria-invalid]="form.get('spouse.birthdate')?.invalid && (form.get('spouse.birthdate')?.dirty || form.get('spouse.birthdate')?.touched) ? 'true' : 'false'"
                        bsDatepicker
                        placeholder="mm/dd/yyyy"
                      />
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="p-3">
                <div class="row">
                  <div class="col-6">
                    <label class="form-label">{{'Taxpayer.check-box-if-deceased' | translate}}</label>
                  </div>
                  <div class="col-6">
                    <label class="form-label">{{'Taxpayer.date-of-death' | translate}}</label>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="row m-0 px-4" formGroupName="taxpayer">
                      <div class="col-6 px-0">
                        <input
                            class="custom-control-input"
                            formControlName="deceased"
                            id="taxpayer-deceased"
                            type="checkbox"
                        />
                        <label class="custom-control-label"
                            for="taxpayer-deceased">{{'Taxpayer.taxpayer' | translate}}</label>
                      </div>
                      <div *ngIf="form.get('taxpayer.deceased').value" class="col-6 px-0">
                        <input
                            id="dateOfDeath"
                            [class.is-invalid]="form.get('taxpayer.dateOfDeath')?.invalid && (form.get('taxpayer.dateOfDeath')?.dirty || form.get('taxpayer.dateOfDeath')?.touched)"
                            [maxDate]="maxDDate"
                            class="form-control"
                            formControlName="dateOfDeath"
                            type="text"
                            [bsConfig]="{ containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                            [attr.aria-label]="'Taxpayer.date-of-death' | translate"
                            [attr.aria-required]="'true'"
                            [attr.aria-invalid]="form.get('taxpayer.dateOfDeath')?.invalid && (form.get('taxpayer.dateOfDeath')?.dirty || form.get('taxpayer.dateOfDeath')?.touched) ? 'true' : 'false'"
                            bsDatepicker
                            placeholder="mm/dd/yyyy"
                        />
                      </div>
                    </div>
                    <div *ngIf="form.get('filing')?.value !== 'S'"
                        class="row m-0 px-4  align-items-baseline" formGroupName="spouse">
                      <div class="col-6 px-0">
                        <input
                            class="custom-control-input"
                            formControlName="deceased"
                            id="spouse-deceased"
                            type="checkbox"
                        /> <label for="spouse-deceased" class="custom-control-label"
                          for="spouse-deceased">{{'Taxpayer.spouse' | translate}}</label>
                      </div>
                      <div *ngIf="form.get('spouse.deceased').value" class="col-6 px-0">
                        <input
                            id="dateOfDeath"
                            [class.is-invalid]="form.get('spouse.dateOfDeath')?.invalid && (form.get('spouse.dateOfDeath')?.dirty || form.get('spouse.dateOfDeath')?.touched)"
                            [maxDate]="maxDDate"
                            class="form-control"
                            formControlName="dateOfDeath"
                            type="text"
                            [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                            [attr.aria-label]="'Taxpayer.date-of-death' | translate"
                            [attr.aria-required]="'true'"
                            [attr.aria-invalid]="form.get('spouse.dateOfDeath')?.invalid && (form.get('spouse.dateOfDeath')?.dirty || form.get('spouse.dateOfDeath')?.touched) ? 'true' : 'false'"
                            bsDatepicker
                            placeholder="mm/dd/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="col-5 border-right">
              <div class="row border-bottom m-0">
                <div class="col-9 border-right p-3">
                  <label for="addressLine1" class="form-label"> {{'Taxpayer.present-home-address' | translate}} </label>
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="addressLine1"
                      groupName="taxpayer.address"
                      id="addressLine1"
                  >
                  </ui-input-selector>
                </div>
                <div class="col-3 p-3">
                  <label for="aptNumber" class="form-label">{{'Taxpayer.apt-no' | translate}}</label>
                  <ui-input-selector
                      id="aptNumber"
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="aptNumber"
                      groupName="taxpayer.address"
                  >
                  </ui-input-selector>
                </div>
              </div>
              <div class="row m-0 p-3 border-bottom">
                <div class="col-12 p-0">
                  <label class="form-label" for="addressLine2"> {{'Taxpayer.address-line-2' | translate}} </label>
                </div>
                <div class="col-12 p-0">

                  <ui-input-selector
                      id="addressLine2"
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="addressLine2"
                      groupName="taxpayer.address"
                  >
                  </ui-input-selector>
                </div>
              </div>
              <div class="row border-bottom m-0">
                <div class="col-6 border-right p-3">
                  <label class="form-label" for="city">{{'Taxpayer.city-town-or-post' | translate}}</label>
                  <ui-input-selector
                      id="city"
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="city"
                      groupName="taxpayer.address"
                  >
                  </ui-input-selector>
                </div>
                <div class="col-3 p-3 border-right">
                  <label class="form-label" for="state">{{'Taxpayer.state' | translate}}</label>
                  <ui-input-selector
                      id="state"
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="state"
                      groupName="taxpayer.address"
                  >
                  </ui-input-selector>
                </div>
                <div class="col-3 p-3">
                  <label class="form-label" for="postalCode">{{'Taxpayer.zip-code' | translate}}</label>
                  <ui-input-selector
                      id="postalCode"
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="postalCode"
                      groupName="taxpayer.address"
                  >
                  </ui-input-selector>
                </div>
              </div>
              <div class="row m-0" formGroupName="foreignAddress">
                <div class="col-4 border-right p-xl-2 p-xxl-3">
                  <label class="form-label" for="foreignCountry">{{'Taxpayer.foreign-country-name' | translate}}</label>
                  <ui-input-selector
                      id="foreignCountry"
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="country"
                      groupName="foreignAddress"
                  >
                  </ui-input-selector>
                </div>
                <div class="col-4 border-right p-xl-2 p-xxl-3">
                  <label for="foreignProvince" class="form-label">{{'Taxpayer.foreign-province-county' | translate}}</label>

                  <ui-input-selector
                      id="foreignProvince"
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="provinceOrState"
                      groupName="foreignAddress"
                  >
                  </ui-input-selector>
                </div>
                <div class="col-4 p-xl-2 p-xxl-3">
                  <label for="foreignPostalCode"  class="form-label">{{'Taxpayer.foreign-postal-code' | translate}}</label>
                  <ui-input-selector
                      id="foreignPostalCode"
                      [controlForm]="form"
                      [data]="individualReturn?.review"
                      class="w-100"
                      controlClass="form-control bg-light"
                      controlName="postalCode"
                      groupName="foreignAddress"
                  >
                  </ui-input-selector>
                </div>
              </div>
            </div>
            <div class="col-4">

              <div class="row m-0">
                <div class="pt-3 px-3">
                  <label class="form-label">
                      <span class="font-weight-bold text-uppercase">
                        {{'Taxpayer.residence-status' | translate}}
                      </span> </label>
                </div>
              </div>

              <div class="pl-2 pb-1">
                <div class="row">
                  <div class="col-12">
                    <div class="row m-0 pl-4">
                      <div class="col-3">
                        <input
                            [class.is-invalid]="form.get('residency').invalid"
                            class="custom-control-input"
                            formControlName="residency"
                            id="resident"
                            name="residency"
                            type="radio"
                            value="R"
                            [attr.aria-invalid]="form.get('residency').invalid"
                        /> <label class="custom-control-label" for="resident"> {{'Taxpayer.resident' | translate}} </label>
                      </div>
                      <div class="col-4">
                        <input
                            [class.is-invalid]="form.get('residency').invalid"
                            class="custom-control-input"
                            formControlName="residency"
                            id="nonresident"
                            name="residency"
                            type="radio"
                            value="N"
                            [attr.aria-invalid]="form.get('residency').invalid"
                        /> <label class="custom-control-label" for="nonresident">
                        {{'Taxpayer.non-resident' | translate}} </label>
                      </div>
                      <div class="col-5">
                        <input
                            [class.is-invalid]="form.get('residency').invalid"
                            class="custom-control-input"
                            formControlName="residency"
                            id="part-year"
                            name="residency"
                            type="radio"
                            value="P"
                            [attr.aria-invalid]="form.get('residency').invalid"
                        /> <label class="custom-control-label text-nowrap" for="part-year">
                        {{'Taxpayer.part-year-resident' | translate}} </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ng-container *ngIf="form.get('residency')?.value === 'P'">
                <p class="pt-2 px-3 pt-3">
                  {{'Taxpayer.part-year-resident-date' | translate}}
                </p>
                <div class="row m-0 pb-3 border-bottom">
                  <div class="col-12 d-flex align-items-center">
                    <label for="partYearFromDate" class="col-sm-2 col-form-label pr-1"> <small class="pr-1">
                      {{ "Taxpayer.from" | translate }}
                    </small>
                    </label>
                    <div class="col-sm-10">
                      <input
                          id="partYearFromDate"
                          [class.is-invalid]="form.get('partYearFromDate').invalid && (form.get('partYearFromDate')?.dirty || form.get('partYearFromDate')?.touched)"
                          class="form-control bg-light text-left custom-height"
                          [maxDate]="maxDate"
                          [minDate]="minDate"
                          [bsConfig]="{containerClass: 'theme-dark-blue',  showWeekNumbers: false}"
                          formControlName="partYearFromDate"
                          [attr.aria-label]="'Taxpayer.from' | translate"
                          [attr.aria-invalid]="form.get('partYearFromDate').invalid && (form.get('partYearFromDate')?.dirty || form.get('partYearFromDate')?.touched) ? 'true' : 'false'"
                          type="text"
                          placeholder="mm/dd/yyyy"
                          bsDatepicker
                      />


                    </div>
                  </div>
                  <div class="col-12 d-flex align-items-center">
                    <label for="partYearToDate" class="col-sm-2 col-form-label pr-1"> <small class="pr-1">{{ 'Taxpayer.to' | translate}}</small>
                    </label>
                    <div class="col-sm-10">
                      <input
                          id="partYearToDate"
                          [class.is-invalid]="form?.get('partYearToDate')?.invalid && (form?.get('partYearToDate')?.dirty || form?.get('partYearToDate')?.touched)"
                          class="form-control bg-light text-left custom-height"
                          formControlName="partYearToDate"
                          type="text"
                          [maxDate]="maxDate"
                          [minDate]="minDate"
                          [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                          [attr.aria-label]="'Taxpayer.to' | translate"
                          [attr.aria-invalid]="form?.get('partYearToDate')?.invalid && (form?.get('partYearToDate')?.dirty || form?.get('partYearToDate')?.touched) ? 'true' : 'false'"
                          placeholder="mm/dd/yyyy"
                          bsDatepicker
                      >

                    </div>
                  </div>
                </div>
              </ng-container>

              <div class="row m-0">
                <div class="pt-3 px-3">
                  <label class="form-label">
                    <span class="font-weight-bold text-uppercase">{{'Taxpayer.filing-status' | translate}}</span>
                  </label>
                </div>
              </div>


              <div class="pl-2 pb-1">
                <div class="row">
                  <div class="col-12">
                    <div class="row m-0 pl-4">
                      <div class="col-3">
                        <input
                            [class.is-invalid]="form.get('filing').invalid"
                            class="custom-control-input"
                            formControlName="filing"
                            id="single"
                            name="filing"
                            type="radio"
                            value="S"
                        />
                        <label class="custom-control-label" for="single">
                          {{'Taxpayer.single' | translate}}
                        </label>
                      </div>
                      <div class="col-4">
                        <input
                            [class.is-invalid]="form.get('filing').invalid"
                            class="custom-control-input"
                            formControlName="filing"
                            id="joint"
                            name="filing"
                            type="radio"
                            value="MJ"
                        /> <label class="custom-control-label" for="joint"
                      >{{'Taxpayer.married-filing-jointly' | translate}}</label
                      >
                      </div>
                      <div class="col-5">
                        <input
                            [class.is-invalid]="form.get('filing').invalid"
                            class="custom-control-input"
                            formControlName="filing"
                            id="full"
                            name="filing"
                            type="radio"
                            value="MS"
                        /> <label class="custom-control-label" for="full"> {{'Taxpayer.married-filing-separately' | translate}} </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="p-3"></div>
              <div class="p-3">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label id="emailLabel" class="small font-weight-medium text-muted text-uppercase">
                        {{'Taxpayer.email' | translate}}:
                      </label>
                      <ng-container *ngIf="individualReturn?.taxpayer?.email" aria-labelledby="emailLabel">
                        <div> {{ formatEmail(individualReturn?.taxpayer?.email) }}</div>
                      </ng-container>
                      <ng-container *ngIf="!individualReturn?.taxpayer?.email">
                        <ui-input-selector
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            class="w-100"
                            controlClass="form-control bg-light"
                            controlName="email"
                            groupName="taxpayer"
                            aria-labelledby="emailLabel"
                        >
                        </ui-input-selector>
                      </ng-container>
                      <div class="pt-1 pl-4" formGroupName="taxpayer">
                        <input
                            class="custom-control-input"
                            formControlName="emailNotifications"
                            id="emailNotifications"
                            type="checkbox"
                        />
                        <label class="custom-control-label" for="emailNotifications">{{'Taxpayer.email-notifications' | translate}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-3 pb-1">
                <div class="row">
                  <div class="col-12">
                    <div class="row m-0 px-4">
                      <div class="col-12 px-0">
                        <input
                            class="custom-control-input"
                            formControlName="federalForm1310"
                            id="federalForm1310"
                            type="checkbox"
                        />
                        <label class="custom-control-label" for="federalForm1310">{{'Taxpayer.federal-form-1310' | translate}}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="px-3 pb-1">
                <div class="row">
                  <div class="col-12">
                    <div class="row m-0 px-4">
                      <div class="col-12 px-0">
                        <input
                            class="custom-control-input"
                            formControlName="itemizedDeductions"
                            id="itemizedDeductions"
                            type="checkbox"
                        />
                        <label class="custom-control-label" for="itemizedDeductions">Itemized
                          {{'Taxpayer.deductions-on-your-Federal-tax-return' | translate}} {{ individualReturn?.taxYear }}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--              <div class="row ">-->
              <!--                <div class="pt-3 px-3">-->
              <!--                  <label class="form-label">-->
              <!--                      <span class="font-weight-bold text-uppercase">-->
              <!--                        Total number of exemptions-->
              <!--                      </span>-->
              <!--                  </label>-->
              <!--                </div>-->
              <!--              </div>-->

              <!--              <div class="px-3 pb-1">-->
              <!--                <div class="row">-->
              <!--                  <div class="col-6">-->
              <!--                    <ui-input-selector [controlForm]="form"-->
              <!--                                       [data]="individualReturn?.review"-->
              <!--                                       controlClass="form-control bg-light text-center"-->
              <!--                                       controlName="numberOfExemptions"-->
              <!--                                       isNumeric="true"-->
              <!--                                       [decimals]="0">-->
              <!--                    </ui-input-selector>-->
              <!--                  </div>-->
              <!--                  <div class="col-6">-->

              <!--                  </div>-->
              <!--                </div>-->
              <!--              </div>-->


            </div>

            <table class="table mb-0" formGroupName="thirdParty">
              <tbody>
              <tr>
                <td class="border-right" colspan="6">
                  <p class="mb-0">
                      <span class="text-uppercase font-weight-bold">
                        {{'Taxpayer.third-party-designee' | translate}}
                      </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td class="d-block mb-0 text-nowrap">
                    <span>
                      {{'Taxpayer.discuss-this-return-with-income-tax-office' | translate}}
                    </span>
                  <span class="align-items-center pl-5">
                      <input class="custom-control-input" formControlName="designee" id="third-party-yes"
                          name="designee"
                          type="radio" value="yes"/> <label class="custom-control-label text-nowrap"
                      for="third-party-yes">{{'Taxpayer.yes-complete-the-following' | translate}}</label>
                    </span>
                  <span class="align-items-center pl-5">
                      <input class="custom-control-input" formControlName="designee" id="third-party-no" name="designee"
                          type="radio" value="no"/> <label class="custom-control-label"
                      for="third-party-no">{{'Taxpayer.no' | translate}}</label>
                    </span>
                </td>
              </tr>
              </tbody>
            </table>
            <table *ngIf="form.get('thirdParty.designee').value =='yes'" class="table mb-0"
                formGroupName="thirdParty">
              <tbody>
              <tr>
                <td class="row mb-0 text-nowrap">
                     <span class=" align-items-center col-4 ">
                       <span>{{'Taxpayer.designees-name' | translate}}</span>
                        <ui-input-selector [controlForm]="form" [data]="individualReturn?.review"
                            controlClass="form-control bg-light text-center"
                            controlName="name" groupName="thirdParty">
                        </ui-input-selector>
                     </span>
                  <span class="align-items-center col-4">
                       <span>{{'Taxpayer.phone-no' | translate}}</span>
                       <ui-input-selector [controlForm]="form" [data]="individualReturn?.review"
                           controlClass="form-control bg-light text-center" controlName="phoneNumber"
                           groupName="thirdParty"
                           mask="(000) 000-0000" placeholder="(000) 000-0000">
                       </ui-input-selector>
                    </span>
                  <span class="align-items-center col-4">
                       <span>{{'Taxpayer.personal-identification-number' | translate}}</span>
                       <ui-input-selector [controlForm]="form" [data]="individualReturn?.review"
                           controlClass="form-control bg-light text-center"
                           controlName="pin" groupName="thirdParty">
                       </ui-input-selector>
                    </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <div *ngIf="!(isDisabled$|async)  && city?.components?.individual?.uploadToFile" class="row py-3">
            <div class="col-lg-6 d-flex">
              <div class="card d-flex flex-column p-3 mb-1 pb-1 h-100">
                <div class="flex-grow-1">
                  <h3 class="mb-0 text-uppercase font-weight-bold">{{'Taxpayer.upload-to-file' | translate}}</h3>
                  <div class="form-content pt-3 font-weight-bold">
                    <p class="text-danger font-weight-bold">{{'Taxpayer.do-not-upload-handwritten-returns' | translate}}</p>
                    <p>
                     {{'Taxpayer.upload-file-info' | translate}}
                    </p>
                    <p>
                      {{ "Taxpayer.include-pages-wages" | translate }}
                    </p>    
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 d-flex justify-content-end">
                    <button (click)="upload()" 
                        [disabled]="(isLoading$ | async)"
                        class="btn btn-primary font-weight-600" 
                        id="efile"
                        tabindex="0" 
                        role="button"
                        [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
                        [attr.aria-busy]="(isLoading$ | async)" 
                        [attr.aria-label]="(isLoading$ | async) ? 'Uploading, please wait' : 'Upload file'" >
                      <ui-spinner [class.active]="isLoading$ | async"><span>{{'Taxpayer.upload' | translate}}</span>
                      </ui-spinner>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="!(isDisabled$|async) && city?.components?.individual?.fillToFile" class="col-lg-6 d-flex">
              <div class="card d-flex flex-column p-3 mb-1 pb-1 h-100">
                <div class="flex-grow-1">
                  <h3 class="mb-0 text-uppercase font-weight-bold">{{'Taxpayer.fill-in-to-file' | translate}}</h3>
                  <div class="form-content pt-3 font-weight-bold">
                    <p>
                      {{'Taxpayer.fill-in-to-file-info' | translate:{cityName: city.name} }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 d-flex justify-content-end">
                    <button (click)="next()" 
                        [disabled]="isLoading$ | async"
                        class="btn btn-primary font-weight-600" 
                        id="next"
                        tabindex="0" 
                        role="button" 
                        [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'" 
                        [attr.aria-busy]="isLoading$ | async" 
                        [attr.aria-label]="(isLoading$ | async) ? 'Loading, please wait' : 'Fill In'">
                      <ui-spinner [class.active]="isLoading$ | async">{{'Taxpayer.fill-in' | translate}}</ui-spinner>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="(isDisabled$|async) || !city?.components?.individual?.uploadToFile" class="row border-top py-3">
            <div class="col-12 d-flex justify-content-end">
              <button (click)="next()" 
                 [disabled]="isLoading$ | async" 
                 class="btn btn-primary font-weight-600" 
                 id="submit"
                 role="button" 
                 [attr.tabindex]="(isLoading$ | async) ? -1 : 0" 
                 [attr.aria-label]="'Buttons.next-btn' | translate" 
                 [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'" 
                 [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'">
                <span>
                  {{
                    "Buttons.next-btn" | translate
                  }}
                </span>
              </button>
            </div>
          </div>

        </form>
      </div>
      <div class="col-12">
        <ng-container *ngIf="(individualReturn$ | async)?.status ==='Not found'">
          <div class="row align-items-center">
            <div class="col-md-12">
              <div class="d-flex align-items-center justify-content-center">
                <h5 class="text-center mb-0 font-weight-bolder"> {{ individualReturn?.taxYear }}
                  {{'Taxpayer.individual-income-tax' | translate}} </h5>
              </div>
            </div>
          </div>
          <div class="row align-items-center">
          </div>
          <div class="row py-3 d-flex text-center align-items-center justify-content-center">
            <h5> {{'Taxpayer.no-tax-return-found' | translate}}</h5>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
