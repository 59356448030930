<form
    (submit)="create()"
    [formGroup]="form"
    class="d-flex flex-column flex-fill"
    novalidate
>
  <div class="modal-header align-items-center">
    <h4 class="modal-title pull-left">{{'Payment.create-payment-method' | translate}}</h4>
    <a (click)="bsModalRef.hide()" class="text-muted" href="javascript:">
      <svg class="icon" viewBox="0 0 24 24">
        <path
            d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
            stroke="none"
        />
      </svg>
    </a>
  </div>

  <div class="scrollable scrollable-y">
    <div class="scrollable-main">
      <div class="scrollable-content">
        <div class="modal-body">
          <div class="form-group">
            <label for="name" class="small font-weight-medium text-muted text-uppercase">
              {{'Payment.name' | translate}} <span class="text-danger pl-1">*</span>
            </label>
            <input id="name"
                [ngClass]="{'is-invalid': form.controls['name']?.touched && form.controls['name'].errors }"
                [attr.aria-invalid]="form.controls['name']?.touched && form.controls['name'].errors ? 'true' : 'false'"
                class="form-control"
                formControlName="name"
                type="text"/>
          </div>
          <div class="form-group">
            <div class="form-group pl-4">
              <input
                  class="custom-control-input"
                  formControlName="isDefault"
                  id="defaultPaymentMethod"
                  type="checkbox"
              />
              <label class="custom-control-label" for="defaultPaymentMethod">
                {{'Payment.default-payment-method' | translate}}
              </label>
            </div>
          </div>
          <tabset type="tabs nav-tabs-line nav-fill">
            <tab *ngIf="this.hasAch" (selectTab)="setPaymentMethodType('ach')">
              <ng-template tabHeading>
                <span class="font-weight-medium text-uppercase">ACH</span>
              </ng-template>
              <div class="card border-top-0">
                <div class="p-3">
                  <div id="bank">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <!-- ui-mask-show maskChar="*" -->
                        <div class="form-group">
                          <label class="font-weight-600" for="routingNumber">
                            {{ "CommonForm.routing-number-label" | translate }}
                            <span class="text-danger pl-1">*</span>
                          </label>  
                          <div class="input-group" formGroupName="bank">
                            <input id="routingNumber"
                                (change)="strip(form.get('bank.routingNumber'))"
                                (displayCounter)="displayCounter($event, 'bank.routingNumber')"
                                (keypress)="onlyNumberKey($event)"
                                [class.is-invalid]="form.get('bank.routingNumber').invalid && (form.get('bank.routingNumber').dirty || form.get('bank.routingNumber').touched)"
                                [attr.aria-invalid]="form.get('bank.routingNumber').invalid && (form.get('bank.routingNumber').dirty || form.get('bank.routingNumber').touched) ? 'true' : 'false'"
                                class="form-control"
                                formControlName="routingNumber"
                                maskChar="*"
                                type="text"
                                ui-mask-show/>
                          </div>
                          <div *ngIf="form.get('bank.routingNumber').touched && form.get('bank.routingNumber').errors" class="invalid-feedback" style="display: block">
                            <div *ngIf="form.get('bank.routingNumber').errors['required'] ||form.get('bank.routingNumber').errors['pattern']">
                              {{ "CommonForm.routing-number-required"| translate }}
                            </div>
                            <div *ngIf="form.get('bank.routingNumber').errors['mustMatch']">
                              {{ "CommonForm.routing-number-not-valid"| translate }}
                            </div>
                            <div *ngIf="form.get('bank.routingNumber').errors['invalidRoutingNumber'] || form.get('bank.routingNumber').errors['lengthError'] ">
                              {{ "CommonForm.routing-number-not-valid"| translate }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="confirmRoutingNumber" class="font-weight-600">{{ "CommonForm.confirm-routing-number" | translate }} <span class="text-danger pl-1">*</span></label>
                          <div class="input-group">
                            <input id="confirmRoutingNumber"
                                (change)="strip(form.get('confirmRoutingNumber'))"
                                (displayCounter)="displayCounter($event, 'confirmRoutingNumber')"
                                (keypress)="onlyNumberKey($event)"
                                class="form-control"
                                formControlName="confirmRoutingNumber"
                                maskChar="*"
                                type="text"
                                ui-mask-show
                            />
                          </div>

                          <div *ngIf="form.get('confirmRoutingNumber').touched && form.get('confirmRoutingNumber').errors" class="invalid-feedback" style="display: block">
                            <div *ngIf="form.get('confirmRoutingNumber').errors['required']">
                              {{ "CommonForm.routing-number-required"| translate }}
                            </div>
                            <div *ngIf="form.get('confirmRoutingNumber').errors['mustMatch']">
                              {{ "CommonForm.routing-number-not-valid"| translate }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label class="font-weight-600" for="accountNumber">
                            {{ "CommonForm.account-number-label" | translate }}
                          </label><span class="text-danger pl-1">*</span>
                          <div class="input-group" formGroupName="bank">
                            <input id="accountNumber"
                                (change)="strip(form.get('bank.accountNumber'))"
                                (displayCounter)="displayCounter($event, 'bank.accountNumber')"
                                (keypress)="onlyNumberKey($event)"
                                [class.is-invalid]=" form.get('bank.accountNumber').invalid && (form.get('bank.accountNumber').dirty || form.get('bank.accountNumber').touched)"
                                [attr.aria-invalid]="form.get('bank.accountNumber').invalid && (form.get('bank.accountNumber').dirty || form.get('bank.accountNumber').touched) ? 'true' : 'false'"
                                class="form-control"
                                formControlName="accountNumber"
                                maskChar="*"
                                type="text"
                                ui-mask-show
                            />
                          </div>
                          <div *ngIf="form.get('bank.accountNumber').touched && form.get('bank.accountNumber').errors " class="invalid-feedback" style="display: block">
                            <div *ngIf="form.get('bank.accountNumber').errors['required']">
                              {{ "CommonForm.account-number-required"| translate }}
                            </div>
                            <div *ngIf="form.get('bank.accountNumber').errors[ 'mustMatch'  ] || form.get('bank.accountNumber').errors['pattern']">
                              {{ "CommonForm.account-number-not-valid"| translate }}
                            </div>
                            <div *ngIf="form.get('bank.accountNumber').errors['invalidAccountNumber'] || form.get('bank.accountNumber').errors['lengthError']">
                              {{ "CommonForm.account-number-not-valid"| translate }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="confirmAccountNumber" class="font-weight-600">
                            {{ "CommonForm.confirm-account-number-label"| translate }}
                          </label> <span class="text-danger pl-1">*</span>
                          <div class="input-group">
                            <input
                                id="confirmAccountNumber"
                                (change)="strip(form.get('confirmAccountNumber'))"
                                (displayCounter)="displayCounter($event, 'confirmAccountNumber')"
                                (keypress)="onlyNumberKey($event)"
                                class="form-control"
                                formControlName="confirmAccountNumber"
                                maskChar="*"
                                type="text"
                                ui-mask-show
                            />
                          </div>

                          <div *ngIf="form.get('confirmAccountNumber').touched && form.get('confirmAccountNumber').errors"
                              class="invalid-feedback"
                              style="display: block">
                            <div *ngIf="form.get('confirmAccountNumber').errors['required']">
                              {{ "CommonForm.account-number-required"| translate }}
                            </div>
                            <div *ngIf="form.get('confirmAccountNumber').errors['mustMatch']">
                              {{ "CommonForm.account-number-not-valid"| translate }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group" formGroupName="bank">
                      <label>{{ "CommonForm.account-type-label" | translate }} <span class="text-danger pl-1">*</span></label>
                      <label *ngFor="let rad of accountType"
                          class="radio-inline">
                        <input 
                            [class.is-invalid]=" form.get('bank.accountType')?.invalid &&(form.get('bank.accountType')?.dirty ||form.get('bank.accountType')?.touched)"
                            [attr.aria-invalid]="form.get('bank.accountType')?.invalid &&(form.get('bank.accountType')?.dirty ||form.get('bank.accountType')?.touched) ? 'true' : 'false'"
                            class="radio m-1 text-sm-start"
                            formControlName="accountType"
                            name="accountType"
                            type="radio"
                            value="{{ rad.value }}"
                        />{{ rad.label | translate }}
                      </label>
                      <div *ngIf="form.get('bank.accountType').touched &&form.get('bank.accountType').errors"
                          class="invalid-feedback"
                          style="display: block">
                        <div *ngIf="form.get('bank.accountType').errors['required']">
                          {{ 'Payment.account-type-required' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
            <tab *ngIf="this.hasCC" (selectTab)="setPaymentMethodType('card')">
              <ng-template tabHeading>
                <span class="font-weight-medium text-uppercase">{{ 'Payment.credit-card' | translate }}</span>
              </ng-template>
              <div class="d-flex justify-content-between my-3 w-100">
                <div
                  class="d-flex flex-column custom-tab mr-2 w-50"
                  [ngClass]="{'active': activeTab === 'stripe-card'}"
                  (click)="switchTab('stripe-card')"
                >
                  <i class="bi bi-credit-card" [ngClass]="{'active-icon': activeTab === 'stripe-card'}"></i>
                  {{ 'Payment.card' | translate }}
                </div>
                <div
                  class="d-flex flex-column custom-tab w-50"
                  [ngClass]="{'active': activeTab === 'stripe-bank'}"
                  (click)="switchTab('stripe-bank')"
                >
                  <i class="bi bi-bank2" [ngClass]="{'active-icon': activeTab === 'stripe-bank'}"></i>
                  {{ 'Payment.us-bank-account' | translate }}
                </div>
              </div>
              
              <div class="card border-top-0">
                
                <div class="p-3">
                  <div id="card">                
                    <form [formGroup]="bankAccountForm" *ngIf="activeTab === 'stripe-bank'">
                      <div class="form-group">
                        <label for="accountNumber">{{'Taxpayer.account-number' | translate}} <span class="text-danger pl-1">*</span></label>
                        <input
                          id="accountNumber"
                          type="text"
                          formControlName="account_number"
                          [class.is-invalid]=" bankAccountForm.get('account_number').invalid && (bankAccountForm.get('account_number').dirty || bankAccountForm.get('account_number').touched)"
                          [attr.aria-invalid]="bankAccountForm.get('account_number').invalid && (bankAccountForm.get('account_number').dirty || bankAccountForm.get('account_number').touched) ? 'true' : 'false'"
                          class="form-control"
                          [placeholder]="'Taxpayer.account-number' | translate"
                        />
                
                        <div *ngIf="bankAccountForm.get('account_number').touched && bankAccountForm.get('account_number').errors " class="invalid-feedback" style="display: block">
                          <div *ngIf="bankAccountForm.get('account_number').errors['required']">
                            {{ "CommonForm.account-number-required"| translate }}
                          </div>
                          <div *ngIf="bankAccountForm.get('account_number').errors[ 'mustMatch'  ] || bankAccountForm.get('account_number').errors['pattern']">
                            {{ "CommonForm.account-number-not-valid"| translate }}
                          </div>
                          <div *ngIf="bankAccountForm.get('account_number').errors['invalidAccountNumber'] || bankAccountForm.get('account_number').errors['lengthError']">
                            {{ "CommonForm.account-number-not-valid"| translate }}
                          </div>
                        </div>
                      </div>
                    
                      <div class="form-group">
                        <label for="routingNumber">{{'Payment.routing-number' | translate}} <span class="text-danger pl-1">*</span></label>
                        <input
                          id="routingNumber"
                          type="text"
                          [class.is-invalid]="bankAccountForm.get('routing_number').invalid && (bankAccountForm.get('routing_number').dirty || bankAccountForm.get('routing_number').touched)"
                          [attr.aria-invalid]="bankAccountForm.get('routing_number').invalid && (bankAccountForm.get('routing_number').dirty || bankAccountForm.get('routing_number').touched) ? 'true' : 'false'"
                          formControlName="routing_number"
                          class="form-control"
                          [placeholder]="'Payment.routing-number' | translate"
                        />
                       
                        <div *ngIf="bankAccountForm.get('routing_number').touched && bankAccountForm.get('routing_number').errors" class="invalid-feedback" style="display: block">
                          <div *ngIf="bankAccountForm.get('routing_number').errors['required'] || bankAccountForm.get('routing_number').errors['pattern']">
                            {{ "CommonForm.routing-number-required"| translate }}
                          </div>
                          <div *ngIf="bankAccountForm.get('routing_number').errors['mustMatch']">
                            {{ "CommonForm.routing-number-not-valid"| translate }}
                          </div>
                          <div *ngIf="bankAccountForm.get('routing_number').errors['invalidRoutingNumber'] || bankAccountForm.get('routing_number').errors['lengthError'] ">
                            {{ "CommonForm.routing-number-not-valid"| translate }}
                          </div>
                        </div>
                      </div>
                    
                      <div class="d-flex justify-content-between align-items-center">
                       <div class="form-group">
                        <label>{{'Payment.account-holder-type' | translate}} <span class="text-danger pl-1">*</span></label>
                        <div class="d-flex">
                          <label class="d-flex align-items-center mr-2">
                            <input type="radio" formControlName="account_holder_type" value="individual" class="mr-2"/> {{'Payment.individual' | translate}}
                          </label>
                          <label class="d-flex align-items-center">
                            <input type="radio" formControlName="account_holder_type" value="company" class="mr-2"/> {{'Payment.company' | translate}}
                          </label>
                        </div>
                       </div>
                    
                       <div class="form-group">
                        <label>{{'Taxpayer.account-type' | translate}} <span class="text-danger pl-1">*</span></label>
                        <div class="d-flex">
                          <label class="d-flex align-items-center mr-2">
                            <input type="radio" formControlName="account_type" value="checking" class="mr-2"/> {{'Payment.checkings' | translate}}
                          </label>
                          <label class="d-flex align-items-center">
                            <input type="radio" formControlName="account_type" value="savings" class="mr-2"/> {{'Payment.savings' | translate}}
                          </label>
                        </div>
                       </div>
                      </div>
                    </form>
                                        
                    <ngx-stripe-card [elementsOptions]="elementsOptions" [options]="cardOptions" *ngIf="activeTab === 'stripe-card'"></ngx-stripe-card>
                    <div class="row py-2">
                      <div class="col-12">
                        <span class="small font-weight-medium text-muted text-uppercase">
                          {{'Payment.convenience-fee-collected' | translate}}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer justify-content-end">
    <a (click)="bsModalRef.hide()" 
      class="btn btn-link" 
      href="javascript:"
      tabindex="0" 
      role="button" 
      [attr.aria-label]="'Payment.close' | translate">
      {{'Payment.close' | translate}}
    </a>
    <button [disabled]=" (isLoading$ | async)" 
      class="btn btn-primary" 
      type="submit"
      role="button"
      [attr.aria-label]="'Payment.create' | translate" 
      [attr.aria-disabled]="(isLoading$ | async)"
      [attr.aria-busy]="(isLoading$ | async)">
      <ui-spinner [class.active]="isLoading$ | async">
        {{'Payment.create' | translate}}
      </ui-spinner>
    </button>
  </div>
</form>
