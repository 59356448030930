import {ChangeDetectorRef, Component, Injector, OnInit} from "@angular/core";
import {HexaDetailComponent} from "@hexalang/ui/core";
import {EPermission, PaymentHelper} from "@city-tax/shared";
import {BehaviorSubject, Observable,} from "rxjs";
import {IndividualService} from "../../../../../services/individual.service";
import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {NgxPermissionsService} from "ngx-permissions";
import {FormArray, FormBuilder, FormGroup, ValidationErrors, Validators,} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {MiIndividualReturnHelper} from "../../../../../helpers/mi/mi-individual-return.helper";

@Component({
  selector: "city-tax-feature-individual-filing-mi-page1",
  templateUrl: "./individual-filing-mi-page1.component.html",
  styleUrls: ["./individual-filing-mi-page1.component.scss"],
})
export class IndividualFilingMiPage1Component
  extends HexaDetailComponent
  implements OnInit {
  public form: FormGroup;
  public title$;
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public individualType = ["T", "S"];
  public whoResided = ["T", "S", "B"];
  public month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public residedFromDay$ = new BehaviorSubject<any>(null);
  public residedToDay$ = new BehaviorSubject<any>(null);
  public taxYear = this.individualService.taxYear;
  public ePermission = EPermission;
  public isLoading$ = this.individualService.isLoading$;
  public isUploading$ = this.individualService.isUploading$;
  public eFileDeadline = this.authService.eFileDeadline(this.taxYear);
  public error = null;
  public taxDocument = this.authService.organization.taxDocument;
  public city = this.authService.organization.name;
  public individualReturn$ = this.individualService.individualReturn$;
  public hasPermission$: Observable<boolean>;
  public individualReturn = this.individualService.individualReturn;
  public config = this.authService.config;
  public taxRate = this.authService.config?.components?.individual?.taxRate;

  constructor(
    injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private individualService: IndividualService,
    private authService: AuthService,
    public toastrService: ToastrService,
    public permissionService: NgxPermissionsService,
    private formBuilder: FormBuilder,
    private paymentHelper: PaymentHelper,
    private cdRef: ChangeDetectorRef,
  ) {
    super(injector);
    this._initFormUpdate();
  }

  get getScheduleOfWages(): FormArray {
    return this.form.get("wagesSalariesTips.scheduleOfWages") as FormArray;
  }

  async ngOnInit() {
    //this.checkPermission();
    if (this.individualService.individualReturn) {
      this._patchFormUpdate();
    } else {
      await this._getReturn();
    }
  }

  public calculate() {

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') return;

    const ir = this.form.getRawValue();
    const merged = Object.assign({}, this.individualService.individualReturn, ir);
    const miHelper = new MiIndividualReturnHelper(
      this.paymentHelper,
      this.authService.config,
      merged
    );

    this.form.get("wagesSalariesTips.grandTotalScheduleTaxableWages").setValue(miHelper.row1());
    this.form.get("taxableInterest.taxableInterestIncome").setValue(miHelper.row2());
    this.form.get("ordinaryDividendsFederal.dividendTaxableIncome").setValue(miHelper.row3());
    this.form.get("alimonyReceivedTax").setValue(miHelper.row5());
    this.form.get("businessIncomeTax").setValue(miHelper.row6());
    this.form.get("capitalGainsOrLosses.taxableCapitalGainsAndLosses").setValue(miHelper.row7());
    this.form.get("otherGainsLoses.otherTaxableGainsAndLosses").setValue(miHelper.row8());
    this.form.get("taxableIraDistributions.taxableIraDistributionsIncome").setValue(miHelper.row9());
    this.form.get("taxablePensionDistributions.taxablePensionDistributionsIncome").setValue(miHelper.row10());
    this.form.get("rentalRealEstate.rentalRealEstateIncome").setValue(miHelper.row11());
    this.form.get("sCorporationDistributions.subchapterSCorporationDistributionsIncome").setValue(miHelper.row12());
    this.form.get("farmIncomeLoss.totalFarmIncomeLoss").setValue(miHelper.row13());
    this.form.get("otherIncome.otherIncomeSubject").setValue(miHelper.row16());
    this.form.get("totalAdditionsFederal").setValue(miHelper.totalAdditionsFederal);
    this.form.get("totalAdditionsAdjustments").setValue(miHelper.totalAdditionsAdjustments);
    this.form.get("totalAdditionsTax").setValue(miHelper.totalAdditionsTax);
    this.form.get("totalIncomeFederal").setValue(miHelper.totalIncomeFederal);
    this.form.get("totalIncomeAdjustments").setValue(miHelper.totalIncomeAdjustments);
    this.form.get("totalTax").setValue(miHelper.totalTax);
    this.form.get("totalIncomeTax").setValue(miHelper.totalIncomeTax);
    this.form.get("totalTaxDue").setValue(miHelper.totalTaxDue);
    this.form.get("totalIncomeAfterDeductions").setValue(miHelper.totalIncomeAfterDeductions);
    this.form.get("totalIncomeSubjectToTax").setValue(miHelper.totalIncomeSubjectToTax);
    this.form.get("totalPaymentsCredits").setValue(miHelper.totalPaymentsCredits);
    this.form.get("penaltyOnUnderpaidEstimatesOrLateFiledReturns").setValue(miHelper.penaltyOnUnderpaidEstimatesOrLateFiledReturns);
    this.form.get("totalDonations").setValue(miHelper.totalDonations);
    this.form.get('totalOverPayment').setValue(miHelper.totalOverPayment);
    this.form.updateValueAndValidity();
    this.cdRef.detectChanges();
  }

  getPayload() {
    const payload = this.form.getRawValue();

    const miHelper = new MiIndividualReturnHelper(
      this.paymentHelper,
      this.authService.config,
      payload
    );

    payload.wagesSalariesTips = miHelper.wagesSalariesTips;
    return payload;
  }

  getFormValidationErrors(form) {
    if (form && form.controls) {
      Object.keys(form.controls).forEach((key) => {
        const control = form.get(key);
        if (control.controls) {
          this.getFormValidationErrors(this.form.get(key));
        }
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            console.log(
              "Key control: " +
              key +
              ", keyError: " +
              keyError +
              ", err value: ",
              controlErrors[keyError]
            );
          });
        }
      });
    }
  }

  addScheduleOfWages(ir) {
    // Function to create a FormGroup for an address
    const createAddressGroup = (address) => {
      return this.formBuilder.group({
        addressLine1: [address?.addressLine1],
        addressLine2: [address?.addressLine2],
        aptNumber: [address?.aptNumber],
        city: [address?.city],
        state: [address?.state],
        postalCode: [address?.postalCode],
      });
    };

    // Function to handle the creation of FormGroups for wages
    const createWagesGroup = (u, type) => {
      const w2LocalityInfoArray = this.formBuilder.array(u.w2LocalityInfo?.map(l => this.formBuilder.group(l)) || []);
      const wagesGroup = this.formBuilder.group({
        ...u,
        type: type,
        ssn: [u.ssn],
        employersFederalId: [u.employersFederalId],
        excludedWages: [u.excludedWages],
        employersAddress: createAddressGroup(u.employersAddress),
        w2LocalityInfo: w2LocalityInfoArray
      });
      return wagesGroup;
    };

    // Function to process each set of wages
    const processWages = (wagesArray, type) => {
      wagesArray?.forEach(u => {
        const wageFormGroup = createWagesGroup(u, type);
        this.getScheduleOfWages.push(wageFormGroup);
      });
    };

    // Process spouse wages
    processWages(ir?.wagesSalariesTips?.spouseScheduleOfWages, 'S');

    // Process taxpayer wages
    processWages(ir?.wagesSalariesTips?.taxpayerScheduleOfWages, 'T');
  }

  addAddressFormGroup(a) {
    return this.formBuilder.group({
      addressLine1: a?.addressLine1,
      addressLine2: a?.addressLine2,
      aptNumber: a?.aptNumber,
      city: a?.city,
      state: a?.state,
      postalCode: a?.postalCode,
    });
  }

  checkChangeRefundDirectDeposit() {
    this.form.get("refundDirectDeposit").valueChanges.subscribe((v: any) => {
      if (v) {
        this.form.get("routingNumber").addValidators(Validators.required);
        this.form.get("routingNumber").updateValueAndValidity();
        this.form.get("accountNumber").addValidators(Validators.required);
        this.form.get("accountNumber").updateValueAndValidity();
        this.form.get("typeOfAccount").addValidators(Validators.required);
        this.form.get("typeOfAccount").updateValueAndValidity();
      } else {
        this.form.get("routingNumber").removeValidators(Validators.required);
        this.form.get("routingNumber").updateValueAndValidity();
        this.form.get("accountNumber").removeValidators(Validators.required);
        this.form.get("accountNumber").updateValueAndValidity();
        this.form.get("typeOfAccount").removeValidators(Validators.required);
        this.form.get("typeOfAccount").updateValueAndValidity();
      }
    });
  };

  private _initFormUpdate() {
    this.form = this.formBuilder.group({
        id: null,
        residency: null,
        eFileDeadline: this.eFileDeadline,
        wagesSalariesTips: this.formBuilder.group({
          grandTotalScheduleWages: null,
          grandTotalScheduleExcludibleWages: null,
          grandTotalScheduleTaxableWages: null,
          scheduleOfWages: this.formBuilder.array([]),
        }),
        fillingCityTaxRateDecimal: null,
        //line 2
        taxableInterest: this.formBuilder.group({
          interestFromFederal: null,
          interestTotalExcludibleIncome: null,
          taxableInterestIncome: null,
        }),

        //line 3
        ordinaryDividendsFederal: this.formBuilder.group({
          dividendIncomeFromFederalReturn: null,
          dividendTotalExcludible: null,
          dividendTaxableIncome: null,
        }),

        //line 4
        taxableRefundsCreditsFederal: null,
        taxableRefundsCreditsAdjustment: null,

        //line5
        alimonyReceivedFederal: null,
        alimonyReceivedAdjustments: null,
        alimonyReceivedTax: null,

        //line 6
        businessIncomeFederal: null,
        businessIncomeAdjustments: null,
        businessIncomeTax: null,

        //line 7
        capitalGainsOrLosses: this.formBuilder.group({
          totalCapitalGainsAndLossesFromFederalReturn: null,
          totalExcludibleCapitalGains: null,
          taxableCapitalGainsAndLosses: null,
        }),

        //line 8
        otherGainsLoses: this.formBuilder.group({
          totalOtherGainsAndLosses: null,
          otherTotalExcludibleGainsAndLosses: null,
          otherTaxableGainsAndLosses: null,
        }),

        //line 9
        taxableIraDistributions: this.formBuilder.group({
          taxableIraDistributionsFederal: null,
          totalExcludibleIraDistributions: null,
          taxableIraDistributionsIncome: null,
        }),

        //line 10
        taxablePensionDistributions: this.formBuilder.group({
          taxablePensionDistributionsFederal: null,
          totalExcludiblePension: null,
          taxablePensionDistributionsIncome: null,
        }),

        gamblingIncome: this.formBuilder.group({
          gamblingIncomeFederal: null,
          gamblingIncomeAdjustments: null,
          gamblingIncomeTax: null,
          gamblingIncomePartYearResident: null,
          gamblingIncomePartYearNonResident: null,
        }),

        partnership: this.formBuilder.group({
          partnershipIncomeFederal: null,
          partnershipIncomeAdjustments: null,
          partnershipIncomeTax: null,
          partnershipIncomePartYearResident: null,
          partnershipIncomePartYearNonResident: null,
        }),

        //line 11
        rentalRealEstate: this.formBuilder.group({
          rentalRealEstateFederal: null,
          totalAdjustments: null,
          rentalRealEstateIncome: null,
        }),

        //line 12
        sCorporationDistributions: this.formBuilder.group({
          subchapterSCorporationDistributionsExclusions: null,
          subchapterSCorporationDistributionsIncome: null,
        }),

        //line 13
        farmIncomeLoss: this.formBuilder.group({
          totalFarmIncomeNetProfit: null,
          excludibleNetProfit: null,
          totalFarmIncomeLoss: null,
        }),

        // //line 14
        unemploymentCompensationFederal: null,
        unemploymentCompensationAdjustments: null,

        //line 15
        socialSecurityFederal: null,
        socialSecurityAdjustments: null,

        // //line 16
        otherIncome: this.formBuilder.group({
          otherIncomeFederal: null,
          otherIncomeExclusions: null,
          otherIncomeSubject: null,
        }),

        // //line 17
        totalAdditionsFederal: null,
        totalAdditionsAdjustments: null,
        totalAdditionsTax: null,

        //line 18
        totalIncomeFederal: null,
        totalIncomeAdjustments: null,
        totalIncomeTax: null,

        // //line 19
        totalDeductions: null,

        //line 20
        totalIncomeAfterDeductions: null,

        //line 21
        numberOfExemptions: null,
        amountForExemptions: null,

        //line 22
        totalIncomeSubjectToTax: null,

        // //line 23
        totalTax: null,

        //line 24
        totalPaymentsCredits: null,
        taxWithheldByEmployer: null,
        paymentsOnPriorYearDeclarationTax: null,
        creditForTaxPaidAnotherCity: this.formBuilder.group({
          grandTotalCredit: null,
        }),

        //line 25
        totalInterestAndPenaltyOnUnderpaidEstimatesOrLateFilesReturns: null,
        interestOnUnderpaidEstimatesOrLateFiledReturns: null,
        penaltyOnUnderpaidEstimatesOrLateFiledReturns: null,

        //line 26
        totalTaxDue: null,

        //line 27
        totalOverPayment: null,

        //line 28
        donation35A: null,
        donation35B: null,
        donation35C: null,
        totalDonations: null,

        //line 29
        amountOfOverpaymentCreditedForward: null,

        //line 30
        overpaymentToBeRefunded: null,

        routingNumber: null,
        accountNumber: null,
        payTaxDueDirectWithdrawal: null,
        overpaymentDirectWithdrawal: null,
        payTaxDetail: null,
        typeOfAccount: null,
        refundDirectDeposit: null,
      },
      {
        validators: [this._totalValidator, this._totalDonationValidator]
      }
    );
  }

  private _patchFormUpdate() {
    const ir = this.individualService.individualReturn;
    const total = +(ir.paymentsOnPriorYearDeclarationTax ?? 0) + +(ir.estimatedTaxPaid ?? 0) + +(ir.priorYearOverpayment ?? 0);
    ir.paymentsOnPriorYearDeclarationTax = total.toString();

    if (this.individualService.individualReturn?.source === 'Upload' || this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      this.form.patchValue(ir);
    } else {
      const miHelper = new MiIndividualReturnHelper(
        this.paymentHelper,
        this.authService.config,
        ir
      );
      this.form.patchValue(miHelper.irForm);
    }
    this.addScheduleOfWages(this.individualService.individualReturn);
    this.calculate();
    this.checkChangeRefundDirectDeposit();
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      this.isDisabled$.next(true)
      this.form.disable();
    }
  }

  private async _getReturn() {
    try {
      await this.individualService.getReturn(this.authService.organization.id, this.authService.config?.state);
      this._patchFormUpdate();
    } catch (error) {
    }
  }

  isOverpayment() {
    const totalOverPayment = this.form.get("totalOverPayment");
    return this.paymentHelper.decimalValue(totalOverPayment.value, 0) > 0;
  }

  private _totalDonationValidator = (group: FormGroup) => {
    const totalOverPayment = group.get("totalOverPayment");
    const totalDonations = group.get("totalDonations");

    if (!totalDonations.value || this.paymentHelper.decimalValue(totalDonations.value, 0) === 0)
      return null;
    
    const valid = this.paymentHelper.decimalValue(totalDonations.value, 0) <= this.paymentHelper.decimalValue(totalOverPayment.value, 0);

    if (!valid) {
      return {
        donationTotalMismatch: true,
      };
    }
    return null;
  };

  private _totalValidator = (group: FormGroup) => {
    const amountOfOverpaymentCreditedForward = group.get("amountOfOverpaymentCreditedForward");
    const overpaymentToBeRefunded = group.get("overpaymentToBeRefunded");
    const totalDonations = group.get("totalDonations");
    const totalOverPayment = group.get("totalOverPayment");

    if (!totalOverPayment.value || this.paymentHelper.decimalValue(totalOverPayment.value, 0) === 0)
      return null;

    let sum = this.paymentHelper.decimalValue(amountOfOverpaymentCreditedForward.value, 0);
    sum += this.paymentHelper.decimalValue(overpaymentToBeRefunded.value, 0);
    sum += this.paymentHelper.decimalValue(totalDonations.value, 0);

    const valid = sum === this.paymentHelper.decimalValue(totalOverPayment.value, 0);

    if (!valid) {
      return {
        totalMismatch: true,
      };
    }
    return null;
  };


  async next() {
    let path = `/individual/filings/mi/${this.individualService.taxYear}/acknowledgement`;
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      await this.router.navigate([path]);
    } else {

      this.getFormValidationErrors(this.form);
      if (this.form.valid) {
        const payload = this.getPayload();
        try {
          await this.individualService.nextPageAttachment1(this.authService.organization.id, payload)
          await this.router.navigate([path]);
        } catch (error) {

        }
      } else {
        this.form.markAllAsTouched();
      }
    }
  }

  async back() {
    let path = `/individual/filings/mi/${this.individualService.taxYear}/`;
    path += this.individualService.individualReturn.residency !== "P" ? "page2" : "tc";

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      await this.router.navigate([path]);
    } else {
      const payload = this.getPayload();
      try {
        await this.individualService.updateReturn(this.city.id, payload);
        await this.router.navigate([path]);
      } catch (error) {

      }
    }
  }
}
