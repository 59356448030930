import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

import {IndividualComponent} from "./individual.component";

import {
  IndividualFilingMiPage1Component
} from "./components/filing/tax-year/mi/page1/individual-filing-mi-page1.component";
import {
  IndividualFilingMiPage2Component
} from "./components/filing/tax-year/mi/page2/individual-filing-mi-page2.component";
import {IndividualFilingMiComponent} from "./components/filing/tax-year/mi/individual-filing-mi.component";
import {IndividualFilingOhComponent} from "./components/filing/tax-year/oh/individual-filing-oh.component";


import {IndividualFilingTaxYearComponent} from "./components/filing/tax-year/individual-filing-tax-year.component";
import {
  IndividualFilingConfirmationComponent
} from "./components/filing/tax-year/confirmation/individual-filing-confirmation.component";
import {IndividualFilingUploadComponent} from "./components/filing/tax-year/upload/individual-filing-upload.component";
import {
  IndividualFilingAcknowledgementComponent
} from "./components/filing/tax-year/acknowledgement/individual-filing-acknowledgement.component";
import {IndividualFilingMiTCComponent} from "./components/filing/tax-year/mi/tc/individual-filing-mi-tc.component";

import {
  IndividualFilingOhPage2Component
} from "./components/filing/tax-year/oh/page2/individual-filing-oh-page2.component";
import {
  IndividualFilingOhPage1Component
} from "./components/filing/tax-year/oh/page1/individual-filing-oh-page1.component";
import {
  IndividualFilingOhStartComponent
} from "./components/filing/tax-year/oh/start/individual-filing-oh-start.component";
import {
  IndividualFilingMiStartComponent
} from "./components/filing/tax-year/mi/start/individual-filing-mi-start.component";
import {NotificationComponent} from "./components/notification/notification.component";
import {
  IndividualPaymentCreateComponent
} from "./components/payment/payment-create/individual-payment-create.component";
import {
  IndividualPaymentCreateIntentComponent
} from "./components/payment/payment-create/intent/individual-payment-create-intent.component";
import {
  IndividualPaymentCreateConfirmationComponent
} from "./components/payment/payment-create/confirmation/individual-payment-create-confirmation.component";
import {
  IndividualPaymentHistoryComponent
} from "./components/payment/payment-history/individual-payment-history.component";
import {
  IndividualPaymentMethodListComponent
} from "./components/payment/payment-method-list/individual-payment-method-list.component";
import {IndividualPaymentComponent} from "./components/payment/individual-payment.component";
import {IndividualPaymentRecurringComponent} from "./components/payment/payment-create/recurring/individual-payment-recurring.component";


const routes: Routes = [
  {
    path: "filings",
    component: IndividualComponent,
    children: [
      {
        path: "mi",
        component: IndividualFilingMiComponent,
        children: [
          {
            path: ":taxYear",
            component: IndividualFilingTaxYearComponent,
            children: [

              {
                path: "",
                pathMatch: "full",
                redirectTo: "start",
              },
              {
                path: "start",
                component: IndividualFilingMiStartComponent,
              },
              {
                path: "upload",
                component: IndividualFilingUploadComponent,
              },
              {
                path: "page1",
                component: IndividualFilingMiPage1Component,
              },
              {
                path: "page2",
                component: IndividualFilingMiPage2Component,
              },
              {
                path: "tc",
                component: IndividualFilingMiTCComponent,
              },
              {
                path: "acknowledgement",
                component: IndividualFilingAcknowledgementComponent,
              },
              {
                path: "confirmation",
                component: IndividualFilingConfirmationComponent,
              },
            ],
          },
        ],
      },
      {
        path: "oh",
        component: IndividualFilingOhComponent,
        children: [
          {
            path: ":taxYear",
            component: IndividualFilingTaxYearComponent,
            children: [
              {
                path: "",
                pathMatch: "full",
                redirectTo: "start",
              },
              {
                path: "start",
                component: IndividualFilingOhStartComponent,
              },
              {
                path: "upload",
                component: IndividualFilingUploadComponent,
              },
              {
                path: "page1",
                component: IndividualFilingOhPage1Component,
              },
              {
                path: "page2",
                component: IndividualFilingOhPage2Component,
              },
              {
                path: "acknowledgement",
                component: IndividualFilingAcknowledgementComponent,
              },
              {
                path: "confirmation",
                component: IndividualFilingConfirmationComponent,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "payments",
    component: IndividualPaymentComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "create",
      },
      {
        path: "create",
        component: IndividualPaymentCreateComponent,
        children: [
          {
            path: "",
            pathMatch: "full",
            redirectTo: "intent",
          },
          {
            path: "intent",
            component: IndividualPaymentCreateIntentComponent,
          },

          {
            path: "intent/:id",
            component: IndividualPaymentCreateIntentComponent,
          },
          {
            path: "intent/:id/recurring",
            component: IndividualPaymentRecurringComponent,
          },

          {
            path: "confirmation",
            component: IndividualPaymentCreateConfirmationComponent,
          },
          {
            path: "confirmation/:id",
            component: IndividualPaymentCreateConfirmationComponent,
          },
        ],
      },
      {
        path: "history",
        component: IndividualPaymentHistoryComponent,
      },

    ],
  },
  {
    path: "payment-methods",
    component: IndividualPaymentComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "list",
      },
      {
        path: "list",
        component: IndividualPaymentMethodListComponent,
      },
    ],
  },
  {
    path: "notifications",
    component: NotificationComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IndividualRouting {
}
