export class ScheduleHelper {
  private readonly worksheetB: any;
  private readonly type: any;
  private readonly schedule: any;
  private readonly scheduleY: any;
  private readonly isResident: any;
  private readonly filingCity: any;

  constructor(private paymentHelper, worksheetB, type, isResident, filingCity) {
    this.worksheetB = worksheetB;
    this.isResident = isResident;
    this.filingCity = filingCity;
    this.type = type;
    this.schedule = this.getDetail();
    this.scheduleY = this.schedule.filter(s => s.scheduleY).map((schedule) => schedule.scheduleY).flat();
  }

  get otherCity() {
    if (!this.isResident) return false;
    for (const schedule of this.schedule) {
      if (schedule.municipality !== this.filingCity?.name && this.paymentHelper.sum(this.schedule, 'amount') > 0) {
        return true;
      }
    }
    return false;
  }

  get totalIncomeLoss() {
    return this.paymentHelper.sum(this.schedule, 'incomeAmount', 2);
  }

  get taxCreditAllowed() {
    return this.paymentHelper.sum(this.schedule, 'taxesPaidAmount', 2);
  }

  get municipalityTaxableIncome() {

    if(this.totalStep5Percentage > 0){
      return this.paymentHelper.decimalValue(this.totalIncomeLoss * this.totalStep5Percentage, 2);
    }

    return this.paymentHelper.sum(this.schedule, 'amount', 2);
  }

  get municipalityPercentage() {
    const totalIncomeLoss = this.totalIncomeLoss;
    if (totalIncomeLoss === 0) return 0;

    const calculatedPercentage = this.municipalityTaxableIncome / totalIncomeLoss;
    return this.totalStep5Percentage > 0 ? this.totalStep5Percentage.toFixed(2) : calculatedPercentage.toFixed(2);
  }

  get averageOriginalCostOfPropertyLocatedEverywhere() {
    return this.paymentHelper.sum(this.scheduleY, 'averageOriginalCostOfPropertyLocatedEverywhere', 2);
  }

  get averageOriginalCostOfPropertyLocatedInMunicipality() {
    return this.paymentHelper.sum(this.scheduleY, 'averageOriginalCostOfPropertyLocatedInMunicipality', 2);
  }

  get grossAnnualRentPaidLocatedEverywhere() {
    return this.paymentHelper.sum(this.scheduleY, 'grossAnnualRentPaidLocatedEverywhere', 2);
  }

  get grossAnnualRentPaidLocatedInMunicipality() {
    return this.paymentHelper.sum(this.scheduleY, 'grossAnnualRentPaidLocatedInMunicipality', 2);
  }

  get step1Municipality() {
    return this.paymentHelper.sum(this.scheduleY, 'totalStep1LocatedInMunicipality', 2);
  }

  get step1Everywhere() {
    return this.paymentHelper.sum(this.scheduleY, 'totalStep1LocatedEverywhere', 2);
  }

  get totalStep1Percentage() {
    if (!this.step1Everywhere || this.step1Everywhere === 0) return 0;
    return +(this.step1Municipality / this.step1Everywhere).toFixed(2);
  }

  get step2Municipality() {
    return this.paymentHelper.sum(this.scheduleY, 'step2LocatedInMunicipality', 2);
  }

  get step2Everywhere() {
    return this.paymentHelper.sum(this.scheduleY, 'step2LocatedEverywhere', 2);
  }

  get totalStep2Percentage() {
    if (!this.step2Everywhere || this.step2Everywhere === 0) return 0;
    return +(this.step2Municipality / this.step2Everywhere).toFixed(2);
  }

  get step3Municipality() {
    return this.paymentHelper.sum(this.scheduleY, 'step3LocatedInMunicipality', 2);
  }

  get step3Everywhere() {
    return this.paymentHelper.sum(this.scheduleY, 'step3LocatedEverywhere', 2);
  }

  get totalStep3Percentage() {
    if (!this.step3Everywhere || this.step3Everywhere === 0) return 0;
    return +(this.step3Municipality / this.step3Everywhere).toFixed(2);
  }

  get totalStep4Percentage() {
    return this.totalStep1Percentage + this.totalStep2Percentage + this.totalStep3Percentage;
  }

  get totalStep5Percentage() {

    let count = !!this.totalStep1Percentage ? 1 : 0;
    count += !!this.totalStep2Percentage ? 1 : 0;
    count += !!this.totalStep3Percentage ? 1 : 0;

    return (this.totalStep1Percentage + this.totalStep2Percentage + this.totalStep3Percentage) / count;
  }

  get schedulesYFields() {
    return {
      averageOriginalCostOfPropertyLocatedEverywhere: this.averageOriginalCostOfPropertyLocatedEverywhere,
      averageOriginalCostOfPropertyLocatedInMunicipality: this.averageOriginalCostOfPropertyLocatedInMunicipality,
      grossAnnualRentPaidLocatedEverywhere: this.grossAnnualRentPaidLocatedEverywhere,
      grossAnnualRentPaidLocatedInMunicipality: this.grossAnnualRentPaidLocatedInMunicipality,

      step1Everywhere: this.step1Everywhere,
      step1Municipality: this.step1Municipality,
      totalStep1Percentage: ((this.totalStep1Percentage || 0) * 100).toFixed(1),

      step2Everywhere: this.step2Everywhere,
      step2Municipality: this.step2Municipality,
      totalStep2Percentage: ((this.totalStep2Percentage || 0) * 100).toFixed(1),

      step3Everywhere: this.step3Everywhere,
      step3Municipality: this.step3Municipality,
      totalStep3Percentage: ((this.totalStep3Percentage || 0) * 100).toFixed(1),

      totalStep4Percentage: ((this.totalStep4Percentage || 0) * 100).toFixed(1),
      totalStep5Percentage: ((this.totalStep5Percentage || 0) * 100).toFixed(1)
    }
  }

  getDetail() {
    const detail = this.worksheetB[this.type] ? Array.isArray(this.worksheetB[this.type]) ? this.worksheetB[this.type] : [this.worksheetB[this.type]] : [];
    return this.isResident ? detail : detail.filter((schedule) => schedule?.municipality === this.filingCity?.name);
  }

}

