import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'city-tax-card-details',
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss']
})
export class CardDetailsComponent implements OnInit {
  @Input() selectedPaymentMethod: any;

  constructor() {
  }

  ngOnInit(): void {
  }

  public getBrandLogo(brand: string): string {
    const logos: { [key: string]: string } = {
      visa: './global/visa.png',
      mastercard: './global/masterCard.png',
      amex: './global/americanExpress.png',
      ideal: './global/ideal.png',
      discovery: './global/discovery.png',
      dinersclub: './global/dinersClub.png',
      paypal: './global/paypal.png',
      stripe: './global/stripe.png',
      ach: './global/bank.png',
      bank: './global/bank.png',
      discover: './global/discover.png',
    };
    return logos[brand.toLowerCase()] || './global/default-logo.png';
  }

  public formatExpiryDate(month: number, year: number): string {
    const formattedMonth = month < 10 ? '0' + month : month;
    return `${formattedMonth}/${year}`;
  }

  public formatBankAccountNumber(accountNumber: string): string {
    if (!accountNumber) return '';
    const lastFour = accountNumber.slice(-4);
    const masked = 'X'.repeat(accountNumber.length - 4);
    return `${masked}${lastFour}`;
  }
}
