<form (submit)="deletePayment()" [formGroup]="formDelete" class="d-flex flex-column flex-fill" novalidate>
  <div class="modal-header align-items-center">
    <h4 class="modal-title pull-left">{{'Payment.delete-schedule-payment' | translate}}</h4>
    <a (click)="bsModalRef.hide()" class="text-muted" href="javascript:">
      <svg class="icon" viewBox="0 0 24 24">
        <path
            d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
            stroke="none"/>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div>{{'Payment.delete-following-payment' | translate}}<br>
      <br>
      <strong>{{ payment?.paymentType }}</strong> {{'Payment.payment-scheduled-for' | translate}} <strong>{{ payment?.originalPaymentDate || payment?.paymentDate|utcDate }}</strong>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <a (click)="bsModalRef.hide()" 
       class="btn btn-link" 
       href="javascript:"
       tabindex="0" 
       role="button" 
       [attr.aria-label]="'Payment.close' | translate">{{'Payment.close' | translate}}</a>
    <button [disabled]="formDelete.invalid || (isLoading$ | async)" 
       class="btn btn-danger" 
       type="submit"
       tabindex="0"
       role="button" 
       [attr.aria-label]="'Payment.delete-payment' | translate"
       [attr.aria-disabled]="formDelete.invalid || (isLoading$ | async) ? 'true': 'false'"
       [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'">
      <ui-spinner [class.active]="isLoading$ | async">{{'Payment.delete-payment' | translate}}</ui-spinner>
    </button>
  </div>
</form>
