import {Component, Injector, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {BsModalRef} from "ngx-bootstrap/modal";
import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {HexaDetailComponent} from "@hexalang/ui/core";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: "city-tax-feature-estimate",
  templateUrl: "./estimate.component.html",
  styleUrls: ["./estimate.component.scss"],
})
export class EstimateComponent extends HexaDetailComponent implements OnInit {
  @Input() public city: any;
  public state: any;
  public isLoading$ = this.authService.isLoading$;
  public organizations$ = this.authService.organizations$;
  public organization$ = this.authService.organization$;
  public organization = null;
  public orgId = null;
  public form: FormGroup;
  public config = this.authService.config;

  public taxYear = new Date().getFullYear() - 1;
  public priorYear = this.taxYear - 1;
  public estimates$ = new BehaviorSubject(null) as BehaviorSubject<any>;

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef,
    private authService: AuthService,
    public toastrService: ToastrService,
    private formBuilder: FormBuilder
  ) {
    super(injector);
    this.state = this.authService.config.state;
  }

  public _isAccountNumberVisible: boolean;

  @Input()
  get isAccountNumberVisible(): boolean {
    return this._isAccountNumberVisible;
  }

  set isAccountNumberVisible(value: boolean) {
    this._isAccountNumberVisible = value;
  }

  async selectCity() {
    await this.authService.setOrganization(this.form.get("organization").value);
  }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      organization: null,
      accountNumber: null,
      lastName: null,
    });

    try {
      if (this.city) {
        await this.authService.getOrganizationsByStateOrGroup(this.state, this.city, "individual");
      } else {
        await this.authService.setOrganization(this.config);
      }
    } catch (error) {
    }
  }

  public async search() {
    this.estimates$.next(null);
    try {
      const estimates = await this.authService.getEstimates(this.authService.organization.id, this.form.getRawValue())
      this.estimates$.next(estimates);
    } catch (error) {
      this.toastrService.error(error.message, error.name);
    }
  }
}
