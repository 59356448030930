import {ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HexaDetailComponent} from "@hexalang/ui/core";
import {BsModalRef} from "ngx-bootstrap/modal";
import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {StripeCardComponent, StripeService,} from 'ngx-stripe';
import {StripeCardElementOptions, StripeElementsOptions} from '@stripe/stripe-js';
import {AccountNumberValidator, MatchValidator, RoutingNumberValidator} from "@city-tax/shared";
import {IIndividualBase} from "../../../interfaces/individual.interface";

@Component({
  selector: 'city-tax-feature-individual-payment-method-create',
  templateUrl: './individual-payment-method-create.component.html',
  styleUrls: ['./individual-payment-method-create.component.scss']
})
export class IndividualPaymentMethodCreateComponent extends HexaDetailComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public bankAccountForm: FormGroup;
  public activeTab: string = 'ach';

  public hasAch: false;
  public hasCC: false;

  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  public organization = this.authService.organization;
  public isLoading$ = this.authService.isLoading$;

  public accountType = [
    {label: "Payment.checking", value: "C"},
    {label: "Payment.savings", value: "S"},
  ];

  constructor(
    injector: Injector,
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public toastrService: ToastrService,
    private stripeService: StripeService,
    private cd: ChangeDetectorRef
  ) {
    super(injector);
    const paymentMethods = this.organization?.paymentMethods;
    if (paymentMethods) {
      this.hasAch = paymentMethods.includes('ach');
      this.hasCC = paymentMethods.includes('card');
    }
  }

  ngOnInit() {
    this.initform();
  }

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        lineHeight: '40px',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: {
      theme: 'flat',
    },
  };

  setPaymentMethodType(type: string) {
    if (this.form.getRawValue().type && this.form.getRawValue().type !== type) {
      this.form.get('type').setValue(type);

      const bank = this.form.get("bank") as FormGroup;
      const accountNumber = bank.get("accountNumber");
      const routingNumber = bank.get("routingNumber");
      const accountType = bank.get("accountType");
      const confirmRoutingNumber = this.form.get("confirmRoutingNumber");
      const confirmAccountNumber = this.form.get("confirmAccountNumber");

      if (type === "ach") {
        accountNumber.setValidators([Validators.required, AccountNumberValidator()]);
        routingNumber.setValidators([Validators.required, RoutingNumberValidator()]);
        accountType.setValidators([Validators.required]);
        confirmRoutingNumber.setValidators([Validators.required]);
        confirmAccountNumber.setValidators([Validators.required]);

        accountNumber.updateValueAndValidity();
        routingNumber.updateValueAndValidity();
        accountType.updateValueAndValidity();
        confirmRoutingNumber.updateValueAndValidity();
        confirmAccountNumber.updateValueAndValidity();
        this.activeTab = 'ach'
      } else {
        accountNumber.clearValidators();
        accountNumber.updateValueAndValidity();
        routingNumber.clearValidators();
        routingNumber.updateValueAndValidity();
        accountType.clearValidators();
        accountType.updateValueAndValidity();
        confirmRoutingNumber.clearValidators();
        confirmRoutingNumber.updateValueAndValidity();
        confirmAccountNumber.clearValidators();
        confirmAccountNumber.updateValueAndValidity();
      }
    }
  }

  public async create() {
    if (this.form.valid) {
      const payload = this.form.getRawValue();
      try {
        if (this.activeTab === 'stripe-card') {
          const individual = this.authService.individual as IIndividualBase;
          this.stripeService.createPaymentMethod(
            {
              type: 'card',
              card: this.card.element,
              billing_details: {
                name: individual.name,
                email: individual.email,
                phone: individual.phone,
                address: {
                  line1: individual.address.addressLine1,
                  city: individual.address.city,
                  state: individual.address.state,
                  postal_code: individual.address.postalCode,
                }
              },
            }
          ).subscribe(async (result) => {
            if (result.error) {
              console.log(result.error.message)
            } else {
              console.log(result.paymentMethod);
              payload.stripeId = result.paymentMethod.id;
              const card = result.paymentMethod.card;
              payload.type = 'card'
              payload.card = {
                cardNumber: card.last4,
                brand: card.brand,
                expMonth: card.exp_month,
                expYear: card.exp_year,
              };
              await this._submit(payload);
            }
          });
        } else if (this.activeTab === 'stripe-bank') {
          const individual = this.authService.individual as IIndividualBase;
          this.stripeService.createPaymentMethod(
            {
              type: 'us_bank_account',
              us_bank_account: {
                account_number: this.bankAccountForm.value.account_number,
                routing_number: this.bankAccountForm.value.routing_number,
                account_holder_type: this.bankAccountForm.value.account_holder_type,
                account_type: this.bankAccountForm.value.account_type,
              },
              billing_details: {
                name: individual.name,
                email: individual.email,
                phone: individual.phone,
                address: {
                  line1: individual.address.addressLine1,
                  city: individual.address.city,
                  state: individual.address.state,
                  postal_code: individual.address.postalCode,
                }
              },
            }
          ).subscribe(async (result: any) => {
            if (result.error) {
              console.log(result.error.message)
            } else {
              payload.stripeId = result.paymentMethod.id;
              payload.type = 'bank';
              const bank = result.paymentMethod.us_bank_account;
              payload.bank = {
                accountType: bank.account_type === 'checking' ? 'C' : 'Savings',
                accountNumber: bank.last4,
                routingNumber: bank.routing_number,
              };
              await this._submit(payload);
            }
          })
        } else {
          await this._submit(payload);
        }
      } catch (error) {
        console.error('pm:create-error', error)
      }
    } else {
      this.form.markAllAsTouched();
    }
  }

  async _submit(payload: any) {
    try {
      console.log('payload', payload);
      await this.authService.addPaymentMethod(this.authService.organizationId, 'individuals', payload);
      this.bsModalRef.hide();
      setTimeout(() => {
        this.toastrService.success(
          "Payment Method was successfully saved!",
          "Payment Method Create"
        );
      }, 1000);
    } catch (error) {
      console.error('pm:submit-error', error)
    }
  }

  private initform() {
    this.form = this.formBuilder.group({
        isDefault: [false],
        name: [null, Validators.required],
        type: ["ach", Validators.required],
        bank: this.formBuilder.group({
          accountType: [null, Validators.required],
          accountNumber: [
            null,
            [
              Validators.required,
              AccountNumberValidator(),
              Validators.minLength(8),
              Validators.maxLength(12),
            ],
          ],
          routingNumber: [
            null,
            [Validators.required,
              RoutingNumberValidator()
            ],
          ],
        }),
        confirmRoutingNumber: [null, Validators.required],
        confirmAccountNumber: [null, Validators.required],
      },
      {
        validators: [
          MatchValidator("routingNumber", "confirmRoutingNumber", "bank"),
          MatchValidator("accountNumber", "confirmAccountNumber", "bank"),
        ],
      }
    );
    this.bankAccountForm = this.formBuilder.group({
        account_number: ['', [Validators.required, AccountNumberValidator(), Validators.minLength(8), Validators.maxLength(12)]],
        routing_number: ['', [Validators.required, RoutingNumberValidator()]],
        account_holder_type: ['individual', [Validators.required]],
        account_type: ['checking', [Validators.required]],
      }
    );

  };

  onlyNumberKey(event: any) {
    var keyCode = event.which ? event.which : event.keyCode;
    return keyCode > 31 && (keyCode < 48 || keyCode > 57) ? false : true;
  }

  formatInteger(any) {
    return !any ? null : any.toString().replace(/\D/g, '') === '' ? 0 : any.toString().replace(/\D/g, '');
  }

  switchTab(tab: string): void {
    this.activeTab = tab;
    this.cd.detectChanges()
  }

  strip(control) {
    control.updateValueAndValidity();
    const value = this.formatInteger(control.value);
    const accountNumberCtrl = this.form.get("bank").get("accountNumber");
    if (value === "272480678") {
      accountNumberCtrl.removeValidators([Validators.minLength(8)]);
      accountNumberCtrl.addValidators([Validators.minLength(12)]);
    } else {
      if (accountNumberCtrl.value !== "272480678") {
        if (accountNumberCtrl.hasValidator(Validators.min(12))) {
          accountNumberCtrl.removeValidators([Validators.minLength(12)]);
          accountNumberCtrl.addValidators([Validators.minLength(8)]);
        }
      }
    }
    accountNumberCtrl.updateValueAndValidity();
    control.setValue(value);
    control.markAsTouched();
    control.updateValueAndValidity();
    this.cd.detectChanges()
  }

  displayCounter(value, name) {
    this.form.get(name).setValue(value);
  }

}
