import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {accountNumber} from "us-bank-account-validator";

export function AccountNumberValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    if (value.length < 5) return {lengthError: true};

    const accountNumberMinusLast4 = value.substring(0, value.length - 4);
    if (+accountNumberMinusLast4 === 0) {
      return null;
    }
    return !accountNumber(value).isValid ? {invalidAccountNumber: true} : null;
  };
}
