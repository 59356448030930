<form *ngIf="payment$ | async as payment" [formGroup]="form" class="payment-form" id="payment-form" novalidate>
  <div class="row p-3">
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="col-12 mb-3">
          <label class="p-Label Label Label--empty font-weight-bold" for="paymentTypeInput" id="paymentType">
            {{ "PaymentPage.payment-type-label" | translate }}
          </label>
          <ng-select
              (change)="updatePaymentType()"
              [attr.aria-invalid]="form.get('paymentType').invalid && (form.get('paymentType').dirty || form.get('paymentType').touched) ? 'true' : 'false'"
              [class.is-invalid]="form.get('paymentType').invalid && (form.get('paymentType').dirty || form.get('paymentType').touched)"
              [clearable]="false"
              [items]="paymentTypes$ | async"
              [multiple]="false"
              [placeholder]="'Payment.select-payment-type' | translate"
              [readonly]="readonly"
              [searchable]="false"
              bindLabel="label"
              bindValue="value"
              formControlName="paymentType"
              id="paymentTypeInput"
              labelForId="paymentType"
          >
            <!-- Template for dropdown options -->
            <ng-template let-index="index" let-item="item" ng-option-tmp>
              <span [ngClass]="{'custom-highlight': item.label === 'Current Tax Year Balance Due'}">
                {{ 'Payment.' + formatTranslationKey(item.label) | translate }}
              </span>
            </ng-template>

            <!-- Template for the selected value -->
            <ng-template let-item="item" ng-label-tmp>
              <span [ngClass]="{'custom-highlight': item.label === 'Current Tax Year Balance Due'}">
                {{ 'Payment.' + formatTranslationKey(item.label) | translate }}
              </span>
            </ng-template>
          </ng-select>
        </div>
        <div class="col-12 mb-3">
          <label class="p-Label Label Label--empty font-weight-bold" for="taxYearControl" id="taxYear">
            {{ "PaymentPage.year-label" | translate }}</label>
          <ng-select
              [attr.aria-invalid]="form.get('taxYear').invalid && (form.get('taxYear').dirty || form.get('taxYear').touched) ? 'true' : 'false'"
              [class.is-invalid]="form.get('taxYear').invalid &&(form.get('taxYear').dirty || form.get('taxYear').touched)"
              [clearable]="false"
              [items]="years$ | async"
              [multiple]="false"
              [placeholder]="'Payment.tax-year' | translate"
              [readonly]="readonly"
              [searchable]="false"
              formControlName="taxYear"
              id="taxYearControl"
              labelForId="taxYear"
          >
          </ng-select>
        </div>
      </div>
      <div *ngIf="!payment.recurringLocator && (form.get('paymentType').value === 'Payment Plan' || form.get('paymentType')?.value?.includes('Estimate')) && form.get('paymentType')?.value !=='4th Quarter Estimate'" class="row bg-gray-100 pt-3">
        <div class="col-12 mb-3">
          <div class="custom-control custom-checkbox">
            <input class="custom-control-input"
                formControlName="recurring"
                id="recurring"
                type="checkbox"/>
            <label class="custom-control-label" for="recurring" for="recurring">
              {{ 'Payment.setup-recurring-payments' | translate }}
            </label>
          </div>
        </div>
        <div *ngIf="form.get('recurring').value" class="col-12 mb-3">
          <div *ngIf="form.get('paymentType').value === 'Payment Plan'" class="row">
            <div class="col-6">
              <label class="font-weight-bolder" for="additionalPayments">
                {{ 'Payment.number-of-additional-payments' | translate }}
              </label>
              <input class="form-control text-right"
                  formControlName="additionalPayments"
                  id="additionalPayments"
                  max="12" min="0" type="number"
              />
            </div>
            <div class="col-6">
              <label class="font-weight-bolder" for="interval">
                {{ 'Payment.payment-interval' | translate }}
              </label>
              <ng-select
                  [clearable]="false"
                  [items]="paymentInterval$ | async"
                  [multiple]="false"
                  [placeholder]="'Payment.interval' | translate"
                  [searchable]="false"
                  formControlName="interval"
                  id="interval"
                  labelForId="interval">
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="col-12">
        <div class="row py-3 bg-gray-100">
          <div class="col-6 px-3">
            <label class="font-weight-bolder" for="amount">
              {{ "PaymentPage.payment-amount-label" | translate }}
            </label> <span class="text-danger pl-1">*</span>
          </div>
          <div class="col-6 px-3">
            <input (input)="updateFees()"
                [allowNegativeNumbers]="false"
                [attr.aria-invalid]="form.get('amount').invalid && (form.get('amount').dirty || form.get('amount').touched) ? 'true' : 'false'"
                [class.is-invalid]="form.get('amount').invalid && (form.get('amount').dirty || form.get('amount').touched)"
                class="form-control text-right"
                formControlName="amount"
                id="amount"
                mask="separator.2"
                onfocus="this.select()"
                prefix="$"
                thousandSeparator=","
                type="text"
            />
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row py-3 bg-gray-100">
          <div class="col-6 px-3">
            <label class="font-weight-bolder" id="feeLabel">
              {{ "CommonForm.fee-label" | translate }}
            </label>
          </div>
          <div [attr.aria-labelledby]="'feeLabel'" class="col-6 px-4 text-right">
            {{ payment?.feeAmount | currency }}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row py-3 bg-gray-100">
          <div class="col-6 px-3">
            <label class="font-weight-bolder font-16" id="totalLabel">
              {{ "CommonForm.total-label" | translate }}
            </label>
          </div>
          <div [attr.aria-labelledby]="'totalLabel totalAmount'" class="col-6 px-4 font-weight-bolder text-right font-16">
            <ui-spinner [class.active]="(isLoading$ | async)">
              {{ payment?.total | currency }}
            </ui-spinner>
          </div>
        </div>
      </div>

      <div *ngIf="form.get('amount').touched && form.get('amount').errors"
          class="col-12 invalid-feedback font-weight-bolder"
          style="display: block">
        {{ "CommonForm.amount-required" | translate }}
      </div>
      <div class="col-12">
        <div class="row pt-3">
          <div class="col-6">
            <label class="font-weight-bolder" for="paymentDate">
              {{ "CommonForm.payment-date-label" | translate }}
            </label>
          </div>
          <div class="col-6 text-right">
            <input
                (ngModelChange)="onDateChange($event)"
                [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                [maxDate]="maxDate"
                [minDate]="minDate"
                bsDatepicker
                class="form-control text-right"
                formControlName="paymentDate"
                id="paymentDate"
                placeholder="mm/dd/yyyy"
                type="text"
            />
          </div>
        </div>
      </div>
      <div *ngIf="payment.isFuturePayment" class="col-12">
        <div class="row pt-1">
          <div class="col-6">
            <label class="font-weight-bolder" for="paymentReminderDate">
              {{ "CommonForm.payment-reminder-date-label" | translate }}
            </label>
          </div>
          <div class="col-6 text-right">
            <input
                [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                [maxDate]="maxDateReminder"
                [minDate]="minDate"
                bsDatepicker
                class="form-control text-right"
                formControlName="paymentReminderDate"
                id="paymentReminderDate"
                placeholder="mm/dd/yyyy"
                type="text"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="(warningFlag$|async)" class="row p-3">
    <div class="badge text-uppercase bg-danger-500 hidden" id="payment-message" style="align-content: center; width: 100%">
      {{ 'Payment.no-additional-tax' | translate:{ currentYear: this.currentYear } }}
    </div>
  </div>

  <city-tax-payment-methods
      #cityTaxPaymentMethods
      (onCancel)="cancel()"
      (onSelectPaymentMethod)="onSelectPaymentMethod($event)"
      (onSubmit)="submit()"
      [config]="config"
      [form]="form"
      [organization]="organization"
      [paymentMethods$]="paymentMethods$"
      [paymentService]="paymentService"
      [state$]="individual$"
  >
  </city-tax-payment-methods>

  <div class="row p-3">
    <div class="col-12 d-flex justify-content-end">
      <button (click)="cancel()"
          [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'"
          [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
          [attr.aria-label]="'Buttons.cancel-btn' | translate"
          [attr.tabindex]="(isLoading$ | async) ? -1 : 0"
          [disabled]="isLoading$ | async"
          class="btn btn-dark font-weight-600 mt-3 text-white"
          id="cancel"
          role="button">
              <span>
                {{ "Buttons.cancel-btn" | translate }}
              </span>
      </button>
      <ui-spinner [class.active]="(isLoading$ | async)">
        <button (click)="submit()"
            *ngIf="!payment.isFuturePayment && !form.get('recurring').value" [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'"
            [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
            [attr.aria-label]="'Buttons.pay-now-btn' | translate"
            [attr.tabindex]="(isLoading$ | async) ? -1 : 0"
            [disabled]="isLoading$ | async"
            class="btn btn-danger font-weight-600 mt-3 ml-3"
            id="submit"
            role="button">
              <span>
                {{ "Buttons.pay-now-btn" | translate }}
              </span>
        </button>
        <button (click)="submit()"
            *ngIf="payment.isFuturePayment && !form.get('recurring').value" [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'"
            [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
            [attr.aria-label]="'Buttons.schedule-btn' | translate"
            [attr.tabindex]="(isLoading$ | async) ? -1 : 0"
            [disabled]="isLoading$ | async"
            class="btn btn-danger font-weight-600 mt-3 ml-3"
            id="schedule"
            role="button">
              <span>
                {{ "Buttons.schedule-btn" | translate }}
              </span>
        </button>
        <button (click)="submit()" *ngIf="form.get('recurring').value"
            [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'"
            [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
            [attr.aria-label]="'Buttons.next-btn' | translate"
            [attr.tabindex]="(isLoading$ | async) ? -1 : 0"
            [disabled]="isLoading$ | async"
            class="btn btn-danger font-weight-600 mt-3 ml-3"
            id="next"
            role="button">
              <span>
                {{ "Buttons.next-btn" | translate }}
              </span>
        </button>
      </ui-spinner>
    </div>
  </div>

</form>
<ng-template #noPermission>
  <ui-forbidden></ui-forbidden>
</ng-template>
