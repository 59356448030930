import {Injectable} from '@angular/core';
import {WithLoadingAndErrorHandler} from '@city-tax/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {IIndividualPaymentResponse, IIndividualPaymentsResponse,} from '../interfaces/individual-payment.interface';
import {IndividualPaymentsModel} from '../models/individual-payments.model';
import {IndividualPaymentModel} from '../models/individual-payment.model';
import {AuthService} from '@city-tax/features/auth';
import {HexaApiService} from '@hexalang/ui/core';
import {ToastrService} from "ngx-toastr";
import {loadStripe} from "@stripe/stripe-js";

@Injectable({
  providedIn: 'root',
})
export class PaymentService extends WithLoadingAndErrorHandler() {
  public payments$: Observable<IIndividualPaymentsResponse>;
  public paymentTypes$: Observable<Array<any>>;
  public paymentType: string;
  public years$: Observable<Array<String>> = of(Array.from(
    {length: (this.currentYear - 11 - this.currentYear) / -1 + 1},
    (_, i) => this.currentYear + i * -1).map((y) => y.toString()));
  public payment$: Observable<IIndividualPaymentResponse>;
  private dataStore: {
    payments: {};
    payment: IndividualPaymentModel;
    stripe: any;
    elements: any;
    client_secret: string;
  };

  constructor(private apiService: HexaApiService,
              private authService: AuthService,
              public toastrService: ToastrService
  ) {
    super(apiService, toastrService);
    this.dataStore = {
      payments: {},
      payment: null,
      stripe: null,
      elements: null,
      client_secret: null
    };
    this._payments = new BehaviorSubject({}) as BehaviorSubject<IIndividualPaymentsResponse>;
    this.payments$ = this._payments.asObservable();
    this._payment = new BehaviorSubject({}) as BehaviorSubject<IIndividualPaymentResponse>;
    this.payment$ = this._payment.asObservable();
    this._paymentTypes = new BehaviorSubject({}) as BehaviorSubject<any>;
    this.paymentTypes$ = this._paymentTypes.asObservable();
  }

  private _payments: BehaviorSubject<IIndividualPaymentsResponse>;

  get payments(): IIndividualPaymentsResponse {
    return this.dataStore.payments;
  }

  private _paymentTypes: BehaviorSubject<any>;

  get paymentTypes() {
    return this._paymentTypes.value;
  }

  private _payment: BehaviorSubject<IIndividualPaymentResponse>;


  get payment(): IndividualPaymentModel {
    return this.dataStore.payment;
  }

  get stripe() {
    return this.dataStore.stripe;
  }

  set stripe(value: any) {
    this.dataStore.stripe = value;
  }

  get client_secret() {
    return this.dataStore.client_secret;
  }

  get elements() {
    return this.dataStore.elements;
  }

  set elements(value: any) {
    this.dataStore.elements = value;
  }

  set payment(payment: IndividualPaymentModel) {
    this.dataStore.payment = payment;
    this.dataStore.client_secret = payment?.intent?.client_secret || null;
    this._payment.next(Object.assign({}, this.dataStore).payment);
  }

  get id(): string {
    if (this.dataStore.payment) {
      return this.dataStore.payment.id;
    }
    return null;
  }

  getPaymentTypes() {
    let types = [
      {label: 'Current Tax Year Balance Due', value: 'Balance Due'},
      {label: 'Prior Tax Year Balance Due', value: 'Balance Due with Notice'},
      {label: 'Extension', value: 'Extension'},
      {label: '1st Quarter Estimate', value: '1st Quarter Estimate'},
      {label: '2nd Quarter Estimate', value: '2nd Quarter Estimate'},
      {label: '3rd Quarter Estimate', value: '3rd Quarter Estimate'},
      {label: '4th Quarter Estimate', value: '4th Quarter Estimate'},
      {label: 'Payment Plan', value: 'Payment Plan'}
    ];

    const estimates = this.authService.organization.components?.individual?.estimates;
    if (!estimates) {
      types = types.filter(
        type =>
          ![
            '1st Quarter Estimate',
            '2nd Quarter Estimate',
            '3rd Quarter Estimate',
            '4th Quarter Estimate'
          ].includes(type.value)
      );
    }

    const paymentPlans = this.authService.organization.components?.individual?.paymentPlans;
    if (!paymentPlans) {
      types = types.filter(type => type.value !== 'Payment Plan');
    }

    this._paymentTypes.next(types);
  }

  public async getPayments(organizationId: string, individualId: string, params: any): Promise<IIndividualPaymentsResponse> {
    const response = await this.get(`organizations/${organizationId}/individuals/me/payments`, params)
    const payments = new IndividualPaymentsModel(response.body as IndividualPaymentsModel);
    this.dataStore.payments = payments;
    this._payments.next(Object.assign({}, this.dataStore).payments);
    return payments;
  }

  public async getPayment(organizationId: string, paymentId: string = null): Promise<IIndividualPaymentResponse> {
    paymentId = paymentId || this.id;
    const response = await this.get(`organizations/${organizationId}/individuals/me/payments/${paymentId}`);
    this.dataStore.stripe = null;
    this.dataStore.elements = null;
    return this.setDataStoreForPayment(response);
  }

  public async getReturnPayment(organizationId: string, returnId: string): Promise<IIndividualPaymentResponse> {
    const response = await this.get(`organizations/${organizationId}/individuals/me/returns/${returnId}/payments`)
    const payment = new IndividualPaymentModel(response.body as IndividualPaymentModel);
    this.payment = payment;
    return payment;
  }

  public async loadPayment(organizationId: string, id: string): Promise<IIndividualPaymentResponse> {
    const response = await this.get(`organizations/${organizationId}/individuals/me/payments/${id}`);
    const payment = new IndividualPaymentModel(response.body as IndividualPaymentModel);
    this.payment = payment;
    return payment;
  }

  public async createPayment(organizationId: string, payload: any): Promise<IIndividualPaymentResponse> {
    const response = await this.post(`organizations/${organizationId}/individuals/me/payments`, payload);
    return this.setDataStoreForPayment(response);
  }

  public async deletePayment(organizationId: string, paymentId: string): Promise<any> {
    const payment = await this.delete(`organizations/${organizationId}/individuals/me/payments/${paymentId}`);
    this.dataStore.payment = null;
    this._payment.next(Object.assign({}, this.dataStore).payment);
    const p = this.payments.data.find(p => p.id === paymentId);
    this.payments.data.splice(this.payments.data.indexOf(p), 1)
    this._payments.next(Object.assign({}, this.dataStore).payments);
    return payment;
  }

  public async updatePayment(organizationId: string, paymentId: string, payload: any): Promise<IIndividualPaymentResponse> {
    const bank = payload.bank;
    const response = await this.put(`organizations/${organizationId}/individuals/me/payments/${paymentId}`, payload);
    response.body.bank = bank;
    return this.setDataStoreForPayment(response);
  }

  public async submitPayment(organizationId: string, payload: any): Promise<IIndividualPaymentResponse> {
    const response = await this.put(`organizations/${organizationId}/individuals/me/payments/${this.id}/submit`, payload);
    return this.setDataStoreForPayment(response);
  }

  public async generateRecurring(organizationId: string, paymentId: string, payload: any = null) {
    const response = await this.put(`organizations/${organizationId}/individuals/me/payments/${paymentId}/recurring`, payload);
    const payments = new IndividualPaymentsModel(response.body as IndividualPaymentsModel);
    this.dataStore.payments = payments;
    this._payments.next(Object.assign({}, this.dataStore).payments);
    return payments;
  }

  public async submitRecurring(organizationId: string, paymentId: string, payload: any = null): Promise<IIndividualPaymentResponse> {
    const response = await this.put(`organizations/${organizationId}/individuals/me/payments/${paymentId}/recurring/submit`, payload);
    return this.setDataStoreForPayment(response);
  }


  async loadStripe() {
    if (!this.dataStore.stripe) {
      this.dataStore.stripe = await loadStripe(this.stripeKey);
    }
  }

  async setDataStoreForPayment(response: any) {
    const payment = new IndividualPaymentModel(response.body as IndividualPaymentModel);
    this.payment = payment;
    return payment;
  }

  public printReceipt(organizationId: string, paymentId: string, locator: string) {
    return this.downloadBlob(`organizations/${organizationId}/individuals/me/payments/${paymentId}/pdf`, null, null, locator);
  }

  public downloadReceipt(organizationId: string, paymentId: string, locator: string) {
    return this.openBlob(`organizations/${organizationId}/individuals/me/payments/${paymentId}/pdf`, null, locator);
  }
}
