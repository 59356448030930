<form
    (ngSubmit)="submit()"
    [formGroup]="form"
    class="d-flex flex-column flex-fill"
    novalidate
>

  <div class="modal-body">
    <div class="form-group">
      <div class="row">
        <div class="col-11">
          <label class="small font-weight-medium text-muted text-uppercase">
            {{ 'Taxpayer.percent-lw' | translate }} <span class="text-danger pl-1">*</span> {{ 'Taxpayer.example-12.5' | translate }}
          </label>
        </div>
        <div class="col-1 d-flex justify-content-end">
          <a (click)="modalRef.hide()" class="text-muted" href="javascript:">
            <svg class="icon" viewBox="0 0 24 24">
              <path d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
                    stroke="none"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-9">
        <input [round]="false" class="w-100 form-control bg-light text-right custom-height" formControlName="percent"
               type="text"
               ui-number>
      </div>
      <div class="col-3">
        <button class="btn btn-primary" 
            type="submit"
            tabindex="0" 
            role="button"
            [attr.aria-label]="'Taxpayer.calculate' | translate">
            {{ 'Taxpayer.calculate' | translate }}
        </button>
      </div>
    </div>
  </div>
</form>
