<form
    (submit)="update()"
    [formGroup]="form"
    class="d-flex flex-column flex-fill"
    novalidate
>
  <div class="modal-header align-items-center">
    <h4 class="modal-title pull-left">{{ 'Payment.update-payment-method' | translate }}</h4>
    <a (click)="bsModalRef.hide()" class="text-muted" href="javascript:">
      <svg class="icon" viewBox="0 0 24 24">
        <path
            d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
            stroke="none"
        />
      </svg>
    </a>
  </div>

  <div class="scrollable scrollable-y">
    <div class="scrollable-main">
      <div class="scrollable-content">
        <div class="modal-body">
          <div class="form-group">
            <label for="name" class="small font-weight-medium text-muted text-uppercase">
              {{ 'Payment.name' | translate }} <span class="text-danger pl-1">*</span>
            </label>
            <input [ngClass]="{
                        'is-invalid':
                          form.controls['name']?.touched &&
                          form.controls['name'].errors
                      }"
                class="form-control"
                formControlName="name"
                type="text"
                id="name"
                [attr.aria-label]="'Payment.name' | translate" 
                [attr.aria-required]="'true'" 
                [attr.aria-invalid]="form.controls['name']?.invalid && (form.controls['name']?.dirty || form.controls['name']?.touched || form.controls['name'].errors) ? 'true' : 'false'" 
               />
          </div>
          <div class="form-group">
            <div class="form-group pl-4">
              <input
                  class="custom-control-input"
                  formControlName="isDefault"
                  id="defaultPaymentMethod"
                  type="checkbox"
                  [attr.aria-label]="'Payment.default-payment-method' | translate"
              />
              <label class="custom-control-label" for="defaultPaymentMethod">
                {{ 'Payment.default-payment-method' | translate }}
              </label>
            </div>
          </div>
          <tabset type="tabs nav-tabs-line nav-fill">
            <tab [active]="paymentMethod.type === 'ach'" [disabled]="paymentMethod.type !== 'ach'">
              <ng-template tabHeading>
                <span class="font-weight-medium text-uppercase">ACH</span>
              </ng-template>
              <div class="card border-top-0">
                <div class="p-3">
                  <div id="bank">
                    <div class="row">
                      <div class="col-12 col-md-6">
                        <!-- ui-mask-show maskChar="*" -->
                        <div class="form-group">
                          <label for="routingNumber" class="font-weight-600">
                            {{ "CommonForm.routing-number-label" | translate }}
                            <span class="text-danger pl-1">*</span>
                          </label>
                          <div class="input-group" formGroupName="bank">
                            <input (change)="strip(form.get('bank.routingNumber'))"
                                (displayCounter)="displayCounter($event, 'bank.routingNumber')"
                                (keypress)="onlyNumberKey($event)"
                                [class.is-invalid]="form.get('bank.routingNumber').invalid && (form.get('bank.routingNumber').dirty || form.get('bank.routingNumber').touched)"
                                class="form-control"
                                formControlName="routingNumber"
                                maskChar="*"
                                type="text"
                                id="routingNumber"
                                [attr.aria-label]="'CommonForm.routing-number-label' | translate"
                                [attr.aria-required]="'true'"
                                [attr.aria-invalid]="form.get('bank.routingNumber').invalid && (form.get('bank.routingNumber').dirty || form.get('bank.routingNumber').touched) ? 'true' : 'false'"
                                ui-mask-show/>
                          </div>
                          <div *ngIf="form.get('bank.routingNumber').touched && form.get('bank.routingNumber').errors" class="invalid-feedback" style="display: block">
                            <div *ngIf="form.get('bank.routingNumber').errors['required'] ||form.get('bank.routingNumber').errors['pattern']">
                              {{ "CommonForm.routing-number-required"| translate }}
                            </div>
                            <div *ngIf="form.get('bank.routingNumber').errors['mustMatch']">
                              {{ "CommonForm.routing-number-not-valid"| translate }}
                            </div>
                            <div *ngIf="form.get('bank.routingNumber').errors['invalidRoutingNumber'] || form.get('bank.routingNumber').errors['lengthError'] ">
                              {{ "CommonForm.routing-number-not-valid"| translate }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="confirmRoutingNumber" class="font-weight-600">{{ "CommonForm.confirm-routing-number" | translate }} <span class="text-danger pl-1">*</span></label>
                          <div class="input-group">
                            <input (change)="strip(form.get('confirmRoutingNumber'))"
                                (displayCounter)="displayCounter($event, 'confirmRoutingNumber')"
                                (keypress)="onlyNumberKey($event)"
                                class="form-control"
                                formControlName="confirmRoutingNumber"
                                maskChar="*"
                                type="text"
                                id="confirmRoutingNumber"
                                [attr.aria-label]="'CommonForm.confirm-routing-number' | translate"
                                [attr.aria-required]="'true'"
                                ui-mask-show
                            />
                          </div>

                          <div *ngIf="form.get('confirmRoutingNumber').touched && form.get('confirmRoutingNumber').errors" class="invalid-feedback" style="display: block">
                            <div *ngIf="form.get('confirmRoutingNumber').errors['required']">
                              {{ "CommonForm.routing-number-required"| translate }}
                            </div>
                            <div *ngIf="form.get('confirmRoutingNumber').errors['mustMatch']">
                              {{ "CommonForm.routing-number-not-valid"| translate }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="accountNumber" class="font-weight-600">
                            {{ "CommonForm.account-number-label" | translate }}
                          </label><span class="text-danger pl-1">*</span>
                          <div class="input-group" formGroupName="bank">
                            <input (change)="strip(form.get('bank.accountNumber'))"
                                (displayCounter)="displayCounter($event, 'bank.accountNumber')"
                                (keypress)="onlyNumberKey($event)"
                                [class.is-invalid]=" form.get('bank.accountNumber').invalid && (form.get('bank.accountNumber').dirty || form.get('bank.accountNumber').touched)"
                                class="form-control"
                                formControlName="accountNumber"
                                maskChar="*"
                                type="text"
                                id="accountNumber"
                                [attr.aria-invalid]="form.get('bank.accountNumber').invalid && (form.get('bank.accountNumber').dirty || form.get('bank.accountNumber').touched) ? 'true' : 'false'"
                                [attr.aria-label]="'CommonForm.account-number-label' | translate"
                                [attr.aria-required]="'true'"
                                ui-mask-show
                            />
                          </div>
                          <div *ngIf="form.get('bank.accountNumber').touched && form.get('bank.accountNumber').errors " class="invalid-feedback" style="display: block">
                            <div *ngIf="form.get('bank.accountNumber').errors['required']">
                              {{ "CommonForm.account-number-required"| translate }}
                            </div>
                            <div *ngIf="form.get('bank.accountNumber').errors[ 'mustMatch'  ] || form.get('bank.accountNumber').errors['pattern']">
                              {{ "CommonForm.account-number-not-valid"| translate }}
                            </div>
                            <div *ngIf="form.get('bank.accountNumber').errors['invalidAccountNumber'] || form.get('bank.accountNumber').errors['lengthError']">
                              {{ "CommonForm.account-number-not-valid"| translate }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-md-6">
                        <div class="form-group">
                          <label for="confirmAccountNumber" class="font-weight-600">
                            {{ "CommonForm.confirm-account-number-label"| translate }}
                          </label> <span class="text-danger pl-1">*</span>
                          <div class="input-group">
                            <input
                                (change)="strip(form.get('confirmAccountNumber'))"
                                (displayCounter)="displayCounter($event, 'confirmAccountNumber')"
                                (keypress)="onlyNumberKey($event)"
                                class="form-control"
                                formControlName="confirmAccountNumber"
                                maskChar="*"
                                type="text"
                                id="confirmAccountNumber"
                                [attr.aria-label]="'CommonForm.confirm-account-number-label' | translate"
                                [attr.aria-required]="'true'"
                                ui-mask-show
                            />
                          </div>

                          <div *ngIf="form.get('confirmAccountNumber').touched && form.get('confirmAccountNumber').errors"
                              class="invalid-feedback"
                              style="display: block">
                            <div *ngIf="form.get('confirmAccountNumber').errors['required']">
                              {{ "CommonForm.account-number-required"| translate }}
                            </div>
                            <div *ngIf="form.get('confirmAccountNumber').errors['mustMatch']">
                              {{ "CommonForm.account-number-not-valid"| translate }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group" formGroupName="bank">
                      <label for="accountType">{{ "CommonForm.account-type-label" | translate }} <span class="text-danger pl-1">*</span></label>
                      <label *ngFor="let rad of accountType" for="accountType-{{rad.value}}"
                          class="radio-inline">
                        <input [class.is-invalid]=" form.get('bank.accountType')?.invalid &&(form.get('bank.accountType')?.dirty ||form.get('bank.accountType')?.touched)"
                            class="radio m-1 text-sm-start"
                            formControlName="accountType"
                            name="accountType"
                            type="radio"
                            value="{{ rad.value }}"
                            id="accountType-{{rad.value}}"  
                            [attr.aria-label]="'rad.label' | translate" 
                            [attr.aria-invalid]="form.get('bank.accountType')?.invalid && (form.get('bank.accountType')?.dirty || form.get('bank.accountType')?.touched) ? 'true' : 'false'" 
                            [attr.aria-required]="'true'" 
                        />{{ rad.label | translate }}
                      </label>
                      <div *ngIf="form.get('bank.accountType').touched &&form.get('bank.accountType').errors"
                          class="invalid-feedback"
                          style="display: block">
                        <div *ngIf="form.get('bank.accountType').errors['required']">
                          {{ 'Payment.account-type-required' | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
            <tab [active]="paymentMethod.type === 'card' || paymentMethod.type === 'bank'" [disabled]="paymentMethod.type !== 'card'">
              <ng-template tabHeading>
                <span class="font-weight-medium text-uppercase">{{ 'Payment.credit-card' | translate }}</span>
              </ng-template>
              <div class="d-flex align-items-center justify-content-center">
                <city-tax-card-details *ngIf="paymentMethod" [selectedPaymentMethod]="paymentMethod"></city-tax-card-details>
              </div>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer justify-content-end">
    <a (click)="bsModalRef.hide()" 
      class="btn btn-link" 
      href="javascript:"
      role="button" 
      [attr.aria-label]="'Payment.close' | translate" 
      tabindex="0">
      {{ 'Payment.close' | translate }}
    </a>
    <button [disabled]=" (isLoading$ | async)" 
       class="btn btn-primary" 
       type="submit"
       role="button" 
       [attr.aria-label]="'Payment.update' | translate"
       [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'" 
       [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'" 
       tabindex="0">
      <ui-spinner [class.active]="isLoading$ | async" [attr.aria-hidden]="true">
        {{ 'Payment.update' | translate }}
      </ui-spinner>
    </button>
  </div>
</form>
