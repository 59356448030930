import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HexaDetailComponent } from '@hexalang/ui/core';
import { AuthService } from 'libs/features/auth/src/lib/services/auth.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

@Component({
  selector: 'city-tax-email-validation-popup',
  templateUrl: './email-validation-popup.component.html',
  styleUrls: ['./email-validation-popup.component.scss']
})
export class EmailValidationPopupComponent  extends HexaDetailComponent implements OnInit {
  public form: FormGroup;
  public individual;
  public business;
  isLoading$ = this.authService.isLoading$;
  public userType: 'individual' | 'business';

  constructor(
    injector: Injector,
    private authService: AuthService,
    private modal: BsModalService,
    private formBuilder: FormBuilder,
    public toastrService: ToastrService,
    private router: Router
  ) { 
    super(injector);
  }

  ngOnInit(): void {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.form = this.formBuilder.group({
      email: [null, [Validators.required, Validators.pattern(emailPattern)]],
    });
    if (this.individual) {
      this.userType = 'individual';
      this.form.patchValue({ email: this.individual.email });
    } else if (this.business) {
      this.userType = 'business';
      this.form.patchValue({ email: this.business.email });
    } }

  async submit() {
    const email = this.form.get('email').value;
    const data ={ email }

    if(this.form.valid){
      if (this.individual) {
        await this.authService.updateIndividual(this.authService.organization.id, data);
      } else if (this.business) {
        await this.authService.updateBusiness(this.authService.organization.id, data);
      }
      this.toastrService.success(
        "Email update successful!",
        "Email Update"
      );
      this.modal?.hide();
    }
  }

  hide() {
    this.modal?.hide();
    const route = this.userType === 'individual'
    ? '/individual/payments/history'
    : '/business/payments/history';
    this.router.navigate([route]);
  }

}
