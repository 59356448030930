import {HexaWithLoadingAndErrorHandler} from "@hexalang/ui/core";

export function WithLoadingAndErrorHandler<T extends Constructor<{}>>(Base: T = class {
} as T) {
  const ExtendedBase = HexaWithLoadingAndErrorHandler(Base); // First, extend Base with HexaWithLoadingAndErrorHandler

  return class extends ExtendedBase {
    // Now extend that result with your additional properties and methods
    public currentYear = new Date().getFullYear();
    public taxYear: number = this.currentYear - 1;
    public nextYear: number = this.currentYear + 1;

    sum(array: any, field: any = null) {
      if (!array || !array.length) return 0;
      let total = 0;
      const values = !field
        ? array.map((d) => +d)
        : array.map((d) => +(d[field] || 0));
      if (values.length === 0) return 0;
      for (const value of values) {
        total += +value;
      }
      return +total.toFixed(2);
    }

    decimalValue(value: any) {
      if (!value) return 0;
      return +(+value).toFixed(2);
    }

    constructor(...args: any[]) {
      super(...args);
    }

    public async handleAndDisplayError(error: any): Promise<any> {
      console.log("handleAndDisplayError", error);
      try {
        const handledError = await super.handleAndDisplayError(error);
        this["toastrService"].error(handledError.message, handledError.name);
        return handledError;
      } catch (err) {
        this["toastrService"].error("An unexpected error occurred", "Error");
        return err;
      }
    }
  };
}

type Constructor<T = {}> = new (...args: any[]) => T;
