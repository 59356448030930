import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {HexaDetailComponent} from "@hexalang/ui/core";
import {FormArray, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {DateFormControl, DateRangeValidator, EPermission, PaymentHelper} from "@city-tax/shared";
import {BehaviorSubject, Observable} from "rxjs";
import {IndividualService} from "../../../../../services/individual.service";
import {AuthService} from "@city-tax/features/auth";
import {NgxPermissionsService} from "ngx-permissions";
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ToastrService} from 'ngx-toastr';
import * as moment from "moment";

@Component({
  selector: "city-tax-feature-individual-filing-mi-excluded-tax-withheld-form",
  templateUrl:
    "./individual-filing-mi-excluded-tax-withheld-form.component.html",
  styleUrls: [
    "./individual-filing-mi-excluded-tax-withheld-form.component.scss",
  ],
})
export class IndividualFilingMIExcludedTaxWithheldFormComponent
  extends HexaDetailComponent
  implements OnInit {
  public w2form: FormGroup;
  public title$;
  public modalRef?: BsModalRef;
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public taxYear = this.individualService.taxYear;
  public currentYear = this.individualService.currentYear;
  public ePermission = EPermission;
  public hasPermission$: Observable<boolean>;
  public isLoading$ = this.individualService.isLoading$;
  public isUploading$ = this.individualService.isUploading$;
  public error = null;
  public taxDocument = this.authService.organization.taxDocument;
  public city = this.authService.organization;
  public individualReturn$ = this.individualService.individualReturn$;
  public individualReturn = this.individualService.individualReturn;
  public individualType = ["T", "S"];
  public individualTypeForSingle = ["T"];
  changeDetectorRef: any;
  updatingValue = false;
  @Input("isEdit") isEdit: boolean = false;
  @Input("row") row: any;
  @Input("index") index: number;
  @Output("updateW2Form") updateW2Form = new EventEmitter();
  @Output("addW2Form") addW2Form = new EventEmitter();
  types = ["taxpayer", "spouse"];
  public minDate = moment(`${this.taxYear}-01-01`, 'YYYY-MM-DD').endOf('day').toDate();
  public maxDate = moment(`${this.taxYear}-12-31`, 'YYYY-MM-DD').endOf('day').toDate();

  constructor(
    injector: Injector,
    private individualService: IndividualService,
    private authService: AuthService,
    public permissionService: NgxPermissionsService,
    private formBuilder: FormBuilder,
    private modal: BsModalService,
    private paymentHelper: PaymentHelper,
    private modalService: BsModalService,
    public toasterService: ToastrService
  ) {
    super(injector);
  }

  get filing() {
    return this.individualService.individualReturn?.filing;
  }

  get localityInfo(): FormArray {
    return this.w2form.get("w2LocalityInfo") as FormArray;
  }

  ngOnInit(): void {
    //this.checkPermission();
    this.w2form = this.formBuilder.group({
      index: this.index,
      id: null,
      type: [null, Validators.required],
      ssn: null,
      employersFederalId: null,
      employersName: null,
      multipleLocations: null,
      dateWorkedTo: new DateFormControl(),
      dateWorkedFrom: new DateFormControl(),
      employersAddress: this.formBuilder.group({
        addressLine1: null,
        addressLine2: null,
        aptNumber: null,
        city: null,
        state: null,
        postalCode: null,
      }),
      totalWages: null,
      wagesNotInBox: null,
      code: null,
      partYearHoursOnJob: null,
      partYearVacation: null,
      partYearDaysHoursWorked: null,
      partYearDaysHoursWorkedCity: null,
      partYearPercentDaysHours: null,
      partYearWagesEarnedCity: null,
      taxableWagesNonResident: null,
      excludedWages: null,
      reasonWagesNonTaxable: null,
      excludibleWages: null,
      excludeLocality: null,
      taxableWages: null,
      grandTotalWages: null,
      w2LocalityInfo: this.formBuilder.array([], [Validators.required]),
    });
    if (this.row) {
      if (this.row && JSON.stringify(this.row) !== "{}") {
        this.row.militaryWagesFlag = this.getBoolean(this.row.militaryWagesFlag);
        this.row.dateWorkedTo = this.wholeDate(this.row.dateWorkedTo);
        this.row.dateWorkedFrom = this.wholeDate(this.row.dateWorkedFrom);
        this.w2form.patchValue(this.row);
        for (const locality of this.row.w2LocalityInfo) {
          this.loadW2LocalityInfo(locality);
        }
      }
      this.w2form.setValidators(DateRangeValidator('dateWorkedFrom', 'dateWorkedTo'));
    }

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      this.isDisabled$.next(true)
      this.w2form.disable();
    }
  }

  wholeDate(dateTime) {
    if (!dateTime) return null;
    return moment(dateTime).format('YYYY-MM-DD');
  }

  addW2LocalityInfo() {
    const a: any = {
      taxWithheldLocalityName: [null, Validators.required],
      localityTaxRate: null,
      wagesBox18: null,
      taxWithheldBox19: null,
      creditForTaxesPaid: null,
    };
    this.localityInfo.push(this.formBuilder.group(a));
  }

  loadW2LocalityInfo(locality) {

    if (locality?.taxWithheldLocalityName) {
      const a: any = {
        taxWithheldLocalityName: [
          locality?.taxWithheldLocalityName,
          Validators.required,
        ],
        localityTaxRate: locality?.taxRate,
        wagesBox18: locality?.wagesBox18,
        taxWithheldBox19: locality?.taxWithheldBox19,
        creditForTaxesPaid: locality?.creditForTaxesPaid,
      };
      this.localityInfo.push(this.formBuilder.group(a));
    }
  }

  removeW2LocalityInfo(index) {
    const value = this.localityInfo.value;
    this.localityInfo.removeAt(index - 1);
    if (this.localityInfo.length === 1) {
      this.w2form.get("multipleLocations").setValue(false);
    }
  }

  onExcludeLocalityChange(event) {
    if (event.target.checked) {
      this.w2form.get("w2LocalityInfo").clearValidators();
      this.w2form.get("w2LocalityInfo").updateValueAndValidity();
    } else {
      this.w2form.get("w2LocalityInfo").setValidators([Validators.required]);
      this.w2form.get("w2LocalityInfo").updateValueAndValidity();
    }
  }


  public trackByFn = (index, item) => {
    return index;
  };

  update() {
    const payload = {...this.w2form.getRawValue()};
    delete payload.w2LocalityInfo;
    const localityInfo = this.localityInfo.controls.map(group => group.value);
    payload['w2LocalityInfo'] = localityInfo;
    this.updateW2Form.emit(payload);
  }

  add() {
    const payload = {...this.w2form.getRawValue()};
    delete payload.w2LocalityInfo;
    const localityInfo = this.localityInfo.controls.map(group => group.value);
    payload['w2LocalityInfo'] = localityInfo;
    if (!payload.excludeLocality) {
      payload.taxWithheldLocalityName = payload.w2LocalityInfo.length > 1 ? 'Multiple' : payload.w2LocalityInfo[0].taxWithheldLocalityName;
    } else {
      payload.taxWithheldLocalityName = 'Excluded';
      payload.w2LocalityInfo = [{
        taxWithheldLocalityName: 'Excluded',
        localityTaxRate: 0,
        wagesBox18: 0,
        taxWithheldBox19: 0,
        creditForTaxesPaid: 0,
      }];
    }
    this.addW2Form.emit(payload);
  }

  getPayload() {

  }

  hide() {
    this.modal?.hide();
  }

  checkDateError(controlName: string) {
    return this.w2form.hasError('dateRangeInvalid') ? 'is-invalid' : '';
  }

  submit() {
    this.getFormValidationErrors(this.w2form);
    const line13 = Number(this.w2form.get('partYearDaysHoursWorked').value);
    const line14 = Number(this.w2form.get('partYearDaysHoursWorkedCity').value);
    const line15 = Number(this.w2form.get('partYearPercentDaysHours').value);
    if (this.individualReturn.residency !== 'N' && this.w2form.get('partYearWagesEarnedCity').value > 0 && !(this.individualReturn?.files?.some(file => file.type === 'Wage-Other')) && !((Math.round(line13) === line14 && line15 === 100))) {
      this.toasterService.error("Upload the required documents.");
    } else {
      if (this.w2form.valid) {
        if (this.isEdit) {
          this.update();
        } else {
          this.add();
        }
      } else {
        this.w2form.markAllAsTouched();
      }
    }
  }

  // public onMultipleLocations(event: any) {
  //   const addressControl = this.w2form.get('employersAddress.addressLine1');
  //   const isChecked = event.target.checked;
  //   if (isChecked) {
  //     addressControl.setValidators([Validators.required]);
  //   } else {
  //     addressControl.clearValidators();
  //   }
  //   addressControl.updateValueAndValidity();
  // }

  setSSN(ssn?: string) {
    this.w2form.patchValue({ssn: ssn});
  }

  getBoolean(any) {
    return (
      any === true ||
      any === "true" ||
      any === "TRUE" ||
      any === "X" ||
      any === "x" ||
      any === 1 ||
      any === "1" ||
      any === "Y"
    );
  }

  calculate() {
    const cell8CtlValue = this.paymentHelper.decimalValue(this.w2form.get('totalWages').value);
    const cell9CtlValue = this.paymentHelper.decimalValue(this.w2form.get('wagesNotInBox').value);

    const cell11CtlValue = this.paymentHelper.decimalValue(this.w2form.get('partYearHoursOnJob').value);
    const cell12CtlValue = this.paymentHelper.decimalValue(this.w2form.get('partYearVacation').value);

    const cell14CtlValue = this.paymentHelper.decimalValue(this.w2form.get('partYearDaysHoursWorkedCity').value);
    const cell18CtlValue = this.paymentHelper.decimalValue(this.w2form.get('excludedWages').value);

    let cell13CtlValue = 0;
    let cell15CtlValue = 0;
    let cell16CtlValue = 0;
    let cell17CtlValue = 0;
    let cell20CtlValue = 0;
    let cell21CtlValue = 0;

    const cell13Ctl = this.w2form.get('partYearDaysHoursWorked');
    const cell15Ctl = this.w2form.get('partYearPercentDaysHours');
    const cell16Ctl = this.w2form.get('partYearWagesEarnedCity');

    const cell17Ctl = this.w2form.get('taxableWagesNonResident');
    const cell20Ctl = this.w2form.get('excludibleWages');
    const cell21Ctl = this.w2form.get('taxableWages');

    if (this.individualReturn.residency !== 'R') {

      cell13CtlValue = cell11CtlValue - cell12CtlValue;
      cell13Ctl.setValue(cell13CtlValue.toFixed(0));

      if (cell13CtlValue > 0) {
        cell15CtlValue = cell14CtlValue / cell13CtlValue * 100;
        cell15Ctl.setValue(cell15CtlValue.toFixed(0));
      } else {
        cell15Ctl.setValue(null);
      }

      cell16CtlValue = (cell8CtlValue + cell9CtlValue) * this.paymentHelper.decimalValue((cell15CtlValue / 100), 2);
      cell16Ctl.setValue(cell16CtlValue.toFixed(0));

      cell17CtlValue = cell13CtlValue > 0 && cell14CtlValue >= 0 ? (cell8CtlValue + cell9CtlValue) - cell16CtlValue : 0;
      cell17Ctl.setValue(cell17CtlValue.toFixed(0));
    }

    cell20CtlValue = cell18CtlValue + cell17CtlValue;
    cell20Ctl.setValue(cell20CtlValue.toFixed(0));

    cell21CtlValue = cell8CtlValue + cell9CtlValue - cell20CtlValue;
    cell21Ctl.setValue(cell21CtlValue.toFixed(0));

  }

  async uploadDocument(type: string, event: any) {
    const media = {
      file: event.target.files[0],
      mimeType: event.target.files[0].type,
    };
    try {
      this.individualReturn = await this.individualService.uploadFile(this.authService.organization.id, this.individualService.individualReturn.id, type, media);
    } catch (error) {
    }
  }

  async delete(file: any) {
    try {
      this.individualReturn = await this.individualService.deleteFile(
        this.authService.organization.id,
        this.individualService.individualReturn.id,
        file.id
      );
    } catch (error) {

    }
  }

  getFormValidationErrors(form) {
    if (form && form.controls) {
      Object.keys(form.controls).forEach((key) => {
        const control = form.get(key);
        if (control.controls) {
          this.getFormValidationErrors(this.w2form.get(key));
        }
        const controlErrors: ValidationErrors = control.errors;
        if (controlErrors != null) {
          Object.keys(controlErrors).forEach((keyError) => {
            console.log(
              "Key control: " +
              key +
              ", keyError: " +
              keyError +
              ", err value: ",
              controlErrors[keyError]
            );
          });
        }
      });
    }
  }


}
