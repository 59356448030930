import {FormGroup, ValidatorFn} from "@angular/forms";

export function MatchValidator(
  controlName: string,
  matchingControlName: string,
  formGroupName: string = null
): ValidatorFn {
  return (formGroup: FormGroup) => {
    let control = formGroup.controls[controlName];
    if (!control) {
      let parent = formGroup.controls[formGroupName] as FormGroup;
      control = parent.controls[controlName];
    }

    let matchingControl = formGroup.controls[matchingControlName];
    if (!matchingControl) {
      let parent = formGroup.controls[formGroupName] as FormGroup;
      matchingControl = parent.controls[matchingControlName];
    }

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({mustMatch: true});
    } else {
      matchingControl.setErrors(null);
    }
    return null;
  };
}
