import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'city-tax-feature-courtesy-withholding',
  templateUrl: './courtesy-withholding.component.html',
  styleUrls: ['./courtesy-withholding.component.scss']
})
export class CourtesyWithholdingComponent
  implements OnInit {
  public form: FormGroup;

  constructor(
    public modalRef: BsModalRef,
    private formBuilder: FormBuilder,
  ) {

  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      multipleLocations: [null, Validators.required],
    });
  }

  public submit() {
    this.modalRef.content = this.form.get('multipleLocations').value;
    this.modalRef.hide()
  }
}
