import {ScheduleHelper} from "./schedule.helper";
import {LossCarryForwardHelper} from "./loss-carry-forward.helper";


export class WorksheetBHelper {
  public worksheetB: any;
  public scheduleC: any;
  public scheduleE: any;
  public scheduleK1: any;
  public scheduleMisc: any;
  public lossCarryForward: any;
  public incomeField: any;
  private filingCity: any;
  private isResident: any;

  constructor(private paymentHelper, taxYear, filingCity, isResident, worksheetB) {
    this.worksheetB = worksheetB || {};
    this.filingCity = filingCity;
    this.isResident = isResident;
    this.incomeField = this.isResident ? 'incomeAmount' : 'amount';
    this.scheduleC = new ScheduleHelper(paymentHelper, this.worksheetB, 'otherIncomeTypeScheduleCDetail', this.isResident, this.filingCity);
    this.scheduleE = new ScheduleHelper(paymentHelper, this.worksheetB, 'otherIncomeTypeScheduleEDetail', this.isResident, this.filingCity);
    this.scheduleK1 = new ScheduleHelper(paymentHelper, this.worksheetB, 'otherIncomeTypeScheduleK1Detail', this.isResident, this.filingCity);
    this.scheduleMisc = new ScheduleHelper(paymentHelper, this.worksheetB, 'otherIncomeTypeScheduleMiscDetail', this.isResident, this.filingCity);
    this.lossCarryForward = new LossCarryForwardHelper(taxYear, this.worksheetB, this.pendingIncome);
  }

  get otherCity() {
    return this.scheduleC.otherCity || this.scheduleE.otherCity || this.scheduleK1.otherCity || this.scheduleMisc.otherCity;
  }

  get schedules() {
    return this.scheduleC.schedule.concat(this.scheduleE.schedule, this.scheduleK1.schedule, this.scheduleMisc.schedule);
  }

  get pendingIncome() {
    return this.paymentHelper.sum(this.schedules, 'incomeAmount');
  }

  get totalIncomeOrLoss() {
    const total = this.paymentHelper.sum(this.schedules, 'incomeAmount');
    return total + this.lossCarryForward?.municipalityLossCarryForward;
  }

  get totalMunicipalityTaxableIncome() {
    const schedules = this.scheduleE.schedule.concat(this.scheduleK1.schedule, this.scheduleMisc.schedule);
    const total = this.paymentHelper.sum(schedules, this.incomeField);
    const totalC = this.scheduleC.municipalityTaxableIncome
    return total + totalC + this.lossCarryForward?.municipalityLossCarryForward;
  }

  get totalTaxCreditAllowedForTaxPaidToOtherCities() {
    //remove return this.worksheetB.totalTaxCreditAllowedForTaxPaidToOtherCities || this.paymentHelper.sum(this.schedules, 'taxesPaidAmount');
    return this.paymentHelper.sum(this.schedules, 'taxesPaidAmount');
  }

  getSchedulesY() {
    return this.scheduleC.schedulesYFields;
  }

}
