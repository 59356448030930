<form (submit)="submit()" [formGroup]="scheduleForm" novalidate>
  <div class="main pt-2">
    <div class="d-flex justify-content-between px-3 py-1">
      <h3>{{ isEdit ? 'Update' : 'Add' }} {{ 'Taxpayer.schedule' | translate }} </h3>
      <div class="">
        <button (click)="hide()" aria-label="Close" class="close pull-right" type="button" tabindex="0" >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="content">
      <div class="bg-gray-100">
        <div class="row">
          <div class="col-12">
            <div class="card card-body mb-6">
              <div class="form-group">
                <label for="typeSelect">{{ 'Taxpayer.type' | translate }}</label>
                <ng-select id="typeSelect"
                    [clearable]="false"
                    [items]="types"
                    [readonly]="isEdit"
                    bindLabel="text"
                    bindValue="name"
                    class="text-capitalize"
                    formControlName="type"
                    [attr.aria-label]="'Taxpayer.type' | translate">
                </ng-select>
              </div>
              <div *ngIf="selectedType=='scheduleC'" class="mb-2" formGroupName="scheduleC">
                <div class="row">
                  <div class="col-12">
                    <div class="row">
                      <div class="form-group col-6">
                        <label for="companyName">{{ 'Taxpayer.company-name' | translate }}</label>
                        <input id="companyName"
                            class="form-control h-25px" 
                            formControlName="companyName"
                            type="text"
                            [attr.aria-label]="'Taxpayer.company-name' | translate">
                      </div>
                      <div class="form-group col-6">
                        <label for="companyFID">{{ 'Taxpayer.company-fid' | translate }}</label>
                        <input id="companyFID"
                            class="form-control h-25px"
                            formControlName="companyFederalId"
                            mask="00-0000000"
                            placeholder="00-0000000"
                            type="text"
                            [attr.aria-label]="'Taxpayer.company-fid' | translate">
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-6">
                        <label for="municipalitySelect">{{ 'Taxpayer.municipality' | translate }}</label>
                        <ng-select id="municipalitySelect"
                            (clear)="onClearDynamic()" 
                            (search)="onSearchDynamic($event)"
                            [bindLabel]="'locality'"
                            [bindValue]="'locality'"
                            [items]="localities.length > 0 ? localities: []"
                            [loading]="isLoading$ | async" class="w-100 form-control p-0"
                            formControlName="municipality"
                            [placeholder]="'Taxpayer.type-to-search' | translate"
                            [attr.aria-label]="'Taxpayer.municipality' | translate"></ng-select>
                      </div>
                    </div>
                    <div class="row pb-2">
                      <div class="col-12  font-weight-bold">
                        {{ 'Taxpayer.total-income-earned' | translate: { name: config?.name } }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col-4">
                        <label>
                          {{ 'Taxpayer.total-income' | translate }}
                        </label>
                        <input [decimals]="city.components.individual.fractionDigits"

                            class="w-100 form-control text-right custom-height"
                            formControlName="incomeAmount"
                            name="incomeAmount"
                            type="text" ui-number
                        >
                      </div>
                      <div *ngIf="individualReturn.nonResident" class="form-group col-4">
                        <label>{{ config?.name }} {{ 'Taxpayer.taxable-income' | translate }}</label>
                        <div class="d-flex inline-block">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right custom-height"
                              formControlName="amount"
                              name="amount"
                              type="text" ui-number

                          >
                          <a (click)="percentModal()" *ngIf="!(isDisabled$|async)"
                              class="btn btn-link btn-sm" href="javascript:;"
                              tooltip="Calculate Taxable Income">
                            <svg class="icon icon-fx" viewBox="0 0 16 16">
                              <path
                                  d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"/>
                              <path
                                  d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"/>
                            </svg>
                          </a>
                        </div>
                      </div>
                      <div class="form-group col-4">
                        <label>{{ 'Taxpayer.taxes-paid-amount' | translate }}</label>
                        <input [decimals]="city.components.individual.fractionDigits"
                            [ngClass]="{'is-invalid': scheduleForm.get('scheduleC.taxesPaidAmount').errors?.greaterThanIncome}"

                            class="w-100 form-control text-right custom-height"
                            formControlName="taxesPaidAmount"
                            name="taxesPaidAmount" type="text"
                            ui-number
                        >
                      </div>
                    </div>
                    <div *ngIf="scheduleForm.get('scheduleC.taxesPaidAmount').errors?.greaterThanIncome"
                        class="row">
                      <div class="col-12 text-danger font-weight-bold">
                        {{ 'Taxpayer.taxes-paid-error' | translate:{ taxRate: (taxRate | percent:'0.2-2') } }}
                      </div>
                    </div>
                    <div class="row p-2">{{ 'Taxpayer.taxes-paid-amount-if-paid-tax' | translate }}
                    </div>
                    <div class="row bg-light border">
                      <div class="col-11 py-2 font-weight-bold">
                        <span class="pr-4"></span>
                        <input
                            class="pl-4 custom-control-input"
                            formControlName="scheduleYChecked"
                            id="scheduleY"
                            type="checkbox"
                        />
                        <label
                            class="custom-control-label"
                            for="scheduleY"
                        >{{ 'Taxpayer.schedule' | translate }} Y</label
                        >
                      </div>
                      <div class="col-1 d-flex justify-content-end align-items-center">
                      </div>
                    </div>
                    <ng-container *ngIf="scheduleYChecked" class="mb-2" formGroupName="scheduleY">
                      <div class="row">
                        <div class="form-group col-1 mb-0"></div>
                        <div class="form-group col-5 mb-0"></div>
                        <div class="form-group col-2 mb-0">{{ 'Taxpayer.located' | translate }}</div>
                        <div class="form-group col-2 mb-0">{{ 'Taxpayer.located-in' | translate }}</div>
                        <div class="form-group col-2 mb-0">{{ 'Taxpayer.percentage' | translate }}</div>
                      </div>

                      <div class="row mb-0">
                        <div class="form-group col-1 mb-1"></div>
                        <div class="form-group col-5 mb-1"></div>
                        <div class="form-group col-2 mb-1">{{ 'Taxpayer.everywhere' | translate }}</div>
                        <div class="form-group col-2 mb-1">{{ 'Taxpayer.municipality' | translate }}</div>
                        <div class="form-group col-2 mb-1">(B ÷ A)</div>
                      </div>
                      <div class="row">
                        <div class="form-group col-1 mb-1">{{ 'Taxpayer.step' | translate }} 1.</div>
                        <div class="form-group col-5 mb-1">{{ 'Taxpayer.average-original-cost-property' | translate }}
                        </div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="averageOriginalCostOfPropertyLocatedEverywhere"
                              type="text" ui-number
                          >
                        </div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="averageOriginalCostOfPropertyLocatedInMunicipality"
                              type="text" ui-number
                          >
                        </div>
                        <div class="form-group col-2 mb-1">
                        </div>
                      </div>
                      <div class="row">
                        <div class="form-group col-1 mb-1"></div>
                        <div class="form-group col-5 mb-1">{{ 'Taxpayer.gross-annual-rent' | translate }}
                        </div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"
                              class="form-control text-right"
                              formControlName="grossAnnualRentPaidLocatedEverywhere"
                              type="text" ui-number
                          >
                        </div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="grossAnnualRentPaidLocatedInMunicipality"
                              type="text" ui-number
                          >
                        </div>
                        <div class="form-group col-2 mb-1"></div>
                      </div>

                      <div class="row">
                        <div class="form-group col-1 mb-1"></div>
                        <div class="form-group col-5 mb-1">{{ 'Taxpayer.total-step' | translate }} 1</div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="totalStep1LocatedEverywhere"
                              type="text" ui-number
                          >
                        </div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="totalStep1LocatedInMunicipality"
                              type="text" ui-number
                          >
                        </div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="totalStep1Percentage"
                              type="text" ui-number
                          >
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-1 mb-1">{{ 'Taxpayer.step' | translate }} 2</div>
                        <div class="form-group col-5 mb-1">{{ 'Taxpayer.wages-salaries-compensation' | translate }}
                        </div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="step2LocatedEverywhere"
                              type="text" ui-number
                          >
                        </div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="step2LocatedInMunicipality"
                              type="text" ui-number
                          >
                        </div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="step2Percentage"
                              type="text" ui-number
                          >
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-1 mb-1">{{ 'Taxpayer.step' | translate }} 3</div>
                        <div class="form-group col-5 mb-1">{{ 'Taxpayer.gross-receipts-sales' | translate }}
                        </div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="step3LocatedEverywhere"
                              type="text" ui-number
                          >
                        </div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="step3LocatedInMunicipality"
                              type="text" ui-number
                          >
                        </div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="step3Percentage"
                              type="text" ui-number
                          >
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-1 mb-1">{{ 'Taxpayer.step' | translate }} 4</div>
                        <div class="form-group col-9 mb-1">{{ 'Taxpayer.total-percentages' | translate }}
                        </div>
                        <div class="form-group col-2 mb-1">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="step4Percentage"
                              type="text" ui-number
                          ></div>
                      </div>

                      <div class="row">
                        <div class="form-group col-1 mb-0">{{ 'Taxpayer.step' | translate }} 5</div>
                        <div class="form-group col-9 mb-0">{{ 'Taxpayer.average-percentages' | translate }}
                        </div>
                        <div class="form-group col-2 mb-0">
                          <input [decimals]="city.components.individual.fractionDigits"

                              class="form-control text-right"
                              formControlName="step5Percentage"
                              type="text" ui-number
                          >
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedType=='scheduleE'" class="mb-2" formGroupName="scheduleE">
                <div class="row">
                  <div class="form-group col-6">
                    <label for="propertyAddress">{{ 'Taxpayer.property-address' | translate }}</label>
                    <input id="propertyAddress" class="form-control h-25px" formControlName="companyName" type="text">
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label for="municipality">{{ 'Taxpayer.municipality' | translate }}</label>
                    <ng-select id="municipality"
                        (clear)="onClearDynamic()" 
                        (search)="onSearchDynamic($event)"
                        [bindLabel]="'locality'"
                        [bindValue]="'locality'"
                        [items]="localities.length > 0 ? localities: []"
                        [loading]="isLoading$ | async" class="w-100 form-control p-0"
                        formControlName="municipality"
                        [placeholder]="'Taxpayer.type-to-search' | translate"></ng-select>
                  </div>
                  <div class="form-group col-6">
                    <label for="incomeType">{{ 'Taxpayer.income-type' | translate }}</label>
                    <ng-select id="incomeType" 
                        [clearable]="true"
                        [items]="eIncomeTypes"
                        formControlName="miscIncomeType">
                    </ng-select>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-12  font-weight-bold">
                    {{ 'Taxpayer.total-income-earned' | translate: { name: config?.name } }}
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-4">
                    <label for="totalIncome">{{ 'Taxpayer.total-income' | translate }}</label>
                    <input id="totalIncome"
                        [decimals]="city.components.individual.fractionDigits"
                        class="w-100 form-control text-right custom-height"
                        formControlName="incomeAmount"
                        name="incomeAmount"
                        type="text" 
                        ui-number
                    >
                  </div>
                  <div *ngIf="individualReturn.nonResident" class="form-group col-4">
                    <label for="taxableIncome">{{ config?.name }} {{ 'Taxpayer.taxable-income' | translate }}</label>
                    <div class="d-flex inline-block">
                      <input id="taxableIncome"
                          [decimals]="city.components.individual.fractionDigits"
                          class="w-100 form-control text-right custom-height"
                          formControlName="amount"
                          name="amount"
                          type="text" 
                          ui-number
                      >
                      <a (click)="percentModal()" class="btn btn-link btn-sm" href="javascript:;"
                          tooltip="Calculate Taxable Income">
                        <svg class="icon icon-fx" viewBox="0 0 16 16">
                          <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"/>
                          <path
                              d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <label for="taxesPaidAmount">{{ 'Taxpayer.taxes-paid-amount' | translate }}</label>
                    <input id="taxesPaidAmount"
                        [decimals]="city.components.individual.fractionDigits"
                        [ngClass]="{'is-invalid': scheduleForm.get('scheduleE.taxesPaidAmount').errors?.greaterThanIncome}"
                        class="w-100 form-control text-right custom-height"
                        formControlName="taxesPaidAmount"
                        name="taxesPaidAmount"
                        type="text"
                        ui-number
                    >
                  </div>
                </div>
                <div *ngIf="scheduleForm.get('scheduleE.taxesPaidAmount').errors?.greaterThanIncome"
                    class="row">
                  <div class="col-12 text-danger font-weight-bold">
                    {{ 'Taxpayer.taxes-paid-error' | translate:{ taxRate: (taxRate | percent:'0.2-2') } }}
                  </div>
                </div>
                <div class="row p-2">{{ 'Taxpayer.taxes-paid-amount-if-paid-tax' | translate }}
                </div>
              </div>
              <div *ngIf="selectedType=='scheduleK1'" class="mb-2" formGroupName="scheduleK1">
                <div class="row">
                  <div class="form-group col-6">
                    <label for="companyName">{{ 'Taxpayer.company-name' | translate }}</label>
                    <input id="companyName" class="form-control h-25px" formControlName="companyName" type="text">
                  </div>
                  <div class="form-group col-6">
                    <label for="companyFederalId">{{ 'Taxpayer.company-fid' | translate }}</label>
                    <input id="companyFederalId"
                        class="form-control h-25px"
                        formControlName="companyFederalId"
                        mask="00-0000000"
                        placeholder="00-0000000"
                        type="text">
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label for="municipality">{{ 'Taxpayer.municipality' | translate }}</label>
                    <ng-select id="municipality"
                        (clear)="onClearDynamic()" 
                        (search)="onSearchDynamic($event)"
                        [bindLabel]="'locality'"
                        [bindValue]="'locality'"
                        [items]="localities.length > 0 ? localities: []"
                        [loading]="isLoading$ | async" class="w-100 form-control p-0"
                        formControlName="municipality"
                        [placeholder]="'Taxpayer.type-to-search' | translate"></ng-select>
                  </div>
                  <div class="form-group col-6">
                    <label for="miscIncomeType">{{ 'Taxpayer.income-type' | translate }}</label>
                    <ng-select id="miscIncomeType"
                        [clearable]="true"
                        [items]="k1IncomeTypes"
                        formControlName="miscIncomeType">
                    </ng-select>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-12  font-weight-bold">
                    {{ 'Taxpayer.total-income-earned' | translate: { name: config?.name } }}
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-4">
                    <label for="incomeAmount">{{ 'Taxpayer.total-income' | translate }}</label>
                    <input id="incomeAmount"
                        [decimals]="city.components.individual.fractionDigits"
                        class="w-100 form-control text-right custom-height"
                        formControlName="incomeAmount"
                        name="incomeAmount"
                        type="text" ui-number
                    >
                  </div>
                  <div *ngIf="individualReturn.nonResident" class="form-group col-4">
                    <label for="taxableIncome">{{ config?.name }} {{ 'Taxpayer.taxable-income' | translate }}</label>
                    <div class="d-flex inline-block">
                      <input id="taxableIncome"
                          [decimals]="city.components.individual.fractionDigits"
                          class="w-100 form-control text-right custom-height"
                          formControlName="amount"
                          name="amount"
                          type="text"
                          ui-number
                      >
                      <a (click)="percentModal()" class="btn btn-link btn-sm" href="javascript:;"
                          tooltip="Calculate Taxable Income">
                        <svg class="icon icon-fx" viewBox="0 0 16 16">
                          <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"/>
                          <path
                              d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <label for="taxesPaidAmount">{{ 'Taxpayer.taxes-paid-amount' | translate }}</label>
                    <input id="taxesPaidAmount" 
                        [decimals]="city.components.individual.fractionDigits"
                        [ngClass]="{'is-invalid': scheduleForm.get('scheduleK1.taxesPaidAmount').errors?.greaterThanIncome}"
                        class="w-100 form-control text-right custom-height"
                        formControlName="taxesPaidAmount"
                        name="taxesPaidAmount" type="text"
                        ui-number
                    >
                  </div>
                </div>
                <div *ngIf="scheduleForm.get('scheduleK1.taxesPaidAmount').errors?.greaterThanIncome"
                    class="row">
                  <div class="col-12 text-danger font-weight-bold">
                    {{ 'Taxpayer.taxes-paid-error' | translate:{ taxRate: (taxRate | percent:'0.2-2') } }}
                  </div>
                </div>
                <div class="row p-2">{{ 'Taxpayer.taxes-paid-amount-if-paid-tax' | translate }}
                </div>
              </div>
              <div *ngIf="selectedType=='scheduleMisc'" class="mb-2" formGroupName="scheduleMisc">
                <div class="row">
                  <div class="form-group col-6">
                    <label for="companyName">{{ 'Taxpayer.company-name' | translate }}</label>
                    <input id="companyName" class="form-control h-25px" formControlName="companyName" type="text">
                  </div>
                  <div class="form-group col-6">
                    <label for="companyFederalId">{{ 'Taxpayer.company-fid' | translate }}</label>
                    <input id="companyFederalId" 
                        class="form-control h-25px"
                        formControlName="companyFederalId"
                        mask="00-0000000"
                        placeholder="00-0000000"
                        type="text">
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label for="municipality">{{ 'Taxpayer.municipality' | translate }}</label>
                    <ng-select id="municipality" 
                        (clear)="onClearDynamic()" 
                        (search)="onSearchDynamic($event)"
                        [bindLabel]="'locality'"
                        [bindValue]="'locality'"
                        [items]="localities.length > 0 ? localities: []"
                        [loading]="isLoading$ | async" class="w-100 form-control p-0"
                        formControlName="municipality"
                        [placeholder]="'Taxpayer.type-to-search' | translate"></ng-select>
                  </div>
                  <div class="form-group col-6">
                    <label for="miscIncomeType">{{ 'Taxpayer.income-type' | translate }}</label>
                    <ng-select id="miscIncomeType"
                        [clearable]="true"
                        [items]="miscIncomeTypes"
                        formControlName="miscIncomeType">
                    </ng-select>
                  </div>
                </div>
                <div class="row pb-2">
                  <div class="col-12  font-weight-bold">
                    {{ 'Taxpayer.total-income-earned' | translate: { name: config?.name } }}
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-4">
                    <label for="incomeAmount">{{ 'Taxpayer.total-income' | translate }}</label>
                    <input id="incomeAmount"
                        [decimals]="city.components.individual.fractionDigits"
                        class="w-100 form-control text-right custom-height"
                        formControlName="incomeAmount"
                        name="incomeAmount"
                        type="text" ui-number
                    >
                  </div>
                  <div *ngIf="individualReturn.nonResident" class="form-group col-4">
                    <label for="amount">{{ config?.name }} {{ 'Taxpayer.taxable-income' | translate }}</label>
                    <div class="d-flex inline-block">
                      <input [decimals]="city.components.individual.fractionDigits"
                          class="w-100 form-control text-right custom-height"
                          formControlName="amount"
                          name="amount"
                          type="text"
                          id="amount"
                          ui-number                  
                      >
                      <a (click)="percentModal()" class="btn btn-link btn-sm" href="javascript:;"
                          tooltip="Calculate Taxable Income">
                        <svg class="icon icon-fx" viewBox="0 0 16 16">
                          <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"/>
                          <path
                              d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <label for="taxesPaidAmount">{{ 'Taxpayer.taxes-paid-amount' | translate }}</label>
                    <input id="taxesPaidAmount"
                        [decimals]="city.components.individual.fractionDigits"
                        [ngClass]="{'is-invalid': scheduleForm.get('scheduleMisc.taxesPaidAmount').errors?.greaterThanIncome}"
                        class="w-100 form-control text-right custom-height"
                        formControlName="taxesPaidAmount"
                        name="taxesPaidAmount" type="text"
                        ui-number
                    >
                  </div>
                </div>
                <div *ngIf="scheduleForm.get('scheduleMisc.taxesPaidAmount').errors?.greaterThanIncome"
                    class="row">
                  <div class="col-12 text-danger font-weight-bold">
                    {{ 'Taxpayer.taxes-paid-error' | translate:{ taxRate: (taxRate | percent:'0.2-2') } }}
                  </div>
                </div>
                <div class="row p-2">{{ 'Taxpayer.taxes-paid-amount-if-paid-tax' | translate }}
                </div>
              </div>
              <div *ngIf="selectedType=='lossCarryForward'" class="mb-2" formGroupName="lossCarryForward">
                <div class="row bg-light border">
                  <div class="col-11 py-2 font-weight-bold">{{ 'Taxpayer.loss-carry-forward' | translate }}</div>
                  <div class="col-1 d-flex justify-content-end align-items-center">
                    <button (click)="addLossCarryForward($event)" 
                        *ngIf="!(isDisabled$|async)"
                        class="btn btn-primary font-weight-600 ml-3 py-1"
                        tabindex="0"
                        [attr.aria-label]="'Taxpayer.loss-carry-forward' | translate"
                        role="button">{{ 'Taxpayer.add' | translate }}
                    </button>
                  </div>
                </div>
                <ng-container *ngIf="lossCarryForward.controls?.length">
                  <div class="row pt-2">
                    <label class="col-3">{{ 'Taxpayer.amount' | translate }}</label>
                    <label class="col text-center">{{ 'Taxpayer.tax-year' | translate }} 1 P</label>
                    <label class="col text-center">{{ 'Taxpayer.tax-year' | translate }} 2 P</label>
                    <label class="col text-center">{{ 'Taxpayer.tax-year' | translate }} 3 P</label>
                    <label class="col text-center">{{ 'Taxpayer.tax-year' | translate }} 4 P</label>
                    <label class="col text-center">{{ 'Taxpayer.tax-year' | translate }} 5 P</label>
                    <label class="col-1"> </label>
                  </div>
                </ng-container>
                <ng-container *ngFor="let lossCarryF of lossCarryForward.controls;index as i">

                  <div [formGroupName]="i" class="row">
                    <div class="form-group col-3">
                      <input [allowNegativeNumbers]="false"
                          [decimals]="city.components.individual.fractionDigits"

                          class="w-100 form-control text-right custom-height"
                          formControlName="lossCarryForwardAmount"
                          id="lossCarryForwardAmount"
                          type="text"
                          ui-number
                      >
                    </div>
                    <div class="form-check col">
                      <input class="w-100 form-check-input"
                          formControlName="taxYear1P"
                          id="taxYear1P"
                          name="taxYear1P"
                          type="checkbox"
                      >
                    </div>
                    <div class="form-check col">
                      <input class="w-100 form-check-input"
                          formControlName="taxYear2P"
                          id="taxYear2P"
                          name="taxYear2P"
                          type="checkbox"
                      >
                    </div>
                    <div class="form-check col">
                      <input class="w-100  form-check-input" formControlName="taxYear3P"
                          id="taxYear3P" name="taxYear3P" type="checkbox">
                    </div>
                    <div class="form-check col">
                      <input class="w-100  form-check-input" formControlName="taxYear4P"
                          id="taxYear4P" name="taxYear4P" type="checkbox">
                    </div>
                    <div class="col form-check">
                      <input class="w-100 form-check-input"
                          formControlName="taxYear5P"
                          id="taxYear5P"
                          name="taxYear5P"
                          type="checkbox"
                      >
                    </div>
                    <div class="col-1">
                   <span (click)="removeLossCarryForward(i)" *ngIf="i!==0" class=" cursor-pointer">
                    <svg class=" icon icon-sm fill-danger-500" viewBox="0 0 24 24">
                            <path
                                d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                stroke="none"/>
                          </svg>
                      </span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <a (click)="hide()" 
       class="btn btn-link" 
       href="javascript:"
       tabindex="0"
       role="button"
       [attr.aria-label]="'Buttons.cancel-btn' | translate"
      >
       {{ "Buttons.cancel-btn" | translate }}
    </a>
    <button *ngIf="!(isDisabled$|async)"
        [disabled]="(isLoading$ | async)"
        class="btn btn-primary f-bold"
        type="submit"
        [attr.aria-disabled]="isLoading$ | async"
        [attr.aria-busy]="isLoading$ | async"
        tabindex="0"
        [attr.aria-label]="isEdit ? 'Update button' : 'Submit button'"
    >
      <ui-spinner [class.active]="isLoading$ | async">
        {{ isEdit ? "Update" : "Buttons.submit-btn" | translate }}
      </ui-spinner>
    </button>
  </div>
</form>
