<div *ngIf="(individualReturn$ | async)?.status === 'Completed'"
    class="card p-3 position-absolute return-filed m-0 text-center">
    {{ 'Taxpayer.filed' | translate }}
</div>
<div *ngIf="(individualReturn$ | async)?.status === 'Mailed'"
    class="card p-3 position-absolute return-filed m-0 text-center">
    {{ 'Taxpayer.mailed' | translate }}
</div>
<div class="p-4">
  <div class="card bg-transparent border-0 shadow-none taxpayer-form">
    <div class="row m-0">
      <div class="col-12">
        <form *ngIf="individualReturn$ | async as individualReturn" [formGroup]="form" novalidate>
          <div class="row align-items-center py-2 bg-white border border-bottom-0">
            <div class="col-3">
              <h2 class="mb-0">CF-1040</h2>
            </div>
            <div class="col-7">
              <div class="text-uppercase text-left py-1">
                <p class="">{{ 'Taxpayer.individual-return-due' | translate }} {{ (individualReturn$|async)?.eFileDeadline | utcDate:'MMMM dd, YYYY' }}</p>
              </div>
            </div>
            <div class="col-2 d-flex justify-content-end">
              {{ 'Taxpayer.how-to-use-this-site' | translate }}
              <a class="pl-2" href="https://cdn.insourcetax.com/Michigan Fill In Instructions.pdf" target="_help" tooltip="Help">
                <svg class="text-primary" fill="currentColor" height="20" viewBox="0 0 16 16" width="20">
                  <path
                      d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 0 1 0-2.098zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z"/>
                  <path
                      d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                </svg>
              </a>
            </div>
          </div>
          <div class="row border bg-white">
            <table class="table">
              <tbody>
              <tr>
                <td></td>
                <td class="border-right" colspan="2">
                  <div class="row w-100">
                    <div class="col-2">
                      <span class="text-uppercase font-weight-bold">{{ 'Taxpayer.income' | translate }}</span>
                    </div>
                    <div class="col-10">
                        <span class="text-uppercase font-weight-bold d-block text-center">
                          {{ 'Taxpayer.round-figures-nearest-dollar' | translate }}
                        </span>
                      <small class="center d-block text-center">
                        {{ 'Taxpayer.drop-amounts-under' | translate }}<br/>{{ 'Taxpayer.increase-amounts' | translate }}
                      </small>
                    </div>
                  </div>
                </td>
                <td class="border-right">
                      <span class="small text-center w-100 d-block">{{ 'Taxpayer.column' | translate }} A
                        <br/>
                        {{ 'Taxpayer.federal-return-data' | translate }}</span>
                </td>
                <td class="border-right">
                      <span class="small text-center w-100 d-block">
                        {{ 'Taxpayer.column' | translate }} B<br/>
                        {{ 'Taxpayer.exclusions-adjustments' | translate }}
                      </span>
                </td>
                <td class="border-right">
                      <span class="small text-center w-100 d-block">
                        {{ 'Taxpayer.column' | translate }} C<br/>
                        {{ 'Taxpayer.taxable-income' | translate }}
                      </span>
                </td>
              </tr>
              <tr>
                <td>1.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">{{ 'Taxpayer.wages-salaries-tips-w2' | translate }}
                    </div>
                    <div class="col-1 text-right">1
                    </div>
                  </div>
                </td>
                <td class="border-right p-2" formGroupName="wagesSalariesTips">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="grandTotalScheduleWages"
                      groupName="wagesSalariesTips"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2" formGroupName="wagesSalariesTips">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="grandTotalScheduleExcludibleWages"
                      groupName="wagesSalariesTips"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2" formGroupName="wagesSalariesTips">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="grandTotalScheduleTaxableWages"
                      groupName="wagesSalariesTips"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr formGroupName="taxableInterest">
                <td>2.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">{{ 'Taxpayer.taxable-interest' | translate }}
                    </div>
                    <div class="col-1 text-right">2
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="interestFromFederal"
                      groupName="taxableInterest"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="interestTotalExcludibleIncome"
                      groupName="taxableInterest"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="taxableInterestIncome"
                      groupName="taxableInterest"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr formGroupName="ordinaryDividendsFederal">
                <td>3.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">
                      {{ 'Taxpayer.ordinary-dividends' | translate }}
                    </div>
                    <div class="col-1 text-right">
                      3
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="dividendIncomeFromFederalReturn"
                      groupName="ordinaryDividendsFederal"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="dividendTotalExcludible"
                      groupName="ordinaryDividendsFederal"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="dividendTaxableIncome"
                      groupName="ordinaryDividendsFederal"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <!--              <tr>-->
              <!--                <td>4.</td>-->
              <!--                <td class="border-right" colspan="2">-->
              <!--                  <div class="row">-->
              <!--                    <div class="col-11">-->
              <!--                      Taxable refunds, credits or offsets of state and local income taxes-->
              <!--                    </div>-->
              <!--                    <div class="col-1 text-right">-->
              <!--                      4-->
              <!--                    </div>-->
              <!--                  </div>-->
              <!--                </td>-->
              <!--                <td class="border-right p-2">-->

              <!--                  <ui-input-selector-->
              <!--                      (change)="calculate()"-->
              <!--                      [controlForm]="form"-->
              <!--                      [data]="(individualReturn$|async)?.review"-->
              <!--                      class="w-100"-->

              <!--                      controlClass="form-control bg-light text-right"-->
              <!--                      controlName="taxableRefundsCreditsFederal"-->
              <!--                      isNumeric="true"-->
              <!--                  >-->
              <!--                  </ui-input-selector>-->
              <!--                </td>-->
              <!--                <td class="border-right p-2">-->
              <!--                  <ui-input-selector-->
              <!--                      (change)="calculate()"-->
              <!--                      [controlForm]="form"-->
              <!--                      [data]="(individualReturn$|async)?.review"-->
              <!--                      [readonly]="individualReturn.residency==='R'"-->
              <!--                      allowNegativeNumbers="true"-->
              <!--                      class="w-100"-->
              <!--                      controlClass="form-control bg-light text-right"-->
              <!--                      controlName="taxableRefundsCreditsAdjustment"-->
              <!--                      isNumeric="true"-->
              <!--                  >-->
              <!--                  </ui-input-selector>-->
              <!--                </td>-->
              <!--                <td class="p-2">-->

              <!--                </td>-->
              <!--              </tr>-->
              <tr>
                <td>4.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">
                      {{ 'Taxpayer.alimony-received' | translate }}
                    </div>
                    <div class="col-1 text-right">
                      4
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="alimonyReceivedFederal"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      class="w-100"

                      controlClass="form-control bg-light text-right"
                      controlName="alimonyReceivedAdjustments"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="alimonyReceivedTax"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr>
                <td>5.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">
                      {{'Taxpayer.bussiness-income-or-loss' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      5
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">

                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="businessIncomeFederal"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="businessIncomeAdjustments"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="businessIncomeTax"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr formGroupName="capitalGainsOrLosses">
                <td>6.</td>
                <td class="border-right" colspan="2">
                  <div class="row align-items-center">
                    <div class="col-11">{{'Taxpayer.capital-gain-or-loss' | translate}}</div>
                    <div class="col-1 text-right">6</div>
                  </div>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalCapitalGainsAndLossesFromFederalReturn"
                      groupName="capitalGainsOrLosses"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalExcludibleCapitalGains"
                      groupName="capitalGainsOrLosses"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="taxableCapitalGainsAndLosses"
                      groupName="capitalGainsOrLosses"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr formGroupName="otherGainsLoses">
                <td>7.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">
                      {{'Taxpayer.others-gain-or-loss' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      7
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalOtherGainsAndLosses"
                      groupName="otherGainsLoses"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="otherTotalExcludibleGainsAndLosses"
                      groupName="otherGainsLoses"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="otherTaxableGainsAndLosses"
                      groupName="otherGainsLoses"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr formGroupName="taxableIraDistributions">
                <td>8.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">
                      {{'Taxpayer.taxable-ira-distributions' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      8
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">

                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="taxableIraDistributionsFederal"
                      groupName="taxableIraDistributions"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalExcludibleIraDistributions"
                      groupName="taxableIraDistributions"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="taxableIraDistributionsIncome"
                      groupName="taxableIraDistributions"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr formGroupName="taxablePensionDistributions">
                <td>9.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">
                      {{'Taxpayer.taxable-pensions-and-annuities' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      9
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">

                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="taxablePensionDistributionsFederal"
                      groupName="taxablePensionDistributions"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <!-- ALWAYS EDITABLE -->
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"

                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalExcludiblePension"
                      groupName="taxablePensionDistributions"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="taxablePensionDistributionsIncome"
                      groupName="taxablePensionDistributions"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr formGroupName="rentalRealEstate">
                <td>10.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">
                      {{'Taxpayer.rental-real-estate' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      10
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">

                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="rentalRealEstateFederal"
                      groupName="rentalRealEstate"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalAdjustments"
                      groupName="rentalRealEstate"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="rentalRealEstateIncome"
                      groupName="rentalRealEstate"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr formGroupName="sCorporationDistributions">
                <td>11.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">{{'Taxpayer.subchapter-s-corporation-distributions' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      11
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">

                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      class="w-100"

                      controlClass="form-control bg-light text-right"
                      controlName="subchapterSCorporationDistributionsExclusions"
                      groupName="sCorporationDistributions"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="subchapterSCorporationDistributionsIncome"
                      groupName="sCorporationDistributions"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr formGroupName="farmIncomeLoss">
                <td>12.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">
                      {{'Taxpayer.farm-income-or-loss' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      12
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalFarmIncomeNetProfit"
                      groupName="farmIncomeLoss"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="excludibleNetProfit"
                      groupName="farmIncomeLoss"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalFarmIncomeLoss"
                      groupName="farmIncomeLoss"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr>
                <td>13.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">{{'Taxpayer.gambling-income' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      13
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="gamblingIncomeFederal"
                      groupName="gamblingIncome"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="gamblingIncomeAdjustments"
                      groupName="gamblingIncome"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="gamblingIncomeTax"
                      groupName="gamblingIncome"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr>
                <td>14.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">{{'Taxpayer.partnership-estate-trust-etc' | translate}}</div>
                    <div class="col-1 text-right">
                      14
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">

                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="partnershipIncomeFederal"
                      groupName="partnership"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="partnershipIncomeAdjustments"
                      groupName="partnership"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="partnershipIncomeTax"
                      groupName="partnership"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr formGroupName="otherIncome">
                <td>15.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">
                      {{'Taxpayer.other-income' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      15
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">

                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="otherIncomeFederal"
                      groupName="otherIncome"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      [readonly]="individualReturn.residency==='R'"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="otherIncomeExclusions"
                      groupName="otherIncome"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="otherIncomeSubject"
                      groupName="otherIncome"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr>
                <td>16.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">
                      {{'Taxpayer.total-additions-lines-2-15' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      16
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalAdditionsFederal"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalAdditionsAdjustments"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalAdditionsTax"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr>
                <td>17.</td>
                <td class="border-right" colspan="2">
                  <div class="row">
                    <div class="col-11">
                      {{'Taxpayer.total-income-1-15' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      17
                    </div>
                  </div>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalIncomeFederal"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="border-right p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalIncomeAdjustments"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalIncomeTax"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr>
                <td>18.</td>
                <td class="border-right" colspan="4">
                  <div class="row">
                    <div class="col-11">
                      {{'Taxpayer.total-deductions-subtractions' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      18
                    </div>
                  </div>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalDeductions"
                      isNumeric="true"

                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr>
                <td>19.</td>
                <td class="border-right" colspan="4">
                  <div class="row">
                    <div class="col-11">
                      {{'Taxpayer.total-income-after-deduction' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      19
                    </div>
                  </div>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalIncomeAfterDeductions"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr>
                <td>20.</td>
                <td class="border-right" colspan="4">
                  <div class="row">
                    <div class="col-10">
                      {{'Taxpayer.exemptions' | translate}}
                    </div>
                    <div class="col-2 d-flex text-right nowrap inline-block">
                      <span class="pr-2">20a</span>
                      <ui-input-selector
                          [controlForm]="form"
                          [data]="(individualReturn$|async)?.review"
                          class="w-100"
                          controlClass="form-control bg-light text-right"
                          controlName="numberOfExemptions"
                          readonly>
                      </ui-input-selector>
                      <span class="pl-2"> 20b</span>
                    </div>
                  </div>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="amountForExemptions"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr>
                <td>21.</td>
                <td class="border-right" colspan="4">
                  <div class="row">
                    <div class="col-11">
                      {{'Taxpayer.total-income-after-deduction-20b-19' | translate}}
                    </div>
                    <div class="col-1 text-right">
                      21
                    </div>
                  </div>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalIncomeSubjectToTax"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr>
                <td>22.</td>
                <td class="border-right" colspan="4">
                  <div class="row">
                    <div *ngIf="individualReturn.residency==='R'" class="col-11">
                      {{'Taxpayer.tax-at-multiple-line-21-by-resident-tax' | translate:{ taxRate: taxRate | percent:'0.2-2' } }}
                    </div>
                    <div *ngIf="individualReturn.residency!=='R'" class="col-11">
                      {{'Taxpayer.tax-at-multiple-line-21-by-nonresident-tax' | translate:{taxRate: (taxRate / 2) | percent:'0.2-2'} }}
                    </div>
                    <div class="col-1 text-right">
                      22b
                    </div>
                  </div>
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalTax"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr>
                <td>23.</td>
                <td class="justify-items-center" colspan="3">
                  <table>
                    <tr>
                      <td class="border-top-0 pr-5" rowspan="2">
                        {{'Taxpayer.payments-and-credits' | translate}}
                      </td>
                      <td class="border-top-0"></td>
                      <td class="border-top-0 text-center align-bottom-flex">
                        {{'Taxpayer.citytax-withheld' | translate: {city} }}
                      </td>
                      <td class="border-top-0"></td>
                      <td class="border-top-0 text-center align-bottom-flex">
                        {{'Taxpayer.other-tax-payments' | translate}}
                      </td>
                      <td class="border-top-0"></td>
                      <td class="border-top-0 text-center ">
                        <div class="align-bottom-flex">
                          {{'Taxpayer.credit-for-tax-paid-to-another-city' | translate}}<br>
                          <a href="https://cdn.insourcetax.com/Credit Form.pdf">Credit Calculation Form</a>
                        </div>
                      </td>
                    </tr>
                    <tr class="flex justify-content-end">
                      <td class="border-top-0">23a</td>
                      <td class="border-top-0">
                        <ui-input-selector
                            (change)="calculate()"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="taxWithheldByEmployer"
                            isNumeric="true"
                            readonly="true"
                        >
                        </ui-input-selector>
                      </td>
                      <td class="border-top-0">23b</td>
                      <td class="border-top-0">
                        <ui-input-selector
                            (change)="calculate()"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            controlClass="form-control bg-light fs-3 text-right d-inline h4"
                            controlName="paymentsOnPriorYearDeclarationTax"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                      <td class="border-top-0">23c</td>
                      <td class="border-top-0">
                        <ui-input-selector
                            (change)="calculate()"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            controlClass="form-control bg-light text-right"
                            controlName="grandTotalCredit"
                            groupName="creditForTaxPaidAnotherCity"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                  </table>
                </td>

                <td class="border-right text-right">
                  <span class="pr-2">{{'Taxpayer.total-payment-and-credit' | translate}}</span> 23
                </td>
                <td class="p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalPaymentsCredits"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>

              <tr>
                <td> 24.</td>

                <td class="justify-items-center" colspan="3">
                  <table>
                    <tbody>
                    <tr>
                      <td class="border-top-0 pr-5" rowspan="2">
                        {{'Taxpayer.interest-and-penalty-for' | translate}}
                      </td>
                      <td class="border-top-0"></td>
                      <td class="border-top-0 text-center">
                        {{'Taxpayer.interests' | translate}}
                      </td>
                      <td class="border-top-0"></td>
                      <td class="border-top-0 text-center">
                        {{'Taxpayer.penalty' | translate}}
                      </td>
                    </tr>
                    <tr>
                      <!--                       rowspan -->
                      <td class="border-top-0">24a</td>
                      <td class="border-top-0">
                        <ui-input-selector
                            (change)="calculate()"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            controlClass="form-control bg-light text-right"
                            controlName="penaltyOnUnderpaidEstimatesOrLateFiledReturns"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                      <td class="border-top-0">24b</td>
                      <td class="border-top-0">
                        <ui-input-selector
                            (change)="calculate()"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            controlClass="form-control bg-light text-right"
                            controlName="interestOnUnderpaidEstimatesOrLateFiledReturns"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>

                    </tr>
                    </tbody>
                  </table>
                </td>
                <td class="text-right border-right">
                  <span class="pr-2">{{'Taxpayer.total-interest-and-penalty' | translate}} </span> 24c
                </td>
                <td class="p-2">
                  <ui-input-selector
                      (change)="calculate()"
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalInterestAndPenaltyOnUnderpaidEstimatesOrLateFilesReturns"
                      isNumeric="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <tr>
                <td>25.</td>
                <td><span class="font-weight-bold pr-2">{{'Taxpayer.tax-due' | translate}}</span></td>
                <td>
                  {{'Taxpayer.amount-you-owe-text' | translate}} {{ city?.toUpperCase() }}
                </td>
                <td></td>
                <td class="text-right border-right">
                  <span class="font-weight-bold pr-2">{{'Taxpayer.pay-with-return' | translate}}</span> 25
                </td>
                <td class="p-2">
                  <ui-input-selector
                      [controlForm]="form"
                      [data]="(individualReturn$|async)?.review"
                      allowNegativeNumbers="true"
                      class="w-100"
                      controlClass="form-control bg-light text-right"
                      controlName="totalTaxDue"
                      isNumeric="true"
                      readonly="true"
                  >
                  </ui-input-selector>
                </td>
              </tr>
              <ng-container *ngIf="form.get('totalOverPayment').value > 0">
                <tr>
                  <td>26.</td>
                  <td><span class="font-weight-bold pr-2">{{'Taxpayer.overpayment-title' | translate}}</span></td>
                  <td>
                    {{'Taxpayer.taxt-overpayment-subtract-22b-24c-23d' | translate}}
                  </td>
                  <td></td>
                  <td class="text-right border-right">
                    <span class="font-weight-bold pr-2">{{'Taxpayer.overpayment-title' | translate}}</span> 26
                  </td>
                  <td class="p-2">
                    <ui-input-selector
                        [controlForm]="form"
                        [data]="(individualReturn$|async)?.review"
                        class="w-100"
                        controlClass="form-control bg-light text-right"
                        controlName="totalOverPayment"
                        isNumeric="true"
                    >
                    </ui-input-selector>
                  </td>
                </tr>
                <tr>
                  <td>27.</td>
                  <td class="justify-items-center" colspan="3">
                    <table>
                      <tbody>
                      <tr>
                        <td class="border-top-0 pr-5" rowspan="2">
                          {{'Taxpayer.amount-of-overpayment-donated' | translate}}
                        </td>
                        <td class="border-top-0"></td>
                        <td class="border-top-0 text-center">
                          {{ config?.components?.individual.donation1 || 'Taxpayer.donation' | translate }} 1
                        </td>
                        <td class="border-top-0"></td>
                        <td class="border-top-0 text-center">
                          {{ config?.components?.individual.donation2 || '' }}
                        </td>
                        <td class="border-top-0"></td>
                        <td class="border-top-0 text-center">
                          {{ config?.components?.individual.donation3 || '' }}
                        </td>
                      </tr>
                      <tr>

                        <td class="border-top-0">27a</td>
                        <td class="border-top-0">
                          <ui-input-selector
                              (change)="calculate()"
                              [controlForm]="form"
                              [data]="(individualReturn$|async)?.review"
                              class="w-100"
                              controlClass="form-control bg-light text-right"
                              controlName="donation35A"
                              isNumeric="true"
                          >
                          </ui-input-selector>
                        </td>
                        <td class="border-top-0"><span *ngIf="config?.components?.individual.donation2">27b</span></td>
                        <td class="border-top-0">
                          <ng-container *ngIf="config?.components?.individual.donation2">
                            <ui-input-selector
                                (change)="calculate()"
                                [controlForm]="form"
                                [data]="(individualReturn$|async)?.review"
                                class="w-100"
                                controlClass="form-control bg-light text-right"
                                controlName="donation35B"
                                isNumeric="true"
                            >
                            </ui-input-selector>
                          </ng-container>
                        </td>
                        <td class="border-top-0"><span *ngIf="config?.components?.individual.donation3">27c</span></td>
                        <td class="border-top-0">
                          <ng-container *ngIf="config?.components?.individual.donation3">
                            <ui-input-selector
                                (change)="calculate()"
                                [controlForm]="form"
                                [data]="(individualReturn$|async)?.review"
                                class="w-100"
                                controlClass="form-control bg-light text-right"
                                controlName="donation35C"
                                isNumeric="true"
                            >
                            </ui-input-selector>
                          </ng-container>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </td>
                  <td class="border-right text-right">
                    27d
                  </td>
                  <td class="p-2">
                    <input [class.is-invalid]="form?.errors?.donationTotalMismatch"
                        class="w-100 form-control bg-light text-right"
                        formControlName="totalDonations"
                        readonly
                        type="text"
                        ui-number
                    >
                    <!--                    <ui-input-selector-->
                    <!--                        [controlForm]="form"-->
                    <!--                        [class.is-invalid]="form?.errors?.donationTotalMismatch"-->
                    <!--                        [data]="(individualReturn$|async)?.review"-->
                    <!--                        [readonly]="!form.get('totalOverPayment').value"-->
                    <!--                        class="w-100"-->
                    <!--                        controlClass="form-control bg-light text-right"-->
                    <!--                        controlName="totalDonations"-->
                    <!--                        isNumeric="true"-->
                    <!--                    >-->
                    <!--                    </ui-input-selector>-->
                  </td>
                </tr>

                <tr>
                  <td>28.</td>
                  <td colspan="2">
                    {{'Taxpayer.amount-of-overpayment-credited' | translate}}
                    {{ +(individualReturn$|async)?.taxYear + 1 }}
                  </td>
                  <td class="text-right border-right" colspan="2">
                    <span class="font-weight-bold pr-2">{{'Taxpayer.amount-of-credit-to' | translate}} {{ +(individualReturn$|async)?.taxYear + 1 }} &gt;&gt;</span> 28
                  </td>
                  <td class="p-2">
                    <input [class.is-invalid]="form?.errors?.totalMismatch"
                        class="w-100 form-control bg-light text-right"
                        formControlName="amountOfOverpaymentCreditedForward"
                        type="text"
                        ui-number
                    >
                  </td>
                </tr>
                <tr>
                  <td>29.</td>
                  <td colspan="2">
                    {{'Taxpayer.amount-of-overpayment-refunded-1' | translate}}
                  </td>
                  <td class="text-right border-right" colspan="2">
                    <span class="font-weight-bold pr-2">{{'Taxpayer.refund-amount' | translate}} &gt;&gt;</span> 29
                  </td>
                  <td class="p-2">
                    <input [class.is-invalid]="form?.errors?.totalMismatch"
                        class="w-100 form-control bg-light text-right"
                        formControlName="overpaymentToBeRefunded"
                        type="text"
                        ui-number
                    >
                  </td>
                </tr>

                <tr *ngIf="form?.errors?.totalMismatch">
                  <td class="text-right text-danger font-weight-medium" colspan="6">
                    {{'Taxpayer.sum-of-line27d-line28-line29-equal-to-line26' | translate}}
                  </td>
                </tr>

                <ng-container *ngIf="form.get('overpaymentToBeRefunded').value>0">
                  <tr>
                    <td rowspan="3">30.</td>
                    <td colspan="2" rowspan="3">
                      {{'Taxpayer.direct-deposit-refund' | translate}}
                    </td>
                    <td class="align-top text-nowrap inline">
                      <input
                          class="custom-control-input"
                          formControlName="refundDirectDeposit"
                          id="refund"
                          type="checkbox"
                          [attr.aria-label]="'30.a Refund (direct deposit)'"
                          [attr.aria-required]="'true'"
                      />
                      <label
                          class="custom-control-label"
                          for="refund"> 30.a {{'Taxpayer.refund-direct-deposit' | translate}}
                      </label>
                    </td>
                    <td class="text-right text-right">
                      30.c {{'Taxpayer.routing-number' | translate}}
                    </td>
                    <td class="align-top">
                      <ui-input-selector
                          [controlForm]="form"
                          [data]="(individualReturn$|async)?.review"
                          class="w-100"
                          controlClass="form-control bg-light text-right"
                          controlName="routingNumber"
                      >
                      </ui-input-selector>
                    </td>
                  </tr>
                  <tr class="border-white">
                    <!--              rowspan---    <td></td>-->
                    <td class="text-nowrap inline border-top-0">
                      <!--                    <input-->
                      <!--                        class="custom-control-input"-->
                      <!--                        formControlName="payTaxDueDirectWithdrawal"-->
                      <!--                        id="due"-->
                      <!--                        type="checkbox"-->
                      <!--                    />-->
                      <!--                    <label class="custom-control-label" for="due">-->
                      <!--                      31.b Pay tax due (direct deposit)-->
                      <!--                    </label>-->
                    </td>
                    <td class="text-right text-right border-top-0">
                      30.d {{'Taxpayer.account-number' | translate}}
                    </td>
                    <td class="border-top-0">
                      <ui-input-selector
                          [controlForm]="form"
                          [data]="(individualReturn$|async)?.review"
                          class="w-100"
                          controlClass="form-control bg-light text-right"
                          controlName="accountNumber"
                      >
                      </ui-input-selector>
                    </td>
                  </tr>
                  <tr>
                    <!--              rowspan---    <td></td>-->
                    <td class="text-right text-right border-top-0">30.d {{'Taxpayer.account-type' | translate}}</td>
                    <td class="align-top text-nowrap inline border-top-0" colspan="2">
                      <span class="px-5">
                      <input
                          [class.is-invalid]=" form.get('typeOfAccount').invalid"
                          class="custom-control-input"
                          formControlName="typeOfAccount"
                          id="actype"
                          name="typeOfAccount"
                          type="radio"
                          value="C"
                          [attr.aria-label]="'30.e1 ' + 'Taxpayer.checking' | translate"
                          [attr.aria-invalid]="form.get('typeOfAccount').invalid ? 'true' : 'false'"
                      />
                      <label class="custom-control-label" for="actype"
                      > 30.e1 {{'Taxpayer.checking' | translate}}</label>
                     </span>
                      <span class="px-5">
                      <input
                          [class.is-invalid]="form.get('typeOfAccount').invalid"
                          class="custom-control-input"
                          formControlName="typeOfAccount"
                          id="acsaving"
                          name="typeOfAccount"
                          type="radio"
                          value="S"
                          [attr.aria-invalid]="form.get('typeOfAccount').invalid ? 'true' : 'false'"
                          [attr.aria-label]="'30.e2 '+ 'Taxpayer.savings' | translate"
                      />
                      <label class="custom-control-label" for="acsaving">30.e2 {{'Taxpayer.savings' | translate}}</label>
                    </span>
                      <a (click)="form.get('typeOfAccount').setValue(null);"
                          href="javascript:;" tooltip="Clear Account Type">
                        <svg class="icon" viewBox="0 0 24 24">
                          <path
                              d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
                              stroke="none"
                          />
                        </svg>
                      </a>
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              </tbody>
            </table>
          </div>
          <div class="bg-white row pb-2">
            <div class="col-12 d-flex justify-content-end">
              <button (click)="back()"
                  [attr.aria-disabled]="(isLoading$ | async)"
                  [attr.aria-label]="'Buttons.back-btn' | translate"
                  [attr.role]="'button'"
                  [attr.tabindex]="(isLoading$ | async) ? -1 : 0"
                  [disabled]="(isLoading$ | async)"
                  class="btn btn-secondary mt-3 ml-3"
                  type="button">
                <span>{{ "Buttons.back-btn" | translate }}</span>
              </button>
              <button (click)="next()"
                  [attr.aria-busy]="(isLoading$ | async)"
                  [attr.aria-disabled]="(isLoading$ | async)"
                  [attr.aria-label]="'Buttons.next-btn' | translate"
                  [attr.role]="'button'"
                  [attr.tabindex]="(isLoading$ | async) ? -1 : 0"
                  [disabled]="(isLoading$ | async)"
                  class="btn btn-primary font-weight-600 mt-3 ml-3"
                  id="submit"
              >
                <ui-spinner [class.active]="isLoading$ | async">
                  <span>{{ "Buttons.next-btn" | translate }}</span>
                </ui-spinner>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #noPermission>
  <ui-forbidden></ui-forbidden>
</ng-template>
