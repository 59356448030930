<form (submit)="submit()" [formGroup]="adjustmentform" novalidate>
  <div class="main pt-2">
    <div class="d-flex justify-content-between px-3 py-1">
      <h3>{{ isEdit ? 'Update' : 'Add' }} {{ 'Taxpayer.adjustment-to-income' | translate }}</h3>
      <div class="">
        <button (click)="hide()" 
            aria-label="Close" 
            class="close pull-right" 
            type="button"
            tabindex="0" 
            role="button">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="content">
      <div class="bg-gray-100">
        <div class="row">
          <div class="col-12">
            <div class="card card-body mb-12">
              <div class="mb-2">
                <div class="form-group">
                  <label for="explanation">
                    {{ 'Taxpayer.explanation' | translate }} <span class="text-danger pl-1">*</span>
                  </label>
                  <textarea id="explanation"
                      [class.is-invalid]="adjustmentform.get('explanation')?.invalid && (adjustmentform.get('explanation')?.dirty || adjustmentform.get('explanation')?.touched)"
                      class="form-control" 
                      formControlName="explanation"
                      rows="3"
                      type="text"
                      [attr.aria-invalid]="adjustmentform.get('explanation')?.invalid && (adjustmentform.get('explanation')?.dirty || adjustmentform.get('explanation')?.touched) ? 'true' : 'false'"
                      [attr.aria-label]="'Taxpayer.explanation' | translate"
                      [attr.aria-required]="'true'"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label for="column1Adjustment">{{ 'Taxpayer.adjustment-amount' | translate }} <span class="text-danger pl-1">*</span></label>
                  <input
                      [class.is-invalid]="adjustmentform.get('column1Adjustment')?.invalid && (adjustmentform.get('column1Adjustment')?.dirty || adjustmentform.get('column1Adjustment')?.touched)"
                      [decimals]="city.components.individual.fractionDigits"
                      class="w-100 form-control text-right custom-height"
                      decimals="0"
                      formControlName="column1Adjustment"
                      id="column1Adjustment"
                      name="column1Adjustment"
                      placeholder=""
                      type="text"
                      [attr.aria-invalid]="adjustmentform.get('column1Adjustment')?.invalid && (adjustmentform.get('column1Adjustment')?.dirty || adjustmentform.get('column1Adjustment')?.touched) ? 'true' : 'false'"
                      [attr.aria-label]="'Taxpayer.adjustment-amount' | translate"
                      [attr.aria-required]="'true'"
                      ui-number>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-end">
    <a (click)="hide()" 
       class="btn btn-link" 
       href="javascript:"
       role="button" 
       tabindex="0" 
       [attr.aria-label]="'Buttons.cancel-btn' | translate">
       {{ "Buttons.cancel-btn" | translate }}
    </a>
    <button
        [disabled]="(isLoading$ | async)"
        class="btn btn-primary f-bold"
        type="submit"
        role="button"
        tabindex="0"
        [attr.aria-label]="isEdit ? 'Update' : ('Buttons.submit-btn' | translate)"
        [attr.aria-disabled]="isLoading$ | async"
        [attr.aria-busy]="isLoading$ | async"
    >
      <ui-spinner [class.active]="isLoading$ | async">
        {{ isEdit ? "Update" : "Buttons.submit-btn" | translate }}
      </ui-spinner>
    </button>
  </div>
</form>
