import {EComponent, EPermission, ERole, EState,} from "@city-tax/shared";

export const ACL = {
  [`${ERole.INDIVIDUAL}`]: [EPermission.INDIVIDUAL_ACCOUNT,],

  [`${ERole.INDIVIDUAL}:${EComponent.PAYMENTS}`]: [
    EPermission.INDIVIDUAL_PAYMENTS,
    EPermission.INDIVIDUAL_PAYMENT_METHODS,
  ],

  [`${ERole.INDIVIDUAL}:${EComponent.FILING}:${EState.MI}`]: [EPermission.INDIVIDUAL_FILING_MI,],

  [`${ERole.INDIVIDUAL}:${EComponent.FILING}:${EState.OH}`]: [EPermission.INDIVIDUAL_FILING_OH,],

  [`${ERole.BUSINESS}`]: [EPermission.BUSINESS_ACCOUNT,],

  [`${ERole.BUSINESS}:${EComponent.PAYMENTS}`]: [EPermission.BUSINESS_PAYMENTS, EPermission.BUSINESS_PAYMENT_METHODS,],

  [`${ERole.BUSINESS}:${EComponent.WITHHOLDING}:${EState.MI}`]: [EPermission.BUSINESS_WITHHOLDING_MI, EPermission.BUSINESS_PAYMENT_METHODS,],

  [`${ERole.BUSINESS}:${EComponent.WITHHOLDING}:${EState.OH}`]: [EPermission.BUSINESS_WITHHOLDING_OH, EPermission.BUSINESS_PAYMENT_METHODS,],

  [`${ERole.BUSINESS}:${EComponent.FILING}:${EState.MI}`]: [EPermission.BUSINESS_FILING_MI,],

  [`${ERole.BUSINESS}:${EComponent.FILING}:${EState.OH}`]: [EPermission.BUSINESS_FILING_OH,],

  [`${ERole.BUSINESS}:${EComponent.W3}`]: [EPermission.BUSINESS_W3,],

  [`Pending`]: [EPermission.PENDING,],
};
