<div *ngIf="payment$ | async as payment" class="payment-form">
  <div class="p-3 d-flex justify-content-center">
    <h6>{{'Payment.review-payment-schedule' |  translate}}</h6>
  </div>
  <div class="p-3">
    <div class="card">
      <ngx-datatable
          #datatablePayments
          [columnMode]="'force'"
          [columns]="columns"
          [count]="(payments$ | async)?.meta?.inlineCount"
          [externalPaging]="false"
          [externalSorting]="false"
          [footerHeight]="50"
          [headerHeight]="50"
          [loadingIndicator]="isLoading$ | async"
          [rowHeight]="50"
          [rows]="(payments$ | async)?.data"
          class="bootstrap datatable-flush"
      >
      </ngx-datatable>
    </div>
  </div>
  <div class="row p-3">
    <div class="col-12 d-flex justify-content-end">
      <button (click)="showModal('cancel', payment)"
          [disabled]="isLoading$ | async"
          class="btn btn-dark font-weight-600 mt-3 text-white"
          id="cancel"
          tabindex="0"
          [attr.aria-label]="'Buttons.cancel-btn' | translate"
          [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
          [attr.tabindex]="(isLoading$ | async) ? -1 : 0"
          role="button">
              <span>
                {{ "Buttons.cancel-btn" | translate }}
              </span>
      </button>
      <ui-spinner [class.active]="(isLoading$ | async)">
        <button (click)="submit()" 
            [disabled]="isLoading$ | async"
            class="btn btn-danger font-weight-600 mt-3 ml-3"
            id="schedule"
            [attr.aria-label]="'Buttons.schedule-btn' | translate"
            [attr.aria-disabled]="isLoading$ | async"
            [attr.aria-busy]="(isLoading$ | async) ? true : null"
            [attr.tabindex]="(isLoading$ | async) ? -1 : 0"
            role="button">
              <span>
                {{ "Buttons.schedule-btn" | translate }}
              </span>
        </button>
      </ui-spinner>
    </div>
  </div>
</div>

<ng-template #actionTemplate class="" let-row="row" let-value="value" ngx-datatable-cell-template>

  <button (click)="showModal('edit',row)" 
       class="btn btn-sm bg-white border shadow" 
       container="body" 
       placement="left" 
       tooltip="Edit" 
       type="button"
       tabindex="0" 
       role="button" 
       [attr.aria-label]="'Payment.edit' | translate" >
    <svg class="bi bi-pencil-fill edit-icon" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
      <path
          d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
    </svg>
  </button>

  <button (click)="showModal('delete',row)" 
        class="btn btn-sm bg-white border shadow" 
        container="body" 
        placement="left" 
        tooltip="Delete" 
        type="button"
        tabindex="0" 
        role="button" 
        [attr.aria-label]="'Payment.delete' | translate">
    <svg class="icon icon-sm fill-danger-500" viewBox="0 0 24 24" aria-hidden="true">
      <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" stroke="none"/>
    </svg>
  </button>
</ng-template>
