<div *ngIf="(individualReturn$ | async)?.status === 'Completed'"
    class="card p-3 position-absolute return-filed m-0 text-center">
    {{ 'Taxpayer.filed' | translate }}
</div>
<div *ngIf="(individualReturn$ | async)?.status === 'Mailed'"
    class="card p-3 position-absolute return-filed m-0 text-center">
    {{ 'Taxpayer.mailed' | translate }}
</div>
<div *ngIf="individualReturn$ | async as individualReturn" class="container py-3">
  <form [formGroup]="form" novalidate>
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="d-flex align-items-center justify-content-center">
          <h5 class="text-center mb-0 font-weight-bolder">{{ individualReturn?.taxYear }} {{ 'Taxpayer.individual-income-tax' | translate }} </h5>
        </div>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="d-flex align-items-center justify-content-center">
          <div class="text-center font-weight-bolder">{{ 'Taxpayer.individual-return-due' | translate }} {{ individualReturn?.eFileDeadline | utcDate:'MMMM dd, YYYY' }}
          </div>
        </div>
      </div>
    </div>
    <div class="row py-3">
      <div class="col-xl-4 col-md-6">
        <div class="row pb-1">
          <div class="col-5 col-xl-4">{{ 'Taxpayer.filing' | translate }}:</div>
          <div
              class="col-7 col-xl-8">{{ individualReturn?.filing == 'S' ? 'Single' : (individualReturn?.filing == 'MJ' ? 'Married filing jointly' : 'Married filing separately') }}
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-5 col-xl-4">{{ 'Taxpayer.taxpayer' | translate }}:</div>
          <div class="col-7 col-xl-8">{{ individualReturn?.taxpayer?.name }}</div>
        </div>
        <div class="row pb-1">
          <div class="col-5 col-xl-4">{{ 'Taxpayer.account-number' | translate }}:</div>
          <div class="col-7 col-xl-8">{{ individualReturn?.taxpayer?.accountNumber }}</div>
        </div>
        <div class="row pb-1">
          <div class="col-5 col-xl-4">SSN:</div>
          <div class="col-7 col-xl-8">XXX-XX-{{ individualReturn?.taxpayer?.ssn.substring(5, 9) }}</div>
        </div>
        <!--          <div class="row pb-1">-->
        <!--            <div class="col-5 col-xl-4">Birthdate:</div>-->
        <!--            <div class="col-7 col-xl-8">{{individualReturn?.taxpayer?.birthdate| utcDate:'MM/dd/YYYY'}}</div>-->
        <!--          </div>-->
        <!--          <div class="row pb-1">-->
        <!--            <div class="col-5 col-xl-4">Phone:</div>-->
        <!--            <div class="col-7 col-xl-8">{{individualReturn?.taxpayer?.phone | phone: 'US' }}</div>-->
        <!--          </div>-->
        <div class="row pb-1">
          <div class="col-5 col-xl-4">{{ 'Taxpayer.email' | translate }}:</div>
          <div class="col-7 col-xl-8">{{ formatEmail(individualReturn?.taxpayer?.email) }}</div>
        </div>
        <div *ngIf="individualReturn?.filing !== 'S'" class="row pb-1">
          <div class="col-5 col-xl-4">{{ 'Taxpayer.spouse-ssn' | translate }}:</div>
          <div class="col-7 col-xl-8">XXX-XX-{{ individualReturn?.spouse?.ssn?.substring(5, 9) }}</div>
        </div>
        <div *ngIf="individualReturn?.filing !== 'S'" class="row pb-1">
          <div class="col-5 col-xl-4">{{ 'Taxpayer.spouse' | translate }}:</div>
          <div class="col-7 col-xl-8">{{ individualReturn?.spouse?.name }}</div>
        </div>
      </div>
      <div class="col-xl-4 d-xl-inline-block d-none"></div>
      <div class="col-xl-4 col-md-6 ">
        <div class="row pb-1">
          <div class="col-5 col-xl-4">{{ 'Taxpayer.resident' | translate }}:</div>
          <div class="col-7 col-xl-8">
            <div class="form-check form-check-inline ">
              <input [checked]="individualReturn?.resident" class="form-check-input" disabled
                  id="defaultCheckYes" onclick="return false" type="checkbox">
              <label class="form-check-label" for="defaultCheckYes">
                {{ 'Taxpayer.yes' | translate }}
              </label>
            </div>
            <div class="form-check form-check-inline ">
              <input [checked]="!individualReturn?.resident" class="form-check-input" disabled
                  id="defaultCheckNO" onclick="return false" type="checkbox">
              <label class="form-check-label" for="defaultCheckNo">
                {{ 'Taxpayer.no' | translate }}
              </label>
            </div>
          </div>
        </div>
        <div class="row pb-1">
          <div class="col-5 col-xl-4">{{ 'Taxpayer.address' | translate }}:</div>
          <div class="col-7 col-xl-8">{{ individualReturn?.taxpayer?.address.addressLine1 }}</div>
        </div>
        <div *ngIf="individualReturn?.taxpayer?.address?.addressLine2  || individualReturn?.taxpayer?.address?.aptNumber"
            class="row pb-1">
          <div class="col-5 col-xl-4"></div>
          <div class="col-7 col-xl-8">{{ individualReturn?.taxpayer?.address?.addressLine2 }} {{ individualReturn?.taxpayer?.address?.aptNumber }}</div>
        </div>
        <div class="row align-items-center">
          <div class="col-5 col-xl-4"></div>
          <div class="col-7 col-xl-8">{{ individualReturn?.taxpayer?.address?.city }},
            {{ individualReturn?.taxpayer?.address?.state }} {{ individualReturn?.taxpayer?.address?.postalCode }}
          </div>
        </div>
        <div *ngIf="individualReturn?.residency==='P'" class="row pb-1">
          <div class="col-12 text-uppercase">{{ 'Taxpayer.part-year-resident-dates' | translate }}</div>
        </div>
        <div *ngIf="individualReturn?.residency==='P'" class="row pb-1">
          <div class="col-5 col-xl-5">{{ 'Taxpayer.moved-into-city' | translate }}:</div>
          <div class="col-7 col-xl-7">{{ individualReturn?.partYearFromDate | utcDate:'MM/dd/yyyy' }}</div>
        </div>
        <div *ngIf="individualReturn?.residency==='P'" class="row pb-1">
          <div class="col-5 col-xl-5">{{ 'Taxpayer.moved-out-of-city' | translate }}:</div>
          <div class="col-7 col-xl-7">{{ individualReturn?.partYearToDate | utcDate:'MM/dd/yyyy' }}</div>
        </div>

        <div *ngIf="individualReturn?.residency==='P' && individualReturn?.filing !=='S' " class="row pb-1">
          <div class="col-12 text-uppercase">{{ 'Taxpayer.spouse-part-year-resident-dates' | translate }}</div>
        </div>
        <div *ngIf="individualReturn?.residency==='P' && individualReturn?.filing !=='S'" class="row pb-1">
          <div class="col-5 col-xl-5">{{ 'Taxpayer.moved-into-city' | translate }}:</div>
          <div class="col-7 col-xl-7">{{ individualReturn?.partYearFromDateSpouse | utcDate:'MM/dd/yyyy' }}</div>
        </div>
        <div *ngIf="individualReturn?.residency==='P' && individualReturn?.filing !=='S'" class="row pb-1">
          <div class="col-5 col-xl-5">{{ 'Taxpayer.moved-out-of-city' | translate }}:</div>
          <div class="col-7 col-xl-7">{{ individualReturn?.partYearToDateSpouse | utcDate:'MM/dd/yyyy' }}</div>
        </div>
        <div *ngIf="individualReturn?.residency==='P'" class="row pb-1">
          <div class="col-12">{{ 'Taxpayer.previous-present-address' | translate }}:</div>
        </div>
        <div *ngIf="individualReturn?.residency==='P'" class="row pb-1">
          <div class="col-5 col-xl-5">{{ 'Taxpayer.address' | translate }}:</div>
          <div class="col-7 col-xl-7">{{ individualReturn?.previousAddress?.addressLine1 }}</div>
        </div>
        <div *ngIf="individualReturn?.residency==='P' && (individualReturn?.previousAddress?.addressLine2  || individualReturn?.previousAddress?.aptNumber)"
            class="row pb-1">
          <div class="col-5 col-xl-5"></div>
          <div class="col-7 col-xl-7">{{ individualReturn?.previousAddress?.addressLine2 }} {{ individualReturn?.previousAddress?.aptNumber }}</div>
        </div>
        <div *ngIf="individualReturn?.residency==='P'" class="row">
          <div class="col-5 col-xl-5"></div>
          <div class="col-7 col-xl-7">{{ individualReturn?.previousAddress?.city }}
            ,{{ individualReturn?.previousAddress?.state }} {{ individualReturn?.previousAddress?.postalCode }}
          </div>
        </div>
        <div class="row pb-1 mt-xl-0 mt-4">
          <div class="col-12">
            <div class="form-group">
              <label for="estimatedTaxPaid"  class="small font-weight-medium text-muted text-uppercase">
                {{ 'Taxpayer.estimates-paid-in-current-tax-year' | translate }}:
              </label>
              <input
                  id="estimatedTaxPaid"
                  [allowNegativeNumbers]="false"
                  [decimals]="city.components.individual.fractionDigits"
                  [readonly]="true"
                  class="w-100 form-control bg-light text-right custom-height"
                  formControlName="estimatedTaxPaid"
                  type="text"
                  ui-number
              >
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label for="priorYearOverpayment"  class="small font-weight-medium text-muted text-uppercase">
                {{ 'Taxpayer.credit-carry-forward-previous-tax-year' | translate }}:
              </label>
              <input 
                  id="priorYearOverpayment"
                  [allowNegativeNumbers]="false"
                  [decimals]="city.components.individual.fractionDigits"
                  [readonly]="true"
                  class="w-100 form-control bg-light text-right custom-height"
                  formControlName="priorYearOverpayment"
                  type="text"
                  ui-number
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row py-3">
      <div class="col-12">
        <div class="row p-2 bg-light border">
          <div class="col-12 font-weight-bold">{{ 'Taxpayer.tax-return-summary' | translate }}</div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2 pt-1">
          <div class="col-md-9 col-7  dots">1. {{ 'Taxpayer.total-qualifying-wages' | translate }}</div>
          <div class="col-1 d-flex justify-content-end">1</div>
          <div class="col-md-2  col-4">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"
                controlName="wagesSalariesTips"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-md-9 col-7  dots">2. {{ 'Taxpayer.other-income-from-federal-schedules' | translate }}</div>
          <div class="col-1 d-flex justify-content-end">2</div>
          <div class="col-md-2  col-4 d-flex d-inline-block">
            <ui-input-selector
                [allowNegativeNumbers]="true"
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"

                controlName="otherIncome"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-md-9 col-7  dots">3. {{ 'Taxpayer.total-income-losses' | translate }}
          </div>
          <div class="col-1 d-flex justify-content-end">3</div>
          <div class="col-md-2  col-4 d-flex d-inline-block">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"

                controlName="totalIncome"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-md-9 col-7  dots">4. {{ 'Taxpayer.adjustments-calculate-difference' | translate }}</div>
          <div class="col-1 d-flex justify-content-end">4</div>
          <div class="col-md-2  col-4 d-flex d-inline-block">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"

                controlName="adjustments"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-md-9 col-7  dots">5. {{ 'Taxpayer.taxable-income' | translate }}</div>
          <div class="col-1 d-flex justify-content-end">5</div>
          <div class="col-md-2  col-4 d-flex d-inline-block">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"

                controlName="taxableIncome"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-md-9 col-7  dots">6. {{ 'Taxpayer.tax-liability' | translate }} {{ ((config?.components?.individual?.taxRate || 0) * 100).toFixed(2) }}%
            ({{ config?.components?.individual?.taxRate }})
          </div>
          <div class="col-1 d-flex justify-content-end">6</div>
          <div class="col-md-2  col-4 d-flex d-inline-block">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"

                controlName="multiplyTaxableIncome"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-10 text-nowrap">7. {{ 'Taxpayer.credits' | translate }}</div>
          <div class="col-2"></div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-1"></div>
          <div class="col-md-7 col-5 dots">7a. {{ config?.name }} {{ 'Taxpayer.city-tax-withheld' | translate }}</div>
          <div class="col-1 d-flex justify-content-end">7a</div>
          <div class="col-md-2  col-4" formGroupName="page2">
            <ng-container formGroupName="worksheetA">
              <input
                  [decimals]="city.components.individual.fractionDigits"
                  class="form-control custom-height-form text-right w-100"
                  formControlName="grandTotalFillingCityTaxWithheld"
                  readonly

                  type="text"
                  ui-number
              >
            </ng-container>
          </div>

          <div class="col-1"></div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-1"></div>
          <div class="col-md-7 col-5 dots">7b. {{ 'Taxpayer.other-city-withholding-credit-not-exceed' | translate }} {{ ((config?.components?.individual?.creditRate || 0) * 100).toFixed(2) }} %
          </div>
          <div class="col-1 d-flex justify-content-end">7b</div>
          <div class="col-md-2  col-4" formGroupName="page2">
            <ng-container formGroupName="worksheetA">
              <input
                  [decimals]="city.components.individual.fractionDigits"
                  class="form-control custom-height-form text-right w-100"
                  formControlName="grandTotalOtherCityTaxWithheld"
                  readonly
                  type="text"
                  ui-number
              >
            </ng-container>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-1"></div>
          <div class="col-md-7 col-5 dots">7c. {{ 'Taxpayer.other-local-taxes-paid-from-schedule-income' | translate }}</div>
          <div class="col-1 d-flex justify-content-end">7c</div>
          <div class="col-md-2  col-4" formGroupName="page2">
            <ng-container formGroupName="worksheetB">
              <input
                  [decimals]="city.components.individual.fractionDigits"
                  class="form-control custom-height-form text-right w-100"
                  formControlName="totalTaxCreditAllowedForTaxPaidToOtherCities"
                  readonly

                  type="text"
                  ui-number
              >
            </ng-container>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-1"></div>
          <div class="col-md-7 col-5 dots">7d. {{ 'Taxpayer.resident-homeowner-credit' | translate }}
          </div>
          <div class="col-1 d-flex justify-content-end">7d</div>
          <div class="col-md-2 col-4">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"
                controlName="residentHomeownerCreditAmount"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
          <div class="col-1"></div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-1"></div>
          <div *ngIf="city.name!=='Mason'" class="col-md-8 col-5  dots">7e. {{ 'Taxpayer.total-credits-allowable' | translate }} (Lines 7a thru 7c)</div>
          <div *ngIf="city.name==='Mason'" class="col-md-8 col-5  dots">7e. {{ 'Taxpayer.total-credits-allowable' | translate }} (Lines 7a thru 7d)</div>
          <div class="col-1 d-flex justify-content-end">7e</div>
          <div class="col-md-2  col-4">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"
                controlName="totalCreditsAllowable"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-md-9 col-7  dots">8. {{ 'Taxpayer.tax-due' | translate }}
          </div>
          <div class="col-1 d-flex justify-content-end">8</div>
          <div class="col-md-2  col-4">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"

                controlName="taxDue"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-md-9 col-7  dots">9. {{ 'Taxpayer.estimated-tax-paid-prior-year-overpayment' | translate }}</div>
          <div class="col-1 d-flex justify-content-end">9</div>
          <div class="col-md-2  col-4">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"

                controlName="totalEstimatedTaxPaidAndPriorYearOverpayment"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-md-9 col-7  dots">10. {{ 'Taxpayer.tax-due-after-payments' | translate }}
          </div>
          <div class="col-1 d-flex justify-content-end">10</div>
          <div class="col-md-2  col-4">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"

                controlName="totalDueAfterPayments"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-10">11. {{ 'Taxpayer.fees' | translate }}
          </div>
          <div class="col-2"></div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-1"></div>
          <div class="col-md-8 col-6 dots">11a. {{ 'Taxpayer.late-filing-fee' | translate }}</div>
          <div class="col-1 d-flex justify-content-end">11a</div>
          <div class="col-md-2  col-4">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"

                controlName="lateFillingFee"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-1"></div>
          <div class="col-md-8 col-6 dots">11b.
            {{ 'Taxpayer.penalty' | translate }} {{ (config?.components?.individual?.penalty || 0) | percent }},
            {{ 'Taxpayer.interest' | translate }} {{ interestPerMonth(config?.components?.individual?.interest || 0) | percent:'0.3-3' }}
            {{ 'Taxpayer.per-month' | translate }}
          </div>
          <div class="col-1 d-flex justify-content-end">11b</div>
          <div class="col-md-2  col-4">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"

                controlName="totalPenaltyAndInterest"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-md-9 col-7  dots">12. {{ 'Taxpayer.total-amount-due-check' | translate: {
            checkPayableTo: (config?.components?.individual?.checkPayableTo || city.name), 
            minAmountOfTaxDue: (config?.components?.individual?.minAmountOfTaxDue || 0).toFixed(2) 
          } }}
          </div>
          <div class="col-1 d-flex justify-content-end">12</div>
          <div class="col-md-2  col-4">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"
                controlName="totalAmountDue"
                isNumeric="true"
            >
            </ui-input-selector>
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-md-9 col-7  dots">13. {{ 'Taxpayer.overpayment-minimum' | translate:{ amount: (+config?.components?.individual?.minAmountOfTaxDue || 0).toFixed(2) } }}
          </div>
          <div class="col-1 d-flex justify-content-end">13</div>
          <div class="col-md-2  col-4">
            <ui-input-selector
                [controlForm]="form"
                [data]="individualReturn?.review"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="true"
                class="w-100"
                controlClass="form-control custom-height-form text-right"
                controlName="overpayment"
                isNumeric="true"
            >
            </ui-input-selector>

          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-md-9 col-7  dots">14. {{ 'Taxpayer.amount-from-line-refunded' | translate }}
          </div>
          <div class="col-1 d-flex justify-content-end">14</div>
          <div class="col-md-2  col-4">
            <input
                [class.is-invalid]="form?.errors?.totalMismatch"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="+!(form.get('overpayment').value > (+config?.components?.individual?.minAmountOfTaxDue))"
                class="w-100 form-control custom-height-form text-right"

                formControlName="overpaymentToBeRefunded"
                ui-number
            >
          </div>
        </div>
        <div class="row align-items-center mb-md-1 mb-2">
          <div class="col-md-9 col-7  dots">15. {{ 'Taxpayer.amount-credited' | translate }}
          </div>
          <div class="col-1 d-flex justify-content-end">15</div>
          <div class="col-md-2  col-4">
            <input (change)="setNetOverpaymentToBeCredited($event)"
                [class.is-invalid]="form?.errors?.totalMismatch"
                [decimals]="city.components.individual.fractionDigits"
                [readonly]="+!(form.get('overpayment').value > 0)"
                class="form-control custom-height-form text-right"

                formControlName="overpaymentToBeCredited"
                ui-number
            >
          </div>
        </div>
        <div *ngIf="form?.errors?.totalMismatch" class="row align-items-center mb-md-1 mb-2">
          <div class="col-12 d-flex justify-content-end text-danger">
            {{ 'Taxpayer.line14-line15-equal-line13' | translate }}
          </div>
        </div>
        <ng-container *ngIf="individualReturn?.totalEstimatedIncomeSubjectToTax > 0">

          <div class="row p-2 bg-light border">
            <div class="col-12 font-weight-bold">{{ 'Taxpayer.declaration-tax' | translate }}</div>
          </div>

          <div class="row align-items-center mb-md-1 mb-2  pt-1">
            <div class="col-md-9 col-7  dots">16. {{ 'Taxpayer.total-income-subject-to-tax' | translate }}
            </div>
            <div class="col-1 d-flex justify-content-end">16</div>
            <div class="col-md-2  col-4">
              <ui-input-selector (change)="calculate()"
                  [controlForm]="form"
                  [data]="individualReturn?.review"
                  [decimals]="city.components.individual.fractionDigits"
                  class="w-100"
                  controlClass="form-control custom-height-form text-right"

                  controlName="totalEstimatedIncomeSubjectToTax"
                  isNumeric="true"
              >
              </ui-input-selector>
            </div>
          </div>

          <div class="row align-items-center mb-md-1 mb-2">
            <div class="col-md-9 col-7  dots0">17. {{ city?.name }}: {{ 'Taxpayer.income-tax-multiply' | translate:{ taxRatePercent: (config?.components?.individual?.taxRate || 0) | percent:'1.1-2', taxRate: config?.components?.individual?.taxRate } }}
            </div>
            <div class="col-1 d-flex justify-content-end">17</div>
            <div class="col-md-2  col-4">
              <ui-input-selector
                  [controlForm]="form"
                  [data]="individualReturn?.review"

                  [decimals]="city.components.individual.fractionDigits"
                  class="w-100"
                  controlClass="form-control custom-height-form text-right"
                  controlName="estimatedIncomeTax"
                  isNumeric="true"
              >
              </ui-input-selector>

            </div>
          </div>

          <div class="row align-items-center mb-md-1 mb-2">
            <div class="col-md-9 col-7  dots">18. {{ 'Taxpayer.less-expected-tax-credits' | translate }}</div>
            <div class="col-1 d-flex justify-content-end">18</div>
            <div class="col-md-2  col-4">
              <ui-input-selector
                  [controlForm]="form"
                  [data]="individualReturn?.review"
                  [decimals]="city.components.individual.fractionDigits"
                  class="w-100"
                  controlClass="form-control custom-height-form text-right"
                  controlName="lessExpectedTaxCredits"
                  isNumeric="true"
              >
              </ui-input-selector>
            </div>
          </div>

          <div class="row align-items-center mb-md-1 mb-2">
            <div class="col-md-9 col-7  dots">19. a. {{ 'Taxpayer.net-tax-due-minus' | translate }}
            </div>
            <div class="col-1 d-flex justify-content-end">19a</div>
            <div class="col-md-2  col-4">
              <ui-input-selector
                  [controlForm]="form"
                  [data]="individualReturn?.review"
                  [decimals]="city.components.individual.fractionDigits"
                  class="w-100"
                  controlClass="form-control custom-height-form text-right"
                  controlName="netTaxDue"
                  isNumeric="true"
              >
              </ui-input-selector>
            </div>
          </div>

          <div class="row align-items-center mb-md-1 mb-2">
            <div class="col-md-9 col-7  dots">19. b. {{ 'Taxpayer.overpayment-credited-prior-year' | translate }}
            </div>
            <div class="col-1 d-flex justify-content-end">19b</div>
            <div class="col-md-2  col-4">
              <ui-input-selector
                  [controlForm]="form"
                  [data]="individualReturn?.review"

                  [decimals]="city.components.individual.fractionDigits"
                  class="w-100"
                  controlClass="form-control custom-height-form text-right"
                  controlName="netOverpaymentToBeCredited"
                  isNumeric="true"
              >
              </ui-input-selector>
            </div>
          </div>

          <div class="row align-items-center mb-md-1 mb-2">
            <div class="col-md-9 col-7  dots"> 20. {{ 'Taxpayer.amount-due-with-declaration' | translate }}
            </div>
            <div class="col-1 d-flex justify-content-end">20</div>
            <div class="col-md-2  col-4">
              <ui-input-selector
                  [controlForm]="form"
                  [data]="individualReturn?.review"
                  [decimals]="city.components.individual.fractionDigits"
                  class="w-100"
                  controlClass="form-control custom-height-form text-right"
                  controlName="amountDueWithThisDeclaration"
                  isNumeric="true"
              >
              </ui-input-selector>
            </div>
          </div>

          <div class="row align-items-center mb-md-1 mb-2">
            <div class="col-md-9 col-7  dots">
              21. {{ 'Taxpayer.total-payment-check' | translate: { checkPayableTo: (city.components.individual.checkPayableTo || city.name) } }}
            </div>
            <div class="col-1 d-flex justify-content-end">21</div>
            <div class="col-md-2  col-4">
              <ui-input-selector
                  [controlForm]="form"
                  [data]="individualReturn?.review"
                  [decimals]="city.components.individual.fractionDigits"
                  class="w-100"
                  controlClass="form-control custom-height-form text-right"
                  controlName="totalOfThisPayment"
                  isNumeric="true"
              >
              </ui-input-selector>
            </div>
          </div>

        </ng-container>
      </div>
    </div>


    <!--    <div class="row">-->
    <!--      <div class="col-12 d-flex justify-content-end">-->
    <!--        <button (click)="print()" [disabled]="isDownloading$ | async" class="btn btn-sm bg-white border shadow" container="body" type="button" tooltip="Print Worksheets">-->
    <!--          <ui-spinner [class.active]="isDownloading$ | async">-->
    <!--          <span class="d-flex align-items-center w-100">-->
    <!--            <svg-->
    <!--                class="icon icon-sm fill-primary-500"-->
    <!--                viewBox="0 0 24 24">-->
    <!--              <path-->
    <!--                  d="M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z"/>-->
    <!--            </svg>-->
    <!--          </span>-->
    <!--          </ui-spinner>-->
    <!--        </button>-->
    <!--      </div>-->
    <!--    </div>-->

    <div class="row py-3">
      <div class="col-12">
        <div class="row p-2 bg-light border">
          <div class="col-md-2 col-sm-3 col-12 font-weight-bold">{{ 'Taxpayer.worksheet' | translate }} A</div>
          <div class="col-md-10 col-sm-8 col-12 font-weight-bold">{{ 'Taxpayer.qualifying-wages-tips' | translate }}
          </div>
        </div>
        <div class="row">
          <div class="col-12 worksheet-sec">
            <div class="row py-1 d-flex border-left border-bottom border-right font-weight-bold">
              <div class="col-2 border-right ">
                <div class="row">
                  <div class="col-12 text-center">A</div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">{{ 'Taxpayer.employer' | translate }}</div>
                </div>
                <div class="row">
                  <div class="col-12"></div>
                </div>
              </div>
              <div class="col-2 border-right ">
                <div class="row">
                  <div class="col-12 text-center">B</div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">{{ 'Taxpayer.locality-cp' | translate }}</div>
                </div>
                <div class="row">
                  <div class="col-12"></div>
                </div>
              </div>
              <div class="col-2 border-right ">
                <div class="row">
                  <div class="col-12 text-center">D</div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">{{ 'Taxpayer.tax-withheld' | translate }}</div>
                </div>
                <div class="row">
                  <div class="col-12"></div>
                </div>
              </div>
              <div class="col-2 border-right ">
                <div class="row">
                  <div class="col-12 text-center">F</div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">{{ config?.name.toUpperCase() }} {{ 'Taxpayer.tax' | translate }}</div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">{{ 'Taxpayer.withheld' | translate }}</div>
                </div>
              </div>
              <div class="col-2 border-right ">
                <div class="row">
                  <div class="col-12 text-center">G</div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">{{ 'Taxpayer.tax-credit-allowed' | translate }}</div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">{{ 'Taxpayer.for-other-cities' | translate }}</div>
                </div>
              </div>
              <div class="col-2 ">
                <div class="row">
                  <div class="col-12 text-center">H</div>
                </div>
                <div class="row">
                  <div class="col-12 text-center">{{ 'Taxpayer.qualifying-wages' | translate }}</div>
                </div>
                <div class="row">
                  <div class="col-12"></div>
                </div>
              </div>
            </div>
            <ng-container *ngFor="let wage of getTaxpayerScheduleOfWages.value;">
              <ng-container *ngFor="let locality of wage?.w2LocalityInfo; let index = index;">
                <div class="row py-1">
                  <div class="col-2 border-right">
                      <span *ngIf="index===0">
                      {{ wage?.employersName }}
                    </span>
                  </div>
                  <div class="col-2 border-right">
                    {{ locality?.taxWithheldLocalityName }}
                  </div>
                  <div class="col-2 border-right text-right">
                     <span *ngIf="locality?.taxWithheldLocalityName !== config?.name  && +locality?.taxWithheldBox19>0">
                     {{ locality?.taxWithheldBox19 |  number: numberFormat }}
                    </span>
                  </div>
                  <div class="col-2 border-right text-right">
                    <span *ngIf="locality?.taxWithheldLocalityName === config?.name && +locality?.taxWithheldBox19>0">
                     {{ locality?.taxWithheldBox19  |  number: numberFormat }}
                    </span>
                  </div>
                  <div class="col-2 border-right text-right">
                    <span *ngIf="+locality.creditForTaxesPaid>0">{{ locality.creditForTaxesPaid  |  number: numberFormat }}</span>
                  </div>
                  <div class="col-2 text-right">
                    <span *ngIf="index===0"> {{ getQualifyingWages(wage) |  number: numberFormat }}</span>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <ng-container *ngFor="let wage of getSpouseScheduleOfWages.value;">
              <ng-container *ngFor="let locality of wage?.w2LocalityInfo; let index = index;">
                <div class="row  py-1">
                  <div class="col-2 border-right">
                                <span *ngIf="index===0">
                                  {{ wage?.employersName }}
                                </span>
                  </div>
                  <div class="col-2 border-right">
                    {{ locality?.taxWithheldLocalityName }}
                  </div>
                  <div class="col-2 border-right text-right">
                                <span *ngIf="locality?.taxWithheldLocalityName !== config?.name  && +locality?.taxWithheldBox19>0">
                                  {{ locality?.taxWithheldBox19 |  number: numberFormat }}
                                </span>
                  </div>
                  <div class="col-2 border-right text-right">
                                <span *ngIf="locality?.taxWithheldLocalityName === config?.name && +locality?.taxWithheldBox19>0">
                                  {{ locality?.taxWithheldBox19  |  number: numberFormat }}
                                </span>
                  </div>
                  <div class="col-2 border-right text-right">
                                <span *ngIf="+locality.creditForTaxesPaid>0">
                                  {{ locality.creditForTaxesPaid  |  number: numberFormat }}
                                 </span>
                  </div>
                  <div class="col-2 text-right">
                    <span *ngIf="index===0"> {{ getQualifyingWages(wage) |  number: numberFormat }}</span>
                  </div>
                </div>
              </ng-container>
            </ng-container>
            <div class="row py-1 border font-weight-bold">
              <div class="col-6 border-right">
                {{ 'Taxpayer.totals' | translate }}
              </div>
              <div class="col-2 text-right border-right">
                <span class="pr-0">{{ ir?.page2?.worksheetA?.grandTotalFillingCityTaxWithheld  |  number: numberFormat }} </span>
              </div>
              <div class="col-2 text-right border-right">
                <span class="pr-0"> {{ ir?.page2?.worksheetA?.grandTotalTaxCreditAllowableOtherCities  |  number: numberFormat }}</span>
              </div>
              <div class="col-2 text-right">
                <span class="pr-0"> {{ ir?.page2?.worksheetA?.grandTotalQualifyingWages  |  number: numberFormat }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(config$|async)?.components?.individual?.eFileType !== 'W2' && hasSchedules()" class="row py-3">
      <div class="col-12">
        <div class="row p-2 bg-light border">
          <div class="col-md-2 col-sm-3 col-12  font-weight-bold">{{ 'Taxpayer.worksheet' | translate }} B</div>
          <div class="col-md-10 col-sm-8 col-12"></div>
        </div>
        <div class="worksheet-sec-b">
          <div class="row">
            <div class="col-5 border-bottom border-right font-weight-bold sec-title border-left py-1">
              {{ 'Taxpayer.schedules' | translate }}
            </div>
            <div class="col-2 min-w-100px text-center border-bottom border-right font-weight-bold sec-title py-1">
              <div>A</div>
              {{ 'Taxpayer.income-loss-from-federal-schedule' | translate }}
            </div>
            <div class="col-1 min-w-100px text-center border-bottom border-right font-weight-bold sec-title py-1">
              <div>B</div>
              <div>{{ config?.name.toUpperCase() }}</div>
              {{ 'Taxpayer.percentage' | translate }}
            </div>
            <div class="col-2 min-w-100px text-center border-bottom border-right font-weight-bold sec-title py-1">
              <div>C</div>
              <div>{{ config?.name.toUpperCase() }}</div>
              {{ 'Taxpayer.taxable-income' | translate }}
              <div>(A X B)</div>
            </div>
            <div class="col-2 min-w-200px text-center border-bottom border-right font-weight-bold sec-title py-1">
              <div>D</div>
              {{ 'Taxpayer.tax-credit-allowed-tax-paid-cities' | translate }}
            </div>
          </div>
          <div class="row">
            <div class="col-5 min-w-200px border-bottom py-2 ">1. {{ 'Taxpayer.business-income-schedule' | translate }}
            </div>
            <div
                class="col-2 min-w-200px border-left border-bottom py-2 text-right">
              <span class="pr-3">{{ ir?.page2?.worksheetB?.scheduleC?.totalIncomeLoss  |  number: numberFormat }} </span>
            </div>
            <div
                class="col-1 min-w-100px  border-left border-bottom py-2 text-right">
              <span class="pr-3">{{ ir?.page2?.worksheetB?.scheduleC?.municipalityPercentage | mask: 'separator.2' }}</span>
            </div>
            <div
                class="col-2 min-w-100px  border-left border-bottom py-2 text-right">
              <span class="pr-3">{{ ir?.page2?.worksheetB?.scheduleC?.municipalityTaxableIncome  |  number: numberFormat }}</span>
            </div>
            <div
                class="col-2 border-left border-bottom py-2 text-right">
              <span class="pr-3">{{ ir?.page2?.worksheetB?.scheduleC?.taxCreditAllowed  |  number: numberFormat }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-5 min-w-200px border-bottom py-2"> 2. {{ 'Taxpayer.schedule-e-rental' | translate:{ name: config?.name.toUpperCase() } }}
            </div>
            <div
                class="col-2 border-left border-bottom py-2 min-w-200px border-bottom text-right">
              <span class="pr-3"> {{ ir?.page2?.worksheetB?.scheduleE?.totalIncomeLoss  |  number: numberFormat }}</span>
            </div>
            <div
                class="col-1 min-w-100px  border-left py-2 border-bottom text-right">
              <span class="pr-3"> {{ ir?.page2?.worksheetB?.scheduleE?.municipalityPercentage  | mask: 'separator.2' }}</span>
            </div>
            <div
                class="col-2 min-w-100px border-left py-2 border-bottom text-right">
              <span class="pr-3"> {{ ir?.page2?.worksheetB?.scheduleE?.municipalityTaxableIncome |  number: numberFormat }}</span>
            </div>
            <div
                class="col-2 border-left border-bottom py-2 text-right">
              <span class="pr-3"> {{ ir?.page2?.worksheetB?.scheduleE?.taxCreditAllowed  |  number: numberFormat }}</span>
            </div>

          </div>
          <div class="row">
            <div class="col-5 min-w-200px border-bottom py-2">
              3. {{ 'Taxpayer.schedule-k1-income' | translate:{ configName: config?.name.toUpperCase() } }}
            </div>

            <div
                class="col-2 border-left border-bottom py-2  min-w-200px border-bottom text-right"><span
                class="pr-3">{{ ir?.page2?.worksheetB?.scheduleK1?.totalIncomeLoss  |  number: numberFormat }}</span>
            </div>
            <div
                class="col-1 py-2 min-w-100px  border-left border-bottom text-right"><span
                class="pr-3">{{ ir?.page2?.worksheetB?.scheduleK1?.municipalityPercentage  | mask: 'separator.2' }}</span>
            </div>
            <div
                class="col-2 py-2 min-w-100px border-left border-bottom text-right"><span
                class="pr-3">{{ ir?.page2?.worksheetB?.scheduleK1?.municipalityTaxableIncome |  number: numberFormat }}</span>
            </div>
            <div
                class="col-2 border-left border-bottom py-2 text-right"><span
                class="pr-3">{{ ir?.page2?.worksheetB?.scheduleK1?.taxCreditAllowed  |  number: numberFormat }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-5 min-w-200px border-bottom py-2"> 4. {{ 'Taxpayer.miscellaneous-income' | translate }}
            </div>
            <div
                class="col-2 border-left border-bottom py-2 min-w-200px border-bottom text-right"><span
                class="pr-3">{{ ir?.page2?.worksheetB?.scheduleMisc?.totalIncomeLoss  |  number: numberFormat }}</span>
            </div>
            <div
                class="col-1 py-2 min-w-100px border-left border-bottom text-right"><span
                class="pr-3">{{ ir?.page2?.worksheetB?.scheduleMisc?.municipalityPercentage  | mask: 'separator.2' }}</span>
            </div>
            <div
                class="col-2 py-2 min-w-100px border-left border-bottom text-right">
              <span class="pr-3"> {{ ir?.page2?.worksheetB?.scheduleMisc?.municipalityTaxableIncome |  number: numberFormat }}</span>
            </div>
            <div
                class="col-2 border-left border-bottom py-2 text-right">
              <span class="pr-3">{{ ir?.page2?.worksheetB?.scheduleMisc?.taxCreditAllowed  |  number: numberFormat }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-5 min-w-200px border-bottom py-2"> 5. {{ 'Taxpayer.net-operating-loss-carryforward' | translate }}
            </div>
            <div
                class="col-2  border-left border-bottom py-2  min-w-200px border-bottom text-right"><span
                class="pr-3">{{ ir?.page2?.worksheetB?.lossCarryForward?.netOperatingLossCarryForward  |  number: numberFormat }}</span>
            </div>
            <div
                class="col-1 py-2 min-w-100px  border-left border-bottom text-right">
              <span class="pr-3">{{ ir?.page2?.worksheetB?.lossCarryForward?.municipalityPercentage  | mask: 'separator.2' }}</span>
            </div>
            <div
                class="col-2 py-2 min-w-100px border-left border-bottom text-right">
              <span class="pr-3">{{ ir?.page2?.worksheetB?.lossCarryForward?.municipalityLossCarryForward |  number: numberFormat }}</span>
            </div>
            <div
                class="col-2 border-left border-bottom py-2 text-right">
              <span class="pr-3">{{ ir?.page2?.worksheetB?.lossCarryForward?.taxCreditAllowed  |  number: numberFormat }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-5  min-w-200px border-bottom py-2"> 6. {{ 'Taxpayer.total-income-loss-combine' | translate }}
            </div>
            <div class="col-2  min-w-200px border-left border-bottom py-2 text-right">
              <span class="pr-3">{{ ir?.page2?.worksheetB?.totalIncomeOrLoss  |  number: numberFormat }}</span>
            </div>
            <div class="col-1 min-w-100px border-left border-bottom py-2"></div>
            <div
                class="col-2  min-w-100px border-bottom border-left py-2 text-right"><span
                class="pr-3">{{ ir?.page2?.worksheetB?.totalMunicipalityTaxableIncome  |  number: numberFormat }}</span>
            </div>
            <div
                class="col-2 border-left border-bottom py-2 text-right"><span
                class="pr-3">{{ ir?.page2?.worksheetB?.totalTaxCreditAllowedForTaxPaidToOtherCities  |  number: numberFormat }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="hasAdjustments()" class="row py-3">
      <div class="col-12">
        <div class="row p-2 bg-light border">
          <div class="col-md-2 col-sm-3 col-12 font-weight-bold">{{ 'Taxpayer.worksheet' | translate }} C</div>
          <div class="col-md-10 col-sm-8 col-12">{{ 'Taxpayer.adjustment-to-income' | translate }}</div>
        </div>
        <div class="row py-2">
          <div class="col-lg-9 col-7"><h6 class="font-weight-medium mb-0">{{ 'Taxpayer.explanation' | translate }}</h6></div>
          <div class="col-lg-3 col-5"><h6 class="font-weight-medium mb-0">{{ 'Taxpayer.adjustment-amount' | translate }}</h6></div>
        </div>
        <div *ngFor="let adjustment of  getAdjustmentToIncomeLine?.value" class="row border py-2">
          <div class="col-lg-9 col-7 border-right">
            {{ adjustment.explanation }}
          </div>
          <div class="col-lg-3 col-5 text-right ">
            <span class="pr-3"> {{ adjustment.column1Adjustment   |  number: numberFormat }}</span>
          </div>
        </div>
        <div class="row py-2">
          <div class="col-lg-9 col-7 border-right font-weight-medium text-right">{{ 'Taxpayer.net-adjustment' | translate }}</div>
          <div class="col-lg-3 col-5 border-bottom border-right text-right">
            <span class="pr-3"> {{ ir?.adjustments  |  number: numberFormat }}</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="(config$|async)?.components?.individual?.eFileType !== 'W2' && hasScheduleY()" class="row py-3">
      <div class="col-12">
        <div class="row p-2 bg-light border">
          <div class="col-md-2 col-sm-3 col-12 font-weight-bold">{{ 'Taxpayer.schedule-cp' | translate }} Y</div>
          <div class="col-md-10 col-sm-8 col-12">{{ 'Taxpayer.business-allocation' | translate }}</div>
        </div>
        <div class="schedule-y">
          <div class="row border-left border-right border-bottom">
            <div class="col-6 min-w-305px border-right"></div>
            <div class="col-2 font-weight-bold min-w-100px text-center border-right">A. {{ 'Taxpayer.located-everywhere' | translate }}
            </div>
            <div class="col-2 font-weight-bold min-w-100px text-center border-right">B. {{ 'Taxpayer.located-in-municipality' | translate }}
            </div>
            <div class="col-2 font-weight-bold min-w-100px text-center">C. {{ 'Taxpayer.percentage' | translate }} (B ÷ A)</div>
          </div>
          <div class="row">
            <div class="col-1 font-weight-bold py-1 border-bottom min-w-80px">{{ 'Taxpayer.step' | translate }} 1.
            </div>
            <div class="col-5 border-left py-1 border-bottom min-w-225px"> {{ 'Taxpayer.average-original-cost-property' | translate }}
            </div>
            <div
                class="col-2 border-left py-1 border-bottom min-w-100px text-right ">
              <span class="pr-3">{{ scheduleYFields?.averageOriginalCostOfPropertyLocatedEverywhere  |  number: numberFormat }}</span>
            </div>
            <div
                class="col-2 border-left py-1 border-bottom min-w-100px text-right ">
              <span class="pr-3">{{ scheduleYFields?.averageOriginalCostOfPropertyLocatedInMunicipality  |  number: numberFormat }}</span>
            </div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px"></div>
          </div>
          <div class="row">
            <div class="col-1 min-w-80px border-bottom">
            </div>
            <div class="col-5 border-left py-1 border-bottom min-w-225px">{{ 'Taxpayer.gross-annual-rent' | translate }}
            </div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px text-right "><span
                class="pr-3">{{ scheduleYFields?.grossAnnualRentPaidLocatedEverywhere  |  number: numberFormat }}</span>
            </div>
            <div
                class="col-2 border-left py-1 border-bottom min-w-100px text-right "><span
                class="pr-3">{{ scheduleYFields?.grossAnnualRentPaidLocatedInMunicipality  |  number: numberFormat }}</span>
            </div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px"></div>
          </div>
          <div class="row">
            <div class="col-1 min-w-80px border-bottom">
            </div>
            <div class="col-5 border-left py-1 border-bottom min-w-225px min-w-80px">{{ 'Taxpayer.total-step' | translate }} 1</div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px text-right "><span
                class="pr-3">{{ scheduleYFields?.step1Everywhere  |  number: numberFormat }}</span></div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px text-right "><span
                class="pr-3">{{ scheduleYFields?.step1Municipality  |  number: numberFormat }}</span></div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px text-right "><span
                class="pr-3">{{ scheduleYFields?.totalStep1Percentage  |  number: numberFormat }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-1 font-weight-bold min-w-80px border-bottom">{{ 'Taxpayer.step' | translate }} 2.
            </div>
            <div class="col-5 border-left py-1 border-bottom min-w-225px min-w-80px">{{ 'Taxpayer.wages-salaries-compensation' | translate }}
            </div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px text-right "><span
                class="pr-3">{{ scheduleYFields?.step2Everywhere  |  number: numberFormat }}</span></div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px text-right "><span
                class="pr-3">{{ scheduleYFields?.step2Municipality  |  number: numberFormat }}</span></div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px text-right "><span
                class="pr-3">{{ scheduleYFields?.totalStep2Percentage  |  number: numberFormat }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-1 font-weight-bold min-w-80px border-bottom">{{ 'Taxpayer.step' | translate }} 3.
            </div>
            <div class="col-5 border-left py-1 border-bottom min-w-225px">{{ 'Taxpayer.gross-receipts-sales' | translate }}
            </div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px text-right "><span
                class="pr-3">{{ scheduleYFields?.step3Everywhere  |  number: numberFormat }}</span></div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px text-right "><span
                class="pr-3">{{ scheduleYFields?.step3Municipality  |  number: numberFormat }}</span></div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px text-right "><span
                class="pr-3">{{ scheduleYFields?.totalStep3Percentage  |  number: numberFormat }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-1 font-weight-bold min-w-80px border-bottom">{{ 'Taxpayer.step' | translate }} 4.
            </div>
            <div class="col-5 border-left py-1 border-bottom min-w-225px">{{ 'Taxpayer.total-percentages' | translate }}
            </div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px"></div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px"></div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px text-right "><span
                class="pr-3">{{ scheduleYFields?.totalStep4Percentage  |  number: numberFormat }}</span>
            </div>
          </div>

          <div class="row">
            <div class="col-1 font-weight-bold min-w-80px border-bottom">{{ 'Taxpayer.step' | translate }} 5.
            </div>
            <div class="col-7 border-left min-w-326px border-bottom">{{ 'Taxpayer.average-percentage-divide' | translate }}
            </div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px"></div>
            <div class="col-2 border-left py-1 border-bottom min-w-100px text-right "><span
                class="pr-3">{{ scheduleYFields?.totalStep5Percentage  |  number: numberFormat }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="false" class="row py-3">
      <div class="col-12">
        <div class="row p-2 bg-light border">
          <div class="col-2 font-weight-bold">W2s</div>
          <div class="col-10 font-weight-bold"></div>
        </div>
        <div class="w-2-sec">
          <div class="row py-1">
            <div class="col-4 font-weight-medium mb-0">
              {{ 'Taxpayer.employer-name' | translate }}
            </div>
            <div class="col-2 font-weight-medium mb-0 min-w-100px">
              {{ 'Taxpayer.fid' | translate }}
            </div>
            <div class="col-2 font-weight-medium mb-0 min-w-100px">
              {{ 'Taxpayer.locality' | translate }}
            </div>
            <div class="col-1 font-weight-medium mb-0 min-w-100px text-right pr-3">
              {{ 'Taxpayer.local-wages' | translate }}
            </div>
            <div class="col-1 font-weight-medium mb-0 min-w-100px text-right pr-3">
              {{ 'Taxpayer.local-tax' | translate }}
            </div>
            <div class="col-2 font-weight-medium mb-0 min-w-100px text-right pr-3">
              {{ 'Taxpayer.credit-for-tax-withheld' | translate }}
            </div>
          </div>
          <ng-container *ngFor="let wage of form.get('page2.worksheetA.taxpayerScheduleOfWages')?.value;">
            <ng-container *ngFor="let locality of wage?.w2LocalityInfo; let index = index;">
              <div class="row py-1">
                <div class="col-4 ">
                  {{ wage.employersName }}
                </div>
                <div class="col-2 min-w-100px">
                  {{ wage.employersFederalId | mask: '00-00000000' }}
                </div>
                <div class="col-2 min-w-100px">
                  {{ locality.taxWithheldLocalityName }}
                </div>
                <div class="col-1 min-w-100px text-right">
                  <span class="pr-3"> {{ locality.wagesBox18  |  number: numberFormat }}</span>
                </div>
                <div class="col-1 min-w-100px text-right">
                  <span class="pr-3"> {{ locality.taxWithheldBox19  |  number: numberFormat }}</span>
                </div>
                <div class="col-2 min-w-100px text-right">
                  <span class="pr-3"> {{ wage.taxCreditAllowedForOtherCities  |  number: numberFormat }}</span>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngFor="let wage of form.get('page2.worksheetA.spouseScheduleOfWages')?.value;">
            <ng-container *ngFor="let locality of wage?.w2LocalityInfo; let index = index;">
              <div class="row py-1">
                <div class="col-4 ">
                  {{ wage.employersName }}
                </div>
                <div class="col-2 min-w-100px">
                  {{ wage.employersFederalId | mask: '00-00000000' }}
                </div>
                <div class="col-2 min-w-100px">
                  {{ locality.taxWithheldLocalityName }}
                </div>
                <div class="col-1 min-w-100px text-right">
                  <span class="pr-3"> {{ locality.wagesBox18  |  number: numberFormat }}</span>
                </div>
                <div class="col-1 min-w-100px text-right">
                  <span class="pr-3"> {{ locality.taxWithheldBox19  |  number: numberFormat }}</span>
                </div>
                <div class="col-2 min-w-100px text-right">
                  <span class="pr-3"> {{ wage.taxCreditAllowedForOtherCities  |  number: numberFormat }}</span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <div *ngIf="false && (config$|async)?.components?.individual?.eFileType !== 'W2' && hasSchedules()"
        class="row py-3">
      <div class="col-12">
        <div class="row p-2 bg-light border">
          <div class="col-2  font-weight-bold text-uppercase">{{ 'Taxpayer.schedules-lw' | translate }}</div>
          <div class="col-10 font-weight-bold"></div>
        </div>
        <div class="schedule-last">
          <div class="row py-1">
            <div class="col-4 font-weight-medium mb-0">
              {{ 'Taxpayer.company-name' | translate }}
            </div>
            <div class="col-2 min-w-100px font-weight-medium mb-0">
              {{ 'Taxpayer.fid' | translate }}
            </div>
            <div class="col-2 min-w-100px font-weight-medium mb-0">
              {{ 'Taxpayer.municipality' | translate }}
            </div>
            <div class="col-1 min-w-100px font-weight-medium mb-0 text-right pr-3">
              {{ 'Taxpayer.income' | translate }}
            </div>
            <div class="col-1 min-w-100px font-weight-medium mb-0 text-right pr-3">
              {{ 'Taxpayer.taxes-paid' | translate }}
            </div>
            <div class="col-2 min-w-100px font-weight-medium mb-0">
              {{ 'Taxpayer.type' | translate }}
            </div>
          </div>
          <ng-container
              *ngFor="let detail of form.get('page2.worksheetB.otherIncomeTypeScheduleCDetail')?.value;">
            <div class="row py-1">
              <div class="col-4">
                {{ detail.companyName }}
              </div>
              <div class="col-2 min-w-100px">
                {{ detail.companyFederalId | mask: '00-00000000' }}
              </div>
              <div class="col-2 min-w-100px">
                {{ detail.municipality }}
              </div>
              <div class="col-1 min-w-100px text-right">
                <span class="pr-3">{{ detail.amount  |  number: numberFormat }}</span>
              </div>
              <div class="col-1 min-w-100px text-right">
                <span class="pr-3">{{ detail.taxesPaidAmount  |  number: numberFormat }}</span>
              </div>
              <div class="col-2 min-w-100px">
                C
              </div>
            </div>
          </ng-container>
          <ng-container
              *ngFor="let detail of form.get('page2.worksheetB.otherIncomeTypeScheduleEDetail')?.value;">
            <div class="row py-1">
              <div class="col-4">
                {{ detail.companyName }}
              </div>
              <div class="col-2 min-w-100px">
                {{ detail.companyFederalId | mask: '00-00000000' }}
              </div>
              <div class="col-2 min-w-100px">
                {{ detail.municipality }}
              </div>
              <div class="col-1 min-w-100px text-right">
                <span class="pr-3">{{ detail.amount  |  number: numberFormat }}</span>
              </div>
              <div class="col-1 min-w-100px text-right">
                <span class="pr-3">{{ detail.taxesPaidAmount  |  number: numberFormat }}</span>
              </div>
              <div class="col-2 min-w-100px">
                {{ detail.miscIncomeType || 'Misc E' }}
              </div>
            </div>
          </ng-container>
          <ng-container
              *ngFor="let detail of form.get('page2.worksheetB.otherIncomeTypeScheduleK1Detail')?.value;">
            <div class="row py-1">
              <div class="col-4">
                {{ detail.companyName }}
              </div>
              <div class="col-2 min-w-100px">
                {{ detail.companyFederalId | mask: '00-00000000' }}
              </div>
              <div class="col-2 min-w-100px">
                {{ detail.municipality }}
              </div>
              <div class="col-1 min-w-100px text-right">
                <span class="pr-3">{{ detail.amount  |  number: numberFormat }}</span>
              </div>
              <div class="col-1 min-w-100px text-right">
                <span class="pr-3">{{ detail.taxesPaidAmount  |  number: numberFormat }}</span>
              </div>
              <div class="col-2 min-w-100px">
                {{ detail.miscIncomeType }}
              </div>
            </div>
          </ng-container>
          <ng-container
              *ngFor="let detail of form.get('page2.worksheetB.otherIncomeTypeScheduleMiscDetail')?.value;">
            <div class="row py-1">
              <div class="col-4">
                {{ detail.companyName }}
              </div>
              <div class="col-2 min-w-100px">
                {{ detail.companyFederalId | mask: '00-00000000' }}
              </div>
              <div class="col-2 min-w-100px">
                {{ detail.municipality }}
              </div>
              <div class="col-1 min-w-100px text-right">
                <span class="pr-3"> {{ detail.amount  |  number: numberFormat }}</span>
              </div>
              <div class="col-1 min-w-100px text-right">
                <span class="pr-3"> {{ detail.taxesPaidAmount  |  number: numberFormat }}</span>
              </div>
              <div class="col-2 min-w-100px">
                {{ detail.miscIncomeType }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="row py-3 border-top align-items-center mb-md-1 mb-2">
      <div class="col-md-11 font-14 font-weight-600">
        {{ 'Taxpayer.return-correct-and-complete-taxable' | translate }}
      </div>
      <div class="col-md-1 col-4">
        <div class="form-check font-weight-bold d-flex align-items-center filling-form">
          <input [class.is-invalid]="form.get('authorization')?.invalid && form.get('authorization')?.touched"
              class="custom-control-input"
              formControlName="authorization"
              id="authorizationCheck"
              type="checkbox"
              [attr.aria-invalid]="form.get('authorization')?.invalid && form.get('authorization')?.touched ? 'true' : 'false'"
          >
          <label class="custom-control-label" for="authorizationCheck">
            {{ 'Taxpayer.yes' | translate }}
          </label>
        </div>
      </div>
    </div>
    <div class="row py-3 border-top">
      <div class="col-12 d-flex justify-content-end">
        <button (click)="back()" 
            class="btn btn-secondary font-weight-600 mr-2" 
            type="button"
            tabindex="0" 
            role="button" 
            [attr.aria-label]="'Buttons.back-btn' | translate">
          <span>{{ "Buttons.back-btn" | translate }}</span>
        </button>
        <button (click)="next()" 
             [disabled]="isLoading$ | async" 
             class="btn btn-primary font-weight-600"
             id="submit"
             tabindex="0" 
             role="button" 
             [attr.aria-label]="'Buttons.next-btn' | translate" 
             [attr.aria-disabled]="(isLoading$ | async) ? true : false" 
             [attr.aria-busy]="(isLoading$ | async) ? true : false">
          <ui-spinner [class.active]="isLoading$ | async">  <span>{{
              "Buttons.next-btn" | translate
            }}</span></ui-spinner>
        </button>
      </div>
    </div>
  </form>
</div>

<ng-template #noPermission>
  <ui-forbidden></ui-forbidden>
</ng-template>
