import {ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HexaDetailComponent} from "@hexalang/ui/core";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DateFormControl, EPermission, PaymentHelper} from "@city-tax/shared";
import {BehaviorSubject, Observable} from "rxjs";

import {AuthService} from "@city-tax/features/auth";
import {NgxPermissionsService} from "ngx-permissions";
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {CourtesyWithholdingComponent} from "../controls/courtesy-withholding/courtesy-withholding.component";
import {ToastrService} from 'ngx-toastr';
import {PartYearHelper} from "../../../../../helpers/oh/part-year.helper";
import * as _ from "lodash";
import * as moment from "moment/moment";
import {IndividualService} from "../../../../../services/individual.service";

@Component({
  selector: "city-tax-feature-individual-filing-oh-w2-form",
  templateUrl: "./individual-filing-oh-w2-form.component.html",
  styleUrls: ["./individual-filing-oh-w2-form.component.scss"],
})
export class IndividualFilingOhW2FormComponent
  extends HexaDetailComponent
  implements OnInit, OnDestroy {
  public w2form: FormGroup;
  public title$;
  public modalRef?: BsModalRef;
  public taxYear = this.individualService.taxYear;
  public currentYear = this.individualService.currentYear;
  public ePermission = EPermission;
  public hasPermission$: Observable<boolean>;
  public isLoading$ = this.individualService.isLoading$;
  public isUploading$ = this.individualService.isUploading$;

  public allowLocality$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public error = null;
  public taxDocument = this.authService.organization.taxDocument;
  public city = this.authService.config;
  public individualReturn$ = this.individualService.individualReturn$;
  public individualReturn = _.cloneDeep(this.individualService.individualReturn);
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public partYearHelper: any;

  changeDetectorRef: any;
  @Input("isEdit") isEdit: boolean = false;
  @Input("row") row: any;
  @Input("index") index: number;
  @Output("updateW2Form") updateW2Form = new EventEmitter();
  @Output("addW2Form") addW2Form = new EventEmitter();
  types = ["taxpayer", "spouse"];
  public minDate = moment(`${this.taxYear}-01-01`, 'YYYY-MM-DD').endOf('day').toDate();
  public maxDate = moment(`${this.taxYear}-12-31`, 'YYYY-MM-DD').endOf('day').toDate();
  public taxCreditAllowedForOtherCities = 0;

  constructor(
    injector: Injector,
    private individualService: IndividualService,
    private authService: AuthService,
    public permissionService: NgxPermissionsService,
    private formBuilder: FormBuilder,
    private modal: BsModalService,
    private paymentHelper: PaymentHelper,
    private modalService: BsModalService,
    public toasterService: ToastrService,
    private cdRef: ChangeDetectorRef,
  ) {
    super(injector);
    this.partYearHelper = new PartYearHelper(this.paymentHelper, this.individualReturn.taxYear);
  }

  get w2LocalityInfo(): FormArray {
    return this.w2form.get("w2LocalityInfo") as FormArray;
  }

  showExcludedFlag() {
    if (this.individualReturn.residency === 'N') {
      if (this.city.name === 'Dublin' || this.city.name === 'Green') {
        return true;
      }
    }
    return false;
  }

  ngOnInit(): void {
    // 1544 – otherCityTaxWithHeld – Total of All Box 19 for that W2
    // 1543 – taxCreditAllowedForOtherCities – Total of Tax Credit for Tax WH allowed for Other cities
    // 1548 – totalWages – Sum of all off Box 5s or 18s depending on what would be greater.
    // 1550 – taxableWages – Taxable wages would be if part year the total of the wages the are taxable
    // 1551 – taxableWagesNonResident – would be wages only if earned in city if non-resident
    this.w2form = this.formBuilder.group({
      index: this.index === -1 ? null : this.index,
      type: [null, Validators.required],
      employersName: [null, Validators.required],
      employersFederalId: [null, Validators.required],
      taxCreditAllowedForOtherCities: null,
      militaryWagesFlag: false,
      excludedWagesFlag: false,
      refundRequestedFlag: false,
      under18WagesFlag: null,
      deferredCompensationFlag: null,
      multipleLocations: false,
      fillingCity: this.city.name,
      birthdate: new DateFormControl(''),
      box1Wages: [null, Validators.required],
      totalBox5Wages:[null, Validators.required],
      taxableWages: null,
      excludedWages: null,
      otherCityTaxWithHeld: null,
      totalWages: null,
      taxableWagesNonResident: null,
      dateWorkedFrom: new DateFormControl(''),
      dateWorkedTo: new DateFormControl(''),
      w2LocalityInfo: this.formBuilder.array([], [Validators.required]),
    });
    if (this.row && JSON.stringify(this.row) !== "{}") {
      this.row.militaryWagesFlag = this.getBoolean(this.row.militaryWagesFlag);
      this.w2form.patchValue(this.row);
      for (const locality of this.row.w2LocalityInfo) {
        this.loadW2LocalityInfo(locality);
      }
      this.checkAllowLocality();
    }
    //this.w2form.get("w2LocalityInfo")?.addValidators(Validators.required);
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      this.isDisabled$.next(true)
      this.w2form.disable();
    }
  }

  addW2LocalityInfo() {
    const a: any = {
      taxWithheldLocalityName: [null, Validators.required],
      localityTaxRate: null,
      wagesBox18: null,
      taxWithheldBox19: null,
      refundRequestedAmount: null,
      creditForTaxesPaid: null,
    };
    if (this.w2LocalityInfo.length === 1 && !this.getBoolean(this.w2form.get("multipleLocations").value) &&
      this.individualReturn.resident == true) {
      this.modalRef = this.modalService.show(CourtesyWithholdingComponent, {
        class: "modal-sm modal-dialog-centered",
        initialState: {},
      });
      this.modalRef.onHide.subscribe((n: string | any) => {
        const multipleLocations = this.getBoolean(this.modalRef.content);
        this.w2form.get("multipleLocations").setValue(multipleLocations);
        this.w2LocalityInfo.push(this.formBuilder.group(a));
        this.checkAllowLocality();
      });
    } else {
      this.w2LocalityInfo.push(this.formBuilder.group(a));
    }
  }

  checkAllowLocality() {
    if (this.w2LocalityInfo?.controls?.length === 2 && !this.w2form.get('multipleLocations').value) {
      this.allowLocality$.next(true);
    }
  }

  loadW2LocalityInfo(locality) {
    const a: any = {
      taxWithheldLocalityName: [
        locality.taxWithheldLocalityName,
        Validators.required,
      ],
      localityTaxRate: locality.localityTaxRate,
      wagesBox18: locality.wagesBox18,
      taxWithheldBox19: locality.taxWithheldBox19,
      refundRequestedAmount: locality.refundRequestedAmount,
      creditForTaxesPaid: locality.creditForTaxesPaid,
    };
    this.w2LocalityInfo.push(this.formBuilder.group(a));
  }

  removeW2LocalityInfo(locality) {
    const index = this.w2LocalityInfo.controls.findIndex(control => control.value === locality.value);
    console.log(index, locality);
    if (index !== -1) { // Make sure the index is found
      this.w2LocalityInfo.removeAt(index);
    }

    if (this.w2LocalityInfo.length === 1) {
      this.w2form.get("multipleLocations").setValue(false);
    }
  }

  public trackByFn = (index, item) => {
    return index;
  };

  disableOthers(event, control) {
    const isChecked = (event.target as HTMLInputElement).checked;
    const excludedWagesFlag = this.w2form.get('excludedWagesFlag');
    const under18WagesFlag = this.w2form.get('under18WagesFlag');
    const deferredCompensationFlag = this.w2form.get('deferredCompensationFlag');
    const refundRequestedFlag = this.w2form.get('refundRequestedFlag');
    const militaryWagesFlag = this.w2form.get('militaryWagesFlag');
    const birthdate = this.w2form.get('birthdate');

    if (isChecked) {
      if (control === 'deferredCompensationFlag') {
        under18WagesFlag.disable();
        refundRequestedFlag.disable();
        militaryWagesFlag.disable();
        excludedWagesFlag.disable();
      } else if (control === 'refundRequestedFlag') {
        under18WagesFlag.disable();
        deferredCompensationFlag.disable();
        militaryWagesFlag.disable();
        excludedWagesFlag.disable();
      } else if (control === 'excludedWagesFlag') {
        under18WagesFlag.disable();
        deferredCompensationFlag.disable();
        refundRequestedFlag.disable();
        militaryWagesFlag.disable();
      } else if (control === 'militaryWagesFlag') {
        excludedWagesFlag.disable()
        deferredCompensationFlag.disable();
        refundRequestedFlag.disable();
        under18WagesFlag.disable();
      } else {
        excludedWagesFlag.disable()
        deferredCompensationFlag.disable();
        refundRequestedFlag.disable();
        militaryWagesFlag.disable();
      }
    } else {
      excludedWagesFlag.enable()
      under18WagesFlag.enable();
      militaryWagesFlag.enable();
      refundRequestedFlag.enable();
      deferredCompensationFlag.enable();
    }

    if (under18WagesFlag.value) {
      birthdate.addValidators(Validators.required);
      birthdate.updateValueAndValidity();
    } else {
      birthdate.removeValidators(Validators.required);
      birthdate.clearValidators()
    }

  }

  setValues(payload) {
    payload.w2LocalityInfo = [].concat(payload.w2LocalityInfo);
    const box5 = this.paymentHelper.decimalValue(payload.totalBox5Wages);
    const box1 = this.paymentHelper.decimalValue(payload.box1Wages);
    const box18 = this.paymentHelper.sum(payload.w2LocalityInfo, 'wagesBox18');

    payload.totalWages = box5 > box18 ? box5 : box18;
    payload.taxableWages = payload.totalWages;

    if (payload.excludedWagesFlag) {
      payload.excludedWages = payload.totalWages;
    } else {
      payload.excludedWages = 0;
    }

    // 1544 – otherCityTaxWithHeld – Total of All Box 19 for that W2
    // 1543 – taxCreditAllowedForOtherCities – Total of Tax Credit for Tax WH allowed for Other cities
    // 1548 – totalWages – Sum of all off Box 5s or 18s depending on what would be greater.
    // 1550 – taxableWages – Taxable wages would be if part year the total of the wages the are taxable
    // 1551 – taxableWagesNonResident – would be wages only if earned in city if non-resident

    const taxCreditAllowedForOtherCities = this.paymentHelper.sum(payload.w2LocalityInfo, 'creditForTaxesPaid');
    // console.log('taxCreditAllowedForOtherCities', taxCreditAllowedForOtherCities);
    if (taxCreditAllowedForOtherCities > 0) {
      payload.taxCreditAllowedForOtherCities = this.paymentHelper.sum(payload.w2LocalityInfo, 'creditForTaxesPaid');
      payload.otherCityTaxWithHeld = this.paymentHelper.sum(payload.w2LocalityInfo, 'taxWithheldBox19');
    }
    // console.log('isPartYear', this.isPartYear());
    if (this.isPartYear()) {
      const daysWorked = this.partYearHelper.daysWorkedAsNonResident(this.individualReturn.partYearFromDate, this.individualReturn.partYearToDate, payload.dateWorkedFrom, payload.dateWorkedTo);
      const perDay = this.incomePerDay(payload);
      payload.taxableWages = this.paymentHelper.decimalValue(daysWorked * perDay);
    } else {
      delete payload.taxCreditAllowedForOtherCities;
      delete payload.otherCityTaxWithHeld;
    }

    if (this.individualReturn.residency === 'N') {
      payload.taxableWagesNonResident = payload.totalWages;
    } else {
      delete payload.taxableWagesNonResident;
    }
    // console.log(payload);
  }

  add() {
    const payloadString = JSON.stringify(this.w2form.getRawValue());
    const payload = JSON.parse(payloadString);
    this.setValues(payload);
    console.log('addW2Form', payload);
    this.addW2Form.emit(payload);
  }

  update() {
    const payloadString = JSON.stringify(this.w2form.getRawValue());
    const payload = JSON.parse(payloadString);
    this.setValues(payload);
    this.updateW2Form.emit(payload);
  }

  hide() {
    this.modal?.hide();
  }

  submit() {
    if (this.w2LocalityInfo.length === 0) {
      this.w2form.markAllAsTouched();
      return;
    }
    const isCourtesyWithholding = this.getBoolean(this.w2form.get("multipleLocations").value);

    if (!isCourtesyWithholding && this.w2LocalityInfo.length > 1) {

      const hasCity = this.w2LocalityInfo.value?.find((w: any) => w?.taxWithheldLocalityName == this.city.name);
      if (!hasCity) {
        this.toasterService.error(
          `One localities must be a ${this.city.name}.`,
          "Invalid"
        );
        return;
      }
    }

    this.taxCreditAllowedForOtherCities = this.paymentHelper.sum(this.w2LocalityInfo.value, 'creditForTaxesPaid');
    // console.log('taxCreditAllowedForOtherCities', this.taxCreditAllowedForOtherCities);

    if (

      this.w2form.get('refundRequestedFlag').value
      && ((!this.individualReturn.resident && !(this.individualReturn?.files?.some(file => file.type === 'Wage-Other')))
        || (this.individualReturn.resident && this.taxCreditAllowedForOtherCities > 0 && !(this.individualReturn?.files?.some(file => file.type === 'City-Other'))))

    ) {
      this.toasterService.error('Upload the required documents.');
    } else {
      if (this.w2form.valid) {
        if (this.isEdit) {
          this.update();
        } else {
          this.add();
        }
      } else {
        this.w2form.markAllAsTouched();
      }
    }   
  }

  getBoolean(any) {
    return (
      any === true ||
      any === "true" ||
      any === "TRUE" ||
      any === "X" ||
      any === "x" ||
      any === 1 ||
      any === "1" ||
      any === "Y"
    );
  }

  isPartYear() {
    const residency = this.individualReturn.residency;
    const partYearToDate = this.individualReturn.partYearToDate;
    const partYearFromDate = this.individualReturn.partYearToDate;
    if (residency === "P") return true;
    return !!(partYearToDate || partYearFromDate);
  }

  incomePerDay(wage) {
    const wageAmount = this.getWageAmount(wage);
    if (wageAmount === 0) return 0;
    const daysWorked = this.partYearHelper.daysWorked(wage.dateWorkedFrom, wage.dateWorkedTo);
    return this.paymentHelper.decimalValue(wageAmount / daysWorked, 4);
  }

  getWageAmount(wage) {
    let wageAmount = this.paymentHelper.decimalValue(wage.totalBox5Wages) === 0 ? this.paymentHelper.decimalValue(wage.box1Wages) : this.paymentHelper.decimalValue(wage.totalBox5Wages);
    if (wage.multipleLocations) {
      wageAmount = 0;
    }
    const filingCityWages = wage?.w2LocalityInfo;
    if (filingCityWages.length === 0) return 0;
    if (!this.otherLocalityThenFillingCity(filingCityWages)) return 0;

    for (const loc of filingCityWages) {
      if (loc.taxWithheldLocalityName !== this.city.name) {
        wageAmount = wageAmount > this.paymentHelper.decimalValue(loc.wagesBox18) ? wageAmount : this.paymentHelper.decimalValue(loc.wagesBox18);
      }
    }
    return wageAmount;
  }

  otherLocalityThenFillingCity(filingCityWages) {
    return filingCityWages.find((loc) => loc.taxWithheldLocalityName !== this.city.name);
  }

  checkOtherCityReturn() {
    return this.individualReturn.files.find((file) => file.type === 'City-Other');
  }

  async uploadDocument(type: string, event: any) {
    const media = {
      file: event.target.files[0],
      mimeType: event.target.files[0].type,
    };
    try {
      this.individualReturn = await this.individualService.uploadFile(this.authService.organization.id, this.individualService.individualReturn.id, type, media);
    } catch (error) {
    }
  }

  async delete(file: any) {
    try {
      this.individualReturn = await this.individualService.deleteFile(
        this.authService.organization.id,
        this.individualService.individualReturn.id,
        file.id
      );
    } catch (error) {

    }
  }
}
