import {IIndividualPaymentBase} from "../interfaces/individual-payment.interface";
import {EPaymentMethod, EPaymentStatus, IBankBase} from "@city-tax/shared";

export class IndividualPaymentModel implements IIndividualPaymentBase {
  amount: number;
  bank: IBankBase;
  clearedDate: Date;
  warningFlag: boolean;
  taxpayer: { name: string };
  feeAmount: number;
  id: string;
  locator: string;
  message: string;
  paymentDate: string;
  originalPaymentDate: string;
  intent: { client_secret?: string, object: string, };
  pm: {
    id: string;
    card: {
      brand: string;
      cardNumber: string;
      expMonth: string;
      expYear: string;
    },
    bank: {
      accountNumber: string;
      routingNumber: string;
    },
    type: string
  };
  type: EPaymentMethod;
  paymentType: string;
  period: string;
  status: EPaymentStatus;
  taxYear: number;
  total: number;
  createdAt: Date;
  updatedAt: Date;
  isFuturePayment: boolean;

  paymentReminderDate: Date;
  recurring: false;
  additionalPayments: null;
  interval: null;
  scheduledPayments?: Array<any>;
  recurringLocator: null;


  constructor(individualPaymentModel: IndividualPaymentModel) {
    if (!individualPaymentModel || !individualPaymentModel.locator) return;
    this.amount = individualPaymentModel.amount;
    this.bank = individualPaymentModel.bank;
    this.clearedDate = individualPaymentModel.clearedDate;
    this.feeAmount = individualPaymentModel.feeAmount;
    this.id = individualPaymentModel.id;
    this.locator = individualPaymentModel.locator;
    this.message = individualPaymentModel.message;
    this.paymentDate = individualPaymentModel.paymentDate;
    this.paymentDate = individualPaymentModel.paymentDate;
    this.warningFlag = individualPaymentModel.warningFlag;
    this.taxpayer = individualPaymentModel.taxpayer;
    this.type = individualPaymentModel.type;
    this.intent = individualPaymentModel.intent;
    this.paymentType = individualPaymentModel.paymentType;
    this.period = individualPaymentModel.period;
    this.status = individualPaymentModel.status;
    this.taxYear = individualPaymentModel.taxYear;
    this.total = individualPaymentModel.total;
    this.createdAt = individualPaymentModel.createdAt;
    this.updatedAt = individualPaymentModel.updatedAt;
    this.pm = individualPaymentModel.pm;
    this.originalPaymentDate = individualPaymentModel.originalPaymentDate;
    this.isFuturePayment = individualPaymentModel.isFuturePayment;
    this.paymentReminderDate = individualPaymentModel.paymentReminderDate;
    this.recurring = individualPaymentModel.recurring;
    this.additionalPayments = individualPaymentModel.additionalPayments;
    this.interval = individualPaymentModel.interval;
    this.scheduledPayments = individualPaymentModel.scheduledPayments;
    this.recurringLocator = individualPaymentModel.recurringLocator;
  }
}
