<div class="pt-3 px-3">
  <form [formGroup]="formFilter" novalidate>
    <div class="row">
      <div class="col-2">
        <div class="form-control-wrapper form-control-wrapper-both">
          <input
              [form]="formFilter"
              class="form-control"
              formControlName="search"
              placeholder="Search..."
              type="text"
              ui-search-clear
          />
        </div>
      </div>
      <div class="col-2">
        <ng-select
            [clearable]="true"
            [items]="status$ | async"
            [multiple]="false"
            [searchable]="true"
            formControlName="status"
            labelForId="status"
            placeholder="Select status"
        >
          <ng-template let-item="item" ng-label-tmp>
            <div class="d-flex align-items-center pr-2">
              <svg [ngClass]="{
                    'fill-success-500': item === 'Active',
                    'fill-danger-500': item === 'Deleted'
                 }"
                  class="icon mr-2 flex-shrink-0"
                  viewBox="0 0 24 24">
                <path
                    d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                    stroke="none"
                />
              </svg>
              <span>{{ item }}</span>
            </div>
          </ng-template>
          <ng-template
              let-index="index"
              let-item="item"
              let-search="searchTerm"
              ng-option-tmp
          >
            <div class="d-flex align-items-center">
              <svg [ngClass]="{
                     'fill-success-500': item === 'Active',
                     'fill-danger-500': item === 'Deleted'
                  }"
                  class="icon icon-sm mr-2 flex-shrink-0"
                  viewBox="0 0 24 24">
                <path
                    d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
                    stroke="none"
                />
              </svg>
              <span>{{ item }}</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="col-auto ml-auto">
        <button
            (click)="showModal('create')"
            class="btn btn-primary"
            type="button"
            [attr.tabindex]="0"
            role="button"
            [attr.aria-label]="'Payment.create' | translate">
            {{ 'Payment.create' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>

<div class="p-3">
  <div class="card">
    <ngx-datatable
        #datatablePaymentMethod
        [columnMode]="ColumnMode.force"
        [columns]="columns"
        [count]="(individual$ | async)?.paymentMethods?.length"
        [externalPaging]="false"
        [externalSorting]="false"
        [footerHeight]="50"
        [headerHeight]="50"
        [limit]="limit"
        [loadingIndicator]="isLoading$ | async"
        [offset]="offset"
        [rowHeight]="50"
        [rows]="(individual$ | async)?.paymentMethods"
        class="bootstrap datatable-flush"
    >
    </ngx-datatable>
  </div>
</div>

<ng-template
    #statusTemplate
    let-i="index"
    let-row="row"
    let-value="value"
    ngx-datatable-cell-template
>
  <div class="d-flex align-items-center">
    <svg
        [ngClass]="{
                'fill-success-500': value === 'Active',
                'fill-danger-500': value === 'Deleted'
            }"
        class="icon icon-sm mr-2 flex-shrink-0"
        viewBox="0 0 24 24"
    >
      <path
          d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z"
          stroke="none"
      />
    </svg>
    <span>{{ value }}</span>
  </div>
</ng-template>

<hexa-checkmark-template></hexa-checkmark-template>

<ng-template #typeTemplate let-i="index" let-value="value">
  <svg *ngIf="value==='card'" class="icon icon-sm pt-1" height="30" tooltip="Credit Card" viewBox="0 0 16 16" width="30">
    <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z"/>
    <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z"/>
  </svg>

  <svg *ngIf="value==='ach' || value==='bank'" class="icon icon-sm pt-1" height="30" tooltip="ACH" viewBox="0 0 16 16" width="30">
    <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z"/>
  </svg>
</ng-template>

<ng-template #detailTemplate let-i="index" let-row="row">
  <div *ngIf="row?.type==='card'">
    <i [ngClass]="formatService.ccIcon(row.card?.brand)" class="fa-brands"></i>
    <span class="pl-2">{{ row.card?.cardNumber }} </span> <span class="pl-2">{{ row.card?.expMonth }}/{{ row.card?.expYear - 2000 }}</span>
  </div>
  <div *ngIf="row?.type==='ach'|| row?.type==='bank'">
    <i class="bi bi-bank2 custom-icon"></i>
    <span class="pl-2">{{ row.bank.routingNumber.slice(-4) | maskLast:4 }}</span>
    <span class="pl-2">{{ row.bank.accountNumber.slice(-4)  | maskLast: 4 }}</span>
  </div>
</ng-template>

<ng-template
    #actionTemplate
    let-i="index"
    let-row="row"
    let-value="value"
>
  <div class="btn-group" dropdown>
    <button
        class="btn btn-icon btn-link p-1"
        dropdownToggle
        type="button"
        tabindex="0"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        [attr.aria-label]="'Open Dropdown'"
    >
      <svg class="icon fill-primary-500" viewBox="0 0 24 24"  role="img" aria-label="Actions menu icon">
        <path
            d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
            stroke="none"
        />
      </svg>
    </button>
    <ul *bsDropdownMenu 
       class="dropdown-menu dropdown-menu-right"
       role="menu"
       aria-labelledby="Open Dropdown">
      <li>
        <a (click)="showModal('update',row)" 
           class="dropdown-item" 
           href="javascript:"
           role="menuitem"
           aria-label="Edit row"
           tabindex="0">
              <span class="d-flex align-items-center">
                   <svg class="icon cursor-pointer" viewBox="0 0 24 24" role="img" aria-label="Edit icon">
                    <path
                        d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z"
                        stroke="none"/>
                  </svg>
                  <span class="ml-2 mr-1">{{'Payment.edit' | translate}}</span>
              </span>
        </a>
      </li>

      <li *ngIf="row.status !== 'Deleted'">
        <a (click)="showModal('delete',row)"
            class="dropdown-item text-danger"
            href="javascript:"
            role="menuitem"
            [attr.aria-label]="'Taxpayer.delete' | translate"
            tabindex="0">
              <span class="d-flex align-items-center">
                <svg class="icon fill-danger-500" viewBox="0 0 24 24" role="img" aria-label="Delete icon">
                  <path
                      d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                      stroke="none"
                  />
                </svg>
                <span class="ml-2 mr-1">{{'Taxpayer.delete' | translate}}</span>
              </span>
        </a>
      </li>
    </ul>
  </div>
</ng-template>

<ng-template #noPermission>
  <ui-forbidden></ui-forbidden>
</ng-template>

<ng-template #noData>
  <span class="text-muted">--</span>
</ng-template>

