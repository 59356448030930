import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "@city-tax/features/auth";
import {PaymentService} from "../../../../services/payment.service";
import {ToastrService} from "ngx-toastr";
import {NgxPermissionsService} from "ngx-permissions";
import {HexaDetailComponent} from "@hexalang/ui/core";
import {DateFormControl, FormatService} from "@city-tax/shared";
import {BsModalRef} from "ngx-bootstrap/modal";
import * as moment from "moment";

@Component({
  selector: 'city-tax-feature-individual-payment-recurring-edit',
  templateUrl: './individual-payment-recurring-edit.component.html',
  styleUrls: ['./individual-payment-recurring-edit.component.scss']
})
export class IndividualPaymentRecurringEditComponent extends HexaDetailComponent
  implements OnInit, OnDestroy {

  public payment: any;
  public config = this.authService.config;
  public form: FormGroup;
  public isLoading$ = this.paymentService.isLoading$;
  public organization = this.authService.organization;
  public nextYear = this.paymentService.nextYear;
  public endString = `${this.nextYear}-12-31`;
  public maxDate = moment(this.endString, 'YYYY-MM-DD').endOf('day').toDate();
  public minDate = this.config?.components?.individual?.multipleSameDayPayments ? moment().subtract(5, "hours").startOf("date").toDate() : moment().endOf("day").toDate();

  constructor(
    injector: Injector,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public paymentService: PaymentService,
    public toastrService: ToastrService,
    public permissionService: NgxPermissionsService,
    public formatService: FormatService,
    public bsModalRef: BsModalRef,
  ) {
    super(injector);
    this.form = this.formBuilder.group({
        amount: [null, Validators.required],
        paymentDate: new DateFormControl('', Validators.required),
        paymentReminderDate: new DateFormControl(''),
      },
    );
  }

  ngOnInit() {
    this.form.patchValue(this.payment);
  }

  public async updatePayment() {
    if (this.form.valid) {
      const payload = this.form.getRawValue();
      try {
        await this.paymentService.updatePayment(this.authService.organization.id, this.payment.id, payload);
        this.bsModalRef.hide();
        setTimeout(() => {
          this.toastrService.success(
            "Payment was successfully updated!",
            "Payment Taxpayer Update"
          );
        }, 1000);
      } catch (error) {

      }

    } else {
      this.form.markAllAsTouched();
    }
  }

  updateFees() {
    this.payment.amount = +(this.form?.value?.amount || 0);
    this.payment.feeAmount = this.calculateFee(this.payment.type, this.payment.amount);
    this.payment.total = this.payment.amount + this.payment.feeAmount;
  }

  calculateFee(type, amount) {
    if (type !== 'card' || +amount === 0) return 0;
    const fee = 0.03;
    const feeMinimum = 1.95;
    const feeAmount = amount * fee;
    return feeAmount > feeMinimum ? feeAmount : feeMinimum;
  }
}
