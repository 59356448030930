<div *ngIf="(individualReturn$ | async)?.status === 'Completed'"
     class="card p-3 position-absolute return-filed m-0 text-center">
     {{ 'Taxpayer.filed' | translate }}
</div>
<div *ngIf="(individualReturn$ | async)?.status === 'Mailed'"
     class="card p-3 position-absolute return-filed m-0 text-center">
     {{ 'Taxpayer.mailed' | translate }}
</div>
<div class="container py-3">
    <form [formGroup]="form" novalidate>
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="d-flex align-items-center justify-content-center">
                    <h5 class="text-center font-weight-bolder">{{ individualReturn?.taxYear }} {{ 'Taxpayer.individual-income-tax' | translate }} </h5>
                </div>
            </div>
        </div>
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="text-center font-weight-bolder">{{ 'Taxpayer.individual-return-due' | translate }} {{ individualReturn?.eFileDeadline | utcDate:'MMMM dd, YYYY' }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row py-3">
            <div class="col-xl-4 col-md-6">
                <div class="row pb-1">
                    <div class="col-5 col-xl-4">{{ 'Taxpayer.filing' | translate }}:</div>
                    <div
                            class="col-7 col-xl-8">{{ individualReturn?.filing == 'S' ? 'Single' : (individualReturn?.filing == 'MJ' ? 'Married filing jointly' : 'Married filing separately') }}
                    </div>
                </div>
                <div class="row pb-1">
                    <div class="col-5 col-xl-4">{{ 'Taxpayer.taxpayer' | translate }}:</div>
                    <div class="col-7 col-xl-8">{{ individualReturn?.taxpayer?.name }}</div>
                </div>
                <div class="row pb-1">
                    <div class="col-5 col-xl-4">{{ 'Taxpayer.account-number' | translate }}:</div>
                    <div class="col-7 col-xl-8">{{ individualReturn?.taxpayer?.accountNumber }}</div>
                </div>
                <div class="row pb-1">
                    <div class="col-5 col-xl-4">SSN:</div>
                    <div class="col-7 col-xl-8">XXX-XX-{{ individualReturn?.taxpayer?.ssn?.substring(5, 9) }}</div>
                </div>
                <!--          <div class="row pb-1">-->
                <!--            <div class="col-5 col-xl-4">Birthdate:</div>-->
                <!--            <div class="col-7 col-xl-8">{{individualReturn?.taxpayer?.birthdate| utcDate:'MM/dd/YYYY'}}</div>-->
                <!--          </div>-->
                <!--          <div class="row pb-1">-->
                <!--            <div class="col-5 col-xl-4">Phone:</div>-->
                <!--            <div class="col-7 col-xl-8">{{individualReturn?.taxpayer?.phone | phone: 'US' }}</div>-->
                <!--          </div>-->
                <div class="row pb-1">
                    <div class="col-5 col-xl-4">{{ 'Taxpayer.email' | translate }}:</div>
                    <div class="col-7 col-xl-8">{{ formatEmail(individualReturn?.taxpayer?.email) }}</div>
                </div>
                <div *ngIf="individualReturn?.filing !== 'S'" class="row pb-1">
                    <div class="col-5 col-xl-4">{{ 'Taxpayer.spouse-ssn' | translate }}:</div>
                    <div class="col-7 col-xl-8">XXX-XX-{{ individualReturn?.spouse?.ssn?.substring(5, 9) }}</div>
                </div>
                <div *ngIf="individualReturn?.filing !== 'S'" class="row pb-1">
                    <div class="col-5 col-xl-4">{{ 'Taxpayer.spouse' | translate }}:</div>
                    <div class="col-7 col-xl-8">{{ individualReturn?.spouse?.name }}</div>
                </div>
            </div>
            <div class="col-xl-4 d-xl-inline-block d-none"></div>
            <div class="col-xl-4 col-md-6 ">
                <div class="row pb-1">
                    <div class="col-5 col-xl-4">{{ 'Taxpayer.resident' | translate }}:</div>
                    <div class="col-7 col-xl-8">
                        <div class="form-check form-check-inline ">
                            <input [checked]="individualReturn?.resident" class="form-check-input" disabled
                                   id="defaultCheckYes" onclick="return false" type="checkbox" [attr.aria-label]="('Taxpayer.resident' | translate) + ' ' + ('Taxpayer.yes' | translate)">
                            <label class="form-check-label" for="defaultCheckYes">
                                {{ 'Taxpayer.yes' | translate }}
                            </label>
                        </div>
                        <div class="form-check form-check-inline ">
                            <input [checked]="!individualReturn?.resident" class="form-check-input" disabled
                                   id="defaultCheckNO" onclick="return false" type="checkbox" [attr.aria-label]="('Taxpayer.resident' | translate) + ' ' + ('Taxpayer.no' | translate)">
                            <label class="form-check-label" for="defaultCheckNo">
                                {{ 'Taxpayer.no' | translate }}
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row pb-1">
                    <div class="col-5 col-xl-4">{{ 'Taxpayer.address' | translate }}:</div>
                    <div class="col-7 col-xl-8">{{ individualReturn?.taxpayer?.address.addressLine1 }}</div>
                </div>
                <div *ngIf="individualReturn?.taxpayer?.address?.addressLine2  || individualReturn?.taxpayer?.address?.aptNumber"
                     class="row pb-1">
                    <div class="col-5 col-xl-4"></div>
                    <div class="col-7 col-xl-8">{{ individualReturn?.taxpayer?.address?.addressLine2 }} {{ individualReturn?.taxpayer?.address?.aptNumber }}</div>
                </div>
                <div class="row align-items-center">
                    <div class="col-5 col-xl-4"></div>
                    <div class="col-7 col-xl-8">{{ individualReturn?.taxpayer?.address?.city }},
                        {{ individualReturn?.taxpayer?.address?.state }} {{ individualReturn?.taxpayer?.address?.postalCode }}
                    </div>
                </div>
                <div *ngIf="individualReturn?.residency==='P'" class="row pb-1">
                    <div class="col-12 text-uppercase">{{ 'Taxpayer.part-year-resident-dates' | translate }}</div>
                </div>
                <div *ngIf="individualReturn?.residency==='P'" class="row pb-1">
                    <div class="col-5 col-xl-5">{{ 'Taxpayer.moved-into-city' | translate }}:</div>
                    <div class="col-7 col-xl-7">{{ individualReturn?.partYearFromDate | utcDate:'MM/dd/yyyy' }}</div>
                </div>
                <div *ngIf="individualReturn?.residency==='P'" class="row pb-1">
                    <div class="col-5 col-xl-5">{{ 'Taxpayer.moved-out-of-city' | translate }}:</div>
                    <div class="col-7 col-xl-7">{{ individualReturn?.partYearToDate | utcDate:'MM/dd/yyyy' }}</div>
                </div>

                <div *ngIf="individualReturn?.residency==='P' && individualReturn?.filing !=='S' " class="row pb-1">
                    <div class="col-12 text-uppercase">{{ 'Taxpayer.spouse-part-year-resident-dates' | translate }}</div>
                </div>
                <div *ngIf="individualReturn?.residency==='P' && individualReturn?.filing !=='S'" class="row pb-1">
                    <div class="col-5 col-xl-5">{{ 'Taxpayer.moved-into-city' | translate }}:</div>
                    <div class="col-7 col-xl-7">{{ individualReturn?.partYearFromDateSpouse | utcDate:'MM/dd/yyyy' }}</div>
                </div>
                <div *ngIf="individualReturn?.residency==='P' && individualReturn?.filing !=='S'" class="row pb-1">
                    <div class="col-5 col-xl-5">{{ 'Taxpayer.moved-out-of-city' | translate }}:</div>
                    <div class="col-7 col-xl-7">{{ individualReturn?.partYearToDateSpouse | utcDate:'MM/dd/yyyy' }}</div>
                </div>
                <div *ngIf="individualReturn?.residency==='P'" class="row pb-1">
                    <div class="col-12">{{ 'Taxpayer.previous-present-address' | translate }}:</div>
                </div>
                <div *ngIf="individualReturn?.residency==='P'" class="row pb-1">
                    <div class="col-5 col-xl-5">{{ 'Taxpayer.address' | translate }}:</div>
                    <div class="col-7 col-xl-7">{{ individualReturn?.previousAddress?.addressLine1 }}</div>
                </div>
                <div *ngIf="individualReturn?.residency==='P' && (individualReturn?.previousAddress?.addressLine2  || individualReturn?.previousAddress?.aptNumber)"
                     class="row pb-1">
                    <div class="col-5 col-xl-5"></div>
                    <div class="col-7 col-xl-7">{{ individualReturn?.previousAddress?.addressLine2 }} {{ individualReturn?.previousAddress?.aptNumber }}</div>
                </div>
                <div *ngIf="individualReturn?.residency==='P'" class="row">
                    <div class="col-5 col-xl-5"></div>
                    <div class="col-7 col-xl-7">{{ individualReturn?.previousAddress?.city }}
                        ,{{ individualReturn?.previousAddress?.state }} {{ individualReturn?.previousAddress?.postalCode }}
                    </div>
                </div>
                <!--        <div class="row pb-1 mt-xl-0 mt-4">-->
                <!--          <div class="col-12">-->
                <!--            <div class="form-group">-->
                <!--              <label class="small font-weight-medium text-muted text-uppercase">-->
                <!--                Estimates Paid in current tax year:-->
                <!--              </label>-->
                <!--              <input [allowNegativeNumbers]="false"-->
                <!--                     class="w-100 form-control bg-light text-right custom-height"-->
                <!--                     formControlName="estimatedTaxPaid"-->
                <!--                     type="text"-->
                <!--                     ui-number-->
                <!--              >-->
                <!--            </div>-->
                <!--          </div>-->
                <!--          <div class="col-12">-->
                <!--            <div class="form-group">-->
                <!--              <label class="small font-weight-medium text-muted text-uppercase">-->
                <!--                Credit Carry Forward from previous tax year:-->
                <!--              </label>-->
                <!--              <input [allowNegativeNumbers]="false"-->
                <!--                     class="w-100 form-control bg-light text-right custom-height"-->
                <!--                     formControlName="priorYearOverpayment"-->
                <!--                     type="text"-->
                <!--                     ui-number-->
                <!--              >-->
                <!--            </div>-->
                <!--          </div>-->
                <!--        </div>-->
            </div>
        </div>

        <div class="row py-3">
            <div class="row py-2 col-12" formGroupName="page2">
                <div class="col-3">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      formControlName="noTaxableIncome"
                      id="noTaxableIncome"
                      [attr.aria-label]="'Taxpayer.no-taxable-income' | translate"
                    />
                    <label for="noTaxableIncome" class="custom-control-label">
                        {{ 'Taxpayer.no-taxable-income' | translate }}
                    </label>
                  </div>
                </div>
              
                <div class="col-9"  *ngIf="form.get('page2.noTaxableIncome')?.value">
                  <textarea
                    formControlName="noTaxableIncomeExplanation"
                    class="form-control"
                    rows="3"
                    [placeholder]="'Taxpayer.provide-explanation-no-taxable-income' | translate"
                  ></textarea>
                </div>
            </div>
            <div class="col-12"  *ngIf="!form.get('page2.noTaxableIncome').value">
                <div class="row bg-light border">
                    <div class="col-lg-11 col-sm-10 col-9 py-2 font-weight-bold">
                        {{ 'Taxpayer.wages-tips-compensation' | translate }}
                    </div>
                    <div class="col-lg-1 col-sm-2 col-3 d-flex justify-content-end align-items-center">
                        <button (click)="openScheduleWages({},false,-1)" 
                                 *ngIf="!(isDisabled$|async)"
                                 class="btn btn-primary font-weight-600 ml-3 py-1"
                                 role="button"
                                 tabindex="0"
                                 [attr.aria-label]="'Taxpayer.add' | translate">
                                 {{ 'Taxpayer.add' | translate }}
                        </button>
                    </div>
                </div>

                <div class="row p-1">
                    <div class="col-12 text-center text-uppercase text-muted text-sm">
                        {{ 'Taxpayer.add-total-wages' | translate }}
                    </div>
                </div>
                <div class="qualify-sec" *ngIf="form.get('page2.worksheetA.taxpayerScheduleOfWages').length > 0 || form.get('page2.worksheetA.spouseScheduleOfWages').length > 0 ">
                    <div class="row py-2 border-bottom align-items-end">
                        <div class="col-1 min-w-60px">
                            <h6 class="font-weight-medium ">T or S</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium"> {{ 'Taxpayer.employer-name' | translate }}</h6>
                        </div>
                        <div class="col-1">
                            <h6 class="font-weight-medium">{{ 'Taxpayer.fid' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium">{{ 'Taxpayer.locality' | translate }}</h6>
                        </div>
                        <div class="col-1">
                            <h6 class="font-weight-medium text-right">{{ 'Taxpayer.qualifying-wages' | translate }}</h6>
                        </div>
                        <div class="col-1">
                            <h6 class="font-weight-medium text-right">{{ 'Taxpayer.tax-withheld' | translate }}</h6>
                        </div>
                        <div class="col-1">
                            <h6 class="font-weight-medium text-right">{{ config?.name }} {{ 'Taxpayer.tax-withheld' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium text-right">{{ 'Taxpayer.tax-credit-allowed-cities' | translate }}</h6>
                        </div>
                        <div class="col-1">
                        </div>
                    </div>
                    <ng-container
                            *ngFor="let wage of this.getTaxpayerScheduleOfWages?.value; let i = index; trackBy:trackByFn">
                        <ng-container *ngFor="let locality of wage?.w2LocalityInfo; let index = index;">
                            <div class="row py-1">
                                <div class="col-1 min-w-60px">
                                    <span *ngIf="index===0"> {{ wage.type }}</span>
                                </div>
                                <div class="col-2">
                                    <span *ngIf="index===0"> {{ wage.employersName }}</span>
                                </div>
                                <div class="col-1 text-nowrap">
                                    <span *ngIf="index===0"> {{ wage.employersFederalId  | mask: '00-00000000' }}</span>
                                </div>
                                <div class="col-2">
                                    {{ locality.taxWithheldLocalityName }}
                                </div>
                                <div class="col-1 text-right">
                                    <span *ngIf="index===0"> {{ getQualifyingWages(wage) |  number: numberFormat }}</span>
                                </div>
                                <div class="col-1 text-right">
                                      <span *ngIf="locality?.taxWithheldLocalityName !== config?.name  && +locality?.taxWithheldBox19>0">
                                        {{ locality?.taxWithheldBox19 |  number: numberFormat }}
                                      </span>
                                </div>
                                <div class="col-1 text-right">
                                    <span *ngIf="locality?.taxWithheldLocalityName === config?.name && +locality?.taxWithheldBox19>0">
                                      {{ locality?.taxWithheldBox19  |  number: numberFormat }}
                                    </span>
                                </div>
                                <div class="col-2 text-right">
                                   <span *ngIf="+locality.creditForTaxesPaid>0">
                                    {{ locality.creditForTaxesPaid  |  number: numberFormat }}
                                   </span>
                                </div>
                                <div *ngIf="index===0"
                                     class="col-1 d-flex justify-content-end align-items-center text-nowrap">
                                    <button (click)="openScheduleWages(wage,true, i)"
                                            class="btn btn-sm btn-primary p-1 mr-2" 
                                            type="button"
                                            [attr.aria-label]="'Taxpayer.open-schedule-wage' | translate"
                                            tabindex="0"
                                            role="button">
                                        <svg class="icon icon-sm" 
                                            viewBox="0 0 16 16"
                                            [attr.aria-hidden]="true" 
                                            focusable="false">
                                            <path
                                                    d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                        </svg>
                                    </button>
                                    <button (click)="removeTaxpayerScheduleOfWages(i)" 
                                            *ngIf="!(isDisabled$|async)"
                                            class="btn btn-sm btn-danger p-1 mr-2" 
                                            type="button"
                                            [attr.aria-label]="'Taxpayer.remove-schedule-wages' | translate"
                                            tabindex="0"
                                            role="button">
                                        <svg
                                                class="icon icon-sm"
                                                viewBox="0 0 24 24"
                                                [attr.aria-hidden]="true" 
                                                focusable="false"
                                        >
                                            <path
                                                    d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                                    stroke="none"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngFor="let wage of this.getSpouseScheduleOfWages.value; let i = index;">
                        <ng-container *ngFor="let locality of wage?.w2LocalityInfo; let index = index;">
                            <div class="row py-1">
                                <div class="col-1 min-w-60px">
                                    <span *ngIf="index===0"> {{ wage.type }}</span>
                                </div>
                                <div class="col-2">
                                    <span *ngIf="index===0"> {{ wage.employersName }}</span>
                                </div>
                                <div class="col-1 text-nowrap">
                                    <span *ngIf="index===0"> {{ wage.employersFederalId  | mask: '00-00000000' }}</span>
                                </div>
                                <div class="col-2">
                                    {{ locality.taxWithheldLocalityName }}
                                </div>
                                <div class="col-1 text-right">
                                    <span *ngIf="index===0"> {{ getQualifyingWages(wage)  |  number: numberFormat }}</span>
                                </div>
                                <div class="col-1 text-right">
                    <span *ngIf="locality?.taxWithheldLocalityName !== config?.name  && +locality?.taxWithheldBox19>0">
                      {{ locality?.taxWithheldBox19 |  number: numberFormat }}
                    </span>
                                </div>
                                <div class="col-1 text-right">
                    <span *ngIf="locality?.taxWithheldLocalityName === config?.name && +locality?.taxWithheldBox19>0">
                      {{ locality?.taxWithheldBox19  |  number: numberFormat }}
                    </span>
                                </div>
                                <div class="col-2 text-right">
                    <span *ngIf="+locality.creditForTaxesPaid>0">
                      {{ locality.creditForTaxesPaid  |  number: numberFormat }}
                    </span>
                                </div>
                                <div *ngIf="index===0"
                                     class="col-1 d-flex justify-content-end align-items-center text-nowrap">
                                    <button (click)="openScheduleWages(wage,true, i)"
                                            class="btn btn-sm btn-primary p-1 mr-2" 
                                            type="button"
                                            [attr.tabindex]="0" 
                                            role="button" 
                                            [attr.aria-label]="'Taxpayer.open-schedule-wage' | translate" >
                                        <svg class="icon icon-sm" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                                            <path
                                                    d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                        </svg>
                                    </button>
                                    <button (click)="removeSpouseScheduleOfWages(i)" 
                                            *ngIf="!(isDisabled$|async)"
                                            class="btn btn-sm btn-danger p-1 mr-2" 
                                            type="button"
                                            [attr.tabindex]="0" 
                                            role="button" 
                                            [attr.aria-label]="'Taxpayer.remove-schedule-wages' | translate" >
                                        <svg
                                                class="icon icon-sm"
                                                viewBox="0 0 24 24"
                                                aria-hidden="true" 
                                                focusable="false"
                                        >
                                            <path
                                                    d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                                    stroke="none"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <div class="row py-1 border-top">
                        <div class="col-6 min-w-60px text-uppercase font-weight-medium text-right">{{ 'Taxpayer.grand-total' | translate }}</div>
                        <div class="col-1 text-right">
                <span *ngIf="+individualReturn?.page2?.worksheetA?.grandTotalQualifyingWages > 0">
                  {{ individualReturn?.page2?.worksheetA?.grandTotalQualifyingWages  |  number: numberFormat }}
                </span>
                        </div>
                        <div class="col-1 text-right"></div>
                        <div class="col-1 text-right">
                 <span *ngIf="+individualReturn?.page2?.worksheetA?.grandTotalFillingCityTaxWithheld>0">
                {{ individualReturn?.page2?.worksheetA?.grandTotalFillingCityTaxWithheld  |  number: numberFormat }}
                 </span>
                        </div>
                        <div class="col-2 text-right">
                 <span *ngIf="+individualReturn?.page2?.worksheetA?.grandTotalTaxCreditAllowableOtherCities>0">
                {{ individualReturn?.page2?.worksheetA?.grandTotalTaxCreditAllowableOtherCities  |  number: numberFormat }}
                 </span>
                        </div>
                        <div class="col-1"></div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="(config$|async)?.components?.individual?.eFileType !== 'W2' &&  !form.get('page2.noTaxableIncome').value" class="row py-3">
            <div class="col-12">
                <div class="row bg-light border">
                    <div class="col-lg-11 col-10  py-2 font-weight-bold">{{ 'Taxpayer.business-or-income-loss' | translate }}</div>
                    <div class="col-lg-1  col-2 d-flex justify-content-end align-items-center">
                        <button (click)="openSchedule()" 
                                *ngIf="!(isDisabled$|async)"
                                class="btn btn-primary font-weight-600 ml-3 py-1"
                                [attr.aria-label]="'Taxpayer.open-schedule' | translate"
                                tabindex="0" 
                                role="button">{{ 'Taxpayer.add' | translate }}
                        </button>
                    </div>
                </div>
                <div *ngIf="getOtherIncomeTypeScheduleCDetail?.value?.length > 0" class="row border-bottom pt-2">
                    <div class="col-11 py-2 text-uppercase font-weight-bold">{{ 'Taxpayer.schedule' | translate }} C</div>
                    <div class="col-1 d-flex justify-content-end align-items-center">
                    </div>
                </div>
                <div *ngIf="getOtherIncomeTypeScheduleCDetail?.value?.length > 0" class="qualify-schedule">
                    <div class="row py-1">
                        <div class="col-2">
                            <h6 class="font-weight-medium "> {{ 'Taxpayer.company-name' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium ">{{ 'Taxpayer.fid' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium ">{{ 'Taxpayer.municipality' | translate }}</h6>
                        </div>
                        <div class="col-1">
                            <h6 class="font-weight-medium text-right">{{ 'Taxpayer.income' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium text-right">{{ 'Taxpayer.taxes-paid' | translate }}</h6>
                        </div>
                        <div class="col-2">
                        </div>
                        <div class="col-1">
                        </div>
                    </div>
                    <ng-container *ngFor="let detail of getOtherIncomeTypeScheduleCDetail?.value; let sIndex = index;">
                        <div class="row py-1">
                            <div class="col-2">
                                {{ detail.companyName }}
                            </div>
                            <div class="col-2 text-nowrap">
                                {{ detail.companyFederalId | mask: '00-00000000' }}
                            </div>
                            <div class="col-2">
                                {{ detail.municipality }}
                            </div>
                            <div class="col-1 text-right">
                                {{ detail.amount |  number: numberFormat }}
                            </div>
                            <div class="col-2 text-right">
                                {{ detail.taxesPaidAmount | number: numberFormat }}
                            </div>
                            <div class="col-2">
                            </div>
                            <div class="col-1 text-nowrap d-flex justify-content-end align-items-center">
                                <button (click)="openSchedule({type:'scheduleC', scheduleC: detail},true, sIndex)"
                                        class="btn btn-sm btn-primary p-1 mr-2" 
                                        type="button"
                                        tabindex="0"
                                        role="button"
                                        [attr.aria-label]="'Taxpayer.open-schedule' | translate">
                                    <svg class="icon icon-sm" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                                        <path
                                                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                    </svg>
                                </button>
                                <button (click)="removeSchedule(sIndex,'scheduleC')" 
                                        *ngIf="!(isDisabled$|async)"
                                        class="btn btn-sm btn-danger p-1 mr-2" 
                                        type="button"
                                        tabindex="0"
                                        role="button"
                                        [attr.aria-label]="'Taxpayer.remove-schedule' | translate">
                                    <svg
                                            class="icon icon-sm"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true" 
                                            focusable="false"
                                    >
                                        <path
                                                d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                                stroke="none"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row py-1 border-top" *ngIf="form.get('page2.worksheetB.otherIncomeTypeScheduleCDetail').length > 0">
                        <div class="col-6">
                            <div class="font-weight-medium text-right text-uppercase">{{ 'Taxpayer.total-income-loss' | translate }}</div>
                        </div>
                        <div class="col-1">
                            <div class="text-right">{{ individualReturn?.page2?.worksheetB?.totalIncomeLossScheduleC  |  number: numberFormat }}</div>
                        </div>
                        <div class="col-5">
                        </div>
                    </div>
                </div>
                <div *ngIf="getOtherIncomeTypeScheduleEDetail?.value?.length > 0" class="row border-bottom pt-2">
                    <div class="col-11 py-2 text-uppercase font-weight-bold">{{ 'Taxpayer.schedule' | translate }} E</div>
                    <div class="col-1 d-flex justify-content-end align-items-center">
                    </div>
                </div>
                <div *ngIf="getOtherIncomeTypeScheduleEDetail?.value?.length > 0" class="qualify-sec-misc">
                    <div class="row py-1">
                        <div class="col-4">
                            <h6 class="font-weight-medium ">{{ 'Taxpayer.property-address' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium ">{{ 'Taxpayer.municipality' | translate }}</h6>
                        </div>
                        <div class="col-1">
                            <h6 class="font-weight-medium text-right">{{ 'Taxpayer.income' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium text-right">{{ 'Taxpayer.taxes-paid' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium ">{{ 'Taxpayer.type' | translate }}</h6>
                        </div>
                        <div class="col-1">
                        </div>
                    </div>
                    <ng-container *ngFor="let detail of getOtherIncomeTypeScheduleEDetail?.value; let sIndex = index;">
                        <div class="row py-1">
                            <div class="col-4">
                                {{ detail.companyName }}
                            </div>
                            <div class="col-2">
                                {{ detail.municipality }}
                            </div>
                            <div class="col-1 text-right">
                                {{ detail.amount  |  number: numberFormat }}
                            </div>
                            <div class="col-2 text-right">
                                {{ detail.taxesPaidAmount  |  number: numberFormat }}
                            </div>
                            <div class="col-2">
                                {{ detail.miscIncomeType }}
                            </div>
                            <div class="col-1 text-nowrap d-flex justify-content-end align-items-center">
                                <button (click)="openSchedule({type:'scheduleE', scheduleE: detail},true, sIndex)"
                                        class="btn btn-sm btn-primary p-1 mr-2" 
                                        type="button"
                                        tabindex="0"
                                        role="button"
                                        [attr.aria-label]="'Taxpayer.open-schedule' | translate">
                                    <svg 
                                         class="icon icon-sm" 
                                         viewBox="0 0 16 16"
                                         aria-hidden="true" 
                                         focusable="false">
                                        <path
                                                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                    </svg>
                                </button>
                                <button (click)="removeSchedule(sIndex, 'scheduleE')" 
                                        *ngIf="!(isDisabled$|async)"
                                        class="btn btn-sm btn-danger p-1 mr-2" 
                                        type="button"
                                        tabindex="0"
                                        role="button"
                                        [attr.aria-label]="'Taxpayer.remove-schedule' | translate">
                                    <svg
                                            class="icon icon-sm"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true" 
                                            focusable="false">
                                        <path
                                                d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                                stroke="none"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row py-1 border-top" *ngIf="form.get('page2.worksheetB.otherIncomeTypeScheduleEDetail').length > 0">
                        <div class="col-6">
                            <div class="font-weight-medium text-uppercase text-right">{{ 'Taxpayer.total-income-loss' | translate }}</div>
                        </div>
                        <div class="col-1">
                            <div class="text-right">{{ individualReturn?.page2?.worksheetB?.totalIncomeLossScheduleE  |  number: numberFormat }}</div>
                        </div>
                        <div class="col-5"></div>
                    </div>
                </div>
                <div *ngIf="getOtherIncomeTypeScheduleK1Detail?.value?.length > 0" class="row  border-bottom pt-2">
                    <div class="col-11 py-2 text-uppercase font-weight-bold">{{ 'Taxpayer.schedule' | translate }} K1</div>
                    <div class="col-1 d-flex justify-content-end align-items-center">
                    </div>
                </div>
                <div *ngIf="getOtherIncomeTypeScheduleK1Detail?.value?.length > 0" class="qualify-sec-k1 ">
                    <div class="row py-1">
                        <div class="col-2">
                            <h6 class="font-weight-medium ">{{ 'Taxpayer.company-name' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium ">{{ 'Taxpayer.fid' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium ">{{ 'Taxpayer.municipality' | translate }}</h6>
                        </div>
                        <div class="col-1">
                            <h6 class="font-weight-medium text-right">{{ 'Taxpayer.income' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium text-right">{{ 'Taxpayer.taxes-paid' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium ">{{ 'Taxpayer.type' | translate }}</h6>
                        </div>
                        <div class="col-1">
                        </div>
                    </div>
                    <ng-container *ngFor="let detail of getOtherIncomeTypeScheduleK1Detail?.value; let sIndex = index;">
                        <div class="row py-1">
                            <div class="col-2">
                                {{ detail.companyName }}
                            </div>
                            <div class="col-2 text-nowrap">
                                {{ detail.companyFederalId | mask: '00-00000000' }}
                            </div>
                            <div class="col-2">
                                {{ detail.municipality }}
                            </div>
                            <div class="col-1 text-right">
                                {{ detail.amount  |  number: numberFormat }}
                            </div>
                            <div class="col-2 text-right">
                                {{ detail.taxesPaidAmount  |  number: numberFormat }}
                            </div>
                            <div class="col-2">
                                {{ detail.miscIncomeType }}
                            </div>
                            <div class="col-1 text-nowrap d-flex justify-content-end align-items-center">
                                <button (click)="openSchedule({type:'scheduleK1', scheduleK1: detail},true, sIndex)"
                                        class="btn btn-sm btn-primary p-1 mr-2"
                                        type="button"
                                        [attr.aria-label]="'Taxpayer.open-schedule' | translate"
                                        tabindex="0"
                                        role="button">
                                    <svg class="icon icon-sm" 
                                         viewBox="0 0 16 16"
                                         aria-hidden="true" 
                                         focusable="false">
                                        <path
                                                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                    </svg>
                                </button>
                                <button (click)="removeSchedule(sIndex,'scheduleK1')" 
                                        *ngIf="!(isDisabled$|async)"
                                        class="btn btn-sm btn-danger p-1 mr-2" 
                                        type="button"
                                        [attr.aria-label]="'Taxpayer.remove-schedule' | translate"
                                        tabindex="0"
                                        role="button">
                                    <svg
                                            class="icon icon-sm"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true"
                                            focusable="false"
                                    >
                                        <path
                                                d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                                stroke="none"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row py-1 border-top"  *ngIf="form.get('page2.worksheetB.otherIncomeTypeScheduleK1Detail').length > 0">
                        <div class="col-6">
                            <div class="font-weight-medium text-right text-uppercase">{{ 'Taxpayer.total-income-loss' | translate }}</div>
                        </div>
                        <div class="col-1">
                            <div class="text-right">{{ individualReturn?.page2?.worksheetB?.totalIncomeLossScheduleK1  |  number: numberFormat }}</div>
                        </div>
                        <div class="col-5">
                        </div>
                    </div>
                </div>
                <div *ngIf="getOtherIncomeTypeScheduleMiscDetail?.value?.length > 0" class="row border-bottom pt-2">
                    <div class="col-11 py-2 text-uppercase font-weight-bold">{{ 'Taxpayer.schedule-misc' | translate }}</div>
                    <div class="col-1 d-flex justify-content-end align-items-center">
                    </div>
                </div>
                <div *ngIf="getOtherIncomeTypeScheduleMiscDetail?.value?.length > 0" class="qualify-sec-misc ">
                    <div class="row py-1">
                        <div class="col-2 min-w-125px">
                            <h6 class="font-weight-medium ">{{ 'Taxpayer.company-name' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium ">{{ 'Taxpayer.fid' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium ">{{ 'Taxpayer.municipality' | translate }}</h6>
                        </div>
                        <div class="col-1">
                            <h6 class="font-weight-medium text-right">{{ 'Taxpayer.income' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium text-right">{{ 'Taxpayer.taxes-paid' | translate }}</h6>
                        </div>
                        <div class="col-2">
                            <h6 class="font-weight-medium ">{{ 'Taxpayer.type' | translate }}</h6>
                        </div>
                        <div class="col-1">
                        </div>
                    </div>
                    <ng-container
                            *ngFor="let detail of getOtherIncomeTypeScheduleMiscDetail?.value;let sIndex = index;">
                        <div class="row py-1">
                            <div class="col-2 min-w-125px">
                                {{ detail.companyName }}
                            </div>
                            <div class="col-2 text-nowrap">
                                {{ detail.companyFederalId | mask: '00-00000000' }}
                            </div>
                            <div class="col-2">
                                {{ detail.municipality }}
                            </div>
                            <div class="col-1 text-right">
                                {{ detail.amount  |  number: numberFormat }}
                            </div>
                            <div class="col-2 text-right">
                                {{ detail.taxesPaidAmount  |  number: numberFormat }}
                            </div>

                            <div class="col-2">
                                {{ detail.miscIncomeType }}
                            </div>
                            <div class="col-1 text-nowrap d-flex justify-content-end align-items-center">
                                <button (click)="openSchedule({type:'scheduleMisc', scheduleMisc: detail},true, sIndex)"
                                        class="btn btn-sm btn-primary p-1 mr-2" 
                                        type="button"
                                        [attr.aria-label]="'Taxpayer.open-schedule' | translate"
                                        tabindex="0"
                                        role="button">
                                    <svg class="icon icon-sm" 
                                         viewBox="0 0 16 16"
                                         aria-hidden="true" 
                                         focusable="false">
                                        <path
                                                d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                    </svg>
                                </button>
                                <button (click)="removeSchedule(sIndex,'scheduleMisc')" 
                                        *ngIf="!(isDisabled$|async)"
                                        class="btn btn-sm btn-danger p-1 mr-2" 
                                        type="button"
                                        [attr.aria-label]="'Taxpayer.remove-schedule' | translate"
                                        tabindex="0"
                                        role="button">
                                    <svg
                                            class="icon icon-sm"
                                            viewBox="0 0 24 24"
                                            aria-hidden="true" 
                                            focusable="false">
                                        <path
                                                d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                                stroke="none"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </ng-container>
                    <div class="row py-1 border-top" *ngIf="form.get('page2.worksheetB.otherIncomeTypeScheduleMiscDetail').length > 0">
                        <div class="col-6">
                            <div class="font-weight-medium text-right text-uppercase">{{ 'Taxpayer.total-income-loss' | translate }}</div>
                        </div>
                        <div class="col-1">
                            <div class="text-right">{{ individualReturn?.page2?.worksheetB?.totalIncomeLossMisc  |  number: numberFormat }}</div>
                        </div>
                        <div class="col-5">
                        </div>
                    </div>
                </div>
                <div *ngIf="getLossCarryForward?.value?.length > 0" class="row border-bottom pt-2">
                    <div class="col-11 py-2 text-uppercase font-weight-bold">{{ 'Taxpayer.loss-carry-forward' | translate }}</div>
                    <div class="col-1 d-flex justify-content-end align-items-center">
                    </div>
                </div>
                <div *ngIf="getLossCarryForward?.value?.length > 0" class="loss-carry-forward">
                    <div class="row py-1">
                        <div class="col-1">
                            <h6 class="font-weight-medium text-right">{{ 'Taxpayer.amount' | translate }}</h6>
                        </div>
                        <div class="col-4"></div>
                        <div class="col-1">
                            <h6 class="font-weight-medium text-center">{{ 'Taxpayer.tax-year' | translate }} 1</h6>
                        </div>
                        <div class="col-1">
                            <h6 class="font-weight-medium text-center">{{ 'Taxpayer.tax-year' | translate }} 2</h6>
                        </div>
                        <div class="col-1">
                            <h6 class="font-weight-medium text-center">{{ 'Taxpayer.tax-year' | translate }} 3</h6>
                        </div>
                        <div class="col-1">
                            <h6 class="font-weight-medium text-center">{{ 'Taxpayer.tax-year' | translate }} 4</h6>
                        </div>
                        <div class="col-1">
                            <h6 class="font-weight-medium text-center">{{ 'Taxpayer.tax-year' | translate }} 5</h6>
                        </div>
                        <div *ngIf="getLossCarryForward?.value.length > 0"
                             class="col-2 d-flex justify-content-end align-items-center">
                            <button (click)="openSchedule({type:'lossCarryForward', lossCarryForward: getLossCarryForward?.value},true,0)"
                                    class="btn btn-sm btn-primary p-1 mr-2"
                                    type="button"
                                    tabindex="0"
                                    role="button"
                                    [attr.aria-label]="'Taxpayer.open-schedule' | translate">
                                <svg class="icon icon-sm" viewBox="0 0 16 16" aria-hidden="true" focusable="false">
                                    <path
                                            d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                </svg>
                            </button>
                            <button (click)="removeSchedule(0,'lossCarryForward')" 
                                    *ngIf="!(isDisabled$|async)"
                                    class="btn btn-sm btn-danger p-1 mr-2" 
                                    type="button"
                                    tabindex="0"
                                    role="button"
                                    [attr.aria-label]="'Taxpayer.remove-schedule' | translate">
                                <svg
                                        class="icon icon-sm"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true" 
                                        focusable="false"
                                >
                                    <path
                                            d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                            stroke="none"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <ng-container *ngFor="let detail of getLossCarryForward?.value;let sIndex = index;">
                        <div class="row py-1">
                            <div class="col-1 text-right">
                                {{ detail.lossCarryForwardAmount  |  number: numberFormat }}
                            </div>
                            <div class="col-4">
                            </div>
                            <div class="col-1 text-center">
                                <ng-container *ngIf="!detail.taxYear1P else checkTemplate"></ng-container>
                            </div>
                            <div class="col-1 text-center">
                                <ng-container *ngIf="!detail.taxYear2P else checkTemplate "></ng-container>
                            </div>
                            <div class="col-1 text-center">
                                <ng-container *ngIf="!detail.taxYear3P else checkTemplate "></ng-container>
                            </div>
                            <div class="col-1 text-center">
                                <ng-container *ngIf="!detail.taxYear4P else checkTemplate"></ng-container>
                            </div>
                            <div class="col-1 text-center">
                                <ng-container *ngIf="!detail.taxYear5P else checkTemplate "></ng-container>
                            </div>
                            <div *ngIf="sIndex===0"
                                 class="col-2 text-nowrap d-flex justify-content-end align-items-center">
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="row py-3"  *ngIf="!this.form.get('page2.noTaxableIncome').value">
            <div class="col-12">
                <div class="row bg-light border">
                    <div class="col-11 py-2 font-weight-bold">{{ 'Taxpayer.adjustment-to-income' | translate }}</div>
                    <div class="col-1 d-flex justify-content-end align-items-center">
                        <button (click)="openAdjustmentToIncome()" 
                                *ngIf="!(isDisabled$|async)"
                                class="btn btn-primary font-weight-600 ml-3 py-1"
                                tabindex="0"
                                role="button"
                                [attr.aria-label]="'Taxpayer.add-adjustment-income' | translate">{{ 'Taxpayer.add' | translate }}
                        </button>
                    </div>
                </div>
                <div *ngIf="getAdjustmentToIncomeLine?.value?.length > 0" class="row py-1">
                    <div class="col-9"><h6 class="font-weight-medium ">{{ 'Taxpayer.explanation' | translate }}</h6></div>
                    <div class="col-2 text-right"><h6 class="font-weight-medium ">{{ 'Taxpayer.adjustment-amount' | translate }}</h6></div>
                    <div class="col-1">
                    </div>
                </div>
                <div *ngFor="let adjustment of  getAdjustmentToIncomeLine?.value" class="row py-1">
                    <div class="col-9">{{ adjustment.explanation }}</div>
                    <div class="col-2 text-right">{{ adjustment.column1Adjustment  |  number: numberFormat }}</div>
                    <div *ngIf="!adjustmentTypes.includes(adjustment.explanation)"
                         class="col-1 text-nowrap d-flex justify-content-end align-items-center">
                        <button (click)="openAdjustmentToIncome(adjustment,true)"
                                class="btn btn-sm btn-primary p-1 mr-2" 
                                type="button"
                                [attr.aria-label]="'Taxpayer.adjust-income' | translate"
                                [attr.tabindex]="0">
                            <svg class="icon icon-sm" viewBox="0 0 16 16" aria-hidden="true">
                                <path
                                        d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                            </svg>
                        </button>
                        <button (click)="removeAdjustmentToIncome(adjustment)" 
                                *ngIf="!(isDisabled$|async)"
                                class="btn btn-sm btn-danger p-1 mr-2" 
                                type="button"
                                [attr.aria-label]="'Taxpayer.remove-adjustment' | translate"
                                [attr.tabindex]="0">
                            <svg
                                    class="icon icon-sm"
                                    viewBox="0 0 24 24"
                                    aria-hidden="true"
                            >
                                <path
                                        d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                        stroke="none"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
                <div *ngIf="getAdjustmentToIncomeLine?.value?.length > 0" class="row border-top">
                    <div class="col-9">
                        <div class="font-weight-medium text-right text-uppercase">{{ 'Taxpayer.total-adjustments' | translate }}</div>
                    </div>
                    <div class="col-2">
                        <div class="text-right">{{ individualReturn?.page2?.worksheetC?.totalColumn1Adjustment | number: numberFormat }}</div>
                    </div>
                    <div class="col-1">
                    </div>
                </div>
            </div>
        </div>

        <div class="row border-top py-3">
            <div class="col-12 d-flex justify-content-end">
                <button (click)="back()" 
                   class="btn btn-secondary font-weight-600 mr-2" 
                   type="button"
                   tabindex="0" 
                   role="button" 
                   [attr.aria-label]="'Buttons.back-btn' | translate">
                <span>{{
                "Buttons.back-btn" | translate
                }}</span>
                </button>
                <button (click)="next()" 
                    [disabled]="isLoading$ | async" 
                    class="btn btn-primary font-weight-600"
                    id="submit"
                    tabindex="0" 
                    role="button" 
                    [attr.aria-label]="'Buttons.next-btn' | translate" 
                    [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'" 
                    [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'">
                    <ui-spinner [class.active]="isLoading$ | async">
                <span>{{ "Buttons.next-btn" | translate }}</span>
                    </ui-spinner>
                </button>
            </div>
        </div>

    </form>
</div>

<ng-template #noPermission>
    <ui-forbidden></ui-forbidden>
</ng-template>

<ng-template #checkTemplate>
    <svg
            class="icon icon-fx vcenter fill-success-500 center"
            height="24"
            viewBox="0 0 24 24"
            width="24"
    >
        <path
                d="M9,20.42L2.79,14.21L5.62,11.38L9,14.77L18.88,4.88L21.71,7.71L9,20.42Z"
                stroke="none"
        />
    </svg>
</ng-template>

<ng-template #empty></ng-template>
