import {Component, Injector, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {IndividualService} from "../../../services/individual.service";
import {HexaDetailComponent, HexaTitleService} from "@hexalang/ui/core";
import {AuthService} from '@city-tax/features/auth';
import {LoadingBarService} from "@ngx-loading-bar/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "city-tax-feature-individual-filing-tax-year",
  templateUrl: "./individual-filing-tax-year.component.html",
  styleUrls: ["./individual-filing-tax-year.component.scss"],
})
export class IndividualFilingTaxYearComponent
  extends HexaDetailComponent
  implements OnInit {
  public taxYear;
  public title$ = this.titleService.title$;
  public history = window.history;

  constructor(
    injector: Injector,
    private route: ActivatedRoute,
    private router: Router,
    public titleService: HexaTitleService,
    private individualService: IndividualService,
    private translate: TranslateService,
    private authService: AuthService,
    private loadingBar: LoadingBarService
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.loadingBar.start();
    this.route.params.subscribe((paramMap) => {
      this.individualService.clearFiling(this.authService.organization.id, paramMap["taxYear"]);
      this.titleService.changeTitle(
        this.translate.instant("Taxpayer.taxpayer-filing", { taxYear: this.individualService.taxYear })
      );
      this.translate.onLangChange.subscribe(() => {
        this.titleService.changeTitle(
          this.translate.instant("Taxpayer.taxpayer-filing", { taxYear: this.individualService.taxYear })
        );
      });
      this.getReturn();
      this.loadingBar.complete();
    });
  }

  async getReturn() {
    try {
      const currentYear = new Date().getFullYear();
      const taxYear = currentYear - 1;
      const ir = await this.individualService.getReturn(this.authService.organization.id, this.authService.config?.state);
      if (ir.status === "Completed" && ir.taxYear === taxYear) {
        let path = `/individual/filings/${this.authService.config?.state.toLowerCase()}/${this.individualService.taxYear}/confirmation`;
        await this.router.navigate([path]);
      }
    } catch (error) {
    }
  }
}
