import {PaymentHelper} from "@city-tax/shared";
import {WagesHelper} from "./wages.helper";

export class MiIndividualReturnHelper {
  private readonly ir: any;
  private readonly filingCity: any;
  private readonly wages: any;

  constructor(private paymentHelper, filingCity, ir) {
    this.paymentHelper = new PaymentHelper();
    this.ir = ir;
    this.filingCity = filingCity;
    this.wages = new WagesHelper(this.paymentHelper, ir.wagesSalariesTips);
  }

  get isPartYear() {
    return this.ir.residency === "P";
  }

  get nonResident() {
    return this.ir.residency === "N";
  }

  //line 19 /deductions 7/ totalDeductions
  get totalDeductions() {
    let total = 0;
    total += this.totalIraDeduction || 0;
    total += this.totalSelfEmployedSep || 0;
    total += this.totalEmployeeBusinessExpense || 0;
    total += this.totalRenaissanceZoneDeduction || 0;
    total += this.totalAlimonyPaidWithoutChildSupport || 0;
    total += this.totalMovingExpensesIntoCityAreaOnly || 0;
    return total;
  }

  //deductions 1. totalIraDeduction
  get totalIraDeduction() {
    return this.ir.totalIraDeduction
      ? this.paymentHelper.decimalValue(this.ir?.totalIraDeduction)
      : null;
  }

  //deductions 2. totalSelfEmployedSep
  get totalSelfEmployedSep() {
    return this.ir.totalSelfEmployedSep
      ? this.paymentHelper.decimalValue(this.ir?.totalSelfEmployedSep)
      : null;
  }

  //deductions 3. totalEmployeeBusinessExpense
  get totalEmployeeBusinessExpense() {
    return this.ir.totalEmployeeBusinessExpense
      ? this.paymentHelper.decimalValue(this.ir?.totalEmployeeBusinessExpense)
      : null;
  }

  //deductions 4. totalRenaissanceZoneDeduction
  get totalRenaissanceZoneDeduction() {
    return this.ir.totalRenaissanceZoneDeduction
      ? this.paymentHelper.decimalValue(this.ir?.totalRenaissanceZoneDeduction)
      : null;
  }

  //deductions 5. totalAlimonyPaidWithoutChildSupport
  get totalAlimonyPaidWithoutChildSupport() {
    return this.ir.totalAlimonyPaidWithoutChildSupport ? this.paymentHelper.decimalValue(this.ir?.totalAlimonyPaidWithoutChildSupport) : null;
  }

  //deductions 6. totalMovingExpensesIntoCityAreaOnly
  get totalMovingExpensesIntoCityAreaOnly() {
    return this.ir.totalMovingExpensesIntoCityAreaOnly ? this.paymentHelper.decimalValue(this.ir?.totalMovingExpensesIntoCityAreaOnly) : null;
  }

  get grandTotalScheduleExcludibleWages() {
    return this.wages.grandTotalScheduleExcludibleWages;
  }

  get grandTotalScheduleTaxableWages() {
    return this.wages.grandTotalScheduleTaxableWages;
  }

  get grandTotalScheduleWages() {
    return this.wages.grandTotalScheduleWages;
  }

  get wagesSalariesTips() {
    return this.wages.getWagesSalariesTips();
  }

  get subTotalIncome() {
    let total = 0;
    total += this.paymentHelper.decimalValue(this.wagesSalariesTips?.grandTotalScheduleWages, 0);
    total += this.paymentHelper.decimalValue(this.ir?.taxableInterest?.interestFromFederal, 0);
    total += this.paymentHelper.decimalValue(this.ir?.ordinaryDividendsFederal?.dividendIncomeFromFederalReturn, 0);
    return total;
  }

  get totalAdditionsA() {
    const list = [
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.taxableInterestA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.ordinaryDividendsA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.taxableRefundsA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.alimonyReceivedA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.businessIncomeA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.capitalGainA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.otherGainLossA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.taxableIraDistributionA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.taxablePensionA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.rentalRealA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.subchapterSA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.farmIncomeA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.unemploymentA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.ssBenefitsA, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.otherIncomeA, 0),]
    return this.paymentHelper.sum(list);
  }

  get totalAdditionsB() {
    const list = [
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.taxableInterestB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.ordinaryDividendsB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.taxableRefundsB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.alimonyReceivedB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.businessIncomeB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.capitalGainB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.otherGainLossB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.taxableIraDistributionB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.taxablePensionB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.rentalRealB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.subchapterSB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.farmIncomeB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.unemploymentB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.ssBenefitsB, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.otherIncomeB, 0),
    ];
    return this.paymentHelper.sum(list);
  }

  get totalAdditionsC() {
    const list = [
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.taxableInterestC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.ordinaryDividendsC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.alimonyReceivedC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.businessIncomeC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.capitalGainC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.otherGainLossC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.taxableIraDistributionC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.taxablePensionC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.rentalRealC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.subchapterSC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.farmIncomeC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.otherIncomeC, 0),
    ];
    return this.paymentHelper.sum(list);
  }

  get totalAdditionsD() {
    const list = [
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.alimonyReceivedD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.businessIncomeD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.capitalGainD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.otherGainLossD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.taxableIraDistributionD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.taxablePensionD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.rentalRealD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.subchapterSD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.farmIncomeD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.otherIncomeD, 0),
    ];
    return this.paymentHelper.sum(list);
  }

  get totalIncomeA() {
    return this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.totalWagesA) + this.totalAdditionsA;
  }

  get totalIncomeB() {
    return this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.totalWagesB) + this.totalAdditionsB;
  }

  get totalIncomeC() {
    return this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.totalWagesC) + this.totalAdditionsC;
  }

  get totalIncomeD() {
    return this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.totalWagesD) + this.totalAdditionsD;
  }

  get totalDeductC() {
    const list = [
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.iraDeductionC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.selfEmpC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.empBizC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.movingExpC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.alimonyPaidC, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.renaissanceZoneDeductC, 0),
    ];
    return this.paymentHelper.sum(list);
  }

  get totalDeductD() {
    const list = [
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.iraDeductionD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.selfEmpD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.empBizD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.movingExpD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.alimonyPaidD, 0),
      this.paymentHelper.decimalValue(this.ir.irs1040ScheduleTC?.renaissanceZoneDeductD, 0),
    ];
    return this.paymentHelper.sum(list);
  }

  get totalIncomeAfterDeductionC() {
    return this.paymentHelper.decimalValue(this.totalIncomeC) - this.totalDeductC;
  }

  get totalIncomeAfterDeductionD() {
    return this.paymentHelper.decimalValue(this.totalIncomeD) - this.totalDeductD;
  }

  isOnePositiveOneNegative(num1, num2) {
    return (num1 > 0 && num2 < 0) || (num1 < 0 && num2 > 0);
  }

  get totalLossesTransferredC() {
    if (this.isOnePositiveOneNegative(this.totalIncomeAfterDeductionC, this.totalIncomeAfterDeductionD)) {
      return this.totalIncomeAfterDeductionC < 0 ? Math.abs(this.totalIncomeAfterDeductionC) : this.paymentHelper.decimalValue(this.totalIncomeAfterDeductionC / 2, 2);
    } else {
      return 0;
    }
  }

  get totalLossesTransferredD() {
    if (this.isOnePositiveOneNegative(this.totalIncomeAfterDeductionC, this.totalIncomeAfterDeductionD)) {
      return this.totalIncomeAfterDeductionC < 0 ? this.totalIncomeAfterDeductionC : this.totalIncomeAfterDeductionD;
    } else {
      return 0;
    }
  }

  get totalIncomeAfterC() {
    return this.totalIncomeAfterDeductionC + this.totalLossesTransferredC;
  }

  get totalIncomeAfterD() {
    return this.totalIncomeAfterDeductionD + this.totalLossesTransferredD;
  }

  get amountExemptionsD() {
    //if 21b (amountForExemptions) > 20c1 (totalIncomeAfterC) then 20b1(totalLossesTransferredC) - 20c1(totalIncomeAfterC) else 0
    if (this.amountForExemptions > this.totalIncomeAfterC) {
      return this.totalLossesTransferredC - this.totalIncomeAfterC;
    }
    return 0;
  }

  //17a
  get totalAdditionsFederal() {
    let total = 0;
    total += this.paymentHelper.decimalValue(this.ir.taxableInterest?.interestFromFederal, 0);
    total += this.paymentHelper.decimalValue(this.ir.ordinaryDividendsFederal?.dividendIncomeFromFederalReturn, 0);
    total += this.paymentHelper.decimalValue(this.ir.taxableRefundsCreditsFederal, 0);
    total += this.paymentHelper.decimalValue(this.ir.alimonyReceivedFederal, 0);
    total += this.paymentHelper.decimalValue(this.ir.businessIncomeFederal, 0);
    total += this.paymentHelper.decimalValue(this.ir.capitalGainsOrLosses?.totalCapitalGainsAndLossesFromFederalReturn, 0);
    total += this.paymentHelper.decimalValue(this.ir.otherGainsLoses?.totalOtherGainsAndLosses, 0);

    total += this.paymentHelper.decimalValue(this.ir.gamblingIncome?.gamblingIncomeFederal, 0);
    total += this.paymentHelper.decimalValue(this.ir.partnership?.partnershipIncomeFederal, 0);

    total += this.paymentHelper.decimalValue(this.ir.taxableIraDistributions?.taxableIraDistributionsFederal, 0);
    total += this.paymentHelper.decimalValue(this.ir.taxablePensionDistributions?.taxablePensionDistributionsFederal, 0);
    total += this.paymentHelper.decimalValue(this.ir.rentalRealEstate?.rentalRealEstateFederal, 0);
    total += this.paymentHelper.decimalValue(this.ir.farmIncomeLoss?.totalFarmIncomeNetProfit, 0);

    total += this.paymentHelper.decimalValue(this.ir.unemploymentCompensationFederal, 0);
    total += this.paymentHelper.decimalValue(this.ir.socialSecurityFederal, 0);
    total += this.paymentHelper.decimalValue(this.ir.otherIncome?.otherIncomeFederal, 0);

    return total;
  }

  //17b
  get totalAdditionsAdjustments() {
    let total = 0;
    total += this.paymentHelper.decimalValue(this.ir.taxableInterest?.interestTotalExcludibleIncome, 0);
    total += this.paymentHelper.decimalValue(this.ir.ordinaryDividendsFederal?.dividendTotalExcludible, 0);
    total += this.paymentHelper.decimalValue(this.ir.taxableRefundsCreditsAdjustment, 0);
    total += this.paymentHelper.decimalValue(this.ir.alimonyReceivedAdjustments, 0);
    total += this.paymentHelper.decimalValue(this.ir.businessIncomeAdjustments, 0);
    total += this.paymentHelper.decimalValue(this.ir.capitalGainsOrLosses?.totalExcludibleCapitalGains, 0);
    total += this.paymentHelper.decimalValue(this.ir.otherGainsLoses?.otherTotalExcludibleGainsAndLosses, 0);

    total += this.paymentHelper.decimalValue(this.ir.gamblingIncome?.gamblingIncomeAdjustments, 0);
    total += this.paymentHelper.decimalValue(this.ir.partnership?.partnershipIncomeAdjustments, 0);

    total += this.paymentHelper.decimalValue(this.ir.taxableIraDistributions?.totalExcludibleIraDistributions, 0);
    total += this.paymentHelper.decimalValue(this.ir.taxablePensionDistributions?.totalExcludiblePension, 0);
    total += this.paymentHelper.decimalValue(this.ir.rentalRealEstate?.totalAdjustments, 0);
    total += this.paymentHelper.decimalValue(this.ir.sCorporationDistributions?.subchapterSCorporationDistributionsExclusions, 0);
    total += this.paymentHelper.decimalValue(this.ir.farmIncomeLoss?.excludibleNetProfit, 0);

    total += this.paymentHelper.decimalValue(this.ir.unemploymentCompensationAdjustments, 0);
    total += this.paymentHelper.decimalValue(this.ir.socialSecurityAdjustments, 0);
    total += this.paymentHelper.decimalValue(this.ir.otherIncome?.otherIncomeExclusions, 0);

    return total;
  }

  //17c
  get totalAdditionsTax() {
    let total = 0;

    total += this.paymentHelper.decimalValue(this.ir.taxableInterest?.taxableInterestIncome, 0);
    total += this.paymentHelper.decimalValue(this.ir.ordinaryDividendsFederal?.dividendTaxableIncome, 0);
    total += this.paymentHelper.decimalValue(this.ir.alimonyReceivedTax, 0);
    total += this.paymentHelper.decimalValue(this.ir.businessIncomeTax, 0);
    total += this.paymentHelper.decimalValue(this.ir.capitalGainsOrLosses?.taxableCapitalGainsAndLosses, 0);
    total += this.paymentHelper.decimalValue(this.ir.otherGainsLoses?.otherTaxableGainsAndLosses, 0);

    total += this.paymentHelper.decimalValue(this.ir.gamblingIncome?.gamblingIncomeTax, 0);
    total += this.paymentHelper.decimalValue(this.ir.partnership?.partnershipIncomeTax, 0);

    total += this.paymentHelper.decimalValue(this.ir.taxableIraDistributions?.taxableIraDistributionsIncome, 0);
    total += this.paymentHelper.decimalValue(this.ir.taxablePensionDistributions?.taxablePensionDistributionsIncome, 0);

    total += this.paymentHelper.decimalValue(this.ir.rentalRealEstate?.rentalRealEstateIncome, 0);
    total += this.paymentHelper.decimalValue(this.ir.sCorporationDistributions?.subchapterSCorporationDistributionsIncome, 0);
    total += this.paymentHelper.decimalValue(this.ir.farmIncomeLoss?.totalFarmIncomeLoss, 0);
    total += this.paymentHelper.decimalValue(this.ir.unemploymentCompensationAdjustments, 0);
    total += this.paymentHelper.decimalValue(this.ir.socialSecurityAdjustments, 0);
    total += this.paymentHelper.decimalValue(this.ir.otherIncome?.otherIncomeSubject, 0);

    return total;
  }

  get grandTotalScheduleTaxWithheld() {
    return this.wages.grandTotalScheduleTaxWithheld;
  }

  //18a
  get totalIncomeFederal() {
    let total = this.totalAdditionsFederal;
    total += this.paymentHelper.decimalValue(this.ir.wagesSalariesTips?.grandTotalScheduleWages, 0);
    return total;
  }

  //18b
  get totalIncomeAdjustments() {
    let total = this.totalAdditionsAdjustments;
    total += this.paymentHelper.decimalValue(this.ir.wagesSalariesTips?.grandTotalScheduleExcludibleWages, 0);
    return total;
  }

  //18c
  get totalIncomeTax() {
    let total = this.totalAdditionsTax;
    total += this.paymentHelper.decimalValue(this.ir.wagesSalariesTips?.grandTotalScheduleTaxableWages, 0);
    return total;
  }

  //20 = 18c - 19
  get totalIncomeAfterDeductions() {
    return this.totalIncomeTax - this.totalDeductions;
  }

  get amountForExemptions() {
    const exemptions = +this.ir.numberOfExemptions || +this.ir.irs1040CfPage2?.boxC || 0;
    const exemptionAmount = +this.filingCity?.components?.individual?.exemptionAmount || 0;
    const amountForExemptions = exemptions * exemptionAmount;
    return this.paymentHelper.decimalValue(amountForExemptions);
  }

  //22 = 20-21
  get totalIncomeSubjectToTax() {
    const totalIncomeSubjectToTaxCal = this.totalIncomeAfterDeductions - this.amountForExemptions;

    if (totalIncomeSubjectToTaxCal < 0) return 0;

    return totalIncomeSubjectToTaxCal;
  }

  // 24
  get totalPaymentsCredits() {
    let total = 0;
    total += this.paymentHelper.decimalValue(this.ir.taxWithheldByEmployer, 0);
    total += this.paymentHelper.decimalValue(this.ir.paymentsOnPriorYearDeclarationTax, 0);
    total += this.paymentHelper.decimalValue(this.ir.creditForTaxPaidAnotherCity?.grandTotalCredit, 0);
    return total;
  }

  // 25
  get penaltyOnUnderpaidEstimatesOrLateFiledReturns() {
    let total = 0;
    total += this.paymentHelper.decimalValue(this.ir.totalInterestAndPenaltyOnUnderpaidEstimatesOrLateFilesReturns, 0);
    total += this.paymentHelper.decimalValue(this.ir.interestOnUnderpaidEstimatesOrLateFiledReturns, 0);
    return total;
  }

  // 28
  get totalDonations() {
    let total = 0;
    total += this.paymentHelper.decimalValue(this.ir.donation35A, 0);
    total += this.paymentHelper.decimalValue(this.ir.donation35B, 0);
    total += this.paymentHelper.decimalValue(this.ir.donation35C, 0);
    return total;
  }

  //23c
  get totalTax() {
    let total = 0;
    const fillingCityTaxRateDecimal = this.ir.fillingCityTaxRateDecimal;
    if (this.totalIncomeSubjectToTax < 0) {
      return 0;
    }

    if (this.isPartYear) {
      total += this.paymentHelper.decimalValue(this.taxResRateAC, 0);
      total += this.paymentHelper.decimalValue(this.taxNonResRateAD, 0);
    } else if (this.nonResident) {
      return this.paymentHelper.decimalValue(this.totalIncomeSubjectToTax * (fillingCityTaxRateDecimal / 2));
    } else {
      return this.paymentHelper.decimalValue(this.totalIncomeSubjectToTax * fillingCityTaxRateDecimal);
    }
    return this.paymentHelper.decimalValue(total, 0);
  }

  get resTotalIncomeSubTaxBC() {
    //20a (totalIncomeAfterDeductionC) - 21b
    const value = this.totalIncomeAfterDeductionC - this.amountForExemptions;
    return value < 0 ? 0 : value;
    // return this.paymentHelper.decimalValue(this.ir?.irs1040ScheduleTC?.resTotalIncomeSubTaxBC, 0);
  }

  get taxResRateAC() {
    const fillingCityTaxRateDecimal = this.ir.fillingCityTaxRateDecimal;
    return this.paymentHelper.decimalValue(this.resTotalIncomeSubTaxBC * fillingCityTaxRateDecimal);
  }

  get nonTotalIncomeSubTaxAD() {
    //20c2 (totalIncomeAfterD) - 21c (amountExemptionsD)
    const value = this.totalIncomeAfterD - this.amountExemptionsD;
    return value < 0 ? 0 : value;
  }

  get taxNonResRateAD() {
    const fillingCityTaxRateDecimal = this.ir.fillingCityTaxRateDecimal;
    //const nonTotalIncomeSubTaxAD = this.paymentHelper.decimalValue(this.ir?.irs1040ScheduleTC?.nonTotalIncomeSubTaxAD, 0);
    return this.paymentHelper.decimalValue(this.nonTotalIncomeSubTaxAD * (fillingCityTaxRateDecimal / 2));
  }

  get calTotalTaxDue() {
    let total = 0;
    total += this.totalTax;
    total += this.penaltyOnUnderpaidEstimatesOrLateFiledReturns;
    total -= this.totalPaymentsCredits;
    return total;
  }

  get totalOverPayment() {
    let total = this.calTotalTaxDue;
    return total < 0 ? this.paymentHelper.decimalValue(Math.abs(total), 0) : 0;
  }

  get totalTaxDue() {
    let total = 0;
    total += this.totalTax;
    total += this.penaltyOnUnderpaidEstimatesOrLateFiledReturns;
    total -= this.totalPaymentsCredits;
    return total < 0 ? 0 : this.paymentHelper.decimalValue(total, 0);
  }

  row1() {
    this.ir.wagesSalariesTips = this.ir.wagesSalariesTips || {};
    this.ir.wagesSalariesTips.grandTotalScheduleTaxableWages = this.paymentHelper.decimalValue(this.ir.wagesSalariesTips.grandTotalScheduleWages) - this.paymentHelper.decimalValue(this.ir.wagesSalariesTips.grandTotalScheduleExcludibleWages);
    return this.ir.wagesSalariesTips.grandTotalScheduleTaxableWages === 0 ? null : this.ir.wagesSalariesTips.grandTotalScheduleTaxableWages
  }

  row2() {
    this.ir.taxableInterest = this.ir.taxableInterest || {};
    this.ir.taxableInterest.taxableInterestIncome = this.paymentHelper.decimalValue(this.ir.taxableInterest.interestFromFederal) - this.paymentHelper.decimalValue(this.ir.taxableInterest.interestTotalExcludibleIncome);
    return this.ir.taxableInterest.taxableInterestIncome === 0 ? null : this.ir.taxableInterest.taxableInterestIncome;
  }

  row3() {
    this.ir.ordinaryDividendsFederal = this.ir.ordinaryDividendsFederal || {};
    this.ir.ordinaryDividendsFederal.dividendTaxableIncome = this.paymentHelper.decimalValue(this.ir.ordinaryDividendsFederal.dividendIncomeFromFederalReturn) - this.paymentHelper.decimalValue(this.ir.ordinaryDividendsFederal.dividendTotalExcludible);
    return this.ir.ordinaryDividendsFederal.dividendTaxableIncome === 0 ? null : this.ir.ordinaryDividendsFederal.dividendTaxableIncome;
  }

  row4() {
    //skip
  }

  row5() {
    this.ir.alimonyReceivedTax = this.paymentHelper.decimalValue(this.ir.alimonyReceivedFederal) - this.paymentHelper.decimalValue(this.ir.alimonyReceivedAdjustments);
    return this.ir.alimonyReceivedTax === 0 ? null : this.ir.alimonyReceivedTax;
  }

  row6() {
    this.ir.businessIncomeTax = this.paymentHelper.decimalValue(this.ir.businessIncomeFederal) - this.paymentHelper.decimalValue(this.ir.businessIncomeAdjustments);
    return this.ir.businessIncomeTax === 0 ? null : this.ir.businessIncomeTax;
  }

  row7() {
    this.ir.capitalGainsOrLosses = this.ir.capitalGainsOrLosses || {};
    this.ir.capitalGainsOrLosses.taxableCapitalGainsAndLosses = this.paymentHelper.decimalValue(this.ir.capitalGainsOrLosses.totalCapitalGainsAndLossesFromFederalReturn) - this.paymentHelper.decimalValue(this.ir.capitalGainsOrLosses.totalExcludibleCapitalGains);
    return this.ir.capitalGainsOrLosses.taxableCapitalGainsAndLosses === 0 ? null : this.ir.capitalGainsOrLosses.taxableCapitalGainsAndLosses;
  }

  row8() {
    this.ir.otherGainsLoses = this.ir.otherGainsLoses || {};
    this.ir.otherGainsLoses.otherTaxableGainsAndLosses = this.paymentHelper.decimalValue(this.ir.otherGainsLoses.totalOtherGainsAndLosses) - this.paymentHelper.decimalValue(this.ir.otherGainsLoses.otherTotalExcludibleGainsAndLosses);
    return this.ir.otherGainsLoses.otherTaxableGainsAndLosses === 0 ? null : this.ir.otherGainsLoses.otherTaxableGainsAndLosses;
  }

  row9() {
    this.ir.taxableIraDistributions = this.ir.taxableIraDistributions || {};
    this.ir.taxableIraDistributions.taxableIraDistributionsIncome = this.paymentHelper.decimalValue(this.ir.taxableIraDistributions.taxableIraDistributionsFederal) - this.paymentHelper.decimalValue(this.ir.taxableIraDistributions.totalExcludibleIraDistributions);
    return this.ir.taxableIraDistributions.taxableIraDistributionsIncome === 0 ? null : this.ir.taxableIraDistributions.taxableIraDistributionsIncome;
  }

  row10() {
    this.ir.taxablePensionDistributions = this.ir.taxablePensionDistributions || {};
    this.ir.taxablePensionDistributions.taxablePensionDistributionsIncome = this.paymentHelper.decimalValue(this.ir.taxablePensionDistributions.taxablePensionDistributionsFederal) - this.paymentHelper.decimalValue(this.ir.taxablePensionDistributions.totalExcludiblePension);
    return this.ir.taxablePensionDistributions.taxablePensionDistributionsIncome === 0 ? null : this.ir.taxablePensionDistributions.taxablePensionDistributionsIncome;
  }

  row11() {
    this.ir.rentalRealEstate = this.ir.rentalRealEstate || {};
    this.ir.rentalRealEstate.rentalRealEstateIncome = this.paymentHelper.decimalValue(this.ir.rentalRealEstate.rentalRealEstateFederal) - this.paymentHelper.decimalValue(this.ir.rentalRealEstate.totalAdjustments);
    return this.ir.rentalRealEstate.rentalRealEstateIncome === 0 ? null : this.ir.rentalRealEstate.rentalRealEstateIncome;
  }

  row12() {
    this.ir.sCorporationDistributions = this.ir.sCorporationDistributions || {};
    this.ir.sCorporationDistributions.subchapterSCorporationDistributionsIncome = this.paymentHelper.decimalValue(this.ir.sCorporationDistributions.subchapterSCorporationDistributionsExclusions);
    return this.ir.sCorporationDistributions.subchapterSCorporationDistributionsIncome === 0 ? null : this.ir.sCorporationDistributions.subchapterSCorporationDistributionsIncome;
  }

  row13() {
    this.ir.farmIncomeLoss = this.ir.farmIncomeLoss || {};
    this.ir.farmIncomeLoss.totalFarmIncomeLoss = this.paymentHelper.decimalValue(this.ir.farmIncomeLoss.totalFarmIncomeNetProfit) - this.paymentHelper.decimalValue(this.ir.farmIncomeLoss.excludibleNetProfit);
    return this.ir.farmIncomeLoss.totalFarmIncomeLoss === 0 ? null : this.ir.farmIncomeLoss.totalFarmIncomeLoss;
  }

  row14() {
    this.ir.gamblingIncome = this.ir.gamblingIncome || {};
    this.ir.gamblingIncome.gamblingIncomeTax = this.paymentHelper.decimalValue(this.ir.gamblingIncome.gamblingIncomeFederal) - this.paymentHelper.decimalValue(this.ir.gamblingIncome.gamblingIncomeAdjustments);
    return this.ir.farmIncomeLoss.gamblingIncomeTax === 0 ? null : this.ir.farmIncomeLoss.gamblingIncomeTax;
  }

  row15() {
    this.ir.partnership = this.ir.partnership || {};
    this.ir.partnership.partnershipIncomeTax = this.paymentHelper.decimalValue(this.ir.partnership.partnershipIncomeFederal) - this.paymentHelper.decimalValue(this.ir.partnership.partnershipIncomeAdjustments);
    return this.ir.partnership.partnershipIncomeTax === 0 ? null : this.ir.partnership.partnershipIncomeTax;
  }

  row16() {
    this.ir.otherIncome = this.ir.otherIncome || {};
    this.ir.otherIncome.otherIncomeSubject = this.paymentHelper.decimalValue(this.ir.otherIncome.otherIncomeFederal) - this.paymentHelper.decimalValue(this.ir.otherIncome.otherIncomeExclusions);
    return this.ir.otherIncome.otherIncomeSubject === 0 ? null : this.ir.otherIncome.otherIncomeSubject;
  }

  row17() {
    this.ir.totalAdditionsFederal = this.totalAdditionsFederal;
    this.ir.totalAdditionsAdjustments = this.totalAdditionsAdjustments;
    this.ir.totalAdditionsTax = this.totalAdditionsTax;
  }

  row18() {
    this.ir.totalIncomeFederal = this.totalIncomeFederal;
    this.ir.totalIncomeAdjustments = this.totalIncomeAdjustments;
    this.ir.totalIncomeTax = this.totalIncomeTax;
  }


  get irForm() {
    this.row1();
    this.row2();
    this.row3();
    this.row4();
    this.row5();
    this.row6();
    this.row7();
    this.row8();
    this.row9();
    this.row10();
    this.row11();
    this.row12();
    this.row13();
    this.row14();
    this.row15();
    this.row16();
    this.row17();
    this.row18();

    const ir = this.ir;

    ir.wagesSalariesTips = this.wages.getWagesSalariesTips();
    ir.numberOfExemptions = this.ir.irs1040CfPage2.boxC;
    ir.amountForExemptions = this.amountForExemptions;

    ir.totalIncomeAfterDeductions = this.totalIncomeAfterDeductions;
    ir.totalIncomeSubjectToTax = this.totalIncomeSubjectToTax;

    if (this.isPartYear) {
      ir.irs1040ScheduleTC = ir.irs1040ScheduleTC || {};

      ir.irs1040ScheduleTC.taxpayerFrom = this.ir.partYearFromDate;
      ir.irs1040ScheduleTC.taxpayerTo = this.ir.partYearToDate;

      ir.irs1040ScheduleTC.spouseFrom = this.ir.partYearFromDateSpouse;
      ir.irs1040ScheduleTC.spouseTo = this.ir.partYearToDateSpouse;

      ir.irs1040ScheduleTC.totalWagesA = this.wages?.totalWagesA || ir.irs1040ScheduleTC.totalWagesA || 0;

      ir.irs1040ScheduleTC = ir.irs1040ScheduleTC || {};
      ir.irs1040ScheduleTC.taxableInterestA = ir.taxableInterest?.interestFromFederal || 0;
      ir.irs1040ScheduleTC.ordinaryDividendsA = ir.ordinaryDividendsFederal?.dividendIncomeFromFederalReturn || 0;
      ir.irs1040ScheduleTC.taxableRefundsA = ir.taxableRefundsCreditsFederal || 0;
      ir.irs1040ScheduleTC.alimonyReceivedA = ir.alimonyReceivedFederal || 0;
      ir.irs1040ScheduleTC.businessIncomeA = ir.businessIncomeFederal || 0;
      ir.irs1040ScheduleTC.capitalGainA = ir.capitalGainsOrLosses?.totalCapitalGainsAndLossesFromFederalReturn || 0;
      ir.irs1040ScheduleTC.otherGainLossA = ir.otherGainsLoses?.totalOtherGainsAndLosses || 0;
      ir.irs1040ScheduleTC.taxableIraDistributionA = ir.taxableIraDistributions?.taxableIraDistributionsFederal || 0;
      ir.irs1040ScheduleTC.taxablePensionA = ir.taxablePensionDistributions?.taxablePensionDistributionsFederal || 0;
      ir.irs1040ScheduleTC.rentalRealA = ir.rentalRealEstate?.rentalRealEstateFederal || 0;
      ir.irs1040ScheduleTC.farmIncomeA = ir.farmIncomeLoss?.totalFarmIncomeNetProfit || 0;
      ir.irs1040ScheduleTC.unemploymentA = ir.unemploymentCompensationFederal || 0;
      ir.irs1040ScheduleTC.ssBenefitsA = ir.socialSecurityFederal || 0;
      ir.irs1040ScheduleTC.otherIncomeA = ir.otherIncome?.otherIncomeFederal || 0;

      ir.irs1040ScheduleTC.totalAdditionsA = this.totalAdditionsA;
      ir.irs1040ScheduleTC.totalAdditionsB = this.totalAdditionsB;
      ir.irs1040ScheduleTC.totalAdditionsC = this.totalAdditionsC;
      ir.irs1040ScheduleTC.totalAdditionsD = this.totalAdditionsD;

      ir.irs1040ScheduleTC.totalIncomeA = this.totalIncomeA;
      ir.irs1040ScheduleTC.totalIncomeB = this.totalIncomeB;
      ir.irs1040ScheduleTC.totalIncomeC = this.totalIncomeC;
      ir.irs1040ScheduleTC.totalIncomeD = this.totalIncomeD;

      ir.irs1040ScheduleTC.totalDeductC = this.totalDeductC;
      ir.irs1040ScheduleTC.totalDeductD = this.totalDeductD;

      ir.irs1040ScheduleTC.totalIncomeAfterDeductionC = this.totalIncomeAfterDeductionC;
      ir.irs1040ScheduleTC.totalIncomeAfterDeductionD = this.totalIncomeAfterDeductionD;

    }

    ir.totalTax = this.totalTax;
    ir.totalTaxDue = this.totalTaxDue;
    return ir;
  }

}
