<form (submit)="deletePayment()" [formGroup]="formDelete" class="d-flex flex-column flex-fill" novalidate>
  <div class="modal-header align-items-center">
    <h4 class="modal-title pull-left">{{'Payment.cancel-scheduled-payments' | translate}}</h4>
    <a (click)="bsModalRef.hide()" class="text-muted" href="javascript:">
      <svg class="icon" viewBox="0 0 24 24">
        <path
            d="M13.46,12L19,17.54V19H17.54L12,13.46L6.46,19H5V17.54L10.54,12L5,6.46V5H6.46L12,10.54L17.54,5H19V6.46L13.46,12Z"
            stroke="none"/>
      </svg>
    </a>
  </div>
  <div class="modal-body">
    <div>{{'Payment.select-all-scheduled-payments' | translate}}</div>

    <ngx-datatable
        #datatablePayments
        (select)="onSelect($event)"
        [columnMode]="'force'"
        [columns]="columns"
        [count]="payments?.length"
        [externalPaging]="false"
        [footerHeight]="50"
        [headerHeight]="50"
        [loadingIndicator]="isLoading$ | async"
        [rowHeight]="50"
        [rows]="payments"
        [scrollbarH]="true"
        [scrollbarV]="true"
        [selected]="selected"
        [selectionType]="'checkbox'"
        class="bootstrap datatable-flush"
    >
    </ngx-datatable>

  </div>
  <div class="modal-footer justify-content-end">
    <a (click)="bsModalRef.hide()" 
      class="btn btn-link" 
      href="javascript:"
      role="button" 
      [attr.aria-label]="'Payment.close' | translate" 
      tabindex="0">{{'Payment.close' | translate}}</a>
    <button [disabled]="formDelete.invalid || (isLoading$ | async)" 
        class="btn btn-danger" 
        type="submit"
        [attr.aria-label]="'Payment.cancel-payment' | translate"
        [attr.aria-disabled]="formDelete.invalid || (isLoading$ | async) ? 'true' : 'false'"
        [attr.aria-busy]="(isLoading$ | async) ? 'true' : 'false'"
        tabindex="0"
        role="button">
      <ui-spinner [class.active]="isLoading$ | async">{{'Payment.cancel-payment' | translate}}</ui-spinner>
    </button>
  </div>
</form>

<ng-template #selectHeaderTemplate let-allRowsSelected="allRowsSelected" let-selectFn="selectFn"
    let-value="value"
    ngx-datatable-header-template>
  <div class="custom-control custom-checkbox">
    <input (change)="selectFn(!allRowsSelected)" [checked]="allRowsSelected"
        class="custom-control-input" id="alert-submitted-transaction-checkbox-check-all"
        type="checkbox">
    <label class="custom-control-label"
        for="alert-submitted-transaction-checkbox-check-all"></label>
  </div>
</ng-template>

<ng-template #selectTemplate let-expanded="expanded" let-isSelected="isSelected"
    let-onCheckboxChangeFn="onCheckboxChangeFn"
    let-row="row" ngx-datatable-cell-template>
  <div class="custom-control custom-checkbox">
    <input (change)="onCheckboxChangeFn($event)" [checked]="isSelected" [id]="row?.id"
        class="custom-control-input"
        type="checkbox">
    <label [for]="row?.id" class="custom-control-label"></label>
  </div>
</ng-template>
