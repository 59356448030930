import {
  IAlimonyPaidWithoutChildSupport,
  ICapitalGainsOrLosses,
  ICreditForTaxPaidAnotherCity,
  IDependent,
  IFarmIncomeLoss,
  IForeignAddress,
  IGamblingIncome,
  IIndividualReturnResponse,
  IIrs1040CfPage2,
  IIrs1040ScheduleTC,
  IOrdinaryDividendsFederal,
  IOtherGainsLoses,
  IOtherIncome,
  IPage2,
  IPartnership,
  IRenaissanceZoneDeduction,
  IRentalRealEstate,
  IReview,
  ISCorporationDistributions,
  ITaxableInterest,
  ITaxableIraDistributions,
  ITaxablePensionDistributions,
  ITaxForm,
  ITaxpayerResided,
  IThirdParty,
  IWagesSalariesTips,
} from "../interfaces/individual-return.interface";
import {FileModel, IAddressBase, IPreparerInformationBase} from "@city-tax/shared";
import {IndividualModel} from "./individual.model";

export class IndividualReturnModel implements IIndividualReturnResponse {
  public id: string;
  public status: string;
  public individualReturnId: string;
  public xmlFileId: string;
  public pdfName: string;
  public extractType: string;
  public eFileDeadline: string;
  public exported: boolean;
  public taxYear: string;
  public cityCode: string;
  public stateId: string;
  public cityId: string;
  public estimatesPaidCurrentYear: number;
  public creditCarryForwardPreviousYear: number
  public taxpayer: IndividualModel;
  public spouse: IndividualModel;
  public residency: string;
  public nonResident: boolean;
  public resident: boolean;
  public partYear: boolean;
  public authorization: boolean;
  public partYearFromDate: string;
  public partYearToDate: string;
  public partYearFromDateSpouse: string;
  public partYearToDateSpouse: string;
  public filing: string;
  public marriedFilingSeparate: boolean;
  public marriedFilingJoint: boolean;
  public single: boolean;

  public federalForm1310?: boolean;
  public itemizedDeductions?: boolean;

  public foreignAddress: IForeignAddress;
  public formerAddress: IAddressBase;
  public previousAddress: IAddressBase;

  public wagesSalariesTips: IWagesSalariesTips;

  public refundDirectDeposit: string;
  public payTaxDueDirectWithdrawal: string;
  public typeOfAccount: string;
  public routingNumber: string;
  public accountNumber: string;
  public ach: boolean;

  public addressWhereTaxpayerResided: Array<ITaxpayerResided>;

  public scheduleDNotRequired: string;

  public totalIncomeFederal: string;
  public totalIncomeTax: string;
  public totalIncomeAfterDeductions: string;
  public numberOfExemptions: string;
  public amountForExemptions: string;
  public totalIncomeSubjectToTax: string;
  public taxRate: string;
  public totalTax: string;
  public taxWithheldByEmployer: string;
  public paymentsOnPriorYearDeclarationTax: string;
  public totalPaymentsCredits: string;
  public totalDonations: string;
  public totalTaxDue: string;
  public totalOverPayment: string;
  public amountOfOverpaymentCreditedForward: string;
  public amountOfOverpaymentRefundedCheck: string;
  public amountOfOverpaymentRefundedRoutingNumber: string;
  public amountOfOverpaymentRefundedTypeOfAccount: string;
  public amountOfOverpaymentRefundedAccountNumber: string;
  public totalInterestAndPenaltyOnUnderpaidEstimatesOrLateFilesReturns: string;

  public scheduleAItemizedDeductions: boolean;

  public unemploymentCompensationFederal: string;
  public unemploymentCompensationAdjustments: string;
  public totalAdditionsFederal: string;
  public totalAdditionsAdjustments: string;
  public totalIncomeAdjustments: string;
  public irs1040ScheduleTC: IIrs1040ScheduleTC;

  public totalIraDeduction?: string;
  public totalSelfEmployedSep?: string;
  public totalEmployeeBusinessExpense?: string;
  public totalMovingExpensesIntoCityAreaOnly?: string;
  public totalRenaissanceZoneDeduction?: string;
  public totalAlimonyPaidWithoutChildSupport?: string;

  public totalDeductions: string;
  public socialSecurityFederal: string;
  public socialSecurityAdjustments: string;

  public thirdParty: IThirdParty;

  public totalAdditionsTax: string;
  public businessIncomeFederal: string;
  public businessIncomeTax: string;

  public taxableIraDistributions: ITaxableIraDistributions;
  public taxablePensionDistributions: ITaxablePensionDistributions;

  public taxableInterest: ITaxableInterest;
  public otherIncome: IOtherIncome;
  public ordinaryDividendsFederal: IOrdinaryDividendsFederal;
  public capitalGainsOrLosses: ICapitalGainsOrLosses;

  public creditForTaxPaidAnotherCity: ICreditForTaxPaidAnotherCity;

  public otherGainsLoses: IOtherGainsLoses;

  public gamblingIncome?: IGamblingIncome;
  public partnership?: IPartnership;

  public farmIncomeLoss: IFarmIncomeLoss;
  public source: string;
  public sCorporationDistributions: Array<ISCorporationDistributions>;
  public renaissanceZoneDeduction: IRenaissanceZoneDeduction;
  public alimonyPaidWithoutChildSupport: Array<IAlimonyPaidWithoutChildSupport>;
  public businessIncomeAdjustments: string;
  public cfCovLine5: boolean;
  public taxableRefundsCreditsFederal: string;
  public taxableRefundsCreditsAdjustment: string;
  public inCareOf: string;
  public directWithdrawalEffectiveDate: string;
  public underpaymentInterestAndPenalty: string;
  public underpaymentOfEstimatedPI: string;
  public interestOnUnderpaidEstimatesOrLateFiledReturns: string;
  public donation35C: string;
  public donation35A: string;
  public selfEmployedSep: string;
  public donation35B: string;
  public municipalityName: string;
  public municipalityAddress: IAddressBase;
  public municipalityPhone: string;
  public municipalityWebSite: string;
  public amendedTaxReturnNo: string;
  public nextYearTaxableIncome: string;
  public totalIncome: string;
  public taxableIncome: string;
  public fillingCityTaxRatePercent: string;
  public fillingCityTaxRateDecimal: string;
  public multiplyTaxableIncome: string;
  public fillingCityCreditRatePercent: string;
  public fillingCityCreditRateDecimal: string;
  public totalCreditsAllowable: string;
  public minAmountOfTaxDue: string;
  public taxDue: string;
  public estimatedTaxPaid: string;
  public priorYearOverpayment: string;
  public totalEstimatedTaxPaidAndPriorYearOverpayment: string;
  public totalDueAfterPayments: string;
  public interest: string;
  public totalAmountDue: string;
  public makeCheckPayableTo: string;
  public overpaymentLessThen: string;
  public totalEstimatedIncomeSubjectToTax: string;
  public estimatedIncomeTax: string;
  public lessExpectedTaxCredits: string;
  public netTaxDue: string;
  public amountDueWithThisDeclaration: string;
  public totalOfThisPayment: string;
  public overpayment: string;
  public overpaymentToBeRefunded: string;
  public residentNo: string;
  public overpaymentToBeCredited: string;
  public ownProperty: boolean;
  public municipalityEmail: string;
  public landlordName: string;
  public dependents: Array<Dependent>;
  public landlordAddress: IAddressBase;

  public noNextYearTaxableIncome: string;
  public adjustments: string;
  public lateFillingFee: string;
  public percentageOfNonResidentIncomeSubjectToTax: string;

  public amendedTaxReturn: boolean;
  public homeownerCredit: boolean;
  public noTaxableIncome: boolean;
  public noTaxableIncomeExplanation: string;
  public exportLocator: string;
  public exportedDate: string;
  public residentHomeownerCreditAmount: string;
  public penalty: string;
  public totalPenaltyAndInterest: string;
  public penaltyOnUnderpaidEstimatesOrLateFiledReturns: string;
  public softwareVendorId: string;
  public auditFlag: boolean;
  public preparerInformation: IPreparerInformationBase;

  public createdAt: Date;
  public updatedAt: Date;
  public files?: Array<FileModel>;
  public confidence: number;
  public taxForms?: ITaxForm;
  public review?: IReview;
  public rentalRealEstate?: IRentalRealEstate;
  public filingDate?: string;
  public locator?: string;

  public alimonyReceivedFederal?: string;
  public alimonyReceivedAdjustments?: string;
  public alimonyReceivedTax?: string;
  public alimonyDateOfDivorce?: Date;

  public acknowledgeName?: string;
  public acknowledgeDate?: Date;
  public page2?: IPage2;

  public extension: boolean;

  public irs1040CfPage2: IIrs1040CfPage2

  constructor(individualReturn: IndividualReturnModel) {
    this.id = individualReturn.id;
    this.status = individualReturn.status;
    this.individualReturnId = individualReturn.individualReturnId;
    this.pdfName = individualReturn.pdfName;
    this.extractType = individualReturn.extractType;
    this.rentalRealEstate = individualReturn.rentalRealEstate;
    this.source = individualReturn.source;
    this.xmlFileId = individualReturn.xmlFileId;
    this.exported = individualReturn.exported;
    this.taxYear = individualReturn.taxYear;
    this.cityCode = individualReturn.cityCode;
    this.stateId = individualReturn.stateId;
    this.cityId = individualReturn.cityId;
    this.authorization = individualReturn.authorization;
    this.taxpayer = new IndividualModel(individualReturn.taxpayer);
    this.spouse = new IndividualModel(individualReturn.spouse);
    this.residency = individualReturn.residency;
    this.nonResident = individualReturn.nonResident;
    this.resident = individualReturn.resident;
    this.partYear = individualReturn.partYear;
    this.exportLocator = individualReturn.exportLocator;
    this.noTaxableIncomeExplanation = individualReturn.noTaxableIncomeExplanation;
    this.exportedDate = individualReturn.exportedDate;

    this.partYearFromDate = individualReturn.partYearFromDate;
    this.partYearToDate = individualReturn.partYearToDate;

    this.partYearFromDateSpouse = individualReturn.partYearFromDateSpouse;
    this.partYearToDateSpouse = individualReturn.partYearToDateSpouse;

    this.eFileDeadline = individualReturn.eFileDeadline;
    this.filing = individualReturn.filing;
    this.marriedFilingSeparate = individualReturn.marriedFilingSeparate;
    this.marriedFilingJoint = individualReturn.marriedFilingJoint;
    this.irs1040ScheduleTC = new Irs1040ScheduleTC(individualReturn.irs1040ScheduleTC);
    this.single = individualReturn.single;
    this.locator = individualReturn.locator;

    this.federalForm1310 = individualReturn.federalForm1310;
    this.itemizedDeductions = individualReturn.itemizedDeductions;

    this.foreignAddress = individualReturn.foreignAddress;
    this.formerAddress = individualReturn.formerAddress;
    this.previousAddress = individualReturn.previousAddress;

    this.wagesSalariesTips = individualReturn.wagesSalariesTips;
    this.gamblingIncome = individualReturn.gamblingIncome;
    this.partnership = individualReturn.partnership;
    this.refundDirectDeposit = individualReturn.refundDirectDeposit;
    this.payTaxDueDirectWithdrawal = individualReturn.payTaxDueDirectWithdrawal;

    this.routingNumber = individualReturn.routingNumber;
    this.accountNumber = individualReturn.accountNumber;

    this.scheduleDNotRequired = individualReturn.scheduleDNotRequired;

    this.totalIncomeFederal = individualReturn.totalIncomeFederal;
    this.totalIncomeTax = individualReturn.totalIncomeTax;
    this.totalIncomeAfterDeductions = individualReturn.totalIncomeAfterDeductions;
    this.numberOfExemptions = individualReturn.numberOfExemptions;
    this.amountForExemptions = individualReturn.amountForExemptions;
    this.totalIncomeSubjectToTax = individualReturn.totalIncomeSubjectToTax;
    this.taxRate = individualReturn.taxRate;
    this.totalTax = individualReturn.totalTax;
    this.taxWithheldByEmployer = individualReturn.taxWithheldByEmployer;
    this.paymentsOnPriorYearDeclarationTax = individualReturn.paymentsOnPriorYearDeclarationTax;
    this.totalPaymentsCredits = individualReturn.totalPaymentsCredits;
    this.totalDonations = individualReturn.totalDonations;
    this.totalTaxDue = individualReturn.totalTaxDue;
    this.totalOverPayment = individualReturn.totalOverPayment;
    this.amountOfOverpaymentCreditedForward =
      individualReturn.amountOfOverpaymentCreditedForward;
    this.amountOfOverpaymentRefundedCheck =
      individualReturn.amountOfOverpaymentRefundedCheck;
    this.amountOfOverpaymentRefundedRoutingNumber =
      individualReturn.amountOfOverpaymentRefundedRoutingNumber;
    this.amountOfOverpaymentRefundedTypeOfAccount =
      individualReturn.amountOfOverpaymentRefundedTypeOfAccount;
    this.amountOfOverpaymentRefundedAccountNumber =
      individualReturn.amountOfOverpaymentRefundedAccountNumber;
    this.totalInterestAndPenaltyOnUnderpaidEstimatesOrLateFilesReturns =
      individualReturn.totalInterestAndPenaltyOnUnderpaidEstimatesOrLateFilesReturns;

    this.dependents = individualReturn.dependents?.length > 0 ? individualReturn.dependents.map((dep) => new Dependent(dep)) : [];

    this.addressWhereTaxpayerResided = individualReturn.addressWhereTaxpayerResided?.length > 0 ? individualReturn.addressWhereTaxpayerResided.map((dep) => new TaxpayerResided(dep)) : [];

    this.scheduleAItemizedDeductions =
      individualReturn.scheduleAItemizedDeductions;

    this.unemploymentCompensationFederal =
      individualReturn.unemploymentCompensationFederal;
    this.unemploymentCompensationAdjustments =
      individualReturn.unemploymentCompensationAdjustments;
    this.totalAdditionsFederal = individualReturn.totalAdditionsFederal;
    this.totalAdditionsAdjustments = individualReturn.totalAdditionsAdjustments;
    this.totalIncomeAdjustments = individualReturn.totalIncomeAdjustments;

    this.totalIraDeduction = individualReturn.totalIraDeduction;
    this.totalSelfEmployedSep = individualReturn.totalSelfEmployedSep;
    this.totalEmployeeBusinessExpense = individualReturn.totalEmployeeBusinessExpense;
    this.totalMovingExpensesIntoCityAreaOnly = individualReturn.totalMovingExpensesIntoCityAreaOnly;
    this.totalRenaissanceZoneDeduction = individualReturn.totalRenaissanceZoneDeduction;
    this.totalAlimonyPaidWithoutChildSupport = individualReturn.totalAlimonyPaidWithoutChildSupport;
    this.totalAlimonyPaidWithoutChildSupport = individualReturn.totalAlimonyPaidWithoutChildSupport;

    this.totalDeductions = individualReturn.totalDeductions;
    this.socialSecurityFederal = individualReturn.socialSecurityFederal;
    this.socialSecurityAdjustments = individualReturn.socialSecurityAdjustments;

    this.thirdParty = individualReturn.thirdParty;

    this.totalAdditionsTax = individualReturn.totalAdditionsTax;
    this.businessIncomeFederal = individualReturn.businessIncomeFederal;
    this.businessIncomeTax = individualReturn.businessIncomeTax;

    this.taxableIraDistributions = individualReturn.taxableIraDistributions;
    this.taxablePensionDistributions =
      individualReturn.taxablePensionDistributions;

    this.taxableInterest = individualReturn.taxableInterest;
    this.otherIncome = individualReturn.otherIncome;
    this.ordinaryDividendsFederal = individualReturn.ordinaryDividendsFederal;
    this.capitalGainsOrLosses = individualReturn.capitalGainsOrLosses;

    this.creditForTaxPaidAnotherCity =
      individualReturn.creditForTaxPaidAnotherCity;

    this.otherGainsLoses = individualReturn.otherGainsLoses;


    this.farmIncomeLoss = individualReturn.farmIncomeLoss;

    this.sCorporationDistributions = individualReturn.sCorporationDistributions;
    this.renaissanceZoneDeduction = individualReturn.renaissanceZoneDeduction;
    this.alimonyPaidWithoutChildSupport =
      individualReturn.alimonyPaidWithoutChildSupport;
    this.businessIncomeAdjustments = individualReturn.businessIncomeAdjustments;
    this.cfCovLine5 = individualReturn.cfCovLine5;
    this.taxableRefundsCreditsFederal =
      individualReturn.taxableRefundsCreditsFederal;
    this.taxableRefundsCreditsAdjustment =
      individualReturn.taxableRefundsCreditsAdjustment;
    this.inCareOf = individualReturn.inCareOf;
    this.directWithdrawalEffectiveDate =
      individualReturn.directWithdrawalEffectiveDate;
    this.underpaymentInterestAndPenalty =
      individualReturn.underpaymentInterestAndPenalty;
    this.underpaymentOfEstimatedPI = individualReturn.underpaymentOfEstimatedPI;
    this.interestOnUnderpaidEstimatesOrLateFiledReturns =
      individualReturn.interestOnUnderpaidEstimatesOrLateFiledReturns;
    this.donation35C = individualReturn.donation35C;
    this.donation35A = individualReturn.donation35A;
    this.selfEmployedSep = individualReturn.selfEmployedSep;
    this.donation35B = individualReturn.donation35B;
    this.municipalityName = individualReturn.municipalityName;
    this.municipalityAddress = individualReturn.municipalityAddress;
    this.municipalityPhone = individualReturn.municipalityPhone;
    this.municipalityWebSite = individualReturn.municipalityWebSite;
    this.amendedTaxReturnNo = individualReturn.amendedTaxReturnNo;
    this.nextYearTaxableIncome = individualReturn.nextYearTaxableIncome;
    this.totalIncome = individualReturn.totalIncome;
    this.taxableIncome = individualReturn.taxableIncome;
    this.fillingCityTaxRatePercent = individualReturn.fillingCityTaxRatePercent;
    this.fillingCityTaxRateDecimal = individualReturn.fillingCityTaxRateDecimal;
    this.multiplyTaxableIncome = individualReturn.multiplyTaxableIncome;
    this.fillingCityCreditRatePercent =
      individualReturn.fillingCityCreditRatePercent;
    this.fillingCityCreditRateDecimal =
      individualReturn.fillingCityCreditRateDecimal;
    this.totalCreditsAllowable = individualReturn.totalCreditsAllowable;
    this.minAmountOfTaxDue = individualReturn.minAmountOfTaxDue;
    this.taxDue = individualReturn.taxDue;
    this.estimatedTaxPaid = individualReturn.estimatedTaxPaid;
    this.priorYearOverpayment = individualReturn.priorYearOverpayment;
    this.totalEstimatedTaxPaidAndPriorYearOverpayment =
      individualReturn.totalEstimatedTaxPaidAndPriorYearOverpayment;
    this.totalDueAfterPayments = individualReturn.totalDueAfterPayments;
    this.interest = individualReturn.interest;
    this.totalAmountDue = individualReturn.totalAmountDue;
    this.makeCheckPayableTo = individualReturn.makeCheckPayableTo;
    this.overpaymentLessThen = individualReturn.overpaymentLessThen;
    this.totalEstimatedIncomeSubjectToTax =
      individualReturn.totalEstimatedIncomeSubjectToTax;
    this.estimatedIncomeTax = individualReturn.estimatedIncomeTax;
    this.lessExpectedTaxCredits = individualReturn.lessExpectedTaxCredits;
    this.netTaxDue = individualReturn.netTaxDue;
    this.amountDueWithThisDeclaration =
      individualReturn.amountDueWithThisDeclaration;
    this.totalOfThisPayment = individualReturn.totalOfThisPayment;

    this.overpayment = individualReturn.overpayment;
    this.overpaymentToBeRefunded = individualReturn.overpaymentToBeRefunded;
    this.residentNo = individualReturn.residentNo;
    this.overpaymentToBeCredited = individualReturn.overpaymentToBeCredited;
    this.ownProperty = individualReturn.ownProperty;
    this.municipalityEmail = individualReturn.municipalityEmail;
    this.landlordName = individualReturn.landlordName;

    this.landlordAddress = individualReturn.landlordAddress;

    this.noNextYearTaxableIncome = individualReturn.noNextYearTaxableIncome;
    this.adjustments = individualReturn.adjustments;
    this.lateFillingFee = individualReturn.lateFillingFee;
    this.percentageOfNonResidentIncomeSubjectToTax = individualReturn.percentageOfNonResidentIncomeSubjectToTax;

    this.amendedTaxReturn = individualReturn.amendedTaxReturn;
    this.extension = individualReturn.extension;
    this.homeownerCredit = individualReturn.homeownerCredit;
    this.noTaxableIncome = individualReturn.noTaxableIncome;
    this.typeOfAccount = individualReturn.typeOfAccount;
    this.residentHomeownerCreditAmount = individualReturn.residentHomeownerCreditAmount;
    this.penalty = individualReturn.penalty;
    this.totalPenaltyAndInterest = individualReturn.totalPenaltyAndInterest;
    this.penaltyOnUnderpaidEstimatesOrLateFiledReturns = individualReturn.penaltyOnUnderpaidEstimatesOrLateFiledReturns;
    this.softwareVendorId = individualReturn.softwareVendorId;
    this.auditFlag = individualReturn.auditFlag;
    this.ach = individualReturn.ach;
    this.preparerInformation = individualReturn.preparerInformation;

    this.filingDate = individualReturn.filingDate;

    this.createdAt = individualReturn.createdAt;
    this.updatedAt = individualReturn.updatedAt;
    this.files = individualReturn.files;
    this.taxForms = individualReturn.taxForms;
    this.review = individualReturn.review;

    this.alimonyReceivedFederal = individualReturn.alimonyReceivedFederal;
    this.alimonyReceivedAdjustments = individualReturn.alimonyReceivedAdjustments;
    this.alimonyReceivedTax = individualReturn.alimonyReceivedTax;
    this.alimonyDateOfDivorce = individualReturn.alimonyDateOfDivorce;
    this.acknowledgeName = individualReturn.acknowledgeName;
    this.acknowledgeDate = individualReturn.acknowledgeDate;

    this.page2 = individualReturn.page2;
    this.extension = individualReturn.extension;
    this.irs1040CfPage2 = individualReturn.irs1040CfPage2 ? new Irs1040CfPage2(individualReturn.irs1040CfPage2) : {};
  }
}

export class Dependent implements IDependent {
  public firstName?: string;
  public lastName?: string;
  public ssn?: string;
  public relationship?: string;
  public birthdate?: string;

  constructor(dependent: IDependent) {
    if (!dependent) return;
    this.firstName = dependent.firstName;
    this.lastName = dependent.lastName;
    this.ssn = dependent.ssn;
    this.relationship = dependent.relationship;
    this.birthdate = dependent.birthdate;
  }
}

export class TaxpayerResided implements ITaxpayerResided {
  public residedIndicate?: string;
  public residedAddress?: string;
  public residedFromMonth?: string;
  public residedFromDay?: string;
  public residedToMonth?: string;
  public residedToDay?: string;
  public id?: string;

  constructor(addressWhereTaxpayerResided: ITaxpayerResided) {
    if (!addressWhereTaxpayerResided) return;
    this.residedIndicate = addressWhereTaxpayerResided.residedIndicate;
    this.residedAddress = addressWhereTaxpayerResided.residedAddress;
    this.residedFromMonth = addressWhereTaxpayerResided.residedFromMonth;
    this.residedFromDay = addressWhereTaxpayerResided.residedFromDay;
    this.residedToMonth = addressWhereTaxpayerResided.residedToMonth;
    this.residedToDay = addressWhereTaxpayerResided.residedToDay;
    this.id = addressWhereTaxpayerResided.id;
  }
}

export class Irs1040CfPage2 implements IIrs1040CfPage2 {
  public boxA?: number;
  public dependents?: Array<IDependent>;
  public numDependentChild?: number;
  public boxB?: number;
  public boxC?: number;
  public claimedDependent?: boolean;
  public addressWhereTaxpayerResided?: Array<ITaxpayerResided>;
  public numDependentNonChild?: number;

  public exemptionsYouDateOfBirth?: string;
  public exemptionsYouRegular?: boolean;
  public exemptionsYouOver65?: boolean;
  public exemptionsYouBlind?: boolean;
  public exemptionsYouDeaf?: boolean;
  public exemptionsYouDisabled?: boolean;

  public exemptionsSpouseDateOfBirth?: string;
  public exemptionsSpouseRegular?: boolean;
  public exemptionsSpouseOver65?: boolean;
  public exemptionsSpouseBlind?: boolean;
  public exemptionsSpouseDeaf?: boolean;
  public exemptionsSpouseDisabled?: boolean;

  constructor(irs1040CfPage2: IIrs1040CfPage2) {
    this.boxA = irs1040CfPage2.boxA;
    this.dependents = irs1040CfPage2.dependents;
    this.numDependentChild = irs1040CfPage2.numDependentChild;
    this.boxB = irs1040CfPage2.boxB;
    this.boxC = irs1040CfPage2.boxC;
    this.claimedDependent = irs1040CfPage2.claimedDependent;
    this.addressWhereTaxpayerResided = irs1040CfPage2.addressWhereTaxpayerResided;
    this.numDependentNonChild = irs1040CfPage2.numDependentNonChild;
    this.exemptionsYouDateOfBirth = this.exemptionsYouDateOfBirth;
    this.exemptionsYouRegular = irs1040CfPage2.exemptionsYouRegular;
    this.exemptionsYouOver65 = irs1040CfPage2.exemptionsYouOver65;
    this.exemptionsYouBlind = irs1040CfPage2.exemptionsYouBlind;
    this.exemptionsYouDeaf = irs1040CfPage2.exemptionsYouDeaf;
    this.exemptionsYouDisabled = irs1040CfPage2.exemptionsYouDisabled;

    this.exemptionsSpouseDateOfBirth = this.exemptionsSpouseDateOfBirth;

    this.exemptionsSpouseRegular = irs1040CfPage2.exemptionsSpouseRegular;
    this.exemptionsSpouseOver65 = irs1040CfPage2.exemptionsSpouseOver65;
    this.exemptionsSpouseBlind = irs1040CfPage2.exemptionsSpouseBlind;
    this.exemptionsSpouseDeaf = irs1040CfPage2.exemptionsSpouseDeaf;
    this.exemptionsSpouseDisabled = irs1040CfPage2.exemptionsSpouseDisabled;

  }
}

export class Irs1040ScheduleTC implements IIrs1040ScheduleTC {

  public taxpayerAddress?: string;
  public spouseAddress?: string;

  public taxpayerFrom?: string;
  public taxpayerTo?: string;
  public spouseFrom?: string;
  public spouseTo?: string;

  public totalWagesA?: string;
  public totalWagesB?: string;
  public totalWagesC?: string;
  public totalWagesD?: string;


  public taxableInterestA?: string;
  public taxableInterestB?: string;
  public taxableInterestC?: string;

  public ordinaryDividendsA?: string;
  public ordinaryDividendsB?: string;
  public ordinaryDividendsC?: string;


//line 4

  public taxableRefundsA?: string;
  public taxableRefundsB?: string;

//line5

  public alimonyReceivedA?: string;
  public alimonyReceivedB?: string;
  public alimonyReceivedC?: string;
  public alimonyReceivedD?: string;


//line 6
  public businessIncomeA?: string;
  public businessIncomeB?: string;
  public businessIncomeC?: string;
  public businessIncomeD?: string;
//line 7

  public capitalGainA?: string;
  public capitalGainB?: string;
  public capitalGainC?: string;
  public capitalGainD?: string;

//line 8

  public otherGainLossA?: string;
  public otherGainLossB?: string;
  public otherGainLossC?: string;
  public otherGainLossD?: string;

//line 9


  public taxableIraDistributionA?: string;
  public taxableIraDistributionB?: string;
  public taxableIraDistributionC?: string;
  public taxableIraDistributionD?: string;

//line 10

  public taxablePensionA?: string;
  public taxablePensionB?: string;
  public taxablePensionC?: string;
  public taxablePensionD?: string;


//line 11

  public rentalRealA?: string;
  public rentalRealB?: string;
  public rentalRealC?: string;
  public rentalRealD?: string;


//line 12

  public subchapterSA?: string;
  public subchapterSB?: string;
  public subchapterSC?: string;
  public subchapterSD?: string;


//line 13

  public farmIncomeA?: string;
  public farmIncomeB?: string;
  public farmIncomeC?: string;
  public farmIncomeD?: string;


// //line 14

  public unemploymentA?: string;
  public unemploymentB?: string;

//line 15

  public ssBenefitsA?: string;
  public ssBenefitsB?: string;

// //line 16

  public otherIncomeA?: string;
  public otherIncomeB?: string;
  public otherIncomeC?: string;
  public otherIncomeD?: string;


// //line 17
  public totalAdditionsA?: string;
  public totalAdditionsB?: string;
  public totalAdditionsC?: string;
  public totalAdditionsD?: string;

// //line 18
  public totalIncomeA?: string;
  public totalIncomeB?: string;
  public totalIncomeC?: string;
  public totalIncomeD?: string;


//line 19
  public totalDeductC?: string;
  public totalDeductD?: string;

//line 20a
  public totalIncomeAfterDeductionC?: string;
  public totalIncomeAfterDeductionD?: string;
//line 20b
  public totalLossesTransferredC?: string;
  public totalLossesTransferredD?: string;
//line 20c
  public totalIncomeAfterC?: string;
  public totalIncomeAfterD?: string;

//21c
  public amountExemptionsD?: string;
//22a
  public resTotalIncomeSubTaxBC?: string;
//22b
  public nonTotalIncomeSubTaxAD?: string;
//23a
  public taxResRateAC?: string;
//23b
  public taxNonResRateAD?: string;

  public iraDeductionA?: string;
  public iraDeductionB?: string;
  public iraDeductionC?: string;
  public iraDeductionD?: string;

  public movingExpA?: string;
  public movingExpB?: string;
  public movingExpC?: string;
  public movingExpD?: string;

  public empBizA?: string;
  public empBizB?: string;
  public empBizC?: string;
  public empBizD?: string;

  public selfEmpA?: string;
  public selfEmpB?: string;
  public selfEmpC?: string;
  public selfEmpD?: string;

  public alimonyPaidA?: string;
  public alimonyPaidB?: string;
  public alimonyPaidC?: string;
  public alimonyPaidD?: string;

  public renaissanceZoneDeductA?: string;
  public renaissanceZoneDeductB?: string;
  public renaissanceZoneDeductC?: string;
  public renaissanceZoneDeductD?: string;

  constructor(irs1040ScheduleTC: IIrs1040ScheduleTC) {
    if (!irs1040ScheduleTC) return;
    this.taxpayerAddress = irs1040ScheduleTC.taxpayerAddress;
    this.spouseAddress = irs1040ScheduleTC.spouseAddress;

    this.taxpayerFrom = irs1040ScheduleTC.taxpayerFrom;
    this.taxpayerTo = irs1040ScheduleTC.taxpayerTo;
    this.spouseFrom = irs1040ScheduleTC.spouseFrom;
    this.spouseTo = irs1040ScheduleTC.spouseTo;

    this.totalWagesA = irs1040ScheduleTC.totalWagesA;
    this.totalWagesB = irs1040ScheduleTC.totalWagesB;
    this.totalWagesC = irs1040ScheduleTC.totalWagesC;
    this.totalWagesD = irs1040ScheduleTC.totalWagesD;
    this.taxableInterestA = irs1040ScheduleTC.taxableInterestA;
    this.taxableInterestB = irs1040ScheduleTC.taxableInterestB;
    this.taxableInterestC = irs1040ScheduleTC.taxableInterestC;
    this.ordinaryDividendsA = irs1040ScheduleTC.ordinaryDividendsA;
    this.ordinaryDividendsB = irs1040ScheduleTC.ordinaryDividendsB;
    this.ordinaryDividendsC = irs1040ScheduleTC.ordinaryDividendsC;
    this.taxableRefundsA = irs1040ScheduleTC.taxableRefundsA;
    this.taxableRefundsB = irs1040ScheduleTC.taxableRefundsB;
    this.alimonyReceivedA = irs1040ScheduleTC.alimonyReceivedA;
    this.alimonyReceivedB = irs1040ScheduleTC.alimonyReceivedB;
    this.alimonyReceivedC = irs1040ScheduleTC.alimonyReceivedC;
    this.alimonyReceivedD = irs1040ScheduleTC.alimonyReceivedD;
    this.businessIncomeA = irs1040ScheduleTC.businessIncomeA;
    this.businessIncomeB = irs1040ScheduleTC.businessIncomeB;
    this.businessIncomeC = irs1040ScheduleTC.businessIncomeC;
    this.businessIncomeD = irs1040ScheduleTC.businessIncomeD;
    this.capitalGainA = irs1040ScheduleTC.capitalGainA;
    this.capitalGainB = irs1040ScheduleTC.capitalGainB;
    this.capitalGainC = irs1040ScheduleTC.capitalGainC;
    this.capitalGainD = irs1040ScheduleTC.capitalGainD;
    this.otherGainLossA = irs1040ScheduleTC.otherGainLossA;
    this.otherGainLossB = irs1040ScheduleTC.otherGainLossB;
    this.otherGainLossC = irs1040ScheduleTC.otherGainLossC;
    this.otherGainLossD = irs1040ScheduleTC.otherGainLossD;

    this.taxableIraDistributionA = irs1040ScheduleTC.taxableIraDistributionA;
    this.taxableIraDistributionB = irs1040ScheduleTC.taxableIraDistributionB;
    this.taxableIraDistributionC = irs1040ScheduleTC.taxableIraDistributionC;
    this.taxableIraDistributionD = irs1040ScheduleTC.taxableIraDistributionD;
    this.taxablePensionA = irs1040ScheduleTC.taxablePensionA;

    this.taxablePensionB = irs1040ScheduleTC.taxablePensionB;
    this.taxablePensionC = irs1040ScheduleTC.taxablePensionC;
    this.taxablePensionD = irs1040ScheduleTC.taxablePensionD;
    this.rentalRealA = irs1040ScheduleTC.rentalRealA;

    this.rentalRealB = irs1040ScheduleTC.rentalRealB;
    this.rentalRealC = irs1040ScheduleTC.rentalRealC;
    this.rentalRealD = irs1040ScheduleTC.rentalRealD;
    this.subchapterSA = irs1040ScheduleTC.subchapterSA;

    this.subchapterSB = irs1040ScheduleTC.subchapterSB;
    this.subchapterSC = irs1040ScheduleTC.subchapterSC;
    this.subchapterSD = irs1040ScheduleTC.subchapterSD;
    this.farmIncomeA = irs1040ScheduleTC.farmIncomeA;
    this.farmIncomeB = irs1040ScheduleTC.farmIncomeB;
    this.farmIncomeC = irs1040ScheduleTC.farmIncomeC;
    this.farmIncomeD = irs1040ScheduleTC.farmIncomeD;
    this.unemploymentA = irs1040ScheduleTC.unemploymentA;
    this.unemploymentB = irs1040ScheduleTC.unemploymentB;
    this.ssBenefitsA = irs1040ScheduleTC.ssBenefitsA;
    this.ssBenefitsB = irs1040ScheduleTC.ssBenefitsB;
    this.otherIncomeA = irs1040ScheduleTC.otherIncomeA;
    this.otherIncomeB = irs1040ScheduleTC.otherIncomeB;
    this.otherIncomeC = irs1040ScheduleTC.otherIncomeC;
    this.otherIncomeD = irs1040ScheduleTC.otherIncomeD;
    this.totalAdditionsA = irs1040ScheduleTC.totalAdditionsA;
    this.totalAdditionsB = irs1040ScheduleTC.totalAdditionsB;
    this.totalAdditionsC = irs1040ScheduleTC.totalAdditionsC;
    this.totalAdditionsD = irs1040ScheduleTC.totalAdditionsD;
    this.totalIncomeA = irs1040ScheduleTC.totalIncomeA;
    this.totalIncomeB = irs1040ScheduleTC.totalIncomeB;
    this.totalIncomeC = irs1040ScheduleTC.totalIncomeC;

    this.totalIncomeD = irs1040ScheduleTC.totalIncomeD;
    this.totalDeductC = irs1040ScheduleTC.totalDeductC;
    this.totalDeductD = irs1040ScheduleTC.totalDeductD;
    this.totalIncomeAfterDeductionC = irs1040ScheduleTC.totalIncomeAfterDeductionC;
    this.totalIncomeAfterDeductionD = irs1040ScheduleTC.totalIncomeAfterDeductionD;
    this.totalLossesTransferredC = irs1040ScheduleTC.totalLossesTransferredC;
    this.totalLossesTransferredD = irs1040ScheduleTC.totalLossesTransferredD;
    this.totalIncomeAfterC = irs1040ScheduleTC.totalIncomeAfterC;
    this.totalIncomeAfterD = irs1040ScheduleTC.totalIncomeAfterD;
    this.amountExemptionsD = irs1040ScheduleTC.amountExemptionsD;
    this.resTotalIncomeSubTaxBC = irs1040ScheduleTC.resTotalIncomeSubTaxBC;
    this.nonTotalIncomeSubTaxAD = irs1040ScheduleTC.nonTotalIncomeSubTaxAD;
    this.taxResRateAC = irs1040ScheduleTC.taxResRateAC;
    this.taxNonResRateAD = irs1040ScheduleTC.taxNonResRateAD;
    this.iraDeductionA = irs1040ScheduleTC.iraDeductionA;
    this.iraDeductionB = irs1040ScheduleTC.iraDeductionB;
    this.iraDeductionC = irs1040ScheduleTC.iraDeductionC;
    this.iraDeductionD = irs1040ScheduleTC.iraDeductionD;
    this.movingExpA = irs1040ScheduleTC.movingExpA;
    this.movingExpB = irs1040ScheduleTC.movingExpB;
    this.movingExpC = irs1040ScheduleTC.movingExpC;

    this.movingExpD = irs1040ScheduleTC.movingExpD;
    this.empBizA = irs1040ScheduleTC.empBizA;
    this.empBizB = irs1040ScheduleTC.empBizB;
    this.empBizC = irs1040ScheduleTC.empBizC;
    this.empBizD = irs1040ScheduleTC.empBizD;
    this.selfEmpA = irs1040ScheduleTC.selfEmpA;
    this.selfEmpB = irs1040ScheduleTC.selfEmpB;
    this.selfEmpC = irs1040ScheduleTC.selfEmpC;
    this.selfEmpD = irs1040ScheduleTC.selfEmpD;
    this.alimonyPaidA = irs1040ScheduleTC.alimonyPaidA;
    this.alimonyPaidB = irs1040ScheduleTC.alimonyPaidB;
    this.alimonyPaidC = irs1040ScheduleTC.alimonyPaidC;
    this.alimonyPaidD = irs1040ScheduleTC.alimonyPaidD;
    this.renaissanceZoneDeductA = irs1040ScheduleTC.renaissanceZoneDeductA;
    this.renaissanceZoneDeductB = irs1040ScheduleTC.renaissanceZoneDeductB;
    this.renaissanceZoneDeductC = irs1040ScheduleTC.renaissanceZoneDeductC;
    this.renaissanceZoneDeductD = irs1040ScheduleTC.renaissanceZoneDeductD;


  }
}
