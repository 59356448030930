import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "@city-tax/features/auth";
import {PaymentService} from "../../../services/payment.service";

@Component({
  selector: 'city-tax-feature-individual-payment-delete',
  templateUrl: './payment-delete.component.html',
  styleUrls: ['./payment-delete.component.scss']
})
export class IndividualPaymentDeleteComponent implements OnInit {
  public formDelete: FormGroup;
  public isLoading$ = this.paymentService.isLoading$;
  public payment: any;

  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private paymentService: PaymentService,
    private authService: AuthService,
  ) {
  }

  ngOnInit(): void {
    this._initFormDelete();
  }

  public async deletePayment() {

    const response = await this.paymentService.deletePayment(
      this.authService.organization.id,
      this.payment.id
    );

    this.bsModalRef.hide();
    this.toastrService.success('Payment deleted successfully', 'Delete Payment');
  };

  private _initFormDelete = () => {
    this.formDelete = this.formBuilder.group({});
  }
}
