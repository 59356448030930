import {IBusinessResponse, IMember} from "../interfaces/business.interface";
import {IAddressBase} from "@city-tax/shared";

export class BusinessModel implements IBusinessResponse {
  id?: string;
  businessId?: string;
  name?: string;
  dba?: string;
  fein?: string;
  accountNumber?: string;
  pin?: string;
  contact?: {
    name?: string;
    title?: string;
  };
  email?: string;
  emailNotifications?: boolean;
  phone?: string;
  fax?: string;
  filingSchedule?: string;

  incorporationDate?: string;
  businessOwnership: {
    proprietorship: boolean,
    partnership: boolean,
    corporation: boolean,
    other: boolean,
    resident: boolean,
    nonResident: boolean,
    professional: boolean,
  };

  address?: IAddressBase;
  mailingAddress?: IAddressBase;
  cityAddress?: IAddressBase;
  paymentMethods?: Array<any>;

  ss4?: {
    key?: string;
    mimeType?: string;
    fileName?: string;
    size?: number;
    type?: string;
    mailingAddress?: IAddressBase;
    cityAddress?: IAddressBase;
    generalInformation: {
      firstWagesDate?: Date;
      numberOfEmployees?: number;
      registrationType?: string;
      previousFein?: null,
      clientFein?: null,
      newBusinessDate?: Date;
      doingBusinessDate?: Date;
      otherDescription?: string;
      previousOwner?: string;
      previousOwnerContinue?: boolean;
      taxYearEndDec31?: boolean;
      taxYearEndMonth?: number;
      taxYearEndDay?: number;
    },
    withholdingPreparedType?: string;
    taxPaidUnderFein?: string;
    w2FiledUnderFein?: string;
    businessOwnership: {
      individualProprietorship?: boolean;
      generalPartnership?: boolean;
      limitedPartnership?: boolean;
      professionalLimitedLiability?: boolean;
      partnership?: boolean;
      limitedLiabilityCompany?: boolean;
      professionalLimitedLiabilityCompany?: boolean;
      michiganCorporation?: boolean;
      michiganSubchapterSCorporation?: boolean;
      michiganProfessionalCorporation?: boolean;
      foreignCorporation?: boolean;
      foreignSubchapterSCorporation?: boolean;
      nonprofitCorporation?: boolean;
      government?: boolean;
      estate?: boolean;
      trust?: boolean;
      other?: boolean;
    },
    member?: Array<IMember>,
  };

  status?: string;
  pending?: boolean;

  constructor(business: BusinessModel) {
    this.id = business.id;
    this.businessId = business.id;
    this.name = business.name;
    this.dba = business.dba;
    this.fein = business.fein;
    this.accountNumber = business.accountNumber;
    this.pin = business.pin;
    this.contact = business.contact;
    this.email = business.email;
    this.phone = business.phone;
    this.filingSchedule = business.filingSchedule;
    this.fax = business.fax;
    this.address = business.address;
    this.mailingAddress = business.mailingAddress;
    this.cityAddress = business.cityAddress;
    this.ss4 = business.ss4;
    this.status = business.status;
    this.pending = business.pending;
    this.paymentMethods = business.paymentMethods;
    this.incorporationDate = business.incorporationDate;
    this.businessOwnership = business.businessOwnership;
    this.emailNotifications = business.emailNotifications;
  }
}
