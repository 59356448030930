import {WorksheetAHelper} from "./worksheet-a.helper";
import {WorksheetBHelper} from "./worksheet-b.helper";
import {WorksheetCHelper} from "./worksheet-c.helper";
import * as moment from "moment/moment";
import {PaymentHelper} from "@city-tax/shared";


export class OhIndividualReturnHelper {
  private readonly ir: any;
  private readonly filingCity: any;
  private readonly worksheetA: WorksheetAHelper;
  private readonly worksheetB: WorksheetBHelper;
  private readonly worksheetC: WorksheetCHelper;

  constructor(private paymentHelper, filingCity, ir) {
    this.ir = ir;
    this.filingCity = filingCity;
    this.paymentHelper = new PaymentHelper();
    this.worksheetA = new WorksheetAHelper(paymentHelper, this.filingCity, this.ir, this.isResident, ir.page2?.worksheetA, this.isPartYear);
    this.worksheetB = new WorksheetBHelper(paymentHelper, ir.taxYear, this.filingCity, this.isResident, ir.page2.worksheetB);
    this.worksheetC = new WorksheetCHelper(paymentHelper, ir.page2.worksheetC);
  }

  get otherCity() {
    return this.worksheetB.otherCity;
  }


  get page2() {
    return {
      worksheetA: this.worksheetA,
      worksheetB: this.worksheetB,
      worksheetC: this.worksheetC,
    };
  }

  get isResident() {
    if (this.ir.residency === 'R' || this.ir.residency === 'P') return true;
    return false;
  }

  get isPartYear() {
    return this.ir.residency === 'P';
  }

  get requiresEstimate() {
    const estimatedTaxPaid = this.paymentHelper.decimalValue(this.ir.estimatedTaxPaid);
    if (!this.isResident) return false;
    if (this.taxDue > 200) return true;
    return this.taxDue > 100 && estimatedTaxPaid >= 150;
  }

  get taxYear() {
    return this.ir.taxYear;
  }

  //line 1
  get wagesSalariesTips() {
    return this.worksheetA?.grandTotalQualifyingWages;
  }

  //line 2
  get otherIncome() {
    return this.ir.totalMunicipalityTaxableIncome || this.paymentHelper.decimalValue(this.worksheetB.totalMunicipalityTaxableIncome);
  }

  //line 3
  get totalIncome() {
    const otherIncome = this.otherIncome < 0 ? 0 : this.otherIncome;
    const value = (this.paymentHelper.decimalValue(this.wagesSalariesTips) + otherIncome);
    return value;
  }


  //line 4
  get adjustments() {
    return this.worksheetC.totalAdjustmentToIncome;
  }

  //line 5
  get taxableIncome() {
    const total = this.totalIncome + this.adjustments;
    return total > 0 ? total : 0;
  }

  //line 6
  get multiplyTaxableIncome() {
    //  console.log('<<<<<<<<<<<<<<<<', this.taxableIncome, this.filingCity?.components?.individual?.taxRate);
    return +(this.taxableIncome * +this.filingCity?.components?.individual?.taxRate || 0).toFixed(2);
  }

  //grandTotalFillingCityTaxWithheld
  //line 7 a
  get grandTotalFillingCityTaxWithheld() {
    return this.worksheetA?.grandTotalFillingCityTaxWithheld;
  }


//grandTotalOtherCityTaxWithheld
  //line 7 b
  get grandTotalOtherCityTaxWithheld() {
    return this.worksheetA?.grandTotalOtherCityTaxWithheld;
  }

  //line 7 c
  get totalTaxCreditAllowedForTaxPaidToOtherCities() {
    const total = this.worksheetB.totalTaxCreditAllowedForTaxPaidToOtherCities;
    const max = (this.otherIncome * +this.filingCity?.components?.individual?.taxRate || 0);
    return this.paymentHelper.decimalValue(total > max ? max < 0 ? 0 : max : total < 0 ? 0 : total);
  }

  get residentHomeownerCreditAmount() {
    if (!this.isResident || this.filingCity?.name !== 'Mason' || !this.ir.ownProperty) return 0;
    const residentHomeownerCreditRate = this.filingCity?.components?.individual?.residentHomeownerCreditRate || 0.0012;
    return this.paymentHelper.decimalValue(this.taxableIncome * residentHomeownerCreditRate);
  }

  //line 7 e
  get totalCreditsAllowable() {
    // console.log({
    //   grandTotalFillingCityTaxWithheld: this.grandTotalFillingCityTaxWithheld,
    //   totalTaxCreditAllowedForTaxPaidToOtherCities: this.totalTaxCreditAllowedForTaxPaidToOtherCities,
    //   grandTotalOtherCityTaxWithheld: this.grandTotalOtherCityTaxWithheld
    // })
    return (this.grandTotalFillingCityTaxWithheld + this.totalTaxCreditAllowedForTaxPaidToOtherCities + this.grandTotalOtherCityTaxWithheld + this.residentHomeownerCreditAmount);
  }

  get taxDueCalculated() {
    // console.log(this.multiplyTaxableIncome, this.totalCreditsAllowable, this.residentHomeownerCreditAmount);
    return this.multiplyTaxableIncome - this.totalCreditsAllowable;
  }

  //line 8 taxDue
  get taxDue() {
    // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', this.multiplyTaxableIncome, this.totalCreditsAllowable, this.residentHomeownerCreditAmount)
    const taxDue = this.multiplyTaxableIncome - this.totalCreditsAllowable;
    return (taxDue < this.filingCity?.components?.individual?.minAmountOfTaxDue ? 0 : taxDue);
  }

  //line 9 Estimated Tax Paid  Prior year overpayment
  //creditCarryForwardPreviousYear + estimatesPaidCurrentYear
  get estimatedTaxPaidAndPriorYearOverpayment() {
    if (this.ir.estimatedTaxPaidAndPriorYearOverpayment && this.paymentHelper.decimalValue(this.ir.estimatedTaxPaidAndPriorYearOverpayment) > 0) return this.ir.penalty;
    const priorYearOverpayment = this.paymentHelper.decimalValue(this.ir.priorYearOverpayment);
    const estimatedTaxPaid = this.paymentHelper.decimalValue(this?.ir.estimatedTaxPaid);

    return (estimatedTaxPaid + priorYearOverpayment);
  }

  get totalDueAfterPaymentsCalculated() {
    return (this.taxDueCalculated - this.estimatedTaxPaidAndPriorYearOverpayment);
  }

  //line 10 totalDueAfterPayments : 7,
  get totalDueAfterPayments() {
    // console.log('+++++++++++', this.taxDue, this.estimatedTaxPaidAndPriorYearOverpayment, (this.taxDue - this.estimatedTaxPaidAndPriorYearOverpayment))
    const value = (this.taxDue - this.estimatedTaxPaidAndPriorYearOverpayment);
    // console.log({
    //   taxDue: this.taxDue,
    //   estimatedTaxPaidAndPriorYearOverpayment: this.estimatedTaxPaidAndPriorYearOverpayment
    // });
    return value < this.filingCity?.components?.individual?.minAmountOfTaxDue ? 0 : value;
  }

  calculateMonthsLate = () => {
    const maxMonths = 4;
    const currentDate = this.ir.filingDate ? moment(this.ir.filingDate) : moment();
    if (this.eFileDeadline.isSameOrAfter(currentDate)) return 0;
    let monthsLate = currentDate.diff(this.eFileDeadline, 'months');
    if (monthsLate === 0) monthsLate = 1;
    return maxMonths ? Math.min(monthsLate, maxMonths) : monthsLate;
  }

  calculateUnderpaymentInterest = () => {
    if (this.filingCity.name.toLowerCase() !== 'mason' || !this.isResident || this.taxDue < 200) return 0;
    const interestRate = 0.0083;
    const underpaymentMonthsLate = this.calculateMonthsLate();
    console.log('underpaymentMonthsLate', underpaymentMonthsLate);
    console.log(this.taxDue, this.taxDue, '90%', (this.taxDue * 0.9) - this.estimatedTaxPaidAndPriorYearOverpayment);
    return this.paymentHelper.decimalValue(underpaymentMonthsLate * ((this.taxDue * 0.9) - this.estimatedTaxPaidAndPriorYearOverpayment) * interestRate, 2);
  }

  calculateMasonPenalty() {
    if (this.filingCity.name.toLowerCase() !== 'mason' || !this.isResident) return 0;
    const lateFilingPenaltyPerMonth = 25;
    const maxLateFilingPenalty = 150;
    const filingMonthsLate = this.calculateMonthsLate();
    return Math.min(filingMonthsLate * lateFilingPenaltyPerMonth, maxLateFilingPenalty);
  }

  get lateFee() {
    if (this.ir.extension) return 0;
    if (!this.isLate) return 0;
    if (this.ir.lateFee && this.ir.lateFee > 0) return this.ir.lateFee;
    return this.filingCity?.components?.individual?.lateFee || 0;
  }

  get penalty() {
    if (this.ir.extension) return 0;
    if (!this.isLate) return 0;

    if (this.filingCity?.components?.individual?.penalty && this.totalDueAfterPaymentsCalculated > this.filingCity?.components?.individual?.minAmountOfTaxDue) {
      const penalty = this.paymentHelper.decimalValue(this.filingCity?.components?.individual?.penalty);
      // const masonPenalty = this.calculateMasonPenalty();
      const penaltyPercent = this.paymentHelper.decimalValue(this.totalDueAfterPayments * penalty);

      console.log('penaltyPercent', penaltyPercent,);
      return this.paymentHelper.decimalValue(penaltyPercent);
    }
    return 0;
  }

  get interest() {
    if (this.ir.extension) return 0;
    if (!this.isLate) return 0;
    if (this.filingCity?.components?.individual?.interest && this.totalDueAfterPayments > this.filingCity?.components?.individual?.minAmountOfTaxDue) {
      const underpaymentInterest = this.calculateUnderpaymentInterest();
      const dailyRate = this.paymentHelper.decimalValue(+this.filingCity?.components?.individual?.interest / 365, 6)
      const filingDate = this.ir.filingDate ? moment(this.ir.filingDate) : moment();
      const days = filingDate.diff(this.eFileDeadline, 'days');
      console.log('this.totalDueAfterPayments', this.totalDueAfterPayments);
      const interest = this.paymentHelper.decimalValue(this.totalDueAfterPayments * dailyRate * days);
      console.log('underpaymentInterest', underpaymentInterest, 'interest', interest, 'total', interest + underpaymentInterest);
      return this.paymentHelper.decimalValue(interest + underpaymentInterest);
    }
    return 0;
  }

  get totalPenaltyAndInterest() {
    if (this.ir.extension) return 0;
    // console.log({
    //   penalty: this.paymentHelper.decimalValue(this.penalty),
    //   interest: this.paymentHelper.decimalValue(this.interest)
    // })
    return (this.paymentHelper.decimalValue(this.penalty) + this.paymentHelper.decimalValue(this.interest));
  }

  //line 12
  get totalAmountDue() {
    const totalDue = this.paymentHelper.decimalValue(this.totalDueAfterPaymentsCalculated) + this.paymentHelper.decimalValue(this.totalPenaltyAndInterest) + this.paymentHelper.decimalValue(this.lateFee);
    // console.log('totalAmountDue', {
    //   totalDueAfterPayments: this.totalDueAfterPayments,
    //   totalPenaltyAndInterest: this.totalPenaltyAndInterest,
    //   lateFee: this.lateFee
    // })
    return (totalDue < this.filingCity?.components?.individual?.minAmountOfTaxDue ? 0 : totalDue);
  }

  //line 13
  get overpayment() {
    let overpayment = this.totalDueAfterPaymentsCalculated < 0 ? Math.abs(this.totalDueAfterPaymentsCalculated) : 0;
    overpayment -= (this.totalPenaltyAndInterest + this.paymentHelper.decimalValue(this.lateFee));
    return (overpayment > +this.filingCity?.components?.individual?.minAmountOfTaxDue ? overpayment : null);
  }

  //Line 14
  get overpaymentToBeRefunded() {
    //TODO PUT BACK AFTER THIS YEAR
    // return this.ir.overpaymentToBeRefunded || this.paymentHelper.decimalValue(this.ir.overpaymentToBeRefunded);
    return this.paymentHelper.decimalValue(this.ir.overpaymentToBeRefunded);
  }

  //Line 15
  get overpaymentToBeCredited() {
    //TODO PUT BACK AFTER THIS YEAR
    //return this.ir.overpaymentToBeCredited || this.paymentHelper.decimalValue(this.ir.overpaymentToBeCredited);
    return this.paymentHelper.decimalValue(this.ir.overpaymentToBeCredited);
  }

  //Line 16
  get totalEstimatedIncomeSubjectToTax() {
    //TODO PUT BACK AFTER THIS YEAR
    //return this.ir.totalEstimatedIncomeSubjectToTax || this.taxableIncome;
    return this.taxableIncome;
  }

  //Line 17
  get estimatedIncomeTax() {
    return this.ir.estimatedIncomeTax || this.multiplyTaxableIncome;
  }

  //Line 18
  get lessExpectedTaxCredits() {
    return this.ir.lessExpectedTaxCredits || this.totalCreditsAllowable;
  }

  //Line 19 a
  get netTaxDue() {
    //TODO ADD BACK IN return this.ir.netTaxDue || this.taxDue;
    return this.taxDue;
  }

  //Line 19 b
  get netOverpaymentToBeCredited() {
    return this.ir.netOverpaymentToBeCredited || this.overpaymentToBeCredited;
  }

  //Line 20
  get amountDueWithThisDeclaration() {
    if (!this.requiresEstimate) return 0;
    const value = this.paymentHelper.decimalValue((this.paymentHelper.decimalValue(this.netTaxDue) - this.paymentHelper.decimalValue(this.netOverpaymentToBeCredited)) / 4);
    // console.log('amountDueWithThisDeclaration', value);
    return value > 0 ? value : 0
  }

  get amountDueWithThisDeclarationMinimum() {
    if (!this.requiresEstimate) return 0;
    const amount = this.paymentHelper.decimalValue(this.netTaxDue) - this.paymentHelper.decimalValue(this.netOverpaymentToBeCredited);
    return this.paymentHelper.decimalValue((amount / 4));
  }

  //Line 21 totalOfThisPayment
  get totalOfThisPayment() {
    return this.paymentHelper.decimalValue((this.paymentHelper.decimalValue(this.amountDueWithThisDeclaration) + this.paymentHelper.decimalValue(this.totalAmountDue)));
  }

  get schedulesY() {
    return this.worksheetB.getSchedulesY();
  }

  get isLate() {
    const filingDate = this.ir.filingDate ? moment.utc(this.ir.filingDate) : moment.utc();
    return filingDate.isAfter(this.eFileDeadline, 'days');
  }

  get eFileDeadline() {
    const fileYear = this.ir.taxYear + 1;
    const deadline = this.filingCity?.components?.individual?.eFileDeadline;
    const deadlineDate = deadline ? moment.utc(deadline) : null;
    const date = moment.utc().set('month', 3).endOf('month');
    if (deadlineDate && deadlineDate.year() === fileYear) {
      return moment.utc(deadlineDate).endOf('day');
    }
    date.set('year', fileYear);
    const dayOfWeek = date.day();
    const addDays = dayOfWeek === 6 ? 2 : dayOfWeek === 0 ? 1 : 0;
    return date.add(addDays, 'days').endOf('day');
  }

  get irForm() {
    const ir = this.ir;
    ir.taxForms = ir.taxForms || {};

    if (this.worksheetA.hasWages) {
      ir.taxForms.w2 = ir.taxForms.w2 || {
        required: false,
        provided: null,
      };
      ir.taxForms.w2.required = true;
    }

    ir.taxForms.otherCity = ir.taxForms.otherCity || {
      required: false,
      provided: null,
    };

    ir.taxForms.federal = ir.taxForms.federal || {
      required: false,
      provided: null,
    };

    ir.taxForms.federal.required = !ir?.page2?.noTaxableIncome;

    ir.wagesSalariesTips = this.wagesSalariesTips;
    ir.estimatedTaxPaid = this.paymentHelper.decimalValue(ir.estimatedTaxPaid);
    ir.priorYearOverpayment = this.paymentHelper.decimalValue(ir.priorYearOverpayment);
    ir.otherIncome = this.otherIncome;
    ir.totalIncome = this.totalIncome;
    ir.adjustments = this.adjustments;
    ir.taxableIncome = this.taxableIncome;
    ir.multiplyTaxableIncome = this.multiplyTaxableIncome;
    ir.totalCreditsAllowable = this.totalCreditsAllowable;
    ir.taxDue = this.taxDue;
    ir.totalEstimatedTaxPaidAndPriorYearOverpayment = this.estimatedTaxPaidAndPriorYearOverpayment;
    ir.totalDueAfterPayments = this.totalDueAfterPayments;
    ir.lateFillingFee = this.lateFee;
    ir.totalPenaltyAndInterest = this.totalPenaltyAndInterest;
    ir.totalAmountDue = this.totalAmountDue;
    ir.overpayment = this.overpayment;
    ir.overpaymentToBeRefunded = this.overpaymentToBeRefunded;
    ir.penalty = this.penalty;
    ir.interest = this.interest;
    ir.totalEstimatedIncomeSubjectToTax = this.totalEstimatedIncomeSubjectToTax;
    ir.estimatedIncomeTax = this.estimatedIncomeTax;
    ir.lessExpectedTaxCredits = this.lessExpectedTaxCredits;
    ir.netTaxDue = this.netTaxDue;
    ir.overpaymentToBeCredited = this.overpaymentToBeCredited;
    ir.amountDueWithThisDeclaration = this.amountDueWithThisDeclaration;
    ir.totalOfThisPayment = this.totalOfThisPayment;

    ir.residentHomeownerCreditAmount = this.residentHomeownerCreditAmount;

    ir.taxForms.otherCity.required = this.otherCity || this.worksheetA.hasOtherCityWages;

    if (!this.requiresEstimate) {
      ir.totalEstimatedIncomeSubjectToTax = 0;
      ir.estimatedIncomeTax = 0;
      ir.lessExpectedTaxCredits = 0;
      ir.netTaxDue = 0;
      ir.overpaymentToBeCredited = 0;
      ir.amountDueWithThisDeclaration = 0;
    }

    ir.page2.worksheetA = ir.page2.worksheetA || {};

    ir.page2.worksheetA.grandTotalFillingCityTaxWithheld = this.worksheetA.grandTotalFillingCityTaxWithheld;
    ir.page2.worksheetA.grandTotalQualifyingWages = this.worksheetA.grandTotalQualifyingWages;
    ir.page2.worksheetA.grandTotalOtherCityTaxWithheld = this.worksheetA.grandTotalOtherCityTaxWithheld;
    ir.page2.worksheetA.grandTotalTaxCreditAllowableOtherCities = this.worksheetA.grandTotalTaxCreditAllowableOtherCities;
    ir.page2.worksheetA.grandTotalScheduleExcludibleWages = this.worksheetA.grandTotalScheduleExcludibleWages;
    ir.page2.worksheetA.grandTotalWages = this.worksheetA.grandTotalWages;
    ir.page2.worksheetA.grandTotalScheduleTaxableWages = this.worksheetA?.grandTotalScheduleTaxableWages;
    ir.page2.worksheetA.grandTotalBox1Wages = this.worksheetA.grandTotalBox1Wages;

    ir.page2.worksheetB = ir.page2.worksheetB || {};

    ir.page2.worksheetB.totalIncomeOrLoss = this.worksheetB.totalIncomeOrLoss;
    ir.page2.worksheetB.totalMunicipalityTaxableIncome = this.worksheetB.totalMunicipalityTaxableIncome;
    ir.page2.worksheetB.totalTaxCreditAllowedForTaxPaidToOtherCities = this.totalTaxCreditAllowedForTaxPaidToOtherCities;

    ir.page2.worksheetB.totalIncomeLossScheduleC = this.worksheetB.scheduleC?.totalIncomeLoss;
    ir.page2.worksheetB.municipalityPercentageScheduleC = this.worksheetB.scheduleC?.municipalityPercentage;
    ir.page2.worksheetB.municipalityTaxableIncomeScheduleC = this.worksheetB.scheduleC?.municipalityTaxableIncome;
    ir.page2.worksheetB.taxCreditAllowedForTaxPaidToOtherCitiesScheduleC = this.worksheetB.scheduleC?.taxCreditAllowed;

    ir.page2.worksheetB.totalIncomeLossScheduleK1 = this.worksheetB.scheduleK1?.totalIncomeLoss;
    ir.page2.worksheetB.municipalityPercentageScheduleK1 = this.worksheetB.scheduleK1?.municipalityPercentage;
    ir.page2.worksheetB.municipalityTaxableIncomeScheduleK1 = this.worksheetB.scheduleK1?.municipalityTaxableIncome;
    ir.page2.worksheetB.taxCreditAllowedForTaxPaidToOtherCitiesScheduleK1 = this.worksheetB.scheduleK1?.taxCreditAllowed;

    ir.page2.worksheetB.totalIncomeLossScheduleE = this.worksheetB.scheduleE?.totalIncomeLoss;
    ir.page2.worksheetB.municipalityPercentageScheduleE = this.worksheetB.scheduleE?.municipalityPercentage;
    ir.page2.worksheetB.municipalityTaxableIncomeScheduleE = this.worksheetB.scheduleE?.municipalityTaxableIncome;
    ir.page2.worksheetB.taxCreditAllowedForTaxPaidToOtherCitiesScheduleE = this.worksheetB.scheduleE?.taxCreditAllowed;

    ir.page2.worksheetB.totalNetOperatingLossCarryForward = this.worksheetB.lossCarryForward?.netOperatingLossCarryForward;
    ir.page2.worksheetB.municipalityPercentageNetOperatingLossCarryForward = this.worksheetB.lossCarryForward?.municipalityPercentage;
    ir.page2.worksheetB.municipalityTaxableIncomeNetOperatingLossCarryForward = this.worksheetB.lossCarryForward?.municipalityLossCarryForward;
    ir.page2.worksheetB.taxCreditAllowedForTaxPaidToOtherCitiesNetOperatingLossCarryForward = this.worksheetB.lossCarryForward?.taxCreditAllowed;

    ir.page2.worksheetB.totalIncomeLossMisc = this.worksheetB.scheduleMisc?.totalIncomeLoss;
    ir.page2.worksheetB.municipalityPercentageMisc = this.worksheetB.scheduleMisc?.municipalityPercentage;
    ir.page2.worksheetB.municipalityTaxableIncomeMisc = this.worksheetB.scheduleMisc?.municipalityTaxableIncome;
    ir.page2.worksheetB.taxCreditAllowedForTaxPaidToOtherCitiesMisc = this.worksheetB.scheduleMisc?.taxCreditAllowed;

    ir.page2.worksheetC = ir.page2.worksheetC || {};
    ir.page2.worksheetC.totalColumn1Adjustment = this.worksheetC?.totalAdjustmentToIncome;

    return ir;
  }
}
