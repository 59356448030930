import * as moment from "moment";

export class PartYearHelper {
  private readonly taxYear: any;

  constructor(private paymentHelper, taxYear) {
    this.taxYear = taxYear;
  }

  daysWorkedAsNonResident(livedFrom, livedTo, workedFrom, workedTo) {
    workedFrom = this.paymentHelper.getStartDate(workedFrom, true, this.taxYear);
    workedTo = this.paymentHelper.getEndDate(workedTo, true, this.taxYear);

    livedFrom = this.paymentHelper.getStartDate(livedFrom, true, this.taxYear);
    livedTo = this.paymentHelper.getEndDate(livedTo, true, this.taxYear);

    // console.log({
    //   livedFrom: livedFrom.format(),
    //   livedTo: livedTo.format(),
    //   workedFrom: workedFrom.format(),
    //   workedTo: workedTo.format()
    // });

    const overlapFrom = moment.max(workedFrom, livedFrom);
    const overlapTo = moment.min(workedTo, livedTo);
    // console.log('overlapFrom >>>>>>', overlapFrom.format())
    // console.log('overlapTo >>>>>>', overlapTo.format())

    // Calculate the number of days in the overlapping period
    const daysInOverlap = overlapTo.diff(overlapFrom, 'days');

    // console.log('daysInOverlap >>>>>>', daysInOverlap)

    if (daysInOverlap < 0) {
      return 0;
    }

    // Calculate the total number of days worked
    const totalDaysWorked = workedTo.diff(workedFrom, 'days');
    // console.log('totalDaysWorked >>>>>>', totalDaysWorked)

    // Calculate the number of days worked as a non-resident
    const daysWorkedAsNonResident = totalDaysWorked - daysInOverlap;

    // console.log('daysWorkedAsNonResident', daysWorkedAsNonResident)
    return Math.abs(daysWorkedAsNonResident);
  }

  daysWorked(workedFrom, workedTo) {
    const workedFromDate = this.paymentHelper.getStartDate(workedFrom, true, this.taxYear);
    const workedToDate = this.paymentHelper.getEndDate(workedTo, true, this.taxYear);
    // console.log('daysWorked', workedToDate.diff(workedFromDate, 'days'));
    return workedToDate.diff(workedFromDate, 'days');
  }

  getDaysBefore(livedFrom, workedFrom) {
    const livedFromDate = this.paymentHelper.getStartDate(livedFrom, true, this.taxYear);
    const workedFromDate = this.paymentHelper.getStartDate(workedFrom, true, this.taxYear);

    if (livedFromDate.isBefore(workedFromDate)) {
      return workedFromDate.diff(livedFromDate, 'days');
    }
    return livedFromDate.diff(workedFromDate, 'days');
  }

  getDaysAfter(livedTo, workedTo) {
    const livedToDate = this.paymentHelper.getEndDate(livedTo, true, this.taxYear);
    const workedToDate = this.paymentHelper.getEndDate(workedTo, true, this.taxYear);

    console.log()

    if (livedToDate.isAfter(workedToDate)) {
      return workedToDate.diff(livedToDate, 'days');
    }
    return livedToDate.diff(workedToDate, 'days');
  }

  getDaysInYear() {
    return moment.utc(+this.taxYear, "YYYY").endOf("year").dayOfYear();
  }

  getDaysUnder18(dateWorkedFrom, dateWorkedTo, dob, year = 18) {
    const from = dateWorkedFrom ? moment.utc(dateWorkedFrom, "YYYY-MM-DD").startOf("day") : moment.utc(+this.taxYear, "YYYY").startOf("year");
    const to = dateWorkedTo ? moment.utc(dateWorkedTo, "YYYY-MM-DD").add(1).startOf("day") : moment.utc(+this.taxYear + 1, "YYYY").startOf("year");
    const eighteenthBirthday = dob ? moment.utc(dob, "YYYY-MM-DD").add(year, 'years').add(1).startOf("day") : moment.utc();
    const daysInYear = this.getDaysInYear();
    const daysUnder18 = eighteenthBirthday.isAfter(to) ? daysInYear : eighteenthBirthday.diff(from, "days");

    // console.log('daysInYear', daysInYear)
    // console.log('dateWorkedFrom', from.format())
    // console.log('dateWorkedTo', to.format())
    // console.log('dob input', dob);
    // console.log('birthdate', eighteenthBirthday.format())
    // console.log('birthdate is after', eighteenthBirthday.isAfter(to))
    // console.log('diff after', to.diff(from, 'days'))
    // console.log('diff before', eighteenthBirthday.diff(from, 'days'))
    // console.log('daysUnder18', daysUnder18);
    return daysUnder18;
  }
}
