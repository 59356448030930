import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {HexaDetailComponent} from "@hexalang/ui/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {EPermission} from "@city-tax/shared";
import {BehaviorSubject, Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {IndividualService} from "../../../../../services/individual.service";
import {AuthService} from "@city-tax/features/auth";
import {NgxPermissionsService} from "ngx-permissions";
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: "city-tax-feature-individual-filing-oh-adjustment-income-form",
    templateUrl: "./individual-filing-oh-adjustment-income-form.component.html",
    styleUrls: ["./individual-filing-oh-adjustment-income-form.component.scss"],
})
export class IndividualFilingOhAdjustmentIncomeFormComponent
    extends HexaDetailComponent
    implements OnInit {
    public adjustmentform: FormGroup;
    public title$;
    public taxYear = this.individualService.taxYear;
    public currentYear = this.individualService.currentYear;
    public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public ePermission = EPermission;
    public hasPermission$: Observable<boolean>;
    public isLoading$ = this.individualService.isLoading$;
    public isUploading$ = this.individualService.isUploading$;
    public error = null;
    public taxDocument = this.authService.organization.taxDocument;
    public city = this.authService.config;
    public individualReturn$ = this.individualService.individualReturn$;
    public individualReturn = this.individualService.individualReturn;


    changeDetectorRef: any;
    @Input("isEdit") isEdit: boolean = false;
    @Input("row") row: any;
    @Output("updateAdjustmentIncomeForm") updateAdjustmentIncomeForm =
        new EventEmitter();
    @Output("addAdjustmentIncomeForm") addAdjustmentIncomeForm =
        new EventEmitter();
    types = ["taxpayer", "spouse"];

    constructor(
        injector: Injector,
        private router: Router,
        private route: ActivatedRoute,
        private individualService: IndividualService,
        private authService: AuthService,
        public permissionService: NgxPermissionsService,
        private formBuilder: FormBuilder,
        private modal: BsModalService
    ) {
        super(injector);
    }

    ngOnInit(): void {
        //this.checkPermission();
        this.adjustmentform = this.formBuilder.group({
            id: null,
            adjustmentType: null,
            explanation: [null, Validators.required],
            column1Adjustment: [null, Validators.required],
        });
        if (this.row) {
            this.adjustmentform.patchValue(this.row);
        }
    }


    update() {
        const payload = this.adjustmentform.getRawValue();
        if (payload.column1Adjustment > 0) {
            payload.column1Adjustment = +payload.column1Adjustment * -1;
        }
        this.updateAdjustmentIncomeForm.emit(payload);
    }

    add() {
        const payload = this.adjustmentform.getRawValue();
        if (payload.column1Adjustment > 0) {
            payload.column1Adjustment = +payload.column1Adjustment * -1;
        }
        this.addAdjustmentIncomeForm.emit(payload);
    }

    hide() {
        this.modal?.hide();
    }

    submit() {
        if (this.adjustmentform.valid) {
            if (this.isEdit) {
                this.update();
            } else {
                this.add();
            }
        } else {
            this.adjustmentform.markAllAsTouched();
        }
    }
}
