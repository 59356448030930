import {Injectable} from '@angular/core';
import {CurrencyPipe, DatePipe, DecimalPipe} from "@angular/common";
import {FeinPipe, FileSizePipe, PhonePipe, SsnPipe} from "@hexalang/ui/shared";
import * as moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class FormatService {

  constructor(
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private feinPipe: FeinPipe,
    private decimalPipe: DecimalPipe,
    private phonePipe: PhonePipe,
    private ssnPipe: SsnPipe,
    private fileSizePipe: FileSizePipe
  ) {
  }

  dateFormat(value: any, pattern = 'MM/dd/yyyy') {
    return this.datePipe.transform(value, pattern);
  }

  currencyFormat(value: any) {
    return this.currencyPipe.transform(value);
  }

  numberFormat(value: any, digitsInfo = '1.0-2') {
    return this.decimalPipe.transform(value, digitsInfo);
  }

  integerFormat(value: any) {
    return this.decimalPipe.transform(value, '1.0-0');
  }

  ssnFormat(value: any) {
    return this.ssnPipe.transform(value);
  }

  feinFormat(value: any) {
    return this.feinPipe.transform(value);
  }

  phoneFormat(value: any) {
    return this.phonePipe.transform(value);
  }

  fileSizeFormat(value: any) {
    return this.fileSizePipe.transform(value);
  }

  toDateString(value: any,) {
    if (!value) return value;
    if (typeof value === 'string') {
      const format = value.includes('/') ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
      return moment.utc(value, 'MM/DD/YYYY').format('YYYY-MM-DD');
    }
    return moment.utc(value).format('YYYY-MM-DD');
  }

  ccIcon(value: any) {
    switch (value) {
      case 'visa':
        return 'fa-cc-visa';
      case 'mastercard':
        return 'fa-cc-mastercard';
      case 'amex':
        return 'fa-cc-amex';
      case 'discover':
        return 'fa-cc-discover';
      default:
        return 'fa-credit-card';
    }
  }

}
