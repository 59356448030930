import {AfterViewInit, Component, Injector, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {HexaListComponent, HexaTitleService, HexaWithPermissions} from "@hexalang/ui/core";
import {NgxPermissionsService} from "ngx-permissions";
import {ToastrService} from "ngx-toastr";
import {FormBuilder} from "@angular/forms";
import {AuthService} from "@city-tax/features/auth";
import {Router} from "@angular/router";
import {EPermission, FormatService} from "@city-tax/shared";
import {IndividualService} from "../../services/individual.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'city-tax-feature-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent
  extends HexaWithPermissions(HexaListComponent)
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("statusTemplate") public statusTemplate: TemplateRef<any>;
  @ViewChild("message") public message: TemplateRef<any>;
  currentModalValue: string;

  public notifications$ = this.individualService.notifications$
  public isLoading$ = this.individualService.isLoading$;

  public bsModalRef: BsModalRef;

  protected readonly EPermission = EPermission;

  constructor(
    injector: Injector,
    public titleService: HexaTitleService,
    public permissionService: NgxPermissionsService,
    public toastrService: ToastrService,
    private individualService: IndividualService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private authService: AuthService,
    private translate: TranslateService,
    public router: Router,
    public formatService: FormatService,
  ) {
    super(injector, {dataGrid: 'IndividualNotifications'});
    this.titleService.changeTitle(this.translate.instant("Taxpayer.notifications"));
    this.translate.onLangChange.subscribe(val => {
      this.titleService.changeTitle(
        this.translate.instant("Taxpayer.notifications")
      )
    })

  }

  async ngOnInit() {
    this.formFilter = this.formBuilder.group({
      search: null,
    });
    await this.getNotifications();
  }

  ngAfterViewInit() {
    this.defineColumns()
  }

  public async read(id: string) {
    await this.individualService.read(this.authService.organization.id, id)
    await this.getNotifications();
    await this.authService.getAlerts();
  }

  defineColumns() {
    this.columns = [
      {
        prop: "status",
        name: "Alert",
        sortable: false,
        minWidth: 100,
        maxWidth: 100,
        cellTemplate: this.statusTemplate,
      },
      {
        prop: "createdAt",
        name: "Created",
        minWidth: 95,
        maxWidth: 95,
        sortable: false,
        pipe: {transform: (value: any) => this.formatService.dateFormat(value)},
      },
      {
        prop: 'message',
        name: 'Message',
        resizeable: true,
        sortable: true,
        minWidth: 200,
        maxWidth: 2000,
        cellTemplate: this.message
      },
    ];
  }

  private async getNotifications() {
    this.commonCombineNoOrgLatest()
      .pipe(
        this.commonPipe((resp: any) => {
          const [formFilterValue] = resp;
          this.preparePagingParams(
            null,
            this.oDataQuery(formFilterValue)
          );
          return this.individualService.getNotifications(
            this.authService.organization.id,
            this.pagingParams
          )
        })
      )
      .subscribe();
  }

  private oDataQuery(formValue: any): Array<string> {
    const oDataQuery = [];
    const formattedFormValue: any = {};

    if (formValue && formValue.search) {
      const search = formValue.search.trim().replace(/'/g, "''");
      formattedFormValue.search = `(substringof('${search}', 'message') eq true )`;
    }

    Object.keys(formattedFormValue).forEach((key) => {
      if (formattedFormValue[key]) {
        oDataQuery.push(formattedFormValue[key]);
      }
    });

    return oDataQuery;
  }

  open(template: TemplateRef<any>, value: string) {
    this.currentModalValue = value;
    this.bsModalRef = this.modalService.show(template, {
      class: 'modal-lg',
    });
  }
}
