import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HexaDetailComponent} from "@hexalang/ui/core";
import {FormGroup} from "@angular/forms";
import {BehaviorSubject, Subscription} from "rxjs";
import {IndividualService} from "../../../../../../services/individual.service";
import {PaymentHelper} from "@city-tax/shared";
import {AuthService} from "@city-tax/features/auth";

@Component({
  selector: 'city-tax-feature-mi-locality',
  templateUrl: './mi-locality.component.html',
  styleUrls: ['./mi-locality.component.scss']
})
export class MiLocalityComponent extends HexaDetailComponent implements OnInit, OnDestroy {
  @Input("locality") locality: any;
  @Input("localityFormGroup") localityFormGroup: FormGroup;
  @Input("count") count: number;
  @Input("multipleLocations") multipleLocations: boolean;
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public config = this.authService.config;
  localitiesSubs!: Subscription;
  public localities = [];
  public isLoading$ = this.individualService.isLoading$;
  public city = this.authService.organization.name;
  public individualReturn = this.individualService.individualReturn;


  constructor(
    injector: Injector,
    private individualService: IndividualService,
    private authService: AuthService,
    private paymentHelper: PaymentHelper
  ) {
    super(injector);
  }

  ngOnInit(): void {
    // this.getLocalities();
    // if (
    //   this.individualReturn.resident == true &&
    //   !this.multipleLocations &&
    //   this.localityFormGroup.get("taxWithheldLocalityName")?.value === this.city && this.count > 1
    // ) {
    //   this.localityFormGroup.get("wagesBox18").disable();
    // }

    // this.multipleLocations.valueChanges.subscribe(value => {
    //   if (
    //     this.individualReturn.resident == true &&
    //     !this.multipleLocations &&
    //     this.localityFormGroup.get("taxWithheldLocalityName")?.value === this.city && this.count > 1
    //   ) {
    //     this.localityFormGroup.get("wagesBox18").disable();
    //   }
    // });
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
    
      this.isDisabled$.next(true)
    }
  }


  ngOnDestroy(): void {
    this.localitiesSubs?.unsubscribe();
  }

  async onSearchDynamic(value: any) {
    try {
      if (value.term === "") {
        this.localities = [];
      } else {
        const res = await this.individualService.getLocalitySearch('MI',
          value.term
        );
        if (res) this.localities = res.data;
      }
    } catch (error) {

    }
  }

  onClearDynamic(): void {
    this.calculateCredit();
  }

  setValues(value: any): void {
    if (value) {
      const taxWithheldLocalityNameCtl = this.localityFormGroup.get("taxWithheldLocalityName");
      taxWithheldLocalityNameCtl.setValue(value.locality);
      this.locality.locality = value.locality;
      this.locality.taxRate = value.taxRate;
      if (this.individualReturn.resident === true && !this.multipleLocations && value.locality == this.city && this.count > 1) {
        this.localityFormGroup.get("wagesBox18").disable();
      } else {
        this.localityFormGroup.get("wagesBox18").enable();
      }

    } else {
      this.locality.locality = null;
      this.locality.taxRate = null;
    }
    // this.calculateCredit();
  }


  calculateCredit() {
    let creditForTaxesPaid = 0.0;
    const creditForTaxesPaidCtl = this.localityFormGroup.get("creditForTaxesPaid");
    const taxWithheldLocalityNameCtl = this.localityFormGroup.get(
      "taxWithheldLocalityName"
    );
    if (
      taxWithheldLocalityNameCtl.value !== this.config?.name &&
      taxWithheldLocalityNameCtl.value
    ) {
      const filingCityTaxRate = this.config?.components?.individual?.taxRate;
      const filingCityCreditRate = this.config?.components?.individual?.creditRate;
      const creditRate = filingCityCreditRate || filingCityTaxRate;
      if (creditRate) {
        const wagesBox18Ctl = this.localityFormGroup.get("wagesBox18");
        const taxWithheldBox19Ctl = this.localityFormGroup.get("taxWithheldBox19");
        const wagesBox18C = wagesBox18Ctl?.value;
        const taxWithheldBox19C = taxWithheldBox19Ctl?.value;
        if (wagesBox18C && taxWithheldBox19C) {
          const totalCredit = this.paymentHelper.decimalValue(wagesBox18C * creditRate);
          const creditForTaxes = this.paymentHelper.decimalValue(taxWithheldBox19C - totalCredit);
          creditForTaxesPaid = creditForTaxes < 0 ? taxWithheldBox19C : totalCredit;
        }
      }
    }
    creditForTaxesPaidCtl.setValue(`${creditForTaxesPaid}`);
  }
}
