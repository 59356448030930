import {Component, Injector, OnInit} from '@angular/core';
import {HexaDetailComponent} from "@hexalang/ui/core";
import {FormArray, FormBuilder, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {DateFormControl, DateRangeValidator, EPermission, FormatService} from "@city-tax/shared";
import {BehaviorSubject, forkJoin, Observable, takeUntil, tap} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {IndividualService} from "../../../../../services/individual.service";
import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {NgxPermissionsService} from "ngx-permissions";
import * as moment from "moment/moment";

@Component({
  selector: "city-tax-feature-start",
  templateUrl: "./individual-filing-mi-start.component.html",
  styleUrls: ["./individual-filing-mi-start.component.scss"],
})
export class IndividualFilingMiStartComponent
  extends HexaDetailComponent
  implements OnInit {
  public form: FormGroup;
  public title$;
  public taxYear = this.individualService.taxYear;
  public currentYear = this.individualService.currentYear;
  public ePermission = EPermission;
  public hasPermission$: Observable<boolean>;
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.individualService.isLoading$;
  public isUploading$ = this.individualService.isUploading$;
  public eFileDeadline = this.authService.eFileDeadline(this.taxYear);
  public error = null;
  public taxDocument = this.authService.organization.taxDocument;
  public city = this.authService.organization;
  public individualReturn$ = this.individualService.individualReturn$;
  public individualReturn = this.individualService.individualReturn;
  public minDate = moment(`${this.taxYear}-01-01`, 'YYYY-MM-DD').endOf('day').toDate();
  public maxDate = moment(`${this.taxYear}-12-31`, 'YYYY-MM-DD').endOf('day').toDate();
  public maxDDate = new Date();
  changeDetectorRef: any;

  constructor(
    injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private individualService: IndividualService,
    private authService: AuthService,
    public toastrService: ToastrService,
    public formatService: FormatService,
    public permissionService: NgxPermissionsService,
    private formBuilder: FormBuilder
  ) {
    super(injector);
  }

  get getScheduleOfWages(): FormArray {
    return this.form.get("wagesSalariesTips.scheduleOfWages") as FormArray;
  }

  async ngOnInit() {
    this.individualReturn$.subscribe((ir) => {
      this.initForm();
      this.patchForm();
    });
  }

  addScheduleOfWages() {
    const d: any = {
      employersFederalId: null,
      excludedWages: null,
      ssn: null,
      taxWithheldLocalityName: null,
      taxWithheldBox19: null,
      id: null,
      type: null,
    };
    this.individualReturn?.wagesSalariesTips?.spouseScheduleOfWages?.map(
      (u: any) => {
        u.type = "S";
        u.ssn = u.ssn ? u.ssn : null;
        u.employersFederalId = u.employersFederalId
          ? u.employersFederalId
          : null;
        u.excludedWages = u.excludedWages ? u.excludedWages : null;
        u.taxWithheldLocalityName = u.w2LocalityInfo?.taxWithheldLocalityName;
        u.taxWithheldBox19 = u.w2LocalityInfo?.taxWithheldBox19;
        u.employersAddress = this.addAddress(u.employersAddress);
        delete u.w2LocalityInfo;
        this.getScheduleOfWages?.push(this.formBuilder.group(u));
      }
    );

    this.individualReturn?.wagesSalariesTips?.taxpayerScheduleOfWages?.map(
      (u: any) => {
        u.type = "T";
        u.ssn = u.ssn ? u.ssn : null;
        u.employersFederalId = u.employersFederalId
          ? u.employersFederalId
          : null;
        u.excludedWages = u.excludedWages ? u.excludedWages : null;
        u.taxWithheldLocalityName = u.w2LocalityInfo?.taxWithheldLocalityName;
        u.taxWithheldBox19 = u.w2LocalityInfo?.taxWithheldBox19;
        u.employersAddress = this.addAddress(u.employersAddress);
        delete u.w2LocalityInfo;
        this.getScheduleOfWages?.push(this.formBuilder.group(u));
      }
    );
    // this.getScheduleOfWages.push(this.formBuilder.group(d));
  }

  addAddress(a) {
    return this.formBuilder.group({
      addressLine1: a?.addressLine1,
      addressLine2: a?.addressLine2,
      aptNumber: a?.aptNumber,
      city: a?.city,
      state: a?.state,
      postalCode: a?.postalCode,
    });
  }

  // async getReturn() {
  //   try {
  //     const ir = await this.individualService.getReturn(this.authService.organization.id, this.authService.config?.state);
  //
  //     if (ir.status === "Completed") {
  //       let path = `/individual/filings/${this.authService.config?.state.toLowerCase()}/${
  //         this.individualService.taxYear
  //       }/confirmation`;
  //       await this.router.navigate([path]);
  //     }
  //     this.patchForm();
  //   } catch (error) {
  //   }
  // }

  getFormValidationErrors(form) {
    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      if (control.controls) {
        this.getFormValidationErrors(control);
      }
      const controlErrors: ValidationErrors = control.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach((keyError) => {
          console.log(
            "Key control: " + key + ", keyError: " + keyError + ", err value: ",
            controlErrors[keyError]
          );
        });
      }
    });
  }

  formatEmail(email: string) {
    if (!email) return null;
    return email.replace(/(?<=^.)[^@]*|(?<=@.).*(?=\.[^.]+$)/gm, (match) =>
      "*".repeat(match.length)
    );
  }

  unlink() {
    this.individualReturn.spouse = null;
    this.form.get("spouse").reset({});
  }

  checkDateError(controlName: string) {
    return this.form.get(controlName)?.invalid || this.form.hasError('dateRangeInvalid') ? 'is-invalid' : '';
  }

  async next(needRedirection = true) {
    let path = `/individual/filings/mi/${this.individualService.taxYear}/page2`;

    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      await this.router.navigate([path]);
    } else {
      this.getFormValidationErrors(this.form);
      if (this.form.valid) {
        const payload = this.form.getRawValue();
        payload.source = 'Fill';
        payload.resident = payload.residency !== "N";
        if (payload.filing === 'S') {
          payload.spouse = null;
        }

        if (payload.filing === 'MS' && payload.spouse) {
          payload.spouse.individualId = null;
        }

        try {
          // await this.authService.updateIndividual(
          //     this.authService.organization.id,
          //     payload.taxpayer
          // );
          await this.individualService.updateReturn(
            this.authService.organization.id,
            payload
          );

          needRedirection && await this.router.navigate([path]);
        } catch (error) {
          console.log(error);
        }

      } else {
        this.form.markAllAsTouched();
      }
    }
  }

  async upload() {
    this.getFormValidationErrors(this.form);
    if (this.form.valid) {
      const payload = this.form.getRawValue();
      payload.source = 'Upload';
      payload.resident = payload.residency !== "N";
      return forkJoin([
        // this.authService.updateIndividual(this.authService.organization.id, payload.taxpayer),
        this.individualService.updateReturn(this.authService.organization.id, payload),
      ]).pipe(
        takeUntil(this.destroy$),
        tap(async () => {
          let path = `/individual/filings/${this.authService.config?.state.toLowerCase()}/${
            this.individualService.taxYear
          }`;
          path += "/upload";
          await this.router.navigate([path]);
        }),
      )
        .subscribe();
    } else {
      this.form.markAllAsTouched();
    }
  }

  private patchForm() {
    this.form.patchValue(this.individualService?.individualReturn);
    this.addScheduleOfWages();
    const filing = this.individualService.individualReturn?.filing;
    if (filing == "MJ" || filing == "MS") {
      this.form.get("spouse.name").addValidators([Validators.required]);
      this.form.get("spouse.ssn").addValidators([Validators.required]);
      this.form.get("spouse.ssn").updateValueAndValidity();
      this.form.get("spouse.name").updateValueAndValidity();
    } else {
      this.form.get("spouse.name").removeValidators([Validators.required]);
      this.form.get("spouse.ssn").removeValidators([Validators.required]);
      this.form.get("spouse.ssn").updateValueAndValidity();
      this.form.get("spouse.name").updateValueAndValidity();
    }
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {
      this.isDisabled$.next(true)
      this.form.disable();
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      id: null,
      eFileDeadline: null,
      taxpayer: this.formBuilder.group({
        address: this.formBuilder.group({
          addressLine1: null,
          addressLine2: null,
          aptNumber: null,
          city: null,
          state: null,
          postalCode: null,
        }),
        ssn: [null, Validators.required],
        name: [null, Validators.required],
        deceased: null,
        email: null,
        emailNotifications: null,
        birthdate: new DateFormControl(''),
        occupation: null,
        dateOfDeath: new DateFormControl(''),
        phone: null,
        individualId: null,
        older65: null,
        blind: null,
        regular: null,
        deaf: null,
        disabled: null,

      }),
      spouse: this.formBuilder.group({
        ssn: null,
        name: null,
        deceased: null,
        birthdate: new DateFormControl(''),
        email: null,
        occupation: null,
        dateOfDeath: new DateFormControl(''),
        phone: null,
        address: this.formBuilder.group({
          addressLine1: null,
          addressLine2: null,
          aptNumber: null,
          city: null,
          state: null,
          postalCode: null,
        }),
        individualId: null,
        older65: null,
        blind: null,
        regular: null,
        deaf: null,
        disabled: null,
      }),
      residency: [null, Validators.required],
      partYearFromDate: new DateFormControl(''),
      partYearToDate: new DateFormControl(''),
      filing: [null, Validators.required],
      foreignAddress: this.formBuilder.group({
        provinceOrState: null,
        country: null,
        postalCode: null,
      }),
      federalForm1310: null,
      itemizedDeductions: null,
      thirdParty: this.formBuilder.group({
        designee: null,
        name: null,
        phoneNumber: null,
        pin: null,
      }),
      numberOfExemptions: null,
    });

    this.form.get('taxpayer.deceased').valueChanges.subscribe(deceased => {
      const dateOfDeathControl = this.form.get('taxpayer.dateOfDeath');
      if (deceased) {
        dateOfDeathControl.setValidators(Validators.required);
      } else {
        dateOfDeathControl.clearValidators();
      }
      dateOfDeathControl.updateValueAndValidity(); // Important to update validity
    });

    this.form.get('thirdParty.designee').valueChanges.subscribe(designee => {
      const thirdPartyGroup = this.form.get('thirdParty') as FormGroup;
      if (designee === 'yes') {
        thirdPartyGroup.get('name').setValidators(Validators.required);
        thirdPartyGroup.get('phoneNumber').setValidators(Validators.required);
        thirdPartyGroup.get('pin').setValidators(Validators.required);
      } else {
        thirdPartyGroup.get('name').clearValidators();
        thirdPartyGroup.get('phoneNumber').clearValidators();
        thirdPartyGroup.get('pin').clearValidators();
      }
      thirdPartyGroup.get('name').updateValueAndValidity();
      thirdPartyGroup.get('phoneNumber').updateValueAndValidity();
      thirdPartyGroup.get('pin').updateValueAndValidity();
    });

    this.form.get('spouse.deceased').valueChanges.subscribe(deceased => {
      const dateOfDeathControl = this.form.get('spouse.dateOfDeath');
      if (deceased) {
        dateOfDeathControl.setValidators(Validators.required);
      } else {
        dateOfDeathControl.clearValidators();
      }
      dateOfDeathControl.updateValueAndValidity(); // Important to update validity
    });

    this.form.get("filing")?.valueChanges?.subscribe((filing) => {
      if (filing == "MJ" || filing == "MS") {
        this.form.get("spouse.name").addValidators([Validators.required]);
        this.form.get("spouse.ssn").addValidators([Validators.required]);
        this.form.get("spouse.ssn").updateValueAndValidity();
        this.form.get("spouse.name").updateValueAndValidity();
      } else {
        this.form.get("spouse.name").removeValidators([Validators.required]);
        this.form.get("spouse.ssn").removeValidators([Validators.required]);
        this.form.get("spouse.ssn").updateValueAndValidity();
        this.form.get("spouse.name").updateValueAndValidity();
      }
    });

    this.form.get('residency').valueChanges.subscribe(residency => {
      const partYearFromDateControl = this.form.get('partYearFromDate');
      const partYearToDateControl = this.form.get('partYearToDate');

      if (residency === 'P') {
        // Set 'required' validators
        partYearFromDateControl.setValidators([Validators.required]);
        partYearToDateControl.setValidators([Validators.required]);

        // Additionally, set the custom date range validator on the FormGroup
        this.form.setValidators([DateRangeValidator('partYearFromDate', 'partYearToDate')]);
      } else {
        partYearFromDateControl.setValue(null);
        partYearToDateControl.setValue(null);
        // Clear validators
        partYearFromDateControl.clearValidators();
        partYearToDateControl.clearValidators();

        // Clear the custom validator from the FormGroup
        this.form.clearValidators();
      }

      // Update validity
      partYearFromDateControl.updateValueAndValidity();
      partYearToDateControl.updateValueAndValidity();
      this.form.updateValueAndValidity(); // Also update the FormGroup validity
    });
  }

  public switchTaxpayerSpouse(individualReturn) {
    // Get the form controls for taxpayer and spouse
    const taxpayerControl = this.form.get('taxpayer') as FormGroup;
    const spouseControl = this.form.get('spouse') as FormGroup;

    if (taxpayerControl && spouseControl) {
      // Temporarily store the taxpayer's value
      const taxpayerValue = taxpayerControl.value;
      const spouseValue = spouseControl.value;


      const taxpayer ={
        ssn: taxpayerValue.ssn,
        name: taxpayerValue.name,
        deceased: taxpayerValue.deceased,
        birthdate: taxpayerValue.birthdate,
        emailNotifications: taxpayerValue.emailNotifications,
        occupation: taxpayerValue.occupation,
        dateOfDeath: taxpayerValue.dateOfDeath,
        phone: taxpayerValue.phone,
        individualId: taxpayerValue.individualId,
      }

      const spouse ={
        ssn: spouseValue.ssn,
        name: spouseValue.name,
        deceased: spouseValue.deceased,
        birthdate: spouseValue.birthdate,
        emailNotifications: spouseValue.emailNotifications,
        occupation: spouseValue.occupation,
        dateOfDeath: spouseValue.dateOfDeath,
        phone: spouseValue.phone,
        individualId: spouseValue.individualId,
      }

      // Swap values between taxpayer and spouse
      taxpayerControl.patchValue(spouse);
      spouseControl.patchValue(taxpayer);

    }

    // Call the next function, passing the desired argument
    this.next(false);
  }
}
