import {Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HexaDetailComponent} from "@hexalang/ui/core";
import {AbstractControl, FormArray, FormBuilder, FormGroup} from "@angular/forms";
import {EPermission, PaymentHelper} from "@city-tax/shared";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {IndividualService,} from "../../../../../services/individual.service";
import {AuthService} from "@city-tax/features/auth";
import {NgxPermissionsService} from "ngx-permissions";
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {PercentComponent} from "../controls/percent/percent.component";

@Component({
  selector: "city-tax-feature-individual-filing-oh-schedule-form",
  templateUrl: "./individual-filing-oh-schedule-form.component.html",
  styleUrls: ["./individual-filing-oh-schedule-form.component.scss"],
})
export class IndividualFilingOhScheduleFormComponent
  extends HexaDetailComponent
  implements OnInit, OnDestroy {
  public scheduleForm: FormGroup;
  public title$;
  public modalRef?: BsModalRef;
  public isDisabled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public taxYear = this.individualService.taxYear;
  public currentYear = this.individualService.currentYear;
  public ePermission = EPermission;
  public config = this.authService.config;
  public hasPermission$: Observable<boolean>;
  public isLoading$ = this.individualService.isLoading$;
  public isUploading$ = this.individualService.isUploading$;
  public error = null;
  public taxDocument = this.authService.organization.taxDocument;
  public city = this.authService.config;
  public individualReturn$ = this.individualService.individualReturn$;
  public individualReturn = this.individualService.individualReturn;
  localitiesSubs!: Subscription;
  public localities = [];
  changeDetectorRef: any;
  @Input("isEdit") isEdit: boolean = false;
  @Input("ownProperty") ownProperty: boolean;
  @Input("residency") residency: string;
  @Input("row") row: any;
  @Input("index") index: any;
  @Input("hasLossCarryForward") hasLossCarryForward: boolean = false;
  @Output("updateScheduleForm") updateScheduleForm = new EventEmitter();
  @Output("addScheduleForm") addScheduleForm = new EventEmitter();
  public taxRate = this.authService.config?.components?.individual?.taxRate;


  types = [
    {name: "scheduleC", text: "Schedule C"},
    {name: "scheduleE", text: "Schedule E"},
    {name: "scheduleK1", text: "Schedule K1"},
    {name: "scheduleMisc", text: "Schedule Misc"},
    {name: "lossCarryForward", text: "Loss Carry Forward"},
  ];

  public miscIncomeTypes = [
    "1099-NEC",
    "1099-MISC",
    "Farm Income",
    "W-2G (Gambling Winnings)",
    "Form 4797 (Ordinary Gains and Losses)",
    "Other Income",
  ];

  public k1IncomeTypes = [
    "Partnership (no S Corp)",
    "Estate And Trust",
    "Beneficiary",
  ];

  public eIncomeTypes = ["Rent", "Royalty", "Farm Rent", "Other Income"];

  constructor(
    injector: Injector,
    private modalService: BsModalService,
    private modal: BsModalService,
    private individualService: IndividualService,
    private authService: AuthService,
    public permissionService: NgxPermissionsService,
    private formBuilder: FormBuilder,
    private paymentHelper: PaymentHelper
  ) {
    super(injector);
  }

  get selectedType() {
    return this.scheduleForm.get("type").value;
  }

  get scheduleYChecked() {
    return this.scheduleForm.get("scheduleC.scheduleYChecked").value;
  }

  get lossCarryForward(): FormArray {
    return this.scheduleForm.get("lossCarryForward") as FormArray;
  }

  async ngOnInit() {
    //this.checkPermission();
    // console.log("******** schedule ********", this.row);
    this.scheduleForm = this.formBuilder.group({
      type: "scheduleC",
      scheduleC: this.formBuilder.group({
        companyFederalId: [null],
        companyName: [null],
        municipality: [null],
        incomeAmount: [null],
        amount: [null],
        taxesPaidAmount: [null, [this.validateAmount.bind(this)]],
        scheduleYChecked: null,
        scheduleY: this.formBuilder.group({
          averageOriginalCostOfPropertyLocatedEverywhere: [null],
          averageOriginalCostOfPropertyLocatedInMunicipality: [null],
          grossAnnualRentPaidLocatedEverywhere: [null],
          grossAnnualRentPaidLocatedInMunicipality: [null],
          step2LocatedEverywhere: [null],
          step2LocatedInMunicipality: [null],
          step2Percentage: [null],
          step3LocatedEverywhere: [null],
          step3LocatedInMunicipality: [null],
          step3Percentage: [null],
          step4Percentage: [null],
          step5Percentage: [null],
          totalStep1LocatedEverywhere: [null],
          totalStep1LocatedInMunicipality: [null],
          totalStep1Percentage: [null],
        }),
      }),
      scheduleE: this.formBuilder.group({
        companyName: [null],
        companyFederalId: [null],
        municipality: [null],
        incomeAmount: [null],
        amount: [null],
        taxesPaidAmount: [null, [this.validateAmount.bind(this)]],
        miscIncomeType: [null],
      }),
      scheduleK1: this.formBuilder.group({
        companyName: [null],
        companyFederalId: [null],
        municipality: [null],
        incomeAmount: [null],
        amount: [null],
        taxesPaidAmount: [null, [this.validateAmount.bind(this)]],
        miscIncomeType: [null],
      }),
      scheduleMisc: this.formBuilder.group({
        companyName: [null],
        companyFederalId: [null],
        municipality: [null],
        incomeAmount: [null],
        amount: [null],
        taxesPaidAmount: [null, [this.validateAmount.bind(this)]],
        miscIncomeType: [null],
      }),
      lossCarryForward: this.formBuilder.array([]),
    });

    if (!this.isEdit && this.hasLossCarryForward) {
      this.types = this.types.filter((t: any) => t?.name != "lossCarryForward");
    }
    if (this.row) {
      if (this.row.scheduleC?.scheduleY) {
        this.row.scheduleC.scheduleYChecked = true;
      }
      if (this.row.lossCarryForward) {
        this.row.lossCarryForward?.map((u: any) => {
          this.lossCarryForward.push(this.formBuilder.group(u));
        });
      }
      this.scheduleForm.patchValue(this.row);
    }
    if (this.individualService.individualReturn?.status === 'Completed' || this.individualService.individualReturn?.status === 'Mailed') {

      this.isDisabled$.next(true)
      this.scheduleForm.disable();
    }
    await this.getLocalities();
  }


  validateAmount(control: AbstractControl) {

    const parent = control.parent;
    if (!parent) {
      return null;
    }

    const incomeAmountControl = parent.get('incomeAmount');

    if (!incomeAmountControl) {
      return null;
    }

    const filingCityTaxRate = this.config?.components?.individual?.taxRate;
    const filingCityCreditRate = this.config?.components?.individual?.creditRate;

    let creditRate = filingCityCreditRate === 0 ? 0 : filingCityCreditRate || filingCityTaxRate;

    if (this.city.name === 'Mason' && !this.ownProperty) {
      creditRate = filingCityTaxRate;
    } else if (this.city.name === 'Mason' && this.ownProperty && this.residency !== 'N') {

    } else {
      creditRate = creditRate > filingCityTaxRate ? filingCityTaxRate : creditRate;
    }

    this.taxRate = creditRate;

    const incomeAmount = incomeAmountControl.value;
    const amount = control.value;
    const calculatedMax = this.paymentHelper.decimalValue(incomeAmount * creditRate);

    // console.log('amount', {incomeAmount, calculatedMax, value: +amount > +calculatedMax});
    if (amount && incomeAmount && +amount > +calculatedMax) {
      return {greaterThanIncome: true};
    }
    return null;
  }

  percentModal() {
    this.modalRef = this.modalService.show(PercentComponent, {
      class: "modal-sm modal-dialog-centered",
      initialState: {},
    });
    this.modalRef.onHide.subscribe((n: string | any) => {
      const percentValue = this.modalRef.content
        ? this.paymentHelper.decimalValue(this.modalRef.content)
        : 0;
      const type = this.scheduleForm.get("type").value;
      const incomeAmount = this.scheduleForm.get(`${type}.incomeAmount`).value;
      const percent = this.paymentHelper.decimalValue(percentValue / 100);
      const amount = this.paymentHelper.decimalValue(incomeAmount * percent);
      this.scheduleForm.get(`${type}.amount`).setValue(amount);
    });
  }

  addLossCarryForward(event: any) {
    event?.stopPropagation();
    event?.preventDefault();
    const a: any = {
      lossCarryForwardAmount: [null],
      taxYear1P: [null],
      taxYear2P: [null],
      taxYear3P: [null],
      taxYear4P: [null],
      taxYear5P: [null],
    };
    this.lossCarryForward.push(this.formBuilder.group(a));
  }

  removeLossCarryForward(index) {
    const value = this.lossCarryForward.value;
    this.lossCarryForward.setValue(
      value
        .slice(0, index)
        .concat(value.slice(index + 1))
        .concat(value[index])
    );
    this.lossCarryForward.removeAt(value.length - 1);
  }

  public trackByFn = (index, item) => {
    return index;
  };

  update() {
    const payload = this.scheduleForm.getRawValue();
    if (this.individualReturn.resident) {
      this.setAmountForResident(payload);
    }
    this.updateScheduleForm.emit(payload);
  }

  add() {
    const payload = this.scheduleForm.getRawValue();
    if (this.individualReturn.resident) {
      this.setAmountForResident(payload);
    }
    this.addScheduleForm.emit(payload);
  }

  setAmountForResident(payload) {
    payload.scheduleC.amount = payload.scheduleC?.incomeAmount;
    payload.scheduleE.amount = payload.scheduleE?.incomeAmount;
    payload.scheduleK1.amount = payload.scheduleK1?.incomeAmount;
    payload.scheduleMisc.amount = payload.scheduleMisc?.incomeAmount;
  }

  hide() {
    this.modal?.hide();
  }

  submit() {
    if (this.scheduleForm.valid) {
      if (this.isEdit) {
        this.update();
      } else {
        this.add();
      }
    } else {
      this.scheduleForm.markAllAsTouched();
    }
  }


  async onSearchDynamic(value: any) {
    if (value.term === "") {
      this.localities = [];
    } else {
      try {
        const res = await this.individualService.getLocalitySearch('OH', value.term);
        this.localities = res.data;
      } catch (error) {

      }
    }
  }

  async getLocalities() {
    if (!this.localities?.length) {
      try {
        const res = await this.individualService
          .getLocalities('OH')
        if (res) this.localities = res.data;
      } catch (error) {

      }
    }
  }

  ngOnDestroy(): void {
    this.localitiesSubs?.unsubscribe();
  }

  onClearDynamic(): void {
  }
}
