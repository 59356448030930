<div class="pt-3 px-3">
  <form [formGroup]="formFilter" novalidate>
    <div class="row">
      <div class="col col-md-2">
        <ng-select
            [clearable]="true"
            [items]="years$ | async"
            [multiple]="true"
            [searchable]="true"
            formControlName="year"
            labelForId="year"
            [placeholder]="'Payment.tax-year' | translate"
        >
        </ng-select>
      </div>
      <!--      <div class="col-auto ml-auto">-->
      <!--        <button (click)="schedulePayment()" class="btn btn-primary" type="button">-->
      <!--          Schedule Payment-->
      <!--        </button>-->
      <!--      </div>-->
      <div class="col-auto ml-auto">
        <button (click)="createPayment()" 
           class="btn btn-primary" 
           type="button"
           [attr.tabindex]="0" 
           [attr.role]="'button'" 
           [attr.aria-label]="'create-a-new-payment' | translate" >
          {{'Payment.new-payment' | translate}}
        </button>
      </div>
    </div>
  </form>
</div>

<div class="p-3">
  <div class="card">
    <ngx-datatable
        #datatableUser
        (page)="onPage($event)"
        (sort)="onSort($event)"
        [columnMode]="'force'"
        [columns]="columns"
        [count]="(payments$ | async)?.meta?.inlineCount"
        [externalPaging]="true"
        [externalSorting]="true"
        [footerHeight]="50"
        [headerHeight]="50"
        [limit]="limit"
        [loadingIndicator]="isLoading$ | async"
        [offset]="offset"
        [rowHeight]="50"
        [rows]="(payments$ | async)?.data"
        [scrollbarH]="true"
        class="bootstrap datatable-flush"
    >
    </ngx-datatable>
  </div>
</div>

<ng-template #statusTemplate let-i="index" let-row="row" let-value="value" ngx-datatable-cell-template>
  <ng-container *ngIf="value === 'Intent'">
    <a [queryParams]="{ id: row.id, redirect: router.url }" [routerLink]="['/individual/payments/create/intent']" class="flex-grow-1">
      {{'Payment.pending' | translate}}
    </a>
  </ng-container>
  <ng-container *ngIf="value === 'Scheduled'">
    <a [queryParams]="{ id: row.id, redirect: router.url }" [routerLink]="['/individual/payments/create/confirmation']" class="flex-grow-1">
      {{ value }}
    </a>
  </ng-container>
  <ng-container *ngIf="value === 'Failed'">
    {{'Payment.failed' | translate}}
  </ng-container>
  <ng-container *ngIf="value !== 'Intent'  && value !== 'Scheduled' && value !== 'Failed'">
    <a [queryParams]="{ id: row.id }" [routerLink]="['/individual/payments/create/confirmation']" class="flex-grow-1">
      {{'Payment.completed' | translate}}
    </a>
  </ng-container>
</ng-template>

<ng-template #removeTemplate let-i="index" let-row="row" let-value="value" ngx-datatable-cell-template>
  <ng-container *ngIf="row.status === 'Scheduled'">
    <button (click)="editPayment(row)" 
        class="btn btn-sm bg-white border shadow" 
        container="body" 
        placement="left" 
        tooltip="Edit" 
        type="button"
        [attr.tabindex]="0"
        [attr.role]="'button'"
        [attr.aria-label]="'Edit payment'">
      <svg class="bi bi-pencil-fill edit-icon" fill="currentColor" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
      </svg>
    </button>
  </ng-container>
  <ng-container *ngIf="row.status === 'Intent'">
    <button (click)="deletePayment(row.id)" 
         class="btn btn-sm bg-white border shadow" 
         container="body" 
         placement="left" 
         tooltip="Delete" 
         type="button"
         [attr.tabindex]="0"
         [attr.role]="'button'"
         [attr.aria-label]="'Delete payment'">
      <svg class="icon icon-sm fill-danger-500" viewBox="0 0 24 24">
        <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" stroke="none"/>
      </svg>
    </button>
  </ng-container>
  <ng-container *ngIf="row.status === 'Scheduled'">
    <button (click)="showModal('delete',row)" 
         class="btn btn-sm bg-white border shadow" 
         container="body" 
         placement="left" 
         tooltip="Delete" 
         type="button"
         [attr.tabindex]="0"
         [attr.role]="'button'"
         [attr.aria-label]="'Delete'">
      <svg class="icon icon-sm fill-danger-500" viewBox="0 0 24 24">
        <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" stroke="none"/>
      </svg>
    </button>
  </ng-container>
</ng-template>

<ng-template #payTemplate let-i="index" let-row="row" let-value="value" ngx-datatable-cell-template>
  <ng-container *ngIf="row.status === 'Intent'">
    <a [queryParams]="{ id: row.id, redirect: router.url }" [routerLink]="['/individual/payments/create/intent']" class="flex-grow-1 btn btn-sm btn-danger px-2 py-1">
      {{'Taxpayer.pay' | translate}}
    </a>
  </ng-container>
</ng-template>

<ng-template #noPermission>
  <ui-forbidden></ui-forbidden>
</ng-template>

<ng-template #paymentDateTemplate let-i="index" let-row="row" let-value="value" ngx-datatable-cell-template>
  <ng-container *ngIf="row.type === 'card'">
    {{ row.originalPaymentDate || row.paymentDate | utcDate: 'MM/dd/yyyy' }}
  </ng-container>
  <ng-container *ngIf="row.type !== 'card'">
    {{ row.paymentDate | utcDate: 'MM/dd/yyyy' }}
  </ng-container>
</ng-template>

<ng-template #noData>
  <span class="text-muted">--</span>
</ng-template>
