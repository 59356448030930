import {Component, Injector, OnInit} from "@angular/core";
import {HexaDetailComponent} from "@hexalang/ui/core";
import {EPermission} from "@city-tax/shared";
import {BehaviorSubject, Observable, Subject, Subscription,} from "rxjs";
import {IndividualService} from "../../../../services/individual.service";
import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {NgxPermissionsService} from "ngx-permissions";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";


@Component({
  selector: "city-tax-feature-individual-filing-upload",
  templateUrl: "./individual-filing-upload.component.html",
  styleUrls: ["./individual-filing-upload.component.scss"],
})
export class IndividualFilingUploadComponent
  extends HexaDetailComponent
  implements OnInit {
  public form: FormGroup;
  public title$;
  public validCityForm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public ePermission = EPermission;
  public isLoading$ = this.individualService.isLoading$;
  public error = null;
  public taxDocument = this.authService.organization.taxDocument;
  public isUploading$ = this.individualService.isUploading$;
  public city = this.authService.organization;

  public individualReturn$ = this.individualService.individualReturn$;
  public individualReturn = this.individualService.individualReturn;
  public hasPermission$: Observable<boolean>;
  public SsnDisplayChange: Subject<string> = new Subject<string>();
  public SsnSubscription: Subscription;
  public SsnMask = false;
  public SsnDisplay;
  changeDetectorRef: any;

  constructor(
    injector: Injector,
    private router: Router,
    private route: ActivatedRoute,
    private individualService: IndividualService,
    public authService: AuthService,
    public toastrService: ToastrService,
    public permissionService: NgxPermissionsService,
    private formBuilder: FormBuilder
  ) {
    super(injector);
    this.form = this.formBuilder.group({
      cityFormFile: null,
      source: null,
      // spouse: this.formBuilder.group({
      //   ssn: [null],
      // }),
      taxForms: this.formBuilder.group({
        w2: this.formBuilder.group({
          required: true,
          provided: null,
        }),
        federal: this.formBuilder.group({
          required: false,
          provided: null,
        }),
        city: this.formBuilder.group({
          type: null,
          attachment1: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_1: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_2: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_3: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment2_4: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment3: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment4: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment5: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment6: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment7: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment8: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment9: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment10: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment11: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment12: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment13: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment14: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment15: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment16: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment17: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment18: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment19: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment20: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment21: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          attachment22: this.formBuilder.group({
            required: false,
            provided: null,
          }),
          cfCov: this.formBuilder.group({
            required: false,
            provided: null,
          }),
        }),
      }),
    });
    this.SsnSubscription = this.SsnDisplayChange.subscribe((value) => {
      this.SsnDisplay = value;
    });
  }

  checkDocuments(ir: any) {
    const cityFormCheck = ir.files?.find(f => f.type === this.taxDocument);
    this.validCityForm$.next(cityFormCheck);
  }

  async ngOnInit() {
    await this._getReturn();
    //this.checkPermission();
  }

  async uploadCityForm(event: any) {
    const type = this.authService.organization.taxDocument;
    await this.uploadDocument(type, event);
  }

  async uploadDocument(type, event) {
    const media = {
      file: event.target.files[0],
      mimeType: event.target.files[0].type,
      type: type
    };
    try {
      const ir = await this.individualService.uploadFile(
        this.authService.organization.id,
        this.individualService.individualReturn.id,
        type,
        media
      );
      this._patchForm(ir);
    } catch (error) {

    }
  }

  public async delete(file) {
    try {
      const ir = await this.individualService.deleteFile(
        this.authService.organization.id,
        this.individualService.individualReturn.id,
        file.id
      );
      this.checkDocuments(ir);
    } catch (error) {

    }
  }

  async back() {
    try {
      const source = this.form.get('source').value;
      let path = `/individual/filings/${this.authService.config?.state.toLowerCase()}/${this.individualService.taxYear}`;
      path += "/start";
      await this.router.navigate([path]);
    } catch (error) {
    }
  }

  async next() {
    try {
      const source = this.form.get('source').value;
      let path = `/individual/filings/${this.authService.config?.state.toLowerCase()}/${this.individualService.taxYear}`;
      console.log(this.city);
      if (source !== 'Upload' || this.city.state === 'OH') {
        path += "/page2";
      } else {
        path += "/acknowledgement";
      }
      await this.router.navigate([path]);
    } catch (error) {
    }
  }

  private _patchForm(ir: any) {
    this.form.patchValue(ir);
    this.checkDocuments(ir);
  }

  private async _getReturn() {
    try {
      const ir = await this.individualService.getReturn(this.authService.organization.id, this.authService.config?.state);
      this._patchForm(ir);
      let path = `/individual/filings`;
      if (ir.status === "Completed") {

        path += `/${this.authService.config?.state.toLowerCase()}/${this.individualService.taxYear}/confirmation`;
        await this.router.navigate([path]);

      }
    } catch (error) {

    }
  }

}
