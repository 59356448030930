<div *ngIf="(individualReturn$ | async)?.status === 'Completed'"
    class="card p-3 position-absolute return-filed m-0 text-center">
  {{'Taxpayer.filed' | translate}}
</div>
<div *ngIf="(individualReturn$ | async)?.status === 'Mailed'"
    class="card p-3 position-absolute return-filed m-0 text-center">
    {{'Taxpayer.mailed' | translate}}
</div>
<div class="p-4" *ngIf="(individualReturn$ | async) as individualReturn">
  <div class="card border-0 shadow-none taxpayer-form">
    <div class="row">
      <div class="col-12 px-0">
        <form [formGroup]="form" novalidate>
          <div class="col-12 table-outer px-0">
            <div class="container mw-100">
              <div class="row border">
                <div class="col-12">
                  <div class="row align-items-center  bg-white">
                    <div class="col-3">
                      <h2 class="">CF-1040</h2>
                    </div>
                    <div class="col-7">
                      <div class="text-uppercase text-left py-1">
                        <p class="">{{'Taxpayer.individual-return-due' | translate}} {{ individualReturn?.eFileDeadline | utcDate:'MMMM dd, YYYY' }}</p>
                      </div>
                    </div>
                    <div class="col-2 d-flex justify-content-end">
                      {{'Taxpayer.how-to-use-this-site' | translate}}
                      <a class="pl-2" href="https://cdn.insourcetax.com/Michigan Fill In Instructions.pdf" target="_help" tooltip="Help">
                        <svg class="text-primary" fill="currentColor" height="20" viewBox="0 0 16 16" width="20">
                          <path
                              d="M6.95.435c.58-.58 1.52-.58 2.1 0l6.515 6.516c.58.58.58 1.519 0 2.098L9.05 15.565c-.58.58-1.519.58-2.098 0L.435 9.05a1.48 1.48 0 0 1 0-2.098zm1.4.7a.495.495 0 0 0-.7 0L1.134 7.65a.495.495 0 0 0 0 .7l6.516 6.516a.495.495 0 0 0 .7 0l6.516-6.516a.495.495 0 0 0 0-.7L8.35 1.134z"/>
                          <path
                              d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94"/>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-12 py-3">
                  <table class="table  mb-0">
                    <thead>
                    <tr>
                      <td class="border-right border-left border-bottom" colspan="7">
                        <div class="row">
                          <div class="col-6 d-flex flex-column">
                            <p class="font-weight-bold mb-0 text-uppercase">
                              {{'Taxpayer.dependents' | translate}}
                            </p>
                            <ng-container formGroupName="irs1040CfPage2">
                              <div class="mb-2 ml-3 pl-2">
                                <input (change)="onClaimedDependent()" class="custom-control-input" formControlName="claimedDependent" id="claimedDependent" type="checkbox"/>
                                <label class="custom-control-label" for="claimedDependent">
                                  {{'Taxpayer.if-someone-else-can-claim-you' | translate}}
                                </label>
                              </div>

                              <div *ngIf="!form.get('irs1040CfPage2.claimedDependent').value" class="form-group mb-0">
                                <label for="numDependentChild">
                                  {{'Taxpayer.enter-number-of-dependent' | translate}}
                                </label>
                                <input class="form-control col-2"
                                    formControlName="numDependentChild"
                                    id="numDependentChild"
                                    type="number"/>
                              </div>
                            </ng-container>
                          </div>
                          <div class="col-6 d-flex flex-column" formGroupName="irs1040CfPage2">
                            <div class="px-0">
                              <div class="d-flex flex-column">
                                <div class="px-0">
                                  {{'Taxpayer.taxpayer' | translate}}:
                                </div>
                                <div class="row ml-3 mb-3 pl-2">
                                  <div *ngIf="city?.components?.individual?.exemptionsYouRegular" class="col-2 px-0">
                                    <input (change)="check($event,'exemptionsYouRegular')"
                                        class="custom-control-input"
                                        formControlName="exemptionsYouRegular"
                                        id="regular"
                                        type="checkbox"/>
                                    <label class="custom-control-label" for="regular">
                                      Regular
                                    </label>
                                  </div>
                                  <div *ngIf="city?.components?.individual?.exemptionsYouOver65" class="col-2 px-0">
                                    <input (change)="check($event,'exemptionsYouOver65')"
                                        class="custom-control-input"
                                        formControlName="exemptionsYouOver65"
                                        id="over"
                                        type="checkbox"/>
                                    <label class="custom-control-label" for="over">
                                      {{'Taxpayer.65-or-over' | translate}}
                                    </label>
                                  </div>
                                  <div *ngIf="city?.components?.individual?.exemptionsYouBlind" class="col-2 px-0">
                                    <input (change)="check($event,'exemptionsYouBlind')"
                                        class="custom-control-input"
                                        formControlName="exemptionsYouBlind"
                                        id="blind"
                                        type="checkbox"/>
                                    <label class="custom-control-label" for="blind">
                                      {{'Taxpayer.blind' | translate}}
                                    </label>
                                  </div>
                                  <div *ngIf="city?.components?.individual?.exemptionsYouDeaf"
                                      class="col-2 px-0">
                                    <input (change)="check($event,'exemptionsYouDeaf')"
                                        class="custom-control-input"
                                        formControlName="exemptionsYouDeaf"
                                        id="deaf"
                                        type="checkbox"/>
                                    <label class="custom-control-label" for="deaf">
                                      {{'Taxpayer.deaf' | translate}}
                                    </label>
                                  </div>
                                  <div *ngIf="city?.components?.individual?.exemptionsYouDisabled" class="col-2 px-0">
                                    <input (change)="check($event,'exemptionsYouDisabled')"
                                        class="custom-control-input"
                                        formControlName="exemptionsYouDisabled"
                                        id="disabled-id"
                                        type="checkbox"/>
                                    <label class="custom-control-label" for="disabled-id">
                                      Disabled
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div *ngIf="form.get('filing').value==='MJ'" class=" d-flex flex-column">
                                <div class="px-0">
                                  Spouse:
                                </div>
                                <div class="row ml-3 mb-3 pl-2">
                                  <div *ngIf="city?.components?.individual?.exemptionsYouRegular" class="col-2 px-0">
                                    <input (change)="check($event,'exemptionsSpouseRegular')"
                                        class="custom-control-input" formControlName="exemptionsSpouseRegular"
                                        id="spouse-regular"
                                        type="checkbox"/>
                                    <label class="custom-control-label" for="spouse-regular">
                                      Regular
                                    </label>
                                  </div>
                                  <div *ngIf="city?.components?.individual?.exemptionsYouOver65" class="col-2 px-0">
                                    <input (change)="check($event,'exemptionsSpouseOver65')"
                                        class="custom-control-input"
                                        formControlName="exemptionsSpouseOver65"
                                        id="spouse-over"
                                        type="checkbox"/>
                                    <label class="custom-control-label" for="spouse-over">
                                      {{'Taxpayer.65-or-over' | translate}}
                                    </label>
                                  </div>
                                  <div *ngIf="city?.components?.individual?.exemptionsYouBlind" class="col-2 px-0">
                                    <input (change)="check($event,'exemptionsSpouseBlind')"
                                        class="custom-control-input"
                                        formControlName="exemptionsSpouseBlind"
                                        id="spouse-blind"
                                        type="checkbox"/>
                                    <label class="custom-control-label" for="spouse-blind">
                                      {{ 'Taxpayer.blind' | translate}}
                                    </label>
                                  </div>
                                  <div *ngIf="city?.components?.individual?.exemptionsYouDeaf" class="col-2 px-0">
                                    <input (change)="check($event,'exemptionsSpouseDeaf')"
                                        class="custom-control-input"
                                        formControlName="exemptionsSpouseDeaf"
                                        id="spouse-deaf"
                                        type="checkbox"/>
                                    <label class="custom-control-label" for="spouse-deaf">
                                      {{ 'Taxpayer.deaf' | translate}}
                                    </label>
                                  </div>
                                  <div *ngIf="city?.components?.individual?.exemptionsYouDisabled" class="col-2 px-0">
                                    <input (change)="check($event,'exemptionsSpouseDisabled')"
                                        class="custom-control-input"
                                        formControlName="exemptionsSpouseDisabled"
                                        id="spouse-disabled-id"
                                        type="checkbox"/>
                                    <label class="custom-control-label" for="spouse-disabled-id">
                                      {{'Taxpayer.disabled' | translate}}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="d-flex">
                              <span class="font-weight-bold text-uppercase align-middle">
                                {{'Taxpayer.exemptions' | translate}}
                              </span>
                              <span class="pl-2">
                              <input
                                  class="form-control bg-light text-center w-50px"
                                  formControlName="boxC"
                                  readonly
                                  type="text">
                                </span>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="form.get('dependents')?.controls.length > 0">
                      <td class="border-left border-right  border-bottom">
                        <p class="text-center w-50 d-block font-weight-bold mb-0">#</p>
                      </td>
                      <td class="border-right border-bottom">
                        <p class="text-center d-block font-weight-bold mb-0 text-uppercase">
                          {{'Taxpayer.first-name' | translate}}
                        </p>
                      </td>
                      <td class="border-right border-bottom">
                        <p class="text-center d-block font-weight-bold mb-0 text-uppercase">
                          {{'Taxpayer.last-name' | translate}}
                        </p>
                      </td>
                      <td class="border-right border-bottom">
                        <p class="text-center d-block font-weight-bold mb-0 text-uppercase">
                          {{'Taxpayer.social-security-number' | translate}}
                        </p>
                      </td>
                      <td class="border-right border-bottom">
                        <p class="text-center d-block font-weight-bold mb-0 text-uppercase">
                          {{'Taxpayer.relationship' | translate}}
                        </p>
                      </td>
                      <td class="border-bottom border-right" colspan="2">
                        <p class="text-center d-block font-weight-bold mb-0 text-uppercase">
                          {{'Taxpayer.date-birth' | translate}}
                        </p>
                      </td>
                    </tr>
                    </thead>
                    <tbody formArrayName="dependents">
                    <ng-container
                        *ngFor="let dep of form.get('dependents')?.controls; let index = index;">
                      <tr [formGroupName]="index">
                        <td class="border-left border-right border-bottom w-50px">
                          <p class="mb-0">{{ index + 1 }}.</p>
                        </td>
                        <td class="border-right border-bottom ">
                          <ui-input-selector
                              [controlForm]="form"
                              [data]="individualReturn?.review"
                              [groupName]="'dependents.' + index"
                              controlClass="form-control bg-light text-center"
                              controlName="firstName"
                          >
                          </ui-input-selector>
                        </td>
                        <td class="border-right border-bottom ">
                          <ui-input-selector
                              [controlForm]="form"
                              [data]="individualReturn?.review"
                              [groupName]="'dependents.' + index"
                              controlClass="form-control bg-light text-center"
                              controlName="lastName"
                          >
                          </ui-input-selector>
                        </td>
                        <td class="border-right border-bottom ">
                          <ui-input-selector
                              [controlForm]="form"
                              [data]="individualReturn?.review"
                              [groupName]="'dependents.' + index"
                              class="w-100"
                              controlClass="form-control bg-light text-center"
                              controlName="ssn"
                              isSSN="true"
                              mask="000-00-0000"
                              placeholder="000-00-0000"
                          >
                          </ui-input-selector>
                        </td>
                        <td class="border-right border-bottom ">
                          <ui-input-selector
                              [controlForm]="form"
                              [data]="individualReturn?.review"
                              [groupName]="'dependents.' + index"
                              class="w-100"
                              controlClass="form-control bg-light text-center"
                              controlName="relationship"
                          >
                          </ui-input-selector>
                        </td>
                        <td class="border-bottom border-right" [formGroupName]="'dependents.' + index">
                          <input
                            id="birthdate"
                            [class.is-invalid]="form.get(['dependents', index, 'birthdate'])?.invalid && 
                                                (form.get(['dependents', index, 'birthdate'])?.dirty || 
                                                form.get(['dependents', index, 'birthdate'])?.touched)"
                            [maxDate]="maxDDate"
                            class="form-control"
                            formControlName="birthdate"
                            type="text"
                            [bsConfig]="{containerClass: 'theme-dark-blue', showWeekNumbers: false}"
                            [attr.aria-label]="'date-birth' | translate"
                            [attr.aria-invalid]="form.get(['dependents', index, 'birthdate'])?.invalid && 
                                                (form.get(['dependents', index, 'birthdate'])?.dirty || 
                                                  form.get(['dependents', index, 'birthdate'])?.touched) ? 'true' : 'false'"
                            placeholder="mm/dd/yyyy"
                            bsDatepicker
                        />
                        </td>
                        <td class="align-middle text-center border-right border-bottom">
                          <button
                              (click)="removeDependent(index);$event.stopPropagation();"
                              [attr.aria-label]="'Taxpayer.delete' | translate"
                              class="btn btn-sm"
                              container="body"
                              placement="left"
                              role="button"
                              tabindex="0"
                              [attr.tooltip]="'Taxpayer.delete' | translate"
                              type="button"
                          >
                            <svg
                                aria-hidden="true"
                                class="icon icon-sm fill-danger-500"
                                viewBox="0 0 24 24"
                            >
                              <path
                                  d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                  stroke="none"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </div>

                <div class="col-12 py-3">
                  <table class="table mb-0" formGroupName="wagesSalariesTips">
                    <thead>
                    <tr>
                      <td class="border-right border-left border-bottom" colspan="7">
                        <div class="row">
                          <div class="col-12 align-bottom d-flex justify-content-between">
                            <p class="font-weight-bold mb-0 text-uppercase">
                              {{'Taxpayer.enter-wages-text' | translate}}
                              <button (click)="openExcludedTaxWithheld({},false)"
                                  [attr.aria-label]="'Add'"
                                  class="btn btn-sm btn-primary py-1"
                                  role="button"
                                  tabindex="0"
                                  type="button">
                                <span class="text-center text-uppercase">{{'Taxpayer.add' | translate}}</span>
                              </button>
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="this.getScheduleOfWages?.value.length > 0">
                      <td class="border-left border-right p-1 border-bottom">
                        <p class="text-center w-100 d-block font-weight-bold mb-0">
                          W-2<br/>#
                        </p>
                      </td>
                      <td class="border-right border-bottom">
                        <p class="text-center w-100 d-block font-weight-bold mb-0">
                          Col. A<br/>{{ filing == 'S' ? 'T' : 'T or S' }}
                        </p>
                      </td>
                      <td class="border-right border-bottom">
                        <p class="text-center w-100 d-block font-weight-bold mb-0">
                          <span class="text-uppercase">{{'Taxpayer.column' | translate}}</span> B<br/>
                          <span class="text-uppercase">{{'Taxpayer.social-security-number' | translate}}</span><br/>
                          <small class="font-weight-bold">({{'Taxpayer.form-w2-box' | translate}} a)</small>
                        </p>
                      </td>
                      <td class="border-right border-bottom">
                        <p class="text-center w-100 d-block font-weight-bold mb-0">
                          <span class="text-uppercase">{{'Taxpayer.column' | translate}}</span> C<br/>
                          {{'Taxpayer.employers-id-number' | translate}}<br/>
                          <small class="font-weight-bold">({{'Taxpayer.form-w2-box' | translate}} b)</small>
                        </p>
                      </td>
                      <td class="border-right border-bottom">
                        <p class="text-center w-100 d-block font-weight-bold mb-0">
                          <span class="text-uppercase">{{'Taxpayer.column' | translate}}</span> D<br/>
                          {{'Taxpayer.exclude-wages' | translate}}<br/>
                          <small>({{'Taxpayer.attach-exclude-wages' | translate}})</small>
                        </p>
                      </td>
                      <td class="border-right border-bottom">
                        <p class="text-center w-100 d-block font-weight-bold mb-0">
                          <span class="text-uppercase">{{'Taxpayer.column' | translate}}</span> E<br/>
                          {{ city?.name?.toUpperCase() }} 
                          <span>{{'Taxpayer.tax-withheld' | translate}}</span><br/>
                          <small>({{'Taxpayer.form-w2-box' | translate}} 19)</small>
                        </p>
                      </td>
                      <td class="border-right border-bottom">
                        <p class="text-center w-100 d-block font-weight-bold mb-0">
                          <span class="text-uppercase">{{'Taxpayer.column' | translate}}</span> F<br/>
                          {{'Taxpayer.locality-name' | translate}}<br/>
                          <small>({{'Taxpayer.form-w2-box' | translate}} 20)</small>
                        </p>
                      </td>
                      <td class="border-right border-bottom">
                      </td>
                    </tr>
                    </thead>
                    <tbody formArrayName="scheduleOfWages">

                    <ng-container *ngFor="let wage of this.getScheduleOfWages?.value; let i = index; trackBy:trackByFn">
                      <ng-container *ngFor="let locality of wage?.w2LocalityInfo; let localityIndex = index;">
                        <tr>
                          <td class="border-left border-right border-bottom w-50px">
                            <p class="mb-0 text-center">{{ i + 1 }}.</p>
                          </td>
                          <td class="border-right border-bottom w-75px text-center">
                            {{ wage?.type }}
                          </td>
                          <td class="border-right border-bottom text-center">
                            <span *ngIf="localityIndex===0"> XXX-XX-{{ wage?.ssn?.substring(5, 9) }}</span>
                          </td>
                          <td class="border-right border-bottom  text-center">
                            <span *ngIf="localityIndex===0"> {{ wage?.employersFederalId  | mask: '00-00000000' }}</span>
                          </td>
                          <td class="border-right border-bottom  text-right">
                            {{ wage?.excludedWages |  number: '1.2-2' }}
                          </td>
                          <td class="border-right border-bottom text-right">
                            {{ locality?.taxWithheldBox19  |  number: '1.2-2' }}
                          </td>
                          <td class="border-right border-bottom text-center">
                            {{ locality?.taxWithheldLocalityName }}
                          </td>
                          <td class="align-middle text-center border-bottom border-right">
                            <ng-container *ngIf="localityIndex===0">
                              <button (click)="openExcludedTaxWithheld(wage,true,i)" class="btn btn-sm btn-primary p-1 mr-2" type="button">
                                <svg class="icon icon-sm" viewBox="0 0 16 16">
                                  <path
                                      d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"/>
                                </svg>
                              </button>
                              <button
                                  (click)="removeWage(i);$event.stopPropagation()"
                                  [attr.aria-label]="'Taxpayer.delete-wage' | translate"
                                  class="btn btn-sm btn-white"
                                  container="body"
                                  placement="left"
                                  role="button"
                                  tabindex="0"
                                  tooltip="{{'Taxpayer.delete-wage' | translate}}"
                                  type="button">
                                <svg aria-hidden="true"
                                    class="icon icon-sm fill-danger-500"
                                    viewBox="0 0 24 24">
                                  <path d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                      stroke="none"/>
                                </svg>
                              </button>
                            </ng-container>
                          </td>
                        </tr>
                      </ng-container>
                    </ng-container>

                    </tbody>
                    <tfoot>
                    <tr *ngIf="this.getScheduleOfWages?.value.length > 0" class="border-left border-right border-bottom">
                      <td colspan="3"></td>
                      <td>
                        <div class="d-flex justify-content-end">
                          <span class="form-label font-weight-bold text-uppercase">
                            {{'Taxpayer.totals' | translate}}
                          </span>
                        </div>
                      </td>
                      <td class="font-weight-bold text-right border-left border-right border-bottom ">
                        {{ form.get('wagesSalariesTips.grandTotalScheduleExcludibleWages').value |  number: '1.2-2' }}

                      </td>
                      <td class="font-weight-bold text-right border-right border-bottom">
                        {{ form.get('wagesSalariesTips.grandTotalScheduleTaxWithheld').value |  number: '1.2-2' }}
                      </td>
                      <td colspan="2"></td>
                    </tr>
                    <tfoot>
                  </table>
                </div>

                <div class="col-12 py-3">
                  <table class="table mb-0">
                    <thead>
                    <tr>
                      <th class="border-left border-bottom" scope="col">
                        <p class="mb-0">
                            <span class="text-uppercase font-weight-bold">
                              {{'Taxpayer.income-information' | translate}}
                            </span>
                          ({{'Taxpayer.other-than-W2-inco' | translate}})
                        </p>
                      </th>
                      <th class="border-right border-bottom" scope="col">
                        <div class="row">
                          <div class="col-2">
                            <input class="custom-control-input" formControlName="interestCb" id="interest" type="checkbox"/>
                            <label class="custom-control-label" for="interest">{{'Taxpayer.interest' | translate}}</label>
                          </div>
                          <div class="col-2">
                            <input class="custom-control-input" formControlName="dividendsCb" id="dividends" type="checkbox"/>
                            <label class="custom-control-label" for="dividends">{{'Taxpayer.dividends' | translate}}</label>
                          </div>
                          <div class="col-3">
                            <input class="custom-control-input" formControlName="businessIncomeCb" id="businessIncome" type="checkbox"/>
                            <label class="custom-control-label" for="businessIncome">{{'Taxpayer.business-income' | translate}}</label>
                          </div>
                          <div class="col-2">
                            <input class="custom-control-input" formControlName="partnershipCb" id="partnership" type="checkbox"/>
                            <label class="custom-control-label" for="partnership">{{'Taxpayer.partnership' | translate}}</label>
                          </div>
                          <div class="col-2">
                            <input class="custom-control-input" formControlName="otherCb" id="other" type="checkbox"/>
                            <label class="custom-control-label" for="other">{{'Taxpayer.other' | translate}}</label>
                          </div>
                        </div>
                      </th>
                      <th *ngIf="showTotal()" class="border-right" scope="col">
                        <p class="small text-center w-100 text-uppercase d-block mb-0 font-weight-bold">
                          {{'Taxpayer.income' | translate}}
                        </p>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="show('wages')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-12">
                            {{'Taxpayer.total-wages-salaries-and-tips' | translate}}
                            <small> ({{'Taxpayer.from-enter-wages-and-excluded-wages' | translate}} )</small>
                          </div>
                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="grandTotalScheduleWages"
                            groupName="wagesSalariesTips"
                            isNumeric="true"
                            readonly="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr *ngIf="show('interestCb')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-12">
                            {{'Taxpayer.interest-incom-report' | translate}}
                          </div>
                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="interestFromFederal"
                            groupName="taxableInterest"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr *ngIf="show('dividendsCb')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-12">
                            {{'Taxpayer.dividend-income-report' | translate}}
                          </div>
                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="dividendIncomeFromFederalReturn"
                            groupName="ordinaryDividendsFederal"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr *ngIf="show('businessIncomeCb')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-12">
                            {{'Taxpayer.bussiness-income-or-loss' | translate}}
                          </div>
                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            allowNegativeNumbers="true"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="businessIncomeFederal"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr *ngIf="show('partnershipCb')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-12">
                            {{'Taxpayer.partnership-estate-trust-etc' | translate}}
                          </div>

                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="partnershipIncomeFederal"
                            groupName="partnership"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr *ngIf="show('otherCb')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-12">
                            {{'Taxpayer.rental-real-estate' | translate}}
                          </div>
                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="rentalRealEstateFederal"
                            groupName="rentalRealEstate"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <!--                    <tr *ngIf="show('otherCb')">-->
                    <!--                      <td class="border-right border-left border-bottom p-2" colspan="2">-->
                    <!--                        <div class="row p-2">-->
                    <!--                          <div class="col-12">-->
                    <!--                            Taxable refunds, credits or offsets of state and local income taxes-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                      </td>-->
                    <!--                      <td class="border-right border-bottom">-->
                    <!--                        <ui-input-selector-->
                    <!--                            (change)="calculate()"-->
                    <!--                            [allowNegativeNumbers]="true"-->
                    <!--                            [controlForm]="form"-->
                    <!--                            [data]="(individualReturn$|async)?.review"-->
                    <!--                            class="w-100"-->
                    <!--                            controlClass="form-control bg-light text-right"-->
                    <!--                            controlName="taxableRefundsCreditsFederal"-->
                    <!--                            isNumeric="true"-->
                    <!--                        >-->
                    <!--                        </ui-input-selector>-->
                    <!--                      </td>-->
                    <!--                    </tr>-->
                    <tr *ngIf="show('otherCb')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-8">
                            {{'Taxpayer.alimony-received' | translate}}
                          </div>
                          <div class="col-4 d-flex inline justify-items-center items-center">
                            <span class="pr-2">{{'Taxpayer.date-of-original-divorce' | translate}}:</span>
                              <input
                                  id="taxpayer-birthdate"
                                  [class.is-invalid]="form.get('alimonyDateOfDivorce')?.invalid && (form.get('alimonyDateOfDivorce')?.dirty || form.get('alimonyDateOfDivorce')?.touched)"
                                  [maxDate]="maxDDate"
                                  class="form-control"
                                  formControlName="alimonyDateOfDivorce"
                                  type="text"
                                  [bsConfig]="{ containerClass: 'theme-dark-blue', showWeekNumbers: false }"
                                  [attr.aria-label]="'alimony-date-of-divorce' | translate"
                                  [attr.aria-invalid]="form.get('alimonyDateOfDivorce')?.invalid && (form.get('alimonyDateOfDivorce')?.dirty || form.get('alimonyDateOfDivorce')?.touched) ? 'true' : 'false'"
                                  bsDatepicker
                                  placeholder="mm/dd/yyyy"
                                  style="max-width: 152px;height: fit-content;"
                              />
                          </div>
                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="alimonyReceivedFederal"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr *ngIf="show('otherCb')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-12">
                            {{'taxpayer.capital-gain-or-loss' | translate}}
                          </div>
                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            allowNegativeNumbers="true"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="totalCapitalGainsAndLossesFromFederalReturn"
                            groupName="capitalGainsOrLosses"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr *ngIf="show('otherCb')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-12">
                            {{'Taxpayer.others-gain-or-loss' | translate}}
                          </div>
                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            allowNegativeNumbers="true"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="totalOtherGainsAndLosses"
                            groupName="otherGainsLoses"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr *ngIf="show('otherCb')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-12">
                            {{'Taxpayer.taxable-ira-distributions' | translate}}
                          </div>
                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="taxableIraDistributionsFederal"
                            groupName="taxableIraDistributions"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr *ngIf="show('otherCb')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-12">
                            {{'Taxpayer.taxable-pensions-and-annuities' | translate}}
                          </div>

                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="taxablePensionDistributionsFederal"
                            groupName="taxablePensionDistributions"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr *ngIf="show('otherCb') && individualReturn?.residency==='R'">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-12">
                            {{'Taxpayer.subchapter-s-corporation-distributions-exclusions' | translate}}
                          </div>

                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            [readonly]="individualReturn?.residency==='R'"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="subchapterSCorporationDistributionsExclusions"
                            groupName="sCorporationDistributions"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr *ngIf="show('otherCb')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-11">
                            {{'Taxpayer.farm-income-or-loss' | translate}}
                          </div>
                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            allowNegativeNumbers="true"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="totalFarmIncomeNetProfit"
                            groupName="farmIncomeLoss"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <!--                    <tr *ngIf="show('otherCb')">-->
                    <!--                      <td class="border-right border-left border-bottom p-2" colspan="2">-->
                    <!--                        <div class="row p-2">-->
                    <!--                          <div class="col-11">-->
                    <!--                            Unemployment compensation-->
                    <!--                          </div>-->
                    <!--                        </div>-->
                    <!--                      </td>-->
                    <!--                      <td class="border-right border-bottom">-->
                    <!--                        <ui-input-selector-->
                    <!--                            (change)="calculate()"-->
                    <!--                            [allowNegativeNumbers]="true"-->
                    <!--                            [controlForm]="form"-->
                    <!--                            [data]="(individualReturn$|async)?.review"-->
                    <!--                            class="w-100"-->
                    <!--                            controlClass="form-control bg-light text-right"-->
                    <!--                            controlName="unemploymentCompensationFederal"-->
                    <!--                            isNumeric="true"-->
                    <!--                        >-->
                    <!--                        </ui-input-selector>-->
                    <!--                      </td>-->
                    <!--                    </tr>-->
                    <!--                    <tr *ngIf="show('otherCb')">-->
                    <!--                      <td class="border-right border-left border-bottom p-2" colspan="2">-->
                    <!--                        <div class="row p-2">-->
                    <!--                          <div class="col-12">-->
                    <!--                            Social security benefits-->
                    <!--                          </div>-->

                    <!--                        </div>-->
                    <!--                      </td>-->
                    <!--                      <td class="border-right border-bottom">-->
                    <!--                        <ui-input-selector-->
                    <!--                            (change)="calculate()"-->
                    <!--                            [allowNegativeNumbers]="true"-->
                    <!--                            [controlForm]="form"-->
                    <!--                            [data]="(individualReturn$|async)?.review"-->
                    <!--                            class="w-100"-->
                    <!--                            controlClass="form-control bg-light text-right"-->
                    <!--                            controlName="socialSecurityFederal"-->
                    <!--                            isNumeric="true"-->
                    <!--                        >-->
                    <!--                        </ui-input-selector>-->
                    <!--                      </td>-->
                    <!--                    </tr>-->
                    <tr *ngIf="show('otherCb')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-12">
                            {{'Taxpayer.other-income' | translate}}
                          </div>
                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="otherIncomeFederal"
                            groupName="otherIncome"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>

                    <tr *ngIf="show('otherCb')">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="row p-2">
                          <div class="col-12">
                            {{'Taxpayer.gambling-income' | translate}}
                          </div>
                        </div>
                      </td>
                      <td class="border-right border-bottom">
                        <ui-input-selector
                            (change)="calculate()"
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="gamblingIncomeFederal"
                            groupName="gamblingIncome"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>


                    <tr *ngIf="showTotal()">
                      <td class="border-right border-left border-bottom p-2" colspan="2">
                        <div class="col-12 text-right form-label font-weight-bold text-uppercase">
                          {{'Taxpayer.total' | translate}}
                        </div>
                      </td>
                      <td class="border-right border-bottom font-weight-bold text-right p-2">
                        <ui-input-selector
                            [allowNegativeNumbers]="true"
                            [controlForm]="form"
                            [data]="(individualReturn$|async)?.review"
                            allowNegativeNumbers="true"
                            class="w-100"
                            controlClass="form-control bg-light text-right"
                            controlName="totalIncomeFederal"
                            isNumeric="true"
                            readonly="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <div *ngIf="(individualReturn$|async)?.residency !== 'P'" class="col-12 py-3">
                  <table class="table mb-0">
                    <thead>
                    <tr>
                      <th class="border-right border-left border-bottom px-1" scope="col">
                          <span class="text-uppercase font-weight-bold pl-4">
                            <input [disabled]="this.checkDeductionsSchedule()" class="custom-control-input" formControlName="deductionsScheduleCb" id="deductionsSchedule"
                                type="checkbox"/>
                              <label class="custom-control-label" for="deductionsSchedule">{{'Taxpayer.deduction-schedule' | translate}}</label>
                          </span>
                        <small class="center text-center pl-3">
                          ({{'Taxpayer.see-instructions-deductions-allocated' | translate}})
                        </small>
                      </th>
                      <th *ngIf="show('deductionsScheduleCb')" class="border-right" scope="col">
                        <p class="small text-center w-100 d-block mb-0 font-weight-bold">
                          {{'taxpayer.deductions' | translate}}
                        </p>
                      </th>
                    </tr>
                    </thead>
                    <tbody *ngIf="show('deductionsScheduleCb')">
                    <tr>
                      <td class="border-right border-left border-bottom p-2">
                        {{'Taxpayer.ira-deduction' | translate}}
                      </td>
                      <td class="border-right border-bottom p-2">
                        <ui-input-selector
                            (change)="totalDeductions()"
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            controlClass="form-control bg-light text-right"
                            controlName="totalIraDeduction"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr>
                      <td class="border-right border-left border-bottom p-2">
                        {{'Taxpayer.self-employed-sep' | translate}}
                      </td>
                      <td class="border-right border-bottom p-2">

                        <ui-input-selector
                            (change)="totalDeductions()"
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            controlClass="form-control bg-light text-right"
                            controlName="totalSelfEmployedSep"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr>
                      <td class="border-right border-left border-bottom p-2">
                        {{'Taxpayer.employee-business-expenses' | translate}}
                      </td>
                      <td class="border-right border-bottom p-2">
                        <ui-input-selector
                            (change)="totalDeductions()"
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            controlClass="form-control bg-light text-right"
                            controlName="totalEmployeeBusinessExpense"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr>
                      <td class="border-right border-left border-bottom p-2">
                        {{'Taxpayer.moving-expenses' | translate}}
                      </td>
                      <td class="border-right border-bottom p-2">

                        <ui-input-selector
                            (change)="totalDeductions()"
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            controlClass="form-control bg-light text-right"
                            controlName="totalMovingExpensesIntoCityAreaOnly"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr>
                      <td class="border-right border-left border-bottom p-2">
                        {{'Taxpayer.alimony-paid' | translate}}
                      </td>
                      <td class="border-right border-bottom p-2">
                        <ui-input-selector
                            (change)="totalDeductions()"
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            controlClass="form-control bg-light text-right"
                            controlName="totalAlimonyPaidWithoutChildSupport"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr>
                      <td class="border-right border-left border-bottom p-2">
                        {{'Taxpayer.renaissance-zone-deduction' | translate}}
                      </td>
                      <td class="border-right border-bottom p-2">

                        <ui-input-selector
                            (change)="totalDeductions()"
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            controlClass="form-control bg-light text-right"
                            controlName="totalRenaissanceZoneDeduction"
                            isNumeric="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    <tr>
                      <td class="border-right border-left border-bottom p-2">
                        {{'Taxpayer.total-deduction-line-1-6-19' | translate}}
                      </td>
                      <td class="border-right border-bottom p-2">
                        <ui-input-selector
                            [controlForm]="form"
                            [data]="individualReturn?.review"
                            controlClass="form-control bg-light text-right"
                            controlName="totalDeductions"
                            isNumeric="true"
                            readonly="true"
                        >
                        </ui-input-selector>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>

                <div class="col-12 py-3">
                  <table class="table mb-0">
                    <tr>
                      <td class="border-right border-left border-bottom" colspan="2">
                        <p class="mb-0 font-weight-bold">
                          <span class="text-uppercase font-weight-bold mr-3">
                            {{'Taxpayer.address-schedule' | translate}}
                          </span>
                          <button (click)="addAddress()"
                              [attr.aria-label]="'Add Address'"
                              class="btn btn-sm btn-primary py-1"
                              role="button"
                              tabindex="0"
                              type="button">
                            <span class="text-center text-uppercase">{{'Taxpayer.add' | translate}}</span>
                          </button>
                          <small class="center text-center pl-2">
                            ({{'Taxpayer.where-t-s-b-resided' | translate}})
                          </small>
                        </p>
                      </td>
                    </tr>
                  </table>
                  <table *ngIf="form.get('addressWhereTaxpayerResided')?.controls.length > 0" class="table mb-0" formArrayName="addressWhereTaxpayerResided">
                    <thead>
                    <tr>
                      <th class="border-right border-bottom border-left" scope="col">
                        <p class="text-center font-weight-bold mb-0">
                          <span class="text-uppercase font-weight-bold">MARK</span>
                        </p>
                        <p class="mb-0 text-center">T, S, B</p>
                      </th>
                      <th class="border-right border-bottom" scope="col">
                        <p class="text-center w-100 d-block mb-0 font-weight-normal">
                          {{'Taxpayer.list-all-residence-text' | translate}}
                        </p>
                      </th>
                      <th class="border-right border-bottom" scope="col">
                        <p class="small text-center w-100 d-block mb-0">
                          {{'Taxpayer.month' | translate}}
                        </p>
                      </th>
                      <th class="border-right border-bottom" scope="col">
                        <p class="small text-center w-100 d-block mb-0">
                          {{'Taxpayer.day' | translate}}
                        </p>
                      </th>
                      <th class="border-right border-bottom" scope="col">
                        <p class="small text-center w-120 d-block mb-0">
                          {{'Taxpayer.month' | translate}}
                        </p>
                      </th>
                      <th class="border-right border-bottom" scope="col">
                        <p class="small text-center w-100 d-block mb-0">
                          {{'Taxpayer.day' | translate}}
                        </p>
                      </th>
                      <th class="border-right border-bottom" scope="col">
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <ng-container *ngFor="
                                        let wage of form.get('addressWhereTaxpayerResided')?.controls;
                                        let index = index;">

                      <tr [formGroupName]="index">
                        <td class="border-right border-left border-bottom">
                          <ng-select
                              [clearable]="false"
                              [items]="whoResided"
                              [multiple]="false"
                              [searchable]="false"
                              formControlName="residedIndicate"
                          >
                          </ng-select>
                        </td>

                        <td class="border-right border-bottom">

                          <ui-input-selector
                              [controlForm]="form"
                              [data]="individualReturn?.review"
                              [groupName]="'addressWhereTaxpayerResided.' + index"
                              controlClass="form-control bg-light text-center"
                              controlName="residedAddress"
                          >
                          </ui-input-selector>
                        </td>
                        <td class="border-right border-bottom">
                          <ng-select
                              (change)="getFromDays(index)"
                              [clearable]="false"
                              [items]=month
                              [multiple]="false"
                              [searchable]="false"
                              formControlName="residedFromMonth"
                          >
                          </ng-select>
                        </td>
                        <td class="border-right border-bottom">
                          <ng-select
                              [clearable]="false"
                              [items]="residedFromDay$| async"
                              [multiple]="false"
                              [searchable]="false"
                              formControlName="residedFromDay"
                          >
                          </ng-select>

                        </td>
                        <td class="border-right border-bottom">
                          <ng-select
                              (change)="getToDays(index)"
                              [clearable]="false"
                              [items]="month"
                              [multiple]="false"
                              [searchable]="false"
                              formControlName="residedToMonth"
                          >
                          </ng-select>
                        </td>
                        <td class="border-right border-bottom">
                          <ng-select
                              [clearable]="false"
                              [items]="residedToDay$| async"
                              [multiple]="false"
                              [searchable]="false"
                              formControlName="residedToDay"
                          >
                          </ng-select>
                        </td>
                        <td class="align-middle text-center border-right border-bottom">
                          <button
                              (click)="removeAddress(index)"
                              [attr.aria-label]="'Delete address'"
                              class="btn btn-sm"
                              container="body"
                              placement="left"
                              role="button"
                              tabindex="0"
                              tooltip="Delete"
                              type="button"
                          >
                            <svg
                                aria-hidden="true"
                                class="icon icon-sm fill-danger-500"
                                focusable="false"
                                viewBox="0 0 24 24"
                            >
                              <path
                                  d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z"
                                  stroke="none"
                              />
                            </svg>
                          </button>
                        </td>
                      </tr>
                    </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="bg-white row pb-2">
                <div class="col-12 d-flex justify-content-end">
                  <button (click)="back()"
                      [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
                      [attr.aria-label]="'Buttons.back-btn' | translate"
                      [attr.tabindex]="(isLoading$ | async) ? -1 : 0"
                      [disabled]="(isLoading$ | async)"
                      class="btn btn-secondary mt-3 ml-3"
                      role="button"
                      type="button">
                    <span>{{
                        "Buttons.back-btn" | translate
                      }}</span>
                  </button>
                  <button (click)="next()"
                      [attr.aria-busy]="(isLoading$ | async)"
                      [attr.aria-disabled]="(isLoading$ | async) ? 'true' : 'false'"
                      [attr.aria-label]="'Buttons.next-btn' | translate"
                      [attr.tabindex]="(isLoading$ | async) ? -1 : 0"
                      [disabled]="(isLoading$ | async)"
                      class="btn btn-primary font-weight-600 mt-3 ml-3"
                      id="submit"
                      role="button">
                    <ui-spinner [class.active]="isLoading$ | async">
                      <span>{{ "Buttons.next-btn" | translate }}</span>
                    </ui-spinner>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<ng-template #noPermission>
  <ui-forbidden></ui-forbidden>
</ng-template>
