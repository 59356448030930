import {ModuleWithProviders, NgModule, Optional, SkipSelf,} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {API_SERVICE_CONFIG_TOKEN, ApiServiceConfig, HexaCoreModule, ToastrComponent} from "@hexalang/ui/core";

export function throwIfAlreadyLoaded(parentModule: any, moduleName: string) {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import ${moduleName} in the AppModule only.`
    );
  }
}

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    HexaCoreModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: ToastrComponent,
      enableHtml: true,
      timeOut: 10000,
    }),
  ],
  exports: [],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }

  static forRoot(config: ApiServiceConfig): ModuleWithProviders<any> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: API_SERVICE_CONFIG_TOKEN,
          useValue: config,
        },
      ],
    };
  }
}
