import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {HexaAuthGuardService} from "@hexalang/ui/core";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./layouts/landing/landing.module").then((x) => x.LandingModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./layouts/account/account.module").then((x) => x.AccountModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./layouts/blank/blank.module").then((x) => x.BlankModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./layouts/dashboard/dashboard.module").then((x) => x.DashboardModule),
    canActivateChild: [HexaAuthGuardService],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRouting {
}
