import {Component, OnInit, Output} from "@angular/core";

import {NgxPermissionsService, NgxRolesService} from "ngx-permissions";
import {AuthService} from "@city-tax/features/auth";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ACL} from "./app.acl";
import {environment} from "../environments/environment";

@Component({
  selector: "admin-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @Output() public redirectPath;

  languages = [
    {value: "en", text: "English"},
    {value: "es", text: "Spanish"},
  ];
  selectedLanguage = "English";

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private roleService: NgxRolesService,
    public permissionService: NgxPermissionsService,
    private translate: TranslateService
  ) {
    // super();
    translate.addLangs(["en", "es"]);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang("en");
    //  console.log(translate.getDefaultLang())
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|es/) ? browserLang : 'en');
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  async ngOnInit() {
    this.route.queryParams.subscribe((p) => {
      if (p?.redirectPath) {
        this.redirectPath = p.redirectPath;
      }
    });
    await this.loadConfig();
    this.authService.setPermissions(ACL, this.router.url);
  }

  async loadConfig() {
    try {
      await this.authService.loadConfig(environment.state, environment.city);
    } catch (error) {
    }
  }
}
