import {Inject, Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {DOCUMENT} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private readonly sessionKey = 'sessionID';

  constructor(
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document
  ) {
  }

  setSessionId(sessionID: string): void {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 30);
    const domain = this.document.location.host === 'localhost:8081' ? undefined : `.${this.document.location.host}`;
    this.cookieService.set(this.sessionKey, sessionID, expiryDate, '/', domain, !!domain, 'Lax');
    const found = this.cookieService.get(this.sessionKey)
    if (!found) {
      console.log('Session ID not found in cookie');
    }
  }

  getSessionId(): string | null {
    return this.cookieService.get(this.sessionKey);
  }

  clearSessionId(): void {
    this.cookieService.delete(this.sessionKey);
  }

}
