import {AfterViewInit, Component, Injector, OnDestroy, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {EBusinessPaymentTypeTaxYear, EPermission, FormatService} from "@city-tax/shared";
import {PaymentService} from "../../../services/payment.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {NgxPermissionsService} from "ngx-permissions";
import {AuthService} from "@city-tax/features/auth";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {HexaListComponent, HexaTitleService, HexaWithPermissions} from "@hexalang/ui/core";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {IndividualPaymentDeleteComponent} from "../payment-delete/payment-delete.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "city-tax-feature-individual-payment-history",
  templateUrl: "./individual-payment-history.component.html",
  styleUrls: ["./individual-payment-history.component.scss"],
})
export class IndividualPaymentHistoryComponent
  extends HexaWithPermissions(HexaListComponent)
  implements OnInit, AfterViewInit, OnDestroy {
  public isLoading$ = this.paymentService.isLoading$;
  @ViewChild("statusTemplate") public statusTemplate: TemplateRef<any>;
  @ViewChild("removeTemplate") public removeTemplate: TemplateRef<any>;
  @ViewChild("payTemplate") public payTemplate: TemplateRef<any>;
  @ViewChild("paymentDateTemplate") public paymentDateTemplate: TemplateRef<any>;
  public columns = [];
  public formFilter: FormGroup;
  public payments$ = this.paymentService.payments$;
  public ePermission = EPermission;
  public taxYear = this.paymentService.taxYear;
  public currentYear = this.paymentService.taxYear;
  public years$ = this.paymentService.years$;
  public regexp = new RegExp('^\\d{2}-');

  public bsModalRef: BsModalRef;

  constructor(
    injector: Injector,
    public titleService: HexaTitleService,
    public permissionService: NgxPermissionsService,
    public toastrService: ToastrService,
    private paymentService: PaymentService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
    public router: Router,
    private formatService: FormatService,
    private modalService: BsModalService,
  ) {
    super(injector, {dataGrid: 'Payments'});
    this.titleService.changeTitle(this.translate.instant("Payment.payments"));
    this.translate.onLangChange.subscribe(val => {
      this.titleService.changeTitle(
        this.translate.instant("Payment.payment")
      )
    })
    this.formFilter = this.formBuilder.group({
      year: null,
    });
  }

  async ngOnInit() {
    await this.getPayments();
    this.checkPermission(EPermission.INDIVIDUAL_PAYMENTS);
  }

  ngAfterViewInit() {
    this.defineColumns();
  }

  public checkForFutureDate(paymentdate): boolean {
    const currentDate = new Date();
    const future = new Date(paymentdate);
    return future > currentDate;
  }

  public async createPayment() {
    const payment = {
      taxYear: this.authService.taxYear,
      paymentType: EBusinessPaymentTypeTaxYear.BALANCE_DUE,
      amount: 0,
    };
    try {
      const paymentResponse = await this.paymentService.createPayment(this.authService.organization.id, payment)
      let path = `/individual/payments/create/intent`;
      this.router.navigate([path], {
        queryParams: {id: paymentResponse?.id, redirect: this.router.url},
      });
    } catch (error) {

    }
  }

  public async schedulePayment() {
    let path = `/individual/payments/create/schedule`;
    await this.router.navigate([path], {
      queryParams: {},
    });
  }

  public async editPayment(payment: any) {
    let path = `/individual/payments/create/intent`;
    await this.router.navigate([path], {
      queryParams: {
        id: payment.id, redirect: this.router.url
      },
    });
  }

  public showModal(type: string, payment: any) {
    let component: any;
    let modelClass: string;
    let ignoreBackdropClick = true;
    const initialState = payment ? {payment} : {};

    switch (type) {
      case 'delete':
        component = IndividualPaymentDeleteComponent;
        modelClass = "modal-sm";
        break;
    }

    this.bsModalRef = this.modalService.show(component, {
      class: modelClass,
      ignoreBackdropClick: ignoreBackdropClick,
      initialState
    });
  }

  public async deletePayment(paymentId: string) {
    try {
      const response = await this.paymentService.deletePayment(
        this.authService.organization.id,
        paymentId
      );
      this.toastrService.success("Payment deleted successfully");
    } catch (error) {

    }
  }

  defineColumns() {
    this.columns = [
      {prop: "taxYear", name: "Tax Year", sortable: false},
      {
        prop: "status",
        name: "Status",
        sortable: false,
        cellTemplate: this.statusTemplate,
      },
      {prop: "paymentType", name: "Type", sortable: false},
      // {prop: "period", name: "Period", sortable: false},
      {
        prop: "paymentDate",
        name: "Payment Date",
        cellTemplate: this.paymentDateTemplate,
        sortable: true,
      },
      {
        prop: "amount",
        name: "Amount",
        pipe: {transform: (value: any) => this.formatService.currencyFormat(value)},
        sortable: false,
      },
      {
        prop: 'message',
        name: 'Message',
        sortable: false,
      },
      {
        width: 100,
        sortable: false,
        cellTemplate: this.payTemplate,
        cellClass: "justify-content-end text-truncate-none",
      },
      {
        sortable: false,
        cellTemplate: this.removeTemplate,
        cellClass: "justify-content-end text-truncate-none",
      },
      // {prop: 'createdAt', name: 'Created Date', pipe: {transform: (value: any) => this.formatService.dateFormat(value)}, sortable: false},
    ];
  }

  private async getPayments() {
    this.commonCombineNoOrgLatest()
      .pipe(
        this.commonPipe((resp: any) => {
          const [formFilterValue] = resp;
          this.preparePagingParams(null, this.oDataQuery(formFilterValue));
          return this.paymentService.getPayments(
            this.authService.organization.id,
            this.authService.individualId,
            this.pagingParams
          )
        })
      )
      .subscribe();
  }

  private oDataQuery(formValue): Array<string> {
    const oDataQuery = [];
    const formattedFormValue: any = {};

    if (formValue && formValue.year && formValue.year.length > 0) {
      formattedFormValue.year = `(taxYear eq ${formValue.year
        .map((s) => `${s}`)
        .toString()
        .replace(new RegExp(",", "g"), " or taxYear eq ")})`;
    }

    Object.keys(formattedFormValue).forEach((key) => {
      if (formattedFormValue[key]) {
        oDataQuery.push(formattedFormValue[key]);
      }
    });

    return oDataQuery;
  }
}
