import {Component, OnInit} from "@angular/core";
import {HexaTitleService} from "@hexalang/ui/core";
import { Location } from '@angular/common';


@Component({
  selector: "city-tax-feature-individual-payment",
  templateUrl: "./individual-payment.component.html",
  styleUrls: ["./individual-payment.component.scss"],
})
export class IndividualPaymentComponent
  implements OnInit {
  public title$ = this.titleService.title$;
  public history = window.history;

  constructor(
    public titleService: HexaTitleService,
    private location: Location
    ) {
  }

  ngOnInit() {
  }
}
