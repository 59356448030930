<div [formGroup]="localityFormGroup" class="row">
  <div class="col-3">
    <ng-select (change)="setValues($event)"
        (clear)="onClearDynamic()"
        (search)="onSearchDynamic($event)"
        [bindLabel]="'locality'"
        [bindValue]="'locality'"
        [items]="localities.length > 0 ? localities: []"
        [loading]="isLoading$ | async"
        class="w-100 form-control p-0"
        formControlName="taxWithheldLocalityName"
        [placeholder]="'type-to-search' | translate">
    </ng-select>
  </div>
  <div class="col-3">
    <div class="form-group">
      <input (change)="calculateCredit()"
          [decimals]="city.components.individual.fractionDigits"
          class="w-100 form-control text-right"
          formControlName="wagesBox18"
          id="wagesBox18"
          name="wagesBox18"
          type="text"
          ui-number>
    </div>
  </div>
  <div class="col-2">
    <div class="form-group">
      <input (change)="calculateCredit()"
          [decimals]="city.components.individual.fractionDigits"

          class="w-100 form-control text-right"
          formControlName="taxWithheldBox19"
          id="taxWithheldBox19"
          name="taxWithheldBox19"
          type="text"
          ui-number
      >
    </div>
  </div>
  <div class="col-2">
    <div class="form-group">
      <input (change)="calculateCredit()"
          [decimals]="city.components.individual.fractionDigits"
          class="w-100 form-control text-right"
          formControlName="refundRequestedAmount"
          id="refundRequestedAmount"
          name="refundRequestedAmount"
          type="text"
          ui-number
      >
    </div>
  </div>
  <div class="col-2">
    <div class="form-group">
      <input [decimals]="city.components.individual.fractionDigits"
          class="w-100 form-control text-right"
          formControlName="creditForTaxesPaid"
          id="creditForTaxesPaid"
          name="creditForTaxesPaid"
          readonly
          type="text"
          ui-number
      >
    </div>
  </div>
  <input [decimals]="city.components.individual.fractionDigits"
      class="w-100 form-control text-right"
      formControlName="localityTaxRate"
      hidden
      id="localityTaxRate"
      name="localityTaxRate"
      type="text"
      ui-number
  >
</div>

