import {Component, Injector, OnInit} from "@angular/core";
import {HexaApiService, HexaDetailComponent, HexaTitleService} from "@hexalang/ui/core";
import {EPermission} from "@city-tax/shared";
import {AuthService} from "@city-tax/features/auth";
import {PaymentService} from "../../../../services/payment.service";
import {ToastrService} from "ngx-toastr";
import {ActivatedRoute, Router} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "city-tax-feature-individual-payment-create-confirmation",
  templateUrl: "./individual-payment-create-confirmation.component.html",
  styleUrls: ["./individual-payment-create-confirmation.component.scss"],
})
export class IndividualPaymentCreateConfirmationComponent
  extends HexaDetailComponent
  implements OnInit {

  public isDownloading$ = this.paymentService.isDownloading$;
  public isLoading$ = this.paymentService.isLoading$;
  public city = this.authService.config.name?.toUpperCase().substring(0,22);
  public ePermission = EPermission;
  public payment$ = this.paymentService.payment$;
  public organization = this.authService.organization;

  constructor(
    injector: Injector,
    public titleService: HexaTitleService,
    private authService: AuthService,
    private apiService: HexaApiService,
    private paymentService: PaymentService,
    private translate: TranslateService,
    public toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(injector);
    this.titleService.changeTitle(this.translate.instant("Payment.payment-confirmation"));
    this.translate.onLangChange.subscribe(val => {
      this.titleService.changeTitle(
        this.translate.instant("Payment.payment-confirmation")
      )
    })
  }

  async ngOnInit() {
    if (!this.route.snapshot.queryParams["id"] && !this.route.snapshot.params["id"]) {
      await this.getPayment();
    } else {
      try {
        await this.paymentService.loadPayment(
          this.authService.organization.id,
          this.route.snapshot.queryParams["id"] ||
          this.route.snapshot.params["id"]
        );
      } catch (error) {
      }
      ;
    }
  }

  public async getPayment() {
    try {
      await this.paymentService.getPayment(this.authService.organization.id);
    } catch (error) {
    }
  }

  async print(id) {
    try {
      await this.paymentService.printReceipt(
        this.authService.organization.id,
        id,
        id
      );
    } catch (error) {
    }
  }

  async additionalPayments() {
    try {
      const response = await this.paymentService.createPayment(this.authService.organization.id, {});
      if (response) {
        let path = `/individual/payments/create/intent`;
        await this.router.navigate([path]);
      }
    } catch (error) {

    }
  }
}
