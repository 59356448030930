import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {IIndividualReturnResponse} from "../interfaces/individual-return.interface";
import {WithLoadingAndErrorHandler} from "@city-tax/core";
import {IndividualReturnModel} from "../models/individual-return.model";
import {HexaApiService, HexaStorageService} from "@hexalang/ui/core";
import {ToastrService} from "ngx-toastr";
import {WithholdingModel} from "../../../../business/src/lib/models/withholding.model";

@Injectable({
  providedIn: "root",
})
export class IndividualService extends WithLoadingAndErrorHandler() {
  public individualReturn$: Observable<IIndividualReturnResponse>;


  public notifications$: Observable<any>;
  private _notifications: BehaviorSubject<any>;

  private dataStore: {
    individualReturn: IndividualReturnModel;
    notifications: any;
  };

  constructor(
    private apiService: HexaApiService,
    private storageService: HexaStorageService,
    public toastrService: ToastrService
  ) {
    super(apiService, toastrService);
    this.dataStore = {
      individualReturn: null,
      notifications: null
    };

    this._individualReturn = new BehaviorSubject({}) as BehaviorSubject<IIndividualReturnResponse>;
    this.individualReturn$ = this._individualReturn.asObservable();

    this._notifications = new BehaviorSubject({}) as BehaviorSubject<WithholdingModel>;
    this.notifications$ = this._notifications.asObservable();
  }

  private _individualReturn: BehaviorSubject<IIndividualReturnResponse>;

  get individualReturn() {
    return this.dataStore.individualReturn;
  }

  get id() {
    return this.storageService.get("return_id") || null;
  }

  public async getReturn(organizationId: string, state = "MI"): Promise<any> {
    const response = await this.get(`organizations/${organizationId}/individuals/me/returns/${this.taxYear}`);
    const individualReturn = state === "OH" ? this.parseOhIr(response.body) : response.body;
    this.storeId(individualReturn.id);
    this.dataStore.individualReturn = new IndividualReturnModel(individualReturn);
    this._individualReturn.next(Object.assign({}, this.dataStore).individualReturn);
    return individualReturn;
  }

  parseOhIr(ir) {
    if (ir?.page2?.worksheetA?.taxpayerScheduleOfWages) {
      if (!Array.isArray(ir.page2.worksheetA.taxpayerScheduleOfWages)) {
        ir.page2.worksheetA.taxpayerScheduleOfWages = [
          ir.page2.worksheetA.taxpayerScheduleOfWages,
        ];
      }
      for (const wage of ir.page2.worksheetA.taxpayerScheduleOfWages) {
        if (wage?.w2LocalityInfo) {
          wage.w2LocalityInfo = [].concat(wage.w2LocalityInfo);
        }
      }
    }
    if (ir?.page2?.worksheetA?.spouseScheduleOfWages) {
      if (!Array.isArray(ir.page2.worksheetA.spouseScheduleOfWages)) {
        ir.page2.worksheetA.spouseScheduleOfWages = [
          ir.page2.worksheetA.spouseScheduleOfWages,
        ];
      }
      for (const wage of ir.page2.worksheetA.spouseScheduleOfWages) {
        if (wage?.w2LocalityInfo) {
          wage.w2LocalityInfo = [].concat(wage.w2LocalityInfo);
        }
      }
    }

    if (ir?.page2?.worksheetB?.otherIncomeTypeScheduleCDetail) {
      if (!Array.isArray(ir.page2.worksheetB.otherIncomeTypeScheduleCDetail)) {
        ir.page2.worksheetB.otherIncomeTypeScheduleCDetail = [
          ir.page2.worksheetB.otherIncomeTypeScheduleCDetail,
        ];
      }
    }
    if (ir?.page2?.worksheetB?.otherIncomeTypeScheduleK1Detail) {
      if (!Array.isArray(ir.page2.worksheetB.otherIncomeTypeScheduleK1Detail)) {
        ir.page2.worksheetB.otherIncomeTypeScheduleK1Detail = [
          ir.page2.worksheetB.otherIncomeTypeScheduleK1Detail,
        ];
      }
    }
    if (ir?.page2?.worksheetB?.otherIncomeTypeScheduleEDetail) {
      if (!Array.isArray(ir.page2.worksheetB.otherIncomeTypeScheduleEDetail)) {
        ir.page2.worksheetB.otherIncomeTypeScheduleEDetail = [
          ir.page2.worksheetB.otherIncomeTypeScheduleEDetail,
        ];
      }
    }

    if (ir?.page2?.worksheetB?.otherIncomeTypeScheduleMiscDetail) {
      if (
        !Array.isArray(ir.page2.worksheetB.otherIncomeTypeScheduleMiscDetail)
      ) {
        ir.page2.worksheetB.otherIncomeTypeScheduleMiscDetail = [
          ir.page2.worksheetB.otherIncomeTypeScheduleMiscDetail,
        ];
      }
    }

    if (ir?.page2?.worksheetC?.adjustmentToIncomeLine) {
      if (!Array.isArray(ir.page2.worksheetC.adjustmentToIncomeLine)) {
        ir.page2.worksheetC.adjustmentToIncomeLine = [
          ir.page2.worksheetC.adjustmentToIncomeLine,
        ];
      }
    }

    if (ir?.page2?.worksheetB?.lossCarryForward) {
      if (!Array.isArray(ir.page2.worksheetB.lossCarryForward)) {
        ir.page2.worksheetB.lossCarryForward = [
          ir.page2.worksheetB.lossCarryForward,
        ];
      }
    }

    return ir;
  }

  public async nextPageReviewPage1(organizationId, payload): Promise<any> {
    payload = payload || {};
    const response = await this.put(
      `organizations/${organizationId}/individuals/me/returns/${this.id}/review/page1`,
      payload
    );
    const individualReturn = new IndividualReturnModel(
      response.body as IndividualReturnModel
    );
    this.dataStore.individualReturn = individualReturn;
    this._individualReturn.next(
      Object.assign({}, this.dataStore).individualReturn
    );
    return individualReturn;
  }

  public async updateReturn(organizationId, payload): Promise<any> {
    payload = payload || {};
    const response = await this.put(`organizations/${organizationId}/individuals/me/returns/${this.id}`, payload);
    const individualReturn = new IndividualReturnModel(response.body as IndividualReturnModel);
    this.dataStore.individualReturn = individualReturn;
    this._individualReturn.next(
      Object.assign({}, this.dataStore).individualReturn
    );
    return individualReturn;
  }

  public async nextPageReviewPage2(organizationId, payload): Promise<any> {
    payload = payload || {};
    const response = await this.put(
      `organizations/${organizationId}/individuals/me/returns/${this.id}/review/page2`,
      payload
    );
    const individualReturn = new IndividualReturnModel(
      response.body as IndividualReturnModel
    );
    this.dataStore.individualReturn = individualReturn;
    this._individualReturn.next(
      Object.assign({}, this.dataStore).individualReturn
    );
    return individualReturn;
  }

  public async nextPageAttachment1(organizationId, payload): Promise<any> {
    payload = payload || {};
    const response = await this.put(
      `organizations/${organizationId}/individuals/me/returns/${this.id}/review/attachment1`,
      payload
    );
    const individualReturn = new IndividualReturnModel(
      response.body as IndividualReturnModel
    );
    this.dataStore.individualReturn = individualReturn;
    this._individualReturn.next(
      Object.assign({}, this.dataStore).individualReturn
    );
    return individualReturn;
  }

  public async nextPageReviewTc(organizationId, payload): Promise<any> {
    payload = payload || {};
    const response = await this.put(
      `organizations/${organizationId}/individuals/me/returns/${this.id}/review/tc`,
      payload
    );

    const individualReturn = new IndividualReturnModel(
      response.body as IndividualReturnModel
    );
    this.dataStore.individualReturn = individualReturn;
    this._individualReturn.next(
      Object.assign({}, this.dataStore).individualReturn
    );
    return individualReturn;
  }

  public async nextPageReviewAcknowledge(organizationId, payload): Promise<any> {
    payload = payload || {};
    const response = await this.put(
      `organizations/${organizationId}/individuals/me/returns/${this.id}/review/acknowledge`,
      payload
    );
    const individualReturn = new IndividualReturnModel(
      response.body as IndividualReturnModel
    );
    this.dataStore.individualReturn = individualReturn;
    this._individualReturn.next(
      Object.assign({}, this.dataStore).individualReturn
    );
    return individualReturn;
  }

  public downloadReturn(organizationId: string, id: string, locator: string) {
    return this.downloadBlob(
      `organizations/${organizationId}/individuals/me/returns/${id}/confirmation`,
      null,
      null,
      locator
    );
  }

  public printWorksheets(organizationId: string, id: string, locator: string) {
    return this.openBlob(
      `organizations/${organizationId}/individuals/me/returns/${id}/worksheets`,
      null,
      locator
    );
  }

  public openReturn(organizationId: string, id: string, locator: string) {
    return this.openBlob(
      `organizations/${organizationId}/individuals/me/returns/${id}/confirmation`,
      null,
      locator
    );
  }

  public async mailReturn(organizationId, payload): Promise<any> {
    const response = await this.put(
      `organizations/${organizationId}/individuals/me/returns/${this.id}/mail`,
      payload
    );
    const individualReturn = new IndividualReturnModel(
      response.body as IndividualReturnModel
    );
    this.dataStore.individualReturn = individualReturn;
    this._individualReturn.next(
      Object.assign({}, this.dataStore).individualReturn
    );
    return individualReturn;
  }

  public async submitReturn(organizationId, payload): Promise<any> {
    const response = await this.put(
      `organizations/${organizationId}/individuals/me/returns/${this.id}/submit`,
      payload
    );
    const individualReturn = new IndividualReturnModel(
      response.body as IndividualReturnModel
    );
    this.dataStore.individualReturn = individualReturn;
    this._individualReturn.next(
      Object.assign({}, this.dataStore).individualReturn
    );
    return individualReturn;
  }

  public async uploadFile(organizationId: string, returnId: string, type: string, media: any): Promise<any> {
    media.type = type;
    const response = await this.upload(`organizations/${organizationId}/individuals/me/returns/${returnId}/files`, media, type);
    const individualReturn = new IndividualReturnModel(response.body as IndividualReturnModel);
    this.dataStore.individualReturn = individualReturn;
    this._individualReturn.next(
      Object.assign({}, this.dataStore).individualReturn
    );
    return individualReturn;
  }

  public async deleteFile(organizationId: string, returnId: string, id: string): Promise<any> {
    const response = await this.delete(
      `organizations/${organizationId}/individuals/me/returns/${returnId}/files/${id}`
    );
    const individualReturn = this.dataStore.individualReturn;
    individualReturn.files = individualReturn.files.filter((f) => f.id !== id);
    this._individualReturn.next(
      Object.assign({}, this.dataStore).individualReturn
    );
    return individualReturn;
  }

  public storeId(id: string) {
    this.storageService.save("return_id", id);
  }

  public clearFiling(organizationId: string, taxYear: number) {
    if (taxYear !== this.taxYear) {
      this.dataStore.individualReturn = null;
      this.storageService.save("return_id", null);
      this._individualReturn.next(Object.assign({}, this.dataStore).individualReturn);
      this.taxYear = taxYear;
      this.currentYear = taxYear + 1;
    }
  }

  async getLocalitySearch(state: string, term: string): Promise<any> {
    const res = await this.get(`tax-rates/${state}/search/${term}`);
    return res.body;
  }

  async getLocalities(state: string,): Promise<any> {
    const res = await this.get(`tax-rates/${state}`);
    return res.body;
  }

  public async getNotifications(organizationId: string, param: any): Promise<WithholdingModel> {
    const response = await this.get(`organizations/${organizationId}/individuals/me/notifications`, param)
    if (response) {
      const notifications = response.body;
      this.dataStore.notifications = notifications;
      this._notifications.next(Object.assign({}, this.dataStore).notifications);
      return notifications;
    }
  }

  public async read(organizationId: string, notificationId: string): Promise<any> {
    const response = await this.put(`organizations/${organizationId}/individuals/me/notifications/${notificationId}`, {})
    return response.body
  }

}
